import React, { useState } from 'react'
import {
  Box,
  toast,
  Checkbox,
  Flex,
  Message,
  Modal,
  Paragraph,
  Text,
  Textarea,
  Button,
} from 'ui'
import { BlockModalProps } from './types'
import { feedbackPrompts } from './feedbackPrompts'
import { useSendFeedbackMutation } from 'queries/review'

export function Feedback(props: BlockModalProps & { shiftId?: number }) {
  const { setModalType, worker, shiftId } = props
  const [selected, setSelected] = useState<string[]>([])
  const [feedback, setFeedback] = useState('')
  const [error, setError] = useState<Error>()
  const { mutate: sendFeedback, isPending } = useSendFeedbackMutation({
    onSuccess() {
      toast.success(`You've successfully sent feedback to ${worker.name}`)
      setModalType(null)
    },
    onError(err) {
      setError(err)
    },
  })

  const onSubmitFeedback = () => {
    const tags = selected.filter((tag) => tag !== 'other')
    const writtenFeedback = feedback.trim()

    if (tags.length === 0 && !writtenFeedback) {
      setError(new Error('Please provide at least one feedback'))
      return
    }

    sendFeedback([
      {
        workerId: worker.id,
        shiftId,
        tags,
        comment: writtenFeedback,
      },
    ])
  }

  return (
    <Modal
      width={[1, 600, 800, 900]}
      title={`Profile Feedback to ${worker.name}`}
      open={true}
      loading={isPending}
      onClose={() => setModalType(null)}
      customCta={
        <>
          {error ? (
            <Message mb={[2, 0]} width={[1, 'auto']} variant={'error'}>
              {error.message}
            </Message>
          ) : null}
          <Box flex={1} />
          <Button variant={'text'} onClick={() => setModalType(null)}>
            Cancel
          </Button>
          <Button onClick={onSubmitFeedback}>Submit Feedback</Button>
        </>
      }
    >
      <Paragraph>
        Give {worker.name} feedback that they can use to improve your experience
        with them and allow them to perform their tasks better for next time.
        The feedback you give here will go directly to the worker.
      </Paragraph>
      <Flex flexWrap={'wrap'} justifyContent={'stretch'} mx={-2}>
        {feedbackPrompts.map((prompt) => (
          <Box my={2} width={[1, 1 / 2]} p={2} key={prompt.tag}>
            <Box
              height={'100%'}
              p={3}
              borderRadius={'standard'}
              boxShadow={'low'}
            >
              <Checkbox
                value={selected.includes(prompt.tag)}
                onChange={(checked) => {
                  if (checked) {
                    setSelected((prevState) => [...prevState, prompt.tag])
                  } else {
                    setSelected((prevState) =>
                      prevState.filter((tag) => tag !== prompt.tag)
                    )
                  }
                }}
              >
                <Text fontWeight={2}>{prompt.title}</Text>
                <Paragraph my={0} mt={1} fontSize={1}>
                  {prompt.description}
                </Paragraph>
              </Checkbox>
            </Box>
          </Box>
        ))}
      </Flex>
      <Box mt={2}>
        <Textarea
          value={feedback}
          minRows={2}
          onChange={(e) => setFeedback(e.currentTarget.value)}
          placeholder={
            '(Optional) Provide written feedback. What you write here will go directly to the worker.'
          }
        />
      </Box>
    </Modal>
  )
}
