import React, { useState } from 'react'
import { Field, Input, Message, Modal, toast } from 'ui'
import {
  useInvalidateChargeLimits,
  useUpdateBonusLimitMutation,
} from 'queries/payment'
import { DollarSignIcon } from 'lucide-react'

interface Props {
  open: boolean
  onClose: () => void
}

export function BonusLimitModal(props: Props) {
  const { open, onClose } = props
  const [limit, setLimit] = useState('')
  const [error, setError] = useState('')
  const { invalidate: invalidateChargeLimits } = useInvalidateChargeLimits()
  const { mutate: updateLimit, isPending: isSaving } =
    useUpdateBonusLimitMutation({
      onSuccess: () => {
        toast.success(`You've successfully set up your monthly bonus limit.`)
        invalidateChargeLimits()
        resetForm()
      },
      onError: (err) => {
        setError(err.message)
      },
    })

  const resetForm = () => {
    setLimit('')
    setError('')
    onClose()
  }

  const handleSubmit = () => {
    const limitAmount = Number(limit)
    if (limitAmount && limitAmount > 0) {
      updateLimit(limitAmount)
    } else {
      setError('Please enter a valid amount')
    }
  }

  return (
    <Modal
      title={'Set Up Monthly Bonus Limit'}
      open={open}
      onClose={resetForm}
      loading={isSaving}
      onOk={handleSubmit}
    >
      <Field label={'Enter a Bonus Limit'}>
        <Input
          icon={<DollarSignIcon size={14} />}
          type={'number'}
          placeholder={'Enter Bonus Limit'}
          value={limit}
          onChange={(e) => setLimit(e.target.value)}
        />
      </Field>
      {error ? <Message variant={'error'}>{error}</Message> : null}
    </Modal>
  )
}
