import { useQuery } from '@tanstack/react-query'
import { employerGuidanceKey } from './keys'
import { EmployerGuidanceOptions, getEmployerGuidance } from 'api/shift'

export function useEmployerGuidance(options: EmployerGuidanceOptions) {
  const { positionId, locationId, mileage, shiftDurationHours } = options
  return useQuery({
    queryKey: employerGuidanceKey(
      positionId,
      locationId,
      mileage,
      shiftDurationHours
    ),
    queryFn: () => getEmployerGuidance(options),
  })
}
