import React, { useState } from 'react'
import { IconButton, Message, Modal, Paragraph, toast } from 'ui'
import { MinusCircleIcon } from 'lucide-react'
import { useRemoveMemberRoleMutation } from 'queries/company'
import { CompanyGroupMember, CompanyMember, CompanyRole } from 'api/company'
import { AuthGuard } from 'components/auth'
import { PermissionEnum } from 'api/userPermissions'

interface Props {
  member: CompanyMember | (CompanyGroupMember & { companyRole?: CompanyRole })
  actionDisabled?: boolean // Optional prop to control the disabled state of the button
}

// Type Guard to check if it's a CompanyGroupMember with a companyRole
function isCompanyGroupMember(
  member: any
): member is CompanyGroupMember & { companyRole?: CompanyRole } {
  return 'companyRole' in member
}

export function RemoveDialog({ member, actionDisabled = false }: Props) {
  const memberName = member.name || 'Team member'
  const [isOpen, setIsOpen] = useState(false)
  const {
    mutate: removeMember,
    isPending,
    error,
  } = useRemoveMemberRoleMutation({
    onSuccess() {
      toast.success(`${memberName} has been removed from your company.`)
      setIsOpen(false)
    },
  })

  const onOk = () => {
    const payload = {
      userId: member.id,
      rolePermId: member.companyRolePerm?.id,
    }

    if (isCompanyGroupMember(member) && member.companyRole?.companyId) {
      payload['companyId'] = member.companyRole.companyId
    }

    removeMember(payload)
  }

  return (
    <AuthGuard.Permission value={PermissionEnum.ManageRoles}>
      {isOpen ? (
        <Modal
          loading={isPending}
          open={isOpen}
          onClose={() => setIsOpen(false)}
          title={'Confirm Removal'}
          onOk={onOk}
          okText={'Confirm'}
        >
          <Paragraph>
            Are you sure you want to remove {memberName} from your company?
          </Paragraph>
          {error ? <Message variant={'error'}>{error.message}</Message> : null}
        </Modal>
      ) : null}
      <IconButton onClick={() => setIsOpen(true)} disabled={actionDisabled}>
        <MinusCircleIcon size={16} />
      </IconButton>
    </AuthGuard.Permission>
  )
}
