import { useEffect, useState } from 'react'
import { WorkShift } from 'typings/common_defs'
import { TimelineShift } from 'api/shift'
import { isBefore, isAfter, parseISO } from 'date-fns'

/**
 * Calculates info about where we are in a shift's timeline (e.g. is it in progress, is it in the past etc.)
 * @param shift
 * @returns {isInProgress, isInPast}
 */
const useShiftTimeline = (shift: WorkShift | TimelineShift | null) => {
  const [isInProgress, setIsInProgress] = useState(true)
  const [isInPast, setIsInPast] = useState(false)

  useEffect(() => {
    if (!shift || !shift.startsAt || !shift.endsAt) {
      return
    }
    setIsInProgress(
      isBefore(parseISO(shift.startsAt), new Date()) &&
        isAfter(parseISO(shift.endsAt), new Date())
    )
    setIsInPast(isBefore(parseISO(shift.endsAt), new Date()))
  }, [shift])

  return { isInProgress, isInPast }
}

export default useShiftTimeline
