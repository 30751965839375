import React from 'react'
import { useWorkerProfile } from 'queries/worker'
import { useCompanyInfo } from 'queries/company'
import { Avatar, Box, Flex, Heading, Pluralize, Text } from 'ui'

interface Props {
  workerId: number
  upcoming: number
  completed: number
}

export function Header(props: Props) {
  const { workerId, upcoming, completed } = props
  const { data: profileData } = useWorkerProfile(workerId)
  const { data: companyInfo } = useCompanyInfo()

  if (!profileData) {
    return null
  }

  return (
    <Flex flexDirection={'row'} mt={2} mb={4}>
      <Avatar
        size={'xlarge'}
        user={{
          userId: profileData.worker.id.toString(),
          name: profileData.worker.name,
          photoUrl: profileData.worker.profilePicUrl,
        }}
      />
      <Box pl={3}>
        <Heading level={2} my={0} mb={1}>
          {profileData.worker.name}
        </Heading>
        <Box display={'inline-flex'}>
          <Text fontWeight={2}>{companyInfo?.name}</Text>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Text color={'lightText'}>
            <Pluralize
              count={completed}
              oneText={'Completed Shift'}
              manyText={'Completed Shifts'}
            />
            &nbsp;&nbsp;•&nbsp;&nbsp;
            <Pluralize
              count={upcoming}
              oneText={'Upcoming Shift'}
              manyText={'Upcoming Shifts'}
            />
          </Text>
        </Box>
      </Box>
    </Flex>
  )
}
