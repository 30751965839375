import React, { ReactNode } from 'react'
import { Modal } from 'ui'
import { PositionEditorProvider } from './PositionEditorProvider'

interface Props {
  positionId?: number
  trigger: ReactNode
  onPositionAdded?: (positionId: number) => void
  onPositionUpdated?: () => void
}

export function AddPosition(props: Props) {
  const { trigger, positionId, onPositionAdded, onPositionUpdated } = props

  return (
    <Modal width={[1, 'auto']} minWidth={[1, '60rem']} trigger={trigger}>
      <Modal.Consumer>
        {({ closeModal }) => (
          <PositionEditorProvider
            positionId={positionId}
            onPositionAdded={(positionId) => {
              if (onPositionAdded) {
                onPositionAdded(positionId)
              }
              closeModal()
            }}
            onPositionUpdated={() => {
              if (onPositionUpdated) {
                onPositionUpdated()
              }
              closeModal()
            }}
          />
        )}
      </Modal.Consumer>
    </Modal>
  )
}
