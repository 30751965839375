import { RadioGroup } from '@workwhile/ui'
import { PaymentTypeEnum } from '../../schemas'
import { RadioOption } from '../../RadioOption'
import { Box, Text } from 'ui'
import { Controller } from 'react-hook-form'
import React from 'react'

export function PaymentTypeSelector() {
  return (
    <Controller
      name={'paymentType'}
      render={({ field }) => (
        <RadioGroup
          {...field}
          flexDirection={['column', 'row']}
          justifyContent={'space-between'}
          value={field.value ?? PaymentTypeEnum.Hourly}
          options={[
            {
              value: PaymentTypeEnum.Hourly,
              label: 'Hourly',
            },
            {
              value: PaymentTypeEnum.LumpSum,
              label: 'Lump Sum',
            },
          ]}
          optionStyleProps={{
            width: [1, '49%'],
          }}
          renderOption={({ indicator, option, isSelected }) => (
            <RadioOption $isSelected={isSelected}>
              {indicator}
              <Box pl={3}>
                <Text fontWeight={2} fontSize={3} className={'option-title'}>
                  {option.label}
                </Text>
                <Box pt={2}>
                  {option.value === PaymentTypeEnum.Hourly ? (
                    <Text>
                      The amount you'll pay each worker for an hour of work
                    </Text>
                  ) : null}
                  {option.value === PaymentTypeEnum.LumpSum ? (
                    <Text>
                      The amount you'll pay each worker for the entire shift
                    </Text>
                  ) : null}
                </Box>
              </Box>
            </RadioOption>
          )}
        />
      )}
    />
  )
}
