import React from 'react'
import { Flex } from 'ui'
import { useWorkerProfile } from './useWorkerProfile'
import { ActionButton } from './ActionButton'
import { AddWorkerToList } from './AddWorkerToList'
import { GiveBonus } from './GiveBonus'
import { ScheduleShiftModal } from 'pages/WorkerProfilePage/ScheduleShiftModal'
import { WorkerActions } from 'components/WorkerActions'
import { useInvalidateWorkerProfile } from 'queries/worker'

export function ActionBar() {
  const { profileData } = useWorkerProfile()
  const { invalidate: invalidateProfile } = useInvalidateWorkerProfile(
    profileData.worker.id
  )

  return (
    <Flex
      py={3}
      alignItems={'center'}
      justifyContent={'center'}
      flexWrap={'wrap'}
    >
      <ScheduleShiftModal />
      <AddWorkerToList />
      <GiveBonus />
      <WorkerActions
        worker={profileData.worker}
        renderButton={(props) => <ActionButton width={32} px={0} {...props} />}
        onSuccess={() => invalidateProfile()}
      />
    </Flex>
  )
}
