import React from 'react'
import {
  Modal,
  Text,
  createColumnHelper,
  Table,
  Loading,
  Button,
  Link,
  Flex,
  useResponsiveValue,
  Box,
} from 'ui'
import { MessageableShift } from 'api/message'
import { getLocalTimezone } from 'lib/time'
import { formatInTimeZone } from 'date-fns-tz'
import { useMessageableUpcomingShifts } from 'queries/message'

interface Props {
  open: boolean
  onClose: () => void
}

const localTimezone = getLocalTimezone()
const getShiftTime = (shift: MessageableShift) => {
  const timezone = shift.location.address?.timezone || localTimezone
  const startsAt = shift.startsAt as string
  return formatInTimeZone(startsAt, timezone, 'MMMM dd, hh:mm aa zzz')
}
const columnHelper = createColumnHelper<MessageableShift>()
const columns = [
  columnHelper.accessor('position', {
    cell: (info) => (
      <>
        <Text my={2} fontWeight={2}>
          {info.getValue().name}
        </Text>
        <Box display={['block', 'block', 'none']}>
          <Text my={2}>{info.row.original.location.name}</Text>
          <Text>{getShiftTime(info.row.original)}</Text>
          <Link to={`/shift/${info.getValue()}`}>
            <Button kind={'medium'} mt={2} block={true}>
              Message
            </Button>
          </Link>
        </Box>
      </>
    ),
  }),
  columnHelper.accessor('location', {
    cell: (info) => info.getValue().name,
  }),
  columnHelper.accessor('startsAt', {
    cell: (info) => getShiftTime(info.row.original),
  }),
  columnHelper.accessor('id', {
    cell: (info) => (
      <Flex justifyContent={'flex-end'}>
        <Link to={`/shift/${info.getValue()}`}>
          <Button kind={'small'} width={100}>
            Message
          </Button>
        </Link>
      </Flex>
    ),
  }),
]

export function MessageableShiftsModal(props: Props) {
  const { open, onClose } = props
  const { data, isLoading } = useMessageableUpcomingShifts()
  const columnsToUse = useResponsiveValue([1, 1, 4])

  return (
    <Modal
      width={[1, 1, 900]}
      title={'Pick a shift to message scheduled workers'}
      open={open}
      onClose={onClose}
    >
      {isLoading ? <Loading /> : null}
      {data ? (
        <Table
          columns={columns.slice(0, columnsToUse)}
          data={data}
          variant={'borderless'}
        />
      ) : null}
    </Modal>
  )
}
