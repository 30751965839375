import React from 'react'
import { LinkProps, useLocation } from 'react-router-dom'
import { Link } from 'ui'

export function ModalLink({ to, ...rest }: LinkProps) {
  const location = useLocation()
  const state = location.state || {}
  const backgroundStack = state.backgroundStack || []

  return (
    <Link
      {...rest}
      to={to}
      state={{ ...state, backgroundStack: [...backgroundStack, location] }}
    />
  )
}
