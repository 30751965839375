import { graphql } from '../graphql'
import { format } from 'date-fns'
import { path } from 'ramda'
import { LogEventType } from 'api/company'

export interface CompanyWorkerLog {
  timestamp: string
  message: string
  userId: number
  eventType: LogEventType
}

const logDateFormat = "yyyyMMdd'T'HH:mm:ssx"

export async function getCompanyWorkerLogs(options: {
  startDate: Date
  endDate: Date
  userId: number
}) {
  const { startDate, endDate, userId } = options
  const data = await graphql(`
    company { 
      eventLogs (
          ${startDate ? `startsAt: "${format(startDate, logDateFormat)}"` : ''}
          ${endDate ? `endsAt: "${format(endDate, logDateFormat)}"` : ''}
          ${userId ? `userId: ${userId}` : ''}
      ) {
          timestamp, message, userId, eventType
      }
    }
  `)

  return path(
    ['data', 'data', 'company', 'eventLogs'],
    data
  ) as CompanyWorkerLog[]
}
