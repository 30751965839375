import React from 'react'
import { WeeklyRatings } from 'typings/common_defs'
import { Heading, Text } from 'ui'
import { TooltipBox } from '../TooltipBox'

interface Props {
  active?: boolean
  payload?: Array<{
    payload: WeeklyRatings
  }>
  label?: string
}

export function RatingTooltip(props: Props) {
  const { active, payload, label } = props

  if (active && payload && label) {
    const data = payload[0].payload
    return (
      <TooltipBox>
        <Heading level={4} my={0} mb={2}>
          {label}
        </Heading>
        <Text>Average Rating: {data.avgRating}</Text>
      </TooltipBox>
    )
  }

  return null
}
