import React from 'react'
import { Flex, DropdownMenu, toast, Loading } from 'ui'
import { CompanyWorker } from 'api/worker'
import { WorkerCardContent } from '../WorkersPage/WorkerCardContent'
import {
  useInvalidateRosterDetail,
  useRemoveWorkerFromRosterMutation,
  useUpdateWorkerStatusForCompanyMutation,
} from 'queries/worker'

interface Props {
  rosterId: number
  data: CompanyWorker
}

export function WorkerCard(props: Props) {
  const { rosterId, data } = props
  const { invalidate: invalidateRoster } = useInvalidateRosterDetail(rosterId)
  const { mutate: removeWorker, isPending: isRemoving } =
    useRemoveWorkerFromRosterMutation({
      rosterId,
      onSuccess: () => {
        invalidateRoster()
        toast.success(`Successfully removed ${data.name} from list`)
      },
      onError: (err) => {
        toast.error(err.message)
      },
    })
  const { mutate: updateStatus, isPending: isUpdatingStatus } =
    useUpdateWorkerStatusForCompanyMutation({
      workerId: Number(data.id),
      onSuccess: () => {
        invalidateRoster()
        toast.success(`Successfully updated ${data.name}'s status`)
      },
      onError: (err) => {
        toast.error(err.message)
      },
    })

  const handleRemoveWorker = () => {
    removeWorker(data.id)
  }

  return (
    <Flex
      py={3}
      alignItems={'center'}
      borderBottomStyle={'solid'}
      borderBottomWidth={1}
      borderBottomColor={'neutrals.200'}
    >
      <WorkerCardContent data={data} />
      {isRemoving || isUpdatingStatus ? (
        <Loading type={'button'} />
      ) : (
        <DropdownMenu>
          <DropdownMenu.Item onSelect={handleRemoveWorker}>
            Remove from List
          </DropdownMenu.Item>
          <DropdownMenu.Separator />
          {!data.statusForCompany ? (
            <>
              <DropdownMenu.Item
                onSelect={() => updateStatus({ action: 'favorite' })}
              >
                Favorite
              </DropdownMenu.Item>
              <DropdownMenu.Item
                onSelect={() => updateStatus({ action: 'block' })}
              >
                Block
              </DropdownMenu.Item>
            </>
          ) : null}
          {data.statusForCompany === 'favorite' ? (
            <DropdownMenu.Item
              onSelect={() => updateStatus({ action: 'unfavorite' })}
            >
              Unfavorite
            </DropdownMenu.Item>
          ) : null}
          {data.statusForCompany === 'blocked' ? (
            <DropdownMenu.Item
              onSelect={() => updateStatus({ action: 'unblock' })}
            >
              Unblock
            </DropdownMenu.Item>
          ) : null}
        </DropdownMenu>
      )}
    </Flex>
  )
}
