import { useMutation } from '@tanstack/react-query'
import {
  getAuth,
  getMultiFactorResolver,
  PhoneAuthProvider,
  ApplicationVerifier,
  MultiFactorError,
  AuthError,
} from 'firebase/auth'

export function useSendMFACodeMutation(options: {
  onSuccess?: (verificationId: string) => void
  onError?: (err: AuthError) => void
  multiFactorError: MultiFactorError
}) {
  const { multiFactorError, ...otherOptions } = options
  return useMutation({
    mutationFn: async (data: { verifier: ApplicationVerifier }) => {
      const auth = getAuth()
      const resolver = getMultiFactorResolver(auth, multiFactorError)
      const phoneAuthProvider = new PhoneAuthProvider(auth)
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        {
          multiFactorHint: resolver.hints[0],
          session: resolver.session,
        },
        data.verifier
      )
      return verificationId
    },
    ...otherOptions,
  })
}
