import React from 'react'
import { Page } from '../layout'
import { Box, Flex } from 'ui'
import { WorkerDetails } from './WorkerDetails'
import { WorkerProfileProvider } from './WorkerProfileProvider'
import { ScheduleOverview } from './ScheduleOverview'

export function WorkerProfilePage() {
  return (
    <WorkerProfileProvider>
      <Page alternateBackground={true} minHeight={'100vh'}>
        <Flex flexWrap={'wrap'}>
          <Box width={[1, 1, 1, 3 / 5]}>
            <WorkerDetails />
          </Box>
          <Box width={[1, 1, 1, 2 / 5]} pl={[0, 0, 0, 5]} pt={[4, 6]}>
            <ScheduleOverview />
          </Box>
        </Flex>
      </Page>
    </WorkerProfileProvider>
  )
}
