import {
  LegacyDeclinedWorkStatuses,
  DeclinedWorkStatuses,
  ShiftEditData,
  TimelineShift,
  WorkStatusEnum,
} from 'api/shift'
import { useMemo } from 'react'
import { differenceInMinutes } from 'date-fns'
import { repeat } from 'ramda'
import { User, Work } from 'typings/common_defs'
import { useAuthContext } from 'components/auth'
import { isRSWWorkerAvailabilityEnabled } from 'lib/flags'

export function useWorkerStats(shift: TimelineShift | ShiftEditData) {
  const { company } = useAuthContext()
  const isRSW = (shift.rosters || []).some((r) => r.isSpecificWorkerRequest)
  const rosterWorkers = useMemo(() => {
    const rosters = shift.rosters || []
    return rosters.reduce((acc, roster) => {
      if (roster) {
        return [...acc, ...(roster.workers || [])]
      }
      return acc
    }, [] as Array<Pick<User, 'id' | 'name' | 'profilePicUrl'>>)
  }, [shift])

  const workers = useMemo(() => {
    const work = shift.work || []
    return work.filter(
      (w) =>
        w.status &&
        [
          WorkStatusEnum.Completed,
          WorkStatusEnum.Scheduled,
          WorkStatusEnum.Started,
          WorkStatusEnum.Approved,
          WorkStatusEnum.EmployerApproved,
          WorkStatusEnum.AdminReview,
          WorkStatusEnum.EmployerReview,
          WorkStatusEnum.Paid,
        ].includes(w.status) &&
        w.worker
    )
  }, [shift])

  const workerStats = useMemo(() => {
    const shouldShowConfirmed =
      differenceInMinutes(new Date(shift.startsAt), new Date()) > 15
    let confirmed = 0
    let clockedIn = 0
    const work = shift.work || []
    work.forEach((work) => {
      if (work.confirmedAt) {
        confirmed++
      }
      if (
        work.startedAt &&
        work.status === WorkStatusEnum.Started &&
        !work.supervisorMarkedNoShow
      ) {
        clockedIn++
      }
    })

    const rosterWorkerIds = rosterWorkers.map((w) => Number(w.id))
    const unableToWork = shift.unableToAttendWork || []
    let declined = 0
    const declinedStatusesToUse = isRSWWorkerAvailabilityEnabled(company)
      ? DeclinedWorkStatuses
      : LegacyDeclinedWorkStatuses
    unableToWork.forEach((work) => {
      if (
        work.status &&
        work.worker &&
        declinedStatusesToUse.includes(work.status as WorkStatusEnum) &&
        rosterWorkerIds.includes(Number(work.worker.id))
      ) {
        declined++
      }
    })

    return {
      shouldShowConfirmed,
      confirmed,
      clockedIn,
      declined,
    }
  }, [shift, rosterWorkers])

  const workersNeeded = shift.workersNeeded ?? 0
  const totalWorkers = [
    ...workers,
    ...(workersNeeded > workers.length
      ? (repeat({}, workersNeeded - workers.length) as Work[])
      : []),
  ]

  return {
    isRSW,
    workers,
    workersNeeded,
    totalWorkers,
    extraWorkers: workers.length - workersNeeded,
    hasWorkers: workers.length > 0,
    ...workerStats,
  }
}
