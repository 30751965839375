import React from 'react'
import { User } from '../../../typings/common_defs'
import { getMinimumPayText } from '../../../lib/payment'
import { Tooltip } from 'ui'
import { Info } from 'lucide-react'
import { useShiftDetail } from '../useShiftDetail'

interface Props {
  user?: User
}

export const ShiftPayExplanationTag = ({ user }: Props) => {
  const { shift } = useShiftDetail()
  if (!shift) {
    return null
  }
  if (!user) {
    return null
  }
  const minimumPayText = getMinimumPayText(user, shift)
  return (
    <Tooltip content={minimumPayText}>
      <Info color={'infos.200'} />
    </Tooltip>
  )
}

export default ShiftPayExplanationTag
