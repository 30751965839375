import { api, graphql } from 'api'
import { path } from 'ramda'

export interface BaseShiftSubmissionRequest {
  position_id?: string | number
  location_id?: string | number
  supervisors: Array<{
    user_id?: number
    name: string
    phone_number?: string | null
    email?: string
    role_perm_id?: number
    request_type: string
  }>
  locationless: boolean
  pay_rate?: string
  pay_lump_sum?: string
  bonus?: number
  specific_worker_ids_to_request?: Array<number>
  roster_ids?: Array<number>
  roster_perm_hold?: boolean
  is_tryout?: boolean
  assignment_name?: string
}

export interface NewShiftSubmissionRequest extends BaseShiftSubmissionRequest {
  shifts: Array<{
    starts_at: string
    ends_at: string
    workers_needed: number
    lunch_length?: number
    is_orientation?: boolean
  }>
}

export interface EditShiftSubmissionRequest extends BaseShiftSubmissionRequest {
  starts_at: string
  ends_at: string
  workers_needed: number
  lunch_length?: number
}

export type ShiftSubmissionRequest =
  | NewShiftSubmissionRequest
  | EditShiftSubmissionRequest

export async function createShift(
  companyId: number,
  shiftData: ShiftSubmissionRequest
) {
  const data = await api.post(`/company/${companyId}/shifts`, shiftData)
  const [shiftId] = data.data as number[]
  const shiftDetail = await graphql(
    `company {
          shiftsDetail(ids: [${shiftId}]) {
            startsAt
          }
        }`,
    'shift_detail'
  )
  return new Date(
    path(
      ['data', 'data', 'company', 'shiftsDetail', 0, 'startsAt'],
      shiftDetail
    )
  )
}

export async function createPastShift(
  companyId: number,
  shiftData: ShiftSubmissionRequest
) {
  await api.post(`/company/${companyId}/pastshift`, shiftData)
}

export async function updateShift(
  companyId: number,
  shiftId: number,
  shiftData: ShiftSubmissionRequest
) {
  await api.put(`/company/${companyId}/shifts/${shiftId}`, shiftData)
}

export async function cancelShift(companyId: number, shiftId: number) {
  await api.post(`/company/${companyId}/cancel/${shiftId}`)
}

export async function addShiftBonus(
  companyId: number,
  shiftId: number,
  bonus: number
) {
  await api.put(`/company/${companyId}/shifts/${shiftId}/addbonus`, { bonus })
}

export async function updateShiftRosterHold(
  companyId: number,
  shiftId: number,
  holdType: 'permanent' | 'temporary'
) {
  await api.put(`/company/${companyId}/shifts/${shiftId}/roster_hold`, {
    new_roster_hold: holdType,
  })
}

export interface BackupShiftRequest {
  starts_at: string
  ends_at: string
  bonus?: number
  lunch_length?: number
}

export async function postBackupShift(
  companyId: number,
  shiftId: number,
  data: BackupShiftRequest
) {
  await api.post(`/company/${companyId}/shifts/${shiftId}/backup`, data)
}
