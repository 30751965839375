import React, { Fragment } from 'react'
import { Box, Button, Flex, Heading, Loading, Text } from 'ui'
import { WorkerCard } from './WorkerCard'
import { InviteWorkers } from './InviteWorkers'
import { ScheduleShiftModal } from './ScheduleShiftModal'
import { useWorkerSearch } from './useWorkerSearch'
import { SearchBar } from './SearchBar'
import { WorkerEmptyState } from 'pages/WorkersPage/WorkerEmptyState'

export function WorkerSection() {
  const {
    searchResult,
    searchKeyword,
    isSearching,
    hasNextPage,
    fetchNextPage,
  } = useWorkerSearch()

  return (
    <Box mt={4}>
      <Flex alignItems={'center'}>
        <Heading level={3}>Workers</Heading>
        <Box flex={1} />
        <ScheduleShiftModal />
      </Flex>
      <Flex flexWrap={'wrap'}>
        <Box width={[1, 1, 1, 3 / 5]} pr={[0, 0, 0, 2, 3]}>
          <SearchBar />
          {isSearching ? <Loading /> : null}
          {searchResult ? (
            <Box mt={4}>
              {searchResult.pages.map((page, pageIdx) => (
                <Fragment key={pageIdx}>
                  {page.items.map((worker) => (
                    <WorkerCard key={worker.id} data={worker} />
                  ))}
                </Fragment>
              ))}
            </Box>
          ) : null}
          {searchResult && searchResult.pages[0].items.length === 0 ? (
            searchKeyword ? (
              <Text color={'neutrals.200'}>No workers found.</Text>
            ) : (
              <WorkerEmptyState />
            )
          ) : null}
          {hasNextPage ? (
            <Button
              loading={isSearching}
              variant={'text'}
              onClick={() => fetchNextPage()}
            >
              See More
            </Button>
          ) : null}
        </Box>
        <Box width={[1, 1, 1, 2 / 5]} pl={[0, 0, 0, 3, 4]} mt={[4, 4, 4, 0]}>
          <InviteWorkers />
        </Box>
      </Flex>
    </Box>
  )
}
