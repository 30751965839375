import { Box } from '@workwhile/ui'
import styled from 'styled-components'

export const ImagePreview = styled(Box).attrs({
  borderRadius: 'standard',
  width: 56,
  height: 56,
})`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`
