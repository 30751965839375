import { api } from 'api'
import { toSnakecaseKeys } from 'lib/string'

export interface Feedback {
  workerId: number
  shiftId?: number
  comment?: string
  tags?: string[]
}

export function sendFeedback(companyId: number, feedbackItems: Feedback[]) {
  return api.post(
    `company/${companyId}/feedback`,
    feedbackItems.map((feedback) => toSnakecaseKeys(feedback))
  )
}
