import React from 'react'
import {
  Box,
  Button,
  Card,
  DisplayIcon,
  Flex,
  Heading,
  Loading,
  Text,
} from 'ui'
import { CalendarIcon } from 'lucide-react'
import { useSchedule } from './useSchedule'
import { ShiftSummary } from './ShiftSummary'
import { useWorkerProfile } from './useWorkerProfile'
import { ModalLink } from 'components/ModalLink'

export function ScheduleOverview() {
  const { profileData } = useWorkerProfile()
  const { upcoming, completed, isLoading } = useSchedule(profileData.worker.id)

  return (
    <Card shadowless={true} borderWidth={0} borderRadius={'large'}>
      <Box display={'inline-flex'} alignItems={'center'}>
        <DisplayIcon>
          <CalendarIcon size={16} />
        </DisplayIcon>
        <Heading level={4} my={0} ml={2}>
          Schedule
        </Heading>
      </Box>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Text fontWeight={2} mt={3}>
            Upcoming
          </Text>
          {upcoming.length > 0
            ? upcoming
                .slice(0, 3)
                .map((shift) => (
                  <ShiftSummary
                    key={shift.shift.id}
                    shift={shift.shift}
                    status={shift.status}
                  />
                ))
            : 'No upcoming shifts'}
          {upcoming.length > 3 ? (
            <Box pl={3} mt={2}>
              +{upcoming.length - 3} more
            </Box>
          ) : null}
          <Text fontWeight={2} mt={3}>
            Completed
          </Text>
          {completed.length > 0
            ? completed
                .slice(0, 3)
                .map((shift) => (
                  <ShiftSummary
                    key={shift.shift.id}
                    shift={shift.shift}
                    status={shift.status}
                  />
                ))
            : 'No recent shifts'}
          {completed.length > 3 ? (
            <Box pl={3} mt={2}>
              +{completed.length - 3} more
            </Box>
          ) : null}
          <Flex justifyContent={'center'} my={4}>
            <ModalLink to={`/workers/${profileData.worker.id}/shifts`}>
              <Button variant={'secondary'} kind={'medium'}>
                View All Shifts
              </Button>
            </ModalLink>
          </Flex>
        </>
      )}
    </Card>
  )
}
