import { useFormContext } from 'react-hook-form'
import { useMemo } from 'react'
import { PartialShiftSupervisor } from '../../schemas'

export function useSupervisors() {
  const { watch, setValue } = useFormContext()
  const supervisors = watch('supervisors') || []

  const values = useMemo(() => {
    return {
      supervisors,
      addSupervisor: (supervisor: PartialShiftSupervisor) => {
        setValue('supervisors', [...supervisors, supervisor], {
          shouldValidate: false,
        })
      },
      removeSupervisor: (id: number) => {
        setValue(
          'supervisors',
          supervisors.filter((s) => s.id !== id),
          { shouldValidate: true }
        )
      },
    }
  }, [supervisors])

  return values
}
