import { track } from 'lib/amplitude'
import { useShiftDetail } from 'pages/ShiftDetailPage/useShiftDetail'
import React, { useEffect, useState, FormEvent } from 'react'
import { Work } from 'typings/common_defs'
import { Button, Heading, Modal, Textarea, Text, toast } from 'ui'
import { useSendMessageMutation } from 'queries/message'
import { toTitleCase } from 'lib/string'

interface Props {
  work: Work
  open: boolean
  onClose: () => void
}

export const MessageWorkerModal = ({ open, onClose, work }: Props) => {
  const { shift } = useShiftDetail()
  const key = 'send_message'
  const [message, setMessage] = useState('')

  useEffect(() => {
    // componentDidMount
    track(`impression, ${key}`)
  }, [])

  const { mutate: sendMessage, isPending: isSendingMessage } =
    useSendMessageMutation({
      onSuccess: () => {
        toast.success(
          `Message sent to ${toTitleCase(work?.worker?.name || 'worker')}.`
        )
        setMessage('')
        onClose()
      },
    })

  const handleSubmit = (e: FormEvent) => {
    track(`button_click, ${key}`)
    e.preventDefault()
    if (shift?.id && work?.worker?.id && message.trim().length > 0) {
      sendMessage({
        message: message.trim(),
        shiftId: shift.id,
        toUserIds: [work?.worker?.id],
      })
    }
  }

  if (!shift || !work) return null

  return (
    <Modal
      width={[1, 900]}
      open={open}
      onClose={onClose}
      customCta={
        <Button
          kind="medium"
          variant="primary"
          onClick={handleSubmit}
          disabled={isSendingMessage}
          loading={isSendingMessage}
        >
          Send
        </Button>
      }
    >
      <Heading level={3}>
        Send a text message to {toTitleCase(work?.worker?.name || 'worker')}
      </Heading>
      <Text mb={3}>You will be notified via email if you get a response.</Text>
      <Textarea
        value={message}
        placeholder="Write your message here"
        onChange={(e) => setMessage(e.target.value)}
        minRows={2}
      />
    </Modal>
  )
}
