import React, { useEffect, useState } from 'react'
import {
  Box,
  Field,
  Heading,
  Input,
  InputMask,
  Button,
  Link,
  Paragraph,
  Loading,
  toast,
} from 'ui'
import * as zod from 'zod'
import { useParams, useSearchParams } from 'react-router-dom'
import { PHONE_NUM } from 'lib/phone'
import { runOktaLogin } from 'lib/okta'

const schema = zod.object({
  name: zod.string().min(1, 'Name is required'),
  phoneNumber: zod.string().min(10, 'Phone number must contain 10 digits'),
})

export function OktaOnboardingForm() {
  const [searchParams] = useSearchParams()
  const params = useParams<{ token: string }>()
  const companyName = searchParams.get('company')

  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [loading, setLoading] = useState(false)

  const [errors, setErrors] = useState<{ name?: string; phoneNumber?: string }>(
    {}
  )

  useEffect(() => {
    const email = searchParams.get('email')
    if (!email) {
      return
    }
  }, [])

  const handleClick = () => {
    if (hasErrors()) {
      toast.error('Please fill out all fields')
      return
    }
    setLoading(true)
    runOktaLogin(
      searchParams.get('email') || '',
      params.token,
      phoneNumber,
      name
    )
  }

  const hasErrors = () => {
    // Reset errors
    setErrors({})

    // Validate manually based on the schema
    const result = schema.safeParse({ name, phoneNumber })
    if (!result.success) {
      const newErrors = {}
      result.error.issues.forEach((issue) => {
        newErrors[issue.path[0]] = issue.message
      })
      setErrors(newErrors)
      return true
    }
    return false
  }

  if (loading) {
    return (
      <Box margin={'auto'}>
        <Loading />
      </Box>
    )
  }

  return (
    <Box margin={'auto'} pb={4} width={[1, 450]}>
      <Heading level={2}>Set Up Your Account</Heading>
      <Paragraph>
        You've been invited to join your team's WorkWhile account. Please setup
        your login credentials below.
      </Paragraph>
      {companyName ? (
        <Heading level={4} my={0} mt={4} mb={3}>
          {companyName}
        </Heading>
      ) : null}
      <Field label={'Full Name'} error={errors.name?.toString()}>
        <Input
          autoFocus={true}
          placeholder={'e.g. Jane Doe'}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Field>
      <Field label={'Phone Number'} error={errors.phoneNumber?.toString()}>
        <InputMask
          autoComplete={'off'}
          placeholder={'e.g. 555-555-5555'}
          mask={PHONE_NUM.mask}
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </Field>
      <Button
        block={true}
        size={'large'}
        onClick={handleClick}
        loading={loading}
      >
        Sign in with Okta
      </Button>
      <Box mt={4}>
        By clicking "Sign Up", you agree to WorkWhile's&nbsp;
        <Link
          to={'https://www.workwhilejobs.com/customer_agreement'}
          target={'_blank'}
        >
          Services Agreement
        </Link>
        .
      </Box>
    </Box>
  )
}
