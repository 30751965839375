import React, { useMemo, useState } from 'react'
import {
  Box,
  Field,
  Heading,
  Input,
  Loading,
  Message,
  Modal,
  Text,
  Textarea,
  toast,
} from 'ui'
import * as zod from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useWorkerLogs } from 'queries/worker/useWorkerLogs'
import { addDays, endOfDay, startOfDay } from 'date-fns'
import { LogEventType } from 'api/company'
import { BonusLog } from 'components/GiveBonusModal/BonusLog'
import { useGiveWorkerBonusMutation } from 'queries/worker/useGiveWorkerBonusMutation'

const schema = zod.object({
  amount: zod.coerce
    .number()
    .positive({ message: 'Please enter a valid bonus amount' }),
  reason: zod
    .string()
    .min(1, { message: 'Please enter a reason for the bonus' }),
})

type FormValues = zod.infer<typeof schema>

interface Props {
  open: boolean
  workerId: number
  workerName?: string
  onClose: () => void
  onSubmitted?: () => void
}

export function GiveBonusModal(props: Props) {
  const { workerName, workerId, open, onClose, onSubmitted } = props
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
  })
  const { startDate, endDate } = useMemo(() => {
    const now = new Date()
    return {
      startDate: startOfDay(addDays(now, -30)),
      endDate: endOfDay(now),
    }
  }, [])
  const { data: logData, isLoading } = useWorkerLogs({
    userId: workerId,
    startDate,
    endDate,
  })
  const bonusLogs = useMemo(() => {
    if (!logData) return []
    return logData.filter(
      (log) => log.eventType === LogEventType.CompanyIssuedBonus
    )
  }, [logData])
  const [error, setError] = useState<Error | null>(null)
  const { mutate: giveBonus, isPending: isSubmittingBonus } =
    useGiveWorkerBonusMutation({
      onSuccess: () => {
        toast.success(
          `Bonus successfully given to ${workerName || 'the worker'}`
        )
        onSubmitted?.()
        onClose()
      },
      onError: (error) => {
        setError(error)
      },
    })

  const onSubmit = handleSubmit((data: FormValues) => {
    giveBonus({
      workerId,
      amount: data.amount,
      reason: data.reason,
    })
  })

  return (
    <Modal
      open={open}
      loading={isSubmittingBonus}
      title={`Give ${workerName || 'the worker'} a Bonus`}
      onClose={onClose}
      okText={'Give Bonus'}
      onOk={() => onSubmit()}
    >
      <Text fontWeight={1} mb={3}>
        Worker will be paid the bonus in 24 hours
      </Text>
      <Field label={'Amount'} error={errors.amount?.message?.toString()}>
        <Input
          type={'number'}
          {...register('amount')}
          placeholder={'e.g. 20'}
        />
      </Field>
      <Field
        label={`Let ${workerName || 'the worker'} know what the bonus is for`}
        error={errors.reason?.message?.toString()}
      >
        <Textarea
          minRows={3}
          {...register('reason')}
          placeholder={
            'e.g. great work on Wed June 19th 8am shift\ne.g. overtime for Thu June 20th 4pm shift'
          }
        />
      </Field>
      {error ? <Message variant={'error'}>{error.message}</Message> : null}
      <Box>
        <Heading level={4} my={0}>
          Recent Bonuses
        </Heading>
        <Text>
          Bonuses paid by your company to this worker in the last 30 days.
        </Text>
        {isLoading ? (
          <Loading />
        ) : bonusLogs.length > 0 ? (
          <Box mt={2}>
            {bonusLogs.map((log, index) => (
              <BonusLog key={index} data={log} />
            ))}
          </Box>
        ) : (
          <Text mt={3} color={'lightText'}>
            No bonuses found.
          </Text>
        )}
      </Box>
    </Modal>
  )
}
