import React from 'react'
import { BlockModalProps, BlockModalType } from './types'
import { Avatar, Button, Flex, Modal, Paragraph } from 'ui'

export function NewWorker(props: BlockModalProps) {
  const { setModalType, worker } = props

  return (
    <Modal
      title={`Are you sure you want to block this new worker, ${worker.name}?`}
      open={true}
      onClose={() => setModalType(null)}
      customCta={
        <>
          <Button
            variant={'text'}
            onClick={() => setModalType(BlockModalType.FillRate)}
          >
            Block Worker
          </Button>
          <Button onClick={() => setModalType(BlockModalType.Feedback)}>
            Give Feedback
          </Button>
        </>
      }
    >
      <Flex justifyContent={'center'}>
        <Avatar
          user={{
            userId: worker.id.toString(),
            name: worker.name,
            photoUrl: worker.profilePicUrl,
          }}
          size={'xxlarge'}
        />
      </Flex>
      <Paragraph>
        This is {worker.name}'s first time working for you. We recommend
        providing them with <strong>feedback</strong> and giving them another
        chance.
      </Paragraph>
    </Modal>
  )
}
