import { useQuery, useQueryClient } from '@tanstack/react-query'
import { reviewEvaluateKey } from './keys'
import { getReviewEvaluateShifts } from 'api/review'

export function useReviewEvaluateShifts(options?: { enabled?: boolean }) {
  return useQuery({
    queryKey: reviewEvaluateKey,
    queryFn: getReviewEvaluateShifts,
    ...options,
  })
}

export function useInvalidateReviewEvaluateShifts() {
  const queryClient = useQueryClient()

  return {
    invalidate: () =>
      queryClient.invalidateQueries({
        queryKey: reviewEvaluateKey,
      }),
  }
}
