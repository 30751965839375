import { Box } from 'ui'
import StarFilledAsset from 'assets/star-filled.svg'
import React from 'react'

export function StarRating(props: { rating?: number }) {
  const { rating } = props
  if (!rating) {
    return '-'
  }

  return (
    <Box display={'inline-flex'} alignItems={'center'}>
      <img width={20} src={StarFilledAsset} alt={'star rating'} />
      &nbsp;
      {rating.toFixed(2)}
    </Box>
  )
}
