import { useQuery, useQueryClient } from '@tanstack/react-query'
import { positionTutorialKey } from './keys'
import { getPositionTutorials } from 'api/company'

export function useCompanyPositionTutorials(positionId: number) {
  return useQuery({
    queryKey: positionTutorialKey(positionId),
    queryFn: () => getPositionTutorials(positionId),
  })
}

export function useInvalidatePositionTutorials() {
  const queryClient = useQueryClient()
  return function invalidateTutorials(positionId: number) {
    return queryClient.invalidateQueries({
      queryKey: positionTutorialKey(positionId),
    })
  }
}
