import React from 'react'
import { CompanyRoster } from 'api/worker'
import { Box, Card, Heading, Text, Pluralize, Link } from 'ui'
import { UsersIcon } from 'lucide-react'

export function RosterCard(props: { data: CompanyRoster }) {
  const { data } = props

  return (
    <Card as={Link} to={`/workers/list/${data.id}`} borderWidth={0}>
      <Heading level={4} my={0} mb={2} maxLines={1}>
        {data.name}
      </Heading>
      <Box display={'inline-flex'} alignItems={'center'}>
        <UsersIcon size={16} />{' '}
        <Text ml={2}>
          <Pluralize
            count={data.workers.length}
            oneText={'worker'}
            manyText={'workers'}
          />
        </Text>
      </Box>
    </Card>
  )
}
