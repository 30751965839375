import React, { useMemo, useState } from 'react'
import {
  Box,
  Button,
  Heading,
  Loading,
  Table,
  Text,
  useResponsiveValue,
  createColumnHelper,
  Flex,
  ProgressBar,
} from 'ui'
import { useChargeLimits } from 'queries/payment'
import { SpendLimitModal } from './SpendLimitModal'
import { formatCurrency } from 'lib/string'
import { BonusLimitModal } from './BonusLimitModal'

const columnHelper = createColumnHelper<{
  type: string
  label: string
  limit?: string
  current?: string
}>()

export function ChargeLimits() {
  const [showSpendLimit, setShowSpendLimit] = useState(false)
  const [showBonusLimit, setShowBonusLimit] = useState(false)
  const columnsToUse = useResponsiveValue([2, 3])
  const { data, isLoading } = useChargeLimits()
  const tableData = useMemo(() => {
    if (!data) return null

    return [
      {
        type: 'spend',
        label: 'Spend Limit',
        limit: data?.monthlyChargeLimit.spendLimit,
        current: data?.monthTotalCharge.monthTotalSpend,
      },
      {
        type: 'bonus',
        label: 'Bonus Limit',
        limit: data?.monthlyChargeLimit.bonusLimit,
        current: data?.monthTotalCharge.monthTotalBonus,
      },
    ]
  }, [data])

  const handleSetUpLimit = (type: string) => {
    if (type === 'spend') {
      setShowSpendLimit(true)
    }

    if (type === 'bonus') {
      setShowBonusLimit(true)
    }
  }

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('label', {
        size: 200,
        cell: (info) => (
          <Box>
            {info.getValue()}
            <Box display={['block', 'none']} my={2}>
              <Button
                width={120}
                kind={'small'}
                onClick={() => handleSetUpLimit(info.row.original.type)}
              >
                {info.row.original.limit ? 'Edit' : 'Set Up'}
              </Button>
            </Box>
          </Box>
        ),
      }),
      columnHelper.accessor('limit', {
        cell: (info) => {
          const limit = info.getValue()
          if (!limit) {
            return (
              <Text color={'neutrals.200'}>
                Set up your monthly {info.row.original.type} limit
              </Text>
            )
          }

          const current = info.row.original.current
            ? Number(info.row.original.current)
            : 0
          const limitValue = info.row.original.limit
            ? Number(info.row.original.limit)
            : 0

          return (
            <Flex flexDirection={'row'} flexWrap={'wrap'} alignItems={'center'}>
              <ProgressBar width={[1, 170]} value={current} max={limitValue} />
              <Box ml={[0, 2]} mt={[2, 0]}>
                <strong>{formatCurrency(current)}</strong> of{' '}
                {formatCurrency(limitValue)}
              </Box>
            </Flex>
          )
        },
      }),
      columnHelper.accessor('type', {
        size: 120,
        cell: (info) => (
          <Button
            width={120}
            kind={'small'}
            onClick={() => handleSetUpLimit(info.row.original.type)}
          >
            {info.row.original.limit ? 'Edit' : 'Set Up'}
          </Button>
        ),
      }),
    ]
  }, [])

  return (
    <Box mt={5}>
      <Heading level={3}>Monthly Spend & Bonus Limits</Heading>
      {isLoading ? <Loading /> : null}
      {!isLoading && tableData ? (
        <Box
          py={2}
          borderStyle={'solid'}
          borderColor={'neutrals.100'}
          borderTopWidth={1}
          borderBottomWidth={1}
        >
          <Table
            variant={'borderless'}
            columns={columns.slice(0, columnsToUse)}
            data={tableData}
          ></Table>
        </Box>
      ) : null}
      <SpendLimitModal
        open={showSpendLimit}
        onClose={() => setShowSpendLimit(false)}
      />
      <BonusLimitModal
        open={showBonusLimit}
        onClose={() => setShowBonusLimit(false)}
      />
    </Box>
  )
}
