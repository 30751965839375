import styled from 'styled-components'

export const Overlay = styled.div<{ $zIndex?: number }>`
  ${({ theme, $zIndex = 10001 }) => `
    position: fixed;
    z-index: ${$zIndex};
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${theme.colors.overlay};
  `}
`
