import React from 'react'
import { Page } from '../layout'
import { Box, Tabs } from 'ui'
import { OutstandingInvoices } from './OutstandingInvoices'
import { PaidInvoices } from './PaidInvoices'
import { ChargeAccounts } from './ChargeAccounts'
import { useCompanyInfo } from 'queries/company'
import { ChargeLimits } from './ChargeLimits'
import { useSearchParams } from 'react-router-dom'

export function PaymentPage() {
  const { data: company } = useCompanyInfo()
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedTab = searchParams.get('type') ?? 'outstanding'

  return (
    <Page title={'Payment'}>
      <Box mt={[0, 1, 3]} mb={[2, 4]}>
        <Tabs
          value={selectedTab}
          onValueChange={(value) => setSearchParams({ type: value })}
          tabs={[
            {
              value: 'outstanding',
              label: company?.chargeUpfront
                ? 'Pending Invoices'
                : 'Outstanding Invoices',
            },
            {
              value: 'paid',
              label: 'Past Invoices',
            },
          ]}
        >
          <Tabs.Content value={'outstanding'}>
            <OutstandingInvoices />
          </Tabs.Content>
          <Tabs.Content value={'paid'}>
            <PaidInvoices />
          </Tabs.Content>
        </Tabs>
      </Box>
      <ChargeAccounts />
      <ChargeLimits />
    </Page>
  )
}
