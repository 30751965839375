import { WorkerProfileShift } from 'api/worker'
import { formatInTimeZone } from 'date-fns-tz'
import { Flex, Box, Text, Button } from 'ui'
import React from 'react'
import { getLocalTimezone } from 'lib/time'
import { WorkStatusEnum } from 'api/shift'
import { useNavigate } from 'react-router'

export function ShiftSummary(props: {
  shift: WorkerProfileShift
  status: string
}) {
  const { shift, status } = props
  const localTimezone = getLocalTimezone()
  const navigate = useNavigate()

  return (
    <Flex
      flexDirection={'row'}
      alignItems={'center'}
      my={2}
      fontSize={1}
      px={3}
    >
      <Box flex={1}>
        <Text>
          <strong>
            {formatInTimeZone(
              shift.startsAt,
              shift.location.address?.timezone || localTimezone,
              'MMM dd'
            )}
          </strong>
          &nbsp;-&nbsp;
          {formatInTimeZone(
            shift.startsAt,
            shift.location.address?.timezone || localTimezone,
            'h:mmaaa'
          )}
          &nbsp;-&nbsp;
          {shift.endsAt
            ? formatInTimeZone(
                shift.endsAt,
                shift.location.address?.timezone || localTimezone,
                'h:mmaaa zzz'
              )
            : null}
        </Text>
        <Text>
          {shift.position.name}, {shift.location.name}
        </Text>
      </Box>
      {status === WorkStatusEnum.EmployerReview ? (
        <Button
          variant={'text'}
          kind={'small'}
          onClick={() => navigate(`/shift/${shift.id}`)}
        >
          Review
        </Button>
      ) : null}
    </Flex>
  )
}
