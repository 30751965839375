import { useQuery, useQueryClient } from '@tanstack/react-query'
import { companyInfoKey } from './keys'
import { getCurrentCompanyInfo } from 'api/company'
import { useAuthContext } from 'components/auth'

// DEPRECATED: Use const {company} = useAuthContext() instead
export function useCompanyInfo(options?: { enabled?: boolean }) {
  const { isAuthenticated } = useAuthContext()
  return useQuery({
    queryKey: companyInfoKey,
    queryFn: getCurrentCompanyInfo,
    gcTime: Infinity, // the only time this would change is if current company changes
    staleTime: Infinity,
    enabled: isAuthenticated,
    ...options,
  })
}

export function useInvalidateCompanyInfo() {
  const queryClient = useQueryClient()

  return {
    invalidate: () =>
      queryClient.invalidateQueries({
        queryKey: companyInfoKey,
      }),
  }
}
