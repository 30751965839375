import OktaAuth from '@okta/okta-auth-js'
import { getOktaAuthParams } from 'api/auth/okta'
import { OktaParams } from 'components/auth/types'
import { toast } from '@workwhile/ui'
import { track } from './amplitude'

export interface OktaData {
  baseUrl: string
  clientId: string
  inviteCode?: string
  email: string
  phoneNumber?: string
  name?: string
  type: 'login' | 'signup'
}

export const runOktaLogin = (
  email: string,
  inviteCode?: string,
  phoneNumber?: string,
  name?: string
) => {
  // TODO - check if email is valid
  track('impression, okta: run login')
  getOktaAuthParams(email)
    .then((response) => {
      track('impression, okta: loaded auth params')
      goToOkta(response, email, inviteCode, phoneNumber, name)
    })
    .catch((error) => {
      toast.error('Something went wrong: ' + error.message)
    })
}

const goToOkta = (
  oktaParams: OktaParams,
  email: string,
  inviteCode?: string,
  phoneNumber?: string,
  name?: string
) => {
  const oktaAuthConfig = {
    issuer: oktaParams.baseUrl,
    clientId: oktaParams.clientId,
    redirectUri: window.location.origin + '/okta_callback',
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
  }
  const oktaAuth = new OktaAuth(oktaAuthConfig)

  const oktaData: OktaData = {
    clientId: oktaParams.clientId,
    baseUrl: oktaParams.baseUrl,
    email: email,
    inviteCode: inviteCode,
    phoneNumber: phoneNumber,
    name: name,
    type: inviteCode ? 'signup' : 'login',
  }

  localStorage.setItem('oktaData', JSON.stringify(oktaData))

  oktaAuth.signInWithRedirect()
}
