import { useMutation } from '@tanstack/react-query'
import { switchCompany } from 'api/company'
import { useCompanyInfo } from './useCompanyInfo'

export function useSwitchCompanyMutation() {
  const { data: company } = useCompanyInfo()

  return useMutation({
    mutationFn: (newCompanyId: number) => {
      if (!company) {
        throw new Error('Company not found')
      }

      return switchCompany(company.id, newCompanyId)
    },
    onSuccess: () => {
      window.location.replace('/home')
    },
  })
}
