import React from 'react'
import { Box, Heading, Text } from 'ui'
import { WorkerBenchStats } from './WorkerBenchStats'
import { TopWorkers } from './TopWorkers'

export function WorkerBench() {
  return (
    <Box
      borderRadius={'xlarge'}
      boxShadow={['medium', 'medium', 'medium', 'none']}
      p={4}
      mt={[4, 4, 4, 0]}
    >
      <Heading level={3} my={0}>
        Worker Bench
      </Heading>
      <Text color={'lightText'} my={2}>
        Leaderboard based on shifts worked
      </Text>
      <WorkerBenchStats />
      <hr />
      <TopWorkers />
    </Box>
  )
}
