import { api, graphql } from 'api'
import { path } from 'ramda'
import { Position, PositionTemplate, Roster } from 'typings/common_defs'
import {
  MarkdownContent,
  QuizQuestion,
  Tutorial,
  TutorialCategory,
} from 'api/shift/typings'
import { toSnakecaseKeys } from 'lib/string'

export const AllPositionTutorialCategories = [
  TutorialCategory.General,
  TutorialCategory.AttireAndGrooming,
]

export const sortTutorialsByCategory = (tutorials: Tutorial[]) => {
  return tutorials.sort((a: Tutorial, b: Tutorial) => {
    const aCategory = a?.category as TutorialCategory
    const bCategory = b?.category as TutorialCategory
    const aIndex = AllPositionTutorialCategories.indexOf(aCategory)
    const bIndex = AllPositionTutorialCategories.indexOf(bCategory)

    // If both categories are not in the predefined list (index = -1)
    // maintain their original order
    if (aIndex === -1 && bIndex === -1) return 0

    // If one category is not in the list, it should go after the listed categories
    if (aIndex === -1) return 1
    if (bIndex === -1) return -1

    // If both categories are in the list, sort by their index
    return aIndex - bIndex
  }) as Tutorial[]
}

export async function getCompanyPositions() {
  const data = await graphql(
    `company {
          positions {
            id, 
            name, 
            about, 
            hidden,
            positionTemplateId,
            requirements,
            requirements2 {
              id, category, name
            },
            tutorials {
              id,
              name,
              category,
              content,
              quiz
            }
          }
        }`,
    'current_company'
  )

  return path(['data', 'data', 'company', 'positions'], data) as Position[]
}

export interface SimplePositionTemplateGroup {
  id: number
  name: string
  positionTemplates: Array<{ id: number; name: string }>
}

export async function getPositionTemplateGroups() {
  const data = await graphql(
    `
        company {
          positionTemplateGroups {
            id,
            name
            positionTemplates {
              id,
              name
            }
          }
        }
  `,
    'position_template_groups'
  )

  const groups = path(
    ['data', 'data', 'company', 'positionTemplateGroups'],
    data
  ) as SimplePositionTemplateGroup[]

  return groups.map((group) => ({
    ...group,
    id: Number(group.id),
    positionTemplates: group.positionTemplates.map((template) => ({
      ...template,
      id: Number(template.id),
    })),
  }))
}

export async function getPositionTemplate(positionTemplateId: number) {
  const data = await graphql(
    `
        company {
          positionTemplates (positionTemplateId: ${positionTemplateId}) {
            id,
            name,
            requirements {
              isPreSelected,
              isWorkwhileRequired,
              requirement {
                id,
                category,
                name,
                photoUploadNeeded,
              }
            }
          }
        }
      `,
    'position_templates'
  )

  const template = path(
    ['data', 'data', 'company', 'positionTemplates', 0],
    data
  ) as PositionTemplate

  return template
    ? {
        ...template,
        id: Number(template.id),
      }
    : undefined
}

export async function getPositionRoster(positionId: number) {
  const data = await graphql(
    `
    company {
      positions (positionId: ${positionId}) {
        id,
        name,
        roster {
          id
          name
          workers {
            id
            name
            profilePicUrl    
          }
        }
      }
    }
    `,
    'position_roster'
  )

  const roster = path(
    ['data', 'data', 'company', 'positions', 0, 'roster'],
    data
  ) as Roster
  return roster
}

export async function getPositionTutorials(positionId: number) {
  const data = await graphql(
    `
    company {
      positions (positionId: ${positionId}) {
        id,
        name,
        tutorials {
          id,
          name,
          category,
          content,
          quiz
        }
      }
    }
    `,
    'position_tutorials'
  )

  const tutorials = path(
    ['data', 'data', 'company', 'positions', 0, 'tutorials'],
    data
  ).map(
    (
      tutorial: Omit<Tutorial, 'content' | 'quiz'> & {
        content?: string
        quiz?: string
      }
    ) => ({
      ...tutorial,
      content: tutorial.content ? JSON.parse(tutorial.content) : [],
      quiz: tutorial.quiz ? JSON.parse(tutorial.quiz) : tutorial.quiz,
    })
  )

  return sortTutorialsByCategory(tutorials)
}

export async function getPositionTutorial(
  positionId: number,
  tutorialId: number
) {
  const tutorials = await getPositionTutorials(positionId)
  return (tutorials || []).find(
    (tutorial) => tutorial.id.toString() === tutorialId.toString()
  )
}

export interface PositionRequest {
  id?: number
  name?: string | null
  about?: string | null
  positionTemplateId?: number
  workerRequirementIds?: Array<number>
  customRequirements?: Array<{ name?: string; category?: string }>
}

export async function createCompanyPosition(
  companyId: number,
  data: PositionRequest
) {
  const response = await api.post(
    `/company/${companyId}/position`,
    toSnakecaseKeys(data)
  )
  return path(['data', 'id'], response)
}

export async function updateCompanyPosition(
  companyId: number,
  positionId: number,
  data: PositionRequest
) {
  await api.put(
    `/company/${companyId}/position/${positionId}`,
    toSnakecaseKeys(data)
  )
}

export interface CreatePositionTutorialRequest {
  companyId: number
  positionId: number
  category: TutorialCategory
  content: MarkdownContent[]
  quiz?: QuizQuestion[]
  notifyWorkers?: boolean
}

export async function createPositionTutorial(
  request: CreatePositionTutorialRequest
): Promise<number> {
  const { companyId, positionId, category, content, quiz, notifyWorkers } =
    request
  const data = await api.post(
    `/company/${companyId}/position/${positionId}/tutorial`,
    {
      category,
      content,
      quiz,
      notify_workers: notifyWorkers,
    }
  )

  const tutorialId = path(['data', 'tutorial_id'], data)
  return tutorialId
}

export type EditPositionTutorialRequest = Omit<
  CreatePositionTutorialRequest,
  'category'
> & {
  tutorialId: number
}

export async function editPositionTutorial(
  request: EditPositionTutorialRequest
): Promise<number> {
  const { companyId, positionId, tutorialId, content, quiz, notifyWorkers } =
    request
  const data = await api.put(
    `/company/${companyId}/position/${positionId}/tutorial/${tutorialId}`,
    {
      content,
      quiz,
      notify_workers: notifyWorkers,
    }
  )
  return path(['data', 'id'], data)
}

export interface RemovePositionTutorialRequest {
  companyId: number
  positionId: number
  tutorialId: number
}

export async function removePositionTutorial(
  request: RemovePositionTutorialRequest
) {
  const { companyId, positionId, tutorialId } = request
  await api.delete(
    `/company/${companyId}/position/${positionId}/tutorial/${tutorialId}`
  )
}
