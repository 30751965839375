import React from 'react'
import { MdCalendarToday } from 'react-icons/md'
import styled from 'styled-components'
import { WorkShift } from '../../typings/common_defs'
import { newColors, newDimens } from '../core/constants'
import { TimelineShift } from 'api/shift'

interface Props {
  shift: WorkShift | TimelineShift
}

const TryoutsDayXofY = ({ shift }: Props) => {
  if (
    !shift ||
    !shift.bundleMetadata ||
    !shift.bundleMetadata.bundleIndex ||
    !shift.bundleMetadata.bundleSize ||
    shift.bundleMetadata.bundleSize === 0
  ) {
    return null
  }

  const { bundleIndex, bundleSize } = shift.bundleMetadata

  if (bundleSize === 1) {
    // if there's only 1 day in the Tryout don't show the day number
    return null
  }

  return (
    <Container>
      <MdCalendarToday />{' '}
      <span>
        Day {bundleIndex} of {bundleSize}
      </span>
    </Container>
  )
}

export default TryoutsDayXofY

const Container = styled.span`
  display: flex;
  align-items: center;
  gap: ${newDimens.xsmall}px;
  color: ${newColors.blackWithTransparency};
`
