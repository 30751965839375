import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz'
import { getShiftTimezone } from 'lib/shift'
import { useShiftDetail } from 'pages/ShiftDetailPage/useShiftDetail'
import React from 'react'
import { TimeRangeInput, TimeRangeInputValue } from 'ui'
import { useAssignedWorkRow } from '../useAssignedWorkerRow'
import { Work } from 'typings/common_defs'
import { addDays } from 'date-fns'
import { AssignedWorkerOptionsEnum } from 'lib/work/assigned_work_options'

interface Props {
  work: Work
}

export const AssignedWorkerTimeRangeInput = ({ work }: Props) => {
  const { shift } = useShiftDetail()
  const { data, setValue } = useAssignedWorkRow(work.id)

  if (!shift) return null
  if (!work?.worker?.id) return null
  const shifttz = getShiftTimezone(shift)

  const parseTimeRangeValueToUTCDate = (
    time: TimeRangeInputValue
  ): Date | null => {
    // Standardizes TimeRangeInputValue to a Date object in UTC
    if (time instanceof Date) {
      return zonedTimeToUtc(time, shifttz)
    } else if (typeof time === 'string') {
      const shiftDate = new Date(shift.startsAt)
      const timeParts = time.split(':')
      const inputDate = new Date(shiftDate)
      inputDate.setHours(parseInt(timeParts[0]), parseInt(timeParts[1]))

      return zonedTimeToUtc(inputDate, shifttz)
    }
    return null // If time is null or not a recognized type
  }

  const handleTimeRangeChange = (value: TimeRangeInputValue) => {
    if (!value) return
    const newStartedAt = value[0] || null
    const newCompletedAt = value[1] || null

    // values returned by TimeRangeInput are 24hr time strings (e.g. 19:00) in shift TZ.
    // To save to data state, we need to:
    // - convert to a datetime
    // - convert from shifttz to UTC
    // - if the completedAt time is before the startedAt time assume the shift was overnight and add a day to completedAt

    const utcStartedAt = newStartedAt
      ? parseTimeRangeValueToUTCDate(newStartedAt)
      : null
    let utcCompletedAt = newCompletedAt
      ? parseTimeRangeValueToUTCDate(newCompletedAt)
      : null

    if (utcCompletedAt && utcStartedAt) {
      if (utcCompletedAt < utcStartedAt) {
        utcCompletedAt = addDays(utcCompletedAt, 1)
      }
    }

    const formattedUTCStartedAt = utcStartedAt
      ? utcStartedAt.toISOString()
      : null
    const formattedUTCCompletedAt = utcCompletedAt
      ? utcCompletedAt.toISOString()
      : null

    setValue('startedAt', formattedUTCStartedAt)
    setValue('completedAt', formattedUTCCompletedAt)
  }

  const zonedStartedAt = data.startedAt
    ? formatInTimeZone(new Date(data.startedAt), shifttz, 'HH:mm')
    : null
  const zonedCompletedAt = data.completedAt
    ? formatInTimeZone(new Date(data.completedAt), shifttz, 'HH:mm')
    : null

  return (
    <TimeRangeInput
      block
      value={[zonedStartedAt, zonedCompletedAt]}
      onChange={handleTimeRangeChange}
      disabled={
        data.finishedEarlyCategory === AssignedWorkerOptionsEnum.NO_SHOW
      }
    />
  )
}
