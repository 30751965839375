import React from 'react'
import { useWorkerProfile } from './useWorkerProfile'
import { Flex, Box, Text, Tooltip } from 'ui'
import styled from 'styled-components'
import { InfoIcon } from 'lucide-react'

const StatBox = styled(Flex).attrs({
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  px: 4,
})`
  ${({ theme: { colors } }) => `
    &:not(:last-child) {
      border-right: 1px solid ${colors.neutrals[200]};
    }
    
    ${Text} {
      text-align: center;
    }
  `}
`

export function WorkerStats() {
  const { profileData } = useWorkerProfile()

  return (
    <Flex
      pt={[3, 3, 4]}
      pb={[4, 4, 5]}
      justifyContent={'center'}
      alignItems={'stretch'}
      borderBottomWidth={1}
      borderBottomStyle={'dashed'}
      borderBottomColor={'neutrals.200'}
    >
      <StatBox>
        <Text fontSize={7} fontWeight={2}>
          {profileData.worker.favorites}
        </Text>
        <Tooltip content={'Number of times favorited'}>
          <Box display={'inline-flex'} alignItems={'center'}>
            Favorited&nbsp;
            <InfoIcon size={18} />
          </Box>
        </Tooltip>
      </StatBox>
      <StatBox>
        <Text fontSize={7} fontWeight={2}>
          {profileData.worker.numFinishedShifts ?? 0}
        </Text>
        <Text>Completed Shifts</Text>
      </StatBox>
      <StatBox>
        <Text fontSize={7} fontWeight={2}>
          {profileData.worker.rating
            ? Number(profileData.worker.rating).toFixed(2)
            : 'N/A'}
        </Text>
        <Text>Rating</Text>
      </StatBox>
    </Flex>
  )
}
