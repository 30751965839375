import { api, graphql, apiDateFormat } from 'api'
import { Location, Position, User, Work, WorkShift } from 'typings/common_defs'
import { path } from 'ramda'
import { NUM_HOURS_MESSAGING_ALLOWED_BEFORE } from 'lib/shift'
import { addHours, format } from 'date-fns'

export type MessageDirection = 'e2w' | 'w2e' | 'a2w' | 'w2a'
export type MessageShiftInfo = Pick<WorkShift, 'id' | 'startsAt' | 'endsAt'> & {
  location: Pick<Location, 'name'> & {
    address: {
      timezone?: string
    }
  }
  position: Pick<Position, 'name'>
}

export interface ChatMessage {
  unreadCount: number
  direction: MessageDirection
  message: string
  fromUser: Pick<User, 'id' | 'name' | 'profilePicUrl'>
  toUser: Pick<User, 'id' | 'name' | 'profilePicUrl'>
  createdAt: string
  shift: MessageShiftInfo
}

export interface PaginatedChatMessages {
  items: ChatMessage[]
  cursor: string | null
}

export async function getLatestMessages(cursor?: string) {
  const data = await graphql(
    `company {
          id
          latestChatMessages${cursor ? `(cursor: "${cursor}")` : ''} {
            items {
              unreadCount
              direction
              message
              fromUser { id, name, profilePicUrl }
              toUser { id, name, profilePicUrl }
              shift { id, startsAt, endsAt, location { name }, position {name} }
            },
            cursor
          }
        }`,
    'threads'
  )

  return path(
    ['data', 'data', 'company', 'latestChatMessages'],
    data
  ) as PaginatedChatMessages
}

interface WorkerChatMessagesResponse {
  items: ChatMessage[]
  currMessageableShiftId: number | null
}

export async function getWorkerMessages(workerId: number) {
  const data = await graphql(
    `company {
          workerChatMessages(workerId: ${workerId}) {
            items {
              direction
              message
              fromUser { id, name, profilePicUrl }
              toUser { id, name, profilePicUrl }
              shift { id, startsAt, endsAt, location { name }, position {name} }
              createdAt
            }
            currMessageableShiftId
          }
        }`,
    'messaging'
  )

  return path(
    ['data', 'data', 'company', 'workerChatMessages'],
    data
  ) as WorkerChatMessagesResponse
}

export interface SendChatMessageRequest {
  shiftId: number
  toUserIds: number[]
  message: string
}

export function sendChatMessage(
  companyId: number,
  data: SendChatMessageRequest
) {
  return api.post(`/company/${companyId}/shifts/${data.shiftId}/chat_send`, {
    user_ids: data.toUserIds,
    message: data.message,
  })
}

export function markChatAsRead(companyId: number, userIds: number[]) {
  return api.post(`/company/${companyId}/chat_read`, { user_ids: userIds })
}

export type MessageableShift = Pick<
  WorkShift,
  'id' | 'startsAt' | 'endsAt' | 'locationless'
> & {
  position: Pick<Position, 'name' | 'id'>
  location: Location
  work: Pick<Work, 'status' | 'worker' | 'confirmedAt' | 'startedAt'>
}

export async function getMessageableUpcomingShifts() {
  const startDate = format(new Date(), apiDateFormat)
  const endDate = format(
    addHours(new Date(), NUM_HOURS_MESSAGING_ALLOWED_BEFORE),
    apiDateFormat
  )
  const data = await graphql(
    `company {
          shifts2 (status: "scheduled", startDate: "${startDate}", endDate: "${endDate}") {
            items {
              position { name, id},
              location { name, id, address {city, state, street, unit, zip, lat, long, timezone}}
              locationless
              id, startsAt, endsAt
              work {
                  status
                  worker {id, name, profilePicUrl, rating}
                  confirmedAt
                  startedAt
              }
            }
          }
        }`,
    'messages_upcoming_shifts'
  )

  return path(
    ['data', 'data', 'company', 'shifts2', 'items'],
    data
  ) as MessageableShift[]
}
