import { useCompanyInfo } from '../company'
import { useMutation } from '@tanstack/react-query'
import {
  createPastShift,
  createShift,
  ShiftSubmissionRequest,
  updateShift,
} from 'api/shift'
import { AxiosError } from 'axios'
import { useUpdateTimelineShift } from './useShiftTimeline'

export function useSaveShiftMutation(options: {
  shiftId?: number
  isPast?: boolean
  onSuccess?: (shiftDate?: Date) => void
  onError?: (err: AxiosError) => void
}) {
  const { isPast, shiftId, ...otherOptions } = options
  const { data: company } = useCompanyInfo()
  const { updateShift: updateTimelineShift } = useUpdateTimelineShift()

  return useMutation({
    mutationFn: async (data: ShiftSubmissionRequest) => {
      if (!company?.id) {
        return
      }

      if (shiftId) {
        return updateShift(company.id, shiftId, data)
      }

      if (isPast) {
        return createPastShift(company.id, data)
      }

      return createShift(company.id, data)
    },
    ...otherOptions,
    onSuccess: (shiftDate) => {
      // invalidate shift timeline
      if (shiftId) {
        updateTimelineShift(shiftId)
      }
      if (otherOptions.onSuccess) {
        otherOptions.onSuccess(shiftDate ?? undefined)
      }
    },
  })
}
