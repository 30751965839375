import React, { createContext, useLayoutEffect, useMemo, useState } from 'react'
import {
  categorySchema,
  detailsSchema,
  PartialPositionData,
  positionSchema,
  requirementSchema,
} from './schemas'
import { Box, Loading, Text } from 'ui'
import { Category, Details, Requirement } from './components'
import { toServerData, NewRequirement } from './dataTransformers'
import { useSavePositionMutation } from 'queries/company'
import { useCompanyPosition } from 'hooks/useCompanyPosition'
import { Position } from 'typings/common_defs'

export enum StepEnum {
  Category = 'Category',
  Requirement = 'Requirement',
  Details = 'Details',
}

export interface PositionEditorContextValues {
  positionId?: number
  position?: Position | null
  isNew: boolean
  steps: typeof STEPS
  currentStep?: StepEnum
  goNext: () => void
  goBack: () => void
  canGoBack: boolean
  positionData: PartialPositionData
  setPositionData: (data: PartialPositionData) => Promise<unknown>
  addRequirement: (requirement: {
    name: string
    category: string
    positionTemplateId: number
  }) => NewRequirement
  newRequirements: NewRequirement[]
  editorError?: string
  submitPositionData: () => void
  isSaving: boolean
}

export const PositionEditorContext =
  createContext<PositionEditorContextValues | null>(null)

export const STEPS = [
  {
    name: StepEnum.Category,
    component: <Category />,
    schemaKey: 'category',
    schema: categorySchema,
  },
  {
    name: StepEnum.Requirement,
    component: <Requirement />,
    schemaKey: 'requirement',
    schema: requirementSchema,
  },
  {
    name: StepEnum.Details,
    component: <Details />,
    schemaKey: 'details',
    schema: detailsSchema,
  },
]

interface PositionEditorProviderProps {
  positionId?: number
  onPositionAdded?: (positionId: number) => void
  onPositionUpdated?: () => void
}

export const PositionEditorProvider = (props: PositionEditorProviderProps) => {
  const { positionId, onPositionAdded, onPositionUpdated } = props
  const [isLoading, setLoading] = useState(!!positionId)
  const editPositionData = useCompanyPosition(positionId)
  const [positionData, setPositionData] = useState<PartialPositionData>({})
  const [currentStep, setCurrentStep] = useState<StepEnum>(StepEnum.Category)
  const currentIdx = useMemo(
    () => STEPS.findIndex((step) => step.name === currentStep),
    [currentStep]
  )
  const [newRequirements, setNewRequirements] = useState<NewRequirement[]>([])
  const canGoBack = currentIdx > 0
  const [error, setError] = useState<string | undefined>()

  useLayoutEffect(() => {
    if (editPositionData) {
      setPositionData({
        category: {
          positionTemplateId: editPositionData.positionTemplateId as number,
        },
        requirement: {
          requirementIds: editPositionData.requirements2
            ? editPositionData.requirements2.map((r) => Number(r.id))
            : [],
        },
        details: {
          positionName: editPositionData.name,
          description: editPositionData.about ?? '',
        },
      })
      setLoading(false)
    }
  }, [editPositionData])

  const goNext = () => {
    if (currentIdx === -1) {
      return setCurrentStep(StepEnum.Category)
    }

    const nextStep = STEPS[currentIdx + 1]
    if (nextStep) {
      setCurrentStep(nextStep.name)
    }
  }

  const goBack = () => {
    if (canGoBack) {
      const prevStep = STEPS[currentIdx - 1]
      if (prevStep) {
        setCurrentStep(prevStep.name)
      }
    }
  }

  const { mutate: savePosition, isPending: isSavingPosition } =
    useSavePositionMutation({
      positionId,
      onError(err) {
        setError(err.message)
      },
      onSuccess(addedId) {
        if (positionId && onPositionUpdated) {
          onPositionUpdated()
        }

        if (!positionId && onPositionAdded) {
          onPositionAdded(addedId)
        }
      },
    })

  const values = useMemo(
    () => ({
      positionId,
      isNew: !positionId,
      position: editPositionData,
      steps: STEPS,
      currentStep,
      goNext,
      goBack,
      canGoBack,
      positionData,
      setPositionData: (data: PartialPositionData) => {
        return new Promise((resolve) => {
          setPositionData((prev) => ({ ...prev, ...data }))
          setPositionData((latestData) => {
            resolve(latestData)
            return latestData
          })
        })
      },
      addRequirement: (requirement: {
        name: string
        category: string
        positionTemplateId: number
      }) => {
        const newRequirement = {
          positionTemplateId: requirement.positionTemplateId,
          isWorkwhileRequired: false,
          isPreSelected: false,
          requirement: {
            id: Date.now(),
            name: requirement.name,
            category: requirement.category,
          },
        }
        setNewRequirements((prev) => [...prev, newRequirement])
        return newRequirement
      },
      newRequirements,
      submitPositionData: () => {
        // ensure we submit the latest data
        setPositionData((latestData) => {
          const result = positionSchema.safeParse(latestData)
          if (!result.success) {
            setError(
              'Please double check all the information provided is correct.'
            )
            return latestData
          }
          const submissionData = toServerData(result.data, newRequirements)
          savePosition(submissionData)
          return latestData
        })
      },
      editorError: error,
      isSaving: isSavingPosition,
    }),
    [
      positionId,
      editPositionData,
      positionData,
      currentStep,
      currentIdx,
      canGoBack,
      newRequirements,
      error,
      isSavingPosition,
    ]
  )

  return (
    <PositionEditorContext.Provider value={values}>
      <Box display={'inline-flex'} pb={3}>
        <Text mr={1}>
          {positionId ? 'Edit Position:' : 'Add New Position:'}
        </Text>
        <Text fontWeight={1} color={'primary'}>
          Step {currentIdx + 1} of {STEPS.length}
        </Text>
      </Box>
      {isLoading ? <Loading /> : STEPS[currentIdx].component}
    </PositionEditorContext.Provider>
  )
}
