import React from 'react'
import { Box } from 'ui'
import { CompanyHeader } from './CompanyHeader'
import { CompanySwitcher } from 'pages/layout/SideNav/CompanyInfo/CompanySwitcher'

export function CompanyInfo() {
  return (
    <Box>
      <CompanyHeader />
      <CompanySwitcher />
    </Box>
  )
}
