import React, {
  createContext,
  Fragment,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { format, isSameDay, parse, startOfDay } from 'date-fns'
import { v4 } from 'uuid'
import { useParams, useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { useCleanTimelinePageCache } from 'queries/shift'

type DateSource = 'control' | 'scrolling'

interface HomeProviderValues {
  currentDate: Date
  referenceDate: Date
  renderDate?: Date
  setCurrentDate: (date: Date, source?: DateSource) => void
  locationId?: number
  positionId?: number
  setLocationId: (locationId?: number) => void
  setPositionId: (positionId?: number) => void
}

export const HomeContext = createContext<HomeProviderValues | null>(null)

export function HomeProvider(props: PropsWithChildren) {
  const { children } = props
  const { date } = useParams<{ date: string }>()
  const referenceDate = useMemo(() => {
    if (date) {
      return startOfDay(parse(date, 'yyyy-MM-dd', new Date()))
    }

    return startOfDay(new Date())
  }, [date])
  const [renderDate, setRenderDate] = useState<Date>()
  const [currentDate, setCurrentDate] = useState(startOfDay(referenceDate))
  const [renderId, setRenderId] = useState<string>('')
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const locationId = useMemo(() => {
    return searchParams.get('location_id')
      ? Number(searchParams.get('location_id'))
      : undefined
  }, [searchParams])
  const positionId = useMemo(() => {
    return searchParams.get('position_id')
      ? Number(searchParams.get('position_id'))
      : undefined
  }, [searchParams])

  const rerenderDate = (date: Date) => {
    const startOfDate = startOfDay(date)
    setCurrentDate(startOfDate)
    setRenderDate(startOfDate)
    setRenderId(v4())
  }

  const { cleanPageCache } = useCleanTimelinePageCache()

  useEffect(() => {
    // clean up cache before render
    cleanPageCache({
      startAt: referenceDate,
      locationId,
      positionId,
    })
    rerenderDate(referenceDate)
  }, [referenceDate, locationId, positionId])

  const value = useMemo(() => {
    return {
      currentDate,
      referenceDate,
      renderDate,
      setCurrentDate: (date: Date, source?: DateSource) => {
        if (source === 'scrolling') {
          setCurrentDate(startOfDay(date))
        } else {
          if (isSameDay(date, referenceDate)) {
            cleanPageCache({
              startAt: referenceDate,
              locationId,
              positionId,
            })
            rerenderDate(referenceDate)
          } else {
            const url = `/home/${format(date, 'yyyy-MM-dd')}`
            navigate(
              `${url}${locationId || positionId ? '?' : ''}${
                locationId ? `location_id=${locationId}` : ''
              }${locationId && positionId ? '&' : ''}${
                positionId ? `position_id=${positionId}` : ''
              }`
            )
          }
        }
      },
      locationId,
      positionId,
      setLocationId: (locationId?: number) =>
        setSearchParams((prevParams) => {
          if (locationId) {
            prevParams.set('location_id', locationId.toString())
          } else {
            prevParams.delete('location_id')
          }
          return prevParams
        }),
      setPositionId: (positionId?: number) =>
        setSearchParams((prevParams) => {
          if (positionId) {
            prevParams.set('position_id', positionId.toString())
          } else {
            prevParams.delete('position_id')
          }
          return prevParams
        }),
    }
  }, [currentDate, referenceDate, renderDate, locationId, positionId])

  return (
    <HomeContext.Provider value={value}>
      {renderId ? <Fragment key={renderId}>{children}</Fragment> : null}
    </HomeContext.Provider>
  )
}
