import { useCompanyInfo } from '../company'
import { useMutation } from '@tanstack/react-query'
import { updateShiftRosters } from 'api/worker/companyRoster'
import { useInvalidateShiftDetail } from 'queries/company/useCompanyShiftDetail'

interface UseUpdateShiftRosterMutation {
  shiftId: number
  onSuccess?: () => void
  onError?: (err: Error) => void
}

export function useUpdateShiftRosterMutation(
  options: UseUpdateShiftRosterMutation
) {
  const { data: company } = useCompanyInfo()
  const { shiftId, onSuccess, ...queryOptions } = options
  const { invalidate: invalidateShiftDetail } = useInvalidateShiftDetail([
    shiftId,
  ])

  return useMutation({
    mutationFn: async (rosterIds: number[]) => {
      if (!company?.id) {
        throw new Error('Company id not found')
      }

      return updateShiftRosters(company.id, shiftId, rosterIds)
    },
    onSuccess() {
      invalidateShiftDetail()
      if (onSuccess) onSuccess()
    },
    ...queryOptions,
  })
}
