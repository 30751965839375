import React, {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { getAuth, User, onAuthStateChanged } from 'firebase/auth'
import { initializeApp } from 'firebase/app'
import { PermissionEnum, UserPermissions } from 'api/userPermissions'
import { useUserPermissions } from 'queries/user'
import { Loading } from 'ui'
import { setAmplitudeUserId } from 'lib/amplitude'
import Bugsnag from '@bugsnag/js'
import { CompanyInfo, getCurrentCompanyInfo } from 'api/company'
import { isDemoMode, reactivateDemoMode } from 'api/demo'
import { config } from 'config'

const app = initializeApp(config.firebaseConfig)
export const auth = getAuth(app)

export interface AuthContextValueProps {
  user: User | null
  company: CompanyInfo | null
  isAuthenticated: boolean
  permissions: UserPermissions | null
  hasPermission: (permission: PermissionEnum) => boolean
}

export const AuthContext = createContext<AuthContextValueProps>(null!)

interface Props {
  children: ReactNode
}

export const AuthProvider = (props: Props) => {
  const { children } = props
  const [initializing, setInitializing] = useState(true)
  const [user, setUser] = useState<User | null>(null)
  const [companyInfo, setCompanyInfo] = useState<CompanyInfo | null>(null)
  const [loadingCompanyInfo, setLoadingCompanyInfo] = useState(false)

  const { data: permissionsData, isFetching: isLoadingPermissions } =
    useUserPermissions(user?.uid)

  const permissions = useMemo(() => {
    if (!user) {
      return null
    }

    return permissionsData || null
  }, [permissionsData, user])

  useEffect(() => {
    return onAuthStateChanged(auth, async (user) => {
      setUser(user)
      if (initializing) {
        setInitializing(false)
      }
    })
  }, [])

  useEffect(() => {
    const isDemo = isDemoMode()
    if (isDemo) {
      reactivateDemoMode()
    }
  }, [])

  useEffect(() => {
    if (user) {
      setAmplitudeUserId(user.uid)
      Bugsnag.setUser(user.uid)
      setLoadingCompanyInfo(true)
      getCurrentCompanyInfo()
        .then((companyInfo) => {
          setCompanyInfo(companyInfo)
        })
        .finally(() => {
          setLoadingCompanyInfo(false)
        })
    } else {
      setCompanyInfo(null)
    }
  }, [user])

  const values = useMemo(
    () => ({
      user,
      company: companyInfo,
      permissions,
      isAuthenticated: !!user,
      hasPermission: (permission: PermissionEnum) => {
        if (!permissions) {
          return false
        }
        return permissions[permission]
      },
    }),
    [user, permissions, companyInfo]
  )

  if (initializing || isLoadingPermissions || loadingCompanyInfo) {
    return <Loading />
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}
