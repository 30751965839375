type SimpleMemoryRouterLocation = {
  // Not pathname, because it's not a URL, it's just a key to identify the route
  path: string
}

type SimpleMemoryRouterHistoryOptions = {
  initialEntries: string[]
  initialIndex?: number
}

const clampIndex = (n: number, length: number) => {
  return Math.min(Math.max(n, 0), length - 1)
}

type To = SimpleMemoryRouterLocation['path'] | SimpleMemoryRouterLocation

type SimpleMemoryRouterHistory = {
  readonly currentLocation: SimpleMemoryRouterLocation
  /**
   * Pushes a new location onto the history stack, increasing its length by one.
   * If there were any entries in the stack after the current one, they are
   * lost.
   *
   * @param to - The new path
   */
  push(to: To): void

  /**
   * Replaces the current location in the history stack with a new one.  The
   * location that was replaced will no longer be available.
   *
   * @param to - The new path
   */
  replace(to: To): void

  /**
   * Navigates `n` entries backward/forward in the history stack relative to the
   * current index. For example, a "back" navigation would use go(-1).
   *
   * @param delta - The delta in the stack index
   */
  go(delta: number): void
  /**
   * Resets the history stack to the initial entries and index.
   */
  reset(): void

  /**
   * Sets up a listener that will be called whenever the current location
   * changes.
   *
   * @param listener - A function that will be called when the location changes
   * @returns unlisten - A function that may be used to stop listening
   */
  listen(listener: () => void): () => void
}

export function createSimpleMemoryRouterHistory(
  options: SimpleMemoryRouterHistoryOptions
): SimpleMemoryRouterHistory {
  const { initialEntries, initialIndex } = options
  const entries: SimpleMemoryRouterLocation[] = initialEntries.map((entry) => ({
    path: entry,
  }))

  let index = clampIndex(
    initialIndex == null ? entries.length - 1 : initialIndex,
    entries.length
  )

  let listener: (() => void) | undefined = undefined

  const history: SimpleMemoryRouterHistory = {
    get currentLocation() {
      return entries[index]
    },
    push: (to: To) => {
      const nextLocation = typeof to === 'string' ? { path: to } : to
      index += 1
      entries.splice(index, entries.length, nextLocation)
      listener?.()
    },
    replace: (to: To) => {
      const nextLocation = typeof to === 'string' ? { path: to } : to
      entries[index] = nextLocation
      listener?.()
    },
    go: (delta: number) => {
      const nextIndex = clampIndex(index + delta, entries.length)
      index = nextIndex
      listener?.()
    },
    reset: () => {
      index = 0
      entries.splice(
        0,
        entries.length,
        ...initialEntries.map((entry) => ({
          path: entry,
        }))
      )
      listener?.()
    },
    listen: (l: () => void) => {
      listener = l
      return () => {
        listener = undefined
      }
    },
  }

  return history
}
