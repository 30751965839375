import { User } from 'typings/common_defs'
import { api, graphql } from 'api'
import { path } from 'ramda'
import { PermissionEnum } from '../userPermissions'

export type CompanyMember = Pick<
  User,
  'id' | 'name' | 'email' | 'phoneNumber' | 'companyRolePerm'
>

export type CompanyGroupMember = Pick<
  User,
  'id' | 'name' | 'email' | 'phoneNumber' | 'companyRolePerm' | 'companyRole'
>

export async function getCompanyMembers() {
  const data = await graphql(
    `company {
          members {id, name, email, phoneNumber, companyRolePerm {id, description}}
        }`,
    'current_company'
  )

  const members = path(
    ['data', 'data', 'company', 'members'],
    data
  ) as CompanyMember[]
  return members.map((member) => ({
    ...member,
    id: Number(member.id),
  }))
}

export async function getCompanyGroupMembers(name: string) {
  const data = await graphql(
    `company {
          groupMembers(name: "${name}") {
            id
            name
            email
            phoneNumber
            companyRolePerm {
              id
              description
            }
            companyRole {
              id
              companyId
            }
          }
        }`,
    'current_company'
  )

  const groupMembers = path(
    ['data', 'data', 'company', 'groupMembers'],
    data
  ) as CompanyGroupMember[]
  return groupMembers.map((groupMember) => ({
    ...groupMember,
    id: Number(groupMember.id),
  }))
}

export type CompanyPendingInvite = Omit<CompanyMember, 'name' | 'phoneNumber'>

export async function getCompanyPendingInvites() {
  const data = await graphql(
    `company {
          pendingInvites {id, email, companyRolePerm {id, description}}
        }`,
    'current_company'
  )

  const pendingInvites = path(
    ['data', 'data', 'company', 'pendingInvites'],
    data
  ) as CompanyPendingInvite[]
  return pendingInvites.map((member) => ({
    ...member,
    id: Number(member.id),
  }))
}

export type CompanyRole = {
  id: number
  name?: string
  description?: string
} & {
  [key in PermissionEnum]: boolean
}

export async function getCompanyRoles() {
  const data = await graphql(
    `
  company {
    rolePerms {
      id,
      name,
      description,
      manageRoles,
      manageBonus,
      paymentAccess,
      messageWorker,
      workTimeAdjustment,
      manageWorker,
      manageShift,
      requestSpecificWorker,
      manageWorker
    }
  }
  `,
    'company_roles'
  )

  const roles = path(
    ['data', 'data', 'company', 'rolePerms'],
    data
  ) as CompanyRole[]
  return roles.map((role) => ({
    ...role,
    id: Number(role.id),
  }))
}

export async function inviteCompanyMember(
  companyId: number,
  email: string,
  rolePermId: number
) {
  return api.post(`/company/${companyId}/invite`, {
    email,
    role_perm_id: rolePermId,
  })
}

export async function removeCompanyMemberRole(
  companyId: number,
  userId: number,
  rolePermId: number
) {
  return api.delete(`/company/${companyId}/perms/${rolePermId}/users/${userId}`)
}

export async function removeCompanyGroupMemberRole(
  companyId: number,
  userId: number,
  rolePermId: number
) {
  return api.delete(
    `/company/${companyId}/group/perms/${rolePermId}/users/${userId}`
  )
}
