import React, {
  createContext,
  PropsWithChildren,
  useMemo,
  useState,
} from 'react'
import { FetchNextPageOptions, InfiniteData } from '@tanstack/react-query'
import { CompanyRoster, PaginatedCompanyWorkers } from 'api/worker'
import {
  useCompanyWorkers,
  useUpdateCompanyWorkerRosters,
} from 'queries/company'

export interface WorkerSearchContextValues {
  searchKeyword: string
  setSearchKeyword: (keyword: string) => void
  searchResult?: InfiniteData<PaginatedCompanyWorkers>
  isSearching: boolean
  hasNextPage?: boolean
  fetchNextPage: (options?: FetchNextPageOptions) => void
  updateWorkerRosters: (
    workerId: number,
    rosters: Array<Pick<CompanyRoster, 'id' | 'name'>>
  ) => void
}

export const WorkerSearchContext =
  createContext<WorkerSearchContextValues | null>(null)

export const WorkerSearchProvider = (props: PropsWithChildren) => {
  const [searchKeyword, setSearchKeyword] = useState<string>('')
  const {
    data: searchResult,
    isFetching: isSearching,
    hasNextPage,
    fetchNextPage,
  } = useCompanyWorkers({
    search: searchKeyword,
    includeRosters: true,
    minLength: 1,
  })
  const { updateWorkerRosters } = useUpdateCompanyWorkerRosters({
    search: searchKeyword,
    includeRosters: true,
  })

  const values = useMemo(() => {
    return {
      searchResult,
      hasNextPage,
      fetchNextPage,
      searchKeyword,
      isSearching,
      setSearchKeyword,
      updateWorkerRosters,
    }
  }, [searchResult, hasNextPage, searchKeyword, isSearching])

  return (
    <WorkerSearchContext.Provider value={values}>
      {props.children}
    </WorkerSearchContext.Provider>
  )
}
