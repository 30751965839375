import React, { useMemo } from 'react'
import { CompanyMember } from 'api/company'
import { toTitleCase } from 'lib/legacy_util'
import { path, prop, sortBy } from 'ramda'
import { components, OptionProps } from 'react-select'
import { FilterOptionOption, Selectable } from 'typings/selectable_ext'

function CustomOption(props: OptionProps<unknown>) {
  if (path(['data', 'value'], props) === '0') {
    return (
      <div className={'other-team-member'}>
        <components.Option {...props} />
      </div>
    )
  }

  return <components.Option {...props} />
}

function customFilterOption(
  candidate: FilterOptionOption<unknown>,
  input: string
) {
  const data = candidate as Selectable<unknown>
  if (!data.value || !data.label) return false
  if (data.value.toString() === '0') {
    return true
  }
  return data.label.toLowerCase().includes(input.toLowerCase())
}

export function useSupervisorOptions(data?: CompanyMember[]) {
  const options: Array<Selectable<unknown>> = useMemo(() => {
    if (!data) return []

    const items = data
      .filter((member) => member.name)
      .map((member) => ({
        ...member,
        value: member.id,
        label: toTitleCase(member.name),
      }))

    return [
      ...sortBy(prop('label'), items),
      {
        value: '0',
        label: 'Name not listed? Assign a Supervisor...',
      },
    ]
  }, [data])

  return {
    options,
    CustomOption,
    customFilterOption,
  }
}
