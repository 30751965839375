import React, { useMemo } from 'react'
import {
  Box,
  Heading,
  Loading,
  Table,
  Button,
  Flex,
  toast,
  useResponsiveValue,
  Text,
} from 'ui'
import { useCompanyPositions } from 'queries/company'
import { AddPosition } from 'components/AddPosition'
import { createPositionColumns } from './columns'

export function PositionList() {
  const { data, isLoading } = useCompanyPositions()
  const positions = useMemo(() => {
    if (!data) return null
    return data.filter((position) => !position.hidden)
  }, [data])
  const columnsToDisplay = useResponsiveValue([1, 1, 3])
  const nameColSize = useResponsiveValue([
    Number.MAX_SAFE_INTEGER,
    Number.MAX_SAFE_INTEGER,
    250,
  ])
  const columns = createPositionColumns(nameColSize)
  const tableVariant = useResponsiveValue([
    'borderless',
    'borderless',
    'standard',
  ])

  return (
    <Box>
      <Flex alignItems={'center'}>
        <Heading level={3}>Positions</Heading>
        <Box flex={1} />
        <AddPosition
          trigger={<Button variant={'text'}>+ Add Position</Button>}
          onPositionAdded={() => {
            toast.success('Successfully added new position')
          }}
        />
      </Flex>
      {isLoading ? <Loading /> : null}
      {positions ? (
        <Table
          variant={tableVariant as 'borderless' | 'standard'}
          columns={columns.slice(0, columnsToDisplay)}
          data={positions}
        />
      ) : null}
      {positions?.length === 0 ? (
        <Text p={3} color={'neutrals.200'}>
          No positions
        </Text>
      ) : null}
    </Box>
  )
}
