/**
 * Lib functions to support WorkerRequirement model
 */

import { WorkerRequirement } from '../typings/common_defs'

/**
 * Returns true if the requirement requires a photo upload
 * @param {WorkerRequirement} requirement
 * @returns {boolean} True if the requirement requires a photo upload
 */
export const requirementNeedsPhotoUpload = (
  requirement: WorkerRequirement
): boolean => {
  return !!requirement.photoUploadNeeded
}
