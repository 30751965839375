import { useQuery } from '@tanstack/react-query'
import { invoiceKey } from './keys'
import { getInvoice } from 'api/payment'

interface UseInvoice {
  invoiceId: number
}

export function useInvoice(options: UseInvoice) {
  const { invoiceId } = options

  return useQuery({
    queryKey: invoiceKey(invoiceId),
    queryFn: () => getInvoice(invoiceId),
  })
}
