import React from 'react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useAuthContext } from 'components/auth'
import { StatsigProvider } from 'statsig-react'
import { getStatsigEnv } from 'lib/statsig/statsig'
import {
  Loading,
  ThemeProvider,
  ModalProvider,
  ToastContainer,
  TooltipProvider,
} from '@workwhile/ui'
import V2Pages from 'pages'
import { IntercomProvider } from 'react-use-intercom'
import { track } from 'lib/amplitude'
import { DevTools } from 'components/DevTools'
import { config } from 'config'
import { CompanySwitchDetect } from 'components/CompanySwitchIndicator/CompanySwitchDetect'

function EntryPoint() {
  const onIntercomOpen = () => {
    track('button_click, support')
  }

  return (
    <ThemeProvider googleMapsApiKey={config.googleMapsApiKey}>
      <ToastContainer position={'top-center'} style={{ zIndex: 1000000000 }} />
      <TooltipProvider delayDuration={500}>
        <ModalProvider>
          <IntercomProvider
            appId={config.intercomAppId}
            onShow={onIntercomOpen}
          >
            <CompanySwitchDetect />
            <V2Pages />
            <DevTools />
          </IntercomProvider>
        </ModalProvider>
      </TooltipProvider>
    </ThemeProvider>
  )
}

export default function App() {
  const { user, company } = useAuthContext()

  return (
    <StatsigProvider
      sdkKey={config.statsigApiKey}
      waitForInitialization={true}
      options={{
        environment: { tier: getStatsigEnv() },
      }}
      user={{
        userID: user?.uid,
        customIDs: company?.id
          ? {
              companyId: company?.id.toString(),
            }
          : undefined,
        custom: {
          companyId: company?.id, // to allow statsig to target companyId as a number (lt, gt, etc.)
        },
      }}
      initializingComponent={<Loading />}
    >
      <EntryPoint />
      <ReactQueryDevtools
        initialIsOpen={false}
        buttonPosition={'bottom-left'}
      />
    </StatsigProvider>
  )
}
