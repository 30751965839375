import { useMutation } from '@tanstack/react-query'
import { inviteCompanyMember } from 'api/company'
import { useCompanyInfo } from './useCompanyInfo'
import { useInvalidateCompanyPendingInvites } from './useCompanyPendingInvites'

export function useInviteCompanyMemberMutation(options?: {
  onSuccess?: () => void
  onError?: (err: Error) => void
}) {
  const { onSuccess, ...otherOptions } = options ?? {}
  const { data: company } = useCompanyInfo()
  const { invalidate: invalidatePendingInvites } =
    useInvalidateCompanyPendingInvites()

  return useMutation({
    mutationFn: ({
      email,
      rolePermId,
    }: {
      email: string
      rolePermId: number
    }) => {
      if (!company) {
        throw new Error('Company not found')
      }

      return inviteCompanyMember(company.id, email, rolePermId)
    },
    onSuccess: () => {
      invalidatePendingInvites()
      if (onSuccess) onSuccess()
    },
    ...otherOptions,
  })
}
