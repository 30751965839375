import React, { useMemo } from 'react'
import { Box, Loading, Text, Table, useResponsiveValue } from 'ui'
import { useInvoices } from 'queries/payment'
import { invoiceTableColumns } from './columns'
import { formatCurrency } from 'lib/string'
import { sort } from 'ramda'
import { useCompanyInfo } from 'queries/company'

export function OutstandingInvoices() {
  const { data: company } = useCompanyInfo()
  const { data: invoices, isLoading } = useInvoices({
    type: 'outstanding',
  })
  const columnsToShow = useResponsiveValue([3, 4])
  const sortedInvoices = useMemo(() => {
    if (!invoices) {
      return []
    }

    return sort((a, b) => {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    }, invoices || [])
  }, [invoices])
  const total = useMemo(() => {
    if (!invoices) {
      return 0
    }

    return invoices.reduce((acc, curr) => {
      return acc + Number(curr.totalAmount)
    }, 0)
  }, [invoices])

  if (isLoading) {
    return <Loading />
  }

  return (
    <Box py={3}>
      <Text mb={3}>
        Total {company?.chargeUpfront ? 'Pending' : 'Outstanding'}:{' '}
        <strong>{formatCurrency(total)}</strong>
      </Text>
      <Table
        columns={invoiceTableColumns.slice(0, columnsToShow)}
        data={sortedInvoices}
      />
      {sortedInvoices.length === 0 ? (
        <Text p={3} color={'neutrals.200'}>
          No {company?.chargeUpfront ? 'Pending' : 'Outstanding'} Invoice
        </Text>
      ) : null}
    </Box>
  )
}
