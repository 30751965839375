import React, { ReactNode, useRef, useState } from 'react'
import { Message, Modal, Paragraph, toast } from 'ui'
import { TimelineShift } from 'api/shift'
import {
  useUpdateShiftRosterHoldMutation,
  useUpdateTimelineShift,
} from 'queries/shift'
import { useInvalidateShiftDetail } from 'queries/company/useCompanyShiftDetail'

interface Props {
  shift: TimelineShift
  trigger: ReactNode
}

export function RemoveRestrictionModal(props: Props) {
  const { shift, trigger } = props
  const closeModalRef = useRef<() => void>()
  const [error, setError] = useState('')
  const { updateShift } = useUpdateTimelineShift()
  const { invalidate: invalidateShiftDetail } = useInvalidateShiftDetail([
    shift?.id,
  ])
  const [updatingShift, setUpdatingShift] = useState(false)
  const { mutate: updateHold, isPending } = useUpdateShiftRosterHoldMutation({
    shiftId: shift.id,
    onSuccess: async () => {
      setUpdatingShift(true)
      await updateShift(shift.id)
      setUpdatingShift(false)
      invalidateShiftDetail()
      if (closeModalRef.current) {
        closeModalRef.current()
      }
      toast.success('Shift updated successfully')
    },
    onError: (err) => {
      setError(err.message)
    },
  })

  return (
    <Modal
      trigger={trigger}
      loading={isPending || updatingShift}
      title={'Open Shift to Qualified Workers'}
      onOk={({ closeModal }) => {
        closeModalRef.current = closeModal
        updateHold('temporary')
      }}
      okText={'Open Shift'}
    >
      <Paragraph>
        If requested workers are unable to attend, WorkWhile can open their
        spots to other qualified workers to help your shift fill.
      </Paragraph>
      <Paragraph>
        To turn this feature on, please click "Open Shift" to confirm.
      </Paragraph>
      {error ? <Message variant={'error'}>{error}</Message> : null}
    </Modal>
  )
}
