import React, { Fragment, ReactNode, useMemo } from 'react'
import { useShiftEditor } from '../../useShiftEditor'
import { AudienceType } from '../../schemas'
import { AudienceInfoModal } from './AudienceInfoModal'
import { BaseLink, Box, Message, Text } from 'ui'
import { EditorMode, StepEnum } from '../../ShiftEditorProvider'
import { AuthGuard } from 'components/auth'
import { PermissionEnum } from 'api/userPermissions'
import { PastShiftCallout } from './PastShiftCallout'
import { AddPaymentMethod } from './AddPaymentMethod'

export function ReviewCallouts() {
  const { shiftData, editorMode, goTo, hasPaymentMethod } = useShiftEditor()
  const calloutItems = useMemo(() => {
    const isPastShift = editorMode === EditorMode.PastShift
    const callouts: Array<ReactNode> = []
    if (isPastShift) {
      callouts.push(<PastShiftCallout />)
    }

    if (!isPastShift) {
      if (
        shiftData.staff?.audienceType &&
        [AudienceType.Roster, AudienceType.SpecificWorkers].includes(
          shiftData.staff?.audienceType
        )
      ) {
        callouts.push(<AudienceInfoModal />)
      }

      if (shiftData.staff?.audienceType === AudienceType.SpecificWorkers) {
        if (shiftData.staff?.restrictShiftToAudience) {
          callouts.push(
            <Message variant={'info'}>
              <Text fontWeight="bold" mb={2}>
                Only your requested workers will be allowed to accept this
                assignment.
              </Text>
              <Text mb={2}>
                Because of this restriction, your assignment may go unfilled.
              </Text>
              <AuthGuard.Permission
                value={PermissionEnum.RequestSpecificWorker}
              >
                To change this setting, please click{' '}
                <BaseLink onClick={() => goTo(StepEnum.Staff)}>here</BaseLink>.
              </AuthGuard.Permission>
            </Message>
          )
        } else {
          callouts.push(
            <Message variant={'warning'}>
              <Text fontWeight="bold" mb={2}>
                If requested workers are unable to attend, let other qualified
                workers accept.
              </Text>
              <Text mb={2}>
                The workers you select will have exclusive access to this
                assignment for a holding period. If there are still open spots
                at the end of the holding period, we'll offer the remaining
                spots to other qualified workers via our proprietary Smart Shift
                Fulfillment algorithm.
              </Text>
              <AuthGuard.Permission
                value={PermissionEnum.RequestSpecificWorker}
              >
                To change this setting, please click{' '}
                <BaseLink onClick={() => goTo(StepEnum.Staff)}>here</BaseLink>.
              </AuthGuard.Permission>
            </Message>
          )
        }
      }

      if (shiftData.staff?.audienceType === AudienceType.Roster) {
        if (shiftData.staff?.restrictShiftToAudience) {
          callouts.push(
            <Message variant={'warning'}>
              <Text fontWeight="bold" mb={2}>
                Only your requested lists will be allowed to accept this
                assignment.
              </Text>
              <Text mb={2}>
                Because of this restriction, your assignment may go unfilled.
              </Text>
              <AuthGuard.Permission
                value={PermissionEnum.RequestSpecificWorker}
              >
                To change this setting, please click{' '}
                <BaseLink onClick={() => goTo(StepEnum.Staff)}>here</BaseLink>.
              </AuthGuard.Permission>
            </Message>
          )
        } else {
          callouts.push(
            <Message variant={'warning'}>
              <Text fontWeight="bold" mb={2}>
                If requested workers are unable to attend, let other qualified
                workers accept.
              </Text>
              <Text mb={2}>
                The Lists you select will have exclusive access to this
                assignment for a holding period. If there are still open spots
                at the end of the holding period, we'll offer the remaining
                spots to other qualified workers via our proprietary Smart Shift
                Fulfillment algorithm.
              </Text>
              <AuthGuard.Permission
                value={PermissionEnum.RequestSpecificWorker}
              >
                To change this setting, please click{' '}
                <BaseLink onClick={() => goTo(StepEnum.Staff)}>here</BaseLink>.
              </AuthGuard.Permission>
            </Message>
          )
        }
      }
    }

    if (!hasPaymentMethod) {
      callouts.push(<AddPaymentMethod />)
    }

    return callouts
  }, [shiftData, editorMode, hasPaymentMethod])

  if (calloutItems.length > 0) {
    return (
      <Box my={3}>
        {calloutItems.map((callout, idx) => (
          <Fragment key={idx}>{callout}</Fragment>
        ))}
      </Box>
    )
  }

  return null
}
