import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useCompanyRosters } from 'queries/company'
import { indexBy, prop } from 'ramda'
import {
  Flex,
  Box,
  Text,
  Pluralize,
  Button,
  Message,
  SwitchRoot,
  SwitchThumb,
} from 'ui'
import { RosterCard } from 'components/RosterSelector/RosterCard'
import { useUniqueWorkersCount } from 'components/RosterSelector/useUniqueWorkersCount'
import { AcknowledgementCheck } from '../../AcknowledgementCheck'

interface Props {
  openSelector?: () => void
}

export function SelectedRosters(props: Props) {
  const {
    watch,
    control,
    formState: { errors },
  } = useFormContext()
  const rosterIds = watch('rosterIds')
  const { data: rosters } = useCompanyRosters()
  const selectedRosters = useMemo(() => {
    if (!rosters || !rosterIds) return []

    const rosterMap = indexBy(prop('id'), rosters)
    return rosterIds.map((id) => rosterMap[id])
  }, [rosterIds, rosters])
  const uniqueWorkersCount = useUniqueWorkersCount(selectedRosters)

  return (
    <>
      {selectedRosters.length > 0 ? (
        <Flex alignItems={'center'} mb={-2}>
          <Text fontWeight={2}>
            <Pluralize
              count={selectedRosters.length}
              oneText={'List'}
              manyText={'Lists'}
            />{' '}
            Selected&nbsp;-&nbsp;
            <Pluralize
              count={uniqueWorkersCount}
              oneText={'unique worker'}
              manyText={'unique workers'}
            />
          </Text>
          <Box flex={1} />
          <Button variant={'text'} onClick={props.openSelector}>
            Edit
          </Button>
        </Flex>
      ) : null}
      <Flex mx={-2} mb={selectedRosters.length > 0 ? 4 : 3} flexWrap={'wrap'}>
        {selectedRosters.map((roster) => {
          return (
            <Box key={roster.id} mt={3} width={[1, 1 / 2, 1 / 2, 1 / 3]} px={2}>
              <RosterCard maxAvatars={4} data={roster} />
            </Box>
          )
        })}
      </Flex>
      {selectedRosters.length > 0 ? (
        <AcknowledgementCheck p={3} mx={-5} mb={-3}>
          <Controller
            name={'restrictShiftToAudience'}
            control={control}
            render={({ field }) => (
              <Flex justifyContent="space-between">
                <Text mr={3}>
                  If requested workers are unable to attend, let other qualified
                  workers accept.
                </Text>
                <SwitchRoot
                  checked={!field.value}
                  onCheckedChange={() => field.onChange(!field.value)}
                >
                  <SwitchThumb>{field.value ? 'OFF' : 'ON'}</SwitchThumb>
                </SwitchRoot>
              </Flex>
            )}
          />
        </AcknowledgementCheck>
      ) : null}
      {errors.rosterIds?.message ? (
        <Message variant={'error'}>
          {errors.rosterIds.message.toString()}
        </Message>
      ) : null}
    </>
  )
}
