import React, { PropsWithChildren, useEffect } from 'react'
import { StepEnum } from './ShiftEditorProvider'
import { useShiftEditor } from './useShiftEditor'
import { Navigate, useLocation } from 'react-router-dom'

interface Props extends PropsWithChildren {
  name: StepEnum
}

export function StepChecker(props: Props) {
  const location = useLocation()
  const { markStepSeen, shiftId, steps, firstUnfinishedStep } = useShiftEditor()
  const currentStep = steps.find((step) => step.name === props.name)

  useEffect(() => {
    if (currentStep) {
      markStepSeen(currentStep.name)
    }
  }, [currentStep])

  if (
    firstUnfinishedStep &&
    currentStep &&
    steps.indexOf(currentStep) > steps.indexOf(firstUnfinishedStep)
  ) {
    return (
      <Navigate
        to={`/home/shift-editor/${shiftId ?? 'new'}/${
          firstUnfinishedStep.route
        }`}
        replace={true}
        state={location.state}
      />
    )
  }

  return props.children
}
