import React from 'react'
import { Avatar, Box, Card, Flex, Heading, Text } from 'ui'
import { format } from 'date-fns'
import { ActionBar } from './ActionBar'
import { useWorkerProfile } from './useWorkerProfile'
import { WorkerStats } from './WorkerStats'
import { Compliments } from './Compliments'
import { Certifications } from './Certifications'
import { ShiftStats } from './ShiftStats'
import { toTitleCase } from 'lib/legacy_util'
import { WorkHistory } from './WorkHistory'

export function WorkerDetails() {
  const { profileData } = useWorkerProfile()

  return (
    <Card
      borderRadius={'xlarge'}
      shadowless={true}
      borderWidth={0}
      mt={[4, 4, 6]}
      px={[3, 3, 6]}
    >
      <Flex flexDirection={'column'} alignItems={'center'}>
        <Box mt={'-7rem'}>
          <Avatar
            user={{
              name: profileData.worker.name,
              photoUrl: profileData.worker.profilePicUrl,
            }}
            size={'xxlarge'}
          />
        </Box>
        <Heading level={1}>{toTitleCase(profileData.worker.name)}</Heading>
        <Text color={'lightText'}>
          {profileData.worker.address
            ? `${profileData.worker.address.city}, ${profileData.worker.address.state}`
            : ''}
          &nbsp;•&nbsp;
          {profileData.worker.firstActive
            ? `Active Since ${format(
                new Date(profileData.worker.firstActive),
                'MMMM yyyy'
              )}`
            : null}
        </Text>
        <ActionBar />
      </Flex>
      <WorkerStats />
      <Compliments />
      <Certifications />
      <ShiftStats />
      <WorkHistory />
    </Card>
  )
}
