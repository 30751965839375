import React from 'react'
import { useShiftEditor } from '../../useShiftEditor'
import { useFormContext } from 'react-hook-form'
import { PaymentForm, PaymentTypeEnum } from '../../schemas'
import { Box, Text, Message, Flex } from 'ui'
import { CircleDollarSign } from 'lucide-react'
import { useTheme } from 'styled-components'
import { EmployerGuidance } from 'typings/common_defs'

interface PayGuidanceProps {
  employerGuidance?: EmployerGuidance
  isLoading: boolean
}

export function PayGuidance({ employerGuidance, isLoading }: PayGuidanceProps) {
  const { shiftData } = useShiftEditor()
  const { colors } = useTheme()
  const { watch } = useFormContext<PaymentForm>()
  const payType = watch('paymentType')
  const numShiftLeads = shiftData.staff?.numShiftLeads

  if (isLoading) {
    return (
      <Message variant={'fixed'} hideIcon={true}>
        <Flex>
          <Box flexShrink={0} pr={2}>
            <CircleDollarSign size={24} color={colors.primary} />
          </Box>
          <Box>
            <Text fontWeight={2}>Calculating pay guidance...</Text>
          </Box>
        </Flex>
      </Message>
    )
  }

  if (
    !employerGuidance ||
    !employerGuidance.minimumPayRate ||
    !employerGuidance.minimumPayLumpSum
  ) {
    return null
  }

  return (
    <Message variant={'fixed'} hideIcon={true}>
      <Flex>
        <Box flexShrink={0} pr={2}>
          <CircleDollarSign size={24} color={colors.primary} />
        </Box>
        <Box>
          <Text fontWeight={2}>Recommended Pay</Text>
          <Box mt={1}>
            {payType === PaymentTypeEnum.LumpSum
              ? `For driving jobs in California, regulations require that the total pay for this shift must be at least $${employerGuidance.minimumPayLumpSum}. Total pay may include bonuses.`
              : `For driving jobs in California, regulations require that the hourly pay for this shift must be at least $${employerGuidance.minimumPayRate}. The average hourly pay may take into account any bonuses offered.`}
            {numShiftLeads
              ? ' And we recommend paying shift leads 20% higher.'
              : null}
          </Box>
        </Box>
      </Flex>
    </Message>
  )
}
