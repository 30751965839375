import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { shiftTimelineKey, shiftTimelineRootKey } from './keys'
import {
  getShiftsForTimeline,
  getTimelineShift,
  PaginatedShiftTimeline,
} from 'api/shift'
import { pathOr } from 'ramda'
import { isSameDay } from 'date-fns'

interface UseShiftTimelineOptions {
  startAt?: Date
  locationId?: number
  positionId?: number
  gcTime?: number
  refetchInterval?: number | false
}

export function useShiftTimeline(options: UseShiftTimelineOptions) {
  const { startAt, locationId, positionId, ...otherOptions } = options
  return useInfiniteQuery<PaginatedShiftTimeline>({
    enabled: !!startAt,
    queryKey: shiftTimelineKey(startAt, locationId, positionId),
    queryFn: async ({ pageParam }) => {
      const headCursor = pathOr(undefined, ['headCursor'], pageParam)
      const tailCursor = pathOr(undefined, ['tailCursor'], pageParam)
      const timelineShifts = await getShiftsForTimeline({
        startAt,
        locationId,
        positionId,
        ...{
          headCursor,
          tailCursor,
        },
      })
      return timelineShifts
    },
    initialPageParam: {
      headCursor: null,
      tailCursor: null,
    },
    getPreviousPageParam: (lastPage) =>
      lastPage?.headCursor
        ? {
            headCursor: lastPage?.headCursor,
          }
        : null,
    getNextPageParam: (lastPage) =>
      lastPage?.tailCursor ? { tailCursor: lastPage?.tailCursor } : null,
    ...otherOptions,
  })
}

export function useInvalidateShiftTimeline() {
  const queryClient = useQueryClient()

  return {
    invalidate: () =>
      queryClient.invalidateQueries({ queryKey: shiftTimelineRootKey }),
  }
}

export function useRemoveShiftFromTimeline(shiftId: number) {
  const queryClient = useQueryClient()
  return {
    removeShift: () => {
      queryClient.setQueriesData(
        { queryKey: shiftTimelineRootKey },
        (oldData?: InfiniteData<PaginatedShiftTimeline>) => {
          if (!oldData) {
            return oldData
          }

          const newPages = oldData.pages.map((page) => {
            const newShifts = page.items.filter(
              (shift) => shift.id.toString() !== shiftId.toString()
            )
            return {
              ...page,
              items: newShifts,
            }
          })

          return {
            ...oldData,
            pages: newPages,
          }
        }
      )
    },
  }
}

export function useUpdateTimelineShift() {
  const queryClient = useQueryClient()

  return {
    updateShift: async (shiftId: number) => {
      const newShift = await getTimelineShift(shiftId)
      queryClient.setQueriesData(
        { queryKey: shiftTimelineRootKey },
        (oldData?: InfiniteData<PaginatedShiftTimeline>) => {
          if (!oldData) {
            return oldData
          }

          const newPages = oldData.pages.map((page) => {
            const newShifts = page.items.map((shift) => {
              if (shift.id.toString() === shiftId.toString()) {
                return newShift
              }
              return shift
            })
            return {
              ...page,
              items: newShifts,
            }
          })

          return {
            ...oldData,
            pages: newPages,
          }
        }
      )
    },
  }
}

export function useCleanTimelinePageCache() {
  const queryClient = useQueryClient()
  return {
    cleanPageCache: (
      options: Pick<
        UseShiftTimelineOptions,
        'startAt' | 'positionId' | 'locationId'
      >
    ) => {
      const { startAt, locationId, positionId } = options
      const queryKey = shiftTimelineKey(startAt, locationId, positionId)

      if (!startAt) {
        return
      }

      queryClient.setQueryData<InfiniteData<PaginatedShiftTimeline>>(
        queryKey,
        (oldData) => {
          if (!oldData) {
            return
          }

          let firstPageIdx = oldData.pageParams.findIndex((pageParam) => {
            const params = pageParam as {
              headCursor?: string | null
              tailCursor?: string | null
            }
            return !params.headCursor && !params.tailCursor
          })
          if (firstPageIdx === -1) {
            firstPageIdx = oldData.pages.findIndex((page) => {
              const firstItem = page.items[0]
              if (firstItem) {
                return isSameDay(new Date(firstItem.startsAt), startAt)
              }
              return false
            })
          }

          if (firstPageIdx !== -1) {
            return {
              pageParams: [{}],
              pages: [oldData.pages[firstPageIdx]],
            }
          }

          return oldData
        }
      )
    },
  }
}
