import { ReactNode } from 'react'
import { CommonGuardProps } from './types'
import { useAuthContext } from './useAuthContext'

export default function Unauthenticated(props: CommonGuardProps): ReactNode {
  const { children, fallback = null } = props
  const { isAuthenticated } = useAuthContext()

  if (isAuthenticated) {
    return fallback
  }

  return children
}
