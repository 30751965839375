import { useQuery } from '@tanstack/react-query'
import { invoicesKey } from './keys'
import { getInvoices, InvoiceType } from 'api/payment'

interface UseInvoices {
  type: InvoiceType
  startDate?: Date
  endDate?: Date
}

export function useInvoices(options: UseInvoices) {
  const { type, startDate, endDate } = options

  return useQuery({
    queryKey: invoicesKey(type, startDate, endDate),
    queryFn: () => getInvoices({ type, startDate, endDate }),
  })
}
