import { Location } from 'typings/common_defs'

export const locations: Location[] = [
  {
    id: 1,
    name: 'Headquarters',
    arrivalInstructions: 'Park in lot 10, report to gate 10',
    address: {
      city: 'Raleigh',
      state: 'NC',
      street: '1400 Edwards Mill Road',
      unit: '',
      zip: 27607,
      lat: '35.80329',
      long: '-78.7217',
      timezone: 'America/New_York',
    },
  },
  {
    id: 2,
    name: 'Downtown Location',
    arrivalInstructions:
      'Go round the back and use the entrance on 10th street. Please ring the bell then wait for someone to open the door.',
    address: {
      city: 'San Francisco',
      state: 'CA',
      street: '169 11th Street',
      unit: '',
      zip: 94103,
      lat: '37.77362',
      long: '-122.41588',
      timezone: 'America/Los_Angeles',
    },
  },
  {
    id: 3,
    name: 'Uptown Location',
    arrivalInstructions: '',
    address: {
      city: 'San Diego',
      state: 'CA',
      street: '13276 Norcroft Road',
      unit: '',
      zip: 92130,
      lat: '32.96152',
      long: '-117.22666',
      timezone: 'America/Los_Angeles',
    },
  },
]

export const locationsByCategory = {
  hospitality: [
    {
      ...locations[0],
      name: 'ABC Arena',
    },
    {
      ...locations[1],
      name: 'XYZ Stadium',
    },
    {
      ...locations[2],
      name: 'Convention Center',
    },
  ],
  driving: [
    {
      ...locations[0],
      name: 'ABC Distribution Center',
    },
    {
      ...locations[1],
      name: 'XYZ Pickup Point',
    },
    {
      ...locations[2],
      name: 'San Diego HUB',
    },
  ],
  warehouse: [
    {
      ...locations[0],
      name: 'HQ Warehouse',
    },
    {
      ...locations[1],
      name: 'ABC Warehouse',
    },
    {
      ...locations[2],
      name: 'XYZ HUB',
    },
  ],
}
