import React, { createContext, PropsWithChildren, useMemo } from 'react'
import { useCompanyShiftDetail } from 'queries/company/useCompanyShiftDetail'
import { TimelineShift } from 'api/shift'
import { sortWorkByPriority } from 'lib/shift'
import { getAssignedWork } from 'lib/work'
import { Work } from 'typings/common_defs'

export interface ShiftDetailContextValues {
  shift: TimelineShift | null
  isLoading: boolean
  isFetching: boolean
  assignedWorkRows: Array<Work>
}

interface ShiftDetailProviderProps extends PropsWithChildren {
  shiftId: number | null
}

export const ShiftDetailContext =
  createContext<ShiftDetailContextValues | null>(null)

export const ShiftDetailProvider = (props: ShiftDetailProviderProps) => {
  const { shiftId, children } = props

  const { data, isLoading, isFetching } = useCompanyShiftDetail({
    ids: shiftId ? [shiftId] : [],
    enabled: !!shiftId,
    includeEstimate: true,
    includePositionLocationDebut: true,
  })

  const shift = useMemo(() => (data ? data[0] : null), [data])

  const assignedWorkRows = useMemo(() => {
    if (!shift) return []
    const numWorkersRequested = shift?.workersNeeded || 0
    const workAssigned = shift?.work
      ? getAssignedWork(sortWorkByPriority(shift))
      : []
    const numWorkAssigned = workAssigned.length

    let rows = workAssigned

    // If we haven't assigned all the workers yet, show skeleton rows
    if (numWorkAssigned < numWorkersRequested) {
      const skeletonRows: Array<Work> = Array.from(
        Array(numWorkersRequested - numWorkAssigned),
        (_, index) => {
          return {
            id: -index, // id must be a number to not clash with work.id type. We use negative number to differentiate from real work ids
            worker: {
              id: 0,
              profilePicUrl: undefined,
              name: 'skeleton',
            },
            status: undefined,
          }
        }
      )
      rows = [...workAssigned, ...skeletonRows]
    }
    return rows
  }, [shift])

  const values = useMemo(
    () => ({
      shift,
      isLoading,
      isFetching,
      assignedWorkRows,
    }),
    [isLoading, isFetching, shift, assignedWorkRows]
  )

  return (
    <ShiftDetailContext.Provider value={values}>
      {children}
    </ShiftDetailContext.Provider>
  )
}
