import styled, { keyframes } from 'styled-components'
import { LucideRefreshCw } from 'lucide-react'

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const SpinningRefreshIcon = styled(LucideRefreshCw)`
  animation: ${spin} 1s linear infinite;
`
