import { devConfig } from './development'
import { stagingConfig } from './staging'
import { EnvConfig, prodConfig } from './production'

type Env = 'development' | 'staging' | 'production'
const env = (process.env.DEPLOY_ENV || 'production') as Env
const appVersion = process.env.APP_VERSION

type Config = {
  env: string
  appVersion?: string
} & EnvConfig

export const config: Config = {
  env,
  appVersion,
  ...{
    development: devConfig,
    staging: stagingConfig,
    production: prodConfig,
  }[env],
}
