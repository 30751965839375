import { getDemoData, matchRestRequest, setDemoData } from 'api/demo/utils'
import { path } from 'ramda'
import { DemoApiMock } from 'api/demo/types'
import { CompanyWorker } from 'api/worker'

export const mockUpdateWorkerStatusForCompany: DemoApiMock = {
  shouldMock: (request) =>
    matchRestRequest(request, /\/company\/\d+\/action/, 'POST'),
  getResponse: async (request) => {
    const workerId = Number(path(['data', 'worker_id'], request))
    const action = String(path(['data', 'action'], request))
    const demoData = getDemoData()
    setDemoData({
      ...demoData,
      workers: demoData.workers.map((worker) => {
        if (worker.id === workerId) {
          return {
            ...worker,
            statusForCompany: getStatusFromAction(action),
          } as CompanyWorker
        }
        return worker
      }),
    })
    return null
  },
}

function getStatusFromAction(action: string) {
  switch (action) {
    case 'block':
      return 'blocked'
    case 'favorite':
      return 'favorite'
    case 'unblock':
      return ''
    case 'unfavorite':
      return ''
    default:
      return ''
  }
}
