import React, { ReactNode, useRef, useState } from 'react'
import styled from 'styled-components'

interface Props {
  children: ReactNode
}

function DraggableScrollComponent({ children }: Props) {
  const [startPosition, setStartPosition] = useState(0)
  const [isScrolling, setIsScrolling] = useState(false)
  const scrollRef = useRef<HTMLDivElement | null>(null)

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsScrolling(true)
    setStartPosition(e.clientY)
  }

  const handleMouseUp = () => {
    setIsScrolling(false)
  }

  const handleMouseMove = (e: React.MouseEvent) => {
    if (isScrolling && scrollRef.current) {
      const currentScroll = scrollRef.current.scrollTop
      const newScrollPosition = currentScroll + startPosition - e.clientY
      if (scrollRef.current) scrollRef.current.scrollTop = newScrollPosition
      setStartPosition(e.clientY)
    }
  }

  return (
    <DraggableScrollContainer
      ref={scrollRef}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseUp}
    >
      {children}
    </DraggableScrollContainer>
  )
}

export default DraggableScrollComponent

const DraggableScrollContainer = styled.div`
  overflow: auto;
  height: 100%;
  // hide scrollbar but maintain functionality
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome and Safari */
  }
  user-select: none;
`
