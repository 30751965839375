import React from 'react'
import { ShiftSimple } from 'api/shift'
import { Box, Button, Card, Flex } from 'ui'
import styled from 'styled-components'
import { getPayRate, getSupervisors } from './utils'
import { ShiftType, StepEnum } from '../../ShiftEditorProvider'
import { useShiftEditor } from '../../useShiftEditor'
import { ShiftTime } from 'components/ShiftTime'

const LineLabel = styled(Box).attrs({
  fontWeight: 2,
  width: 120,
})``

const LineValue = styled(Box).attrs({
  flex: 1,
})``

export function MobileShiftCard(props: { data: ShiftSimple }) {
  const { data } = props
  const { goTo, setShiftType, loadShiftData } = useShiftEditor()

  return (
    <Card borderWidth={0} mb={3}>
      <Flex alignItems={'center'}>
        <LineLabel>Position:</LineLabel>
        <LineValue>{data.position?.name}</LineValue>
      </Flex>
      <Flex alignItems={'center'}>
        <LineLabel>Location:</LineLabel>
        <LineValue>{data.location?.name}</LineValue>
      </Flex>
      <Flex alignItems={'center'}>
        <LineLabel>Time:</LineLabel>
        <LineValue>
          <ShiftTime shift={data} shortVersion={true} />
        </LineValue>
      </Flex>
      <Flex alignItems={'center'}>
        <LineLabel>Pay:</LineLabel>
        <LineValue>{getPayRate(data)}</LineValue>
      </Flex>
      <Flex alignItems={'center'}>
        <LineLabel>Supervisor(s):</LineLabel>
        <LineValue>{getSupervisors(data)}</LineValue>
      </Flex>
      <Button
        mt={3}
        width={[1, 200]}
        kind={'medium'}
        onClick={() => {
          setShiftType(data.isTryout ? ShiftType.Tryout : ShiftType.Regular)
          loadShiftData(data)
          goTo(StepEnum.Details)
        }}
      >
        Duplicate
      </Button>
    </Card>
  )
}
