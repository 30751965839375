import { useQuery, useQueryClient } from '@tanstack/react-query'
import { companyMembersKey, companyGroupMembersKey } from './keys'
import { getCompanyMembers, getCompanyGroupMembers } from 'api/company'

export function useCompanyMembers() {
  return useQuery({
    queryKey: companyMembersKey,
    queryFn: getCompanyMembers,
  })
}

export function useInvalidateCompanyMembers() {
  const queryClient = useQueryClient()

  return {
    invalidate: () =>
      queryClient.invalidateQueries({ queryKey: companyMembersKey }),
  }
}

export function useCompanyGroupMembers(name) {
  return useQuery({
    queryKey: [companyGroupMembersKey, name],
    queryFn: () => getCompanyGroupMembers(name),
    enabled: !!name,
  })
}
