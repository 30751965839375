import React from 'react'
import { useShiftEditor } from '../useShiftEditor'
import { useCompanyPosition } from 'hooks/useCompanyPosition'
import { Box, Heading, Paragraph } from 'ui'

export function AboutSection() {
  const { shiftData } = useShiftEditor()
  const position = useCompanyPosition(shiftData?.details?.positionId)

  if (!position) {
    return null
  }

  return (
    <Box boxShadow={'low'} borderRadius={'standard'} bg={'white'} p={3} mx={3}>
      <Heading level={4} my={0} mb={2}>
        About
      </Heading>
      <Paragraph fontSize={0} lineHeight={'1.2rem'}>
        {position.about}
      </Paragraph>
    </Box>
  )
}
