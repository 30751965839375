import React, { useState } from 'react'
import { LucideMoreHorizontal } from 'lucide-react'
import { MessageWorkerModal } from '../MessageWorkerModal'
import { LocationTrackingMapModal } from '../LocationTrackingMapModal'
import { Trip, Work } from 'typings/common_defs'
import { DropdownMenu, Button, Tooltip, Box } from 'ui'
import { isShiftMessagable } from 'lib/shift'
import {
  getTripsWithShareUrl,
  SHIFT_DETAIL_WORKER_ACTIONS,
  ShiftDetailWorkerAction,
} from 'lib/work'
import { useShiftDetail } from 'pages/ShiftDetailPage/useShiftDetail'

interface Props {
  work: Work
}

export const AssignedWorkerEditModeOverflowActions = ({ work }: Props) => {
  const { shift } = useShiftDetail()
  const [isLocationTrackingMapModalOpen, setIsLocationTrackingMapModalOpen] =
    useState(false)
  const [isMessageWorkerModalOpen, setIsMessageWorkerModalOpen] =
    useState(false)

  if (!shift) {
    return null
  }

  const trips: Array<Trip> = getTripsWithShareUrl(work)

  const _getActionsToDisplay = (): Array<ShiftDetailWorkerAction> => {
    const actionsToDisplay: Array<ShiftDetailWorkerAction> = []
    if (trips.length > 0) {
      actionsToDisplay.push(SHIFT_DETAIL_WORKER_ACTIONS.viewLocation)
    }
    if (isShiftMessagable(shift)) {
      actionsToDisplay.push(SHIFT_DETAIL_WORKER_ACTIONS.message)
    }
    return actionsToDisplay
  }

  const _renderAction = (action: ShiftDetailWorkerAction) => {
    switch (action) {
      case SHIFT_DETAIL_WORKER_ACTIONS.viewLocation:
        return (
          <DropdownMenu.Item
            onSelect={() => setIsLocationTrackingMapModalOpen(true)}
          >
            View Location
          </DropdownMenu.Item>
        )
      case SHIFT_DETAIL_WORKER_ACTIONS.message:
        return (
          <DropdownMenu.Item onSelect={() => setIsMessageWorkerModalOpen(true)}>
            Message Worker
          </DropdownMenu.Item>
        )
      default:
        return null
    }
  }

  const actions: Array<ShiftDetailWorkerAction> = _getActionsToDisplay()

  if (!actions || actions.length === 0) {
    return null
  }

  return (
    <>
      <DropdownMenu
        trigger={
          <Button
            variant="secondary"
            kind={'small'}
            maxWidth={30}
            p={0}
            pl={0}
            pr={0}
          >
            <Tooltip content={'More actions'}>
              <Box
                display="flex"
                flex={1}
                justifyContent="center"
                alignItems="center"
              >
                <LucideMoreHorizontal size={16} />
              </Box>
            </Tooltip>
          </Button>
        }
      >
        {actions.map((action) => _renderAction(action))}
      </DropdownMenu>
      <LocationTrackingMapModal
        work={work}
        open={isLocationTrackingMapModalOpen}
        onClose={() => setIsLocationTrackingMapModalOpen(false)}
      />
      <MessageWorkerModal
        work={work}
        open={isMessageWorkerModalOpen}
        onClose={() => setIsMessageWorkerModalOpen(false)}
      />
    </>
  )
}
