/**
 * Pills shown when different rating is selected
 * e.g. REVIEW_PILLS_SUB_3 are shown when rating of 3 or less is selected
 */
const REVIEW_PILLS_SUB_3 = [
  'Late or Left Early',
  'Unproductive',
  'Bad attitude',
  'Distracted',
]
const REVIEW_PILLS_4 = ['Good attitude', 'Productive', 'Followed instructions']
const REVIEW_PILLS_5 = ['Great attitude', 'Very Productive', 'Above and beyond']

/**
 * Get Pills that user can select from after giving user a rating from 1-5
 * @param rating: the rating selected by user when reviewing a Work
 * @returns array of strings that represents pill items shown for that rating
 */
export const getPillItems = (
  rating: number | null | undefined
): Array<string> => {
  let pillItems = REVIEW_PILLS_SUB_3
  if (rating === 4) {
    pillItems = REVIEW_PILLS_4
  } else if (rating === 5) {
    pillItems = REVIEW_PILLS_5
  }
  return pillItems
}
