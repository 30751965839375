import { PositionData } from './schemas'
import { PositionRequest } from 'api/company'
import { PositionTemplateToWorkerRequirement } from 'typings/common_defs'

export interface NewRequirement extends PositionTemplateToWorkerRequirement {
  positionTemplateId: number
}

export function toServerData(
  data: PositionData,
  newRequirements: NewRequirement[]
): PositionRequest {
  return {
    name: data.details.positionName,
    about: data.details.description,
    positionTemplateId: data.category.positionTemplateId,
    workerRequirementIds: data.requirement.requirementIds,
    customRequirements: newRequirements.map((r) => ({
      name: r.requirement.name,
      category: r.requirement.category,
    })),
  }
}
