import React, { ReactNode, useState } from 'react'
import { User } from 'typings/common_defs'
import { StatusForCompanyEnum } from 'api/worker'
import { useUpdateWorkerStatusForCompanyMutation } from 'queries/worker'
import { useTheme } from 'styled-components'
import { Loading, Tooltip } from 'ui'
import HeartFilled from 'assets/heart-filled.svg'
import HeartOutlineSvg from 'assets/heart-outline.svg'
import { BlockModal } from './BlockModal'
import { XCircleIcon } from 'lucide-react'

interface Props {
  worker: User
  shiftId?: number
  renderButton: (buttonProps: {
    onClick: () => void
    children: ReactNode
  }) => ReactNode
  onSuccess?: () => void
}

export function WorkerActions(props: Props) {
  const [worker, setWorker] = useState(props.worker)
  const isFavorited = worker.statusForCompany === StatusForCompanyEnum.Favorite
  const isBlocked = worker.statusForCompany === StatusForCompanyEnum.Blocked
  const onSuccess = (status?: StatusForCompanyEnum) => {
    setWorker((prevWorker) => ({
      ...prevWorker,
      statusForCompany: status,
    }))
    if (props.onSuccess) {
      props.onSuccess()
    }
  }
  const { mutate: updateStatus, isPending } =
    useUpdateWorkerStatusForCompanyMutation({
      workerId: worker.id,
      onSuccess,
    })
  const { colors } = useTheme()
  const isLoadingStatus = isPending

  const onFavorite = () => updateStatus({ action: 'favorite' })
  const onUnfavorite = () => updateStatus({ action: 'unfavorite' })
  const onUnblock = () => updateStatus({ action: 'unblock' })

  return (
    <>
      {isLoadingStatus ? <Loading type={'button'} /> : null}
      {!isLoadingStatus && !isBlocked ? (
        isFavorited ? (
          <Tooltip content={'Click to unfavorite'}>
            {props.renderButton({
              onClick: onUnfavorite,
              children: (
                <img
                  width={16}
                  style={{ flexShrink: 0 }}
                  src={HeartFilled}
                  alt="favorited"
                />
              ),
            })}
          </Tooltip>
        ) : (
          <>
            <Tooltip content={'Click to favorite'}>
              {props.renderButton({
                onClick: onFavorite,
                children: (
                  <img
                    width={16}
                    style={{ flexShrink: 0 }}
                    src={HeartOutlineSvg}
                    alt="not favorited"
                  />
                ),
              })}
            </Tooltip>
            <BlockModal
              worker={worker}
              renderButton={props.renderButton}
              onSuccess={onSuccess}
            />
          </>
        )
      ) : null}
      {!isLoadingStatus && isBlocked ? (
        <Tooltip content={'Click to unblock'}>
          {props.renderButton({
            onClick: onUnblock,
            children: (
              <XCircleIcon
                style={{ flexShrink: 0 }}
                size={16}
                color={colors.warning}
              />
            ),
          })}
        </Tooltip>
      ) : null}
    </>
  )
}
