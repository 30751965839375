import React, { useEffect, useMemo } from 'react'
import {
  Box,
  Button,
  DatePicker,
  Field,
  Flex,
  Heading,
  Message,
  Select,
  Text,
} from 'ui'
import { track } from 'lib/amplitude'
import { useShiftEditor } from '../../useShiftEditor'
import { Controller, useForm } from 'react-hook-form'
import { ScheduleForm, scheduleSchema } from '../../schemas'
import { zodResolver } from '@hookform/resolvers/zod'
import { TimeSelector } from 'components/TimeSelector'
import { SelectableDuration } from 'typings/selectable_ext'
import {
  getLocalTimezone,
  getLongDuration,
  getTimezoneName,
  getW2BreakOptions,
  UNPAID_BREAK_OPTIONS,
} from 'lib/time'
import { useCompanyLocation } from 'hooks/useCompanyLocation'
import { FiClock } from 'react-icons/fi'
import { Footer } from '../../Footer'
import { useShiftTime } from '../../useShiftTime'
import { VscMegaphone } from 'react-icons/vsc'
import { useCompanyPosition } from 'hooks/useCompanyPosition'
import { EditorMode } from '../../ShiftEditorProvider'
import { addWeeks } from 'date-fns'
import { TryoutLabel } from 'components/tryouts/TryoutLabel'
import { useAuthContext } from 'components/auth'
import { useLunchLabel } from '../../useLunchLabel'
import { useUpdateEffect } from 'ahooks'
import { useW2MinimumLunchLength } from 'queries/company/useW2MinimumLunchLength'

export function SchedulePage() {
  const { shiftData, isNew, editorMode, isTryout, setShiftData, goNext } =
    useShiftEditor()
  const { company } = useAuthContext()
  const isPastShift = editorMode === EditorMode.PastShift
  const allowMultipleDays = isNew && editorMode === EditorMode.Standard

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<ScheduleForm>({
    resolver: zodResolver(scheduleSchema),
    defaultValues: {
      isW2: company?.w2 === true,
      orientationShift: shiftData?.orientationShift,
      ...shiftData?.schedule,
    } as ScheduleForm,
  })

  const selectedLocation = useCompanyLocation(shiftData?.details?.locationId)
  const selectedDays = watch('selectedDays')
  const startTime = watch('startTime')
  const endTime = watch('endTime')
  const position = useCompanyPosition(shiftData?.details?.positionId)
  const lunchLength = watch('lunchLength')
  const isW2 = watch('isW2')

  // Orientation shift days are sorted in ascending order
  const lastDayOfOrientation = watch('orientationShift')?.selectedDays?.at(-1)

  const timezone = selectedLocation?.address?.timezone ?? getLocalTimezone()
  const {
    isOvernight,
    spansDaylightSaving,
    totalDurationInMinutes,
    startsAt,
    endsAt,
  } = useShiftTime({
    date: selectedDays?.[0],
    startTime: startTime?.value,
    endTime: endTime?.value,
    lunchLength: lunchLength?.value,
    timezone,
  })

  const { data: minimumLunchLength = 0 } = useW2MinimumLunchLength({
    locationId: selectedLocation?.id,
    startTime: startsAt ?? undefined,
    endTime: endsAt ?? undefined,
  })

  const breakOptions = useMemo(
    () => (isW2 ? getW2BreakOptions(minimumLunchLength) : UNPAID_BREAK_OPTIONS),
    [isW2, minimumLunchLength]
  )

  const lunchLabel = useLunchLabel({
    isW2: isW2 ?? false,
    minimumLunchLength,
  })

  const onSubmit = handleSubmit((data) => {
    setShiftData({
      schedule: {
        ...data,
        timezone,
      },
    })
    goNext()
  })

  useEffect(() => {
    track('impression, ShiftEditor_Dates_page')
  }, [])

  useUpdateEffect(() => {
    if (isW2 && breakOptions.length > 0) {
      setValue('lunchLength', breakOptions[0] as Required<SelectableDuration>)
    }
  }, [isW2, breakOptions])

  return (
    <Box>
      <Heading level={[2, 1]} my={4}>
        Enter Dates & Time
      </Heading>
      <Box width={[1, 1, '65rem']}>
        <form onSubmit={onSubmit}>
          {isTryout ? (
            <Box mb={3}>
              <TryoutLabel />
            </Box>
          ) : null}
          <Field
            label={
              <Heading level={3} my={1}>
                {isTryout
                  ? 'Select Tryout Date'
                  : isPastShift
                  ? 'Select the Date the Shift occurred'
                  : 'Select Date'}
              </Heading>
            }
            error={errors.selectedDays?.message?.toString()}
            mb={4}
          >
            <Controller
              name={'selectedDays'}
              control={control}
              render={({ field }) => (
                <DatePicker
                  mode={allowMultipleDays ? 'multiple' : 'single'}
                  disabled={
                    isPastShift
                      ? {
                          after: new Date(),
                          before: addWeeks(new Date(), -3),
                        }
                      : {
                          before: lastDayOfOrientation
                            ? lastDayOfOrientation
                            : new Date(),
                        }
                  }
                  selected={field.value as Date[] | undefined}
                  onSelect={(days) =>
                    field.onChange(days instanceof Date ? [days] : days)
                  }
                  placeholder={
                    allowMultipleDays
                      ? 'Select Date - You can select multiple days'
                      : 'Select Date'
                  }
                />
              )}
            />
            {isTryout ? (
              <Message
                variant={'fixed'}
                py={3}
                icon={<VscMegaphone fontSize={18} />}
              >
                <Text fontWeight={2} mb={2}>
                  Tryout Schedule
                </Text>
                <Text>
                  Select the days when your Tryout will take place. We’ll only
                  send you workers who haven't yet worked your&nbsp;
                  {position?.name ?? 'selected'}
                  &nbsp;position. During the tryout, you’ll assess each worker
                  and either keep or remove them from the&nbsp;
                  {position?.name ?? 'selected'} List.
                </Text>
              </Message>
            ) : null}
          </Field>
          <Heading level={3}>
            {isTryout ? 'Select Tryout Time' : 'Select Time'}
          </Heading>
          <Flex flexDirection={['column', 'row']}>
            <Field
              width={[1, 1 / 2]}
              mr={[0, 2]}
              label={'Start time'}
              error={errors.startTime?.message?.toString()}
            >
              <Controller
                name={'startTime'}
                control={control}
                render={({ field }) => (
                  <TimeSelector
                    aria-label={'Select Start Time'}
                    placeholder={'Select a start time'}
                    value={field.value}
                    from={'07:00'}
                    date={selectedDays?.[0]}
                    timezone={timezone}
                    onChange={field.onChange}
                  />
                )}
              />
            </Field>
            <Field
              width={[1, 1 / 2]}
              ml={[0, 2]}
              label={'End time'}
              error={errors.endTime?.message?.toString()}
            >
              <Controller
                name={'endTime'}
                control={control}
                render={({ field }) => (
                  <TimeSelector
                    aria-label={'Select End Time'}
                    placeholder={'Select an end time'}
                    value={field.value}
                    from={startTime?.value}
                    date={selectedDays?.[0]}
                    timezone={timezone}
                    showDuration={true}
                    onChange={field.onChange}
                  />
                )}
              />
            </Field>
          </Flex>
          <Message hideIcon={true} variant={'fixed'} systemLevel={false}>
            <Flex alignItems={'center'} flexDirection={'row'}>
              <FiClock />
              <Box ml={2}>
                Time zone for this shift is{' '}
                {getTimezoneName(timezone, selectedDays?.[0])}
              </Box>
            </Flex>
          </Message>
          {isOvernight && totalDurationInMinutes ? (
            <Message
              variant={'warning'}
              systemLevel={false}
              mt={3}
              width={'100%'}
            >
              This shift is currently scheduled for{' '}
              <strong>{getLongDuration(totalDurationInMinutes)}</strong>{' '}
              (excluding unpaid breaks). If this is incorrect, please correct
              the Start or End time.
            </Message>
          ) : null}
          {spansDaylightSaving ? (
            <Message
              variant={'warning'}
              systemLevel={false}
              mt={3}
              width={'100%'}
            >
              This shift spans a daylight saving time change. Please double
              check the Start and End times.
            </Message>
          ) : null}

          <Field
            mt={3}
            label={
              <Flex alignItems="center">
                <Text fontWeight="bold" mr={2} style={{ flex: 1 }}>
                  {lunchLabel}
                </Text>
                {isW2 ? (
                  <Button
                    variant="text"
                    size="small"
                    as="a"
                    href="https://www.dol.gov/agencies/whd/state/meal-breaks"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    More info
                  </Button>
                ) : null}
              </Flex>
            }
            error={errors.lunchLength?.message?.toString()}
          >
            <Controller
              name={'lunchLength'}
              control={control}
              render={({ field }) => (
                <Select
                  aria-label={'Select Break Time'}
                  placeholder={'Select a break time'}
                  value={field.value}
                  options={breakOptions}
                  onChange={(option) => {
                    field.onChange(option)
                  }}
                />
              )}
            />
          </Field>

          <Footer />
        </form>
      </Box>
    </Box>
  )
}
