import React from 'react'
import { RenderOptionProps } from '@workwhile/ui'
import { Box, Text } from 'ui'
import { AudienceType } from '../../schemas'
import { Roster } from './Roster'
import { RSW } from './RSW'
import { RadioOption } from '../../RadioOption'

export function AudienceTypeOption(props: RenderOptionProps) {
  const { indicator, isSelected, option } = props
  return (
    <RadioOption $isSelected={isSelected}>
      {indicator}
      <Box flex={1} ml={3} pr={4} className={'option-content'}>
        <Text fontWeight={2} className={'option-title'}>
          {option.label}
        </Text>
        {option.value === AudienceType.Default ? (
          <Text mt={2}>
            Intelligently target preferred workers. Favorites are notified
            first, followed by experienced workers before we offer to the broad
            marketplace.
          </Text>
        ) : null}
        {option.value === AudienceType.Roster && isSelected ? <Roster /> : null}
        {option.value === AudienceType.SpecificWorkers && isSelected ? (
          <RSW />
        ) : null}
      </Box>
    </RadioOption>
  )
}
