import * as zod from 'zod'

export enum AudienceType {
  Default = 'Default',
  Roster = 'Roster',
  SpecificWorkers = 'SpecificWorkers',
}

export const audienceTypeEnum = zod.nativeEnum(AudienceType, {
  required_error: 'Please select an audience type',
})

export enum SupervisorRequestType {
  ExistingUser = 'existing_user',
  InvitedTeamMember = 'invited_team_member',
  FreeForm = 'free_form',
  ExistingShiftSupervisor = 'existing_shift_supervisor',
}

export const supervisorRequestTypeEnum = zod.nativeEnum(SupervisorRequestType, {
  required_error: 'Please select a supervisor type',
})

export const baseShiftSupervisorSchema = zod.object({
  id: zod.number(),
  name: zod.string().nonempty('Please enter a name'),
  phoneNumber: zod.string().nullable().optional(),
  email: zod.string().optional(),
  roleId: zod.number().nullable().optional(),
  requestType: supervisorRequestTypeEnum.default(
    SupervisorRequestType.FreeForm
  ),
  sendInvite: zod.boolean().default(true).optional(),
})

export const shiftSupervisorSchema = baseShiftSupervisorSchema
  .refine((data) => !data.sendInvite || data.email, {
    message: 'Email required for invite',
    path: ['email'], // path of error
  })
  .refine((data) => !data.sendInvite || data.roleId, {
    message: 'Role required for invite',
    path: ['roleId'], // path of error
  })

const partialShiftSupervisorSchema = baseShiftSupervisorSchema.partial()

export type ShiftSupervisor = zod.infer<typeof shiftSupervisorSchema>
export type PartialShiftSupervisor = zod.infer<
  typeof partialShiftSupervisorSchema
>

export const staffSchema = zod
  .object({
    numWorkers: zod.coerce
      .number({ invalid_type_error: 'Please enter a number' })
      .min(1, 'Please select at least one worker'),
    audienceType: audienceTypeEnum.default(AudienceType.Default),
    supervisors: zod
      .array(shiftSupervisorSchema)
      .min(1, 'Please select at least one supervisor'),
    rosterIds: zod.array(zod.number()).optional(),
    restrictShiftToAudience: zod.boolean().default(false),
    specificWorkers: zod.array(zod.any()).optional(),
    numShiftLeads: zod.coerce.number().optional(),
    shiftLeadEarlyStartTime: zod
      .object({
        label: zod.string(),
        value: zod.number(),
      })
      .optional(),
    shiftLeadLateEndTime: zod
      .object({
        label: zod.string(),
        value: zod.number(),
      })
      .optional(),
  })
  .refine(
    (data) =>
      data.audienceType !== AudienceType.Roster ||
      (data.rosterIds || []).length > 0,
    {
      message:
        'Please select at least one eligible list or change your priority settings to Smart Shift Fulfillment',
      path: ['rosterIds'], // path of error
    }
  )
  .refine(
    (data) =>
      data.audienceType !== AudienceType.SpecificWorkers ||
      (data.specificWorkers || []).length > 0,
    {
      message:
        'Please select at least one eligible worker or change your priority settings to Smart Shift Fulfillment',
      path: ['specificWorkers'], // path of error
    }
  )
  .refine(
    (data) => {
      return (
        data.audienceType !== AudienceType.SpecificWorkers ||
        !data.restrictShiftToAudience ||
        (data.specificWorkers || []).length >= data.numWorkers
      )
    },
    {
      message:
        'The the number of Specific Workers selected must be greater than or equal to the number of workers.',
      path: ['specificWorkers'], // path of error
    }
  )

export type StaffForm = zod.infer<typeof staffSchema>
