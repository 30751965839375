import { useQuery, useQueryClient } from '@tanstack/react-query'
import { reviewTryoutsKey } from './keys'
import { getReviewTryoutsShifts } from 'api/review'

export function useReviewTryoutShifts(options?: { enabled?: boolean }) {
  return useQuery({
    queryKey: reviewTryoutsKey,
    queryFn: getReviewTryoutsShifts,
    ...options,
  })
}

export function useInvalidateReviewTryoutShifts() {
  const queryClient = useQueryClient()

  return {
    invalidate: () =>
      queryClient.invalidateQueries({
        queryKey: reviewTryoutsKey,
      }),
  }
}
