import React from 'react'
import { Work } from 'typings/common_defs'
import { Modal, Text } from 'ui'

interface Props {
  work: Work
  trigger: React.ReactNode
}

export const AdminReviewExplainerModal = ({ trigger, work }: Props) => {
  return (
    <Modal title={'Worker being reviewed'} trigger={trigger} width={[1, 900]}>
      <Text mb={2}>
        We noticed out of the ordinary behavior from {work?.worker?.name} and
        are reviewing their work to accordingly take action. Don't worry, you
        will only be charged for the hours they actually worked.
      </Text>
    </Modal>
  )
}
