import React from 'react'
import { Box, Carousel, Heading, Pluralize } from 'ui'
import { useShiftDetail } from '../useShiftDetail'
import { LeadShiftCard } from './LeadShiftCard'

export const ShiftDetailLeads = () => {
  const { shift } = useShiftDetail()

  if (!shift) return null

  const leadShifts = shift?.leadShifts ?? []

  if (!leadShifts || leadShifts.length === 0) return null

  const numLeadsRequested = leadShifts.reduce((acc, leadShift) => {
    return acc + (leadShift?.workersNeeded || 0)
  }, 0)

  return (
    <Box mb={2}>
      <Heading level={2} fontWeight={2}>
        Shift{' '}
        <Pluralize
          hideCount
          count={numLeadsRequested}
          oneText="Lead"
          manyText="Leads"
        />
      </Heading>
      <Carousel mx={-2} py={3} cardsToShow={[1, 2, 2, 3, 4]} gutter={2}>
        {leadShifts.map((leadShift) => (
          <LeadShiftCard key={leadShift.id} leadShift={leadShift} />
        ))}
      </Carousel>
    </Box>
  )
}
