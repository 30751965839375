import { PaginatedShiftTimeline, TimelineShift } from 'api/shift'
import { useEffect, useState } from 'react'
import { addDays, endOfDay, format, startOfDay } from 'date-fns'
import { useHomeContext } from './useHomeContext'
import { InfiniteData } from '@tanstack/react-query'

export const dateIdFormat = 'yyyy-MM-dd'
const daysIncrement = 50

interface Props {
  data?: InfiniteData<PaginatedShiftTimeline>
  hasNextPage?: boolean
  hasPreviousPage?: boolean
}

export function useShiftDates(props: Props) {
  const { data, hasNextPage } = props
  const { referenceDate } = useHomeContext()
  const [lowerBound, setLowerBound] = useState<Date | null>(null)
  const [upperBound, setUpperBound] = useState<Date | null>(null)
  const [allShifts, setAllShifts] = useState<TimelineShift[] | null>(null)
  const [shiftsByDate, setShiftsByDate] = useState<Record<
    string,
    TimelineShift[]
  > | null>(null)

  useEffect(() => {
    if (!data) return

    const shifts = (
      data?.pages.reduce((acc, page) => {
        return [...acc, ...page.items]
      }, [] as TimelineShift[]) || []
    ).sort(
      (a, b) => new Date(a.startsAt).getTime() - new Date(b.startsAt).getTime()
    )

    const firstDate = startOfDay(
      shifts.length > 0 ? new Date(shifts[0].startsAt) : referenceDate
    )
    const lastDate = endOfDay(
      shifts.length > 0
        ? new Date(shifts[shifts.length - 1].startsAt)
        : referenceDate
    )

    setLowerBound((prevBound) => {
      if (prevBound) {
        return prevBound < firstDate ? prevBound : firstDate
      }
      return firstDate < referenceDate ? firstDate : referenceDate
    })

    setUpperBound((prevBound) => {
      if (prevBound) {
        return prevBound > lastDate ? prevBound : lastDate
      }
      if (hasNextPage) {
        return lastDate > referenceDate ? lastDate : referenceDate
      }
      return addDays(lastDate, daysIncrement)
    })

    setAllShifts(shifts)
  }, [referenceDate, data?.pages, hasNextPage])

  useEffect(() => {
    if (!lowerBound || !upperBound || !allShifts) return
    const obj: Record<string, TimelineShift[]> = {}
    let curDate = lowerBound
    while (curDate < upperBound) {
      const dateId = format(curDate, dateIdFormat)
      obj[dateId] = allShifts.filter(
        (shift) => format(new Date(shift.startsAt), dateIdFormat) === dateId //format(new Date(shift.startsAt), 'yyyy-MM-dd') === dateId
      )
      curDate = addDays(curDate, 1)
    }

    setShiftsByDate(obj)
  }, [lowerBound, upperBound, allShifts])

  return {
    allShifts,
    shiftsByDate,
    upperBound,
    lowerBound,
    increaseLowerBound: () =>
      setLowerBound(addDays(lowerBound ?? referenceDate, -daysIncrement)),
    increaseUpperBound: () => {
      setUpperBound(addDays(upperBound ?? referenceDate, daysIncrement))
    },
  }
}
