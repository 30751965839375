import { api, graphql } from 'api'
import { path } from 'ramda'
import { MonthlyChargeLimit, MonthTotalCharge } from 'typings/common_defs'

export async function getChargeLimits() {
  const response = await graphql(
    `company {
          monthlyChargeLimit {spendLimit, bonusLimit},
          monthTotalCharge {monthTotalSpend, monthTotalBonus},
        }`,
    'current_company'
  )

  const data = path(['data', 'data', 'company'], response)

  return {
    monthlyChargeLimit: data.monthlyChargeLimit as MonthlyChargeLimit,
    monthTotalCharge: data.monthTotalCharge as MonthTotalCharge,
  }
}

export function updateSpendLimit(companyId: number, spendLimit: number) {
  return api.put(`/company/${companyId}/add_spend_limit`, {
    spend_limit: spendLimit,
  })
}

export function updateBonusLimit(companyId: number, bonusLimit: number) {
  return api.put(`/company/${companyId}/add_bonus_limit`, {
    bonus_limit: bonusLimit,
  })
}
