import React from 'react'
import { Button, Flex } from 'ui'
import { useShiftEditor } from './useShiftEditor'
import { StepEnum } from './ShiftEditorProvider'
import { ReduceWorkersModal } from './ReduceWorkersModal'

export function Footer(props: { ctaText?: string }) {
  const {
    isSaving,
    isShiftSaved,
    currentStep,
    confirmationSeen,
    hasRequiredData,
    shiftData,
    editData,
  } = useShiftEditor()
  const showBackToConfirm =
    confirmationSeen && hasRequiredData() && currentStep !== StepEnum.Confirm

  const isReducingWorkers =
    shiftData.staff?.numWorkers &&
    editData?.workersNeeded &&
    shiftData.staff?.numWorkers < editData?.workersNeeded

  return (
    <Flex justifyContent={'center'}>
      {isReducingWorkers && currentStep === StepEnum.Confirm ? (
        <ReduceWorkersModal
          shift={editData}
          trigger={
            <Button
              width={'100%'}
              my={[4, 5]}
              type={'button'}
              loading={isSaving}
              disabled={isShiftSaved}
            >
              {props.ctaText ??
                (showBackToConfirm ? 'Back to Summary' : 'Next')}
            </Button>
          }
        />
      ) : (
        <Button
          width={[1, 1, 1 / 3]}
          my={[4, 5]}
          type={'submit'}
          loading={isSaving}
          disabled={isShiftSaved}
        >
          {props.ctaText ?? (showBackToConfirm ? 'Back to Summary' : 'Next')}
        </Button>
      )}
    </Flex>
  )
}
