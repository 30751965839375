import { useQuery, useQueryClient } from '@tanstack/react-query'
import { reviewOvertimeApprovalKey } from './keys'
import { getReviewOvertimeApprovalShifts } from 'api/review'

export function useReviewOvertimeApprovalShifts(options?: {
  enabled?: boolean
}) {
  return useQuery({
    queryKey: reviewOvertimeApprovalKey,
    queryFn: getReviewOvertimeApprovalShifts,
    ...options,
  })
}

export function useInvalidateReviewOvertimeApprovalShifts() {
  const queryClient = useQueryClient()

  return {
    invalidate: () =>
      queryClient.invalidateQueries({
        queryKey: reviewOvertimeApprovalKey,
      }),
  }
}
