import React from 'react'
import { useAnalyticsContext } from '../useAnalyticsContext'
import { Avatar, Box, Flex, Pluralize, Text } from 'ui'
import { BriefcaseIcon, StarIcon } from 'lucide-react'

export function TopWorkers() {
  const { insight } = useAnalyticsContext()
  const workers = insight?.workerBench.topWorkers ?? []

  return (
    <Box>
      {workers.map((worker, index) => (
        <Flex
          key={worker.worker.id}
          borderStyle={'solid'}
          borderColor={'neutrals.100'}
          borderTopWidth={index === 0 ? 0 : 1}
          py={3}
          alignItems={'center'}
        >
          <Avatar
            size={'medium'}
            user={{
              userId: worker.worker.id.toString(),
              name: worker.worker.name,
              photoUrl: worker.worker.profilePicUrl,
            }}
          />
          <Box pl={3} flex={1}>
            <Text fontWeight={2}>{worker.worker.name}</Text>
            <Box>
              <Flex flexDirection={'row'}>
                <Box
                  width={[1, 100]}
                  display={'inline-flex'}
                  alignItems={'center'}
                >
                  <BriefcaseIcon size={16} />
                  &nbsp;&nbsp;
                  <Pluralize
                    count={worker.totalShifts ?? 0}
                    oneText={'shift'}
                    manyText={'shifts'}
                  />
                </Box>
                <Box
                  width={[1, 'auto']}
                  ml={[0, 3]}
                  display={'inline-flex'}
                  alignItems={'center'}
                >
                  <StarIcon size={16} />
                  &nbsp;&nbsp;
                  {worker.worker.rating?.toFixed(2)}
                </Box>
              </Flex>
            </Box>
          </Box>
        </Flex>
      ))}
    </Box>
  )
}
