import { api } from 'api'

export interface HypotheticalShift {
  start: string
  end: string
  positionId: number
  locationId: number
}

export interface WorkerAvailabilityRequest {
  workerIds: number[]
  hypotheticalShifts?: Array<HypotheticalShift | null>
  shiftIds?: number[]
}

export interface WorkerAvailability {
  workerId: number
  shift: HypotheticalShift
  available: boolean
  reasons?: string[]
}

interface WorkerAvailabilityRequestParams extends WorkerAvailabilityRequest {
  companyId: number
}

export const getWorkerAvailability = async ({
  companyId,
  workerIds,
  hypotheticalShifts,
  shiftIds,
}: WorkerAvailabilityRequestParams) => {
  // if any fields are missing, quietly terminate
  if (!companyId || !workerIds) {
    return
  }

  const data = await api.post(`/company/${companyId}/worker_availability`, {
    worker_ids: workerIds,
    hypothetical_shifts: hypotheticalShifts?.map((shift) =>
      shift
        ? {
            start: shift.start,
            end: shift.end,
            position_id: shift.positionId,
            location_id: shift.locationId,
          }
        : null
    ),
    shift_ids: shiftIds,
  })
  const availabilities = data.data.worker_availabilities

  return availabilities.map((availability) => ({
    workerId: availability.worker_id,
    shift: availability.shift,
    available: availability.available,
    reasons: availability.reasons,
  })) as WorkerAvailability[]
}
