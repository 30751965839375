import { useAuthContext } from 'components/auth'
import { useMutation } from '@tanstack/react-query'
import { removeShiftTutorial, RemoveShiftTutorialRequest } from 'api/shift'

type MutationPayload = Pick<
  RemoveShiftTutorialRequest,
  'tutorialId' | 'propagate'
>

export function useRemoveShiftTutorialMutation(options: {
  shiftId: number
  onSuccess?: () => void
  onError?: (error: Error) => void
}) {
  const { shiftId, onSuccess, onError } = options
  const companyId = useAuthContext().company?.id as number
  return useMutation({
    mutationFn: (request: MutationPayload) =>
      removeShiftTutorial({ ...request, companyId, shiftId }),
    onSuccess,
    onError,
  })
}
