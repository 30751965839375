import React from 'react'
import EmptyListAsset from 'assets/empty-lists.png'
import { Box, Flex, Heading, Text } from 'ui'

export function ListEmptyState() {
  return (
    <Flex
      mt={-3}
      borderRadius={'standard'}
      backgroundColor={'emptyState'}
      p={3}
      alignItems={'center'}
      flexWrap={'wrap'}
    >
      <img src={EmptyListAsset} style={{ maxWidth: '100%' }} alt={'No lists'} />
      <Box width={[1, 'auto']} ml={[0, 4]}>
        <Heading level={4} color={'lightText'} my={0}>
          Add your favorite workers to a list
        </Heading>
        <Text color={'lightText'}>
          Create custom Lists of workers you’d like to reserve or organize for
          any shift.
        </Text>
      </Box>
    </Flex>
  )
}
