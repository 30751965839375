import React, { useState } from 'react'
import { useUpdateEmailPreferenceMutation, useUserProfile } from 'queries/user'
import {
  Accordion,
  Box,
  Button,
  Flex,
  Link,
  Loading,
  Select,
  Text,
  toast,
} from 'ui'
import { useEmailPreferences } from './useEmailPreferences'

export function ProfileInfo() {
  const { data, isLoading } = useUserProfile()
  const emailPreferences = useEmailPreferences(data)
  const [updatingPref, setUpdatingPref] = useState('')
  const { mutate: updatePreference } = useUpdateEmailPreferenceMutation({
    onSuccess: () => {
      toast.success('Email preferences updated')
      setUpdatingPref('')
    },
    onError: (err) => {
      toast.error(err.message)
    },
  })

  if (isLoading || !data) {
    return <Loading />
  }

  return (
    <Box>
      <Flex
        flexWrap={'wrap'}
        p={3}
        borderBottom={'1px solid'}
        borderBottomColor={'neutrals.100'}
      >
        <Box width={[1, 1, 1, '25%']}>{data.name}</Box>
        <Box width={[1, 1, 1, '30%']}>{data.companyRolePerm.description}</Box>
        <Box width={[1, 1, 1, '35%']}>{data.email}</Box>
        <Box width={[1, 1, 1, '10%']} textAlign={'right'} mt={[3, 3, 3, 0]}>
          <Link to={'/sign-out'}>
            <Button kind={'small'}>Sign out</Button>
          </Link>
        </Box>
      </Flex>
      <Box
        px={3}
        py={2}
        borderBottom={'1px solid'}
        borderBottomColor={'neutrals.100'}
      >
        <Accordion
          type={'single'}
          items={[
            {
              trigger: 'Email Preferences',
              content: emailPreferences.map((preference, index) => (
                <Flex
                  key={preference.type}
                  flexWrap={'wrap'}
                  alignItems={'center'}
                  p={3}
                  pl={0}
                  pr={0}
                  borderBottom={
                    index < emailPreferences.length - 1 ? '1px solid' : 'none'
                  }
                  borderBottomColor={'neutrals.100'}
                >
                  <Box pl={[0, 0, 0, 3]} width={[1, 1, 1, '25%']}>
                    {preference.name}
                  </Box>
                  <Box width={[1, 1, 1, '60%']}>
                    <Text fontSize={1} color={'lightText'}>
                      {preference.description}
                    </Text>
                  </Box>
                  <Box width={[1, 1, 1, '15%']} mt={[3, 3, 3, 0]}>
                    <Select
                      block={true}
                      isLoading={updatingPref === preference.type}
                      options={preference.options}
                      placeholder={'Frequency'}
                      defaultValue={preference.options.find(
                        (option) => option.value === preference.selectedValue
                      )}
                      menuPortalTarget={document.body}
                      onChange={(option) => {
                        const { value } = option as { value?: string }
                        if (value) {
                          setUpdatingPref(preference.type)
                          updatePreference({
                            type: preference.type,
                            frequency: value,
                          })
                        }
                      }}
                    />
                  </Box>
                </Flex>
              )),
            },
          ]}
        />
      </Box>
    </Box>
  )
}
