import { ReactNode } from 'react'
import {
  PartialShiftData,
  detailsSchema,
  scheduleSchema,
  staffSchema,
  paymentSchema,
  orientationShiftSchema,
} from './schemas'

export enum EditorMode {
  Standard = 'Create', // Create or Edit (Edit is implied if shiftId is provided)
  PastShift = 'PastShift',
}

export enum ShiftType {
  Regular = 'Regular',
  Tryout = 'Tryout',
  LongTermAssignment = 'LongTermAssignment',
}

export enum StepEnum {
  Start = 'Start',
  Details = 'Details',
  Schedule = 'Schedule',
  Staff = 'Staff',
  Payment = 'Payment',
  Confirm = 'Confirm',
  // These pages are only used for Long Term Assignment
  OrientationShift = 'Orientation Shift',
  WorkShift = 'Work Shift',
}

export interface Step {
  name: StepEnum
  route: string
  page: ReactNode
  schemaKey?: keyof PartialShiftData
  schema?:
    | typeof detailsSchema
    | typeof scheduleSchema
    | typeof orientationShiftSchema
    | typeof staffSchema
    | typeof paymentSchema
}
