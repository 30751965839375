import { useQuery, useQueryClient } from '@tanstack/react-query'
import { companyRostersKey } from './keys'
import { getCompanyRosters } from 'api/worker'

export function useCompanyRosters(options?: { enabled?: boolean }) {
  return useQuery({
    queryKey: companyRostersKey,
    queryFn: getCompanyRosters,
    ...options,
  })
}

export function useInvalidateCompanyRosters() {
  const queryClient = useQueryClient()

  return {
    invalidate: () =>
      queryClient.invalidateQueries({
        queryKey: companyRostersKey,
      }),
  }
}
