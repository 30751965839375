import React from 'react'
import EmptyMessageAsset from 'assets/empty-messages.svg'
import { Box, Flex, Heading, Text } from 'ui'
import { SendHorizonalIcon } from 'lucide-react'
import { useTheme } from 'styled-components'

export function MessageEmptyState() {
  const { colors } = useTheme()

  return (
    <Flex
      width={[0, 0, 0, 'auto']}
      flexDirection={'column'}
      mt={1}
      ml={[0, 0, 0, 5]}
      boxShadow={'medium'}
      borderRadius={'large'}
      overflow={'hidden'}
      backgroundColor={'white'}
    >
      <Box height={72} backgroundColor={'offWhite'} />
      <Flex
        flex={1}
        m={3}
        flexDirection={'column'}
        borderRadius={'standard'}
        backgroundColor={'emptyState'}
        px={3}
        py={6}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <img
          src={EmptyMessageAsset}
          style={{ maxWidth: '100%', height: 77 }}
          alt={'No workers'}
        />
        <Box width={[1, 'auto']} mt={3} px={[2, 6]}>
          <Heading level={4} color={'lightText'} textAlign={'center'}>
            Communicate with your workers
          </Heading>
          <Text color={'lightText'} textAlign={'center'}>
            Send important messages to workers during an active shift.
          </Text>
        </Box>
      </Flex>
      <Flex
        m={3}
        mt={0}
        border={'1px solid'}
        borderColor={'neutrals.200'}
        borderRadius={'standard'}
        backgroundColor={'offWhite'}
        alignItems={'center'}
        pr={3}
      >
        <input
          style={{ padding: '10px 15px', flex: 1 }}
          disabled={true}
          placeholder={'Type a message'}
        />
        <SendHorizonalIcon size={20} color={colors.primary} />
      </Flex>
    </Flex>
  )
}
