import { CompanyRoster } from 'api/worker'
import { useMemo } from 'react'

export function useUniqueWorkersCount(rosters?: CompanyRoster[]) {
  const uniqueWorkersCount = useMemo(() => {
    if (!rosters) return 0
    const workers = rosters.reduce((acc: number[], roster) => {
      if (roster) {
        return [...acc, ...roster.workers.map((worker) => worker.id)]
      }
      return acc
    }, [])
    return new Set(workers).size
  }, [rosters])

  return uniqueWorkersCount
}
