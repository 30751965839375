import * as zod from 'zod'
import { detailsSchema } from './detailsSchema'
export * from './detailsSchema'
import { requirementSchema } from './requirementSchema'
export * from './requirementSchema'
import { categorySchema } from './categorySchema'
export * from './categorySchema'

export const positionSchema = zod.object({
  category: categorySchema,
  details: detailsSchema,
  requirement: requirementSchema,
})

export const deepPartialPositionSchema = positionSchema.deepPartial()
export const partialPositionSchema = positionSchema.partial()

export type PositionData = zod.infer<typeof positionSchema>
export type PartialPositionData = zod.infer<typeof partialPositionSchema>
export type DeepPartialPositionData = zod.infer<
  typeof deepPartialPositionSchema
>
