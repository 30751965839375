import React from 'react'
import { Box, Flex, DatePicker, Button } from 'ui'
import { useLogsContext } from './useLogsContext'
import { TimeSelector } from 'components/TimeSelector'
import { QUARTER_HOUR_INTERVAL_OPTIONS } from 'lib/time'

export function RangePicker() {
  const {
    startDate,
    endDate,
    startTimeStr,
    endTimeStr,
    setStartDate,
    setEndDate,
    setStartTime,
    setEndTime,
    fetchLogs,
  } = useLogsContext()
  return (
    <Flex flexWrap={'wrap'}>
      <Box width={[1 / 2, 1 / 2, 1 / 2, '20%']} pr={3}>
        <DatePicker
          mode={'single'}
          selected={startDate}
          placeholder={'Start Date'}
          onSelect={(date) => {
            if (date) {
              setStartDate(date as Date)
            }
          }}
        />
      </Box>
      <Box width={[1 / 2, 1 / 2, 1 / 2, '18%']} pr={[0, 3]}>
        <TimeSelector
          placeholder={'Select Time'}
          menuPortalTarget={document.body}
          value={QUARTER_HOUR_INTERVAL_OPTIONS.find(
            (option) => option.value === startTimeStr
          )}
          onChange={(option) => {
            const { value } = option as { value: string }
            if (value) {
              setStartTime(value)
            }
          }}
        />
      </Box>
      <Box p={2} width={[1, 1, 1, 45]}>
        To
      </Box>
      <Box width={[1 / 2, 1 / 2, 1 / 2, '20%']} pr={3}>
        <DatePicker
          mode={'single'}
          selected={endDate}
          placeholder={'End Date'}
          onSelect={(date) => {
            if (date) {
              setEndDate(date as Date)
            }
          }}
        />
      </Box>
      <Box width={[1 / 2, 1 / 2, 1 / 2, '18%']} pr={[0, 3]}>
        <TimeSelector
          placeholder={'Select Time'}
          menuPortalTarget={document.body}
          value={QUARTER_HOUR_INTERVAL_OPTIONS.find(
            (option) => option.value === endTimeStr
          )}
          onChange={(option) => {
            const { value } = option as { value: string }
            if (value) {
              setEndTime(value)
            }
          }}
        />
      </Box>
      <Box
        width={[1, 1, 1, 'auto']}
        flex={['auto', 'auto', 'auto', 1]}
        pt={[3, 3, 3, 0]}
      >
        <Button block={true} onClick={fetchLogs}>
          Find Range
        </Button>
      </Box>
    </Flex>
  )
}
