import { getDemoData, matchGraphqlRequest } from '../utils'

export const mockGetDuplicateShifts = {
  shouldMock: (request) =>
    matchGraphqlRequest(request, /company\{shiftDuplicateRecommendations/),
  getResponse: async () => {
    const demoData = getDemoData()
    return {
      data: {
        company: {
          shiftDuplicateRecommendations: demoData.shifts.slice(
            demoData.shifts.length - 10
          ),
        },
      },
    }
  },
}
