import styled from 'styled-components'

/**
 * This is a component that is used to make an image look like it is inset in a frame.
 * Usage:
 * <PictureContainer>
 *  <img src={imageSrc} />
 *  <PictureFrameEffect />
 * </PictureContainer>
 */

export const PictureFrameEffect = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: inset 0px -14px 5px rgba(0, 0, 0, 0.01),
    inset 0px -8px 5px rgba(0, 0, 0, 0.03),
    inset 0px -3px 3px rgba(0, 0, 0, 0.04),
    inset 0px -1px 2px rgba(0, 0, 0, 0.05);
`
