import { User } from 'typings/common_defs'
import { graphql } from '../graphql'
import { path } from 'ramda'
import { api } from '../api'

export interface WorkerProfileShift {
  id: number
  startsAt: string
  endsAt: string
  location: {
    name: string
    address: {
      timezone: string
    }
  }
  position: {
    name: string
  }
}

export type Worker = Pick<
  User,
  | 'id'
  | 'name'
  | 'profilePicUrl'
  | 'backgroundCheckStatus'
  | 'firstActive'
  | 'favorites'
  | 'numPaidShifts'
  | 'numFinishedShifts'
  | 'numShiftsForCompany'
  | 'numHoursForCompany'
  | 'numHoursForCompanyPastWeek'
  | 'rating'
  | 'shadowBlocked'
  | 'status'
  | 'statusForCompany'
  | 'workProfile'
  | 'rosters'
  | 'address'
>

export interface WorkerProfile {
  companyId: number
  companyName: string
  worker: Worker
}

export async function getWorkerProfile(workerId: number) {
  const data = await graphql(
    `
    company {
      id,
      name,
      worker(workerId: "${workerId}") {
        backgroundCheckStatus,
        firstActive,
        favorites,
        id,
        name,
        address {
          city,
          state
        },
        profilePicUrl,
        numPaidShifts,
        numFinishedShifts,
        numShiftsForCompany,
        numHoursForCompany,
        numHoursForCompanyPastWeek,
        rating,
        rosters {
          id, name
        }
        shadowBlocked,
        status,
        statusForCompany,
        workProfile {
          hasCar, hasLicense,
          workExperience {
            id,
            experience,
            startedAt,
            endedAt,
            companyName,
            skills
          }
        },
      }
    }
    `
  )
  const companyData = path(['data', 'data', 'company'], data)
  return {
    companyId: companyData.id,
    companyName: companyData.name,
    worker: companyData.worker,
  } as WorkerProfile
}

export interface WorkerStatusForCompanyRequest {
  action: 'block' | 'favorite' | 'unblock' | 'unfavorite'
  reason?: string
}

export async function updateWorkerStatusForCompany(
  companyId: number,
  workerId: number,
  data: WorkerStatusForCompanyRequest
) {
  return api.post(`/company/${companyId}/action`, {
    worker_id: workerId,
    action: data.action,
    reason: data.reason,
  })
}
