import React from 'react'
import { CompanyWorker, StatusForCompanyEnum } from 'api/worker'
import { Avatar, Box, Button, Card, Flex, Text, Tooltip } from 'ui'
import { HiHeart } from 'react-icons/hi'
import { BiBlock } from 'react-icons/bi'

interface Props {
  data: CompanyWorker
  onSelect: () => void
}

export function MobileCard(props: Props) {
  const { data, onSelect } = props
  return (
    <Card mb={3} shadowless={true}>
      <Flex flexDirection={'row'} alignItems={'center'}>
        <Avatar
          size={'medium'}
          user={{
            userId: data.id.toString(),
            name: data.name,
            photoUrl: data.profilePicUrl,
          }}
        />
        <Text fontWeight={2} ml={2}>
          {data.name}
        </Text>
      </Flex>
      <Flex mt={2}>
        <Text fontWeight={1}>Avg. Rating:</Text>
        <Box ml={3} width={30}>
          {data.rating ? data.rating.toFixed(2) : '--'}
        </Box>
        {data.statusForCompany === StatusForCompanyEnum.Favorite ? (
          <Text ml={2} color={'primary'}>
            <Tooltip content={'Favorite'}>
              <span>
                <HiHeart fontSize={18} />
              </span>
            </Tooltip>
          </Text>
        ) : null}
        {data.statusForCompany === StatusForCompanyEnum.Blocked ? (
          <Text ml={2} color={'error'}>
            <Tooltip content={'Blocked'}>
              <span>
                <BiBlock fontSize={18} />
              </span>
            </Tooltip>
          </Text>
        ) : null}
      </Flex>
      <Flex mt={2}>
        <Text fontWeight={1}>Shifts:</Text>
        <Box ml={3} width={30}>
          {data.numShiftsForCompany}
        </Box>
      </Flex>
      <Button
        mt={3}
        kind={'medium'}
        disabled={data.statusForCompany === StatusForCompanyEnum.Blocked}
        width={1}
        onClick={onSelect}
      >
        Select
      </Button>
    </Card>
  )
}
