import React, { useMemo } from 'react'
import { Box, Select, Text } from 'ui'
import { SelectableCompanyGroup } from 'typings/selectable_ext'
import { CompanySwitchIndicator } from 'components/CompanySwitchIndicator'
import { useSwitchCompanyMutation } from 'queries/company'
import { EyeIcon } from 'lucide-react'
import { createPortal } from 'react-dom'
import { useAuthContext } from 'components/auth'

export function CompanySwitcher() {
  const { company } = useAuthContext()
  const companyOptions = useMemo(() => {
    if (company && company.companyGroup && company.companyGroup.companies) {
      return company.companyGroup.companies.map((c) => ({
        value: Number(c.id),
        label: c.name,
      }))
    }

    return []
  }, [company])
  const { mutate: switchCompany, isPending: isSwitching } =
    useSwitchCompanyMutation()

  const handleCompanyChange = (selectedOption: SelectableCompanyGroup) => {
    if (!selectedOption || !company) {
      return
    }
    switchCompany(Number(selectedOption.value))
  }

  if (!company || companyOptions.length === 0) {
    return <Box height={80} />
  }

  return (
    <>
      <Box p={4} pt={3} className={'cta-container'} position={'relative'}>
        <Box mb={2}>
          <Text className={'input-label'} color={'lightText'} maxLines={1}>
            Currently viewing:
          </Text>
        </Box>
        <Select
          options={companyOptions}
          onChange={(selectedOption) => {
            handleCompanyChange(selectedOption as SelectableCompanyGroup)
          }}
          value={companyOptions.find((o) => o.value === company.id)}
        />
        <span className={'viewing-icon'}>
          <EyeIcon size={22} />
        </span>
      </Box>
      {isSwitching
        ? createPortal(<CompanySwitchIndicator />, document.body)
        : null}
    </>
  )
}
