import React from 'react'
import { Box, Text, Message } from 'ui'
import { useIntercom } from 'react-use-intercom'

interface Props {
  clearError?: () => void
}

export function ErrorMessage(props: Props) {
  const { clearError } = props
  const { show: showIntercom } = useIntercom()

  return (
    <Box p={4}>
      <Message variant={'error'}>
        Something went wrong. Please try again and&nbsp;
        <Text
          color={'primary'}
          onClick={showIntercom}
          fontWeight={2}
          style={{
            display: 'inline-block',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
        >
          contact support
        </Text>
        &nbsp;if you require further assistance.
      </Message>
      <Box mt={3}>
        <a onClick={clearError}>Click here</a> to try again.
      </Box>
    </Box>
  )
}
