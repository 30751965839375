import React, { useState } from 'react'
import { RosterDetail } from 'api/worker'
import { Field, IconButton, Input, Message, Modal, toast } from 'ui'
import { useUpdateRosterMutation } from 'queries/worker/useUpdateRosterMutation'
import { PencilIcon } from 'lucide-react'

interface Props {
  data: RosterDetail
}

export function EditListModal(props: Props) {
  const { data } = props
  const [listName, setListName] = useState(data.name)
  const [error, setError] = useState('')
  const { mutate: updateList, isPending: isUpdating } = useUpdateRosterMutation(
    {
      rosterId: data.id,
      onSuccess() {
        setError('')
        setOpen(false)
        toast.success('List updated successfully')
      },
      onError(err) {
        setError(err.message)
      },
    }
  )
  const [open, setOpen] = useState(false)

  const onSave = () => {
    if (!listName) {
      setError('List name is required')
      return
    }

    updateList({ name: listName })
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title={'Edit List'}
        onOk={onSave}
        okText={'Save'}
        loading={isUpdating}
      >
        <Field label={'List Name'}>
          <Input
            onChange={(e) => setListName(e.target.value)}
            value={listName}
          />
        </Field>
        {error ? <Message variant={'error'}>{error}</Message> : null}
      </Modal>
      <IconButton onClick={() => setOpen(true)}>
        <PencilIcon size={18} />
      </IconButton>
    </>
  )
}
