import { TextareaWrapper } from '@workwhile/ui'
import React, { FormEvent, useState } from 'react'
import {
  useInvalidateWorkerMessages,
  useSendMessageMutation,
} from 'queries/message'
import { IconButton, Loading } from 'ui'
import { SendHorizonalIcon } from 'lucide-react'

interface Props {
  shiftId: number | null
  workerId: number
}

export function ChatBox(props: Props) {
  const { shiftId, workerId } = props
  const [message, setMessage] = useState('')
  const { invalidate: invalidateThread } = useInvalidateWorkerMessages(workerId)
  const { mutate: sendMessage, isPending: isSendingMessage } =
    useSendMessageMutation({
      onSuccess: () => {
        invalidateThread()
        setMessage('')
      },
    })
  const isDisabled = !shiftId || isSendingMessage

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (shiftId && message.trim().length > 0) {
      sendMessage({
        message: message.trim(),
        shiftId,
        toUserIds: [workerId],
      })
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <TextareaWrapper
        display={'flex'}
        flexDirection={'row'}
        disabled={isDisabled}
      >
        <textarea
          rows={3}
          disabled={isDisabled}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder={
            shiftId
              ? 'Type a message'
              : 'You can only message workers when there is an active shift'
          }
        />
        <IconButton
          aria-label={'Send Message'}
          type={'submit'}
          m={1}
          disabled={isDisabled || message.trim().length === 0}
        >
          {isSendingMessage ? (
            <Loading type={'button'} />
          ) : (
            <SendHorizonalIcon size={20} />
          )}
        </IconButton>
      </TextareaWrapper>
    </form>
  )
}
