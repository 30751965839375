import { useQuery, useQueryClient } from '@tanstack/react-query'
import { companyShiftDetailKey } from './keys'
import { getCompanyShiftDetail } from 'api/company'

export function useCompanyShiftDetail(options?: {
  enabled?: boolean
  ids: number[]
  includeEstimate?: boolean
  includePositionLocationDebut?: boolean
}) {
  const { ids, includeEstimate, includePositionLocationDebut } = options || {}

  return useQuery({
    queryKey: companyShiftDetailKey(ids || []),
    queryFn: () =>
      getCompanyShiftDetail({
        ids: ids || [],
        includeEstimate,
        includePositionLocationDebut,
      }),
    refetchInterval: 60000, // refresh every 60s by default
    ...options,
  })
}

export function useInvalidateShiftDetail(ids: number[] | null) {
  const queryClient = useQueryClient()

  return {
    invalidate: () => {
      if (!ids || ids.includes(-1)) {
        // If ids are null or contain the fallback value (-1), don't proceed.
        return
      }
      queryClient.invalidateQueries({ queryKey: companyShiftDetailKey(ids) })
    },
  }
}
