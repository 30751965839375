import React, { forwardRef } from 'react'
import { Text } from '@workwhile/ui'
import styled from 'styled-components'

export type BaseShiftCellProps = {
  label?: string
  value: string
  backgroundColor?: string
  color?: string
  onClick?: () => void
}

const StyledButton = styled('button').withConfig({
  shouldForwardProp: (prop) => !['backgroundColor'].includes(prop),
})<Pick<BaseShiftCellProps, 'backgroundColor'>>`
  border: none;
  border-radius: ${({ theme }) => theme.radii.small};
  padding: ${({ theme }) => theme.space[2]};
  background-color: ${({ backgroundColor }) => backgroundColor};
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:focus-visible {
    box-shadow: 0px 0px 7px 1px ${({ theme }) => theme.colors.neutrals[200]};
  }
`

export const BaseShiftCell = forwardRef<HTMLButtonElement, BaseShiftCellProps>(
  ({ label, value, backgroundColor, color, onClick }, ref) => {
    return (
      <StyledButton
        backgroundColor={backgroundColor}
        ref={ref}
        onClick={onClick}
      >
        <Text fontSize={0} color={color} as="span">
          {label}
        </Text>
        <Text fontSize={0} color={color} as="span">
          {value}
        </Text>
      </StyledButton>
    )
  }
)

BaseShiftCell.displayName = 'BaseShiftCell'
