import { User, WorkShift } from 'typings/common_defs'
import {
  determineIfWorkerAssignedToShift,
  determineIfWorkerUnableToAttendShift,
} from './shift'
import { TimelineShift } from 'api/shift'
import { isAfter, parseISO } from 'date-fns'

export const ROSTER_USER_STATUS = {
  active: 'active',
  removal_pending: 'removal_pending',
  removed: 'removed',
  tryout_pending: 'tryout_pending',
}

/**
 * Get user status on for a position roster
 * @param {WorkShift | TimelineShift} shift
 * @param {User} worker
 * @returns {string | null} status of user on position roster
 */
export const getPositionRosterUserStatus = (
  shift: WorkShift | TimelineShift,
  worker: User
): string | null => {
  const positionRoster = shift.position?.roster
  if (!positionRoster) {
    return null
  }
  if (
    !positionRoster.rosterUsers ||
    !Array.isArray(positionRoster.rosterUsers)
  ) {
    return null
  }
  const rosterUser = positionRoster.rosterUsers.find(
    (ru) => Number(ru.userId) === Number(worker.id)
  )
  if (!rosterUser) {
    return null
  }
  return rosterUser.status
}

/**
 * Get roster workers on a shift
 * @param {WorkShift | TimelineShift} shift
 * @returns {Array<User>} roster workers on a shift
 */
export const getRosterWorkersOnShift = (
  shift: WorkShift | TimelineShift
): Array<User> => {
  const rosters = shift?.rosters
  if (!rosters || !Array.isArray(rosters)) {
    return []
  }
  const rosterWorkers: Array<User> = []
  for (const roster of rosters) {
    if (!roster.workers || !Array.isArray(roster.workers)) {
      continue
    }
    rosterWorkers.push(...roster.workers)
  }
  return rosterWorkers
}

/**
 * Given a shift, determines if the shift is currently restricted to rosters
 * @param {WorkShift | TimelineShift} shift
 * @returns {boolean} True if shift is restricted to rosters, else false
 **/
export const determineIfShiftRestrictedToRosters = (
  shift: WorkShift | TimelineShift
): boolean => {
  // if shift.rosters && shift.rosters.length > 0
  // and the shift.rostersHoldExpiresAt is null or in the future
  // the shift is restricted to rosters
  if (!shift.rosters || shift.rosters.length === 0) {
    return false
  }
  if (!shift.rostersHoldExpiresAt) {
    // if the shift has rosters, and the hold never expires
    // then by definition the shift is restricted to rosters
    return true
  }
  const rostersHoldExpiresAt = parseISO(shift.rostersHoldExpiresAt)
  return isAfter(rostersHoldExpiresAt, new Date())
}

/**
 * Given a shift, determine the number of workers on the roster who are assigned to the shift
 * @param {WorkShift | TimelineShift} shift
 * @returns {number} number of workers on the roster who are assigned to the shift
 */
export const getNumRosterWorkersAssignedToShift = (
  shift: WorkShift | TimelineShift
): number => {
  const rosterWorkers = getRosterWorkersOnShift(shift)
  // for each roster worker, determine if they exist in shift.work
  let numRosterWorkersAssignedToShift = 0
  for (const rosterWorker of rosterWorkers) {
    if (determineIfWorkerAssignedToShift(rosterWorker, shift)) {
      numRosterWorkersAssignedToShift += 1
    }
  }
  return numRosterWorkersAssignedToShift
}

/**
 * Given a shift, determine the number of workers on the roster who are unable to attend the shift
 * @param {WorkShift | TimelineShift} shift
 * @returns {number} number of workers on the roster who are unable to attend the shift
 */
export const getNumRosterWorkersUnableToAttendShift = (
  shift: WorkShift | TimelineShift
): number => {
  const rosterWorkers = getRosterWorkersOnShift(shift)

  // for each roster worker, determine if they exist in shift.work
  let numRosterWorkersUnableToAttendShift = 0
  for (const rosterWorker of rosterWorkers) {
    const isWorkerUnableToAttend = determineIfWorkerUnableToAttendShift(
      rosterWorker,
      shift
    )
    if (isWorkerUnableToAttend) {
      numRosterWorkersUnableToAttendShift += 1
    }
  }
  return numRosterWorkersUnableToAttendShift
}
