import { useQuery, useQueryClient } from '@tanstack/react-query'
import { allUserPermissionsKey, userPermissionsKey } from './keys'
import { getUserPermissions } from 'api/userPermissions'

export function useUserPermissions(userId?: string) {
  return useQuery({
    queryKey: userPermissionsKey(userId),
    queryFn: getUserPermissions,
    enabled: !!userId,
  })
}

export function useInvalidateUserPermissions() {
  const queryClient = useQueryClient()

  return {
    invalidate: () =>
      queryClient.invalidateQueries({
        queryKey: allUserPermissionsKey,
      }),
  }
}
