import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Box, Button, Text } from 'ui'
import { WorkersSelector } from 'components/WorkersSelector'
import { SelectedRSW } from './SelectedRSW'
import { useShiftEditor } from 'pages/ShiftEditorPage/useShiftEditor'
import { EditorMode } from 'pages/ShiftEditorPage/ShiftEditorProvider'
import { useHypotheticalShifts } from 'hooks/useHypotheticalShifts'
import { isRSWWorkerAvailabilityEnabled } from 'lib/flags'
import { useAuthContext } from 'components/auth'
import { SelectedWorkerStatus } from 'components/WorkersSelector/SelectedWorkerStatus'

export function RSW() {
  const { company } = useAuthContext()
  const { editorMode, editData } = useShiftEditor()
  const { control, watch } = useFormContext()
  const [open, setOpen] = useState(false)
  const specificWorkers = watch('specificWorkers')
  const hasSelectedWorkers = (specificWorkers ?? []).length > 0
  const hypotheticalShifts = useHypotheticalShifts()

  return (
    <Box>
      <Text my={2} mb={3}>
        {editorMode === EditorMode.PastShift
          ? 'Your chosen workers will be assigned to this shift.'
          : 'A text message will be sent to your chosen workers, inviting them to accept this shift.'}
      </Text>
      {!hasSelectedWorkers ? (
        <Button width={[1, 200]} onClick={() => setOpen(true)}>
          Add Workers
        </Button>
      ) : null}
      <SelectedRSW openSelector={() => setOpen(true)} />
      <Controller
        render={({ field: { value, onChange } }) => (
          <WorkersSelector
            open={open}
            onClose={() => setOpen(false)}
            selected={value}
            onChange={(selected) => {
              onChange(selected)
              setOpen(false)
            }}
            selectedWorkerStatusComponent={(worker) => {
              return editorMode !== EditorMode.PastShift &&
                isRSWWorkerAvailabilityEnabled(company) ? (
                <SelectedWorkerStatus
                  worker={worker}
                  hypotheticalShifts={hypotheticalShifts}
                  existingShift={editData}
                />
              ) : null
            }}
          />
        )}
        name={'specificWorkers'}
        control={control}
      />
    </Box>
  )
}
