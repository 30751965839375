import React from 'react'
import { Flex, Heading, Text } from 'ui'

export function AddEmptyState() {
  return (
    <Flex
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      p={5}
    >
      <Heading level={4} color={'lightText'}>
        There are no workers yet
      </Heading>
      <Text px={[2, 7]} textAlign={'center'}>
        Workers that have worked a shift with your company will appear here. But
        you can proceed and create this List.
      </Text>
    </Flex>
  )
}
