import { DemoApiMock } from '../types'
import { getDemoData, matchGraphqlRequest } from '../utils'

export const mockGetLocations: DemoApiMock = {
  shouldMock: (request) => matchGraphqlRequest(request, /company\{locations/),
  getResponse: async () => {
    const demoData = getDemoData()
    return {
      data: {
        company: {
          locations: demoData.locations,
        },
      },
    }
  },
}
