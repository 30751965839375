import { useMemo } from 'react'

export interface PreviewDataRow {
  workerName: string
  position: string
  location: string
  startsAt: string
  endsAt: string
}

// Worker Name,Position,Location,Start Time,End Time,Clock-in Time,Clock-out Time,Hours Worked,Break in Minutes,Worker Pay Rate,Bonus,Worker Earnings,Charge Rate,Charge Status,Charge,Discount

export function usePreviewData(csvData?: string) {
  const data = useMemo(() => {
    if (!csvData) return []

    const rows = csvData.split('\r\n').filter((r) => r)
    // first row is header
    return rows.slice(1).map((row) => {
      const [workerName, position, location, startsAt, endsAt] = row.split(',')
      return {
        workerName,
        position,
        location,
        startsAt,
        endsAt,
      } as PreviewDataRow
    })
  }, [csvData])

  return data
}
