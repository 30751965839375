import { useCompanyInfo } from '../company'
import { useMutation } from '@tanstack/react-query'
import { Feedback, sendFeedback } from 'api/review'

export function useSendFeedbackMutation(options: {
  onSuccess?: () => void
  onError?: (err: Error) => void
}) {
  const { data: company } = useCompanyInfo()

  return useMutation({
    mutationFn: async (feedbackItems: Feedback[]) => {
      if (!company?.id) {
        throw new Error('Company id not found')
      }

      return sendFeedback(company.id, feedbackItems)
    },
    ...options,
  })
}
