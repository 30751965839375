import { getDemoData, matchRestRequest, setDemoData } from 'api/demo/utils'
import { getPositionTemplate, PositionRequest } from 'api/company'
import { DemoCategory } from 'api/demo/types'
import { positions, positionsByCategory } from '../data/positions'
import { Position } from 'typings/common_defs'
import { KeysToSnakeCase } from 'typings/casing'

export const mockCreatePosition = {
  shouldMock: (request) =>
    matchRestRequest(request, /\/company\/\d+\/position/, 'POST'),
  getResponse: async (request) => {
    return createPosition(request.data)
  },
}

export async function createPosition(
  positionRequest: KeysToSnakeCase<PositionRequest>
) {
  const demoData = getDemoData()
  const {
    name,
    about,
    position_template_id,
    worker_requirement_ids = [], // TODO: add custom requirements if necessary
  } = positionRequest
  const newPosition: Position = {
    id: Math.max(...demoData.positions.map((p) => p.id), 0) + 1,
    name,
    about,
    hidden: false,
    positionTemplateId: position_template_id,
  } as NonNullable<Position>
  if (position_template_id) {
    const template = await getPositionTemplate(position_template_id)
    if (template) {
      const requirementIds = worker_requirement_ids.map(String)
      newPosition.requirements2 = (template.requirements ?? [])
        .filter((item) =>
          requirementIds.includes(item.requirement.id.toString())
        )
        .map((item) => ({
          id: item.requirement.id,
          name: item.requirement.name,
          category: item.requirement.category,
        }))
      newPosition.requirements = JSON.stringify(
        newPosition.requirements2.map((item) => item.name)
      )
    }
  }
  setDemoData({
    ...demoData,
    positions: [...demoData.positions, newPosition],
  })
  return {
    id: newPosition.id,
  }
}

export function createMockPositionsForCategory(category: DemoCategory) {
  return positionsByCategory[category] ?? positions
}
