import React, { useState } from 'react'
import {
  convertTryoutEvaluationStatusToRosterUserStatus,
  determineInitialTryoutEvalutationValue,
} from '../../lib/tryout'
import { User, WorkShift } from '../../typings/common_defs'
import { ToggleGroup, ToggleGroupItem } from '../core/Toggle/ToggleGroup'
import { track } from '../../lib/amplitude'
import { TimelineShift } from 'api/shift/shiftTimeline'

export const TRYOUT_EVALUATION_OPTIONS = {
  keep: 'Keep',
  undecided: 'Undecided',
  remove: 'Remove',
}

export interface TryoutEvaluationData {
  worker_id: number
  status: string
}

interface Props {
  shift: WorkShift | TimelineShift
  worker: User
  handleToggleSelect: (data: TryoutEvaluationData) => void
}

const TryoutEvaluationToggleGroup = ({
  shift,
  worker,
  handleToggleSelect,
}: Props) => {
  const [value, setValue] = useState<string>(
    determineInitialTryoutEvalutationValue(shift, worker)
  )

  const handleValueChange = (value: string) => {
    if (value) setValue(value)
    track('button_click, tryouts_evaluation_toggle_value_change', { value })
    const data = {
      worker_id: worker.id,
      status: convertTryoutEvaluationStatusToRosterUserStatus(value),
    }
    handleToggleSelect(data)
  }

  return (
    <ToggleGroup
      type="single"
      defaultValue={TRYOUT_EVALUATION_OPTIONS.undecided}
      value={value}
      onValueChange={handleValueChange}
      aria-label="Tryout evaluation"
    >
      <ToggleGroupItem
        value={TRYOUT_EVALUATION_OPTIONS.keep}
        aria-label={TRYOUT_EVALUATION_OPTIONS.keep}
      >
        {TRYOUT_EVALUATION_OPTIONS.keep}
      </ToggleGroupItem>
      <ToggleGroupItem
        value={TRYOUT_EVALUATION_OPTIONS.undecided}
        aria-label={TRYOUT_EVALUATION_OPTIONS.undecided}
      >
        {TRYOUT_EVALUATION_OPTIONS.undecided}
      </ToggleGroupItem>
      <ToggleGroupItem
        value={TRYOUT_EVALUATION_OPTIONS.remove}
        aria-label={TRYOUT_EVALUATION_OPTIONS.remove}
      >
        {TRYOUT_EVALUATION_OPTIONS.remove}
      </ToggleGroupItem>
    </ToggleGroup>
  )
}

export default TryoutEvaluationToggleGroup
