import React from 'react'
import ShiftDetailWorkerStatus from './ShiftDetailWorkerStatus'
import { Work } from 'typings/common_defs'
import { AssignedWorkerActions } from './AssignedWorkerActions'
import { Box, Flex, Tooltip } from 'ui'
import { APPROVED_WORK_STATUSES } from 'lib/work'
import { PastWorkInfo } from './PastWorkInfo'
import { useShiftDetail } from '../useShiftDetail'
import styled, { css, keyframes } from 'styled-components'
import { SpinningRefreshIcon } from './SpinningRefreshIcon'
import { useAppTheme } from '@workwhile/ui'

interface Props {
  work: Work
  switchToEditMode: () => void
}

export const ShiftDetailAssignedWorkerRowViewMode = ({
  work,
  switchToEditMode,
}: Props) => {
  const { isFetching } = useShiftDetail()
  const { colors } = useAppTheme()

  const getContent = () => {
    if (!work) return null

    // if the shift has been approved, show PastWork Info
    if (
      work?.status &&
      ([...APPROVED_WORK_STATUSES, 'bailed'].includes(work.status) ||
        work.supervisorMarkedNoShow)
    ) {
      return <PastWorkInfo work={work} />
    }

    // else show status and actions
    return (
      <>
        <ShiftDetailWorkerStatus work={work} />
        <AssignedWorkerActions
          work={work}
          switchToEditMode={switchToEditMode}
        />
      </>
    )
  }

  return (
    <Flex flexDirection="row" alignItems="center" flex={1}>
      {isFetching ? (
        <Box mr={3}>
          <Tooltip content={'Updating work data...'}>
            <SpinningRefreshIcon size={16} color={colors.info} />
          </Tooltip>
        </Box>
      ) : null}
      <Container
        flexDirection={['column', 'row', 'row', 'row']}
        isLoading={isFetching}
      >
        {getContent()}
      </Container>
    </Flex>
  )
}

const pulseAnimation = keyframes`
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.8;
  }
`

const Container = styled(Flex)<{ isLoading: boolean }>`
  align-items: center;
  justify-content: space-between;
  flex: 1;
  line-height: 5rem;
  ${(props) =>
    props.isLoading &&
    css`
      animation: ${pulseAnimation} 2s infinite ease-in-out;
    `}
`
