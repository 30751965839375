import React from 'react'
import { User } from '../../../typings/common_defs'
import { Label, Tooltip } from 'ui'
import { toTitleCase, toFirstName } from 'lib/string'
import { useShiftDetail } from '../useShiftDetail'

interface Props {
  user: User
}

export const FirstDayTag = ({ user }: Props) => {
  const { shift } = useShiftDetail()
  if (!shift) return null

  const tooltipText = `It's ${toTitleCase(
    toFirstName(user.name)
  )}'s first day${' '}
  ${
    shift?.position?.name
      ? `working as a ${shift.position.name}`
      : 'doing this job'
  }${' '}
  at ${shift?.location?.name || 'this location'}. Say hi and welcome them to
  the team!`

  return (
    <Tooltip content={tooltipText}>
      <Label variant="none" size="small">
        👋 First Day
      </Label>
    </Tooltip>
  )
}
