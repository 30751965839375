import React, { ReactNode, useState } from 'react'
import { CompanyRoster, CompanyWorker, Worker } from 'api/worker'
import { toast, Loading } from 'ui'
import { RosterSelector } from 'components/RosterSelector'
import {
  useAddWorkerToRostersMutation,
  useInvalidateCompanyRosters,
} from 'queries/company'

interface Props {
  data: CompanyWorker | Worker
  onUpdated?: (rosters: Array<Pick<CompanyRoster, 'id' | 'name'>>) => void
  ctaLabel?: ReactNode
}

export function WorkerRostersSummary(props: Props) {
  const { onUpdated, data, ctaLabel = 'Add To List' } = props
  const rosters = data.rosters ?? []
  const rosterIds = rosters.map((item) => Number(item.id))
  const [open, setOpen] = useState(false)
  const { invalidate: invalidateLists } = useInvalidateCompanyRosters()
  const { mutate: addToRosters, isPending: isAddingToRosters } =
    useAddWorkerToRostersMutation({
      workerId: Number(data.id),
      onSuccess: (rosters) => {
        if (onUpdated) {
          onUpdated(rosters)
        }
        invalidateLists()
      },
      onError: (err) => {
        toast.error(err.message)
      },
    })

  const onRostersSelected = async (rosterIds: number[]) => {
    addToRosters(rosterIds)
    setOpen(false)
  }

  if (isAddingToRosters) {
    return <Loading type={'button'} />
  }

  return (
    <>
      <span onClick={() => setOpen(true)}>{ctaLabel}</span>
      {open ? (
        <RosterSelector
          open={open}
          title={`Add ${data.name || 'Worker'} to Lists`}
          ctaText={'Add to Selected Lists'}
          showStats={false}
          onClose={() => setOpen(false)}
          selected={rosterIds}
          onChange={onRostersSelected}
        />
      ) : null}
    </>
  )
}
