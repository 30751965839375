import { api, graphql } from 'api'
import { path } from 'ramda'

export interface CompanyRoster {
  id: number
  name: string
  workers: Array<{
    id: number
    profilePicUrl?: string
    name?: string
  }>
}

export async function getCompanyRosters() {
  const data = await graphql(
    `
        company {
          rosters {
            id
            name
            workers {
              id
              profilePicUrl
              name
            }
          }
        }`,
    'rosters_query'
  )
  const rosters = path(
    ['data', 'data', 'company', 'rosters'],
    data
  ) as CompanyRoster[]
  return rosters.map((roster) => ({
    ...roster,
    id: Number(roster.id), // fix BE returning string for ids
  }))
}

export async function addWorkerToRosters(
  companyId: number,
  workerId: number,
  rosterIds: number[]
) {
  const data = await api.put(
    `/company/${companyId}/worker/${workerId}/rosters`,
    {
      roster_ids: rosterIds,
    }
  )
  return path(['data', 'rosters'], data) as Array<
    Pick<CompanyRoster, 'id' | 'name'>
  >
}

export async function addWorkersToRoster(
  companyId: number,
  rosterId: number,
  workerIds: number[]
) {
  const data = await api.post(
    `/company/${companyId}/roster/${rosterId}/workers`,
    {
      worker_ids: workerIds,
    }
  )
  return data
}

export interface NewRosterRequest {
  name: string
  workerIds: number[]
}

export function createNewRoster(companyId: number, data: NewRosterRequest) {
  return api.post(`/company/${companyId}/roster`, {
    name: data.name,
    worker_ids: data.workerIds,
  })
}

export function updateRoster(
  companyId: number,
  rosterId: number,
  options: { name: string }
) {
  return api.put(`/company/${companyId}/roster/${rosterId}`, {
    name: options.name,
  })
}

export const updateShiftRosters = (
  companyId: number,
  shiftId: number,
  rosterIds: number[]
) => {
  return api.put(`/company/${companyId}/shifts/${shiftId}/edit_rosters`, {
    roster_ids: rosterIds,
  })
}

export const updateRosterWorkers = (
  companyId: number,
  rosterId: number,
  rosterUsers: { worker_id: number; status: string }[]
) => {
  return api.put(`/company/${companyId}/roster/${rosterId}/workers`, {
    roster_users: rosterUsers,
  })
}
