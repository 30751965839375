import React from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Button } from '@workwhile/ui'

export function ExitButton() {
  const navigate = useNavigate()
  const location = useLocation()

  const handleClose = () => {
    // if there is no option to go back, user entered URL directly into browser
    // - in that case, redirect to home
    if (location.key === 'default') {
      navigate('/')
      return
    }
    navigate(-1)
  }

  return (
    <Button variant="secondary" onClick={handleClose}>
      Exit
    </Button>
  )
}
