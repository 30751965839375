import React from 'react'
import styled from 'styled-components'
import { Box, Flex, tabletScreen } from 'ui'
import { SideNav } from './SideNav'
import { MobileTopNav } from './MobileTopNav'
import { NotificationCenter } from 'pages/layout/NotificationCenter'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { Outlet } from 'react-router-dom'
import { useLayoutContext } from './useLayoutContext'
export * from './Page'
export * from './PageContent'

export const PAGE_SIZE = '1440px'
export const MOBILE_NAV_HEIGHT = 48

export const LayoutContainer = styled(Flex).attrs({
  minHeight: '100vh',
  flexDirection: ['column', 'column', 'row'],
  alignItems: 'stretch',
})<{
  $menuOpen: boolean
  $menuWidth?: string
  $navCollapsed?: boolean
}>`
  ${({ $menuOpen, $menuWidth, $navCollapsed }) => `
    .modal-page-container {
      z-index: 2;
    }
    
    .page-content {
      --padding: calc((100% - ${PAGE_SIZE}) / 2);
      padding-left: var(--padding);
      padding-right: var(--padding);
    }
    
    .page-container {
      z-index: 1;
      max-width: ${PAGE_SIZE};
      margin-left: auto;
      margin-right: auto;
      padding-left: ${$navCollapsed ? '10rem' : '0'};
      
      ${tabletScreen(`
        margin-left: 0;
        margin-right: 0;
        height: calc(100vh - ${MOBILE_NAV_HEIGHT}px);
      `)}
    }
    
    > .mobile-top-nav, > .page-container {
      transition: transform 0.3s ease-in-out;
      
      ${
        $menuOpen && $menuWidth
          ? `
        transform: translateX(${$menuWidth});
      `
          : ''
      }
    }
  `}
`

export function Layout() {
  const { mobileMenuOpen, menuWidth, isNavCollapsed } = useLayoutContext()

  return (
    <LayoutContainer
      $menuOpen={mobileMenuOpen}
      $menuWidth={menuWidth}
      $navCollapsed={isNavCollapsed}
      pt={[MOBILE_NAV_HEIGHT, MOBILE_NAV_HEIGHT, 0]}
    >
      <SideNav />
      <MobileTopNav />
      <Box className={'page-container'} flex={['unset', 'unset', 1]}>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </Box>
      <NotificationCenter />
    </LayoutContainer>
  )
}
