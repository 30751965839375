import { TimelineShift } from 'api/shift'
import { formatInTimeZone } from 'date-fns-tz'
import { getShiftTimezone } from 'lib/shift'
import React, { useMemo } from 'react'
import { Label } from 'ui'

interface Props {
  size?: 'small' | 'medium' | 'large'
  shift: TimelineShift
}

export const BackupShiftLabel = ({ size = 'medium', shift }: Props) => {
  const shiftToBackDetails = useMemo(() => {
    if (!shift.shiftToBackup) {
      return null
    }
    const shifttz = getShiftTimezone(shift)
    const shiftToBackStartTime = shift.shiftToBackup.startsAt
    const formattedShiftToBackStartTime = shiftToBackStartTime
      ? formatInTimeZone(new Date(shiftToBackStartTime), shifttz, 'h:mm a')
      : null
    return formattedShiftToBackStartTime
      ? ` for the ${formattedShiftToBackStartTime} Shift`
      : null
  }, [shift])

  return (
    <>
      <Label variant="information" size={size} flexShrink={0}>
        Backup Shift{shiftToBackDetails}
      </Label>
    </>
  )
}
