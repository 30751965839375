import React, { useMemo } from 'react'
import { CompanyWorker } from 'api/worker'
import { Box, Button, Card } from 'ui'
import { WorkerRostersSummary } from './WorkerRostersSummary'
import styled from 'styled-components'
import { ModalLink } from 'components/ModalLink'
import { LinkProps } from 'react-router-dom'
import { WorkerCardContent } from './WorkerCardContent'
import { useWorkerSearch } from './useWorkerSearch'

const CardContainer = styled(Card).attrs({
  as: ModalLink,
  flexWrap: 'wrap',
  flexDirection: 'row',
  alignItems: 'flex-start',
  my: 3,
  borderWidth: 0,
  color: 'text',
})<LinkProps>`
  ${({ theme: { colors } }) => `
    &:hover {
      position: relative;
      text-decoration: none;
      color: ${colors.text};
    }
  `}
`

interface Props {
  data: CompanyWorker
}

export function WorkerCard(props: Props) {
  const { data } = props
  const { updateWorkerRosters } = useWorkerSearch()
  const rosters = data.rosters ?? []
  const listText = useMemo(() => {
    if (rosters.length === 0) {
      return '+ Add to List'
    }

    return `${rosters[0].name}${
      rosters.length > 1 ? ` +${rosters.length - 1}` : ''
    }`
  }, [rosters])

  return (
    <CardContainer
      to={`/workers/${data.id}`}
      onClick={(e) => {
        if (
          e.target instanceof Element &&
          e.target.closest('button, .modal-content')
        ) {
          e.preventDefault()
          e.stopPropagation()
        }
      }}
    >
      <WorkerCardContent data={data} />
      <Box width={[1, 'auto']} mt={[3, 0]} ml={['6.4rem', 0]} pl={[3, 0]}>
        <WorkerRostersSummary
          data={data}
          ctaLabel={
            <Box display={'inline-flex'} alignItems={'center'}>
              <Button variant={'text'}>{listText}</Button>
            </Box>
          }
          onUpdated={(rosters) => updateWorkerRosters(data.id, rosters)}
        />
      </Box>
    </CardContainer>
  )
}
