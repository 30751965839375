import React, { useState } from 'react'
import {
  Box,
  Flex,
  Heading,
  Loading,
  Table,
  Text,
  useResponsiveValue,
} from 'ui'
import { useCompanyGroupMembers } from 'queries/company'
import { groupMemberColumns } from './columns'
import { SearchBar } from './MemberSearchBar'

export function GroupMembers() {
  const [searchKeyword, setSearchKeyword] = useState('')
  const [hasSearched, setHasSearched] = useState(false) // To track if search has been initiated
  const { data, isLoading } = useCompanyGroupMembers(searchKeyword)
  const tableMode = useResponsiveValue(['borderless', 'standard']) as
    | 'borderless'
    | 'standard'
  const columnsToUse = useResponsiveValue([1, 4])

  // Trigger search only if hasSearched is true
  const filteredData = hasSearched
    ? data?.filter((member) =>
        member.name.toLowerCase().includes(searchKeyword.toLowerCase())
      )
    : []

  // Modify the setSearchKeyword to also set hasSearched to true
  const handleSearch = (keyword: string) => {
    setSearchKeyword(keyword)
    setHasSearched(true)
  }

  return (
    <Box mt={5}>
      <Heading level={3}>All Organization Members</Heading>
      <Text mb={3}>
        Search for members across all locations and branches of your company
      </Text>
      <Box width="100%" maxWidth="600px">
        <SearchBar setSearchKeyword={handleSearch} isLoading={isLoading} />
        <Flex justifyContent="center" alignItems="center" mt={2}>
          {isLoading ? <Loading /> : null}
        </Flex>
      </Box>
      {!isLoading &&
      hasSearched &&
      (!filteredData || filteredData.length === 0) ? (
        <Text color={'lightText'} mb={3}>
          No matching group members found.
        </Text>
      ) : null}
      {!isLoading && hasSearched && filteredData && filteredData.length > 0 ? (
        <Table
          variant={tableMode}
          columns={groupMemberColumns.slice(0, columnsToUse)}
          data={filteredData}
        />
      ) : null}
    </Box>
  )
}
