import { UserProfile } from 'api/user'
import { indexBy } from 'ramda'

export function useEmailPreferences(profile?: UserProfile) {
  if (!profile) return []

  const { companyUserNotificationOptions, companyUserNotificationPreferences } =
    profile

  const preferencesByType = indexBy(
    (p) => p.type,
    companyUserNotificationPreferences
  )

  return companyUserNotificationOptions.map((option) => {
    return {
      type: option.type,
      name: option.typeDisplayName,
      description: preferencesByType[option.type]?.typeDescriptionDisplayName,
      options: option.frequencyOptions.map((frequency, index) => ({
        value: frequency,
        label: option.frequencyOptionsDisplayNames[index],
      })),
      selectedValue: preferencesByType[option.type]?.frequency,
    }
  })
}
