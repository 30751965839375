import React, { ReactNode } from 'react'
import { Modal } from '@workwhile/ui'
import { InviteForm } from './InviteForm'

interface Props {
  trigger: ReactNode
  onMemberAdded?: () => void
}

export function AddCompanyMember(props: Props) {
  const { trigger, onMemberAdded } = props

  return (
    <Modal width={[1, 680]} trigger={trigger} title={'Add a Company Member'}>
      <InviteForm />
    </Modal>
  )
}
