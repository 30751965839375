import { toTitleCase } from 'lib/legacy_util'
import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Box, Flex, Avatar, Label, Button, Text } from 'ui'
import { ModalLink } from 'components/ModalLink'
import { Work } from 'typings/common_defs'
import { useShiftDetail } from '../useShiftDetail'
import { useWorkStatusBadge } from 'hooks/useWorkStatusBadge'
import { FirstDayTag } from './FirstDayTag'
import { ShiftDetailAssignedWorkerRowEditMode } from './ShiftDetailAssignedWorkerRowEditMode'
import {
  shouldShowApproveButton,
  shouldShowNeedsAttentionLabel,
} from 'lib/work'
import { ShiftDetailAssignedWorkerRowViewMode } from './ShiftDetailAssignedWorkerRowViewMode'
import { AdminReviewExplainerModal } from './AdminReviewExplainerModal'
import { ShieldAlert } from 'lucide-react'
import { useAppTheme } from '@workwhile/ui'

interface Props {
  work: Work
  showWorkerLabel?: boolean
  showWorkerStatusBadge?: boolean
}

enum AssignedWorkerRowMode {
  View = 'view',
  Edit = 'edit',
}

export const ShiftDetailAssignedWorkerRow = ({
  work,
  showWorkerLabel = true,
  showWorkerStatusBadge = true,
}: Props) => {
  const { shift } = useShiftDetail()
  const { pendingAdminReview } = work
  const { colors } = useAppTheme()
  const initializeRowMode = () => {
    // determine whether we should show view mode or edit mode as the initial mode
    // we should initialize to edit mode if the work needs approval and the work has not already been marked as a No Show by the supervisor
    // this is because work can be in admin review and be supervisor marked no show (but unlike other admin review cases, we don't want the biz to re-approve the work)
    // else, initalize to view mode

    return shouldShowApproveButton(work) && !work.supervisorMarkedNoShow
      ? AssignedWorkerRowMode.Edit
      : AssignedWorkerRowMode.View
  }

  const [mode, setMode] = useState(initializeRowMode())

  useEffect(() => {
    // when the status from DB changes (NOT local changes), we need to re-initialize the row mode
    if (work) {
      setMode(initializeRowMode())
    }
  }, [work.status])

  const badge = useWorkStatusBadge(shift, work)

  if (!shift) return null
  if (!work?.worker?.id) return null

  const getContent = () => {
    if (mode === AssignedWorkerRowMode.Edit) {
      return (
        <ShiftDetailAssignedWorkerRowEditMode
          key={`shift-detail-assigned-worker-row-edit-mode-${work.id}`}
          work={work}
          switchToViewMode={() => setMode(AssignedWorkerRowMode.View)}
        />
      )
    }
    return (
      <ShiftDetailAssignedWorkerRowViewMode
        key={`shift-detail-assigned-worker-row-view-mode-${work.id}`}
        work={work}
        switchToEditMode={() => setMode(AssignedWorkerRowMode.Edit)}
      />
    )
  }

  const getWorkerUnderNameLabel = () => {
    if (!showWorkerLabel) return null

    if (shouldShowNeedsAttentionLabel(work)) {
      return (
        <Box mt={1}>
          <Label variant="error">Needs Attention</Label>
        </Box>
      )
    }

    if (pendingAdminReview) {
      return (
        <AdminReviewExplainerModal
          work={work}
          trigger={
            <Button variant="text" kind="small">
              <ShieldAlert size={16} stroke={colors.errors['200']} />
              <Text color="errors.200">Under Audit</Text>
            </Button>
          }
        />
      )
    }

    if (work.isPositionLocationDebut && work.worker) {
      return (
        <Box mt={1}>
          <FirstDayTag user={work.worker} />
        </Box>
      )
    }

    return null
  }

  return (
    <Flex
      flexDirection={['column', 'column', 'row', 'row']}
      alignItems="flex-start"
    >
      <Flex alignItems="center">
        <ModalLink to={`/workers/${work.worker.id}`}>
          <Box
            mr={3}
            onClick={(e) => {
              if (
                e.target instanceof Element &&
                e.target.closest('button, .modal-content')
              ) {
                e.preventDefault()
                e.stopPropagation()
              }
            }}
          >
            <Avatar
              size="large"
              user={{
                photoUrl: work.worker?.profilePicUrl,
                name: work.worker?.name,
              }}
              badge={showWorkerStatusBadge ? badge : null}
            />
          </Box>
        </ModalLink>
        <Flex
          flexDirection="column"
          mr={3}
          alignItems="flex-start"
          justifyContent={'center'}
        >
          <ModalLink to={`/workers/${work.worker.id}`}>
            <Box width={140} style={{ whiteSpace: 'normal' }}>
              {toTitleCase(work.worker?.name) || (
                <Skeleton width={60} enableAnimation={false} />
              )}
            </Box>
          </ModalLink>
          {getWorkerUnderNameLabel()}
        </Flex>
      </Flex>
      {getContent()}
    </Flex>
  )
}
