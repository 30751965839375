import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Heading,
  Loading,
  Select,
  Text,
  useResponsiveValue,
} from 'ui'
import { useLatestMessages } from 'queries/message'
import { MessageListItem } from './MessageListItem'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { useShiftFilter } from './useShiftFilter'
import { MessageableShiftsModal } from './MessageableShiftsModal'

export function MessageList() {
  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useLatestMessages()
  const params = useParams<{ workerId?: string }>()
  const workerId = params.workerId ? Number(params.workerId) : undefined
  const navigate = useNavigate()
  const autoSelectFirst = useResponsiveValue([false, false, false, true])
  const {
    filteredData,
    filterOptions,
    selectedFilterOption,
    setSelectedShift,
  } = useShiftFilter(data)
  const [showUpcomingShiftsModal, setShowUpcomingShiftsModal] = useState(false)

  useEffect(() => {
    if (autoSelectFirst && !workerId && data && data[0]) {
      const [from] = data[0].direction.split('2')
      const worker = from === 'w' ? data[0].fromUser : data[0].toUser
      navigate(`/messages/${worker.id}`)
    }
  }, [data, workerId, autoSelectFirst])

  return (
    <>
      <Button
        block={true}
        mb={2}
        onClick={() => setShowUpcomingShiftsModal(true)}
      >
        Message Workers
      </Button>
      <Select
        placeholder={'Filter by Shift'}
        options={filterOptions}
        value={selectedFilterOption}
        isClearable={true}
        onChange={(newValue) => {
          if (newValue) {
            const shiftId = Number((newValue as { value: string }).value)
            setSelectedShift(shiftId)
          } else {
            setSelectedShift(null)
          }
        }}
      />
      <Heading level={3} my={0} mt={4} mb={2}>
        Workers
      </Heading>
      {isLoading ? <Loading /> : null}
      {data ? (
        <Box>
          {filteredData.map((message, index) => (
            <MessageListItem data={message} key={index} />
          ))}
        </Box>
      ) : null}
      {isFetchingNextPage ? (
        <Loading />
      ) : hasNextPage ? (
        <Button variant={'text'} onClick={() => fetchNextPage()}>
          Load More
        </Button>
      ) : null}
      {data && filteredData.length === 0 ? (
        <Text color={'neutrals.200'}>No messages found.</Text>
      ) : null}
      {showUpcomingShiftsModal ? (
        <MessageableShiftsModal
          open={showUpcomingShiftsModal}
          onClose={() => setShowUpcomingShiftsModal(false)}
        />
      ) : null}
    </>
  )
}
