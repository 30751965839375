import React from 'react'
import { Box } from 'ui'
import { PendingInvites } from './PendingInvites'
import { CompanyMembers } from './CompanyMembers'
import { GroupMembers } from './GroupMembers'
import { useAuthContext } from 'components/auth'

export function Team() {
  const { company } = useAuthContext()
  const isCompanyInGroup =
    company && company.companyGroup && company.companyGroup.companies
  return (
    <Box>
      <PendingInvites />
      <CompanyMembers />
      {isCompanyInGroup ? <GroupMembers /> : null}
    </Box>
  )
}
