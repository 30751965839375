import React, { useMemo } from 'react'
import { Page } from 'pages/layout'
import { Box, Flex, Heading, Loading, Text, toast } from '@workwhile/ui'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useCompanyPositionTutorials,
  useInvalidatePositionTutorials,
  useRemovePositionTutorialMutation,
} from 'queries/company'
import { MarkdownContent, TutorialCategory } from 'api/shift/typings'
import { convertLegacyTutorialToMarkdown } from 'lib/tutorial'
import { TutorialCard } from 'components/Tutorials/TutorialCard'
import { AllPositionTutorialCategories } from 'api/company'

export const PositionInstructionsPage = () => {
  const positionId = Number(useParams<{ positionId: string }>().positionId)
  const navigate = useNavigate()
  const { data, isFetching } = useCompanyPositionTutorials(positionId)
  const invalidateTutorials = useInvalidatePositionTutorials()
  const { mutate: removePositionTutorial, isPending: isRemoving } =
    useRemovePositionTutorialMutation({
      positionId,
      onSuccess() {
        invalidateTutorials(positionId)
      },
      onError(error) {
        toast.error(error.message)
      },
    })

  const activeTutorials = useMemo(() => {
    if (!data) {
      return []
    }
    return data.map((tutorial) => ({
      ...tutorial,
      content: convertLegacyTutorialToMarkdown(tutorial.content),
    }))
  }, [data])

  const inactiveTutorials = useMemo(
    () =>
      AllPositionTutorialCategories.filter(
        (category) => !activeTutorials.some((t) => t.category === category)
      ).map((category) => ({
        category: category as TutorialCategory,
        content: [] as MarkdownContent[],
      })),
    [activeTutorials]
  )

  const handleRemove = ({ tutorialId }: { tutorialId: number }) => {
    removePositionTutorial({ tutorialId })
  }

  const handleClick = (tutorialId: number | TutorialCategory) => {
    navigate(`/position/${positionId}/instruction/${tutorialId}`)
  }

  if (isFetching) {
    return <Loading />
  }

  return (
    <Page title="Position Instructions">
      <Text>
        Select any of the instruction categories below to add to your position.
      </Text>
      {activeTutorials.length > 0 ? (
        <Box mt={4}>
          <Heading level={3}>Active</Heading>
          <Flex mx={-2} flexWrap={'wrap'}>
            {activeTutorials.map((tutorial) => (
              <Box
                key={tutorial.id}
                width={[1, 1 / 2, 1 / 2, 1 / 3, 1 / 4]}
                p={2}
              >
                <TutorialCard
                  data={tutorial}
                  isRemoving={isRemoving}
                  removeTitle="Remove Instructions for all shifts associated with this position"
                  onRemove={handleRemove}
                  onClick={handleClick}
                />
              </Box>
            ))}
          </Flex>
        </Box>
      ) : null}
      {inactiveTutorials.length > 0 ? (
        <Box mt={4}>
          {activeTutorials.length > 0 ? (
            <Heading level={3}>Inactive</Heading>
          ) : null}
          <Flex mx={-2} flexWrap={'wrap'}>
            {inactiveTutorials.map((tutorial) => (
              <Box
                key={tutorial.category}
                width={[1, 1 / 2, 1 / 2, 1 / 3, 1 / 4]}
                p={2}
              >
                <TutorialCard
                  data={tutorial}
                  isRemoving={isRemoving}
                  onRemove={handleRemove}
                  onClick={handleClick}
                />
              </Box>
            ))}
          </Flex>
        </Box>
      ) : null}
    </Page>
  )
}
