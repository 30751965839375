import { useQuery } from '@tanstack/react-query'
import { workerComplimentsKey } from './keys'
import { getWorkerCompliments } from 'api/worker'

export function useWorkerCompliments(workerId: number) {
  return useQuery({
    queryKey: workerComplimentsKey(workerId),
    queryFn: () => getWorkerCompliments(workerId),
  })
}
