import React from 'react'
import IphoneMockup from 'components/IphoneMockup'
import { ShiftHeader } from './ShiftHeader'
import { LocationMap } from './LocationMap'
import { AboutSection } from './AboutSection'
import { RequirementSection } from './RequirementSection'
import DraggableScrollComponent from 'components/core/DraggableScrollComponent'
import { Box } from 'ui'

export function Preview() {
  return (
    <IphoneMockup>
      <DraggableScrollComponent>
        <Box pb={4}>
          <ShiftHeader />
          <LocationMap />
          <AboutSection />
          <RequirementSection />
        </Box>
      </DraggableScrollComponent>
    </IphoneMockup>
  )
}
