import { useQuery } from '@tanstack/react-query'
import { workerLogsKey } from './keys'
import { getCompanyWorkerLogs } from 'api/worker'

export function useWorkerLogs(options: {
  startDate: Date
  endDate: Date
  userId: number
}) {
  const { startDate, endDate, userId } = options
  return useQuery({
    queryKey: workerLogsKey(startDate, endDate, userId),
    queryFn: () => getCompanyWorkerLogs(options),
  })
}
