import React from 'react'
import { Box, Button, Flex, Heading } from 'ui'
import { ModalLink } from 'components/ModalLink'
import { useWorkerProfile } from './useWorkerProfile'
import { InfoLineItem } from './InfoLineItem'
import { BriefcaseIcon, HourglassIcon } from 'lucide-react'

export function ShiftStats() {
  const { profileData } = useWorkerProfile()

  return (
    <Box width={1} py={3}>
      <Heading level={4}>Shifts at {profileData.companyName}</Heading>
      <InfoLineItem
        icon={<BriefcaseIcon size={20} />}
        label={'Total Shifts'}
        value={profileData.worker.numShiftsForCompany}
      />
      <InfoLineItem
        icon={<HourglassIcon size={20} />}
        label={'Total Hours'}
        value={profileData.worker.numHoursForCompany}
      />
      <InfoLineItem
        icon={<HourglassIcon size={20} />}
        label={'Hours in the Last 7 Days'}
        value={profileData.worker.numHoursForCompanyPastWeek}
      />
      <Flex justifyContent={'flex-end'} mt={4}>
        <ModalLink to={`/workers/${profileData.worker.id}/shifts`}>
          <Button variant={'secondary'} kind={'medium'}>
            View All Shifts
          </Button>
        </ModalLink>
      </Flex>
    </Box>
  )
}
