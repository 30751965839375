import { DemoApiMock } from '../types'
import {
  getDemoData,
  getGraphqlRequest,
  matchGraphqlRequest,
} from 'api/demo/utils'
import { pathOr } from 'ramda'
import { PaginatedShiftTimeline, TimelineShift } from 'api/shift'

export const mockShiftTimeline: DemoApiMock = {
  shouldMock: (request) => matchGraphqlRequest(request, /infiniteShifts/),
  getResponse: async (request) => {
    const graphqlRequest = getGraphqlRequest(request)
    const startAt = pathOr('', ['variables', 'startDate'], graphqlRequest)
    const headCursor = pathOr(
      '',
      ['variables', 'headCursor'],
      graphqlRequest
    ) as string
    const tailCursor = pathOr(
      '',
      ['variables', 'tailCursor'],
      graphqlRequest
    ) as string
    const positionId: number | undefined = pathOr(
      undefined,
      ['variables', 'positionId'],
      graphqlRequest
    )
    const locationId: number | undefined = pathOr(
      undefined,
      ['variables', 'locationId'],
      graphqlRequest
    )
    const demoData = getDemoData()
    const shifts = demoData.shifts.filter((shift) => {
      if (locationId && shift.location?.id !== locationId) {
        return false
      }
      if (positionId && shift.position?.id !== positionId) {
        return false
      }
      return true
    })

    let timelineData: PaginatedShiftTimeline | undefined
    if (startAt) {
      const startDate = new Date(startAt)
      const filteredShifts = shifts.filter((shift) => {
        return new Date(shift.startsAt!).getTime() >= startDate.getTime()
      })
      const headCursorShift = filteredShifts.length ? filteredShifts[0] : null
      timelineData = {
        items: filteredShifts as TimelineShift[],
        headCursor: headCursorShift
          ? `${headCursorShift.startsAt}|${headCursorShift.id}`
          : undefined,
        tailCursor: undefined,
      }
    } else if (headCursor) {
      const [startsAt, id] = headCursor.split('|')
      const startDate = new Date(startsAt)
      const filteredShifts = shifts.filter((shift) => {
        const shiftStartsAt = new Date(shift.startsAt!)
        if (shiftStartsAt < startDate) {
          return true
        }
        if (shiftStartsAt > startDate) {
          return false
        }
        return shift.id < Number(id)
      })
      const tailCursorShift = filteredShifts[filteredShifts.length - 1]
      timelineData = {
        items: filteredShifts as TimelineShift[],
        headCursor: undefined,
        tailCursor: tailCursorShift
          ? `${tailCursorShift.startsAt}|${tailCursorShift.id}`
          : undefined,
      }
    } else if (tailCursor) {
      const [startsAt, id] = tailCursor.split('|')
      const startDate = new Date(startsAt)
      const filteredShifts = shifts.filter((shift) => {
        const shiftStartsAt = new Date(shift.startsAt!)
        if (shiftStartsAt > startDate) {
          return true
        }
        if (shiftStartsAt < startDate) {
          return false
        }
        return shift.id > Number(id)
      })
      const headCursorShift = filteredShifts.length ? filteredShifts[0] : null
      timelineData = {
        items: filteredShifts as TimelineShift[],
        headCursor: headCursorShift
          ? `${headCursorShift.startsAt}|${headCursorShift.id}`
          : undefined,
        tailCursor: undefined,
      }
    }

    return {
      data: {
        company: {
          infiniteShifts: {
            ...timelineData,
            items: timelineData?.items,
          },
        },
      },
    }
  },
}
