import { graphql } from 'api/graphql'
import { path } from 'ramda'
import { TimelineShift } from 'api/shift'

export async function getReviewEvaluateShifts() {
  const data = await graphql(
    `company {
      performanceReviewShifts: shifts(status:"pending_review") {
        location {name, id, address{timezone}}
        position {
          name, id
        }
        startsAt, endsAt, id
        work {
          id, status, completedAt, finishedEarlyCategory
        }
      },
    }`,
    'shifts_to_review_needs_attention'
  )

  return path(
    ['data', 'data', 'company', 'performanceReviewShifts'],
    data
  ) as TimelineShift[]
}
