import { useMemo } from 'react'
import { getLocalTimezone, toMinutes } from 'lib/time'
import { useShiftEditor } from '../../useShiftEditor'
import { addDays, addMinutes, startOfDay } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'

export function useShiftDate() {
  const { shiftData } = useShiftEditor()

  const [startsAt, endsAt] = useMemo(() => {
    if (
      !shiftData.schedule?.endTime ||
      !shiftData.schedule.startTime ||
      !shiftData.schedule?.selectedDays[0]
    ) {
      return [null, null]
    }

    const timezone = shiftData.schedule?.timezone || getLocalTimezone()
    const startMinutes = toMinutes(shiftData.schedule.startTime.value)
    const endMinutes = toMinutes(shiftData.schedule.endTime.value)
    const date = shiftData.schedule.selectedDays[0]
    const startsAt = addMinutes(startOfDay(date), startMinutes)
    const endsAt = addMinutes(startOfDay(date), endMinutes)

    return [
      zonedTimeToUtc(startsAt, timezone),
      zonedTimeToUtc(
        endMinutes < startMinutes ? addDays(endsAt, 1) : endsAt,
        timezone
      ),
    ]
  }, [shiftData])

  return {
    startsAt,
    endsAt,
  }
}
