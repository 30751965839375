import { useMutation } from '@tanstack/react-query'
import {
  createCompanyLocation,
  LocationRequest,
  updateCompanyLocation,
} from 'api/company'
import { useCompanyInfo } from './useCompanyInfo'
import { useInvalidateCompanyLocations } from './useCompanyLocations'

interface UseSaveLocationMutation {
  locationId?: number
  onSuccess?: (locationId?: number) => void
  onError?: (err: Error) => void
}

export function useSaveLocationMutation(options?: UseSaveLocationMutation) {
  const { locationId, ...otherOptions } = options || {}
  const { data: company } = useCompanyInfo()
  const { invalidate: invalidateLocations } = useInvalidateCompanyLocations()

  return useMutation({
    mutationFn: async (data: LocationRequest) => {
      if (!company?.id) {
        throw new Error('Company id not found')
      }

      if (!locationId) {
        const locationId = await createCompanyLocation(company.id, data)
        await invalidateLocations()
        return locationId
      }

      await updateCompanyLocation(company.id, locationId, data)
      await invalidateLocations()
    },
    ...otherOptions,
  })
}
