import { useCompanyInfo } from '../company'
import { useMutation } from '@tanstack/react-query'
import { updateRoster } from 'api/worker'
import { useInvalidateRosterDetail } from './useRosterDetail'

interface UseUpdateRosterMutation {
  rosterId: number
  onSuccess?: () => void
  onError?: (err: Error) => void
}

export function useUpdateRosterMutation(options: UseUpdateRosterMutation) {
  const { data: company } = useCompanyInfo()
  const { rosterId, onSuccess, ...queryOptions } = options
  const { invalidate: invalidateRoster } = useInvalidateRosterDetail(rosterId)

  return useMutation({
    mutationFn: async (roster: { name: string }) => {
      if (!company?.id) {
        throw new Error('Company id not found')
      }

      return updateRoster(company.id, rosterId, { name: roster.name })
    },
    onSuccess() {
      invalidateRoster()
      if (onSuccess) onSuccess()
    },
    ...queryOptions,
  })
}
