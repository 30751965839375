import { WorkSubmissionRequest, approveWork, updateWork } from 'api/work'
import { useCompanyInfo } from '../company'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { shouldShowApproveButton } from 'lib/work'
import { Work } from 'typings/common_defs'
import { useInvalidateShiftDetail } from 'queries/company/useCompanyShiftDetail'
import {
  useInvalidateReviewEvaluateShifts,
  useInvalidateReviewOvertimeApprovalShifts,
} from 'queries/review'
import { useUpdateTimelineShift } from '../shift'

export function useSaveWorkMutation(options: {
  editedWork: Work
  shiftId: number
  onSuccess?: () => void
  onError?: (err: AxiosError) => void
}) {
  const { data: company } = useCompanyInfo()

  const { invalidate } = useInvalidateShiftDetail([options.shiftId])
  const { invalidate: invalidateReviewOvertimeApprovalShifts } =
    useInvalidateReviewOvertimeApprovalShifts()
  const { invalidate: invalidateReviewEvaluateShifts } =
    useInvalidateReviewEvaluateShifts()
  const { updateShift: updateTimelineShift } = useUpdateTimelineShift()

  return useMutation({
    mutationFn: async (data: WorkSubmissionRequest) => {
      if (!company?.id || options.shiftId === -1) {
        return
      }

      if (shouldShowApproveButton(options.editedWork)) {
        return approveWork(company.id, options.shiftId, data)
      }

      return updateWork(company.id, options.shiftId, data)
    },
    ...options,
    onSuccess: () => {
      if (options.onSuccess) {
        options.onSuccess()
      }
      updateTimelineShift(options.shiftId)
      invalidateReviewOvertimeApprovalShifts()
      invalidateReviewEvaluateShifts()
      invalidate()
    },
  })
}
