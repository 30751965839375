import React from 'react'
import { Box, Button, Flex, useResponsiveValue } from 'ui'
import { LocationSelector } from 'components/LocationSelector'
import { PositionSelector } from 'components/PositionSelector'
import { useHomeContext } from './useHomeContext'
import { SelectableOption } from 'typings/selectable_ext'
import { CSVModal } from './CSVModal'

export function FilterBar() {
  const { locationId, positionId, setLocationId, setPositionId } =
    useHomeContext()

  const locationPlaceholder = useResponsiveValue([
    'Location',
    'Filter by Location',
  ])
  const positionPlaceholder = useResponsiveValue([
    'Position',
    'Filter by Position',
  ])
  return (
    <Flex mt={[0, 2]} zIndex={2}>
      <Box width={[3 / 7, 2 / 5]} pr={[1, 2]}>
        <LocationSelector
          value={locationId}
          placeholder={locationPlaceholder}
          isClearable={true}
          onChange={(newValue) => {
            if (!newValue) {
              setLocationId(undefined)
              return
            }
            const { value } = newValue as SelectableOption
            if (value) {
              setLocationId(Number(value))
            }
          }}
        />
      </Box>
      <Box width={[3 / 7, 2 / 5]} pl={[1, 2]}>
        <PositionSelector
          value={positionId}
          placeholder={positionPlaceholder}
          isClearable={true}
          onChange={(newValue) => {
            if (!newValue) {
              setPositionId(undefined)
              return
            }
            const { value } = newValue as SelectableOption
            if (value) {
              setPositionId(Number(value))
            }
          }}
        />
      </Box>
      <Box flex={1} display={['none', 'block']} />
      <CSVModal
        trigger={
          <Button variant={'secondary'} width={['auto', 100]} ml={[2, 0]}>
            CSV
          </Button>
        }
      />
    </Flex>
  )
}
