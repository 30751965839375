import { graphql } from '../graphql'
import { path } from 'ramda'

export enum MealBreakStatusEnum {
  Scheduled = 'scheduled', // meal break is scheduled to be taken, but workers cannot yet take it
  Available = 'available', // meal break is available to be taken
  Taken = 'taken', // meal break is taken
  Overdue = 'overdue', // meal break is overdue by the legal requirement
  Waived = 'waived', // meal break is waived
  Missed = 'missed', // user-reported missed break
}

export interface MealBreak {
  index: number
  status: MealBreakStatusEnum
  takeBefore: string
  takenAt: string
  waiveable: boolean
}

export async function getWorkMealBreaks(shiftId: number, workId: number) {
  const data = await graphql(
    `company {
        shiftsDetail(ids: [${shiftId}]) {
          work(workId: ${workId}) {
            isSelfClockedOut
            mealbreaks {
              index, status, takeBefore, takenAt, waiveable
            }
          }
        }
      }`,
    'shift_work_mealbreaks'
  )

  return path(
    ['data', 'data', 'company', 'shiftsDetail', 0, 'work', 0],
    data
  ) as {
    isSelfClockedOut: boolean
    mealbreaks: MealBreak[]
  }
}
