import { useContext } from 'react'
import { ShiftDetailContext } from './ShiftDetailProvider'

export function useShiftDetail() {
  const context = useContext(ShiftDetailContext)
  if (!context) {
    throw new Error('useShiftDetail must be used within a ShiftDetailProvider')
  }
  return context
}
