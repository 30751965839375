import React from 'react'
import { FiBell } from 'react-icons/fi'
import styled from 'styled-components'
import { Badge } from 'ui'
import { useLayoutContext } from 'pages/layout/useLayoutContext'

const ButtonWrapper = styled.div`
  position: relative;
  ${Badge} {
    position: absolute;
    top: -0.6rem;
    left: 100%;
    margin-left: -1rem;
  }
`

const NotificationIcon = styled.button`
  border-radius: 100%;
  height: 3.4rem;
  width: 3.4rem;
  outline: none;
  border: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({ theme: { colors } }) => `
    background-color: ${colors.errors[50]};
    
    &:hover {
      background-color: ${colors.errors[100]};
      color: ${colors.white};
    }
  `}
`

export function NotificationButton() {
  const { toggleNotificationCenter } = useLayoutContext()

  return (
    <ButtonWrapper onClick={toggleNotificationCenter}>
      <NotificationIcon>
        <FiBell fontSize={18} />
      </NotificationIcon>
      <Badge count={10} />
    </ButtonWrapper>
  )
}
