import React from 'react'
import { CompanyLog, LogEventType } from 'api/company'
import { ShiftSummary } from './ShiftSummary'
import { Box, Message, Text } from 'ui'
import { toTitleCase } from 'lib/string'

export function LogItemDescription(props: { data: CompanyLog }) {
  const { data } = props
  const { eventType, message, shift } = data

  if (shift) {
    if (eventType === LogEventType.ShiftModify) {
      const json = message
        .split('changes=')[1]
        .replace(/'/g, '"')
        .replace(/Decimal\("(.+)"\)/g, '$1')
      try {
        const changes = JSON.parse(json)
        return (
          <Box>
            {Object.keys(changes).map((key) => (
              <Text key={key}>
                {toTitleCase(key.replace(/_/, ' '))}: {changes[key]}
              </Text>
            ))}
          </Box>
        )
      } catch {
        return (
          <Message variant={'warning'}>Unable to parse JSON: {json}</Message>
        )
      }
    }

    return <ShiftSummary data={shift} />
  }

  return <Text color={'lightText'}>No additional details.</Text>
}
