import React, { useMemo } from 'react'
import { useShiftEditor } from '../useShiftEditor'
import { useCompanyPosition } from 'hooks/useCompanyPosition'
import { Box, Heading, Text } from 'ui'
import { groupBy } from 'ramda'

export function RequirementSection() {
  const { shiftData } = useShiftEditor()
  const position = useCompanyPosition(shiftData?.details?.positionId)
  const requirementGroups = useMemo(() => {
    if (!position?.requirements2) {
      return {}
    }

    return groupBy(
      (requirement) => requirement.category ?? 'Other',
      position.requirements2 ?? []
    )
  }, [position])

  if (!position || (position.requirements2 ?? []).length === 0) {
    return null
  }

  return (
    <Box
      boxShadow={'low'}
      borderRadius={'standard'}
      bg={'white'}
      p={3}
      mt={3}
      mx={3}
    >
      <Heading level={4} my={0} mb={2}>
        Requirements
      </Heading>
      <Text fontSize={0}>
        You may be turned away from the shift without pay if you fail to meet
        all of the requirements.
      </Text>
      {Object.keys(requirementGroups).map((groupName) => (
        <Box mt={3} key={groupName}>
          <Text fontWeight={2}>{groupName}</Text>
          {requirementGroups[groupName]?.map((requirement) => (
            <Text key={requirement.id} fontSize={0}>
              •&nbsp;{requirement.name}
            </Text>
          ))}
        </Box>
      ))}
    </Box>
  )
}
