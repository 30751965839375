import { calculateEstimatedPay } from 'lib/payment'
import { formatCurrency } from 'lib/string'
import { calculateEstimatedTimeWorked } from 'lib/work'
import { AssignedWorkerOptionsEnum } from 'lib/work/assigned_work_options'
import { useShiftDetail } from 'pages/ShiftDetailPage/useShiftDetail'
import React from 'react'
import { Work } from 'typings/common_defs'
import { Flex, Paragraph } from 'ui'
import { useAssignedWorkRow } from '../useAssignedWorkerRow'
import { BreakInfo } from '../mealbreaks/BreakInfo'

interface Props {
  work: Work
}

export const AssignedWorkerDurationAndPay = ({ work }: Props) => {
  const { shift } = useShiftDetail()
  const { data } = useAssignedWorkRow(work.id)

  if (!shift) return null

  if (data.finishedEarlyCategory === AssignedWorkerOptionsEnum.NO_SHOW) {
    return (
      <Flex flexDirection="column" justifyContent={'center'} mr={3} width={180}>
        <Paragraph fontWeight={2} my={0}>
          Total
        </Paragraph>
        <Paragraph my={0}>0mins, {formatCurrency(0)}</Paragraph>
      </Flex>
    )
  }

  if (!data.startedAt || !data.completedAt) {
    return null
  }

  return (
    <>
      <Flex flexDirection="column" justifyContent={'center'} mr={3} width={180}>
        <Paragraph fontWeight={2} my={0}>
          Total
        </Paragraph>
        <Paragraph my={0}>
          {calculateEstimatedTimeWorked(data)},{' '}
          {formatCurrency(calculateEstimatedPay(data, shift))}
        </Paragraph>
      </Flex>
      <BreakInfo
        workId={work.id}
        renderContent={({ description, detailTrigger }) => (
          <Flex
            flexDirection="column"
            justifyContent={'center'}
            mr={3}
            width={110}
          >
            <Paragraph fontWeight={2} my={0}>
              Break
            </Paragraph>
            <Flex alignItems={'center'}>
              {description}&nbsp;
              {detailTrigger}
            </Flex>
          </Flex>
        )}
      />
    </>
  )
}
