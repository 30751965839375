import React, { useEffect } from 'react'
import { Routes, Route, Location } from 'react-router'
import { Box, tabletScreen } from 'ui'
import { MOBILE_NAV_HEIGHT, PAGE_SIZE } from './layout'
import { useLayoutContext } from './layout/useLayoutContext'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { ShiftDetail } from './ShiftDetailPage'
import { ShiftEditorPage } from './ShiftEditorPage'
import { WorkerProfilePage } from './WorkerProfilePage'
import { ListDetailPage } from './ListDetailPage'
import { InvoiceDetailPage } from './InvoiceDetailPage'
import { WorkerShiftsPage } from './WorkerShiftsPage'

const ModalContainer = styled(Box).attrs({
  backgroundColor: 'white',
  height: '100%',
  overflow: 'auto',
  pt: [MOBILE_NAV_HEIGHT, MOBILE_NAV_HEIGHT, 0],
})<{ $navCollapsed: boolean }>`
  ${({ $navCollapsed }) => `
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 11;
    
    .page-content {
      --padding: calc((100% - ${PAGE_SIZE}) / 2);
      padding-left: var(--padding);
      padding-right: var(--padding);
    }
    
    .page-container {
      max-width: ${PAGE_SIZE};
      margin-left: auto;
      margin-right: auto;
      padding-left: ${$navCollapsed ? '10rem' : '0'};
  
      ${tabletScreen(`
          margin-left: 0;
          margin-right: 0;
          height: calc(100vh - ${MOBILE_NAV_HEIGHT}px);
      `)}
    }
  `}
`

const routes = (
  <>
    <Route path={'/shift/:shiftId'} element={<ShiftDetail />} />
    <Route
      path={'/home/shift-editor/:shiftId/*'}
      element={<ShiftEditorPage />}
    />
    <Route path={'/workers/:workerId'} element={<WorkerProfilePage />} />
    <Route path={'/workers/list/:rosterId'} element={<ListDetailPage />} />
    <Route path={'/workers/:workerId/shifts'} element={<WorkerShiftsPage />} />
    <Route
      path={'/payments/invoice/:invoiceId'}
      element={<InvoiceDetailPage />}
    />
  </>
)

const ModalContent = ({
  location,
  zIndex,
  isNavCollapsed,
}: {
  location: Location
  zIndex: number
  isNavCollapsed: boolean
}) => (
  <ModalContainer $navCollapsed={isNavCollapsed} style={{ zIndex }}>
    <Box className={'page-container modal-page-container'}>
      <Routes location={location}>{routes}</Routes>
    </Box>
  </ModalContainer>
)

export function ModalRoutes() {
  const { isNavCollapsed } = useLayoutContext()
  const location = useLocation()
  const backgroundStack =
    (location.state && location.state.backgroundStack) || []
  const baseModalZIndex = 999

  useEffect(() => {
    if (backgroundStack.length > 0) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [backgroundStack])

  // Function to recursively render modals (while maintaining the context of the background modal)
  const renderForegroundModal = (index: number) => {
    if (index > backgroundStack.length) {
      return null
    }
    const modalLocation = backgroundStack[index] || location
    return (
      <>
        {renderForegroundModal(index + 1)}
        <ModalContent
          location={modalLocation}
          zIndex={baseModalZIndex + index}
          isNavCollapsed={isNavCollapsed}
        />
      </>
    )
  }
  return renderForegroundModal(1)
}
