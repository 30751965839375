import React, { useState } from 'react'
import { Box, Field, Heading, Input, Button } from 'ui'
import { WWLogo } from 'components/WWLogo'
import * as zod from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { runOktaLogin } from 'lib/okta'

const schema = zod.object({
  email: zod
    .string({
      required_error: 'Email is required',
    })
    .email('Must be valid email address'),
})

type FormData = zod.infer<typeof schema>

export function OktaLoginForm() {
  const [loading, setLoading] = useState(false)

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const onSubmit = handleSubmit((data) => {
    setLoading(true)
    runOktaLogin(data.email)
  })

  return (
    <Box margin={'auto'} pb={4} width={[1, 450]}>
      <form onSubmit={onSubmit}>
        <WWLogo size={'medium'} />
        <Heading level={2} my={4}>
          Sign in to WorkWhile.
        </Heading>
        <Field label={'Email'} error={errors.email?.message?.toString()}>
          <Input
            disabled={loading}
            autoFocus={true}
            placeholder={'e.g. jane.doe@example.com'}
            {...register('email')}
          />
        </Field>
        <Box mt={4}>
          <Button block={true} size={'large'} type={'submit'} loading={loading}>
            Sign in with Okta
          </Button>
        </Box>
      </form>
    </Box>
  )
}
