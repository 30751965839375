import React, { useMemo } from 'react'
import { TimelineShift } from 'api/shift'
import { Text, Pluralize } from '@workwhile/ui'
import { useWorkerStats } from './useWorkerStats'
import { User } from 'typings/common_defs'

export function FillStatusText(props: { shift: TimelineShift }) {
  const { shift } = props
  const {
    isRSW,
    workers,
    workersNeeded,
    totalWorkers,
    shouldShowConfirmed,
    confirmed,
    declined,
    clockedIn,
  } = useWorkerStats(shift)

  const fillStatusText = useMemo(() => {
    if (isRSW) {
      if (workers.length === totalWorkers.length) {
        return 'Shift has been filled!'
      }

      const rosters = shift.rosters || []
      const allRosterWorkers = rosters.reduce((acc, roster) => {
        if (roster) {
          return [...acc, ...(roster.workers || [])]
        }
        return acc
      }, [] as Array<Pick<User, 'id' | 'name' | 'profilePicUrl'>>)

      return [
        `${allRosterWorkers.length} invited`,
        confirmed ? `${confirmed} requests confirmed` : null,
        declined ? `${declined} declined` : null,
      ]
        .filter((t) => t)
        .join(', ')
    }

    return (
      <>
        Filled {Math.min(workers.length, workersNeeded)} of{' '}
        <Pluralize
          count={workersNeeded}
          oneText={'worker'}
          manyText={'workers'}
        />
        {shouldShowConfirmed && confirmed > 0
          ? `, ${confirmed} Confirmed`
          : null}
        {clockedIn > 0 ? `, ${clockedIn} Clocked In` : null}
      </>
    )
  }, [
    shift,
    isRSW,
    workers,
    totalWorkers,
    shouldShowConfirmed,
    confirmed,
    clockedIn,
  ])

  return <Text color={'lightText'}>{fillStatusText}</Text>
}
