import React, { useMemo, useState } from 'react'
import { WorkForCompany } from 'api/worker'
import { Box, Button, Table, useResponsiveValue } from 'ui'
import { completedColumns, upcomingColumns } from './columns'

interface Props {
  data: WorkForCompany[]
  type: 'upcoming' | 'completed'
}

const PAGE_SIZE = 20

export function ShiftTable(props: Props) {
  const { data, type } = props
  const [limit, setLimit] = useState(PAGE_SIZE)
  const paginatedData = useMemo(() => {
    return data.slice(0, limit)
  }, [data, limit])
  const shouldUseCard = useResponsiveValue([true, true, false])
  const columns = type === 'upcoming' ? upcomingColumns : completedColumns

  return (
    <Box my={3}>
      <Table
        variant={shouldUseCard ? 'borderless' : 'standard'}
        columns={shouldUseCard ? columns.slice(0, 1) : columns}
        data={paginatedData}
      />
      {props.data.length > limit ? (
        <Button mt={2} onClick={() => setLimit(limit + PAGE_SIZE)}>
          Load More
        </Button>
      ) : null}
    </Box>
  )
}
