import React from 'react'
import { Page } from '../layout'
import { ListSection } from './ListSection'
import { WorkerSection } from './WorkerSection'
import { WorkerSearchProvider } from './WorkerSearchProvider'

export function WorkersPage() {
  return (
    <Page title={'Workers'}>
      <ListSection />
      <WorkerSearchProvider>
        <WorkerSection />
      </WorkerSearchProvider>
    </Page>
  )
}
