import React from 'react'
import styled from 'styled-components'
import { Heading, Loading } from 'ui'

const CompanySwitchingDiv = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000;
  position: absolute;
  left: 0;
  top: 0;
`

const CompanySwitchingContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: white;
`

export function CompanySwitchIndicator() {
  return (
    <CompanySwitchingDiv>
      <CompanySwitchingContent>
        <Loading />
        <Heading level={3}>Switching companies...</Heading>
      </CompanySwitchingContent>
    </CompanySwitchingDiv>
  )
}
