import { useMutation } from '@tanstack/react-query'
import { markChatAsRead } from 'api/message'
import { useResetUnreadCount } from './useLatestMessages'
import { useAuthContext } from 'components/auth'

export function useMarkAsReadMutation(workerId: number) {
  const { company } = useAuthContext()
  const { resetUnreadCount } = useResetUnreadCount(workerId)

  return useMutation({
    mutationFn: () => markChatAsRead(company?.id as number, [workerId]),
    onSuccess: resetUnreadCount,
  })
}
