import React, { useState } from 'react'
import { RosterSelector } from 'components/RosterSelector'
import { Controller, useFormContext } from 'react-hook-form'
import { Box, Button, Text } from 'ui'
import { SelectedRosters } from './SelectedRosters'

export function Roster() {
  const { control, watch } = useFormContext()
  const [open, setOpen] = useState(false)
  const rosterIds = watch('rosterIds')
  const hasSelectedRosters = (rosterIds ?? []).length > 0

  return (
    <Box>
      <Text my={2} mb={3}>
        A text message will be sent to the workers on your chosen Lists,
        inviting them to accept this shift.
      </Text>
      {!hasSelectedRosters ? (
        <Button width={[1, 200]} onClick={() => setOpen(true)}>
          Add Lists
        </Button>
      ) : null}
      <SelectedRosters openSelector={() => setOpen(true)} />
      <Controller
        render={({ field: { value, onChange } }) => (
          <RosterSelector
            open={open}
            onClose={() => setOpen(false)}
            selected={value}
            onChange={(selected) => {
              onChange(selected)
              setOpen(false)
            }}
          />
        )}
        name={'rosterIds'}
        control={control}
      />
    </Box>
  )
}
