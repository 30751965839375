import { Avatar, Text, IconButton, Box } from '@workwhile/ui'
import React, { PropsWithChildren } from 'react'
import { User } from 'typings/common_defs'
import { LucideX } from 'lucide-react'
import styled from 'styled-components'
import { track } from 'lib/amplitude'

interface Props extends PropsWithChildren {
  worker: User
  setSelected: React.Dispatch<React.SetStateAction<User[]>>
}

export const SelectedWorker = ({ worker, setSelected, children }: Props) => {
  return (
    <SelectedWorkerPill>
      <Avatar
        size={'small'}
        user={{
          userId: worker.id.toString(),
          name: worker.name,
          photoUrl: worker.profilePicUrl,
        }}
      />
      <Box mx={2}>
        <Text>{worker.name}</Text>
        {children}
      </Box>
      <IconButton
        onClick={() => {
          track('button_click, deselect_worker', {
            workerId: worker.id,
            workerName: worker.name,
          })
          setSelected((selected) =>
            selected.filter((item) => item.id !== worker.id)
          )
        }}
      >
        <LucideX size={18} />
      </IconButton>
    </SelectedWorkerPill>
  )
}

const SelectedWorkerPill = styled(Box).attrs({
  display: 'inline-flex',
  alignItems: 'center',
  mr: 2,
  mb: 2,
  px: 2,
  py: 1,
})`
  ${({ theme: { colors, radii } }) => `
      border: 1px solid ${colors.primary};
      border-radius: ${radii.standard};
    `}
`
