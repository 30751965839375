import React from 'react'
import { useShiftDetail } from '../useShiftDetail'
import { Avatar, Box, Flex, Text } from 'ui'
import Skeleton from 'react-loading-skeleton'
import ShiftDetailWorkerStatus from '../assigned_workers/ShiftDetailWorkerStatus'
import { isWorkRowUnassigned } from 'lib/work'

export const ShiftLeadRows = () => {
  const { assignedWorkRows, isLoading } = useShiftDetail()

  if (isLoading) {
    return (
      <Flex flexDirection="row" alignItems="center" mb={2}>
        <Box mr={3}>
          <Skeleton circle={true} width={50} height={50} />
        </Box>
        <Box>
          <Box mb={2}>
            <Skeleton width={130} height={20} />
          </Box>
          <Skeleton width={80} height={16} />
        </Box>
      </Flex>
    )
  }

  return assignedWorkRows.map((work) => {
    return (
      <Flex flexDirection="row" alignItems="center" key={work.id} mb={2}>
        <Box mr={3}>
          <Avatar
            size="large"
            user={{
              photoUrl: work.worker?.profilePicUrl,
              name: isWorkRowUnassigned(work) ? undefined : work.worker?.name,
            }}
            badge={null}
          />
        </Box>
        <Box>
          <Text fontWeight="bold">
            {isWorkRowUnassigned(work)
              ? null
              : work.worker?.name || <Skeleton />}
          </Text>
          <ShiftDetailWorkerStatus work={work} />
        </Box>
      </Flex>
    )
  })
}
