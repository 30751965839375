import React from 'react'
import { Flex, Heading, Text } from 'ui'
import { CalendarCheck2Icon } from 'lucide-react'

export function EmptyState() {
  return (
    <Flex
      flex={1}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Flex
        alignItems={'center'}
        justifyContent={'center'}
        borderRadius={'50%'}
        backgroundColor={'white'}
        border={'2px solid'}
        borderColor={'neutrals.100'}
        height={'8.8rem'}
        width={'8.8rem'}
      >
        <CalendarCheck2Icon size={40} />
      </Flex>
      <Heading level={4} color={'lightText'}>
        Welcome to your Schedule page!
      </Heading>
      <Text textAlign={'center'} color={'lightText'}>
        You will see your shifts posted here once they have been created.
        <br />
        Hover over the Create Shift (+ icon) on the nav bar to create your first
        shift.
      </Text>
    </Flex>
  )
}
