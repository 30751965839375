import React, { useMemo, useState } from 'react'
import { Tutorial, TutorialCategory } from 'api/shift/typings'
import {
  Box,
  Card,
  Flex,
  Heading,
  Icon,
  IconButton,
  Text,
  MarkdownProvider,
  MarkdownEditor,
  extractImagesFromMarkdown,
  Loading,
} from '@workwhile/ui'
import { getTutorialMetadata } from 'lib/tutorial'
import { PencilLineIcon, Trash2Icon } from 'lucide-react'
import { SaveOptionsModal } from '../SaveOptionsModal'
import { ImagePreview } from './styled'
import { AllPositionTutorialCategories } from 'api/company/companyPosition'

interface Props {
  data: Pick<Tutorial, 'category' | 'content'> & { id?: number }
  isRemoving: boolean
  showOptions?: boolean
  removeTitle?: string
  position?: string
  location?: string
  onRemove: ({
    tutorialId,
    propagate,
  }: {
    tutorialId: number
    propagate?: boolean
  }) => void
  onClick: (tutorialId: number | TutorialCategory) => void
}

export function TutorialCard({
  data,
  isRemoving,
  onRemove,
  onClick,
  showOptions,
  removeTitle,
  position,
  location,
}: Props) {
  const { category, content, id } = data
  const metadata = getTutorialMetadata(category as TutorialCategory)
  const images = useMemo(() => {
    if (content[0]?.markdown) {
      return extractImagesFromMarkdown(content[0].markdown)
    }
    return []
  }, [content])
  const [showRemovalOptions, setShowRemovalOptions] = useState(false)

  const positionOrLocation = useMemo(() => {
    if (category && AllPositionTutorialCategories.includes(category)) {
      return { position }
    }
    return { location }
  }, [category, position, location])

  if (!metadata) {
    return null
  }

  return (
    <>
      <Card
        borderWidth={0}
        height={'100%'}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          const tutorialId = id || category
          if (tutorialId) {
            onClick(tutorialId)
          }
        }}
      >
        <Heading level={4} my={0} mb={1}>
          <Flex alignItems={'center'}>
            <Icon icon={metadata.icon} size={24} />
            <Text ml={2}>{metadata?.title}</Text>
          </Flex>
        </Heading>
        {id ? (
          <Flex flexDirection={'column'} flex={1}>
            <Box flex={1}>
              <Flex flexWrap={'wrap'} mt={3}>
                {images.map((image, index) => (
                  <ImagePreview
                    key={index}
                    style={{ backgroundImage: `url(${image.src})` }}
                    mr={2}
                  />
                ))}
              </Flex>
              <Box m={-2} maxHeight={100} overflow={'hidden'} mb={3}>
                <MarkdownProvider>
                  <MarkdownEditor
                    readonly
                    borderless
                    scale={0.8}
                    allowMedia={false}
                    defaultValue={content[0].markdown}
                  />
                </MarkdownProvider>
              </Box>
            </Box>
            <Flex alignItems={'center'} justifyContent={'flex-end'}>
              {isRemoving ? (
                <Loading />
              ) : (
                <>
                  <IconButton color={'primary'} mr={2}>
                    <Icon
                      icon={PencilLineIcon}
                      size={20}
                      color={'currentcolor'}
                    />
                  </IconButton>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation()
                      setShowRemovalOptions(true)
                    }}
                  >
                    <Icon icon={Trash2Icon} size={20} color={'currentcolor'} />
                  </IconButton>
                </>
              )}
            </Flex>
          </Flex>
        ) : (
          <Text
            color={'primary'}
            fontSize={1}
            style={{ textDecoration: 'underline' }}
          >
            Add {metadata?.description}
          </Text>
        )}
      </Card>
      {showRemovalOptions ? (
        <SaveOptionsModal
          open={showRemovalOptions}
          isRemoving
          showOptions={showOptions}
          removeTitle={removeTitle}
          category={category}
          {...positionOrLocation}
          onOk={(propagate) => {
            if (id) {
              onRemove({ tutorialId: id, propagate })
              setShowRemovalOptions(false)
            }
          }}
          onClose={() => setShowRemovalOptions(false)}
        />
      ) : null}
    </>
  )
}
