import { Tutorial } from 'api/shift/typings'
import { convertLegacyTutorialToMarkdown } from 'lib/tutorial'
import { useMemo } from 'react'

export function useCompatTutorial(tutorial?: Tutorial) {
  return useMemo(() => {
    if (!tutorial) {
      return tutorial
    }
    return {
      ...tutorial,
      content: convertLegacyTutorialToMarkdown(tutorial.content),
    }
  }, [tutorial])
}
