import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'

export type AssignmentWorkflowsContextState = {
  requestWorkersFlow: {
    open: () => void
    close: () => void
    isOpen: boolean
  }
}

export const AssignmentWorkflowsContext =
  createContext<AssignmentWorkflowsContextState | null>(null)

export const useAssignmentWorkflowsContextStateValue = () => {
  const context = useContext(AssignmentWorkflowsContext)
  if (!context) {
    throw new Error(
      'useAssignmentWorkflowsContextStateValue must be used within a <AssignmentWorkflowsProvider />'
    )
  }
  return context
}

type AssignmentWorkflowsProviderProps = PropsWithChildren

/**
 * Provides the state for the assignment workflows such as the request workers, etc.
 */
export const AssignmentWorkflowsProvider = ({
  children,
}: AssignmentWorkflowsProviderProps) => {
  const [isRequestWorkersFlowOpen, setIsRequestWorkersFlowOpen] =
    useState(false)

  const value: AssignmentWorkflowsContextState = {
    requestWorkersFlow: {
      open: () => setIsRequestWorkersFlowOpen(true),
      close: () => setIsRequestWorkersFlowOpen(false),
      isOpen: isRequestWorkersFlowOpen,
    },
  }

  return (
    <AssignmentWorkflowsContext.Provider value={value}>
      {children}
    </AssignmentWorkflowsContext.Provider>
  )
}
