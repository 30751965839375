export const feedbackPrompts: {
  title: string
  description: string
  tag: string
  other: boolean
}[] = [
  {
    title: 'Check-in before leaving',
    description:
      'Remember to check in with a supervisor if you need to leave before being dismissed! Otherwise, you may be reported for abandoning a shift.',
    tag: 'check-in-before-leaving',
    other: false,
  },

  {
    title: 'Improve productivity',
    description:
      "Make sure you are achieving the productivity goals for the task. Ask a supervisor for help if you don't know what you're supposed to be doing or find the task difficult.",
    tag: 'improve-productivity',
    other: false,
  },

  {
    title: 'Put phone away, focus on task',
    description:
      "Be sure to put your phone away after clock-in! It's not safe to be on the phone while working. If you need to use your phone because of an emergency please inform your supervisor.",
    tag: 'focus-on-task',
    other: false,
  },

  {
    title: 'Be respectful of colleagues',
    description:
      'Always try to be be respectful and polite. Workers with a positive attiude are more likely to become company favorites and get access to more shifts. ',
    tag: 'be-respectful',
    other: false,
  },
  {
    title: 'Arrive on Time',
    description:
      'Lateness causes delays and unfairly shifts workload onto your teammates. Always try to arrive on time for 5-stars and check your on-time rate in the Me tab.',
    tag: 'arrive-on-time',
    other: false,
  },
  {
    title: 'Stay at your station',
    description:
      'Stay at your assigned station during the shift. If you need to step away, find a supervisor and let them know. Focus on the assigned task to earn 5 stars.',
    tag: 'stay-at-station',
    other: false,
  },
  {
    title: 'Other',
    description:
      'Enter your feedback in the text box. What you write will be sent to the worker.',
    tag: 'other',
    other: true,
  },
]
