import React from 'react'
import { Box, DisplayIcon, Heading, Loading } from 'ui'
import { GemIcon } from 'lucide-react'
import styled from 'styled-components'
import { InviteWorkerModal } from './InviteWorkerModal'
import { usePendingInvites } from 'queries/worker'
import { WorkerInviteCard } from './WorkerInviteCard'

const InviteContainer = styled(Box).attrs({
  borderRadius: 'xlarge',
})`
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, #fa15ff, #37cfff, #7c4fff) border-box;
  border: 1px solid transparent;
`

export function InviteWorkers() {
  const { data: pendingInvites, isLoading } = usePendingInvites()

  return (
    <InviteContainer>
      <Box borderRadius={'xlarge'} p={3} backgroundColor={'neutrals.50'}>
        <Box display={'inline-flex'} alignItems={'center'}>
          <DisplayIcon>
            <GemIcon size={18} />
          </DisplayIcon>
          <Heading level={4} my={0} ml={2}>
            Invite Workers
          </Heading>
        </Box>
        <hr />
        <Box my={3}>
          Know a great worker you’d like to schedule on WorkWhile?{' '}
          <strong>Invite today</strong>.
        </Box>
        <InviteWorkerModal />
        {isLoading ? <Loading /> : null}
        {pendingInvites?.map((invite) => (
          <WorkerInviteCard key={invite.id} data={invite} />
        ))}
      </Box>
    </InviteContainer>
  )
}
