import { TimelineShift } from 'api/shift'
import {
  TryoutEvaluationData,
  TRYOUT_EVALUATION_OPTIONS,
} from '../components/tryouts/TryoutEvaluationToggleGroup'
import { User, WorkShift } from '../typings/common_defs'
import { track } from './amplitude'
import { getPositionRosterUserStatus, ROSTER_USER_STATUS } from './roster'

/**
 * Given a shift and worker, determine the worker's current evaluation in a Tryout
 * i.e. we are inferring the initial state of this toggle:
 * https://www.figma.com/file/1XK3AmgapzGuikFXovmOnP/Tryouts-%26-Long-Term-Assignments?node-id=1538%3A401&t=V5li7h3rFfxk6Ujm-4
 * @param {WorkShift | TimelineShift} shift
 * @param {User} worker
 * @returns {typeof TRYOUT_EVALUATION_OPTIONS[keyof typeof TRYOUT_EVALUATION_OPTIONS]} - one of the TRYOUT_EVALUATION_OPTIONS
 */
export const determineInitialTryoutEvalutationValue = (
  shift: WorkShift | TimelineShift,
  worker: User
): (typeof TRYOUT_EVALUATION_OPTIONS)[keyof typeof TRYOUT_EVALUATION_OPTIONS] => {
  const rosterUserStatus = getPositionRosterUserStatus(shift, worker)
  if (rosterUserStatus === ROSTER_USER_STATUS.active) {
    return TRYOUT_EVALUATION_OPTIONS.keep
  }
  if (
    rosterUserStatus === ROSTER_USER_STATUS.removal_pending ||
    rosterUserStatus === ROSTER_USER_STATUS.removed
  ) {
    return TRYOUT_EVALUATION_OPTIONS.remove
  }
  return TRYOUT_EVALUATION_OPTIONS.undecided
}

const TRYOUT_NUX_LOCAL_STORAGE_KEY = 'tryout_nux_seen'

/**
 * Checks local storage and returns true if user has seen Tryouts NUX
 */
export const checkIfUserHasSeenTryoutNUX = () => {
  return !!localStorage.getItem(TRYOUT_NUX_LOCAL_STORAGE_KEY)
}

/**
 * Saves to local storage that user has seen Tryouts NUX
 */
export const saveUserHasSeenTryoutNUX = () => {
  track('impression, save_user_has_seen_tryout_nux')
  localStorage.setItem(TRYOUT_NUX_LOCAL_STORAGE_KEY, 'true')
}

/**
 * Removes from local storage that user has seen Tryouts NUX
 */
export const removeUserHasSeenTryoutNUX = () => {
  localStorage.removeItem(TRYOUT_NUX_LOCAL_STORAGE_KEY)
}

/**
 * Given a tryout evaluation status, convert it to a roster user status
 * @param {typeof TRYOUT_EVALUATION_OPTIONS[keyof typeof TRYOUT_EVALUATION_OPTIONS]} tryoutEvaluationStatus
 * @returns {string} roster user status
 */
export const convertTryoutEvaluationStatusToRosterUserStatus = (
  tryoutEvaluationStatus: (typeof TRYOUT_EVALUATION_OPTIONS)[keyof typeof TRYOUT_EVALUATION_OPTIONS]
): string => {
  if (tryoutEvaluationStatus === TRYOUT_EVALUATION_OPTIONS.keep) {
    return ROSTER_USER_STATUS.active
  }
  if (tryoutEvaluationStatus === TRYOUT_EVALUATION_OPTIONS.remove) {
    return ROSTER_USER_STATUS.removal_pending
  }
  return ROSTER_USER_STATUS.tryout_pending
}

/**
 * Given a shift, get the number of work that needs a tryout evaluation
 */
export const getNumWorkThatNeedsTryoutEvaluation = (shift: WorkShift) => {
  const { position } = shift
  if (!position || !position.roster || !position.roster.rosterUsers) {
    return 0
  }
  let numWorkThatNeedsTryoutEvaluation = 0
  const rosterUsers = position.roster.rosterUsers
  for (const user of rosterUsers) {
    if (user.status === ROSTER_USER_STATUS.tryout_pending) {
      numWorkThatNeedsTryoutEvaluation++
    }
  }
  return numWorkThatNeedsTryoutEvaluation
}

/**
 * Given a shift, returns True if a decision is needed on tryouts
 * - If there are any tryout_pending users, which the user has not evaluated
 * - and it is the last day of the tryout
 * @param shift
 * @returns {boolean} True if a decision is needed on tryouts
 */
export const needsDecisionOnTryouts = (
  shift: WorkShift,
  userInput: TryoutEvaluationData[]
) => {
  const { bundleMetadata, position } = shift
  const isLastDayOfTryout =
    bundleMetadata && bundleMetadata?.bundleIndex === bundleMetadata?.bundleSize

  const workerIds = shift.work?.map((w) => Number(w.worker?.id)) || []

  const tryoutPendingUsers = position?.roster?.rosterUsers?.filter(
    (user) =>
      user.status === ROSTER_USER_STATUS.tryout_pending &&
      workerIds.includes(Number(user.userId))
  )

  if (!tryoutPendingUsers || tryoutPendingUsers.length === 0) {
    return false
  }

  let hasTryoutPendingUsers = false

  for (const tpu of tryoutPendingUsers) {
    const userHasBeenEvaluated = userInput.some(
      (ui) =>
        Number(ui.worker_id) === tpu.userId &&
        ui.status !== ROSTER_USER_STATUS.tryout_pending
    )
    if (!userHasBeenEvaluated) {
      hasTryoutPendingUsers = true
      break
    }
  }

  return isLastDayOfTryout && hasTryoutPendingUsers
}
