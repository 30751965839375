import React from 'react'
import { Box, Heading, Loading, Text } from 'ui'
import { useAnalyticsContext } from '../useAnalyticsContext'
import { RatingStats } from './RatingStats'
import { RatingChart } from './RatingChart'

export function Performance() {
  const { insight } = useAnalyticsContext()

  return (
    <Box borderRadius={'xlarge'} boxShadow={'medium'} p={4} mt={4}>
      <Heading level={3} my={0}>
        Performance
      </Heading>
      <Text color={'lightText'} my={2}>
        Based on worker reviews after each completed shift
      </Text>
      {insight ? (
        <>
          <RatingStats />
          <RatingChart />
        </>
      ) : (
        <Loading />
      )}
    </Box>
  )
}
