import { NavigateOptions, To, useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'

export function useNavigateWithContext() {
  const navigateFunction = useNavigate()
  const location = useLocation()

  const navigate = (to: To, options?: NavigateOptions, clearState = false) => {
    if (location.state?.backgroundStack && !clearState) {
      return navigateFunction(to, { ...options, state: location.state })
    }
    return navigateFunction(to, { ...options })
  }

  return { navigate, location }
}
