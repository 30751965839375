import useUrlState from '@ahooksjs/use-url-state'
import { format, isValid, parse, startOfWeek } from 'date-fns'
import { useMemo } from 'react'

export const useWeekStartState = () => {
  const [{ weekStart: weekStartQueryParam }, setWeekStartQueryParam] =
    useUrlState({
      weekStart: format(
        startOfWeek(new Date(), { weekStartsOn: 0 }),
        'yyyy-MM-dd'
      ),
    })

  const safeWeekStart = useMemo(() => {
    const parsedValue = parse(weekStartQueryParam, 'yyyy-MM-dd', new Date())
    const isValidDate = isValid(parsedValue)
    return isValidDate
      ? startOfWeek(parsedValue, { weekStartsOn: 0 })
      : startOfWeek(new Date(), { weekStartsOn: 0 })
  }, [weekStartQueryParam])

  const setWeekStartByDate = (date: Date) => {
    setWeekStartQueryParam({
      weekStart: format(date, 'yyyy-MM-dd'),
    })
  }

  return [safeWeekStart, setWeekStartByDate] as const
}
