import { useCompanyInfo } from '../company'
import { useMutation } from '@tanstack/react-query'
import { addShiftBonus } from 'api/shift'
import { AxiosError } from 'axios'

export function useAddShiftBonusMutation(options: {
  shiftId: number
  onSuccess?: () => void
  onError?: (err: AxiosError) => void
}) {
  const { shiftId, ...otherOptions } = options
  const { data: company } = useCompanyInfo()

  return useMutation({
    mutationFn: async (bonus: number) => {
      if (!company?.id) {
        return
      }

      return addShiftBonus(company.id, shiftId, bonus)
    },
    ...otherOptions,
  })
}
