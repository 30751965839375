import { User } from 'typings/common_defs'

export enum BlockModalType {
  WorkerInformation = 'WorkerInformation',
  FillRate = 'FillRate',
  NewWorker = 'NewWorker',
  Feedback = 'Feedback',
  Block = 'Block',
}

export interface BlockModalProps {
  setModalType: (modalType: BlockModalType | null) => void
  worker: User
}
