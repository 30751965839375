import { graphql, apiDateFormat } from 'api'
import { format } from 'date-fns'
import { path } from 'ramda'
import {
  AvgShiftStats,
  WeeklySpend,
  WorkerBench,
  Performance,
  WeeklyFillRate,
  WeeklyRatings,
} from 'typings/common_defs'

export interface InsightOptions {
  startDate: Date
  endDate: Date
  locationId?: number
  positionId?: number
}

export interface CompanyInsight {
  avgShiftStats: AvgShiftStats
  workerBench: WorkerBench
  weeklySpends: WeeklySpend
  performance: Performance
  weeklyFillRates: WeeklyFillRate[]
  weeklyRatings: WeeklyRatings[]
}

export async function getInsight(options: InsightOptions) {
  const { startDate, endDate, locationId, positionId } = options
  const gqlParams = [
    `startDate: "${format(startDate, apiDateFormat)}"`,
    `endDate: "${format(endDate, apiDateFormat)}"`,
    positionId ? `positionId: ${positionId}` : '',
    locationId ? `locationId: ${locationId}` : '',
  ]
    .filter((p) => p)
    .join(', ')

  const data = await graphql(
    `company {
          insight( ${gqlParams}){
            avgShiftStats{
              avgPay
              avgBonus
              recommendedPay
              recommendedBonus
              recommendedLeadDays
              shortLeadDaysRate
            }
            workerBench{
              topWorkers{
                worker{
                  id
                  name
                  rating
                  profilePicUrl
                }
                totalShifts
              }
              totalFavorite
              totalBlocked
            }
            weeklySpends{
              weekStartDate
              totalSpend
              totalPay
              totalBonus
            }
            performance{
              avgRating
              totalOneStar
              totalTwoStar
              totalThreeStar
              totalFourStar
              totalFiveStar
            }
            weeklyFillRates{
              weekStartDate
              workersFilled
              workersRequested
            }
            weeklyRatings{
              weekStartDate
              avgRating
            }
          }
        }`,
    'company_insight'
  )

  return path(['data', 'data', 'company', 'insight'], data) as CompanyInsight
}
