import { useMutation } from '@tanstack/react-query'
import {
  createCompanyPosition,
  PositionRequest,
  updateCompanyPosition,
} from 'api/company'
import { useCompanyInfo } from './useCompanyInfo'
import { useInvalidateCompanyPositions } from 'queries/company/useCompanyPositions'

interface UseSavePositionMutation {
  positionId?: number
  onError?: (error: Error) => void
  onSuccess?: (positionId: number) => void
}

export function useSavePositionMutation(options?: UseSavePositionMutation) {
  const { positionId, ...otherOptions } = options || {}
  const { data: company } = useCompanyInfo()
  const { invalidate: invalidatePositions } = useInvalidateCompanyPositions()

  return useMutation({
    mutationFn: async (data: PositionRequest) => {
      if (!company?.id) {
        throw new Error('Company id not found')
      }
      if (!positionId) {
        const positionId = await createCompanyPosition(company.id, data)
        await invalidatePositions()
        return positionId
      }

      await updateCompanyPosition(company.id, positionId, data)
      await invalidatePositions()
    },
    ...otherOptions,
  })
}
