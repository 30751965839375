import { HypotheticalShift } from 'api/worker'
import { getHypotheticalShiftsFromShiftEditorData } from 'lib/shift'
import { useShiftEditor } from 'pages/ShiftEditorPage/useShiftEditor'
import { useMemo } from 'react'

export const useHypotheticalShifts = (): Array<HypotheticalShift | null> => {
  const { shiftData } = useShiftEditor()
  const hypotheticalShifts: Array<HypotheticalShift | null> = useMemo(
    () => getHypotheticalShiftsFromShiftEditorData(shiftData),
    [
      shiftData.schedule,
      shiftData.details,
      shiftData.schedule?.selectedDays,
      shiftData.schedule?.startTime.value,
      shiftData.schedule?.endTime.value,
      shiftData.schedule?.timezone,
      shiftData.details?.positionId,
      shiftData.details?.locationId,
    ]
  )
  return hypotheticalShifts
}
