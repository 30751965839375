import React from 'react'
import { track } from 'lib/amplitude'
import { Box, Field, Heading, Input, Button, Message, Link } from 'ui'
import { WWLogo } from 'components/WWLogo'
import * as zod from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useSignInMutation } from 'queries/user'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

const schema = zod.object({
  email: zod
    .string({
      required_error: 'Email is required',
    })
    .email('Must be valid email address'),
  password: zod
    .string({
      required_error: 'Password is required',
    })
    .min(8, 'At least 8 characters required'),
})

type FormData = zod.infer<typeof schema>

export function LoginForm() {
  const [searchParams] = useSearchParams()
  const passwordReset = searchParams.get('pwd_reset') === '1'

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const navigate = useNavigate()

  const {
    mutate: signIn,
    isPending: isSigningIn,
    error,
  } = useSignInMutation({
    async onSuccess(userCredential) {
      track('impression, success: firebase sign in')
      try {
        await userCredential.user.getIdToken()
      } catch {
        track('impression, failed: get user idToken')
      }
      track('impression, success: get user idToken')
      location.href = '/home'
      track('impression, doneWithSignIncallback')
    },
    onError(err) {
      if (err.code === 'auth/multi-factor-auth-required') {
        navigate('/verify-mfa', { state: { multiFactorError: err } })
        return
      }
      track('impression, failed: firebase sign in')
    },
  })

  const onSubmit = handleSubmit((data) => {
    track('button_click, sign in')
    signIn(data)
  })

  return (
    <Box margin={'auto'} pb={4} width={[1, 450]}>
      <form onSubmit={onSubmit}>
        <WWLogo size={'medium'} />
        {passwordReset ? (
          <Message variant={'success'} mt={4}>
            Password reset instructions were sent to your email.
          </Message>
        ) : null}
        <Heading level={2} my={4}>
          Sign in to WorkWhile.
        </Heading>
        <Field label={'Email'} error={errors.email?.message?.toString()}>
          <Input
            disabled={isSigningIn}
            autoFocus={true}
            placeholder={'e.g. jane.doe@example.com'}
            {...register('email')}
          />
        </Field>
        <Field label={'Password'} error={errors.password?.message?.toString()}>
          <Input
            disabled={isSigningIn}
            type={'password'}
            placeholder={'Password'}
            {...register('password')}
          />
        </Field>
        <Box mt={4}>
          <Button
            block={true}
            size={'large'}
            type={'submit'}
            loading={isSigningIn}
          >
            Sign in
          </Button>
        </Box>
        {error ? (
          <Message width={1} mt={2} mb={4} variant={'error'}>
            {error.message}
          </Message>
        ) : null}
        <Box mt={4}>
          <Link to={'/reset-password'}>Forgot your password?</Link>
        </Box>
        <Box mt={4}>
          <Link to={'/login?okta=true'}>Sign in using Okta</Link>
        </Box>
      </form>
    </Box>
  )
}
