import { PaginatedChatMessages } from 'api/message'
import { useMemo, useState } from 'react'
import { addHours, format, isAfter } from 'date-fns'
import { InfiniteData } from '@tanstack/react-query'

export function useShiftFilter(data?: InfiniteData<PaginatedChatMessages>) {
  const [selectedShift, setSelectedShift] = useState<number | null>(null)

  const flattenData = useMemo(() => {
    if (!data) {
      return []
    }
    return data.pages.map((page) => page.items).flat()
  }, [data])

  const filterOptions = useMemo(() => {
    if (!data) {
      return []
    }

    const timeBound = addHours(new Date(), 2)
    return flattenData
      .filter((message) => {
        if (!message.shift.endsAt) {
          return false
        }
        const endsAt = new Date(message.shift.endsAt)
        return isAfter(endsAt, timeBound)
      })
      .map((message) => {
        const startsAt = new Date(message.shift.startsAt as string)
        return {
          value: message.shift.id,
          label: `${message.shift.position.name}, ${
            message.shift.location.name
          } - ${format(startsAt, 'MMM dd, h:mm a')}`,
        }
      })
  }, [flattenData])

  const selectedFilterOption = useMemo(() => {
    return filterOptions.find(
      (option) => Number(option.value) === Number(selectedShift)
    )
  }, [selectedShift, filterOptions])

  const filteredData = useMemo(() => {
    if (!selectedShift) {
      return flattenData
    }

    return flattenData.filter(
      (message) => Number(message.shift.id) === Number(selectedShift)
    )
  }, [selectedShift, flattenData])

  return {
    selectedFilterOption,
    filterOptions,
    setSelectedShift,
    filteredData,
  }
}
