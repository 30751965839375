import React from 'react'
import styled from 'styled-components'
import { Box, Flex, Text } from 'ui'
import { repeat } from 'ramda'
import StarSvg from './star.svg'

export const RatingInfo = styled(Flex).attrs<{
  rating: number
  count?: number
}>((props) => ({
  flexDirection: 'row',
  alignItems: 'center',
  px: 3,
  py: 2,
  children: (
    <>
      <Box flex={1} display={'inline-flex'} alignItems={'center'}>
        <Box width={40}>{props.rating.toFixed(2)}</Box>
        {repeat('★', props.rating).map((star, index) => (
          <Box mr={1} key={index}>
            <img src={StarSvg} alt={'star'} />
          </Box>
        ))}
      </Box>
      <Text fontWeight={2}>{props.count}</Text>
    </>
  ),
}))`
  &:nth-child(even) {
    background-color: ${(props) => props.theme.colors.neutrals[50]};
  }
`
