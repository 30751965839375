export const analyticsRootKey = ['analytics']
export const insightKey = (
  startDate: Date,
  endDate: Date,
  locationId?: number,
  positionId?: number
) => [
  ...analyticsRootKey,
  'insight',
  startDate.toISOString(),
  endDate.toISOString(),
  locationId,
  positionId,
]
