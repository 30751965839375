import React from 'react'
import { CompanyWorker } from 'api/worker'
import { Avatar, Flex, Box, Text, Button } from '@workwhile/ui'
import { useShiftEditor } from '../../useShiftEditor'
import { toTitleCase } from 'lib/legacy_util'
import { SelectedWorkerStatus } from 'components/WorkersSelector/SelectedWorkerStatus'
import { useHypotheticalShifts } from 'hooks/useHypotheticalShifts'
import { isRSWWorkerAvailabilityEnabled } from 'lib/flags'
import { useAuthContext } from 'components/auth'
import { formatInTimeZone } from 'date-fns-tz'
import { getLocalTimezone } from 'lib/time'
import { EditorMode } from 'pages/ShiftEditorPage/ShiftEditorProvider'

interface Props {
  data: CompanyWorker
  onRemove?: () => void
}

export function RSWCard(props: Props) {
  const { data } = props
  const { company } = useAuthContext()
  const {
    shiftData,
    shiftStatusData: { workerStatus },
    editData,
    editorMode,
  } = useShiftEditor()
  const selectedDate = shiftData.schedule?.selectedDays[0]
  const status = workerStatus[data.id]
  const workerName = toTitleCase(data.name)
  const firstName = workerName.split(' ')[0]
  const hypotheticalShifts = useHypotheticalShifts()

  return (
    <Flex
      alignItems={['flex-start', 'center']}
      width={1}
      my={3}
      flexWrap={'wrap'}
    >
      <Avatar
        size={'medium'}
        user={{
          userId: data.id.toString(),
          name: data.name,
          photoUrl: data.profilePicUrl,
        }}
      />
      <Flex
        flex={1}
        flexDirection={['column', 'row']}
        alignItems={['flex-start', 'center']}
      >
        <Box ml={3} flex={1}>
          <Text>{workerName}</Text>
          {isRSWWorkerAvailabilityEnabled(company) ? (
            <>
              {editorMode !== EditorMode.PastShift ? (
                <Box display="inline-flex">
                  <SelectedWorkerStatus
                    worker={data}
                    hypotheticalShifts={hypotheticalShifts}
                    existingShift={editData}
                  />
                </Box>
              ) : null}
            </>
          ) : (
            <Text fontSize={1}>
              {status === 'pending' || !status
                ? "We'll notify you as soon as we receive a response."
                : null}
              {status === 'available' ? (
                <Text color={'success'}>{firstName} is available</Text>
              ) : null}
              {status === 'unavailable' ? (
                <Text color={'error'}>
                  {firstName} is unavailable on&nbsp;
                  {selectedDate
                    ? formatInTimeZone(
                        selectedDate,
                        shiftData.schedule?.timezone || getLocalTimezone(),
                        'MMMM d'
                      )
                    : ''}
                </Text>
              ) : null}
            </Text>
          )}
        </Box>
        <Button kind={'medium'} onClick={props.onRemove} mt={[2, 0]}>
          Remove
        </Button>
      </Flex>
    </Flex>
  )
}
