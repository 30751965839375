import { Location } from 'typings/common_defs'
import {
  Selectable,
  SelectableDuration,
  SelectableTime,
} from 'typings/selectable_ext'
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz'
import { addDays, differenceInMinutes, format, formatDuration } from 'date-fns'

export const apiDateFormat = 'YYYY-MM-DD'
export const dayDateTimeFormat = 'ddd MMM Do, YYYY h:mma'
export const dayMonthDateYearTimeZoneFormat = 'ddd MMM Do, YYYY h:mma z'

/**
 * @param {Location | Selectable<Location> | null} location
 * @returns {string} the timezone associated with the location or best guess e.g. America/Los_Angeles
 */
export const getLocationTimezone = (
  location?: Location | Selectable<Location> | null
): string => {
  return location?.address?.timezone || getLocalTimezone()
}

/**
 * @returns {string} the user's local timezone e.g. America/Los_Angeles
 */
export const getLocalTimezone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

/**
 * @param {string} timezone e.g. "America/Los_Angeles"
 * @returns 2, 3 or 4 letter abbreviated timezone e.g. PDT, EST or CDT etc.
 */
export const getTimezoneAbbr = (timezone: string, refDate?: Date) => {
  return formatInTimeZone(refDate || new Date(), timezone, 'zzz')
}

export const getTimezoneName = (timezone: string, refDate?: Date) => {
  return formatInTimeZone(refDate || new Date(), timezone, 'zzzz')
}

export const getLongDuration = (minutes: number) => {
  const hours = Math.floor(minutes / 60)
  const mins = minutes % 60
  // Manually constructing the duration string
  const formattedDuration = [
    hours ? `${hours} hrs` : '', // Include hours part only if it's non-zero
    mins ? `${mins} mins` : '', // Include minutes part only if it's non-zero
  ]
    .filter(Boolean)
    .join(' ') // Join non-empty parts with space, removing any potential empty strings

  return formattedDuration
}

/**
 * Take a time string 24:00 and convert it to minutes
 * @param time
 * @returns {number} minutes
 */
export function toMinutes(time: string) {
  const parts = time.split(':')
  const hours = Number(parts[0])
  const minutes = Number(parts[1])
  return hours * 60 + minutes
}

export function toTimeString(minutes: number) {
  const hours = Math.floor(minutes / 60)
  const mins = minutes % 60
  return `${hours.toString().padStart(2, '0')}:${mins
    .toString()
    .padStart(2, '0')}`
}

/**
 * Given a date, a time string and time zone, return a UTC date
 * Example scenario is when user selects the date and time separately for a shift
 * @param date
 * @param timeStr
 * @param timezone
 */
export function getUTCDateTime(date: Date, timeStr: string, timezone: string) {
  const dateTimeString = `${format(date, 'yyyy-MM-dd')}T${timeStr}:00`
  const dateTime = new Date(dateTimeString)
  return zonedTimeToUtc(dateTime, timezone)
}

/**
 * Given a date, start and end time strings and a timezone
 * return the duration in minutes
 * @param date
 * @param startTime
 * @param endTime
 * @param timezone
 */
export function getDurationFromTimeStrings(
  date: Date,
  startTime: string,
  endTime: string,
  timezone: string
) {
  const startMinutes = toMinutes(startTime)
  const endMinutes = toMinutes(endTime)
  const startsAt = getUTCDateTime(date, startTime, timezone)
  const endsAt = getUTCDateTime(
    endMinutes < startMinutes ? addDays(date, 1) : date,
    endTime,
    timezone
  )
  return differenceInMinutes(endsAt, startsAt)
}

/**
 * Options user can choose from when entering a shift's unpaid break
 */
export const UNPAID_BREAK_OPTIONS: Array<SelectableDuration> = [
  { value: 0, label: '0 mins' },
  { value: 10, label: '10 mins' },
  { value: 15, label: '15 mins' },
  { value: 30, label: '30 mins' },
  { value: 45, label: '45 mins' },
  { value: 60, label: '60 mins' },
]

export const getW2BreakOptions = (
  minimumLunchLength: number | undefined
): Array<SelectableDuration> => {
  if (minimumLunchLength === undefined || minimumLunchLength === 0) {
    return [
      { value: 0, label: '0 mins' },
      { value: 30, label: '30 mins' },
      { value: 60, label: '60 mins' },
    ]
  }

  if (minimumLunchLength === 30) {
    return [
      { value: 30, label: '30 mins (minimum required)' },
      { value: 60, label: '60 mins (optional)' },
    ]
  }

  return [{ value: 60, label: '60 mins (minimum required)' }]
}

/**
 * Options user can choose from when entering a shift lead early start and late end duration in minutes
 */
export const SHIFT_LEAD_EXTRA_TIME_DURATION: Array<{
  value: number
  label: string
}> = [
  { value: 0, label: '0 mins' },
  { value: 15, label: '15 mins' },
  { value: 30, label: '30 mins' },
  { value: 45, label: '45 mins' },
  { value: 60, label: '60 mins' },
  { value: 75, label: '75 mins' },
  { value: 90, label: '90 mins' },
]

export const DEFAULT_SHIFT_LEAD_EXTRA_TIME_DURATION =
  SHIFT_LEAD_EXTRA_TIME_DURATION[2]

/**
 * Shift start and end times can only occur on 15 minute intervals
 */
export const QUARTER_HOUR_INTERVAL_OPTIONS: Array<SelectableTime> = [
  { value: '00:00', label: '12:00am' },
  { value: '00:15', label: '12:15am' },
  { value: '00:30', label: '12:30am' },
  { value: '00:45', label: '12:45am' },
  { value: '01:00', label: '1:00am' },
  { value: '01:15', label: '1:15am' },
  { value: '01:30', label: '1:30am' },
  { value: '01:45', label: '1:45am' },
  { value: '02:00', label: '2:00am' },
  { value: '02:15', label: '2:15am' },
  { value: '02:30', label: '2:30am' },
  { value: '02:45', label: '2:45am' },
  { value: '03:00', label: '3:00am' },
  { value: '03:15', label: '3:15am' },
  { value: '03:30', label: '3:30am' },
  { value: '03:45', label: '3:45am' },
  { value: '04:00', label: '4:00am' },
  { value: '04:15', label: '4:15am' },
  { value: '04:30', label: '4:30am' },
  { value: '04:45', label: '4:45am' },
  { value: '05:00', label: '5:00am' },
  { value: '05:15', label: '5:15am' },
  { value: '05:30', label: '5:30am' },
  { value: '05:45', label: '5:45am' },
  { value: '06:00', label: '6:00am' },
  { value: '06:15', label: '6:15am' },
  { value: '06:30', label: '6:30am' },
  { value: '06:45', label: '6:45am' },
  { value: '07:00', label: '7:00am' },
  { value: '07:15', label: '7:15am' },
  { value: '07:30', label: '7:30am' },
  { value: '07:45', label: '7:45am' },
  { value: '08:00', label: '8:00am' },
  { value: '08:15', label: '8:15am' },
  { value: '08:30', label: '8:30am' },
  { value: '08:45', label: '8:45am' },
  { value: '09:00', label: '9:00am' },
  { value: '09:15', label: '9:15am' },
  { value: '09:30', label: '9:30am' },
  { value: '09:45', label: '9:45am' },
  { value: '10:00', label: '10:00am' },
  { value: '10:15', label: '10:15am' },
  { value: '10:30', label: '10:30am' },
  { value: '10:45', label: '10:45am' },
  { value: '11:00', label: '11:00am' },
  { value: '11:15', label: '11:15am' },
  { value: '11:30', label: '11:30am' },
  { value: '11:45', label: '11:45am' },
  { value: '12:00', label: '12:00pm' },
  { value: '12:15', label: '12:15pm' },
  { value: '12:30', label: '12:30pm' },
  { value: '12:45', label: '12:45pm' },
  { value: '13:00', label: '1:00pm' },
  { value: '13:15', label: '1:15pm' },
  { value: '13:30', label: '1:30pm' },
  { value: '13:45', label: '1:45pm' },
  { value: '14:00', label: '2:00pm' },
  { value: '14:15', label: '2:15pm' },
  { value: '14:30', label: '2:30pm' },
  { value: '14:45', label: '2:45pm' },
  { value: '15:00', label: '3:00pm' },
  { value: '15:15', label: '3:15pm' },
  { value: '15:30', label: '3:30pm' },
  { value: '15:45', label: '3:45pm' },
  { value: '16:00', label: '4:00pm' },
  { value: '16:15', label: '4:15pm' },
  { value: '16:30', label: '4:30pm' },
  { value: '16:45', label: '4:45pm' },
  { value: '17:00', label: '5:00pm' },
  { value: '17:15', label: '5:15pm' },
  { value: '17:30', label: '5:30pm' },
  { value: '17:45', label: '5:45pm' },
  { value: '18:00', label: '6:00pm' },
  { value: '18:15', label: '6:15pm' },
  { value: '18:30', label: '6:30pm' },
  { value: '18:45', label: '6:45pm' },
  { value: '19:00', label: '7:00pm' },
  { value: '19:15', label: '7:15pm' },
  { value: '19:30', label: '7:30pm' },
  { value: '19:45', label: '7:45pm' },
  { value: '20:00', label: '8:00pm' },
  { value: '20:15', label: '8:15pm' },
  { value: '20:30', label: '8:30pm' },
  { value: '20:45', label: '8:45pm' },
  { value: '21:00', label: '9:00pm' },
  { value: '21:15', label: '9:15pm' },
  { value: '21:30', label: '9:30pm' },
  { value: '21:45', label: '9:45pm' },
  { value: '22:00', label: '10:00pm' },
  { value: '22:15', label: '10:15pm' },
  { value: '22:30', label: '10:30pm' },
  { value: '22:45', label: '10:45pm' },
  { value: '23:00', label: '11:00pm' },
  { value: '23:15', label: '11:15pm' },
  { value: '23:30', label: '11:30pm' },
  { value: '23:45', label: '11:45pm' },
]
