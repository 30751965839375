import { Company } from '../typings/common_defs'
import {
  DYNAMIC_CONFIGS,
  getStatsigDynamicConfig,
  getStatsigGate,
} from './statsig/statsig'
import { FeatureGates } from './statsig/feature_gates'
import { CompanyInfo } from 'api/company'

export const isEmailNotificationPreferencesEnabled = true

export const isRosterEnabled = true

export const isFirstDayTagEnabled = true

export const isPastWorkInfoEnabled = true

export const isShiftDetailPaymentSummaryEnabled = true

export const isCompanyWorkerInviteEnabled = true

export const isAutoApproveShiftsEmployerModalEnabled = false

export const isJobReqsRefreshEnabled = true

export const customCancellationPolicyEnabled = true

export const isTryoutsEnabled = true
export const isRefactoredTryoutEvaluationListEnabled = true
export const shouldDisplayCreateTryoutOption = (company: Company) =>
  checkConfigWhiteListForCompany(
    company,
    DYNAMIC_CONFIGS.tryouts_company_id_whitelist
  )

export const checkConfigWhiteListForCompany = (
  company: CompanyInfo,
  config: string
) => {
  // This function is used to check if a company is whitelisted for a particular config
  // The config needs to be set up in this format:
  // {companyIds: ['3', '4', '5'], companyGroupIds: ['6'], useWhitelist: true}

  const dynamicConfig = getStatsigDynamicConfig(config)
  const useWhitelist = dynamicConfig?.value?.useWhitelist
  if (!useWhitelist) return true

  const whitelist = (dynamicConfig?.value?.companyIds ?? []) as Array<
    string | undefined
  >

  const groupWhitelist = (dynamicConfig?.value?.companyGroupIds ?? []) as Array<
    string | undefined
  >

  return (
    whitelist.includes(company.id?.toString()) ||
    groupWhitelist.includes(company.companyGroup?.id?.toString())
  )
}

export const isAnonymousPhoneNumbersEnabled = () =>
  getStatsigGate(FeatureGates.AnonymousProxyTooltip)

export const isConfirmLargeHourlyRateEnabled = () =>
  getStatsigGate(FeatureGates.ConfirmLargeHourlyRate)

export const isRSWV2Enabled = () => getStatsigGate(FeatureGates.RSWV2)

export const isClockInOverrideEnabled = () =>
  getStatsigGate(FeatureGates.EnableClockInOverride)

// enables tutorials on dev and staging for all companies
export const isTutorialsPageEnabledOnDevAndStaging = () =>
  getStatsigGate(FeatureGates.BizPortalTutorialPage)

export const isTutorialsPageEnabled = (company?: CompanyInfo | null) => {
  if (!company) {
    return false
  }
  if (isTutorialsPageEnabledOnDevAndStaging()) {
    return true
  }
  if (
    checkConfigWhiteListForCompany(
      company,
      DYNAMIC_CONFIGS.biz_portal_tutorial_whitelist
    )
  ) {
    return true
  }
  return false
}

export const isLeadShiftCreationEnabled = (company: CompanyInfo) => {
  return (
    checkConfigWhiteListForCompany(
      company,
      DYNAMIC_CONFIGS.shift_lead_creation_hospitality
    ) || getStatsigGate(FeatureGates.LeadShiftCreation)
  )
}

export const isRSWWorkerAvailabilityEnabled = (
  company?: CompanyInfo | null
) => {
  if (!company) {
    return false
  }
  return getStatsigGate(FeatureGates.RSWAvailability)
}

export const isShiftInstructionImprovementsEnabled = () =>
  getStatsigGate(FeatureGates.ShiftInstructionImprovements)

export const isProp22MinimumPayEnabled = () =>
  getStatsigGate(FeatureGates.Prop22MinimumPay)
