import React from 'react'
import { Modal, Pluralize, Text } from 'ui'
import { useShiftDetail } from '../useShiftDetail'
import { getAssignedWork } from 'lib/work'

interface Props {
  trigger: React.ReactNode
}

export const CushionedWorkersModal = ({ trigger }: Props) => {
  const { shift } = useShiftDetail()

  if (!shift || !shift.workersNeeded) return null

  const workList = shift.work || []
  const assignedWork = getAssignedWork(workList)

  return (
    <Modal
      title={
        <Text>
          We've scheduled{' '}
          <Pluralize
            count={assignedWork.length - shift.workersNeeded}
            oneText={'extra worker'}
            manyText={'extra workers'}
          />{' '}
          for you!
        </Text>
      }
      trigger={trigger}
      width={[1, 900]}
    >
      <Text mb={2}>
        Don't worry, you'll only be charged for the {shift.workersNeeded} you
        asked for. If you don't need the extra help, feel free to send them
        home. They will still be paid by WorkWhile.
      </Text>
    </Modal>
  )
}
