import React from 'react'
import { Box, DisplayIcon, Heading, Text } from 'ui'
import { BarChart2Icon } from 'lucide-react'

export function ListStats() {
  // nothing to render for now
  return null

  return (
    <Box p={3} backgroundColor={'neutrals.50'} borderRadius={'large'}>
      <Box display={'inline-flex'} alignItems={'center'}>
        <DisplayIcon>
          <BarChart2Icon size={18} />
        </DisplayIcon>
        <Heading level={4} my={0} ml={2}>
          List Stats
        </Heading>
      </Box>
      <Text mt={3} fontWeight={2}>
        Performance
      </Text>
    </Box>
  )
}
