import React, { useState } from 'react'
import {
  Box,
  Button,
  Field,
  Flex,
  Heading,
  Input,
  InputMask,
  Message,
  Modal,
  toast,
} from 'ui'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { PHONE_NUM, unmaskPhoneNumber } from 'lib/phone'
import { InviteWorkerForm, schema } from './schema'
import { InfoCarousel } from './InfoCarousel'
import { useInviteWorkerMutation } from 'queries/worker'

export function InviteWorkerModal() {
  const [open, setOpen] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<InviteWorkerForm>({
    resolver: zodResolver(schema),
  })
  const [error, setError] = useState('')
  const { mutate: inviteWorker, isPending: isInviting } =
    useInviteWorkerMutation({
      onSuccess: () => {
        setOpen(false)
        resetForm()
        toast.success('Worker invited successfully')
      },
      onError: (err) => {
        setError(err.message)
      },
    })

  const resetForm = () => {
    reset({
      name: '',
      phoneNumber: '',
      email: '',
    })
    setError('')
  }

  const onSubmit = handleSubmit((data) => {
    inviteWorker({
      name: data.name,
      email: data.email ?? null,
      phoneNumber: data.phoneNumber
        ? unmaskPhoneNumber(data.phoneNumber)
        : null,
    })
  })

  return (
    <>
      <Modal
        loading={isInviting}
        width={[1, 680]}
        open={open}
        onClose={() => {
          setOpen(false)
          resetForm()
        }}
      >
        <Box>
          <Box
            backgroundColor={'neutrals.50'}
            mx={-4}
            mt={-4}
            py={[4, 5]}
            pb={[3, 4]}
            mb={4}
            borderTopLeftRadius={'standard'}
          >
            <Heading level={3} my={0} textAlign={'center'}>
              Invite a Worker to WorkWhile
            </Heading>
            <InfoCarousel />
          </Box>
          <form onSubmit={onSubmit}>
            <Field
              label={"Worker's Name"}
              error={errors.name?.message?.toString()}
            >
              <Input
                {...register('name')}
                placeholder={"Enter worker's name"}
              />
            </Field>
            <Box py={3} display={['block', 'none']}>
              <hr />
            </Box>
            <Flex flexWrap={'wrap'}>
              <Field
                width={[1, 3 / 7]}
                label={"Worker's Phone"}
                error={errors.phoneNumber?.message?.toString()}
              >
                <InputMask
                  mask={PHONE_NUM.mask}
                  {...register('phoneNumber')}
                  placeholder={`eg. ${PHONE_NUM.mask}`}
                />
              </Field>
              <Box
                width={[1, 1 / 7]}
                textAlign={'center'}
                pt={[0, 40]}
                fontSize={1}
              >
                OR
              </Box>
              <Field
                width={[1, 3 / 7]}
                label={"Worker's Email"}
                error={errors.email?.message?.toString()}
              >
                <Input
                  type={'email'}
                  {...register('email')}
                  placeholder={"Enter worker's email"}
                />
              </Field>
            </Flex>
            {error ? (
              <Message my={3} variant={'error'}>
                {error}
              </Message>
            ) : null}
            <Flex justifyContent={'center'}>
              <Button width={[1, 200]} my={3} type={'submit'}>
                Submit
              </Button>
            </Flex>
          </form>
        </Box>
      </Modal>
      <Button my={3} block={true} onClick={() => setOpen(true)}>
        Send Invite
      </Button>
    </>
  )
}
