import React, { useEffect, useState } from 'react'
import AssetFindRegulars from '../../assets/biz-portal-v2-acknowledge-tryout-list-screenshot.png'
import AssetCreateTryoutShift from '../../assets/biz-portal-v2-create-tryout-screenshot.png'
import AssetDisqualifyWorkers from '../../assets/biz-portal-v2-review-tryout-screenshot.png'
import AssetAfterTryout from '../../assets/biz-portal-v2-create-shift-for-regulars-screenshot.png'
import { Modal, Flex, Heading, Paragraph, Button } from 'ui'
import { track } from 'lib/amplitude'
import styled from 'styled-components'
import { saveUserHasSeenTryoutNUX } from 'lib/tryout'
import { PictureFrameEffect } from '../core/PictureFrameEffect'

interface TryoutsNuxContent {
  imgSrc?: string
  title: string
  subtitle: string
}

interface Props {
  open: boolean
  doneCallback: () => void
}

const tryoutPages: Array<TryoutsNuxContent> = [
  {
    title: 'Build Your Bench with Tryouts ',
    subtitle:
      "A tryout is a unique type of shift where you can qualify workers for a spot on your bench. We'll send you workers who haven't worked in the role before, and you can evaluate them to see if they're the right fit.",
    imgSrc: AssetFindRegulars,
  },
  {
    title: 'Step 1: Create a Tryout Shift',
    subtitle:
      'Select the position for your Tryout. Only workers who have not worked this position with you will be able to accept this shift. We will match you with workers who are best suited for a long term assignment.',
    imgSrc: AssetCreateTryoutShift,
  },
  {
    title: 'Step 2: Qualify and Disqualify Workers for your Bench ',
    subtitle:
      'After each shift, you will evaluate workers from your Tryout and decide who to add to your bench — or who to remove from the opportunity.',
    imgSrc: AssetDisqualifyWorkers,
  },
  {
    title: 'Step 3: Create Shifts for Qualified Tryout Workers',
    subtitle:
      'You can then create shifts for that position which only those you’ve qualified will be able to accept.',
    imgSrc: AssetAfterTryout,
  },
]

export const TryoutExplainerModal = ({ open, doneCallback }: Props) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0)

  const currentPage = tryoutPages[currentPageIndex]

  useEffect(() => {
    if (open) {
      track('impression, tryout_explainer_modal')
    }
  }, [open])

  const nextFn = () => {
    if (currentPageIndex === tryoutPages.length - 1) {
      saveUserHasSeenTryoutNUX()
      if (doneCallback) {
        doneCallback()
      }
      return
    }
    setCurrentPageIndex(currentPageIndex + 1)
  }

  return (
    <Modal
      open={open}
      title="What is a Tryout?"
      width={[1, 'auto']}
      customCta={
        <Flex flexDirection="row" alignItems="center" justifyContent="center">
          {currentPageIndex > 0 ? (
            <Button
              variant="secondary"
              kind="large"
              onClick={() => setCurrentPageIndex(currentPageIndex - 1)}
              mr={2}
            >
              Go Back
            </Button>
          ) : null}
          <Button variant="primary" kind="large" onClick={nextFn}>
            {currentPageIndex === tryoutPages.length - 1 ? 'Got It' : 'Next'}
          </Button>
        </Flex>
      }
    >
      <>
        <IllustrationContainer
          borderRadius={3}
          display={['none', 'none', 'flex']}
        >
          <Illustration src={currentPage.imgSrc} />
          <PictureFrameEffect />
        </IllustrationContainer>
        <EducationContent p={3} m={3}>
          <Heading textAlign="center" level={3}>
            {currentPage.title}
          </Heading>
          <Paragraph textAlign="center">{currentPage.subtitle}</Paragraph>
        </EducationContent>
      </>
    </Modal>
  )
}

const Illustration = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin-bottom: -40px;
`

const IllustrationContainer = styled(Flex)`
  position: relative;
  background-color: #80bcff;
  width: 60vw;
  min-width: 400px;
  height: 400px;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
`

const EducationContent = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`
