import React from 'react'
import { Box, DatePicker, Flex } from 'ui'
import { LocationSelector } from 'components/LocationSelector'
import { PositionSelector } from 'components/PositionSelector'
import { SelectableOption } from 'typings/selectable_ext'
import { useAnalyticsContext } from './useAnalyticsContext'

export function FilterBar() {
  const {
    dates,
    locationId,
    positionId,
    setLocationId,
    setPositionId,
    setDates,
  } = useAnalyticsContext()

  return (
    <Flex mt={[0, 2]} flexWrap={'wrap'}>
      <Box width={[1, 1 / 2, 1 / 2, 1 / 3]} pr={[0, 2]} mb={[2, 0]}>
        <LocationSelector
          value={locationId}
          placeholder={'Filter by Location'}
          isClearable={true}
          onChange={(newValue) => {
            if (!newValue) {
              setLocationId(undefined)
              return
            }
            const { value } = newValue as SelectableOption
            if (value) {
              setLocationId(Number(value))
            }
          }}
        />
      </Box>
      <Box width={[1, 1 / 2, 1 / 2, 1 / 3]} pr={[0, 0, 0, 2]}>
        <PositionSelector
          value={positionId}
          placeholder={'Filter by Position'}
          isClearable={true}
          onChange={(newValue) => {
            if (!newValue) {
              setPositionId(undefined)
              return
            }
            const { value } = newValue as SelectableOption
            if (value) {
              setPositionId(Number(value))
            }
          }}
        />
      </Box>
      <Box width={[1, 1, 1, 1 / 3]} mt={[2, 2, 2, 0]}>
        <DatePicker
          mode={'range'}
          selected={dates}
          placeholder={'Pick Date Range'}
          onSelect={(newDates) => {
            if (newDates) {
              setDates(newDates as { from: Date; to?: Date })
            }
          }}
          selectCta={'Apply'}
        />
      </Box>
    </Flex>
  )
}
