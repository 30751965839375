import React from 'react'
import {
  Box,
  Button,
  Heading,
  Message,
  ModalCtaContainer,
  toast,
} from '@workwhile/ui'
import { useSimpleMemoryRouterActions } from '../../../SimpleMemoryRouter'
import { useRequestWorkersFlowContextStateValue } from '../../context/RequestWorkersProvider'
import { useAssignmentWorkflowsContextStateValue } from '../../../context/AssignmentWorkflowsProvider'

export const ReviewAndConfirm = () => {
  const { go, reset } = useSimpleMemoryRouterActions()
  const { requestWorkersFlow } = useAssignmentWorkflowsContextStateValue()
  const {
    actions: { submitFormToServer },
    state: {
      mutationState: { isError },
    },
  } = useRequestWorkersFlowContextStateValue()

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    submitFormToServer(undefined, {
      onSuccess: () => {
        toast.success('Requested workers successfully!')
        requestWorkersFlow.close()
        reset()
      },
    })
  }

  return (
    <Box as="form" onSubmit={onSubmit}>
      <Heading level={3} style={{ marginTop: 0 }} as="h1">
        Review & Confirm
      </Heading>
      <Box py={10}>
        TODO
        <br />
        {isError ? (
          <Message variant="error" mt={4}>
            Something went wrong, please try again later.
          </Message>
        ) : null}
      </Box>
      <ModalCtaContainer mt={4}>
        <Button type="button" variant="text" onClick={() => go(-1)}>
          Back
        </Button>
        <Button type="submit">Confirm & Request Workers</Button>
      </ModalCtaContainer>
    </Box>
  )
}
