import React, { useMemo } from 'react'
import { WorkerInvite } from 'api/worker'
import { Avatar, Box, Flex, Text } from 'ui'
import { format, isToday, isYesterday } from 'date-fns'

export function WorkerInviteCard(props: { data: WorkerInvite }) {
  const { data } = props
  const timestamp = useMemo(() => {
    const createdAt = new Date(data.createdAt)
    if (isYesterday(createdAt)) {
      return 'yesterday'
    }

    if (isToday(createdAt)) {
      return 'today'
    }

    return format(createdAt, 'MMM d')
  }, [data])

  return (
    <Flex alignItems={'center'} my={3}>
      <Avatar size={'medium'} user={{ name: data.name }} />
      <Box ml={2}>
        <Text fontWeight={2}>{data.name}</Text>
        <Text color={'lightText'}>Invitation sent {timestamp}</Text>
      </Box>
    </Flex>
  )
}
