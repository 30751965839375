import { api } from 'api'

export function giveCompanyWorkerBonus(options: {
  companyId: number
  workerId: number
  amount: number
  reason: string
}) {
  const { companyId, workerId, amount, reason } = options
  return api.post(`/company/${companyId}/bonus`, {
    worker_id: workerId,
    amount,
    comment: reason,
  })
}
