import React from 'react'
import { Box, Text } from '@workwhile/ui'
import { User } from 'typings/common_defs'
import { WorkerAvailability } from 'api/worker'

interface Props {
  unavailableWorkers: User[]
  workerAvailabilities: WorkerAvailability[]
}

export const UnavailableWorkersTooltip: React.FC<Props> = ({
  unavailableWorkers,
  workerAvailabilities,
}) => {
  return (
    <Box>
      {unavailableWorkers.map((worker) => {
        const availability = workerAvailabilities.find(
          (a) => Number(a.workerId) === Number(worker.id)
        )
        return (
          <Box key={worker.id} mb={2}>
            <Text fontWeight="bold">{worker.name}</Text>
            {availability?.reasons && availability.reasons.length > 0 ? (
              <ul style={{ margin: 0, paddingLeft: '20px' }}>
                {availability.reasons.map((reason, index) => (
                  <li key={index}>
                    <Text>{reason}</Text>
                  </li>
                ))}
              </ul>
            ) : (
              <Text>No reason provided</Text>
            )}
          </Box>
        )
      })}
    </Box>
  )
}
