import { ReactNode } from 'react'
import { AuthenticatedProps } from './types'
import { intersection } from 'ramda'
import { useAuthContext } from './useAuthContext'

export default function Role(props: AuthenticatedProps): ReactNode {
  const { value, compareMode = 'any', children, fallback = null } = props
  const { user, permissions } = useAuthContext()

  if (user && !permissions) {
    return null // retrieving permissions
  }

  if (!permissions) {
    return fallback
  }

  const roles = [permissions.name]

  const requiredRoles = value ? (Array.isArray(value) ? value : [value]) : []

  if (requiredRoles.length === 0) {
    throw new Error('At least 1 role must be supplied to Role')
  }

  if (
    compareMode === 'any' &&
    intersection(roles, requiredRoles).length === 0
  ) {
    return fallback
  }

  if (
    compareMode === 'all' &&
    !requiredRoles.every((role) => roles.includes(role))
  ) {
    return fallback
  }

  return children
}
