import React from 'react'
import { Page } from 'pages/layout/Page'
import { FilterBar } from 'pages/HomePage/FilterBar'
import { HomeProvider } from './HomeProvider'
import { CalendarControl } from './CalendarControl'
import { AbsoluteScrollContainer } from './AbsoluteScrollContainer'

export function HomePage() {
  return (
    <HomeProvider>
      <Page
        display={'flex'}
        fullHeight={true}
        flexDirection={'column'}
        title={'Schedule'}
        overflowX={'hidden'}
      >
        <FilterBar />
        <CalendarControl />
        <AbsoluteScrollContainer />
      </Page>
    </HomeProvider>
  )
}
