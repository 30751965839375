import React from 'react'
import { Box, Card } from 'ui'
import { ChargeAccounts } from '../../../PaymentPage/ChargeAccounts'
import { AlertTriangleIcon } from 'lucide-react'
import { useTheme } from 'styled-components'

export function AddPaymentMethod() {
  const { colors } = useTheme()

  return (
    <Card>
      <ChargeAccounts
        title={
          <Box display={'inline-flex'} alignItems={'center'}>
            <AlertTriangleIcon size={24} color={colors.warning} />
            &nbsp;Payment Info Needed.
          </Box>
        }
        emptyStateText={
          'Please add a payment method to create your first shift.'
        }
      />
    </Card>
  )
}
