import { WorkerAvailabilityRequest } from 'api/worker'

export const workerKey = ['worker']
export const workerProfileKey = (workerId: number) => [
  ...workerKey,
  workerId.toString(),
]
export const rosterDetailKey = (rosterId: number) => [
  ...workerKey,
  'rosterDetail',
  rosterId.toString(),
]
export const pendingInvitesKey = [...workerKey, 'pendingInvites']
export const workerShiftsKey = (workerId: number) => [
  ...workerKey,
  'workerShifts',
  workerId.toString(),
]
export const workerComplimentsKey = (workerId: number) => [
  ...workerKey,
  'workerCompliments',
  workerId.toString(),
]
export const workerLogsKey = (
  startDate: Date,
  endDate: Date,
  userId: number
) => [
  ...workerKey,
  'workerLogs',
  startDate.toISOString(),
  endDate.toISOString(),
  userId.toString(),
]
export const workerAvailabilityKey = ({
  workerIds,
  hypotheticalShifts,
  shiftIds,
}: WorkerAvailabilityRequest) => [
  ...workerKey,
  'workerAvailability',
  workerIds?.join('|'),
  hypotheticalShifts
    ?.map(
      (shift) =>
        `${shift?.positionId}-${shift?.locationId}-${shift?.start}-${shift?.end}`
    )
    .join('|'),
  shiftIds?.join('|'),
]
