import React, { useEffect } from 'react'
import { track } from 'lib/amplitude'
import {
  Box,
  Card,
  CenterBox,
  Field,
  Heading,
  Input,
  Button,
  Message,
  Link,
} from 'ui'
import { WWLogo } from 'components/WWLogo'
import * as zod from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useResetPasswordMutation } from 'queries/user'
import { useNavigate } from 'react-router'

const schema = zod.object({
  email: zod
    .string({
      required_error: 'Email is required',
    })
    .email('Must be valid email address'),
})

type FormData = zod.infer<typeof schema>

export function ResetPasswordPage() {
  useEffect(() => {
    track('impression, forgot password')
  }, [])

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  })

  const navigate = useNavigate()

  const {
    mutate: resetPassword,
    isPending,
    error,
  } = useResetPasswordMutation({
    async onSuccess() {
      track('impression, success: forget pwd email sent to user')
      navigate('/login?pwd_reset=1')
    },
    onError() {
      track('impression, failed: firebase unable to send forgot pwd email')
    },
  })

  const onSubmit = handleSubmit((data) => {
    track('button_click, submit forgot password')
    resetPassword(data)
  })

  return (
    <CenterBox>
      <Box width={[1, 425]}>
        <Card py={4} px={[4, 3]}>
          <form onSubmit={onSubmit}>
            <WWLogo size={'medium'} />
            <Heading level={4} my={4}>
              Enter your email to reset your password.
            </Heading>
            <Field label={'Email'} error={errors.email?.message?.toString()}>
              <Input
                disabled={isPending}
                autoFocus={true}
                placeholder={'e.g. jane.doe@example.com'}
                {...register('email')}
              />
            </Field>
            <Box mt={4}>
              <Button
                block={true}
                size={'large'}
                type={'submit'}
                loading={isPending}
              >
                Submit
              </Button>
            </Box>
            {error ? (
              <Message mt={2} mb={4} variant={'error'}>
                {error.message}
              </Message>
            ) : null}
            <Box mt={4}>
              <Link to={'/login'}>Remember your password? Sign In.</Link>
            </Box>
          </form>
        </Card>
      </Box>
    </CenterBox>
  )
}
