import { graphql } from '../graphql'
import { path } from 'ramda'
import { ShiftSimple } from './typings'

export async function getShiftDuplicateRecs() {
  const data = await graphql(
    `
      company {
        shiftDuplicateRecommendations {
          isTryout,
          id, startsAt, endsAt, 
          supervisors (status: "scheduled") { id, name, phoneNumber, userId, status}, 
          workersNeeded, 
          payRate, payLumpSum, lunchLength,
          shiftBonuses { amount },
          position { name, id, hidden },
          location { name, id, address {city, state, street, unit, zip, lat, long, timezone}}
          locationless
        }
      }
        `,
    'current_company'
  )

  const shifts = path(
    ['data', 'data', 'company', 'shiftDuplicateRecommendations'],
    data
  ) as ShiftSimple[]

  return shifts.map((shift) => ({
    ...shift,
    position: shift.position
      ? {
          ...shift.position,
          id: Number(shift.position.id),
        }
      : undefined,
    location: shift.location
      ? {
          ...shift.location,
          id: Number(shift.location.id),
        }
      : undefined,
  }))
}
