import { getDemoData, matchRestRequest, setDemoData } from 'api/demo/utils'
import { path, pick } from 'ramda'
import { DemoApiMock } from 'api/demo/types'
import { CompanyWorker } from 'api/worker'
import { matchPath } from 'react-router-dom'

export const mockUpdateRoster: DemoApiMock = {
  shouldMock: (request) =>
    matchRestRequest(request, /\/company\/\d+\/roster\/\d+/, 'PUT'),
  getResponse: async (request) => {
    const rosterId = request.url?.split('/').pop() as string
    const name = path(['data', 'name'], request) as string
    const demoData = getDemoData()
    setDemoData({
      ...demoData,
      rosters: demoData.rosters.map((roster) =>
        roster.id === Number(rosterId) ? { ...roster, name } : roster
      ),
    })
    return null
  },
}

export const mockAddWorkersToRoster: DemoApiMock = {
  shouldMock: (request) =>
    matchRestRequest(request, /\/company\/\d+\/roster\/\d+\/workers/, 'POST'),
  getResponse: async (request) => {
    const match = matchPath(
      '/company/:companyId/roster/:rosterId/workers',
      request.url as string
    )
    const rosterId = Number(match?.params.rosterId)
    const workerIds = path(['data', 'worker_ids'], request) as number[]
    const demoData = getDemoData()
    setDemoData({
      ...demoData,
      rosters: demoData.rosters.map((roster) => {
        if (roster.id !== rosterId) {
          return roster
        }
        const newWorkers = workerIds
          .filter(
            (workerId) =>
              !roster.workers.some(
                (worker) => worker.id.toString() === workerId.toString()
              )
          )
          .map((workerId) =>
            pick(
              ['id', 'profilePicUrl', 'name'],
              demoData.workers.find(
                (worker) => worker.id.toString() === workerId.toString()
              ) as CompanyWorker
            )
          )
        return {
          ...roster,
          workers: [...roster.workers, ...newWorkers],
        }
      }),
    })
    return null
  },
}

export const mockAddWorkerToRosters: DemoApiMock = {
  shouldMock: (request) =>
    matchRestRequest(request, /\/company\/\d+\/worker\/\d+\/rosters/, 'PUT'),
  getResponse: async (request) => {
    const match = matchPath(
      '/company/:companyId/worker/:workerId/rosters',
      request.url as string
    )
    const workerId = Number(match?.params.workerId)
    const rosterIds = path(['data', 'roster_ids'], request) as number[]
    const demoData = getDemoData()
    const updatedDemoData = {
      ...demoData,
      rosters: demoData.rosters.map((roster) => {
        if (!rosterIds.includes(roster.id)) {
          // remove from roster if already part of
          return {
            ...roster,
            workers: roster.workers.filter(
              (worker) => worker.id.toString() !== workerId.toString()
            ),
          }
        }
        // already part of roster
        if (
          roster.workers.some(
            (worker) => worker.id.toString() === workerId.toString()
          )
        ) {
          return roster
        }
        return {
          ...roster,
          workers: [
            ...roster.workers,
            pick(
              ['id', 'profilePicUrl', 'name'],
              demoData.workers.find(
                (worker) => worker.id.toString() === workerId.toString()
              )
            ),
          ],
        }
      }),
    }
    setDemoData(updatedDemoData)
    return {
      rosters: updatedDemoData.rosters.filter((roster) =>
        rosterIds.includes(roster.id)
      ),
    }
  },
}

export const mockRemoveWorkerFromRoster: DemoApiMock = {
  shouldMock: (request) =>
    matchRestRequest(
      request,
      /\/company\/\d+\/roster\/\d+\/worker\/\d+/,
      'DELETE'
    ),
  getResponse: async (request) => {
    const match = matchPath(
      '/company/:companyId/roster/:rosterId/worker/:workerId',
      request.url as string
    )
    const rosterId = match?.params.rosterId as string
    const workerId = match?.params.workerId as string
    const demoData = getDemoData()
    setDemoData({
      ...demoData,
      rosters: demoData.rosters.map((roster) =>
        roster.id === Number(rosterId)
          ? {
              ...roster,
              workers: roster.workers.filter(
                (worker) => worker.id !== Number(workerId)
              ),
            }
          : roster
      ),
    })
    return null
  },
}
