import React from 'react'
import { useShiftEditor } from '../../useShiftEditor'
import { EditorMode } from '../../ShiftEditorProvider'
import { isFuture } from 'date-fns'
import { useShiftDate } from '../SchedulePage/useShiftDate'
import { Message, Pluralize } from 'ui'

export function PastShiftCallout() {
  const { editorMode, shiftData } = useShiftEditor()
  const numWorkers = shiftData.staff?.numWorkers ?? 0
  const { endsAt } = useShiftDate()

  if (editorMode !== EditorMode.PastShift || !endsAt) {
    return null
  }

  const isOngoing = isFuture(endsAt)
  const workerText = (
    <Pluralize
      count={numWorkers}
      oneText={'worker'}
      manyText={'workers'}
      hideCount={true}
    />
  )

  if (isOngoing) {
    return (
      <Message variant={'info'}>
        This shift is ongoing. WorkWhile will clock the {workerText}&nbsp;in.
        The {workerText} will be expected to clock themselves out.
      </Message>
    )
  }

  return (
    <Message variant={'info'}>
      This shift has ended and the {workerText} will be paid within the next 24
      hours.
    </Message>
  )
}
