import React from 'react'
import { TimelineShiftCardPlaceholder } from '../useTimelineContent'
import { Box, Card, CardProps } from 'ui'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'

export const CardContainer = styled(Card).attrs<CardProps>({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  shadowless: true,
  borderWidth: 0,
  color: 'text',
})``

export function Placeholder(
  props: Pick<TimelineShiftCardPlaceholder, 'height' | 'translateY'>
) {
  const { height, translateY } = props

  return (
    <CardContainer
      style={{
        height,
        transform: `translate3d(0, ${translateY}px, 0)`,
      }}
    >
      <Box width={[1, 1 / 2, 1 / 5]}>
        <Skeleton />
      </Box>
      <Box fontSize={1} width={[1, 2 / 3, 1 / 4]}>
        <Skeleton />
      </Box>
    </CardContainer>
  )
}
