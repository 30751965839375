import { useMutation } from '@tanstack/react-query'
import { addWorkerToRosters, CompanyRoster } from 'api/worker'
import { useCompanyInfo } from './useCompanyInfo'

interface UseAddWorkerToRostersMutation {
  workerId: number
  onSuccess?: (rosters: Array<Pick<CompanyRoster, 'id' | 'name'>>) => void
  onError?: (err: Error) => void
}

export function useAddWorkerToRostersMutation(
  options: UseAddWorkerToRostersMutation
) {
  const { data: company } = useCompanyInfo()

  return useMutation({
    mutationFn: async (rosterIds: number[]) => {
      if (!company?.id) {
        throw new Error('Company id not found')
      }

      return addWorkerToRosters(company.id, options.workerId, rosterIds)
    },
    ...options,
  })
}
