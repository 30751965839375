import { useQuery, useQueryClient } from '@tanstack/react-query'
import { chargeAccountsKey } from './keys'
import { getChargeAccounts } from 'api/payment'
import { ChargeAccount } from 'typings/common_defs'

interface UseChargeAccounts {
  onSuccess?: (chargeAccounts: ChargeAccount[]) => void
  refetchOnWindowFocus?: boolean | 'always'
}

export function useChargeAccounts(options?: UseChargeAccounts) {
  return useQuery({
    queryKey: chargeAccountsKey,
    queryFn: getChargeAccounts,
    ...options,
  })
}

export function useInvalidateChargeAccounts() {
  const queryClient = useQueryClient()

  return {
    invalidate: () =>
      queryClient.invalidateQueries({ queryKey: chargeAccountsKey }),
  }
}
