import styled from 'styled-components'
import { Flex } from 'ui'

export const RadioOption = styled(Flex)<{ $isSelected: boolean }>`
  ${({ $isSelected, theme: { colors, radii } }) => `
    position: relative;
    overflow: hidden;
    border: 1px solid ${colors.neutrals[300]};
    border-radius: ${radii.standard};
    width: 100%;
    
    .option-title {
      color: ${$isSelected ? colors.primary : colors.neutrals[400]};
    }
    
    ${
      $isSelected
        ? `
      box-shadow: 0 0 0 1px ${colors.primary};
      border-color: ${colors.primary};
    `
        : ''
    }
  `}
`

RadioOption.defaultProps = {
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  my: 1,
  p: 3,
}
