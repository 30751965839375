import { useMutation } from '@tanstack/react-query'
import {
  getAuth,
  createUserWithEmailAndPassword,
  AuthErrorCodes,
  signInWithEmailAndPassword,
  signInWithCustomToken,
} from 'firebase/auth'
import { FirebaseError } from 'firebase/app'
import { acceptInviteRequest, updateUserAccount } from 'api/user'
import { track } from 'lib/amplitude'
import { useInvalidateUserPermissions } from 'queries/user/useUserPermissions'
import { getCurrentCompanyInfo } from 'api/company'

export function useSignUpMutation(options?: {
  onSuccess?: () => void
  onError?: (err: Error | FirebaseError) => void
}) {
  const { invalidate: invalidateUserPermissions } =
    useInvalidateUserPermissions()
  return useMutation({
    mutationFn: async (data: {
      email: string
      name: string
      password: string
      phoneNumber: string
      inviteToken: string
    }) => {
      const { name, email, password, phoneNumber, inviteToken } = data
      try {
        const auth = getAuth()

        try {
          await createUserWithEmailAndPassword(auth, email, password)
          track('impression, success: firebase create user')
        } catch (err) {
          track('impression, failed: firebase create user')

          // if email exists, attempt to login before proceeding
          if ((err as FirebaseError).code === AuthErrorCodes.EMAIL_EXISTS) {
            await signInWithEmailAndPassword(auth, email, password)
          } else {
            throw err
          }
        }

        await createAccountAndAcceptInvite(
          name,
          email,
          phoneNumber,
          inviteToken,
          invalidateUserPermissions
        )
      } catch (err) {
        throw new Error(translateError(err as Error | FirebaseError))
      }
    },
    ...options,
  })
}

export function useOktaSignUpMutation(options?: {
  onSuccess?: () => void
  onError?: (err: Error | FirebaseError) => void
}) {
  const { invalidate: invalidateUserPermissions } =
    useInvalidateUserPermissions()
  return useMutation({
    mutationFn: async (data: {
      email: string
      name: string
      phoneNumber: string
      inviteToken: string
      firebaseToken: string
    }) => {
      const { name, email, phoneNumber, inviteToken, firebaseToken } = data
      track('impression, okta: signup mutation', {
        email,
        name,
        phoneNumber,
        inviteToken,
        firebaseToken,
      })
      try {
        const auth = getAuth()

        try {
          await signInWithCustomToken(auth, firebaseToken)
          track('impression, okta firebase: firebase token signin')
        } catch (err) {
          track('impression, failed: okta frebase: firebase token signin')
          // TODO - Better handling if user is already signed up to this company
        }

        await createAccountAndAcceptInvite(
          name,
          email,
          phoneNumber,
          inviteToken,
          invalidateUserPermissions
        )
      } catch (err) {
        throw new Error(translateError(err as Error | FirebaseError))
      }
    },
    ...options,
  })
}

async function createAccountAndAcceptInvite(
  name: string,
  email: string,
  phoneNumber: string,
  inviteToken: string,
  invalidateUserPermissions
) {
  try {
    await updateUserAccount({ name, email, phoneNumber })
    track('impression, success: postNewUser')
  } catch (err) {
    track('impression, failed: postNewUser')
    throw err
  }

  try {
    await acceptInviteRequest(inviteToken)

    track('impression, SUCCESS: invited-user accept invite request succeeded')
    // invalidate permissions query to force a refresh since user is newly associated
    // with the company
    await invalidateUserPermissions()
  } catch (err) {
    // check if invite failed due to user already being associated with the company
    const companyInfo = await getCurrentCompanyInfo()
    if (companyInfo) {
      track('impression, invited-user accept already-accepted invite')
      location.href = '/home'
      return
    }
    track('impression, FAILED: invited-user accept invite request failed')
    throw err
  }
}

function translateError(err: Error | FirebaseError) {
  if ('code' in err) {
    switch (err.code) {
      case AuthErrorCodes.EMAIL_EXISTS:
        return 'Email address already in use'
      case AuthErrorCodes.INVALID_EMAIL:
        return 'Invalid email address'
      case 'auth/invalid-login-credentials':
        return 'An account exists with this email address. Please ensure to use the same password as before.'
    }
  }

  return 'We were unable to create your account. Please try again.'
}
