import { EnvConfig } from './production'

const overrideApiHost = sessionStorage.getItem('useStagingApi') === 'yes'
const apiHost = overrideApiHost
  ? 'https://workwhile-staging.wl.r.appspot.com'
  : process.env.API_HOST
  ? `http://${process.env.API_HOST}`
  : 'https://api.workwhilejobs.com'

export const devConfig: EnvConfig = {
  bugsnagApiKey: 'd485e370149f6b9b78cb61bb162bc08e',
  bugsnagReleaseStage: 'local',
  apiHostUrl: `${apiHost}/v1`,
  graphqlHostUrl: `${apiHost}/graphql`,
  intercomAppId: 'ozmz3fh8',
  statsigApiKey: 'client-CxBrRSAPOy78E7hQr6TLRq85o7ESRxkgtfuN5hOYWj0',
  firebaseConfig: {
    apiKey: 'AIzaSyDkAPtzfcldOGgX8eB0oMKBhsAJ_KKoTAA',
    authDomain: 'workwhile-247820.firebaseapp.com',
    databaseURL: 'https://workwhile-247820.firebaseio.com',
    projectId: 'workwhile-247820',
    storageBucket: '',
    messagingSenderId: '280730972481',
    appId: '1:280730972481:web:f5dec81a827a2396',
  },
  googleMapsApiKey: 'AIzaSyDseZX6cQmFDv1rLXoS_hAnY7kHmbLp-Gs',
  stripePublishableKey: 'pk_test_TuhYLnuyDoYVKHhAmpRzXrPn00jcIqoZln',
  amplitudeApiKey: '3e6571bd5176861da91f8c3a33ae2795',
}
