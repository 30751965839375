import { graphql } from 'api/graphql'
import { path } from 'ramda'
import { Assignment } from 'typings/common_defs'

type GetAssignmentDetailRequest = {
  assignmentId?: number
}

type GetAssignmentDetailResponse = Assignment

type GetAssignmentDetailResponseDTO = {
  data: {
    company: {
      assignment: Assignment
    }
  }
}

export const getAssignmentDetail = async ({
  //TODO: start using this once backend is ready
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  assignmentId,
}: GetAssignmentDetailRequest): Promise<GetAssignmentDetailResponse> => {
  const data = await graphql<GetAssignmentDetailResponseDTO>(
    `
      company {
        assignment(assignmentId: ${assignmentId}) {
          id
          name
        }
      }
    `,
    'assignment_detail'
  )

  return path(['data', 'data', 'company', 'assignment'], data)
}
