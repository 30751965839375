import React from 'react'
import { Page } from 'pages/layout'
import { useAssignmentDetailContextStateValue } from './context'
import { ScheduleView } from './ScheduleContent'

export const AssignmentDetailPageContent = () => {
  const { assignment } = useAssignmentDetailContextStateValue()

  return (
    <>
      <Page title={assignment.name} px={0}>
        <ScheduleView />
      </Page>
    </>
  )
}
