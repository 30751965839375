import { ShiftDetailAssignedWorkers } from 'pages/ShiftDetailPage/assigned_workers/ShiftDetailAssignedWorkers'
import { useShiftDetail } from 'pages/ShiftDetailPage/useShiftDetail'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Work } from 'typings/common_defs'
import { Box, Flex, Link } from 'ui'

interface Props {
  rowsToSkeleton?: Array<Work>
}

export const EvaluateReviewShiftRows = ({ rowsToSkeleton }: Props) => {
  const { shift, isLoading } = useShiftDetail()

  if (isLoading) {
    const skeletonRows = rowsToSkeleton || [{ id: '1' }]
    return skeletonRows.map((work, idx) => (
      <Flex
        flexDirection="column"
        key={`needs-attention-review-shift-rows-skeleton-${work.id}-${idx}`}
      >
        <Flex flexDirection="row" alignItems="center" mb={2}>
          <Box mr={2}>
            <Skeleton
              height={50}
              width={50}
              circle
              style={{ marginRight: 10 }}
            />
          </Box>
          <Box mr={4}>
            <Skeleton height={35} width={100} />
          </Box>
          <Box mr={2}>
            <Skeleton height={35} width={150} />
          </Box>
          <Box mr={2}>
            <Skeleton height={35} width={150} />
          </Box>
          <Box mr={2}>
            <Skeleton height={35} width={90} />
          </Box>
        </Flex>
        <Skeleton height={20} width={100} />
      </Flex>
    ))
  }

  if (!shift) return null

  return (
    <>
      <ShiftDetailAssignedWorkers
        showUnfilledRows={false}
        showWorkerLabel={false}
        showWorkerStatusBadge={false}
        showNeedsApprovalOnly={true}
        showBulkApproveButton={false}
      />
      <Box mb={3}>
        <Link
          to={`/shift/${shift.id}`}
          onClick={(e) => {
            if (
              e.target instanceof Element &&
              e.target.closest('button, .modal-content')
            ) {
              e.preventDefault()
              e.stopPropagation()
            }
          }}
        >
          View All Timesheets
        </Link>
      </Box>
    </>
  )
}
