import React, { useMemo } from 'react'
import { PencilIcon, Trash2Icon } from 'lucide-react'
import { Box, Button, Flex, IconButton } from 'ui'
import { TimelineShift } from 'api/shift'
import { useModalNavigate } from 'hooks/useModalNavigate'
import { AuthGuard } from 'components/auth'
import { PermissionEnum } from 'api/userPermissions'
import { CancelModal } from './CancelModal'
import { useWorkerStats } from './useWorkerStats'
import { BonusModal } from './BonusModal'
import { RemoveRestrictionModal } from './RemoveRestrictionModal'

export function ShiftCtas(props: { data: TimelineShift }) {
  const { data } = props
  const startDate = new Date(data.startsAt)
  const endDate = new Date(data.endsAt)
  const now = new Date()
  const isInProgress = now >= startDate && now <= endDate
  const isPastShift = endDate < now
  const { isRSW, workersNeeded, workers } = useWorkerStats(data)
  const showRemoveRestriction =
    !isInProgress &&
    isRSW &&
    workers.length < workersNeeded &&
    !data.rostersHoldExpiresAt
  const showAddBonus = !isInProgress && workers.length < workersNeeded
  const navigate = useModalNavigate()
  const hasBonus = useMemo(() => {
    if (!data.shiftBonuses || data.shiftBonuses.length === 0) return false
    return data.shiftBonuses[data.shiftBonuses.length - 1].amount > 0
  }, [data])

  return (
    <AuthGuard.Permission value={PermissionEnum.ManageShifts}>
      <Flex
        flexDirection={'row'}
        alignItems={'center'}
        color={'primary'}
        flexShrink={0}
      >
        {showRemoveRestriction ? (
          <AuthGuard.Permission value={PermissionEnum.RequestSpecificWorker}>
            <RemoveRestrictionModal
              shift={data}
              trigger={
                <Button disabled={isPastShift} kind={'small'} mr={3}>
                  Remove Restriction
                </Button>
              }
            />
          </AuthGuard.Permission>
        ) : showAddBonus ? (
          <AuthGuard.Permission value={PermissionEnum.ManageBonus}>
            <BonusModal
              shift={data}
              trigger={
                <Button disabled={isPastShift} kind={'small'} mr={3}>
                  {hasBonus ? 'Edit Bonus' : 'Add Bonus'}
                </Button>
              }
            />
          </AuthGuard.Permission>
        ) : null}
        <Box mr={1}>
          <IconButton
            disabled={isInProgress || isPastShift}
            onClick={() => {
              navigate(`/home/shift-editor/${data.id}/confirm`)
            }}
          >
            <PencilIcon size={16} />
          </IconButton>
        </Box>
        <Box>
          <CancelModal
            shift={data}
            trigger={
              <IconButton disabled={isInProgress || isPastShift}>
                <Trash2Icon size={18} />
              </IconButton>
            }
          />
        </Box>
      </Flex>
    </AuthGuard.Permission>
  )
}
