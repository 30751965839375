import React from 'react'
import {
  createBrowserRouter,
  Navigate,
  Route,
  useLocation,
  ScrollRestoration,
} from 'react-router-dom'
import { createRoutesFromElements, RouterProvider, Routes } from 'react-router'
import {
  SignOutPage,
  OnboardingPage,
  LoginPage,
  ResetPasswordPage,
} from './auth'
import { Layout } from 'pages/layout'
import { HomePage } from 'pages/HomePage'
import { Intercom } from 'components/Intercom'
import { AuthGuard } from 'components/auth'
import { ShiftEditorPage } from 'pages/ShiftEditorPage'
import NotFound from 'components/not_found/not_found'
import { ModalRoutes } from './ModalRoutes'
import { LayoutProvider } from './layout/LayoutProvider'
import { ShiftDetail } from './ShiftDetailPage'
import { WorkersPage } from './WorkersPage'
import { WorkerProfilePage } from './WorkerProfilePage'
import { ListDetailPage } from './ListDetailPage'
import { MessagePage } from './MessagePage'
import { PaymentPage } from './PaymentPage'
import { ReviewPage } from './ReviewPage'
import { InvoiceDetailPage } from './InvoiceDetailPage'
import { AnalyticsPage } from './AnalyticsPage'
import { SettingsPage } from './settings'
import { VerifyMFA } from './auth/VerifyMFA'
import { WorkerShiftsPage } from './WorkerShiftsPage'
import OktaLoginCallback from './auth/OktaLoginCallback'
import { ShiftInstructionsPage } from './ShiftInstructionsPage'
import { ShiftInstructionEditor } from 'pages/ShiftInstructionEditor'
import { PositionInstructionsPage } from './PositionInstructionsPage'
import { PositionInstructionEditor } from 'pages/PositionInstructionEditor'
import { LocationInstructionsPage } from './LocationInstructionsPage'
import { LocationInstructionEditor } from 'pages/LocationInstructionEditor'
import { AssignmentDetailPage } from './AssignmentDetailPage'
import { useGate } from 'statsig-react'
import { FeatureGates } from 'lib/statsig/feature_gates'

const PageRoutes = () => {
  const location = useLocation()
  const { value: shouldEnableLTA } = useGate(FeatureGates.EnableLTA)
  const backgroundStack =
    (location.state && location.state.backgroundStack) || []
  return (
    <LayoutProvider>
      <ScrollRestoration />
      {backgroundStack.length > 0 ? <ModalRoutes /> : null}
      <Routes
        location={backgroundStack.length > 0 ? backgroundStack[0] : location}
      >
        <Route
          path={'/onboarding'}
          element={<Navigate to={'/login'} replace={true} />}
        />
        <Route path={'/login'} element={<LoginPage />} />
        <Route path={'/reset-password'} element={<ResetPasswordPage />} />
        <Route path={'/sign-out'} element={<SignOutPage />} />
        <Route path={'/invited_user/:token'} element={<OnboardingPage />} />
        <Route path={'/verify-mfa'} element={<VerifyMFA />} />
        <Route path={'/okta_callback'} element={<OktaLoginCallback />} />
        <Route
          path={'/'}
          element={
            <AuthGuard.Authenticated fallback={<Navigate to={'/login'} />}>
              <Layout />
              <Intercom />
            </AuthGuard.Authenticated>
          }
        >
          <Route index element={<Navigate to="/home" />} />
          <Route path={'/home/:date?'} element={<HomePage />} />
          <Route
            path={'/home/shift-editor/:shiftId/*'}
            element={<ShiftEditorPage />}
          />
          <Route
            path={'/shift/:shiftId/instruction/:idOrCategory'}
            element={<ShiftInstructionEditor />}
          />
          <Route
            path={'/shift/:shiftId/instructions'}
            element={<ShiftInstructionsPage />}
          />
          <Route path={'/shift/:shiftId'} element={<ShiftDetail />} />
          <Route path={'/workers'} element={<WorkersPage />} />
          <Route
            path={'/workers/:workerId/shifts'}
            element={<WorkerShiftsPage />}
          />
          <Route path={'/workers/:workerId'} element={<WorkerProfilePage />} />
          <Route
            path={'/workers/list/:rosterId'}
            element={<ListDetailPage />}
          />
          <Route path={'/review'} element={<ReviewPage />} />
          <Route path={'/messages/:workerId?'} element={<MessagePage />} />
          <Route path={'/payments'} element={<PaymentPage />} />
          <Route
            path={'/payments/invoice/:invoiceId'}
            element={<InvoiceDetailPage />}
          />
          <Route path={'/analytics'} element={<AnalyticsPage />} />
          <Route path={'/settings/:tab?'} element={<SettingsPage />} />
          <Route
            path={'/profile/tutorials'}
            element={<Navigate to={'/settings/tutorials'} replace={true} />}
          />
          <Route
            path={'/position/:positionId/instruction/:idOrCategory'}
            element={<PositionInstructionEditor />}
          />
          <Route
            path={'/position/:positionId/instructions'}
            element={<PositionInstructionsPage />}
          />
          <Route
            path={'/location/:locationId/instruction/:idOrCategory'}
            element={<LocationInstructionEditor />}
          />
          <Route
            path={'/location/:locationId/instructions'}
            element={<LocationInstructionsPage />}
          />
          {shouldEnableLTA ? (
            <Route
              path={'/assignments/:assignmentId'}
              element={<AssignmentDetailPage />}
            />
          ) : null}
          <Route path={'*'} element={<NotFound />} />
        </Route>
      </Routes>
    </LayoutProvider>
  )
}

const router = createBrowserRouter(
  createRoutesFromElements(<Route path={'*'} Component={PageRoutes} />)
)

export default function Pages() {
  return (
    <RouterProvider router={router} future={{ v7_startTransition: true }} />
  )
}
