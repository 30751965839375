import React, { useRef } from 'react'
import styled from 'styled-components'
import { NavSection } from './NavSection'
import { CtaButtons } from './CtaButtons'
import { CompanyInfo } from './CompanyInfo'
import { Box, CloseButton, tabletScreen } from 'ui'
import { useLayoutContext } from '../useLayoutContext'
import { Overlay } from '../Overlay'
import { useHover } from 'ahooks'

const SideNavCloseButton = styled(CloseButton)<{
  $visible: boolean
  $menuWidth: string
}>`
  position: absolute;
  z-index: 10002;
  transition: transform 0.3s ease-in-out;
  visibility: hidden;

  ${({ $visible, $menuWidth }) => `
    top: 1.5rem;
    left: 0;
    
    ${
      $visible
        ? `
        visibility: visible;
        transform: translateX(calc(${$menuWidth} + 1rem));
    `
        : ''
    }
  `}
`

const NavContainer = styled(Box)<{
  $open: boolean
  $collapsed: boolean
  $menuWidth: string
}>`
  ${({ theme: { colors, shadows }, $open, $collapsed, $menuWidth }) => `
    background-color: ${colors.white};
    border-right: 1px solid ${colors.neutrals[100]};
    overflow: auto;
    position: sticky;
    top: 0;
    will-change: width;
    transition: width 0.3s ease-in-out;
    width: ${$menuWidth};
    
    .link-label, .input-label, .company-name {
      transition: opacity 0.3s ease-in-out;
    }
    
    .cta-container {
      transition: padding 0.3s ease-in-out;
      button svg {
        display: none;
      }
    }
    
    .react-select__control {
      flex-wrap: nowrap;
    }
    
    .viewing-icon {
      position: absolute;
      top: calc(50% - 0.6rem);
      left: calc(50% - 1.1rem);
      opacity: 0;
      pointer-events: none;
    }
    
    .react-select__indicator, .react-select__single-value, .viewing-icon {
      transition: opacity 0.3s ease-in-out;
    }
    
    ${
      $collapsed
        ? `
      position: fixed;
      z-index: 9999;
      height: 100vh;
      
      &:not(:hover) {
        width: 10rem;
        .link-label, .input-label, .company-name {
          // transform: translateX(-200px);
          opacity: 0;
        }
        
        .cta-container {
          padding-left: 2.5rem;
          padding-right: 2.5rem;  
          
          button svg {
            display: inline;
          }
        }
        
        .cta-text {
          display: none;
        }
        
        .react-select__indicator, .react-select__single-value, .react-select__placeholder {
          opacity: 0;
        }
        
        .react-select__input {
          display: none;
        }
        
        .viewing-icon {
          opacity: 1;
        }
      }
    `
        : ''
    }
      
    ${tabletScreen(`
      position: fixed;
      z-index: 10002;
      height: 100%;
      max-height: 100vh;
      overflow-y: auto;
      top: 0;
      left: 0;
      border-radius: 0;
      transform: translateX(-100%);
      box-shadow: none;
      transition: transform 0.3s ease-in-out;
      
      ${
        $open
          ? `
        transform: translateX(0);
        box-shadow: ${shadows.standard};
      `
          : ''
      }
    `)}
  `}
`

export function SideNav() {
  const ref = useRef(null)
  const isHover = useHover(ref)
  const { menuWidth, mobileMenuOpen, isNavCollapsed, toggleMobileMenu } =
    useLayoutContext()

  return (
    <>
      {mobileMenuOpen ? <Overlay onClick={toggleMobileMenu} /> : null}
      {isHover && isNavCollapsed ? <Overlay $zIndex={9998} /> : null}
      <SideNavCloseButton
        onClick={toggleMobileMenu}
        $menuWidth={menuWidth}
        $visible={mobileMenuOpen}
      />
      <NavContainer
        ref={ref}
        $open={mobileMenuOpen}
        $collapsed={isNavCollapsed}
        $menuWidth={menuWidth}
      >
        <CompanyInfo />
        <NavSection />
        <CtaButtons />
      </NavContainer>
    </>
  )
}
