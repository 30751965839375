import React from 'react'
import { WeeklyFillRate } from 'typings/common_defs'
import { Heading, Text } from 'ui'
import { TooltipBox } from '../TooltipBox'

interface Props {
  active?: boolean
  payload?: Array<{
    payload: WeeklyFillRate & {
      fillRate: number
      delta: number
    }
  }>
  label?: string
}

export function FillRateTooltip(props: Props) {
  const { active, payload, label } = props

  if (active && payload && label) {
    const data = payload[0].payload
    return (
      <TooltipBox>
        <Heading level={4} my={0} mb={2}>
          {label}
        </Heading>
        <Text>Workers Requested: {data.workersRequested}</Text>
        <Text>Workers Filled: {data.workersFilled}</Text>
        <Text>Fill Rate: {Number(data.fillRate) * 100}%</Text>
      </TooltipBox>
    )
  }

  return null
}
