import axios from 'axios'
import { getLocalTimezone } from 'lib/time'
import { getAuth } from 'firebase/auth'
import { config } from 'config'

export const api = axios.create({
  baseURL: config.apiHostUrl,
})

api.defaults.headers.common['content-type'] = 'application/json'
api.defaults.headers.common.Accept = 'application/json'
api.defaults.headers.common.Timezone = getLocalTimezone()
api.defaults.headers.common['APP-SOURCE'] = 'bizportal'

// this method ensures that the auth token is always up-to-date
export async function getAuthToken(forceRefresh = false) {
  return new Promise<string | null>((resolve) => {
    const unsubscribe = getAuth().onIdTokenChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken(forceRefresh)
        resolve(token)
      } else {
        resolve(null)
      }
      unsubscribe()
    })
  })
}

api.interceptors.request.use(async (config) => {
  const idToken = await getAuthToken()
  if (idToken) {
    config.headers.Authorization = idToken
  }

  return config
})

/* show toast for any api error
 * most errors will come in this form (normalized to match marshmallow):
 * {
 *   "message": {
 *      'long': ['longitude must be number.'], 'general': ['you can not edit shift after offers are sent']
 *   }
 * }
 * it is still possible that some errors will come as error.message = string. e.g. AssertionError will throw a 500
 */
api.interceptors.response.use(null, async (error) => {
  if (error) {
    return Promise.reject(
      new Error(getErrorMessage(error?.response?.data?.message))
    )
  }
})

const getErrorMessage = (msg) => {
  if (msg) {
    if (msg instanceof Object) {
      const keys = Object.keys(msg)
      for (let i = 0; i < keys.length; i += 1) {
        // for each message:
        const key = keys[i]
        if (key === 'general') {
          // if the error is general print it out.
          const arr = msg[key]
          let printout = 'Error: '
          arr.forEach((val) => {
            printout += `${val}. `
          })
          return printout
        } else {
          // else, the message is not "general" and requires context, give it context.
          const errorToShow = `${key}: ${msg[key][0]}`
          return errorToShow
        }
      }
    } else if (typeof msg === 'string') {
      return msg
    }
  }
  return (
    "Hmm.. looks like things aren't working. " +
    'Please check your internet connection. ' +
    'If you require assistance, please contact us.'
  )
}

export const apiDateFormat = 'yyyy-MM-dd'
