import { Box, Text, useAppTheme } from '@workwhile/ui'
import { AlertCircle } from 'lucide-react'
import React, { forwardRef } from 'react'
import styled from 'styled-components'

export type BaseWorkerShiftCellProps = {
  heading?: string
  variant?: 'info' | 'critical'
  value: string
  borderStyle?: 'solid' | 'dashed'
  onClick?: () => void
}

const StyledButton = styled('button').withConfig({
  shouldForwardProp: (prop) => !['borderStyle', 'variant'].includes(prop),
})<Pick<BaseWorkerShiftCellProps, 'borderStyle' | 'variant'>>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 2px solid ${({ theme }) => theme.colors.neutrals[100]};
  color: ${({ theme }) => theme.colors.neutrals[400]};
  border-style: ${({ borderStyle }) => borderStyle};
  padding: ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii.small};
  cursor: pointer;
  width: 100%;
  background: transparent;
  ${({ theme, variant }) => {
    if (variant === 'critical') {
      return `
        border-color: ${theme.colors.error100};
        color: ${theme.colors.error200};
      `
    }
  }}

  &:focus-visible {
    box-shadow: 0px 0px 7px 1px ${({ theme }) => theme.colors.neutrals[200]};
  }
`

export const BaseWorkerShiftCell = forwardRef<
  HTMLButtonElement,
  BaseWorkerShiftCellProps
>(
  (
    { heading, variant = 'info', value, borderStyle = 'solid', onClick },
    ref
  ) => {
    const { colors } = useAppTheme()
    return (
      <StyledButton
        variant={variant}
        borderStyle={borderStyle}
        onClick={onClick}
        ref={ref}
      >
        {heading ? (
          <Text
            fontSize={0}
            color="lightText"
            style={{ textTransform: 'capitalize' }}
          >
            {heading}
          </Text>
        ) : null}
        <Text fontSize={0}>{value}</Text>
        {variant === 'critical' ? (
          <Box
            as="span"
            position="absolute"
            right={-8}
            top={-8}
            backgroundColor={colors.error100}
            borderRadius="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            size={16}
          >
            <AlertCircle size={14} color={colors.error50} />
          </Box>
        ) : null}
      </StyledButton>
    )
  }
)

BaseWorkerShiftCell.displayName = 'BaseWorkerShiftCell'
