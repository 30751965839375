import React, {
  createContext,
  PropsWithChildren,
  useMemo,
  useState,
} from 'react'
import { v4 } from 'uuid'
import axios, { CancelTokenSource } from 'axios'
import {
  MarkdownContent,
  QuizQuestion,
  Tutorial,
  TutorialCategory,
} from 'api/shift/typings'
import { toast } from '@workwhile/ui'
import { useTemplateShortcut } from './hooks/useTemplateShortcut'
import { useLatest } from 'ahooks'
import { getTutorialMetadata } from 'lib/tutorial'
import { SaveOptionsModal } from './SaveOptionsModal'
import { convertToLegacyJsonTutorial } from './convertToLegacyJsonTutorial'
import { cleanUpContent } from './cleanUpContent'
import { useGenerateCompanyQuizMutation } from 'queries/company'

interface EditorContextValue {
  tutorialId?: number
  location?: string
  position?: string
  content: MarkdownContent[]
  setContent: React.Dispatch<React.SetStateAction<MarkdownContent[]>>
  quiz: QuizQuestion[] | null
  setQuiz: React.Dispatch<React.SetStateAction<QuizQuestion[] | null>>
  addQuizQuestion: () => void
  generateQuiz: () => void
  stopGenerating: () => void
  currentPageIdx: number
  category: TutorialCategory
  setCurrentPageIdx: (idx: number) => void
  editorDefaultValue: string
  metadata: ReturnType<typeof getTutorialMetadata>
  templateTutorialId: number | null
  error: Error | null
  isSaving: boolean
  isGeneratingQuiz: boolean
  isGeneratedQuiz: boolean
  showSaveOptionsModal: () => void
  tabValue: string
  setTabValue: React.Dispatch<React.SetStateAction<string>>
}

export const EditorContext = createContext<EditorContextValue | null>(null)

interface EditorProviderProps extends PropsWithChildren {
  tutorialId?: number
  location?: string
  position?: string
  category: TutorialCategory
  tutorial?: Tutorial
  isSaving: boolean
  error: Error | null
  title?: string
  removeTitle?: string
  showOptions?: boolean
  showNotifyWorkers?: boolean
  saveTutorial: (tutorial: {
    content: MarkdownContent[]
    quiz: QuizQuestion[] | undefined
    propagate: boolean
    notifyWorkers: boolean
  }) => void
}

export function EditorProvider(props: EditorProviderProps) {
  const {
    tutorialId,
    category,
    location,
    position,
    tutorial,
    children,
    isSaving,
    error,
    title,
    removeTitle,
    showOptions,
    showNotifyWorkers,
    saveTutorial,
  } = props
  const [currentPageIdx, setCurrentPageIdx] = useState<number>(0)
  const [editorContent, setEditorContent] = useState<MarkdownContent[]>(
    tutorial?.content || [{ markdown: '' }]
  )
  const [quiz, setQuiz] = useState<QuizQuestion[] | null>(
    tutorial?.quiz ?? null
  )
  const [isGeneratedQuiz, setIsGeneratedQuiz] = useState<boolean>(false)

  const latestContent = useLatest(editorContent)
  const templateTutorialId = useTemplateShortcut(setEditorContent)
  const [tabValue, setTabValue] = useState<string>('content')
  const [cancelTokenSource, setCancelTokenSource] =
    useState<CancelTokenSource | null>(null)
  const [showOptionsModal, setShowOptionsModal] = useState<boolean>(false)
  const editorDefaultValue = useMemo(() => {
    return latestContent.current[currentPageIdx]?.markdown
  }, [currentPageIdx, templateTutorialId, editorContent.length, tabValue])
  const metadata = useMemo(
    () => getTutorialMetadata(category as TutorialCategory),
    [category]
  )
  const { mutate: generateTutorialQuiz, isPending: isGeneratingQuiz } =
    useGenerateCompanyQuizMutation({
      onSuccess: (data) => {
        if (data) {
          setIsGeneratedQuiz(true)
          setQuiz(data)
        } else {
          setIsGeneratedQuiz(false)
          value.addQuizQuestion()
        }
      },
      onError: (error) => {
        setQuiz(null)
        setIsGeneratedQuiz(false)
        toast.error(error.message || 'Failed to generate quiz')

        // If the quiz generation request was not cancelled, add a question manually
        if (cancelTokenSource) {
          value.addQuizQuestion()
        }
      },
    })

  const value = useMemo(
    () => ({
      tutorialId,
      location,
      position,
      content: editorContent,
      setContent: setEditorContent,
      quiz,
      setQuiz,
      generateQuiz: () => {
        if (!tutorial) {
          return
        }
        const cancelToken = axios.CancelToken.source()
        setCancelTokenSource(cancelToken)
        generateTutorialQuiz({
          text: {
            name: tutorial.name,
            content: tutorial.content,
          },
          cancelToken,
        })
      },
      stopGenerating: () => {
        if (cancelTokenSource) {
          cancelTokenSource.cancel('Request cancelled')
          setCancelTokenSource(null)
        }
      },
      addQuizQuestion: () =>
        setQuiz((prev) => {
          const correctValue = v4()
          return [
            ...(prev || []),
            {
              key: v4(),
              type: 'radio-group',
              label: `Quiz Question ${(prev?.length ?? 0) + 1}`,
              values: [
                {
                  label: 'Option 1',
                  value: correctValue,
                },
                {
                  label: 'Option 2',
                  value: v4(),
                },
                {
                  label: 'Option 3',
                  value: v4(),
                },
                {
                  label: 'Option 4',
                  value: v4(),
                },
              ],
              correct_value: correctValue,
            },
          ]
        }),
      currentPageIdx,
      setCurrentPageIdx,
      category,
      editorDefaultValue,
      metadata,
      templateTutorialId,
      isSaving,
      isGeneratingQuiz,
      isGeneratedQuiz,
      error,
      saveTutorial,
      showSaveOptionsModal: () => setShowOptionsModal(true),
      tabValue,
      setTabValue,
    }),
    [
      tutorialId,
      tutorial,
      location,
      position,
      currentPageIdx,
      category,
      editorContent,
      editorDefaultValue,
      quiz,
      metadata,
      templateTutorialId,
      error,
      isSaving,
      isGeneratingQuiz,
      isGeneratedQuiz,
      tabValue,
    ]
  )

  return (
    <EditorContext.Provider value={value}>
      {children}
      {showOptionsModal ? (
        <SaveOptionsModal
          open={showOptionsModal}
          title={title}
          location={location}
          position={position}
          category={category}
          removeTitle={removeTitle}
          showOptions={showOptions}
          showNotifyWorkers={showNotifyWorkers}
          onClose={() => setShowOptionsModal(false)}
          onOk={(propagate: boolean, notifyWorkers: boolean) => {
            setShowOptionsModal(false)
            saveTutorial({
              content: editorContent.map((page) => {
                const jsonContent = convertToLegacyJsonTutorial(page.markdown)
                return {
                  ...page,
                  markdown: cleanUpContent(page.markdown),
                  content: jsonContent,
                }
              }),
              quiz: quiz && quiz.length > 0 ? quiz : undefined,
              propagate,
              notifyWorkers,
            })
          }}
        />
      ) : null}
    </EditorContext.Provider>
  )
}
