import { useCompanyInfo } from '../company'
import { useMutation } from '@tanstack/react-query'
import { cancelShift } from 'api/shift'
import { AxiosError } from 'axios'

export function useCancelShiftMutation(options: {
  shiftId: number
  onSuccess?: () => void
  onError?: (err: AxiosError) => void
}) {
  const { shiftId, ...otherOptions } = options
  const { data: company } = useCompanyInfo()

  return useMutation({
    mutationFn: async () => {
      if (!company?.id) {
        return
      }

      return cancelShift(company.id, shiftId)
    },
    ...otherOptions,
  })
}
