import React from 'react'
import { Flex, Heading } from '@workwhile/ui'
import { useCalendarViewContextStateValue } from '../context/CalendarViewProvider'
import { format } from 'date-fns'
import { CalendarControls } from './CalendarControls'

export const CalendarHeader = () => {
  const {
    state: { currentWeekStart },
  } = useCalendarViewContextStateValue()

  return (
    <Flex justifyContent="space-between">
      <Flex alignItems="center">
        <Heading level={3} fontWeight="400">
          {format(currentWeekStart, 'MMMM, yyyy')}
        </Heading>
      </Flex>
      <Flex alignItems="center" style={{ gap: '1rem' }}>
        <CalendarControls />
      </Flex>
    </Flex>
  )
}
