import React, { useEffect, useState } from 'react'
import { Flex, Box, Checkmark } from 'ui'
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'

export const BONUSES = [
  {
    value: 10,
  },
  {
    value: 15,
  },
  {
    value: 20,
    isRecommended: true,
  },
  { value: 25 },
  { value: 30 },
]

const BonusBox = styled(Flex).attrs({
  width: 1,
  alignItems: 'center',
  justifyContent: 'center',
  px: 3,
  py: 2,
  mb: 2,
})<{ $isRecommended: boolean; $isSelected: boolean }>`
  ${({ theme: { colors, radii }, $isRecommended, $isSelected }) => `
    position: relative;
    border: 1px solid ${colors.neutrals[200]};
    border-radius: ${radii.standard};
    
    ${
      $isRecommended
        ? `
      &:before {
        content: 'Recommended';
        position: absolute;
        width: 8rem;
        top: -0.9rem;
        background-color: ${colors.info};
        color: ${colors.white};
        border-radius: 2rem;
        font-size: 0.9rem;
        text-align: center;
        font-weight: 400;
        padding: 0.1rem 0;
      }
    
    `
        : ''
    }
    
    ${
      $isSelected
        ? `
      box-shadow: inset 0 0 0 1px ${colors.primary};
      border-color: ${colors.primary};
      color: ${colors.primaries[300]};
      
      ${Checkmark} {
        border-radius: 50%;
        position: absolute;
        top: -1rem;
        right: -1.8rem;
        font-size: 1.3rem;
      }
    `
        : ''
    }
  `}
`

export function BonusSelector() {
  const { watch, setValue } = useFormContext()
  const bonus = watch('bonus')
  const [selected, setSelected] = useState<null | number>(bonus ?? null)
  const customBonus = watch('customBonus')

  useEffect(() => {
    if (customBonus) {
      setValue('bonus', undefined)
      setSelected(null)
    }
  }, [customBonus])

  useEffect(() => {
    setValue('bonus', selected)
    if (selected !== null) {
      setValue('customBonus', undefined)
    }
  }, [selected])

  return (
    <Flex flexWrap={'wrap'} mx={-2}>
      {BONUSES.map((bonus) => (
        <Box key={bonus.value} width={[1 / 3, 1 / 4, 1 / 5]} p={2}>
          <BonusBox
            $isRecommended={!!bonus.isRecommended}
            $isSelected={selected === bonus.value}
            fontWeight={2}
            fontSize={4}
            onClick={() =>
              setSelected((prevSelected) =>
                prevSelected === bonus.value ? null : bonus.value
              )
            }
          >
            ${bonus.value}
            {selected === bonus.value ? <Checkmark /> : null}
          </BonusBox>
        </Box>
      ))}
    </Flex>
  )
}
