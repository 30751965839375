import { getDemoData, matchRestRequest, setDemoData } from 'api/demo/utils'
import { LocationRequest } from 'api/company'
import { DemoCategory } from 'api/demo/types'
import { locations, locationsByCategory } from '../data/locations'
import { geocodeFromAddress } from 'components/AutocompleteAddress'

export const mockCreateLocation = {
  shouldMock: (request) =>
    matchRestRequest(request, /\/company\/\d+\/location/, 'POST'),
  getResponse: async (request) => {
    return createLocation(request.data)
  },
}

export async function createLocation(locationRequest: LocationRequest) {
  const demoData = getDemoData()
  const {
    name,
    street,
    state,
    city,
    zip,
    clockInLat,
    clockInLong,
    clockInRadius,
    unit,
    arrivalInstructions,
  } = locationRequest
  // get geo info
  const placeInfo = await geocodeFromAddress(
    `${street} ${city}, ${state} ${zip}`
  )
  const newLocation = {
    id: Math.max(...demoData.locations.map((l) => l.id ?? 0), 0) + 1,
    name,
    address: {
      street,
      state,
      city,
      zip,
      unit,
      timezone: 'America/Los_Angeles',
      lat: placeInfo?.geometry?.lat,
      long: placeInfo?.geometry?.lng,
    },
    arrivalInstructions,
    clockInLat,
    clockInLong,
    clockInRadius,
  }
  setDemoData({
    ...demoData,
    locations: [...demoData.locations, newLocation],
  })
  return {
    id: newLocation.id,
  }
}

export function createMockLocationsForCategory(category: DemoCategory) {
  return locationsByCategory[category] ?? locations
}
