import { useContext } from 'react'
import { PositionEditorContext } from './PositionEditorProvider'

export function usePositionEditor() {
  const context = useContext(PositionEditorContext)
  if (!context) {
    throw new Error(
      'usePositionEditor must be used within a PositionEditorProvider'
    )
  }
  return context
}
