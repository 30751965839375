import React, {
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import { Flex, Heading } from 'ui'
import { format } from 'date-fns'
import { headerHeight, TimelineDateHeader } from './useTimelineContent'
import useInViewport from 'hooks/useInViewport'
import { useHomeContext } from 'pages/HomePage/useHomeContext'
import { useLatest } from 'ahooks'

const HeadingWrapper = styled(Flex).attrs<{ date: Date }>((props) => ({
  position: 'absolute',
  zIndex: 1,
  top: 0,
  left: 0,
  width: '100%',
  alignItems: 'flex-end',
  children: (
    <Heading level={3} my={0}>
      {format(props.date, 'EEEE, MMMM d')}
    </Heading>
  ),
}))``

export function DateHeading(
  props: TimelineDateHeader & {
    containerRef: MutableRefObject<HTMLDivElement | null>
    containerHeight?: number
  }
) {
  const { containerRef, containerHeight, hasContent, isFooter } = props
  const { setCurrentDate } = useHomeContext()
  const ref = useRef<HTMLDivElement | null>(null)
  const [previousY, setPreviousY] = useState<number>(0)
  const latestYRef = useLatest(previousY)
  const [scrollDirection, setScrollDirection] = useState('down')
  const latestScrollDirection = useLatest(scrollDirection)
  const marginTop = useMemo(() => {
    if (containerHeight) {
      return `-${containerHeight - headerHeight}px`
    }
    return '-90%'
  }, [containerHeight])
  const [isInViewport] = useInViewport(ref, {
    root: containerRef,
    threshold: 0.25,
    rootMargin: `0px 0px ${marginTop}`,
    callback: (entry) => {
      const currentY = entry.boundingClientRect.y
      if (currentY > latestYRef.current) {
        setScrollDirection('up')
      } else {
        setScrollDirection('down')
      }
      setPreviousY(currentY)
    },
  })

  useEffect(() => {
    if (isInViewport) {
      if (isFooter && latestScrollDirection.current === 'up') {
        setCurrentDate(props.data, 'scrolling')
      } else if (!isFooter && latestScrollDirection.current === 'down') {
        setCurrentDate(props.data, 'scrolling')
      }
    }
  }, [isInViewport])

  return (
    <HeadingWrapper
      ref={ref}
      date={props.data}
      style={{
        visibility: isFooter ? 'hidden' : 'visible',
        opacity: hasContent ? 1 : 0.5,
        height: props.height,
        transform: `translate3d(0, ${props.translateY}px, 0)`,
      }}
    />
  )
}
