import { ApiRequest, DemoApiMock } from '../types'
import { mockShiftTimeline } from './getShiftTimeline'
import { mockCreateLocation } from './createLocation'
import { mockGetLocations } from './getLocations'
import { mockGetPositions } from './getPositions'
import { mockCreatePosition } from './createPosition'
import { mockGetWorkers } from './getWorkers'
import { mockGetRosters } from './getRosters'
import { mockGetRosterDetails } from './getRosterDetails'
import { mockCreateRoster } from './createRoster'
import {
  mockUpdateRoster,
  mockAddWorkersToRoster,
  mockRemoveWorkerFromRoster,
  mockAddWorkerToRosters,
} from './updateRoster'
import { mockGetWorkerProfile } from './getWorkerProfile'
import { mockUpdateWorkerStatusForCompany } from './updateWorkerStatus'
import { mockGetShiftDetails } from './getShiftDetails'
import { mockGetCompanyMembers } from './getCompanyMembers'
import { mockGetDuplicateShifts } from './getDuplicateShifts'
import { mockCreateShift } from './createShift'
import {
  mockAddShiftBonus,
  mockCancelShift,
  mockUpdateShift,
} from './updateShift'
import { mockGetWorkerCompliments } from './getWorkerCompliments'
import {
  mockGetPendingReviewShifts,
  mockGetOvertimeReviewShifts,
} from './getReviews'
import {
  mockApproveWork,
  mockBulkApproveWork,
  mockUpdateWork,
} from './editWork'
import { mockGetPositionTemplateGroups } from './getPositionTemplateGroups'
import { mockGetPositionTemplate } from './getPositionTemplate'
import { mockUpdatePosition } from './updatePosition'
import { mockGetInsights } from './getInsights'

const mocks: DemoApiMock[] = [
  mockShiftTimeline,
  mockCreateLocation,
  mockGetLocations,
  mockGetPositions,
  mockCreatePosition,
  mockGetWorkers,
  mockGetRosters,
  mockGetRosterDetails,
  mockCreateRoster,
  mockUpdateRoster,
  mockAddWorkerToRosters,
  mockAddWorkersToRoster,
  mockRemoveWorkerFromRoster,
  mockGetWorkerProfile,
  mockUpdateWorkerStatusForCompany,
  mockShiftTimeline,
  mockGetShiftDetails,
  mockGetCompanyMembers,
  mockGetDuplicateShifts,
  mockCreateShift,
  mockAddShiftBonus,
  mockCancelShift,
  mockUpdateShift,
  mockGetWorkerCompliments,
  mockGetPendingReviewShifts,
  mockGetOvertimeReviewShifts,
  mockUpdateWork,
  mockBulkApproveWork,
  mockApproveWork,
  mockGetPositionTemplateGroups,
  mockGetPositionTemplate,
  mockUpdatePosition,
  mockGetInsights,
]

export async function getMockData(request: ApiRequest) {
  for (const mock of mocks) {
    if (mock.shouldMock(request)) {
      return mock.getResponse(request) ?? null // null to prevent api pass-through
    }
  }
  return undefined
}
