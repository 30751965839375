import React from 'react'
import { Box, Button, Flex, Heading, Loading, Text } from '@workwhile/ui'
import { useEditorContext } from './hooks/useEditorContext'

export function QuizLoading() {
  const { metadata, stopGenerating } = useEditorContext()
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="40vh"
      p={4}
    >
      <Heading level={4}>Generating a quiz for {metadata?.title}</Heading>
      <Loading />
      <Text mt={2} mb={4}>
        This may take a few minutes.
      </Text>
      <Button variant="secondary" kind="medium" onClick={stopGenerating}>
        Stop generating
      </Button>
    </Flex>
  )
}
