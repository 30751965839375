import { useMemo } from 'react'
import { useCompanyPositions } from 'queries/company'

/**
 * Custom hook to get a company position by id.
 * @param positionId
 */
export function useCompanyPosition(positionId?: number) {
  const { data } = useCompanyPositions()

  const position = useMemo(() => {
    if (!data || !positionId) return null
    return data.find(
      (position) => position.id?.toString() === positionId.toString()
    )
  }, [data, positionId])

  return position
}
