import * as zod from 'zod'

export enum PaymentTypeEnum {
  Hourly = 'hourly',
  LumpSum = 'lump_sum',
}

export const paymentSchema = zod
  .object({
    allowZeroPay: zod.boolean().optional(),
    paymentType: zod.nativeEnum(PaymentTypeEnum, {
      required_error: 'Please select a payment type',
    }),
    pay: zod.coerce
      .number({ invalid_type_error: 'Please enter a number' })
      .gte(0, 'Please enter a valid amount'),
    shiftLeadPay: zod.coerce
      .number({ invalid_type_error: 'Please enter a number' })
      .gte(0, 'Please enter a valid amount')
      .optional(),
    bonus: zod.coerce
      .number({ invalid_type_error: 'Please enter a number' })
      .optional(),
    customBonus: zod.coerce
      .number({ invalid_type_error: 'Please enter a number' })
      .optional(),
  })
  .refine(
    (data) => {
      return data.allowZeroPay || data.pay > 0
    },
    { message: 'Please enter a non-zero pay amount', path: ['pay'] }
  )
  .refine(
    (data) => {
      return (
        data.allowZeroPay ||
        data.shiftLeadPay === undefined ||
        data.shiftLeadPay > 0
      )
    },
    { message: 'Please enter a non-zero pay amount', path: ['shiftLeadPay'] }
  )

export type PaymentForm = zod.infer<typeof paymentSchema>
