import React from 'react'
import { Flex, Loading, Text } from '@workwhile/ui'

type AsyncContentProps = {
  isLoading: boolean
  isError: boolean
  children: React.ReactNode
}

export const AsyncContent = ({
  isLoading,
  isError,
  children,
}: AsyncContentProps) => {
  if (isLoading) {
    return (
      <tr>
        <td scope="row" colSpan={8}>
          <Flex justifyContent="center" alignItems="center" py={4}>
            <Loading />
          </Flex>
        </td>
      </tr>
    )
  }

  if (isError) {
    return (
      <tr>
        <td scope="row" colSpan={8}>
          <Flex justifyContent="center" alignItems="center" py={4}>
            <Text fontWeight="medium" color="error">
              Unable to load this assignment. Please refresh the page or try
              again in a few minutes.
              <br />
              If you need assistance, please contact WorkWhile support.
            </Text>
          </Flex>
        </td>
      </tr>
    )
  }

  return <>{children}</>
}
