import React from 'react'
import {
  CalendarView,
  CalendarViewProvider,
  AssignmentWorkflowsProvider,
} from './Calendar'

export const ScheduleView = () => {
  return (
    <CalendarViewProvider>
      <AssignmentWorkflowsProvider>
        <CalendarView />
      </AssignmentWorkflowsProvider>
    </CalendarViewProvider>
  )
}
