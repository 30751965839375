import React from 'react'
import { Avatar, Box, Flex, useAppTheme } from '@workwhile/ui'

type BaseWorkerCardCellProps = {
  avatar?: {
    photoUrl?: string
    name?: string
  }
  title: React.ReactNode
}

export const BaseWorkerCardCell = ({
  avatar = {},
  title,
}: BaseWorkerCardCellProps) => {
  const theme = useAppTheme()
  return (
    <Box as="th">
      <Flex
        as="span"
        flexDirection="row"
        alignItems="center"
        borderStyle="solid"
        borderWidth="1px"
        borderColor={theme.colors.neutrals[100]}
        borderRadius={theme.radii.small}
        p={2}
        m={1}
        style={{
          gap: theme.space[2],
        }}
      >
        <Avatar
          size="small"
          user={{
            photoUrl: avatar?.photoUrl,
            name: avatar?.name,
          }}
          badge={null}
        />
        {title}
      </Flex>
    </Box>
  )
}
