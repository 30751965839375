import React from 'react'
import { useShiftEditor } from '../../useShiftEditor'
import { Box, Button, Modal, Paragraph, Table } from 'ui'
import { AudienceType } from '../../schemas'

const TEMPORARY_HOLD_SCHEDULE_COLUMNS = [
  {
    header: 'HOURS BETWEEN REQUEST AND SHIFT START	',
    accessorKey: 'hoursBetweenRequestAndShiftStart',
  },
  {
    header: 'HOLDING PERIOD',
    accessorKey: 'holdingPeriod',
  },
]

const TEMPORARY_HOLD_SCHEDULE_DATA = [
  {
    hoursBetweenRequestAndShiftStart: 'More than 24 hrs',
    holdingPeriod: '10hrs',
  },
  {
    hoursBetweenRequestAndShiftStart: 'Between 24hrs and 8hrs',
    holdingPeriod: '3hrs',
  },
  {
    hoursBetweenRequestAndShiftStart: 'Less than 8 hrs',
    holdingPeriod: '1hr',
  },
]

export function AudienceInfoModal() {
  const { shiftData } = useShiftEditor()
  const audienceType = shiftData?.staff?.audienceType

  if (
    !audienceType ||
    ![AudienceType.SpecificWorkers, AudienceType.Roster].includes(audienceType)
  ) {
    return null
  }

  return (
    <Modal
      width={[1, 600]}
      trigger={<Button variant={'text'}>Who can accept this shift?</Button>}
      title={'Who can accept this shift'}
      showCancel={false}
      onOk={({ closeModal }) => closeModal()}
    >
      {shiftData.staff?.restrictShiftToAudience ? (
        <>
          <Paragraph>
            You have chosen to only allow selected{' '}
            {audienceType === AudienceType.Roster ? 'lists' : 'workers'} to
            accept this shift. This may cause the shift to go unfilled.
          </Paragraph>
          <Paragraph>
            Switching from a permanent to temporary hold will give the selected{' '}
            {audienceType === AudienceType.Roster ? 'lists' : 'workers'}{' '}
            priority while balancing maintaining a high fill rate. To change
            this setting, please tap the edit button.
          </Paragraph>
        </>
      ) : (
        <>
          <Box>
            The {audienceType === AudienceType.Roster ? 'lists' : 'workers'} you
            select will have exclusive access to this shift for a holding
            period.&nbsp;
            {audienceType === AudienceType.Roster
              ? "If there are still open spots at the end of the holding period, we'll offer the remaining spots to other workers"
              : "If a worker does not accept before the holding period ends, we'll offer their spot to other workers"}
            via our proprietary Smart Shift Fulfillment algorithm.
          </Box>
          <Table
            mt={4}
            columns={TEMPORARY_HOLD_SCHEDULE_COLUMNS}
            data={TEMPORARY_HOLD_SCHEDULE_DATA}
          />
          <Box mt={4}>
            If you'd like to restrict the shift to the selected lists of workers
            only, please tap the edit button to change this setting.
          </Box>
        </>
      )}
    </Modal>
  )
}
