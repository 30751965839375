import React from 'react'
import EmptyWorkerAsset from 'assets/empty-workers.svg'
import { Box, Flex, Heading, Text } from 'ui'

export function WorkerEmptyState() {
  return (
    <Flex
      flexDirection={'column'}
      mt={-3}
      borderRadius={'standard'}
      backgroundColor={'emptyState'}
      px={3}
      py={6}
      alignItems={'center'}
    >
      <img
        src={EmptyWorkerAsset}
        style={{ maxWidth: '100%', height: 127 }}
        alt={'No workers'}
      />
      <Box width={[1, 'auto']} px={[0, 5]}>
        <Heading level={4} color={'lightText'} textAlign={'center'}>
          View or search for workers quickly
        </Heading>
        <Text color={'lightText'} textAlign={'center'}>
          Workers that have worked a shift with you or those that have accepted
          an invitation will appear here.
        </Text>
      </Box>
    </Flex>
  )
}
