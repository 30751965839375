import { useMutation } from '@tanstack/react-query'
import { useCompanyInfo } from '../company'
import { updateSpendLimit } from 'api/payment'

interface UseUpdateSpendLimitMutation {
  onSuccess?: () => void
  onError?: (err: Error) => void
}

export function useUpdateSpendLimitMutation(
  options?: UseUpdateSpendLimitMutation
) {
  const { data: company } = useCompanyInfo()

  return useMutation({
    mutationFn: async (limit: number) => {
      if (!company?.id) {
        throw new Error('Company id not found')
      }
      return updateSpendLimit(company.id, limit)
    },
    ...options,
  })
}
