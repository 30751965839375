export const prodConfig = {
  bugsnagApiKey: 'd485e370149f6b9b78cb61bb162bc08e',
  bugsnagReleaseStage: 'prod',
  apiHostUrl: 'https://api.workwhilejobs.com/v1',
  graphqlHostUrl: 'https://api.workwhilejobs.com/graphql',
  intercomAppId: 'ozmz3fh8',
  statsigApiKey: 'client-CxBrRSAPOy78E7hQr6TLRq85o7ESRxkgtfuN5hOYWj0',
  firebaseConfig: {
    apiKey: 'AIzaSyDkAPtzfcldOGgX8eB0oMKBhsAJ_KKoTAA',
    authDomain: 'workwhile-247820.firebaseapp.com',
    databaseURL: 'https://workwhile-247820.firebaseio.com',
    projectId: 'workwhile-247820',
    storageBucket: '',
    messagingSenderId: '280730972481',
    appId: '1:280730972481:web:f5dec81a827a2396',
  },
  googleMapsApiKey: 'AIzaSyDseZX6cQmFDv1rLXoS_hAnY7kHmbLp-Gs',
  stripePublishableKey: 'pk_live_21rLL4GCKBEtHciXZuOeXF7T00o8DzqQhj',
  amplitudeApiKey: '3e6571bd5176861da91f8c3a33ae2795',
}

export type EnvConfig = typeof prodConfig
