import { TimelineShift } from 'api/shift'
import { formatInTimeZone } from 'date-fns-tz'
import { getShiftTimezone } from 'lib/shift'
import {
  LucideBriefcase,
  LucideCalendarClock,
  LucideToggleLeft,
} from 'lucide-react'
import { ShiftDetailProvider } from 'pages/ShiftDetailPage/ShiftDetailProvider'
import React from 'react'
import { Flex, Box, Paragraph, Link } from 'ui'
import { TryoutReviewShiftRows } from './TryoutReviewShiftRows'
import { getAssignedWork, shouldShowApproveButton } from 'lib/work'

interface Props {
  shift: TimelineShift
}

export const TryoutReviewShift = ({ shift }: Props) => {
  const timezone = getShiftTimezone(shift)
  const startDate = new Date(shift.startsAt)
  const endDate = new Date(shift.endsAt)

  const workList = shift.work || []
  const assignedWorkers = getAssignedWork(workList)
  const rowsForApproval = assignedWorkers.filter((work) =>
    shouldShowApproveButton(work)
  )

  return (
    <Box>
      <Flex
        flexDirection={['column', 'column', 'row']}
        alignItems={['flex-start', 'flex-start', 'center']}
        mb={2}
      >
        <Flex flexDirection="row" alignItems="center">
          <LucideBriefcase size={20} />
          <Paragraph fontWeight="bold" ml={2} mr={4}>
            {shift?.position?.name} - {shift?.location?.name}
          </Paragraph>
        </Flex>
        <Flex flexDirection="row" alignItems="center">
          <LucideCalendarClock size={20} />
          <Paragraph fontWeight="bold" ml={2} mr={4}>
            {formatInTimeZone(startDate, timezone, 'EEE, MMM d')}
            {' - '}
            {formatInTimeZone(startDate, timezone, 'h:mma')} -{' '}
            {formatInTimeZone(endDate, timezone, 'h:mma zzz')}{' '}
            {shift?.lunchLength && shift.lunchLength > 0
              ? `• Unpaid Break: ${shift?.lunchLength}mins`
              : ''}
          </Paragraph>
        </Flex>
        <Flex flexDirection="row" alignItems="center">
          <LucideToggleLeft size={20} />
          <Paragraph fontWeight="bold" ml={2} mr={4}>
            Qualifying workers for the{' '}
            <Link to={`/workers/list/${shift.position?.roster?.id}`}>
              {shift.position?.roster?.name}
            </Link>
          </Paragraph>
        </Flex>
      </Flex>
      <ShiftDetailProvider shiftId={shift.id}>
        <TryoutReviewShiftRows rowsToSkeleton={rowsForApproval} />
      </ShiftDetailProvider>
    </Box>
  )
}
