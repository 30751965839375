import React, { useMemo } from 'react'
import { Box, Button, Flex, Loading, Message, Text } from '@workwhile/ui'
import { useShiftDetail } from 'pages/ShiftDetailPage/useShiftDetail'
import { getTutorialMetadata } from 'lib/tutorial'
import { useShiftTutorials } from 'queries/shift'
import { ClipboardXIcon } from 'lucide-react'
import { useNavigate } from 'react-router'

export function TutorialsInfo() {
  const navigate = useNavigate()
  const { shift } = useShiftDetail()
  const { data, isLoading } = useShiftTutorials(shift?.id as number)
  const tutorials = useMemo(() => {
    if (!data?.tutorials) {
      return []
    }

    return data.tutorials.map(
      (tutorial) => getTutorialMetadata(tutorial.category)?.title
    )
  }, [data])

  if (!shift) {
    return null
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <Message mt={4} variant={'info'} systemLevel={true} hideIcon={true}>
      <Flex alignItems={'center'}>
        <Flex flex={1} pr={3}>
          <ClipboardXIcon size={20} />
          <Box ml={2}>
            <Text style={{ flex: 1 }}>
              {tutorials.length > 0
                ? `There are ${tutorials.length} Instructions for this shift.`
                : 'There are no Instructions for this shift.'}
            </Text>
            <Text mt={1} fontWeight={2} fontSize={1}>
              {tutorials.join(', ')}
            </Text>
          </Box>
        </Flex>
        <Button
          kind={'medium'}
          onClick={() => navigate(`/shift/${shift.id}/instructions`)}
        >
          {tutorials.length > 0 ? 'Edit' : 'Add'} Instructions
        </Button>
      </Flex>
    </Message>
  )
}
