import { useCompanyInfo } from '../company'
import { useMutation } from '@tanstack/react-query'
import { removeWorkerFromRoster } from 'api/worker'

interface UseRemoveWorkerFromRosterMutation {
  rosterId: number
  onSuccess?: () => void
  onError?: (err: Error) => void
}

export function useRemoveWorkerFromRosterMutation(
  options: UseRemoveWorkerFromRosterMutation
) {
  const { data: company } = useCompanyInfo()

  return useMutation({
    mutationFn: async (workerId: number) => {
      if (!company?.id) {
        throw new Error('Company id not found')
      }

      return removeWorkerFromRoster(company.id, options.rosterId, workerId)
    },
    ...options,
  })
}
