import { getInsight, InsightOptions } from 'api/analytics'
import { useQuery } from '@tanstack/react-query'
import { insightKey } from './keys'

type UseInsight = InsightOptions

export function useInsight(options: UseInsight) {
  const { startDate, endDate, locationId, positionId } = options

  return useQuery({
    queryKey: insightKey(startDate, endDate, locationId, positionId),
    queryFn: () =>
      getInsight({
        startDate,
        endDate,
        locationId,
        positionId,
      }),
  })
}
