import React, { useMemo } from 'react'
import { Box, Select, SelectProps, Text } from 'ui'
import { components } from 'react-select'
import { useCompanyPositions } from 'queries/company'

interface Props extends SelectProps {
  includeAll?: boolean
}

const ALL_POSITION_OPTION = {
  label: 'All Positions',
  value: 0,
}

export function PositionSelector(props: Props) {
  const { includeAll, ...otherProps } = props
  const { data, isLoading } = useCompanyPositions()

  const options = useMemo(() => {
    if (!data) return []
    const optionItems = data
      .filter((p) => !p.hidden)
      .map((item) => ({
        label: item.name,
        value: Number(item.id),
      }))

    if (includeAll) {
      return [ALL_POSITION_OPTION, ...optionItems]
    }

    return optionItems
  }, [data, includeAll])

  const value = useMemo(() => {
    return options.find((option) => option.value === props.value)
  }, [props.value, options])

  return (
    <Select
      aria-label={'Position Selector'}
      options={options}
      {...otherProps}
      isLoading={isLoading}
      isMulti={false}
      value={value}
      components={{
        NoOptionsMessage: (msgProps) => (
          <components.NoOptionsMessage {...msgProps}>
            <Text color={'primary'} textAlign={'left'}>
              There are no positions yet. Add a new one.
            </Text>
          </components.NoOptionsMessage>
        ),
      }}
    />
  )
}
