import { useCompanyInfo } from '../company'
import { useMutation } from '@tanstack/react-query'
import { updateShiftRosterHold } from 'api/shift'
import { AxiosError } from 'axios'

export function useUpdateShiftRosterHoldMutation(options: {
  shiftId: number
  onSuccess?: () => void
  onError?: (err: AxiosError) => void
}) {
  const { shiftId, ...otherOptions } = options
  const { data: company } = useCompanyInfo()

  return useMutation({
    mutationFn: async (holdType: 'permanent' | 'temporary') => {
      if (!company?.id) {
        return
      }

      return updateShiftRosterHold(company.id, shiftId, holdType)
    },
    ...otherOptions,
  })
}
