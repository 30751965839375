import React, { useMemo } from 'react'
import { Select, SelectProps } from 'ui'
import {
  getDurationFromTimeStrings,
  getLocalTimezone,
  QUARTER_HOUR_INTERVAL_OPTIONS,
} from 'lib/time'

interface TimeSelectorProps extends SelectProps {
  from?: string
  date?: Date
  timezone?: string
  showDuration?: boolean
}

interface OptionWithDuration {
  value: string
  label: string
  totalDuration?: number
}

export function TimeSelector(props: TimeSelectorProps) {
  const {
    from,
    date,
    timezone = getLocalTimezone(),
    showDuration,
    ...otherProps
  } = props

  const options = useMemo(() => {
    const fromIdx = from
      ? QUARTER_HOUR_INTERVAL_OPTIONS.findIndex(
          (option) => option.value === from
        )
      : 0
    const fromValue = QUARTER_HOUR_INTERVAL_OPTIONS[fromIdx].value as string
    // make sure the first option is the from value
    const toOptions = [
      ...QUARTER_HOUR_INTERVAL_OPTIONS.slice(fromIdx),
      ...QUARTER_HOUR_INTERVAL_OPTIONS.slice(0, fromIdx),
    ]
    if (!showDuration) {
      return toOptions
    }
    return toOptions.map((option) => {
      if (!option.value) return option
      const durationInMinutesMinusLunch = getDurationFromTimeStrings(
        date || new Date(),
        fromValue,
        option.value,
        timezone
      )
      return {
        ...option,
        totalDuration: durationInMinutesMinusLunch / 60,
      }
    }) as OptionWithDuration[]
  }, [from, showDuration, timezone, date])

  // if from changes, we need to update value with updated duration
  const optionValue = useMemo(() => {
    if (!props.value) {
      return
    }
    return options.find((option) => {
      return option.value === (props.value as { value: string }).value
    })
  }, [props.value, options])

  return (
    <Select
      options={options}
      {...otherProps}
      isMulti={false}
      value={optionValue}
      formatOptionLabel={(option) => {
        const optionWithDuration = option as OptionWithDuration
        return (
          <div data-value={optionWithDuration.value}>
            {optionWithDuration.label}
            {showDuration && optionWithDuration.totalDuration !== undefined ? (
              <strong style={{ marginLeft: '0.5rem' }}>
                ({optionWithDuration.totalDuration} hrs)
              </strong>
            ) : null}
          </div>
        )
      }}
    />
  )
}
