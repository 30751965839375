import { useAuthContext } from 'components/auth'
import { useMutation } from '@tanstack/react-query'
import {
  removeLocationTutorial,
  RemoveLocationTutorialRequest,
} from 'api/company'

type MutationPayload = Pick<RemoveLocationTutorialRequest, 'tutorialId'>

export function useRemoveLocationTutorialMutation(options: {
  locationId: number
  onSuccess?: () => void
  onError?: (error: Error) => void
}) {
  const { locationId, onSuccess, onError } = options
  const companyId = useAuthContext().company?.id as number
  return useMutation({
    mutationFn: (request: MutationPayload) =>
      removeLocationTutorial({ ...request, companyId, locationId }),
    onSuccess,
    onError,
  })
}
