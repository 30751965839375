import React, { useEffect } from 'react'
import { track } from 'lib/amplitude'
import { Flex, Box } from 'ui'
import { Photography } from '../Photography'
import { VerifyMFAForm } from './VerifyMFAForm'

export function VerifyMFA() {
  useEffect(() => {
    track('impression, verify_mfa')
  }, [])

  return (
    <Flex height={'100vh'} flexDirection={'row'}>
      <Box
        position={'relative'}
        width={[0, 0, 0, 0, 750]}
        display={['none', 'none', 'none', 'none', 'block']}
      >
        <Photography />
      </Box>
      <Box
        display={'grid'}
        flex={1}
        minHeight={'100vh'}
        p={[3, 3, 3, 3, 4]}
        overflow={'auto'}
      >
        <VerifyMFAForm />
      </Box>
    </Flex>
  )
}
