import React, { ReactNode, useMemo, useState } from 'react'
import {
  Box,
  Button,
  DatePicker,
  getDatesArray,
  Flex,
  Modal,
  Text,
  Loading,
  Table,
  createColumnHelper,
  useResponsiveValue,
} from 'ui'
import { useHomeContext } from '../useHomeContext'
import { LocationSelector } from 'components/LocationSelector'
import { SelectableOption } from 'typings/selectable_ext'
import { PositionSelector } from 'components/PositionSelector'
import { useShiftCSV } from 'queries/shift'
import { usePreviewData, PreviewDataRow } from './usePreviewData'
import styled from 'styled-components'
import { format } from 'date-fns'
import fileDownload from 'js-file-download'
import { CalendarSearchIcon } from 'lucide-react'

const maxPreviewRows = 10

const EmptyContainer = styled(Flex).attrs({
  flexDirection: 'column',
  py: [5, 7],
  px: [4, 7],
  color: 'lightText',
  alignItems: 'center',
})``

const columnHelper = createColumnHelper<PreviewDataRow>()
const columns = [
  columnHelper.accessor('workerName', {
    header: 'Worker',
  }),
  columnHelper.accessor('position', {
    header: 'Position',
  }),
  columnHelper.accessor('location', {
    header: 'Location',
  }),
  columnHelper.accessor('startsAt', {
    header: 'Starts',
  }),
]
export function CSVModal(props: { trigger: ReactNode }) {
  const { locationId: initialLocationId, positionId: initialPositionId } =
    useHomeContext()
  const [dates, setDates] = useState<Date[]>([])
  const [locationId, setLocationId] = useState<number | undefined>(
    initialLocationId
  )
  const [positionId, setPositionId] = useState<number | undefined>(
    initialPositionId
  )
  const { data, isFetching } = useShiftCSV({
    startDate: dates[0],
    endDate: dates[dates.length - 1],
    locationId: locationId === 0 ? undefined : locationId,
    positionId: positionId === 0 ? undefined : positionId,
  })
  const previewData = usePreviewData(data)
  const fileName = useMemo(() => {
    if (dates.length === 0) return ''

    const baseName = 'workwhile_shifts_report'
    if (dates.length === 1) {
      return `${baseName}_${format(dates[0], 'yyMMdd')}.csv`
    }

    return `${baseName}_${format(dates[0], 'yyMMdd')}-${format(
      dates[dates.length - 1],
      'yyMMdd'
    )}.csv`
  }, [dates])
  const overflowRows = Math.max(previewData.length - maxPreviewRows, 0)
  const tableColumns = useResponsiveValue([columns.slice(0, 3), columns])

  const resetData = () => {
    setDates([])
  }

  const onDownloadClick = () => {
    fileDownload(data, fileName)
  }

  return (
    <Modal
      title={'Download CSV'}
      trigger={props.trigger}
      width={[1, 900]}
      onClose={resetData}
      customCta={
        <>
          <Button
            disabled={previewData.length === 0}
            width={150}
            onClick={onDownloadClick}
          >
            Download
          </Button>
        </>
      }
    >
      <Text mb={2}>Select and apply filters below to generate a CSV.</Text>
      <Flex flexWrap={'wrap'}>
        <Box width={[1, 1 / 3]} pr={[0, 2]}>
          <LocationSelector
            value={locationId ?? 0}
            includeAll={true}
            placeholder={'Select Location'}
            onChange={(newValue) => {
              const { value } = newValue as SelectableOption
              setLocationId(value !== undefined ? Number(value) : undefined)
            }}
          />
        </Box>
        <Box width={[1, 1 / 3]} pr={[0, 2]} my={[2, 0]}>
          <PositionSelector
            value={positionId ?? 0}
            includeAll={true}
            placeholder={'Select Position'}
            onChange={(newValue) => {
              const { value } = newValue as SelectableOption
              setPositionId(value !== undefined ? Number(value) : undefined)
            }}
          />
        </Box>
        <Box width={[1, 1 / 3]}>
          <DatePicker
            mode={'range'}
            placeholder={'Date'}
            selectCta={'Continue'}
            onSelect={(dates) => {
              if (dates) {
                setDates(getDatesArray(dates))
              }
            }}
          />
        </Box>
      </Flex>
      {isFetching ? (
        <EmptyContainer>
          <Loading />
        </EmptyContainer>
      ) : null}
      {!isFetching && !data ? (
        <EmptyContainer>
          <CalendarSearchIcon size={50} />
          <Text mt={3} textAlign={'center'}>
            Select a date at minimum to view shifts to select from.
          </Text>
        </EmptyContainer>
      ) : null}
      {data && previewData.length === 0 ? (
        <EmptyContainer>No data found for the selected filters.</EmptyContainer>
      ) : null}
      {previewData.length > 0 ? (
        <Flex py={4} minHeight={300} alignItems={'flex-start'}>
          <Table
            variant={'borderless'}
            width={'100%'}
            columns={tableColumns}
            data={previewData.slice(0, maxPreviewRows)}
          />
        </Flex>
      ) : null}
      {overflowRows > 0 ? (
        <Text textAlign={'right'} fontWeight={2}>
          +{overflowRows} more
        </Text>
      ) : null}
    </Modal>
  )
}
