import { WorkShift, Work } from 'typings/common_defs'
import { WorkStatusEnum } from './shiftTimeline'

export interface ShiftSimple
  extends Pick<
    WorkShift,
    | 'isTryout'
    | 'id'
    | 'endsAt'
    | 'expectedMileage'
    | 'supervisors'
    | 'workersNeeded'
    | 'payRate'
    | 'payLumpSum'
    | 'lunchLength'
    | 'shiftBonuses'
    | 'position'
    | 'location'
    | 'locationless'
    | 'cancellationPolicy'
    | 'rosters'
  > {
  startsAt: string
  work?: Array<Omit<Work, 'status'> & { status?: WorkStatusEnum }>
}

export type ShiftEditData = ShiftSimple &
  Pick<
    WorkShift,
    'rosters' | 'rostersHoldExpiresAt' | 'unableToAttendWork' | 'work'
  >

export enum TutorialCategory {
  General = 'general',
  ArrivalAndParking = 'arrival_and_parking',
  AttireAndGrooming = 'attire_and_grooming',
  ClockIn = 'clock_in',
  ClockOut = 'clock_out',
}

export const AllTutorialCategories = [
  TutorialCategory.General,
  TutorialCategory.ArrivalAndParking,
  TutorialCategory.AttireAndGrooming,
  TutorialCategory.ClockIn,
  TutorialCategory.ClockOut,
]

export interface MarkdownContent {
  markdown: string
}

export interface QuizAnswer {
  label: string
  value: string
}

export interface QuizQuestion {
  key: string
  type: 'radio-group'
  label: string
  values: QuizAnswer[]
  correct_value: string
}

export interface Tutorial {
  id: number
  name: string
  category?: TutorialCategory
  content: MarkdownContent[]
  quiz?: QuizQuestion[]
  companyId?: number
}
