import React, {
  forwardRef,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Status, Wrapper } from '@googlemaps/react-wrapper'
import { Box, Input } from 'ui'
import { useDebounce } from 'ahooks'
import * as Popover from '@radix-ui/react-popover'
import styled from 'styled-components'
import { PlaceSuggestion } from './usePlaceSuggestionInfo'
import { config } from 'config'
export * from './usePlaceSuggestionInfo'
export * from './useAddressInfo'
export type { PlaceInfo } from './utils'
export { geocodeFromAddress } from './utils'

export type PlaceType = 'address'

interface Props {
  type?: PlaceType
  name?: string
  autoFocus?: boolean
  placeholder?: string
  disabled?: boolean
  value?: unknown
  onChange?: (value: string) => void
  onSelect?: (suggestion: PlaceSuggestion | null) => void
  useSelectValue?: boolean
}

const Container = styled(Box)`
  ${({ theme: { colors, radii } }) => `
    .popover-content {
      animation-duration: 0;
      border: 1px solid ${colors.neutrals[200]};
      border-radius: ${radii.small};
      width: var(--radix-popover-trigger-width);
      background-color: ${colors.white};
      padding: 0.5rem 0;
    }
  `}
`

const SuggestionItem = styled(Box)<{ $hasCursor: boolean }>`
  ${({ theme: { colors, radii }, $hasCursor }) => `
    padding: 0.8rem 1rem;
    &:hover {
      background-color: ${colors.primaries[50]};
    }
    
    ${
      $hasCursor
        ? `
      background-color: ${colors.primary};
      color: ${colors.white};
    `
        : ''
    }
  `}
`

export const AutocompleteAddress = forwardRef((props: Props, ref) => {
  const {
    type = 'address',
    value,
    onChange,
    useSelectValue = true,
    onSelect,
    ...inputProps
  } = props
  const [internalValue, setInternalValue] = useState(
    (value as string | undefined) ?? ''
  )
  const debouncedKeyword = useDebounce(internalValue.trim(), { wait: 300 })
  const [suggestions, setSuggestions] = useState<PlaceSuggestion[]>([])
  const [open, setOpen] = useState(false)
  const [service, setService] =
    useState<google.maps.places.AutocompleteService>()
  const inputRef = useRef<HTMLInputElement | null>()
  const [selected, setSelected] = useState<PlaceSuggestion | null>(null)
  const [cursorIndex, setCursorIndex] = useState<number | null>(null)
  const [enabled, setEnabled] = useState(false)

  // on external value change
  useEffect(() => {
    const externalValue = (value as string | undefined) ?? ''
    setInternalValue((prevValue) => {
      if (externalValue !== prevValue) {
        setEnabled(false)
        return externalValue
      }

      return prevValue
    })
  }, [value])

  useEffect(() => {
    setInternalValue((prevValue) => {
      const keyword = prevValue.trim()
      if (
        enabled &&
        keyword &&
        service &&
        (!selected || selected.description !== keyword)
      ) {
        setCursorIndex(null)
        service.getPlacePredictions(
          {
            input: keyword,
            types: [type],
            componentRestrictions: { country: 'us' },
            // fields: ['address_components', 'formatted_address'],
          },
          (predictions) => {
            if (predictions) {
              const options = predictions.map((prediction) => ({
                description: prediction.description,
                placeId: prediction.place_id,
              }))

              setSuggestions(options)

              if (
                options.length > 0 &&
                (!selected || selected.placeId !== options[0].placeId)
              ) {
                setOpen(true)
              }
            }
          }
        )
      } else {
        setSuggestions([])
      }
      return prevValue
    })
  }, [type, service, debouncedKeyword, selected, enabled])

  const setSelection = (suggestion: PlaceSuggestion) => {
    setOpen(false)
    setSelected(suggestion)
    if (useSelectValue) {
      setInternalValue(suggestion.description)
    }
    setSuggestions([])
    setCursorIndex(null)
    if (onSelect) {
      onSelect(suggestion)
    }
  }

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (['ArrowUp', 'ArrowDown', 'Enter'].includes(e.key)) {
      e.preventDefault()
    }

    if (e.key === 'ArrowUp') {
      if (cursorIndex === null) {
        setCursorIndex(suggestions.length - 1)
      } else {
        setCursorIndex(
          cursorIndex === 0 ? suggestions.length - 1 : cursorIndex - 1
        )
      }
    }

    if (e.key === 'ArrowDown') {
      if (cursorIndex === null) {
        setCursorIndex(0)
      } else {
        setCursorIndex(
          cursorIndex < suggestions.length - 1 ? cursorIndex + 1 : 0
        )
      }
    }

    if (e.key === 'Enter') {
      if (cursorIndex !== null && suggestions[cursorIndex]) {
        setSelection(suggestions[cursorIndex])
      }
    }
  }

  return (
    <Wrapper
      apiKey={config.googleMapsApiKey}
      libraries={['geocoding', 'places']}
      callback={(status) => {
        if (status === Status.SUCCESS) {
          setService(new google.maps.places.AutocompleteService())
        }
      }}
    >
      <Container>
        <Popover.Root open={open} onOpenChange={(state) => setOpen(state)}>
          <Popover.Anchor>
            <Input
              block={true}
              {...inputProps}
              value={internalValue}
              className={'autocomplete-input'}
              autoComplete={'off'}
              ref={(node) => {
                inputRef.current = node
                if (typeof ref === 'function') {
                  ref(node)
                }
              }}
              onChange={(e) => {
                setEnabled(true)
                setInternalValue(e.currentTarget.value)
                if (onChange) onChange(e.currentTarget.value)
              }}
              onKeyDown={handleInputKeyDown}
              onFocus={() => {
                if (suggestions.length > 0) {
                  setOpen(true)
                }
              }}
            />
          </Popover.Anchor>
          {suggestions.length ? (
            <Popover.Content
              className={'popover-content'}
              align={'start'}
              sideOffset={5}
              style={{ zIndex: 10001 }}
              onOpenAutoFocus={(e) => e.preventDefault()}
              onInteractOutside={(e) => {
                if (e.currentTarget === inputRef.current) {
                  e.preventDefault()
                }
              }}
            >
              {suggestions.map((suggestion, index) => (
                <SuggestionItem
                  key={suggestion.placeId}
                  $hasCursor={cursorIndex === index}
                  onClick={() => {
                    setSelection(suggestion)
                  }}
                >
                  {suggestion.description}
                </SuggestionItem>
              ))}
            </Popover.Content>
          ) : null}
        </Popover.Root>
      </Container>
    </Wrapper>
  )
})
