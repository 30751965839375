import React from 'react'
import {
  DEFAULT_CLOCK_IN_RADIUS_METER,
  LocationForm,
  locationSchema,
} from './schema'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAddressInfo } from './useAddressInfo'
import { Flex, Box, Field, Input, ModalCtaContainer, Button, Message } from 'ui'
import { AutocompleteAddress } from '../AutocompleteAddress'
import { Map } from '../Map'
import { ClockInOverrides } from './ClockInOverrides'
import { LocationRequest } from 'api/company'

interface Props {
  data?: LocationForm
  onSubmit: (data: LocationRequest) => void
  onCancel?: () => void
  error: Error | null
}

export function EditLocationForm(props: Props) {
  const { data, onSubmit, onCancel, error } = props
  const methods = useForm<LocationForm>({
    resolver: zodResolver(locationSchema),
    defaultValues: data,
  })
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = methods
  const {
    setSuggestion,
    lat,
    long,
    clockInLat,
    clockInLong,
    clockInRadius,
    mapId,
  } = useAddressInfo(methods)

  const onFormSubmit = handleSubmit((data) => {
    const submitData = {
      name: data.name,
      street: data.address,
      city: data.city,
      state: data.state,
      zip: data.zipCode,
      unit: data.unit,
      clockInLat: data.clockInLat ?? data.lat,
      clockInLong: data.clockInLong ?? data.long,
      clockInRadius: data.clockInRadius ?? DEFAULT_CLOCK_IN_RADIUS_METER,
    }

    onSubmit(submitData)
  })

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={(e) => {
          e.stopPropagation()
          onFormSubmit(e)
        }}
      >
        <Flex flexDirection={'column'} maxHeight={['70vh', '80vh']}>
          <Box flex={1} overflow={'auto'} mx={-4} px={4}>
            <Flex flexWrap={'wrap'}>
              <Box width={[1, 3 / 5]}>
                <Flex flexWrap={'wrap'}>
                  <Field
                    width={[1, 2 / 3]}
                    pr={[0, 3]}
                    label={'Address'}
                    error={errors.address?.message?.toString()}
                  >
                    <Controller
                      name={'address'}
                      control={control}
                      render={({ field }) => (
                        <AutocompleteAddress
                          {...field}
                          autoFocus={true}
                          useSelectValue={false}
                          onSelect={(suggestion) => setSuggestion(suggestion)}
                          type={'address'}
                          placeholder={'Enter Address'}
                        />
                      )}
                    />
                  </Field>
                  <Field
                    width={[1, 1 / 3]}
                    label={'Unit'}
                    error={errors.unit?.message?.toString()}
                  >
                    <Input {...register('unit')} placeholder={'Unit #'} />
                  </Field>
                </Flex>
                <Flex flexWrap={'wrap'}>
                  <Field
                    width={[1, 1 / 3]}
                    pr={[0, 3]}
                    label={'City'}
                    error={errors.city?.message?.toString()}
                  >
                    <Input {...register('city')} placeholder={'Enter City'} />
                  </Field>
                  <Field
                    width={[1, 1 / 3]}
                    pr={[0, 3]}
                    label={'Zip Code'}
                    error={errors.zipCode?.message?.toString()}
                  >
                    <Input
                      {...register('zipCode')}
                      placeholder={'Enter Zip Code'}
                    />
                  </Field>
                  <Field
                    width={[1, 1 / 3]}
                    label={'State'}
                    error={errors.state?.message?.toString()}
                  >
                    <Input {...register('state')} placeholder={'Enter State'} />
                  </Field>
                </Flex>
                <Field
                  width={1}
                  label={'Location Name'}
                  error={errors.name?.message?.toString()}
                >
                  <Input
                    {...register('name')}
                    maxLength={40}
                    placeholder={'Enter Location Name'}
                  />
                </Field>
              </Box>
              <Box width={[1, 2 / 5]} pl={[0, 3]} pb={3}>
                <Map
                  height={[300, '100%']}
                  mb={3}
                  zoom={lat && long ? 16 : 3}
                  center={
                    lat && long
                      ? { lat, lng: long }
                      : { lng: -95.71255, lat: 37.092338 }
                  }
                  markers={
                    lat && long ? [{ position: { lat, lng: long } }] : undefined
                  }
                  circle={
                    clockInLat && clockInLong && clockInRadius
                      ? {
                          lat: clockInLat,
                          lng: clockInLong,
                          radius: Number(clockInRadius),
                          onCenterChange: (center) => {
                            setValue('clockInLat', center.lat)
                            setValue('clockInLong', center.lng)
                          },
                        }
                      : undefined
                  }
                />
              </Box>
            </Flex>
            <ClockInOverrides />
          </Box>
          <ModalCtaContainer>
            {error ? (
              <Message flex={['auto', 1]} variant={'error'} mb={[2, 0]}>
                {error.message}
              </Message>
            ) : null}
            <Button variant={'text'} onClick={onCancel}>
              Cancel
            </Button>
            <Button type={'submit'} kind={'medium'}>
              {data ? 'Save Location' : 'Add Location'}
            </Button>
          </ModalCtaContainer>
        </Flex>
      </form>
    </FormProvider>
  )
}
