import { useMutation } from '@tanstack/react-query'
import { createNewRoster, NewRosterRequest } from 'api/worker'
import { useCompanyInfo } from './useCompanyInfo'

interface UseCreateRosterMutation {
  onSuccess?: () => void
}

export function useCreateRosterMutation(options?: UseCreateRosterMutation) {
  const { data: company } = useCompanyInfo()

  return useMutation({
    mutationFn: async (data: NewRosterRequest) => {
      if (!company?.id) {
        throw new Error('Company id not found')
      }
      return createNewRoster(company.id, data)
    },
    ...options,
  })
}
