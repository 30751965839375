import React from 'react'
import { useEditorContext } from './hooks/useEditorContext'
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Loading,
  Message,
  Paragraph,
} from '@workwhile/ui'
import { PlusIcon } from 'lucide-react'
import { QuizQuestionCard } from './QuizQuestionCard'
import { QuizPreview } from './QuizPreview'
import { SaveBox } from './SaveBox'
import { QuizLoading } from './QuizLoading'

export function QuizEditor() {
  const { quiz, addQuizQuestion, isGeneratingQuiz, isGeneratedQuiz } =
    useEditorContext()

  if (isGeneratingQuiz) {
    return <QuizLoading />
  }
  const title = isGeneratedQuiz ? 'Review & Edit Your Quiz' : 'Edit Your Quiz'
  return (
    <Box>
      <Heading level={3}>{title}</Heading>
      <Flex>
        <Box flex={1} mr={[0, 0, 0, 4, 7]}>
          {isGeneratedQuiz ? (
            <>
              <Message variant={'info'} py={3}>
                This quiz was generated by Artificial Intelligence and may
                contain information that is incorrect.
              </Message>
              <Paragraph pb={2}>
                <ol style={{ paddingInlineStart: 0 }}>
                  <li>1. Please review the quiz carefully.</li>
                  <br />
                  <li>2. To edit, simply click the screen.</li>
                  <br />
                  <li>3. When you're done, please click "Save" below.</li>
                </ol>
              </Paragraph>
            </>
          ) : (
            <Message variant={'info'} py={3}>
              Sorry, we couldn’t auto generate a quiz for you. Please add a
              question manually.
            </Message>
          )}
          {(quiz || []).map((question) => (
            <QuizQuestionCard question={question} key={question.key} />
          ))}
          <Button variant={'text'} onClick={addQuizQuestion}>
            <Icon icon={PlusIcon} size={16} />
            &nbsp;Add Question
          </Button>
          <SaveBox />
        </Box>
        <Box display={['none', 'none', 'none', 'block']}>
          <Box position={'sticky'} top={60}>
            <QuizPreview />
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}
