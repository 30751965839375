import styled from 'styled-components'
import { Box } from '@workwhile/ui'

export const TooltipBox = styled(Box).attrs({
  backgroundColor: 'infos.50',
  color: 'infos.200',
  px: 3,
  py: 2,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'infos.200',
  borderRadius: 'standard',
})``
