import React from 'react'
import { useAnalyticsContext } from '../useAnalyticsContext'
import { Box, Flex, Heading, Text } from 'ui'
import { RatingInfo } from './RatingInfo'

export function RatingStats() {
  const { insight } = useAnalyticsContext()

  return (
    <Box mt={4}>
      {insight?.performance?.avgRating !== null ? (
        <Flex flexDirection={'row'} alignItems={'center'}>
          <Text fontSize={56} fontWeight={2} mr={3}>
            {insight?.performance?.avgRating}
          </Text>
          <Heading level={4} my={0}>
            Average
            <br />
            Review
          </Heading>
        </Flex>
      ) : null}
      <Box my={3}>
        <RatingInfo rating={5} count={insight?.performance?.totalFiveStar} />
        <RatingInfo rating={4} count={insight?.performance?.totalFourStar} />
        <RatingInfo rating={3} count={insight?.performance?.totalThreeStar} />
        <RatingInfo rating={2} count={insight?.performance?.totalTwoStar} />
        <RatingInfo rating={1} count={insight?.performance?.totalOneStar} />
      </Box>
    </Box>
  )
}
