import React, { useMemo } from 'react'
import { usePreviewData } from './usePreviewData'
import { addDays, format, isSameDay, startOfWeek } from 'date-fns'
import { Box, Flex } from 'ui'

export function WeekCalendar() {
  const { dates } = usePreviewData()
  const weekDays = useMemo(() => {
    const startDate = startOfWeek(dates[0], { weekStartsOn: 0 })
    return Array.from({ length: 7 }, (_, i) => addDays(startDate, i))
  }, [dates])

  return (
    <Flex flexDirection={'row'} justifyContent={'space-between'} mt={2}>
      {weekDays.map((d) => {
        const isSelected = dates.some((selectedDate) =>
          isSameDay(d, selectedDate)
        )

        return (
          <Flex
            flexDirection={'column'}
            key={d.toISOString()}
            fontSize={0}
            alignItems={'center'}
          >
            {format(d, 'eeeeee')}
            <Box
              borderRadius={'50%'}
              width={'2.3rem'}
              height={'2.3rem'}
              bg={isSelected ? 'primary' : 'none'}
              color={isSelected ? 'white' : 'text'}
              mt={1}
              p={1}
              textAlign={'center'}
            >
              {format(d, 'd')}
            </Box>
          </Flex>
        )
      })}
    </Flex>
  )
}
