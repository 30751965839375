import { Text } from 'ui'
import { formatInTimeZone } from 'date-fns-tz'
import { getLocationTimezone, getTimezoneName } from 'lib/time'
import React from 'react'
import { ShiftSimple } from 'api/shift'

interface Props {
  shift: Pick<ShiftSimple, 'startsAt' | 'endsAt'> & {
    location?: {
      address?: {
        timezone: string
      }
    }
  }
  shortVersion?: boolean
}

export function ShiftTime(props: Props) {
  const { shift, shortVersion } = props
  const { startsAt, endsAt, location } = shift
  const timezone = location?.address?.timezone || getLocationTimezone()

  if (!startsAt || !endsAt) {
    return null
  }

  if (shortVersion) {
    return (
      <Text>
        {formatInTimeZone(startsAt, timezone, 'h:mmaaa')} -&nbsp;
        {formatInTimeZone(endsAt, timezone, 'h:mmaaa zzz')}
      </Text>
    )
  }

  return (
    <>
      <Text>
        {formatInTimeZone(startsAt, timezone, 'h:mmaaa')} -&nbsp;
        {formatInTimeZone(endsAt, timezone, 'h:mmaaa')}
      </Text>
      <Text fontSize={1} color={'neutrals.300'}>
        {getTimezoneName(timezone)}
      </Text>
    </>
  )
}
