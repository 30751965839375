import { useAnalyticsContext } from '../useAnalyticsContext'
import { useMemo } from 'react'
import { format, isSameMonth, isSameYear, parse } from 'date-fns'
import { apiDateFormat } from 'api'
import {
  GOOD_FILL_RATE_PERCENT,
  GOOD_SHORT_LEAD_DAYS_PERCENTAGE,
} from 'lib/insight'
import { WeeklyFillRate } from 'typings/common_defs'

export type FillRateData = WeeklyFillRate & {
  fillRate: string
  delta: number
}

export function useFillRate() {
  const { insight, dates } = useAnalyticsContext()

  const data = useMemo(() => {
    if (!insight) return []

    return insight.weeklyFillRates.map((item) => ({
      ...item,
      delta: item.workersRequested - item.workersFilled,
      fillRate: (item.workersFilled / item.workersRequested).toFixed(2),
      weekStartDate: format(
        parse(item.weekStartDate, apiDateFormat, new Date()),
        'MMM d, yyyy'
      ),
    }))
  }, [insight]) as FillRateData[]

  const { totalWorkersRequested, totalWorkersFilled, averageFillRate } =
    useMemo(() => {
      const acc = data.reduce(
        (acc, item) => {
          acc.totalWorkersRequested += item.workersRequested
          acc.totalWorkersFilled += item.workersFilled
          return acc
        },
        {
          totalWorkersRequested: 0,
          totalWorkersFilled: 0,
        }
      )

      return {
        ...acc,
        averageFillRate: acc.totalWorkersFilled / acc.totalWorkersRequested,
      }
    }, [insight])

  const shouldShowRecs = averageFillRate * 100 < GOOD_FILL_RATE_PERCENT

  const leadDayRec = useMemo(() => {
    if (!insight || !shouldShowRecs) return null
    const avgShiftStats = insight?.avgShiftStats
    if (
      avgShiftStats.shortLeadDaysRate != null &&
      avgShiftStats.recommendedLeadDays != null &&
      avgShiftStats.shortLeadDaysRate > GOOD_SHORT_LEAD_DAYS_PERCENTAGE
    ) {
      return avgShiftStats.recommendedLeadDays
    }
    return null
  }, [shouldShowRecs, insight])

  const bonusRec = useMemo(() => {
    if (!insight || !shouldShowRecs) return null
    const avgShiftStats = insight?.avgShiftStats
    if (
      avgShiftStats.avgBonus != null &&
      avgShiftStats.recommendedBonus != null &&
      avgShiftStats.avgBonus < avgShiftStats.recommendedBonus
    ) {
      return avgShiftStats?.recommendedBonus
    }
    return null
  }, [shouldShowRecs, insight])

  const payRec = useMemo(() => {
    if (!insight || !shouldShowRecs) return null
    const avgShiftStats = insight?.avgShiftStats
    if (
      avgShiftStats.avgPay != null &&
      avgShiftStats.recommendedPay != null &&
      avgShiftStats.avgPay < avgShiftStats.recommendedPay
    ) {
      return avgShiftStats?.recommendedPay
    }
    return null
  }, [shouldShowRecs, insight])

  const dateRangeLabel = useMemo(() => {
    const from = dates.from
    const to = dates.to ?? dates.from
    if (!isSameYear(from, to)) {
      return `${format(from, 'MMM d, yyyy')} - ${format(to, 'MMM d, yyyy')}`
    }

    if (!isSameMonth(from, to)) {
      return `${format(from, 'MMM d')} - ${format(to, 'MMM d, yyyy')}`
    }

    return `${format(from, 'MMM d')} - ${format(to, 'd, yyyy')}`
  }, [dates])

  return {
    insight,
    data,
    dateRangeLabel,
    totalWorkersFilled,
    totalWorkersRequested,
    averageFillRate,
    leadDayRec,
    bonusRec,
    payRec,
  }
}
