import React from 'react'
import { Box, Message, ThemeProvider } from 'ui'

interface Props {
  clearError?: () => void
}

// top-level boundary when nothing at route level or more granular handled the exception
export function TopLevelErrorBoundary(props: Props) {
  const { clearError } = props

  return (
    <ThemeProvider>
      <Box p={4}>
        <Message variant={'error'}>
          Something went wrong. Please try again and contact support if you
          require further assistance.
        </Message>
        <Box mt={3}>
          <a onClick={clearError}>Click here</a> to try again.
        </Box>
      </Box>
    </ThemeProvider>
  )
}
