import { useContext } from 'react'
import { WorkerSearchContext } from './WorkerSearchProvider'

export function useWorkerSearch() {
  const context = useContext(WorkerSearchContext)
  if (!context) {
    throw new Error(
      'useWorkerSearch must be used within a WorkerSearchProvider'
    )
  }
  return context
}
