import { DemoApiMock } from '../types'
import { matchGraphqlRequest } from '../utils'
import { positionTemplateGroups } from '../data/positionTemplateGroups'

export const mockGetPositionTemplateGroups: DemoApiMock = {
  shouldMock: (request) =>
    matchGraphqlRequest(request, /company\{positionTemplateGroups/),
  getResponse: async () => {
    return {
      data: {
        company: {
          positionTemplateGroups,
        },
      },
    }
  },
}
