import { api } from 'api'

export interface WorkSubmissionRequest {
  work_id: number
  started_at?: string | null
  completed_at?: string | null
  no_show?: boolean | null
  finished_early_category?: string | null
  finished_early_reason?: string | null
  review_rating?: number | null
  review_comment?: string | null
  review_tags?: string | null // TODO(Alex): figure out why this is a string and not string array
  tryout_new_status?: string | null
}

export async function bulkApproveWork(
  companyId: number,
  shiftId: number,
  data: WorkSubmissionRequest[]
) {
  const formattedData = { work_item_edits: data }

  const response = await api.post(
    `/company/${companyId}/shifts/${shiftId}/bulk_edit_review_approve`,
    formattedData
  )
  return response.data
}

export async function approveWork(
  companyId: number,
  shiftId: number,
  data: WorkSubmissionRequest
) {
  await api.post(
    `/company/${companyId}/shifts/${shiftId}/edit_review_approve`,
    data
  )
}

export async function updateWork(
  companyId: number,
  shiftId: number,
  data: WorkSubmissionRequest
) {
  await api.post(`/company/${companyId}/shifts/${shiftId}/edit_work`, data)
}
