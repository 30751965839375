import { getDemoData, getGraphqlRequest, matchGraphqlRequest } from '../utils'
import { Work } from 'typings/common_defs'

export const mockGetWorkers = {
  shouldMock: (request) => matchGraphqlRequest(request, /company\{workers2/),
  getResponse: async (request) => {
    const graphqlRequest = getGraphqlRequest(request)
    const demoData = getDemoData()
    const workers = demoData.workers
    const rosters = demoData.rosters
    const query = graphqlRequest.variables.search
      ? (graphqlRequest.variables.search as string).trim().toLowerCase()
      : ''

    const allWork = demoData.shifts
      .map((shift) =>
        (shift.work ?? []).map((work) => ({
          ...work,
          shift,
        }))
      )
      .flat()
    const getWorkForCompany = (workerId: number) => {
      return allWork.filter((work) => work.worker?.id === workerId)
    }

    return {
      data: {
        company: {
          workers2: {
            cursor: null,
            items: (query
              ? workers.filter((worker) =>
                  worker.name.toLowerCase().includes(query)
                )
              : workers
            ).map((worker) => {
              const workForCompany = getWorkForCompany(worker.id)
              const lastShift = getLastShift(workForCompany)
              return {
                ...worker,
                rosters: rosters.filter((roster) =>
                  roster.workers.some(
                    (rosterWorker) => rosterWorker.id === worker.id
                  )
                ),
                numShiftsForCompany: workForCompany.length,
                workForCompany: lastShift
                  ? [
                      {
                        startedAt: lastShift?.startsAt,
                      },
                    ]
                  : [],
              }
            }),
          },
        },
      },
    }
  },
}

export function getLastShift(workForCompany: Work[]) {
  const sortedWork = workForCompany
    .filter((work) => ['completed', 'paid'].includes(work.status ?? ''))
    .sort((a, b) => {
      const aStart = new Date(a.shift!.startsAt!)
      const bStart = new Date(b.shift!.startsAt!)
      return bStart.getTime() - aStart.getTime()
    })
  return sortedWork[0]?.shift
}
