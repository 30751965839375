import React from 'react'
import { useParams } from 'react-router'
import { AssignmentDetailProvider } from './context/AssignmentDetailProvider'
import { AssignmentDetailPageContent } from './AssignmentDetailPageContent'

export const AssignmentDetailPage = () => {
  const { assignmentId: assignmentIdParam } = useParams<{
    assignmentId: string
  }>()
  const assignmentId = assignmentIdParam ? Number(assignmentIdParam) : undefined

  return (
    <AssignmentDetailProvider assignmentId={assignmentId}>
      <AssignmentDetailPageContent />
    </AssignmentDetailProvider>
  )
}
