import { useQuery, useQueryClient } from '@tanstack/react-query'
import { companyLocationsKey } from './keys'
import { getCompanyLocations } from 'api/company'

interface UseCompanyLocationsOptions {
  enabled?: boolean
  // allow certain view to always get latest data independent of cache/stale time, e.g. location list
  refetchOnMount: boolean | 'always'
}

export function useCompanyLocations(options?: UseCompanyLocationsOptions) {
  return useQuery({
    queryKey: companyLocationsKey,
    queryFn: getCompanyLocations,
    gcTime: Infinity, // only invalidate on adding/editing location
    staleTime: Infinity,
    ...options,
  })
}

export function useInvalidateCompanyLocations() {
  const queryClient = useQueryClient()

  return {
    invalidate: () =>
      queryClient.invalidateQueries({
        queryKey: companyLocationsKey,
      }),
  }
}
