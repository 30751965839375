import { useAuthContext } from 'components/auth'
import { useMutation } from '@tanstack/react-query'
import { generateQuizFromText } from 'api/company'
import { GenerateQuizRequest } from 'api/company/companyQuiz'
import { QuizQuestion } from 'api/shift'

type MutationPayload = Pick<GenerateQuizRequest, 'text' | 'cancelToken'>

interface UseGenerateCompanyQuizMutation {
  onSuccess?: (data: QuizQuestion[]) => void
  onError?: (error: Error) => void
}

export function useGenerateCompanyQuizMutation(
  options: UseGenerateCompanyQuizMutation
) {
  const companyId = useAuthContext().company?.id as number
  return useMutation({
    mutationFn: (request: MutationPayload) =>
      generateQuizFromText({ ...request, companyId }),
    ...options,
  })
}
