import { useQuery } from '@tanstack/react-query'
import { getAssignmentDetail } from 'api/assignment/getAssignmentDetail'
import { assignmentDetailKey } from './keys'

export const useAssignmentDetailQuery = (
  request: Parameters<typeof getAssignmentDetail>[0]
) => {
  return useQuery({
    queryKey: assignmentDetailKey(request.assignmentId || ''),
    queryFn: () => getAssignmentDetail(request),
  })
}
