import { useMutation } from '@tanstack/react-query'
import { updateEmailPreference } from 'api/user'
import { useCompanyInfo } from '../company'
import { useInvalidateUserProfile } from './useUserProfile'

export function useUpdateEmailPreferenceMutation(options?: {
  onSuccess?: () => void
  onError?: (err: Error) => void
}) {
  const { onSuccess, ...otherOptions } = options ?? {}
  const { data: company } = useCompanyInfo()
  const { invalidate: invalidateUserProfile } = useInvalidateUserProfile()

  return useMutation({
    mutationFn: async ({
      type,
      frequency,
    }: {
      type: string
      frequency: string
    }) => {
      if (!company) {
        throw new Error('Company not found')
      }

      await updateEmailPreference(company.id, type, frequency)
    },
    onSuccess: async () => {
      if (onSuccess) onSuccess()
      await invalidateUserProfile()
    },
    ...otherOptions,
  })
}
