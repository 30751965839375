import { useContext } from 'react'
import { AuthContext } from './AuthProvider'

export function useAuthContext() {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuthContext must be used within a AuthProvider')
  }
  return context
}
