import { useQuery } from '@tanstack/react-query'
import { stripeACHUrlKey } from './keys'
import { getStripeACHUrl } from 'api/payment'

interface UseChargeAccounts {
  onSuccess?: (url?: string) => void
}

export function useStripeACHUrl(options?: UseChargeAccounts) {
  return useQuery({
    queryKey: stripeACHUrlKey,
    queryFn: getStripeACHUrl,
    ...options,
  })
}
