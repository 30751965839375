import React from 'react'
import styled from 'styled-components'
import { Link, Flex, Box, Badge } from 'ui'
import { track } from 'lib/amplitude'

interface NavLinkProps {
  to: string
  icon: React.ReactNode
  text: string
  badgeCount?: number
  selected?: boolean
}

const LinkContainer = styled(Flex).attrs({
  alignItems: 'center',
  px: 4,
  py: '1.3rem',
})<{ $selected: boolean }>`
  ${({ theme, $selected }) => `
    position: relative;
    &:hover {
      background-color: ${theme.colors.primaries[50]};
    }
    
    ${
      $selected
        ? `
      background-color: ${theme.colors.primaries[50]};
      
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 0.4rem;
        height: 100%;
        background-color: ${theme.colors.primaries[300]};
      }
    `
        : ''
    }
  `}
`

export function NavLink(props: NavLinkProps) {
  const { to, icon, text, badgeCount, selected } = props

  const onLinkClick = () => {
    track(`button_click, ${to.replace(/^\//, '')}`)
  }

  return (
    <Link to={to} onClick={onLinkClick} style={{ textDecoration: 'none' }}>
      <LinkContainer
        fontWeight={selected ? 2 : 0}
        color={selected ? 'primaries.300' : 'lightText'}
        $selected={!!selected}
      >
        <Flex ml={2} flexShrink={0} alignItems={'center'} fontSize={16}>
          {icon}
        </Flex>
        <Box className={'link-label'} mx={3}>
          {text}
        </Box>
        {badgeCount ? <Badge count={badgeCount} /> : null}
      </LinkContainer>
    </Link>
  )
}
