import React from 'react'
import { Flex, Text, Carousel, Box } from 'ui'
import styled from 'styled-components'
import { MailPlusIcon, SmartphoneIcon, SmilePlusIcon } from 'lucide-react'

const CarouselCard = styled(Flex).attrs({
  flexDirection: 'column',
  alignItems: 'center',
  px: [0, 7],
})``

const CarouselIcon = styled(Box).attrs({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  my: 4,
  width: '8.8rem',
  height: '8.8rem',
})<{ $step: number }>`
  ${({ theme: { colors }, $step }) => `
    position: relative;
    border: 2px solid ${colors.text};
    border-radius: 50%;
    
    &:before {
      content: '${$step}';
      width: 3.2rem;
      height: 3.2rem;
      position: absolute;
      background-color: ${colors.text};
      border-radius: 50%;
      color: ${colors.white};
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      left: 0;
      top: 0;
      margin-left: -0.5rem;
      margin-top: -0.5rem;
    }
  `}
`

export function InfoCarousel() {
  return (
    <Carousel indicators={true} cardsToShow={1} mx={4} mobileScroll={false}>
      <CarouselCard>
        <CarouselIcon $step={1}>
          <SmartphoneIcon strokeWidth={1} size={44} />
        </CarouselIcon>
        <Text fontWeight={1} fontSize={3} textAlign={'center'}>
          We'll send the worker a message with a link to download the WorkWhile
          app.
        </Text>
      </CarouselCard>
      <CarouselCard>
        <CarouselIcon $step={2}>
          <SmilePlusIcon strokeWidth={1} size={44} />
        </CarouselIcon>
        <Text fontWeight={1} fontSize={3} textAlign={'center'}>
          When they accept, they'll be added to your Favorites so you can easily
          schedule them.
        </Text>
      </CarouselCard>
      <CarouselCard>
        <CarouselIcon $step={3}>
          <MailPlusIcon strokeWidth={1} size={44} />
        </CarouselIcon>
        <Text fontWeight={1} fontSize={3} textAlign={'center'}>
          You'll receive an e-mail when they're ready to be scheduled.
        </Text>
      </CarouselCard>
    </Carousel>
  )
}
