import React, { createContext, PropsWithChildren, useMemo } from 'react'
import {
  addDays,
  addWeeks,
  endOfDay,
  format,
  parse,
  startOfDay,
} from 'date-fns'
import { useSearchParams } from 'react-router-dom'
import {
  DEFAULT_INSIGHT_END_DAY_BEFORE_NOW,
  DEFAULT_INSIGHT_START_WEEK_BEFORE_NOW,
} from 'lib/insight'
import { useInsight } from 'queries/analytics'
import { CompanyInsight } from 'api/analytics'

interface AnalyticsProviderValues {
  locationId?: number
  positionId?: number
  dates: { from: Date; to?: Date }
  setLocationId: (locationId?: number) => void
  setPositionId: (positionId?: number) => void
  setDates: (dates: { from: Date; to?: Date }) => void
  insight?: CompanyInsight
}

export const AnalyticsContext = createContext<AnalyticsProviderValues | null>(
  null
)

const dateFormat = 'yyyy-MM-dd'
export function AnalyticsProvider(props: PropsWithChildren) {
  const { children } = props
  const [searchParams, setSearchParams] = useSearchParams()
  const locationId = useMemo(() => {
    return searchParams.get('location_id')
      ? Number(searchParams.get('location_id'))
      : undefined
  }, [searchParams])
  const positionId = useMemo(() => {
    return searchParams.get('position_id')
      ? Number(searchParams.get('position_id'))
      : undefined
  }, [searchParams])
  const dates = useMemo(() => {
    const datesStr = searchParams.get('dates')
    if (datesStr) {
      const [from, to] = datesStr.split('~')
      return {
        from: startOfDay(parse(from, dateFormat, new Date())),
        to: endOfDay(parse(to, dateFormat, new Date())),
      }
    }

    return {
      from: startOfDay(
        addWeeks(new Date(), -DEFAULT_INSIGHT_START_WEEK_BEFORE_NOW)
      ),
      to: endOfDay(addDays(new Date(), -DEFAULT_INSIGHT_END_DAY_BEFORE_NOW)),
    }
  }, [searchParams])
  const { data: insight } = useInsight({
    startDate: dates.from,
    endDate: dates.to,
    locationId,
    positionId,
  })

  const value = useMemo(() => {
    return {
      insight,
      dates,
      locationId,
      positionId,
      setLocationId: (locationId?: number) =>
        setSearchParams((prevParams) => {
          if (locationId) {
            prevParams.set('location_id', locationId.toString())
          } else {
            prevParams.delete('location_id')
          }
          return prevParams
        }),
      setPositionId: (positionId?: number) =>
        setSearchParams((prevParams) => {
          if (positionId) {
            prevParams.set('position_id', positionId.toString())
          } else {
            prevParams.delete('position_id')
          }
          return prevParams
        }),
      setDates: (dates: { from: Date; to?: Date }) => {
        setSearchParams((prevParams) => {
          const datesStr = `${format(dates.from, dateFormat)}~${format(
            dates.to || dates.from,
            dateFormat
          )}`
          prevParams.set('dates', datesStr)
          return prevParams
        })
      },
    }
  }, [locationId, positionId, dates, insight])

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  )
}
