import React from 'react'
import { Box, Carousel } from 'ui'
import Photo1 from './photos/construction.jpg'
import Photo2 from './photos/warehouse.jpg'
import Photo3 from './photos/hospitality.jpg'
import styled from 'styled-components'

const PhotoBox = styled(Box).attrs({
  width: 1,
  height: '100vh',
})`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

export function Photography() {
  return (
    <Carousel showNavButtons={false} slideShow={true} transitionDuration={500}>
      <PhotoBox style={{ backgroundImage: `url(${Photo1})` }} />
      <PhotoBox style={{ backgroundImage: `url(${Photo2})` }} />
      <PhotoBox
        style={{
          backgroundImage: `url(${Photo3})`,
          backgroundPosition: 'left center',
        }}
      />
    </Carousel>
  )
}
