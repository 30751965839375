import { Page } from 'pages/layout'
import React from 'react'
import { NeedsAttention } from './NeedsAttention'
import { Evaluate } from './Evaluate'
import { Tryouts } from './Tryouts'

export function ReviewPage() {
  return (
    <Page title="Review">
      <Tryouts />
      <NeedsAttention />
      <Evaluate />
    </Page>
  )
}
