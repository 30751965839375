import React, { ReactNode } from 'react'
import styled from 'styled-components'
import './devices.css'

const Wrapper = styled.div`
  .device,
  .device-iphone-14-pro .device-frame {
    height: 694px;
    width: 342px;
  }

  .device-iphone-14-pro .device-screen {
    width: 100%;
    height: 100%;
    padding-top: 38px;
    overflow: auto;
    z-index: 10;

    // hide scrollbar but maintain functionality
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  // hide scrollbar but maintain functionality on chrome/safari
  .device-screen::-webkit-scrollbar {
    display: none;
  }

  .device-header {
    z-index: 11;
  }

  .device-sensors {
    &:before,
    &:after {
      z-index: 11;
    }
  }

  .device-frame > div {
    background-color: white;
  }

  // Device Slider
  .device .slick-slider {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px !important;
    mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
    -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);

    .slick-list {
      width: 100%;
      flex-grow: 1;
    }

    .slick-track > div {
      display: inline-block !important;
    }

    .slick-slide div {
      outline: 0 !important;
    }

    .slick-arrow {
      display: none !important;
    }
  }

  .slick-track {
    align-items: flex-start;
    display: flex;
  }
`

interface Props {
  children: ReactNode
}

const IPhone = ({ children }: Props) => (
  <Wrapper>
    <div className="device device-iphone-14-pro">
      <div className="device-frame">
        <div className="device-screen">{children}</div>
      </div>
      <div className="device-stripe" />
      <div className="device-header" />
      <div className="device-sensors" />
      <div className="device-btns" />
      <div className="device-power" />
      <div className="device-home" />
    </div>
  </Wrapper>
)

export default IPhone
