import { graphql } from '../graphql'
import { WorkerProfileShift } from './workerProfile'
import { path } from 'ramda'

export interface WorkForCompany {
  status: string
  shift: WorkerProfileShift
  e2wReview?: {
    rating?: number
  }
}

export async function getWorkerShiftsForCompany(workerId: number) {
  const data = await graphql(
    `
    company {
      id,
      name,
      worker(workerId: "${workerId}") {
        workForCompany {
          status,
          shift {
            id,
            startsAt,
            endsAt,
            location {
              name,
              address {
                timezone
              }
            }
            position {
              name
            }
          }
          e2wReview {
            rating
          }
        }
      }
    }
    `
  )

  return path(
    ['data', 'data', 'company', 'worker', 'workForCompany'],
    data
  ) as WorkForCompany[]
}
