import React from 'react'
import { Box, createColumnHelper, Flex, IconButton, Text, Tooltip } from 'ui'
import { CompanyMember, CompanyPendingInvite } from 'api/company'
import { toTitleCase } from 'lib/string'
import { RemoveDialog } from './RemoveDialog'
import { MinusCircleIcon } from 'lucide-react'

const pendingColumnHelper = createColumnHelper<CompanyPendingInvite>()
export const pendingColumns = [
  pendingColumnHelper.accessor('email', {
    header: 'Email',
    cell: (info) => (
      <>
        {info.getValue()}
        <Box display={['block', 'none']}>
          {info.row.original.companyRolePerm?.description}
        </Box>
      </>
    ),
  }),
  pendingColumnHelper.accessor('companyRolePerm.description', {
    header: 'Role',
  }),
]

const memberColumnHelper = createColumnHelper<CompanyMember>()
export const memberColumns = [
  memberColumnHelper.accessor('name', {
    header: 'Name',
    cell: (info) => (
      <>
        {toTitleCase(info.getValue())}
        <Flex display={['flex', 'none']}>
          <Box flex={1}>
            <Text>{info.row.original.email}</Text>
            <Text>{info.row.original.companyRolePerm?.description}</Text>
          </Box>
          <RemoveDialog member={info.row.original} />
        </Flex>
      </>
    ),
  }),
  memberColumnHelper.accessor('email', {
    header: 'Email',
  }),
  memberColumnHelper.accessor('companyRolePerm.description', {
    header: 'Role',
  }),
  memberColumnHelper.accessor('id', {
    header: '',
    size: 30,
    cell: (info) => <RemoveDialog member={info.row.original} />,
  }),
]

const groupMemberColumnHelper = createColumnHelper<CompanyMember>()
export const groupMemberColumns = [
  memberColumnHelper.accessor('name', {
    header: 'Name',
    cell: (info) => (
      <>
        {toTitleCase(info.getValue())}
        <Flex display={['flex', 'none']}>
          <Box flex={1}>
            <Text>{info.row.original.email}</Text>
            <Text>{info.row.original.companyRolePerm?.description}</Text>
          </Box>
          <RemoveDialog member={info.row.original} />
        </Flex>
      </>
    ),
  }),
  groupMemberColumnHelper.accessor('email', {
    header: 'Email',
  }),
  groupMemberColumnHelper.accessor('companyRolePerm.description', {
    header: 'Role',
  }),
  groupMemberColumnHelper.accessor('id', {
    header: '',
    size: 30,
    cell: (info) => {
      const isDisabled =
        info.row.original.companyRolePerm?.description.includes('administrator')
      const tooltipContent =
        'Administrators outside your organization cannot be removed here. Please contact support.'
      return (
        <div
          className="tooltip-wrapper"
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onMouseOver={isDisabled ? undefined : () => {}}
        >
          {isDisabled ? (
            <Tooltip content={tooltipContent}>
              <div style={{ display: 'inline-block' }}>
                {' '}
                {/* Wrap the button in a span or div */}
                <IconButton disabled={true}>
                  <MinusCircleIcon size={16} />
                </IconButton>
              </div>
            </Tooltip>
          ) : (
            <RemoveDialog
              member={info.row.original}
              actionDisabled={isDisabled}
            />
          )}
        </div>
      )
    },
  }),
]
