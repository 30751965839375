import { Location } from 'typings/common_defs'
import { useMemo } from 'react'

export function useLocationCoordinates(location?: Location | null) {
  const coordinates = useMemo(() => {
    if (location && location.address?.lat && location.address?.long) {
      return {
        lat: Number(location.address.lat),
        lng: Number(location.address.long),
      }
    }

    return null
  }, [location])

  return coordinates
}
