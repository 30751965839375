import React from 'react'
import { useLogsContext } from './useLogsContext'
import { Box, Loading, Text } from 'ui'
import { LogItem } from './LogItem'

export function LogsContent() {
  const { logs, isLoading } = useLogsContext()

  if (isLoading) {
    return <Loading />
  }

  return (
    <Box>
      {logs && logs.length === 0 ? (
        <Text color={'lightText'}>
          No logs found for the selected time range.
        </Text>
      ) : null}
      {logs && logs.length > 0
        ? logs.map((log, index) => <LogItem key={index} data={log} />)
        : null}
    </Box>
  )
}
