import { getLocalTimezone } from 'lib/time'
import {
  selectNumericOptionSchema,
  selectOptionSchema,
} from 'pages/ShiftEditorPage/schemas/generic'
import * as zod from 'zod'

export const BackupShiftSchema = zod
  .object({
    timezone: zod.string().default(getLocalTimezone()),
    selectedDays: zod
      .array(zod.date(), { required_error: 'Please select a day' })
      .min(1, 'Please select a day'),
    startTime: zod.object(selectOptionSchema, {
      required_error: 'Please select a start time',
    }),
    endTime: zod.object(selectOptionSchema, {
      required_error: 'Please select an end time',
    }),
    lunchLength: zod.object(selectNumericOptionSchema).optional(),
    bonus: zod.coerce
      .number({ invalid_type_error: 'Please enter a number' })
      .nonnegative('Please enter a positive number')
      .optional(),
  })
  .refine((data) => data.startTime.value !== data.endTime.value, {
    message: 'Shift must be longer than 0 minutes.',
    path: ['endTime'], // path of error
  })

export type BackupShiftForm = zod.infer<typeof BackupShiftSchema>
