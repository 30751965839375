import { createSimpleMemoryRouterHistory } from './createSimpleMemoryRouterHistory'

type SimpleMemoryRoute = {
  path: string
  element: React.ReactNode
  state?: unknown
}

type SimpleMemoryRouterOptions = {
  routes: SimpleMemoryRoute[]
  initialEntries?: string[]
  initialIndex?: number
}

/**
 * Creates a simple memory router that uses path keys to navigate between routes and supports a basic history stack.
 *
 * Great for building out stepper components or wizards.
 *
 * Examples:
 *
 * Create a router with 3 steps
 *
 *  const stepRouter = createSimpleMemoryRouter({
 *    routes: [
 *      { path: 'step-1', element: <div>Step 1</div> },
 *      { path: 'step-2', element: <div>Step 2</div> },
 *      { path: 'step-3', element: <div>Step 3</div> },
 *    ],
 *    initialEntries: ['step-1'],
 *  })
 *
 * Navigate between steps
 *
 *  stepRouter.history.push('step-2') // Navigates to step 2
 *  stepRouter.history.go(-1) // Navigates to step 1
 *  stepRouter.history.go(1) // Navigates to step 2
 *  stepRouter.history.replace('step-2') // Replaces the current step with step 2
 *
 * Use the router in your component
 *
 * const YourStepperComponent = () => {
 *   return (
 *     <SimpleMemoryRouterProvider router={stepRouter}>
 *       <SimpleMemoryRouterOutlet />
 *     </SimpleMemoryRouterProvider>
 *   )
 * }
 *
 * @param options - The options for the router.
 * @returns The router.
 */
export const createSimpleMemoryRouter = (
  options: SimpleMemoryRouterOptions
) => {
  const { routes, initialEntries, initialIndex } = options

  const history = createSimpleMemoryRouterHistory({
    initialEntries: initialEntries ?? [routes[0].path],
    initialIndex: initialIndex ?? 0,
  })

  return {
    routes,
    history,
  }
}
