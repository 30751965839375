import { getDemoData, getGraphqlRequest, matchGraphqlRequest } from '../utils'
import { DEMO_COMPANY_ID } from 'api/demo'

export const mockGetWorkerCompliments = {
  shouldMock: (request) =>
    matchGraphqlRequest(request, /workForWorkWhile\{e2wReview\{tags/),
  getResponse: async (request) => {
    const graphqlRequest = getGraphqlRequest(request)
    const demoData = getDemoData()
    const workerId = Number(graphqlRequest.variables.workerId)

    const allWorkWithReview = demoData.shifts
      .map((shift) =>
        (shift.work ?? []).map((work) => ({
          ...work,
          shift,
        }))
      )
      .flat()
      .filter((work) => work.worker?.id === workerId && work.e2wReview)

    return {
      data: {
        company: {
          id: DEMO_COMPANY_ID,
          name: 'DemoCo',
          worker: {
            workForWorkWhile: allWorkWithReview,
          },
        },
      },
    }
  },
}
