import React, { useMemo } from 'react'
import { Box, Flex, Heading } from 'ui'
import { useHomeContext } from './useHomeContext'
import { addDays, format, isSameDay, isToday, startOfWeek } from 'date-fns'
import styled from 'styled-components'
import { DateJumper } from './DateJumper'

const ControlContainer = styled(Flex).attrs({
  mt: [0, 3, 4],
  pb: 3,
  alignItems: 'center',
})`
  ${({ theme: { colors } }) => `
    position: relative;
    
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      margin-left: -50%;
      height: 1px;
      width: 200%;
      background-color: ${colors.neutrals[100]};
      z-index: 1;
    }
  `}
`

const DateCell = styled(Flex).attrs({
  flexDirection: 'column',
  width: [1 / 7, 1 / 7, 60],
  mr: 2,
  alignItems: 'center',
})<{ $isCurrent: boolean }>`
  ${({ theme: { colors }, $isCurrent }) => `
    position: relative;
    cursor: pointer;
    
    ${
      $isCurrent
        ? `
          &:after {
            content: '';
            position: absolute;
            bottom: -1.6rem;
            left: 0;
            width: 100%;
            border: 2px solid ${colors.primary};
          }
          `
        : ``
    }
    
    &:last-child {
      margin-right: 0;
    }
    
    &:hover {
      > * {
        color: ${colors.primary};
      }
    }
  `}
`

export function CalendarControl() {
  const { currentDate, setCurrentDate } = useHomeContext()
  const weekDays = useMemo(() => {
    const startDate = startOfWeek(currentDate)
    return '1234567'.split('').map((d, index) => addDays(startDate, index))
  }, [currentDate])

  return (
    <ControlContainer>
      <Flex flexWrap={'wrap'} alignItems={'center'} width={[1, 4 / 5]}>
        <Flex
          alignItems={'center'}
          justifyContent={['center', 'flex-start']}
          width={[1, 110, 140]}
          py={[3, 0]}
        >
          <Heading level={3} my={0}>
            {format(currentDate, 'MMM yyyy')}
          </Heading>
          <Box flex={1} justifyContent={'flex-end'} display={['flex', 'none']}>
            <DateJumper />
          </Box>
        </Flex>
        <Flex
          borderLeftWidth={2}
          borderStyle={['none', 'solid']}
          borderLeftColor={'neutrals.100'}
          pl={[0, 2, 4]}
          flex={['auto', 1]}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          {weekDays.map((date) => {
            const today = isToday(date)
            const isCurrentDate = isSameDay(date, currentDate)
            return (
              <DateCell
                $isCurrent={isCurrentDate}
                key={date.toISOString()}
                onClick={() => setCurrentDate(date)}
              >
                <Box
                  fontSize={1}
                  fontWeight={today ? 1 : 0}
                  color={today ? 'primary' : 'lightText'}
                >
                  {format(date, 'EEEEE')}
                </Box>
                <Box
                  fontWeight={today ? 2 : 0}
                  color={today ? 'primary' : 'text'}
                >
                  {format(date, 'd')}
                </Box>
              </DateCell>
            )
          })}
        </Flex>
      </Flex>
      <Box display={['none', 'block']} flex={1} />
      <Box display={['none', 'block']}>
        <DateJumper />
      </Box>
    </ControlContainer>
  )
}
