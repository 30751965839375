import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Box, Flex, Text } from 'ui'
import { FillRateRec } from './FillRateRec'

export const FillRateInfo = styled(Flex).attrs<{
  label: string
  text: ReactNode
  rec?: string | null
}>((props) => ({
  flexDirection: 'row',
  alignItems: 'center',
  px: 3,
  py: 2,
  children: (
    <>
      <Box flex={1}>{props.label}</Box>
      <Text fontWeight={2}>
        {props.rec ? (
          <Flex flexDirection={'column'} alignItems={'flex-end'}>
            <Box>{props.text}</Box>
            <FillRateRec text={props.rec} />
          </Flex>
        ) : (
          props.text
        )}
      </Text>
    </>
  ),
}))`
  &:nth-child(even) {
    background-color: ${(props) => props.theme.colors.neutrals[50]};
  }
`
