import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { addDays, addMinutes, format, parse, startOfDay } from 'date-fns'
import { useSearchParams } from 'react-router-dom'
import { toMinutes, toTimeString } from 'lib/time'
import { CompanyLog } from 'api/company'
import { useCompanyLogs } from 'queries/company/useCompanyLogs'

interface LogsProviderValues {
  startDate: Date
  setStartDate: (date: Date) => void
  startTime: number
  startTimeStr: string
  setStartTime: (time: string) => void
  endDate: Date
  setEndDate: (date: Date) => void
  endTime: number
  setEndTime: (time: string) => void
  endTimeStr: string
  isLoading: boolean
  logs?: CompanyLog[]
  fetchLogs: () => void
}

export const LogsContext = createContext<LogsProviderValues | null>(null)

const dateFormat = 'yyyy-MM-dd'

export function LogsProvider(props: PropsWithChildren) {
  const { children } = props
  const [searchParams, setSearchParams] = useSearchParams()
  const [enabled, setEnabled] = useState(true)

  const endDate = useMemo(() => {
    const today = new Date()
    return searchParams.get('end_date')
      ? parse(searchParams.get('end_date') as string, dateFormat, new Date())
      : today
  }, [searchParams])

  const endTime = useMemo(() => {
    const time = searchParams.get('end_time')
    const timeInMinutes = time
      ? toMinutes(time)
      : new Date().getHours() * 60 + new Date().getMinutes()
    // round up to nearest 15 minutes
    return Math.ceil(timeInMinutes / 15) * 15
  }, [searchParams])

  const startDate = useMemo(() => {
    return searchParams.get('start_date')
      ? parse(searchParams.get('start_date') as string, dateFormat, new Date())
      : addDays(endDate, -3)
  }, [searchParams, endDate])

  const startTime = useMemo(() => {
    const time = searchParams.get('start_time')
    if (!time) return endTime
    const timeInMinutes = toMinutes(time)
    // round down to nearest 15 minutes
    return Math.ceil(timeInMinutes / 15) * 15
  }, [searchParams, endTime])

  const fullStartDate = useMemo(() => {
    return addMinutes(startOfDay(startDate), startTime)
  }, [startDate, startTime])

  const fullEndDate = useMemo(() => {
    return addMinutes(startOfDay(endDate), endTime)
  }, [endDate, endTime])

  const { data: logs, isFetching: isLoading } = useCompanyLogs({
    startDate: fullStartDate,
    endDate: fullEndDate,
    enabled,
  })

  useEffect(() => {
    if (!isLoading) {
      setEnabled(false)
    }
  }, [isLoading])

  useEffect(() => {
    if (searchParams.get('start_date') && searchParams.get('end_date')) {
      setSearchParams((params) => {
        if (!params.get('start_date')) {
          params.set('start_date', format(startDate, dateFormat))
        }
        if (!params.get('end_date')) {
          params.set('end_date', format(endDate, dateFormat))
        }
        if (!params.get('start_time')) {
          params.set('start_time', toTimeString(startTime))
        }
        if (!params.get('end_time')) {
          params.set('end_time', toTimeString(endTime))
        }
        return params
      })
    }
  }, [searchParams, startDate, endDate, startTime, endTime])

  const value = useMemo(() => {
    return {
      logs,
      isLoading,
      startDate,
      endDate,
      setStartDate: (date: Date) =>
        setSearchParams((prevParams) => {
          prevParams.set('start_date', format(date, dateFormat))
          return prevParams
        }),
      setEndDate: (date: Date) =>
        setSearchParams((prevParams) => {
          prevParams.set('end_date', format(date, dateFormat))
          return prevParams
        }),
      startTime,
      setStartTime: (time: string) =>
        setSearchParams((prevParams) => {
          prevParams.set('start_time', time)
          return prevParams
        }),
      endTime,
      setEndTime: (time: string) =>
        setSearchParams((prevParams) => {
          prevParams.set('end_time', time)
          return prevParams
        }),
      startTimeStr: toTimeString(startTime),
      endTimeStr: toTimeString(endTime),
      fetchLogs: () => setEnabled(true),
    }
  }, [startDate, endDate, logs, isLoading])

  return <LogsContext.Provider value={value}>{children}</LogsContext.Provider>
}
