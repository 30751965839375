import { getDemoData, getGraphqlRequest, matchGraphqlRequest } from '../utils'
import { CompanyWorker } from 'api/worker'
import { getLastShift } from 'api/demo/mocks/getWorkers'

export const mockGetRosterDetails = {
  shouldMock: (request) => matchGraphqlRequest(request, /company\{rosters\(/),
  getResponse: async (request) => {
    const demoData = getDemoData()
    const graphqlRequest = getGraphqlRequest(request)
    const rosterId = graphqlRequest.variables.rosterId as number
    const rosters = demoData.rosters
    const workers = demoData.workers
    const roster = rosters.find((r) => r.id === rosterId)

    if (!roster) {
      throw new Error(`Roster with id ${rosterId} not found`)
    }

    const allWork = demoData.shifts
      .map((shift) =>
        (shift.work ?? []).map((work) => ({
          ...work,
          shift,
        }))
      )
      .flat()
    const getWorkForCompany = (workerId: number) => {
      return allWork.filter((work) => work.worker?.id === workerId)
    }

    return {
      data: {
        company: {
          rosters: [
            {
              ...roster,
              workers: roster.workers.map((worker) => {
                const workerData = workers.find(
                  (w) => w.id === worker.id
                ) as CompanyWorker
                const workForCompany = getWorkForCompany(worker.id)
                const lastShift = getLastShift(workForCompany)
                return {
                  ...workerData,
                  numShiftsForCompany: workForCompany.length,
                  workForCompany: [
                    {
                      startedAt: lastShift?.startsAt,
                    },
                  ],
                }
              }),
            },
          ],
        },
      },
    }
  },
}
