import React from 'react'
import { Box, Text, Message, Field, Input, Flex, Button } from 'ui'
import { useFormContext, get } from 'react-hook-form'
import { DEFAULT_CLOCK_IN_RADIUS_METER, LocationForm } from './schema'

export function ClockInOverrides() {
  const {
    register,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<LocationForm>()

  const lat = watch('lat')
  const long = watch('long')

  const onReset = () => {
    setValue('clockInLat', lat)
    setValue('clockInLong', long)
    setValue('clockInRadius', DEFAULT_CLOCK_IN_RADIUS_METER)
  }

  return (
    <Flex flexWrap={'wrap'} alignItems={'flex-end'}>
      <Box width={[1, 3 / 5]}>
        <Text mr={2} mb={2}>
          Clock-in location
        </Text>
        <Message variant={'info'} mb={3}>
          Move the circle on the map to the area where workers are expected to
          arrive and check in. You can also customize the radius if necessary.
        </Message>
      </Box>
      <Flex width={[1, 2 / 5]} pl={[0, 3]} alignItems={'flex-end'}>
        <Field
          flex={1}
          label={'Radius (m)'}
          error={get(errors, 'clockInRadius')?.message?.toString()}
        >
          <Input
            type={'number'}
            min={DEFAULT_CLOCK_IN_RADIUS_METER}
            {...register('clockInRadius')}
          />
        </Field>
        <Box mb={3} ml={3}>
          <Button variant={'text'} onClick={onReset}>
            Reset
          </Button>
        </Box>
      </Flex>
    </Flex>
  )
}
