import React, { useState } from 'react'
import { Button, toast } from 'ui'
import { WorkersSelector } from 'components/WorkersSelector'
import { RosterDetail, CompanyWorker } from 'api/worker'
import { useAddWorkersToRosterMutation } from 'queries/company'
import { useInvalidateRosterDetail } from 'queries/worker'
import { AddEmptyState } from 'pages/ListDetailPage/AddEmptyState'

export function AddWorkersModal(props: { data: RosterDetail }) {
  const { data } = props
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState([])
  const { invalidate: invalidateRosterDetail } = useInvalidateRosterDetail(
    Number(data.id)
  )
  const { mutate: addWorkersToRoster, isPending } =
    useAddWorkersToRosterMutation({
      rosterId: data.id,
      onSuccess: () => {
        toast.success(`Workers added to ${data.name}`)
        setOpen(false)
        setSelected([])
        invalidateRosterDetail()
      },
      onError: (err) => {
        toast.error(err.message)
      },
    })

  const onWorkersSelected = (workers: CompanyWorker[]) => {
    addWorkersToRoster(workers.map((w) => w.id))
  }

  return (
    <>
      <WorkersSelector
        open={open}
        loading={isPending}
        title={`Add Workers to ${data.name}`}
        onClose={() => setOpen(false)}
        onChange={onWorkersSelected}
        selected={selected}
        emptyState={<AddEmptyState />}
      />
      <Button
        variant={'secondary'}
        kind={'medium'}
        onClick={() => setOpen(true)}
      >
        Add Workers
      </Button>
    </>
  )
}
