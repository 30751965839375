import { useFormContext } from 'react-hook-form'
import { useMemo } from 'react'

export function useSupervisor(id: number) {
  const {
    watch,
    register,
    setValue,
    formState: { errors },
    control,
  } = useFormContext()
  const supervisors = watch('supervisors') || []
  const index = supervisors.findIndex((s) => s.id === id)
  const data = supervisors[index]
  const dataPath = `supervisors.${index}`
  const supervisorErrors = (errors.supervisors || [])[index] ?? {}

  const values = useMemo(() => {
    return {
      data,
      register: (name: string) => register(`${dataPath}.${name}`),
      watch: (name: string) => watch(`${dataPath}.${name}`),
      dataPath,
      getPath: (name: string) => `${dataPath}.${name}`,
      remove: () => {
        setValue(
          'supervisors',
          supervisors.filter((s) => s.id !== data.id)
        )
      },
      errors: supervisorErrors,
      control,
    }
  }, [supervisors, data, dataPath, supervisorErrors])

  return values
}
