import React from 'react'
import { Box, Field, Heading, Input, Message, Textarea } from 'ui'
import { Footer } from '../Footer'
import { useForm } from 'react-hook-form'
import { DetailsForm, detailsSchema } from '../schemas'
import { zodResolver } from '@hookform/resolvers/zod'
import { usePositionEditor } from '../usePositionEditor'
import { useDebounceEffect } from 'ahooks'

export function Details() {
  const { positionData, setPositionData, editorError, submitPositionData } =
    usePositionEditor()
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DetailsForm>({
    resolver: zodResolver(detailsSchema),
    defaultValues: positionData.details,
  })
  const positionName = watch('positionName')
  const description = watch('description')

  useDebounceEffect(
    () => {
      // auto-save input values
      setPositionData({
        details: {
          positionName,
          description,
        },
      })
    },
    [positionName, description],
    { wait: 500 }
  )

  return (
    <form
      onSubmit={(e) => {
        e.stopPropagation()
        e.preventDefault()
        handleSubmit(async (data) => {
          await setPositionData({
            details: data,
          })
          submitPositionData()
        })(e)
      }}
    >
      <Box width={[1, '68rem']}>
        <Heading level={3}>Provide Job Details</Heading>
        <Field
          label={'Position Name'}
          error={errors.positionName?.message?.toString()}
        >
          <Input
            placeholder={'Enter Position Name'}
            {...register('positionName')}
          />
        </Field>
        <Field
          label={'Job Description'}
          error={errors.description?.message?.toString()}
        >
          <Textarea
            minRows={15}
            placeholder={'Write a description of the shift'}
            {...register('description')}
          />
        </Field>
        {editorError ? (
          <Message mb={3} variant={'error'}>
            {editorError}
          </Message>
        ) : null}
        <Footer ctaText={'Save'} />
      </Box>
    </form>
  )
}
