import { ShiftEditData, TimelineShift } from 'api/shift'
import { useMemo } from 'react'
import { PolicyWindow } from 'typings/common_defs'
import { differenceInMinutes } from 'date-fns'
import { equals } from 'ramda'

export const DEFAULT_CANCELLATION_POLICY_WINDOW: PolicyWindow[] = [
  { cutoffTimeHours: 24, chargePercentage: 100 },
]

export function useCancellationPolicy(shift: TimelineShift | ShiftEditData) {
  const policyWindows =
    shift.cancellationPolicy?.policyWindows ??
    DEFAULT_CANCELLATION_POLICY_WINDOW

  const policyWindow = useMemo(() => {
    const now = new Date()
    const start = new Date(shift.startsAt)
    const diffMinutes = differenceInMinutes(start, now)
    const applicableWindows = policyWindows.filter((policyWindow) => {
      const cutoffTime = policyWindow.cutoffTimeHours * 60
      if (diffMinutes <= cutoffTime) {
        return policyWindow
      }
    })

    applicableWindows.sort((a, b) => a.cutoffTimeHours - b.cutoffTimeHours)
    return applicableWindows[0]
  }, [shift, policyWindows])

  return {
    policyWindow,
    isStandard: equals(policyWindows, DEFAULT_CANCELLATION_POLICY_WINDOW),
  }
}
