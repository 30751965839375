import { Loader } from '@googlemaps/js-api-loader'
import { config } from 'config'

export interface PlaceInfo {
  formattedAddress: string
  addressComponents: {
    address: string
    unit?: string
    city: string
    state: string
    postalCode: string
  }
  geometry: {
    lat: number
    lng: number
  }
}

export const loader = new Loader({
  apiKey: config.googleMapsApiKey,
  libraries: ['geocoding', 'places'],
})

export function transformGeocoderResult(result: google.maps.GeocoderResult) {
  const addressCmps = {
    address: '',
    unit: undefined as string | undefined,
    city: '',
    state: '',
    postalCode: '',
  }
  result.address_components.forEach((cmp) => {
    if (cmp.types.includes('street_number')) {
      addressCmps.address = `${cmp.long_name} ${addressCmps.address}`.trim()
    }
    if (cmp.types.includes('route')) {
      addressCmps.address = `${addressCmps.address} ${cmp.long_name} `.trim()
    }
    if (cmp.types.includes('subpremise')) {
      addressCmps.unit = cmp.long_name
    }
    if (cmp.types.includes('locality')) {
      addressCmps.city = cmp.long_name
    }
    if (cmp.types.includes('administrative_area_level_1')) {
      addressCmps.state = cmp.short_name
    }
    if (cmp.types.includes('postal_code')) {
      addressCmps.postalCode = cmp.long_name
    }
  })
  const location = result.geometry.location
  return {
    formattedAddress: result.formatted_address,
    addressComponents: addressCmps,
    geometry: {
      lat: location.lat(),
      lng: location.lng(),
    },
  }
}

export function geocodeFromAddress(address: string): Promise<PlaceInfo> {
  return new Promise((resolve, reject) => {
    try {
      loader.importLibrary('geocoding').then((geocoding) => {
        const service = new geocoding.Geocoder()
        service.geocode({ address }).then(({ results }) => {
          const result = results[0]
          if (result && !result.partial_match) {
            resolve(transformGeocoderResult(result))
          } else {
            reject(new Error('Unable to resolve address'))
          }
        })
      })
    } catch (ex) {
      reject(ex)
    }
  })
}
