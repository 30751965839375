import { useCompanyLocation } from 'hooks/useCompanyLocation'
import { useCompanyPosition } from 'hooks/useCompanyPosition'
import { toTitleCase } from 'lib/legacy_util'
import { groupBy } from 'ramda'
import { useMemo } from 'react'
import { addMinutes, format, startOfDay } from 'date-fns'
import { formatCurrency } from 'lib/string'
import { getTimezoneAbbr, toMinutes } from 'lib/time'
import { PaymentTypeEnum } from './schemas'
import { useShiftEditor } from './useShiftEditor'

const formatDates = (dates: Date[]) => {
  const groups = groupBy((date) => format(date, 'MMMM yyy'), dates)
  const isMultiYear =
    Object.keys(groupBy((date) => date.getFullYear().toString(), dates))
      .length > 1
  return Object.entries(groups).map(([, group]) => {
    if (!group) return [] as string[]
    return group
      .map((date, idx) => {
        if (group.length === 1) {
          return format(date, isMultiYear ? 'MMMM d - yyy' : 'MMMM d')
        }
        if (idx === 0) {
          return format(date, 'MMMM d')
        }
        if (idx === group.length - 1) {
          return format(date, isMultiYear ? 'd - yyy' : 'd')
        }
        return format(date, 'd')
      })
      .join(', ')
  })
}
export function useShiftSummary() {
  const { shiftData, hasRequiredData } = useShiftEditor()
  const location = useCompanyLocation(shiftData.details?.locationId)
  const position = useCompanyPosition(shiftData.details?.positionId)
  const bonus = shiftData.payment?.bonus || shiftData.payment?.customBonus
  const dates = shiftData.schedule?.selectedDays ?? []
  const formattedDates = useMemo(() => formatDates(dates), [dates])

  const pay = useMemo(() => {
    if (!shiftData.payment?.pay) return null
    if (shiftData.payment.paymentType === PaymentTypeEnum.Hourly) {
      return `$${shiftData.payment.pay.toFixed(2)}/hr per worker`
    }
    return `$${shiftData.payment.pay.toFixed(2)} per worker`
  }, [])

  const shiftLeadPay = useMemo(() => {
    if (!shiftData.payment?.shiftLeadPay) return null
    if (shiftData.payment.paymentType === PaymentTypeEnum.Hourly) {
      return `${formatCurrency(
        shiftData.payment.shiftLeadPay
      )}/hr per shift lead`
    }
    return `${formatCurrency(shiftData.payment.shiftLeadPay)} per shift lead`
  }, [])

  const shiftLeadTime = useMemo(() => {
    if (!shiftData.schedule?.startTime || !shiftData.schedule?.endTime)
      return null
    const startMinutes = toMinutes(shiftData.schedule.startTime.value)
    const endMinutes = toMinutes(shiftData.schedule.endTime.value)
    const shiftLeadEarlyStartMinutes =
      shiftData.staff?.shiftLeadEarlyStartTime?.value ?? 0
    const shiftLeadLateEndMinutes =
      shiftData.staff?.shiftLeadLateEndTime?.value ?? 0
    const date = new Date() // any date - just a reference to convert time
    const startsAt = addMinutes(startOfDay(date), startMinutes)
    const endsAt = addMinutes(startOfDay(date), endMinutes)
    const shiftLeadStartsAt = addMinutes(startsAt, -shiftLeadEarlyStartMinutes)
    const shiftLeadEndsAt = addMinutes(endsAt, shiftLeadLateEndMinutes)
    const timezone = shiftData.schedule.timezone

    return `${format(shiftLeadStartsAt, 'h:mmaaa')} - ${format(
      shiftLeadEndsAt,
      'h:mmaaa'
    )} ${getTimezoneAbbr(timezone, shiftLeadStartsAt)}`
  }, [shiftData])

  const orientationSummary = shiftData.orientationShift?.willHaveOrientation
    .value
    ? {
        time:
          shiftData.orientationShift?.startTime &&
          shiftData.orientationShift?.endTime
            ? `${shiftData.orientationShift?.startTime.label} - ${
                shiftData.orientationShift?.endTime.label
              } ${getTimezoneAbbr(
                shiftData.orientationShift.timezone,
                shiftData.orientationShift.selectedDays?.[0]
              )}`
            : 'No Time',
        dates: shiftData.orientationShift?.selectedDays ?? [],
        formattedDates: formatDates(
          shiftData.orientationShift?.selectedDays ?? []
        ),
        unpaidBreak:
          shiftData.orientationShift?.lunchLength?.label ?? 'No Unpaid Break',
      }
    : null
  return {
    hasData: !hasRequiredData(),
    location: shiftData.details?.locationless
      ? 'Locationless'
      : location?.name ?? 'No Location',
    position: position?.name ?? 'No Position',
    dates,
    formattedDates,
    time:
      shiftData.schedule?.startTime && shiftData.schedule?.endTime
        ? `${shiftData.schedule?.startTime.label} - ${
            shiftData.schedule?.endTime.label
          } ${getTimezoneAbbr(
            shiftData.schedule.timezone,
            shiftData.schedule.selectedDays[0]
          )}`
        : 'No Time',
    unpaidBreak: shiftData.schedule?.lunchLength?.label ?? 'No Unpaid Break',
    supervisors:
      shiftData.staff?.supervisors
        ?.map((supervisor) => toTitleCase(supervisor.name))
        .join(', ') ?? 'No Supervisors',
    workers: shiftData.staff?.numWorkers ?? 'No Workers',
    pay,
    bonus: bonus ? `$${bonus.toFixed(2)}` : 'No Bonus',
    shiftLeads: shiftData.staff?.numShiftLeads || 0,
    shiftLeadPay,
    shiftLeadTime,
    orientationShift: orientationSummary,
  }
}
