import { graphql } from 'api/graphql'
import { path } from 'ramda'
import { TimelineShift } from 'api/shift'

export async function getReviewTryoutsShifts() {
  const data = await graphql(
    `company {
        tryoutReviewShifts: shifts(status:"pending_tryout_evaluation") {
            bundleMetadata {
              bundleIndex,
              bundleSize
            }
            isTryout
            location {name, id, address{timezone}}
            position {
              name, id
              roster {
                id, name
                rosterUsers {
                  userId, status
                }
              }
            }
            startsAt, endsAt, id
            work {
              id, status, completedAt, finishedEarlyCategory
            }
          },
      }`,
    'shifts_to_review_tryouts'
  )

  return path(
    ['data', 'data', 'company', 'tryoutReviewShifts'],
    data
  ) as TimelineShift[]
}
