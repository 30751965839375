import React, { useState } from 'react'
import { Modal, Heading, Flex, Box, Button, Card } from 'ui'
import { useNavigate } from 'react-router'

export function ScheduleShiftModal() {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  return (
    <>
      <Modal width={[1, 680]} open={open} onClose={() => setOpen(false)}>
        <Box py={4}>
          <Heading level={3} my={0} mb={4} textAlign={'center'}>
            Select a Schedule Option Below
          </Heading>
          <Flex
            flexDirection={'row'}
            flexWrap={'wrap'}
            justifyContent={'space-between'}
          >
            <Card alignItems={'center'} width={[1, 11 / 23]} mb={[4, 0]}>
              <Heading level={4} my={0}>
                Request For Future Shift
              </Heading>
              <Box textAlign={'center'} my={3}>
                Invite a worker to a shift with a start time in the future.
              </Box>
              <Button
                block={true}
                onClick={() => {
                  navigate('/home/shift-editor/new?schedule=future')
                }}
              >
                Create Future Shift
              </Button>
            </Card>
            <Card alignItems={'center'} width={[1, 11 / 23]}>
              <Heading level={4} my={0}>
                Create a Past Shift
              </Heading>
              <Box textAlign={'center'} my={3}>
                Assign a worker to a shift with a start time in the past.
              </Box>
              <Button
                block={true}
                onClick={() => {
                  navigate('/home/shift-editor/new?schedule=past')
                }}
              >
                Create Past Shift
              </Button>
            </Card>
          </Flex>
        </Box>
      </Modal>
      <Button variant={'text'} onClick={() => setOpen(true)}>
        + Schedule a past or future shift
      </Button>
    </>
  )
}
