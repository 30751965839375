import React, { useMemo } from 'react'
import { Select, SelectProps, Text } from 'ui'
import { useCompanyLocations } from 'queries/company'
import { components } from 'react-select'

interface Props extends SelectProps {
  includeAll?: boolean
}

const ALL_LOCATION_OPTION = {
  label: 'All Locations',
  value: 0,
}

export function LocationSelector(props: Props) {
  const { includeAll, ...otherProps } = props
  const { data, isLoading } = useCompanyLocations()

  const options = useMemo(() => {
    if (!data) return []
    const optionItems = data.map((item) => ({
      label: item.name,
      value: Number(item.id),
    }))

    if (includeAll) {
      return [ALL_LOCATION_OPTION, ...optionItems]
    }

    return optionItems
  }, [includeAll, data])

  const value = useMemo(() => {
    return options.find((option) => option.value === props.value)
  }, [props.value, options])

  return (
    <Select
      aria-label={'Location Selector'}
      options={options}
      {...otherProps}
      isMulti={false}
      isLoading={isLoading}
      value={value}
      components={{
        NoOptionsMessage: (msgProps) => (
          <components.NoOptionsMessage {...msgProps}>
            <Text color={'primary'} textAlign={'left'}>
              There are no locations yet. Add a new one.
            </Text>
          </components.NoOptionsMessage>
        ),
      }}
    />
  )
}
