import { useMutation } from '@tanstack/react-query'
import {
  getAuth,
  getMultiFactorResolver,
  PhoneAuthProvider,
  MultiFactorError,
  PhoneMultiFactorGenerator,
  AuthError,
} from 'firebase/auth'

export function useVerifyMFACodeMutation(options: {
  onSuccess?: () => void
  onError?: (err: AuthError) => void
  multiFactorError: MultiFactorError
}) {
  const { multiFactorError, ...otherOptions } = options
  return useMutation({
    mutationFn: async (data: {
      verificationId: string
      verificationCode: string
    }) => {
      const auth = getAuth()
      const resolver = getMultiFactorResolver(auth, multiFactorError)
      const cred = PhoneAuthProvider.credential(
        data.verificationId,
        data.verificationCode
      )
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)
      return resolver.resolveSignIn(multiFactorAssertion)
    },
    ...otherOptions,
  })
}
