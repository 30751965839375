import { useQuery } from '@tanstack/react-query'
import { positionTemplateKey } from './keys'
import { getPositionTemplate } from 'api/company'

export function usePositionTemplate(positionTemplateId?: number) {
  return useQuery({
    queryKey: positionTemplateKey(positionTemplateId),
    queryFn: async () => {
      if (positionTemplateId) {
        return getPositionTemplate(positionTemplateId)
      }
    },
    enabled: !!positionTemplateId,
  })
}
