import { useMemo } from 'react'
import {
  getDurationFromTimeStrings,
  getLocalTimezone,
  getUTCDateTime,
  toMinutes,
} from 'lib/time'
import { addDays } from 'date-fns'

interface Props {
  date?: Date
  startTime?: string
  endTime?: string
  lunchLength?: number
  timezone?: string
}

export function useShiftTime(props: Props) {
  const {
    date = new Date(),
    timezone = getLocalTimezone(),
    startTime,
    endTime,
    lunchLength,
  } = props

  const durationInMinutesMinusLunch = useMemo(() => {
    if (!startTime || !endTime) {
      return null
    }
    return (
      getDurationFromTimeStrings(date, startTime, endTime, timezone) -
      (lunchLength ?? 0)
    )
  }, [startTime, endTime])

  //
  const totalDurationInMinutes = useMemo(() => {
    if (!startTime || !endTime) {
      return null
    }
    return getDurationFromTimeStrings(date, startTime, endTime, timezone)
  }, [startTime, endTime])

  const { startsAt, endsAt } = useMemo(() => {
    if (!startTime || !endTime) {
      return { startsAt: null, endsAt: null }
    }
    const startMinutes = toMinutes(startTime)
    const endMinutes = toMinutes(endTime)
    const startsAt = getUTCDateTime(date, startTime, timezone)
    const endsAt = getUTCDateTime(
      endMinutes < startMinutes ? addDays(date, 1) : date,
      endTime,
      timezone
    )
    return { startsAt, endsAt }
  }, [date, startTime, endTime, timezone])

  const spansDaylightSaving = useMemo(() => {
    if (!startsAt || !endsAt) {
      return false
    }
    return startsAt.getTimezoneOffset() !== endsAt.getTimezoneOffset()
  }, [startsAt, endsAt])

  return {
    startTime,
    endTime,
    lunchLength,
    durationInMinutesMinusLunch,
    totalDurationInMinutes,
    isOvernight: startTime && endTime && endTime < startTime,
    spansDaylightSaving,
    startsAt,
    endsAt,
  }
}
