import { Company } from 'typings/common_defs'
import { api, graphql } from 'api'
import { path } from 'ramda'

export const FALL_BACK_TAKE_PERCENTAGE = 20

export type CompanyInfo = Pick<
  Company,
  | 'name'
  | 'id'
  | 'logoUrl'
  | 'companyGroup'
  | 'takePercentageEstimate'
  | 'chargeUpfront'
  | 'w2'
>

// a lightweight version of getting current company info
export async function getCurrentCompanyInfo() {
  const data = await graphql(
    `company {
          name, id, logoUrl, takePercentageEstimate, chargeUpfront
          companyGroup { id, companies { id, name } }, w2
        }`,
    'current_company'
  )

  const company = path(['data', 'data', 'company'], data) as CompanyInfo

  if (!company) {
    return null
  }

  return {
    ...company,
    id: Number(company.id),
    takePercentageEstimate: company.takePercentageEstimate
      ? Number(company.takePercentageEstimate)
      : FALL_BACK_TAKE_PERCENTAGE,
  }
}

export async function switchCompany(
  currentCompanyId: number,
  newCompanyId: number
) {
  return api.post(`/company/${currentCompanyId}/group/switch_company`, {
    new_company_id: newCompanyId,
  })
}
