import { useReviewEvaluateShifts } from 'queries/review'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Box, Heading, Text, Flex } from 'ui'
import { EvaluateReviewShift } from './EvaluateReviewShift'
import { EvaluateEmptyState } from 'pages/ReviewPage/Evaluate/EvaluateEmptyState'

export const Evaluate = () => {
  const {
    data: performanceReviewShifts,
    isLoading: isLoadingPerformanceReviewShifts,
  } = useReviewEvaluateShifts()
  return (
    <Box mb={4}>
      <Heading level={2} mr={3}>
        Evaluate Workers
      </Heading>
      <Text mb={3}>
        5 stars = Excellent, 4 stars = Good, 3 stars = OK, 2 stars = Needs
        improvement, 1 star = Unacceptable
      </Text>
      {isLoadingPerformanceReviewShifts ? (
        <Flex flexDirection="column">
          <Box mb={2}>
            <Skeleton height={20} width={200} />
          </Box>
          <Flex flexDirection="row" alignItems="center">
            <Box mr={2}>
              <Skeleton
                height={40}
                width={40}
                circle
                style={{ marginRight: 10 }}
              />
            </Box>
            <Box mr={4}>
              <Skeleton height={20} width={100} />
            </Box>
            <Box mr={2}>
              <Skeleton height={20} width={150} />
            </Box>
            <Box mr={2}>
              <Skeleton height={20} width={150} />
            </Box>
            <Box mr={2}>
              <Skeleton height={20} width={90} />
            </Box>
          </Flex>
        </Flex>
      ) : performanceReviewShifts && performanceReviewShifts.length > 0 ? (
        performanceReviewShifts?.map((shift) => (
          <EvaluateReviewShift shift={shift} key={shift.id} />
        ))
      ) : (
        <EvaluateEmptyState />
      )}
    </Box>
  )
}
