import React from 'react'
import {
  Box,
  Heading,
  Loading,
  Table,
  Button,
  Flex,
  toast,
  useResponsiveValue,
  Text,
} from 'ui'
import { useCompanyLocations } from 'queries/company'
import { AddLocation } from 'components/AddLocation'
import { createLocationColumns } from './columns'

export function LocationList() {
  const { data, isLoading } = useCompanyLocations()
  const columnsToDisplay = useResponsiveValue([1, 1, 3])
  const nameColSize = useResponsiveValue([
    Number.MAX_SAFE_INTEGER,
    Number.MAX_SAFE_INTEGER,
    250,
  ])
  const columns = createLocationColumns(nameColSize)
  const tableVariant = useResponsiveValue([
    'borderless',
    'borderless',
    'standard',
  ])

  return (
    <Box mt={5}>
      <Flex alignItems={'center'}>
        <Heading level={3}>Locations</Heading>
        <Box flex={1} />
        <AddLocation
          trigger={<Button variant={'text'}>+ Add Location</Button>}
          onLocationSaved={() => {
            toast.success('Successfully added new location')
          }}
        />
      </Flex>
      {isLoading ? <Loading /> : null}
      {data ? (
        <Table
          variant={tableVariant as 'borderless' | 'standard'}
          columns={columns.slice(0, columnsToDisplay)}
          data={data}
        />
      ) : null}
      {data?.length === 0 ? (
        <Text p={3} color={'neutrals.200'}>
          No locations
        </Text>
      ) : null}
    </Box>
  )
}
