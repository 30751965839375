import { useMemo } from 'react'

interface UseLunchLabelParams {
  isW2: boolean
  minimumLunchLength: number
}

export function useLunchLabel({
  isW2,
  minimumLunchLength,
}: UseLunchLabelParams) {
  const lunchLabel = useMemo(() => {
    if (isW2) {
      switch (minimumLunchLength) {
        case 0:
          return 'Does this shift include an unpaid break?'
        case 30:
          return '1 MEAL BREAK REQUIRED - This shift requires AT LEAST one unpaid break of 30 minutes. You may choose to add an additional 30 minute break.'
        case 60:
          return '2 MEAL BREAKS REQUIRED - This shift requires 2 unpaid breaks of 30 minutes each, for a total of 60 minutes.'
      }
    }

    return 'Does this shift include an unpaid break?'
  }, [isW2, minimumLunchLength])

  return lunchLabel
}
