import React from 'react'
import { useShiftDetail } from '../useShiftDetail'
import { CopyButton, Flex, Box, Text, Message } from 'ui'
import { shouldShowClockInOutCodes } from 'lib/shift'
import { LucideInfo, LucideKeyRound } from 'lucide-react'

export const ShiftDetailClockInOutCode = () => {
  const { shift } = useShiftDetail()
  if (!shift) return null

  if (!shouldShowClockInOutCodes(shift)) return null

  const hasBothCodes = shift.clockInCode && shift.clockOutCode

  const getExplanatoryText = () => {
    let content = 'clock in and clock out codes'
    if (shift.requiresClockInCode && !shift.requiresClockOutCode) {
      content = 'a clock in code'
    }
    if (!shift.requiresClockInCode && shift.requiresClockOutCode) {
      content = 'a clock out code'
    }
    return `Workers need to enter ${content} to be paid for this shift.`
  }

  return (
    <Flex flexDirection="column" bg="neutrals.50" borderRadius={3} p={3}>
      <Flex
        flexDirection="row"
        mb={4}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Flex
          bg="neutrals.400"
          borderRadius="50px"
          p={2}
          alignItems="center"
          justifyContent="center"
        >
          <LucideKeyRound size={16} color="white" />
        </Flex>
        <Text ml={2} fontSize={2} fontWeight="bold">
          Clock In/Out Codes
        </Text>
      </Flex>
      <Flex
        flexDirection={'row'}
        mt={[3, 3, 0]}
        flex={1}
        alignItems="center"
        justifyContent="center"
        mb={4}
      >
        {shift.clockInCode ? (
          <Flex flexDirection={'column'} alignItems={'center'} mr={[4, 4, 4]}>
            <Text color={'lightText'} fontSize={1}>
              Clock In Code
            </Text>
            <Box display={'inline-flex'} alignItems={'center'} fontSize={7}>
              {shift.clockInCode}
              &nbsp;&nbsp;
              <CopyButton text={shift.clockInCode} />
            </Box>
          </Flex>
        ) : null}

        {hasBothCodes ? (
          <Box height="4em" width="1px" bg="neutrals.200" mr={4} />
        ) : null}

        {shift.clockOutCode ? (
          <Flex flexDirection={'column'} alignItems={'center'}>
            <Text color={'lightText'} fontSize={1}>
              Clock Out Code
            </Text>
            <Box display={'inline-flex'} alignItems={'center'} fontSize={7}>
              {shift.clockOutCode}
              &nbsp;&nbsp;
              <CopyButton text={shift.clockOutCode} />
            </Box>
          </Flex>
        ) : null}
      </Flex>
      <Message
        variant="fixed"
        style={{ backgroundColor: 'white' }}
        icon={<LucideInfo size={20} />}
      >
        {getExplanatoryText()}
      </Message>
    </Flex>
  )
}
