import { ShiftSimple } from 'api/shift'
import { toTitleCase } from 'lib/legacy_util'

export function getPayRate(data: ShiftSimple) {
  const { payRate, payLumpSum } = data
  if (payRate) {
    return `$${payRate}/hr per worker`
  }
  if (payLumpSum) {
    return `$${payLumpSum} per worker`
  }
  return null
}

export function getSupervisors(data: ShiftSimple) {
  const supervisors = data.supervisors ?? []
  return supervisors
    .map((supervisor) => toTitleCase(supervisor.name))
    .join(', ')
}
