import React from 'react'
import { Loading, Tabs } from 'ui'
import { useParams, useSearchParams } from 'react-router-dom'
import { useSchedule } from '../WorkerProfilePage/useSchedule'
import { Page } from '../layout'
import { Header } from './Header'
import { ShiftTable } from './ShiftTable'

export function WorkerShiftsPage() {
  const params = useParams<{ workerId: string }>()
  const workerId = Number(params.workerId)
  const { isLoading, upcoming, completed } = useSchedule(workerId)
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = searchParams.get('type') || 'completed'

  return (
    <Page title={'Shifts'}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Header
            workerId={workerId}
            upcoming={upcoming.length}
            completed={completed.length}
          />
          <Tabs
            value={tab}
            onValueChange={(value) => {
              setSearchParams({ type: value })
            }}
            tabs={[
              {
                value: 'completed',
                label: 'Completed',
              },
              {
                value: 'upcoming',
                label: 'Upcoming',
              },
            ]}
          >
            <Tabs.Content value={'completed'}>
              <ShiftTable data={completed} type={'completed'} />
            </Tabs.Content>
            <Tabs.Content value={'upcoming'}>
              <ShiftTable data={upcoming} type={'upcoming'} />
            </Tabs.Content>
          </Tabs>
        </>
      )}
    </Page>
  )
}
