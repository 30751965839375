import { useCompanyInfo } from '../company'
import { useMutation } from '@tanstack/react-query'
import { updateRosterWorkers } from 'api/worker/companyRoster'
import { useInvalidateShiftDetail } from 'queries/company/useCompanyShiftDetail'

interface UseUpdateRosterWorkersMutation {
  shiftId?: number
  rosterId: number

  onSuccess?: () => void
  onError?: (err: Error) => void
}

export function useUpdateRosterWorkersMutation(
  options: UseUpdateRosterWorkersMutation
) {
  const { data: company } = useCompanyInfo()
  const { shiftId, onSuccess, rosterId, ...queryOptions } = options
  const { invalidate: invalidateShiftDetail } = useInvalidateShiftDetail([
    shiftId || -1,
  ])

  return useMutation({
    mutationFn: async (
      rosterUsers: { worker_id: number; status: string }[]
    ) => {
      if (!company?.id) {
        throw new Error('Company id not found')
      }

      return updateRosterWorkers(company.id, rosterId, rosterUsers)
    },
    onSuccess() {
      invalidateShiftDetail()
      if (onSuccess) onSuccess()
    },
    ...queryOptions,
  })
}
