import { useCompanyInfo } from '../company'
import { useMutation } from '@tanstack/react-query'
import {
  StatusForCompanyEnum,
  updateWorkerStatusForCompany,
  WorkerStatusForCompanyRequest,
} from 'api/worker'

interface UseUpdateWorkerStatusForCompanyMutation {
  workerId: number
  onSuccess?: (status?: StatusForCompanyEnum) => void
  onError?: (err: Error) => void
}

export function useUpdateWorkerStatusForCompanyMutation(
  options: UseUpdateWorkerStatusForCompanyMutation
) {
  const { data: company } = useCompanyInfo()

  return useMutation({
    mutationFn: async (data: WorkerStatusForCompanyRequest) => {
      if (!company?.id) {
        throw new Error('Company id not found')
      }

      await updateWorkerStatusForCompany(company.id, options.workerId, data)

      if (data.action === 'block') {
        return StatusForCompanyEnum.Blocked
      }

      if (data.action === 'favorite') {
        return StatusForCompanyEnum.Favorite
      }
    },
    ...options,
  })
}
