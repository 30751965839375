import React, { useMemo } from 'react'
import { CalendarViewContextState } from '../../context'
import {
  BaseWorkerShiftCell,
  BaseWorkerShiftCellProps,
} from './BaseWorkerShiftCell'
import { getLocationTimezone } from 'lib/time'
import { formatInTimeZone } from 'date-fns-tz'
import * as Popover from '@radix-ui/react-popover'
import { WorkerShiftCellDetail } from './WorkerShiftCellDetail'

type WorkerShiftCellProps = {
  work: CalendarViewContextState['state']['workers'][number]['works'][string][number]
  isThisWorkerSlotRow: boolean
}

export const WorkerShiftCell = ({
  work,
  isThisWorkerSlotRow,
}: WorkerShiftCellProps) => {
  const heading = work.shift.isOrientation ? 'Orientation' : undefined

  const valueAndVariant:
    | Pick<BaseWorkerShiftCellProps, 'variant' | 'value'>
    | undefined = useMemo(() => {
    const { status } = work
    const timezone = getLocationTimezone(work.shift.location)

    if (status === 'scheduled') {
      return {
        variant: 'info',
        value: `Scheduled`,
      }
    }

    if (status === 'started') {
      const startTime = work.startedAt
        ? formatInTimeZone(work.startedAt, timezone, 'h:mm a z')
        : undefined

      return {
        variant: 'info',
        value: startTime ? `Clocked in at ${startTime}` : 'Working...',
      }
    }

    if (
      ['completed', 'approved', 'paid', 'employer_approved'].includes(status)
    ) {
      const startTime = work.startedAt
        ? formatInTimeZone(work.startedAt, timezone, 'h:mm a')
        : undefined

      const endTime = work.completedAt
        ? formatInTimeZone(work.completedAt, timezone, 'h:mm a z')
        : undefined

      return {
        variant: 'info',
        value: startTime && endTime ? `${startTime} - ${endTime}` : 'Completed',
      }
    }

    if (status === 'removed') {
      return {
        variant: 'info',
        value: 'Removed',
      }
    }

    if (status === 'admin_review') {
      return {
        variant: 'info',
        value: 'Under Review',
      }
    }

    if (status === 'employer_review') {
      return {
        variant: 'critical',
        value: 'Review Required',
      }
    }

    if (status === 'bailed') {
      return {
        variant: 'critical',
        value: 'No show',
      }
    }

    if (
      [
        'employer_cancelled',
        'rejected',
        'worker_declined_req',
        'system_declined',
      ].includes(status)
    ) {
      return undefined
    }

    return undefined
  }, [work])

  if (!valueAndVariant) {
    return null
  }

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <BaseWorkerShiftCell
          heading={heading}
          {...valueAndVariant}
          borderStyle={isThisWorkerSlotRow ? 'dashed' : 'solid'}
        />
      </Popover.Trigger>
      <Popover.Content
        side="right"
        align="start"
        sideOffset={5}
        asChild
        style={{ zIndex: 1 }}
      >
        <WorkerShiftCellDetail work={work} />
      </Popover.Content>
    </Popover.Root>
  )
}
