import { CompletedWorkStatuses, WorkStatusEnum } from 'api/shift'
import { useWorkerShifts } from 'queries/worker'

export function useSchedule(workerId: number) {
  const { data, isLoading } = useWorkerShifts(workerId)
  const work = data || []
  const now = new Date()
  const upcoming = work
    .filter(
      (w) =>
        w.status === WorkStatusEnum.Scheduled &&
        new Date(w.shift.startsAt) > now
    )
    .sort(
      (a, b) =>
        new Date(a.shift.startsAt).getTime() -
        new Date(b.shift.startsAt).getTime()
    )

  const completed = work
    .filter((w) => CompletedWorkStatuses.includes(w.status as WorkStatusEnum))
    .sort(
      (a, b) =>
        new Date(b.shift.startsAt).getTime() -
        new Date(a.shift.startsAt).getTime()
    )

  return {
    isLoading,
    upcoming,
    completed,
  }
}
