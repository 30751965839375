import { useMutation } from '@tanstack/react-query'
import { inviteWorker, InviteWorkerRequest } from 'api/worker'
import { useCompanyInfo } from 'queries/company'
import { useInvalidatePendingInvites } from './usePendingInvites'

interface UseInviteWorkerMutation {
  onError?: (error: Error) => void
  onSuccess?: () => void
}

export function useInviteWorkerMutation(options?: UseInviteWorkerMutation) {
  const { data: company } = useCompanyInfo()
  const { onSuccess, ...queryOptions } = options ?? {}
  const { invalidate: invalidatePendingInvites } = useInvalidatePendingInvites()

  return useMutation({
    mutationFn: async (data: InviteWorkerRequest) => {
      if (!company?.id) {
        throw new Error('Company id not found')
      }

      return inviteWorker(company.id, data)
    },
    onSuccess() {
      invalidatePendingInvites()
      if (onSuccess) onSuccess()
    },
    ...queryOptions,
  })
}
