import React, { useState } from 'react'
import {
  SHIFT_DETAIL_WORKER_ACTIONS,
  ShiftDetailWorkerAction,
  getTripsWithShareUrl,
  isWorkEditable,
} from 'lib/work'
import { Trip, Work } from 'typings/common_defs'
import { useShiftDetail } from 'pages/ShiftDetailPage/useShiftDetail'
import { Flex, Box, IconButton } from 'ui'
import { LocateFixed, MessageCircle, Pencil } from 'lucide-react'
import { PermissionEnum } from 'api/userPermissions'
import { AuthGuard } from 'components/auth'
import { useAppTheme } from '@workwhile/ui'
import { LocationTrackingMapModal } from '../LocationTrackingMapModal'
import { UnableToEditWorkExplainerModal } from '../UnableToEditWorkExplainerModal'
import { isShiftMessagable } from 'lib/shift'
import { MessageWorkerModal } from '../MessageWorkerModal'

interface Props {
  work: Work
  switchToEditMode: () => void
}

export const AssignedWorkerActions = ({ work, switchToEditMode }: Props) => {
  const { shift, isFetching } = useShiftDetail()
  const [isLocationTrackingMapModalOpen, setIsLocationTrackingMapModalOpen] =
    useState(false)
  const [isMessageWorkerModalOpen, setIsMessageWorkerModalOpen] =
    useState(false)
  const { colors } = useAppTheme()
  if (!shift) return null

  const trips: Array<Trip> = getTripsWithShareUrl(work)

  const _getActionsToDisplay = (): Array<ShiftDetailWorkerAction> => {
    const actionsToDisplay: Array<ShiftDetailWorkerAction> = []
    if (
      work.status &&
      !['paid', 'employer_approved', 'bailed'].includes(work?.status) &&
      !work.supervisorMarkedNoShow
    ) {
      // if the work is not paid, approved, or marked as a no show, then we can show the adjust time button
      actionsToDisplay.push(SHIFT_DETAIL_WORKER_ACTIONS.adjustTime)
    }
    if (trips.length > 0) {
      actionsToDisplay.push(SHIFT_DETAIL_WORKER_ACTIONS.viewLocation)
    }
    if (isShiftMessagable(shift)) {
      actionsToDisplay.push(SHIFT_DETAIL_WORKER_ACTIONS.message)
    }
    return actionsToDisplay
  }

  const _renderAction = (action: ShiftDetailWorkerAction) => {
    const isEditable = isWorkEditable(work)

    switch (action) {
      case SHIFT_DETAIL_WORKER_ACTIONS.viewLocation:
        return (
          <>
            <IconButton
              size="normal"
              onClick={() => setIsLocationTrackingMapModalOpen(true)}
            >
              <LocateFixed size={16} />
            </IconButton>
            <LocationTrackingMapModal
              work={work}
              open={isLocationTrackingMapModalOpen}
              onClose={() => setIsLocationTrackingMapModalOpen(false)}
            />
          </>
        )
      case SHIFT_DETAIL_WORKER_ACTIONS.adjustTime:
        return (
          <AuthGuard.Permission value={PermissionEnum.WorkTimeAdjustment}>
            {isEditable ? (
              <IconButton
                size="normal"
                onClick={switchToEditMode}
                disabled={isFetching}
              >
                <Pencil size={16} stroke={colors.primaries['200']} />
              </IconButton>
            ) : (
              <UnableToEditWorkExplainerModal
                trigger={
                  <IconButton size="normal">
                    <Pencil size={16} stroke={colors.neutrals['200']} />
                  </IconButton>
                }
              />
            )}
          </AuthGuard.Permission>
        )
      case SHIFT_DETAIL_WORKER_ACTIONS.message:
        return (
          <AuthGuard.Permission value={PermissionEnum.MessageWorker}>
            <IconButton
              size="normal"
              onClick={() => setIsMessageWorkerModalOpen(true)}
            >
              <MessageCircle size={16} />
            </IconButton>
            <MessageWorkerModal
              work={work}
              open={isMessageWorkerModalOpen}
              onClose={() => setIsMessageWorkerModalOpen(false)}
            />
          </AuthGuard.Permission>
        )
      default:
        return null
    }
  }

  const actions: Array<ShiftDetailWorkerAction> = _getActionsToDisplay()

  if (!work || !work.status) {
    return null
  }

  if (!actions || actions.length === 0) {
    return null
  }

  return (
    <Flex flexDirection="row" alignItems="center">
      {actions.map((action) => (
        <Box mr={1} key={action}>
          {_renderAction(action)}
        </Box>
      ))}
    </Flex>
  )
}
