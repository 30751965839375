import React, { ReactNode } from 'react'
import { useShiftDetail } from '../../useShiftDetail'
import { useAssignedWorkRow } from '../useAssignedWorkerRow'
import { Work } from 'typings/common_defs'
import { useAuthContext } from 'components/auth'
import { MealBreaksModal } from './MealBreaksModal'

interface RenderContentProps {
  description: string
  detailTrigger: ReactNode
}

interface Props {
  workId: number
  renderContent: (props: RenderContentProps) => ReactNode
}

export function BreakInfo({ workId, renderContent }: Props) {
  const { shift } = useShiftDetail()
  const work = useAssignedWorkRow(workId).data as Work
  const { company } = useAuthContext()
  const isW2 = company!.w2

  if (!isW2 || !work.completedAt || shift?.lunchLength === null) {
    return null
  }

  if (shift?.lunchLength === 0 && work.lunchLengthOverride === null) {
    return null
  }

  return renderContent({
    description: `${work.lunchLengthOverride ?? shift?.lunchLength} mins`,
    detailTrigger:
      shift?.id && work.lunchLengthOverride !== null ? (
        <MealBreaksModal shift={shift} workId={workId} />
      ) : null,
  })
}
