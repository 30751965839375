import React from 'react'
import { CompanyRoster } from 'api/worker'
import {
  Box,
  Card,
  Heading,
  Pluralize,
  Text,
  AvatarGroup,
  Checkmark,
  CardProps,
} from 'ui'
import styled from 'styled-components'

interface Props extends CardProps {
  data: CompanyRoster
  onClick?: () => void
  isSelected?: boolean
  maxAvatars?: number
}

const CardContainer = styled(Card)<{ $isSelected?: boolean }>`
  ${({ $isSelected, theme: { colors } }) => `
    ${
      $isSelected
        ? `
      box-shadow: 0 0 0 1px ${colors.primary};
      border-color: ${colors.primary};
      background-color: ${colors.primaries[50]};
    `
        : ''
    }
    
    ${Checkmark} {
      position: absolute;
      top: 1rem;
      right: 0;
    }
  `}
`

export function RosterCard(props: Props) {
  const { data, isSelected, maxAvatars = 5, ...otherProps } = props

  return (
    <CardContainer
      shadowless={true}
      height={'100%'}
      justifyContent={'flex-end'}
      $isSelected={isSelected}
      {...otherProps}
    >
      <Box>
        <AvatarGroup
          size={'medium'}
          max={maxAvatars}
          overlapOffset={'-1.5rem'}
          users={data.workers.map((worker) => {
            const [firstName, lastName] = (worker.name ?? '').split(' ')
            return {
              userId: worker.id.toString(),
              photoUrl: worker.profilePicUrl,
              firstName,
              lastName,
            }
          })}
        />
      </Box>
      <Heading level={4} maxLines={1} my={0} mt={2} title={data.name}>
        {data.name}
      </Heading>
      <Text>
        <Pluralize
          count={data.workers.length}
          oneText={'worker'}
          manyText={'workers'}
          noneText={'No workers'}
        />
      </Text>
      {isSelected ? <Checkmark /> : null}
    </CardContainer>
  )
}
