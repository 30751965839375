import React from 'react'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { FillRateTooltip } from './FillRateTooltip'
import { Box } from '@workwhile/ui'
import { FillRateData } from './useFillRate'
import { useTheme } from 'styled-components'

interface Props {
  data: FillRateData[]
}

export function FillRateChart(props: Props) {
  const { colors } = useTheme()
  const { data } = props

  return (
    <Box height={250}>
      <ResponsiveContainer>
        <ComposedChart data={data}>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey={'weekStartDate'}
            tick={{ fill: colors.lightText, fontSize: 11, dy: 10 }}
          />
          <YAxis yAxisId={'bar'} strokeWidth={0} />
          <YAxis yAxisId={'line'} hide={true} />
          <Tooltip content={<FillRateTooltip />} />
          <Bar
            dataKey={'workersFilled'}
            yAxisId={'bar'}
            stackId={'a'}
            fill={colors.primary}
            maxBarSize={40}
          />
          <Bar
            dataKey={'delta'}
            yAxisId={'bar'}
            stackId={'a'}
            fill={colors.primaries[100]}
            maxBarSize={40}
          />
          <Line
            dataKey="fillRate"
            yAxisId={'line'}
            stroke={colors.text}
            fill={colors.text}
            activeDot={{ r: 6 }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  )
}
