import { useQuery } from '@tanstack/react-query'
import { duplicateShiftsKey } from './keys'
import { getShiftDuplicateRecs } from 'api/shift'

export function useDuplicateShifts() {
  return useQuery({
    queryKey: duplicateShiftsKey,
    queryFn: getShiftDuplicateRecs,
  })
}
