import { useQuery, useQueryClient } from '@tanstack/react-query'
import { companyPendingInvitesKey } from './keys'
import { getCompanyPendingInvites } from 'api/company'

export function useCompanyPendingInvites() {
  return useQuery({
    queryKey: companyPendingInvitesKey,
    queryFn: getCompanyPendingInvites,
  })
}

export function useInvalidateCompanyPendingInvites() {
  const queryClient = useQueryClient()

  return {
    invalidate: () =>
      queryClient.invalidateQueries({ queryKey: companyPendingInvitesKey }),
  }
}
