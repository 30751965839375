import { useCompanyInfo } from 'queries/company'
import { useChargeAccounts } from 'queries/payment'
import { useMemo } from 'react'

export function useHasPaymentMethod() {
  const { data: companyInfo } = useCompanyInfo()
  const { data: chargeAccounts } = useChargeAccounts()

  const hasPaymentMethod = useMemo(() => {
    if (companyInfo?.chargeUpfront === false) {
      return true
    }

    return !!chargeAccounts?.length
  }, [companyInfo, chargeAccounts])

  return hasPaymentMethod
}
