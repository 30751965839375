import {
  Location,
  Position,
  ShiftSupervisor,
  WorkShift,
} from 'typings/common_defs'
import {
  addDays,
  addHours,
  addMinutes,
  roundToNearestMinutes,
  startOfDay,
} from 'date-fns'
import { CompanyWorker } from 'api/worker'
import { pick } from 'ramda'
import { companyMembers } from '../data/companyMembers'
import { DemoApiMock } from 'api/demo/types'
import { getDemoData, matchRestRequest, setDemoData } from 'api/demo/utils'
import { NewShiftSubmissionRequest } from 'api/shift'

export const mockCreateShift: DemoApiMock = {
  shouldMock: (request) =>
    matchRestRequest(request, /\/company\/\d+\/shifts$/, 'POST'),
  getResponse: async (request) => {
    const params = request.data as NewShiftSubmissionRequest
    const demoData = getDemoData()
    const { locations, positions, shifts, supervisors } = demoData
    const location = locations.find((l) => l.id === params.location_id)
    const position = positions.find((p) => p.id === params.position_id)
    if (!location || !position) {
      throw new Error('Location or position not found')
    }

    const baseShift = {
      ...createStandardShift(),
      location,
      position,
      locationless: params.locationless,
      payRate: params.pay_rate,
      payLumpSum: params.pay_lump_sum,
    }

    const baseSupervisors = params.supervisors.map((supervisor) => ({
      userId: supervisor.user_id,
      name: supervisor.name,
      phoneNumber: supervisor.phone_number ?? '',
      email: supervisor.email,
    }))

    const newSupervisors = [] as ShiftSupervisor[]
    const baseShiftId = shifts[shifts.length - 1].id + 1
    const baseSupervisorId = (supervisors[supervisors.length - 1].id ?? 0) + 1

    const newShifts = params.shifts.map((shift, index) => {
      const shiftId = baseShiftId + index
      const shiftSupervisors = baseSupervisors.map(
        (supervisor, supervisorIdx) => ({
          ...supervisor,
          id: baseSupervisorId + index + supervisorIdx,
          shiftId,
        })
      )
      newSupervisors.push(...shiftSupervisors)
      return {
        ...baseShift,
        id: shiftId,
        startsAt: shift.starts_at,
        endsAt: shift.ends_at,
        workersNeeded: shift.workers_needed,
        lunchLength: shift.lunch_length,
        supervisors: shiftSupervisors,
      }
    }) as WorkShift[]

    const updatedDemoData = {
      ...demoData,
      shifts: [...shifts, ...newShifts],
      supervisors: [...supervisors, ...newSupervisors],
    }

    setDemoData(updatedDemoData)

    return [newShifts[0].id]
  },
}

function getWorkStatus(needsReview: boolean, startsAt: Date, endsAt: Date) {
  const now = new Date()
  if (startsAt < now) {
    if (endsAt < now) {
      return needsReview ? 'employer_review' : 'paid'
    }
    return 'started'
  }

  return 'scheduled'
}

export function createMockShiftsForCategory(
  locations: Location[],
  positions: Position[],
  workers: CompanyWorker[]
) {
  const today = new Date()
  const shifts: WorkShift[] = []
  let workCount = 0
  const supervisors = [] as ShiftSupervisor[]

  const addShift = (
    startsAt: Date,
    endsAt: Date,
    location: Location,
    cushion = 0,
    needsReview = false
  ) => {
    const shiftId = shifts.length + 1
    const workersNeeded = Math.round(Math.random() * 10 + 5)
    const shiftWorkers = getRandomWorkers(
      workers,
      shifts,
      workersNeeded + cushion,
      startsAt
    )
    const supervisor = {
      ...getRandomSupervisor(supervisors.length),
      shiftId,
    }
    supervisors.push(supervisor)
    const position = getRandomPosition(positions)
    workCount++
    const workStatus = getWorkStatus(needsReview, startsAt, endsAt)
    shifts.push({
      ...createStandardShift(),
      id: shiftId,
      startsAt: startsAt.toISOString(),
      endsAt: endsAt.toISOString(),
      workersNeeded,
      work: shiftWorkers.map((worker, workerIdx) => ({
        id: workCount + workerIdx,
        startedAt: startsAt.toISOString(),
        completedAt:
          workStatus === 'employer_review'
            ? addMinutes(endsAt, 30).toISOString()
            : workStatus === 'paid'
            ? endsAt.toISOString()
            : undefined,
        status: workStatus,
        worker: pick(['id', 'name', 'profilePicUrl'], worker),
        e2wReview:
          workStatus === 'paid'
            ? {
                rating: 5,
                tags: JSON.stringify([getRandomTag()]),
              }
            : undefined,
      })),
      location,
      position,
      supervisors: [supervisor],
      ...getPayForPosition(position),
    })
  }

  // create shifts for the past 15 days
  for (let i = -1; i > -15; i--) {
    const date = startOfDay(addDays(today, i))
    // create 1 shift per location per day
    for (const location of locations) {
      const startsAt = addHours(date, 7 + locations.indexOf(location) * 2)
      const endsAt = addHours(startsAt, 8)
      if (i === -1 && locations.indexOf(location) === locations.length - 1) {
        addShift(startsAt, endsAt, location, 0, true)
      } else {
        addShift(startsAt, endsAt, location)
      }
    }
  }

  // create shifts for today
  // at least 1 shift in progress
  const todayFirstShiftStartsAt = roundToNearestMinutes(addHours(today, -1), {
    nearestTo: 30,
  })
  addShift(
    todayFirstShiftStartsAt,
    addHours(todayFirstShiftStartsAt, 8),
    locations[0],
    2 // cushion for in-progress shift
  )
  for (const location of locations) {
    const locationIdx = locations.indexOf(location)
    if (locationIdx === 0) continue
    const date = startOfDay(today)
    const startsAt = addHours(date, 7 + locationIdx * 2)
    const endsAt = addHours(startsAt, 8)
    addShift(startsAt, endsAt, location, 2)
  }

  // create shifts for the next 15 days
  for (let i = 1; i < 15; i++) {
    const date = startOfDay(addDays(today, i))
    // create 1 shift per location per day
    for (const location of locations) {
      const startsAt = addHours(date, 7 + locations.indexOf(location) * 2)
      const endsAt = addHours(startsAt, 8)
      addShift(startsAt, endsAt, location)
    }
  }

  // sort shift chronologically by startsAt and then by id
  shifts.sort((a, b) => {
    const startsAtDiff =
      new Date(a.startsAt!).getTime() - new Date(b.startsAt!).getTime()
    if (startsAtDiff !== 0) {
      return startsAtDiff
    }
    return a.id - b.id
  })

  return { shifts, supervisors }
}

function getRandomTag() {
  const tags = ['above_and_beyond', 'very_productive', 'great_attitude']
  return tags[Math.floor(Math.random() * tags.length)]
}

function createStandardShift() {
  return {
    cancellationPolicy: {
      policyWindows: [
        {
          cutoffTimeHours: 24,
          chargePercentage: 100,
        },
      ],
    },
    isTryout: false,
    locationless: false,
    recommendBackupShift: false,
    unableToAttendWork: [],
    clockInCode: generateClockCode(),
    clockOutCode: generateClockCode(),
    shiftBonuses: [],
  }
}

function generateClockCode() {
  return Math.round(Math.random() * 8999 + 1000)
}

function getRandomWorkers(
  allWorkers: CompanyWorker[],
  shifts: WorkShift[],
  numWorkers: number,
  date: Date
) {
  // get a list of workers who have not worked on given date
  const workers = shuffle(
    allWorkers.filter(
      (worker) =>
        !shifts.some(
          (shift) =>
            new Date(shift.startsAt!).getDate() === date.getDate() &&
            (shift.work ?? []).some((work) => work.worker?.id === worker.id)
        )
    )
  ) as CompanyWorker[]
  // return the first n workers
  return workers.slice(0, numWorkers)
}

function getRandomPosition(positions: Position[]) {
  return positions[Math.floor(Math.random() * positions.length)]
}

function getRandomSupervisor(currentCount: number) {
  const supervisorIdx = Math.floor(Math.random() * companyMembers.length)
  const member = companyMembers[supervisorIdx]
  return {
    id: currentCount + 1,
    name: member.name,
    email: member.email,
    phoneNumber: member.phoneNumber,
    userId: member.id,
  } as ShiftSupervisor
}

function isDrivingPosition(position: Position) {
  return position.positionTemplateId === 22
}

function getPayForPosition(position: Position) {
  const isDriving = isDrivingPosition(position)
  // return random pay between 150 and 200 for driving positions
  if (isDriving) {
    return {
      payLumpSum: Math.round(Math.random() * 50 + 150).toFixed(2),
    }
  }

  return {
    payRate: Math.round(Math.random() * 10 + 15).toFixed(2),
  }
}

function shuffle(array: unknown[]) {
  let currentIndex = array.length,
    randomIndex

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }

  return array
}
