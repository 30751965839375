import {
  CalendarViewShift,
  CalendarViewWorker,
} from 'pages/AssignmentDetailPage/types'
import { graphql } from 'api/graphql'
import { format } from 'date-fns'
import { Assignment } from 'typings/common_defs'
import { groupBy, path } from 'ramda'

type GetCalendarEventsRequest = {
  assignmentId: number
  startDate: Date
  endDate: Date
}

type GetCalendarEventsResponse = {
  shifts: {
    [day: string]: CalendarViewShift[]
  }
  workers: CalendarViewWorker[]
}

type GetCalendarEventsResponseDTO = {
  data: {
    company: {
      assignment: Assignment & {
        shifts: CalendarViewShift[]
      }
    }
  }
}

export const getCalendarEvents = async ({
  assignmentId,
  startDate,
  endDate,
}: GetCalendarEventsRequest): Promise<GetCalendarEventsResponse> => {
  const startDateDay = format(startDate, 'yyyyMMdd')
  const endDateDay = format(endDate, 'yyyyMMdd')

  const data = await graphql<GetCalendarEventsResponseDTO>(
    `
      company {
        assignment(assignmentId: ${assignmentId}) {
          id
          name
          shifts(startDate: "${startDateDay}", endDate: "${endDateDay}") {
            id
            startsAt
            endsAt
            location {
              id
              name
              address {
                city
                lat
                long
                state
                street
                unit
                timezone
                zip
              }
            }
            locationless
            payLumpSum
            payRate
            position {
              id
              name
            }
            shiftBonuses {
              amount
            }
          }
        }
      }
    `,
    'assignment_calendar_view'
  )

  const shifts =
    path(['data', 'data', 'company', 'assignment', 'shifts'], data) || []

  const calendarViewShifts: GetCalendarEventsResponse['shifts'] = groupBy(
    (shift) => format(new Date(shift.startsAt), 'yyyy-MM-dd'),
    Array.isArray(shifts) ? shifts : []
  ) as GetCalendarEventsResponse['shifts']

  // FIXME: Implement this later
  const workers = []

  const response: GetCalendarEventsResponse = {
    shifts: calendarViewShifts,
    workers,
  }

  return response
}
