import { DemoApiMock } from '../types'
import { getDemoData, matchGraphqlRequest } from '../utils'

export const mockGetPositions: DemoApiMock = {
  shouldMock: (request) => matchGraphqlRequest(request, /company\{positions/),
  getResponse: async () => {
    const demoData = getDemoData()
    return {
      data: {
        company: {
          positions: demoData.positions,
        },
      },
    }
  },
}
