import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Heading,
  Modal,
  ModalCtaContainer,
  RadioGroup,
} from '@workwhile/ui'
import { TutorialCategory } from 'api/shift'
import { AllPositionTutorialCategories } from 'api/company/companyPosition'

interface Props {
  open: boolean
  category?: TutorialCategory
  location?: string
  position?: string
  isRemoving?: boolean
  title?: string
  removeTitle?: string
  showOptions?: boolean
  showNotifyWorkers?: boolean
  onOk: (propagate: boolean, notifyWorkers: boolean) => void
  onClose: () => void
}

export function SaveOptionsModal({
  open,
  title,
  category,
  location,
  position,
  removeTitle,
  isRemoving,
  showOptions,
  showNotifyWorkers,
  onOk,
  onClose,
}: Props) {
  const [propagate, setPropagate] = useState<boolean | null>(null)
  const [notifyWorkers, setNotifyWorkers] = useState<boolean>(false)
  const handleSave = () => {
    if (showOptions && propagate !== null) {
      onOk(propagate, notifyWorkers)
    } else {
      onOk(false, notifyWorkers)
    }
  }
  const disabled = showOptions && propagate === null
  const headingText = isRemoving ? removeTitle : title
  const removeText = showOptions ? 'Select & Remove' : 'Remove'
  const saveText = showOptions ? 'Select & Save' : 'Save'
  const buttonText = isRemoving ? removeText : saveText
  const categoryText =
    category && AllPositionTutorialCategories.includes(category)
      ? `for the ${position} position`
      : `at the ${location} location`
  return (
    <Modal open={open} onClose={onClose} maxWidth="55rem">
      <Box pb={4}>
        <Heading level={3}>{headingText}</Heading>
        {!!showOptions && (
          <RadioGroup
            options={[
              {
                label: 'This shift only',
                value: 'this_shift_only',
              },
              {
                label: `This shift and future shifts ${categoryText}`,
                value: 'apply_to_other_shifts',
              },
            ]}
            name="propagate"
            onChange={(value) => setPropagate(value !== 'this_shift_only')}
          />
        )}
      </Box>
      <ModalCtaContainer>
        {showNotifyWorkers ? (
          <Box flex={1} justifyContent="flex-start">
            <Checkbox
              value={notifyWorkers}
              onChange={(checked) => setNotifyWorkers(checked)}
            >
              Notify workers about this change
            </Checkbox>
          </Box>
        ) : null}
        <Box>
          <Button variant={'text'} onClick={onClose} mr={3}>
            Cancel
          </Button>
          <Button onClick={handleSave} disabled={disabled}>
            {buttonText}
          </Button>
        </Box>
      </ModalCtaContainer>
    </Modal>
  )
}
