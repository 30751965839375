import React from 'react'
import { usePositionEditor } from './usePositionEditor'
import { Button, Message, ModalCtaContainer } from 'ui'

export function Footer(props: { ctaText?: string }) {
  const { canGoBack, isSaving, goBack } = usePositionEditor()

  return (
    <ModalCtaContainer>
      {canGoBack ? (
        <Button variant={'text'} onClick={goBack}>
          Back
        </Button>
      ) : null}
      <Button loading={isSaving} type={'submit'}>
        {props.ctaText ?? 'Next'}
      </Button>
    </ModalCtaContainer>
  )
}
