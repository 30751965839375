import React, { ReactNode } from 'react'
import { Box, BoxProps, Flex, Heading } from 'ui'
import { NotificationButton } from './NotificationButton'
import styled from 'styled-components'

interface PageProps extends BoxProps {
  title?: string
  showNotification?: boolean
  headerCtas?: ReactNode
  alternateBackground?: boolean
  fullHeight?: boolean
  children: ReactNode
}

const PageWrapper = styled(Box)<{ $alternateBackground?: boolean }>`
  ${({ theme: { colors }, $alternateBackground }) => `
    ${
      $alternateBackground
        ? `
      &:before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        background-color: ${colors.neutrals[50]};
      }
    `
        : ''
    }
  `}
`

PageWrapper.defaultProps = {
  px: [3, 4, 5],
  py: [3, 4],
}

export function Page(props: PageProps) {
  const {
    title,
    alternateBackground,
    children,
    showNotification = false,
    headerCtas,
    fullHeight,
    ...boxProps
  } = props
  const showHeaderBar = showNotification || title || headerCtas

  return (
    <PageWrapper
      $alternateBackground={alternateBackground}
      {...(fullHeight ? { height: ['100%', '100%', '100vh'] } : {})}
      {...boxProps}
    >
      {showHeaderBar ? (
        <Box display={['none', 'none', 'flex']}>
          {title ? <Heading level={1}>{title}</Heading> : null}
          <Flex flex={1} alignItems={'center'} justifyContent={'flex-end'}>
            {headerCtas}
            {showNotification ? (
              <Box mx={3}>
                <NotificationButton />
              </Box>
            ) : null}
          </Flex>
        </Box>
      ) : null}
      {children}
    </PageWrapper>
  )
}
