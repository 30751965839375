import { useQuery, useQueryClient } from '@tanstack/react-query'
import { shiftTutorialsKey } from './keys'
import { getShiftTutorials } from 'api/shift'

export function useShiftTutorials(shiftId: number) {
  return useQuery({
    queryKey: shiftTutorialsKey(shiftId),
    queryFn: () => getShiftTutorials(shiftId),
  })
}

export function useInvalidateShiftTutorials() {
  const queryClient = useQueryClient()
  return function invalidateTutorials(shiftId: number) {
    return queryClient.invalidateQueries({
      queryKey: shiftTutorialsKey(shiftId),
    })
  }
}
