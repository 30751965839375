import { getDemoData, matchRestRequest, setDemoData } from 'api/demo/utils'
import { CompanyRoster, CompanyWorker, NewRosterRequest } from 'api/worker'
import { pick } from 'ramda'
import { KeysToSnakeCase } from 'typings/casing'
import { addDays } from 'date-fns'
import { DemoCategory } from 'api/demo/types'

export const mockCreateRoster = {
  shouldMock: (request) =>
    matchRestRequest(request, /\/company\/\d+\/roster$/, 'POST'),
  getResponse: async (request) => {
    return createRoster(request.data)
  },
}

export async function createRoster(
  rosterRequest: KeysToSnakeCase<NewRosterRequest>
) {
  const demoData = getDemoData()
  const workers = demoData.workers
  const { name, worker_ids } = rosterRequest
  const newRoster: CompanyRoster & { createdAt: string } = {
    id: Math.max(...demoData.rosters.map((item) => item.id), 0) + 1,
    name,
    createdAt: new Date().toISOString(),
    workers: worker_ids.map((workerId) => {
      const worker = workers.find(
        (worker) => worker.id === workerId
      ) as CompanyWorker
      return pick(['id', 'profilePicUrl', 'name'], worker)
    }),
  }
  setDemoData({
    ...demoData,
    rosters: [...demoData.rosters, newRoster],
  })
  return newRoster.id
}

function randomizeCreatedAt() {
  const daysAgo = Math.random() * 10 + 1
  return addDays(new Date(), -daysAgo).toISOString()
}

export function createMockRostersForCategory(
  category: DemoCategory,
  workers: CompanyWorker[]
): CompanyRoster[] {
  let rosterNames: string[] = []
  switch (category) {
    case 'hospitality':
      rosterNames = [
        'Event Staff Crew',
        'ABC Arena Parking Attendants',
        'Weekend Servers',
      ]
      break
    case 'driving':
      rosterNames = [
        'Morning Delivery Crew',
        'Downtown Delivery Crew',
        'Heavy Parcels Crew',
      ]
      break
    case 'warehouse':
      rosterNames = [
        'Pick & Pack Staff',
        'Receiving Crew',
        'Warehouse Associate Superstars',
      ]
      break
    default:
      rosterNames = ['Headquarters Crew', 'ABC Branch Staff', 'Late Night Crew']
  }

  return [
    {
      id: 1,
      name: 'Headquarters Crew',
      workers: workers
        .slice(0, 10)
        .map((worker) => pick(['id', 'name', 'profilePicUrl'], worker)),
    },
    {
      id: 2,
      name: 'ABC Branch Staff',
      workers: workers
        .slice(10, 15)
        .map((worker) => pick(['id', 'name', 'profilePicUrl'], worker)),
    },
    {
      id: 3,
      name: 'Late Night Crew',
      workers: workers
        .slice(16, 25)
        .map((worker) => pick(['id', 'name', 'profilePicUrl'], worker)),
    },
  ].map((roster, index) => ({
    ...roster,
    name: rosterNames[index] || roster.name,
    createdAt: randomizeCreatedAt(),
  }))
}
