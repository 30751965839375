import React from 'react'
import { CompanyWorkerLog } from 'api/worker'
import { Box, Text } from 'ui'
import { format } from 'date-fns'

export function BonusLog(props: { data: CompanyWorkerLog }) {
  const { data } = props

  return (
    <Box py={2} borderTop={'1px solid'} borderColor={'offWhite'}>
      <Text fontWeight={1}>
        {format(new Date(data.timestamp), 'MMM d, yyyy hh:mmaaa')}
      </Text>
      <Text>{data.message}</Text>
    </Box>
  )
}
