import { useQuery } from '@tanstack/react-query'
import { shiftForEditKey } from './keys'
import { getShiftForEdit } from 'api/shift'

interface UseShiftForEditOptions {
  shiftId?: number
}

export function useShiftForEdit(options: UseShiftForEditOptions) {
  const { shiftId } = options
  return useQuery({
    queryKey: shiftForEditKey(shiftId),
    queryFn: async () => {
      if (shiftId) {
        return getShiftForEdit(shiftId)
      }
      return
    },
    enabled: !!shiftId,
  })
}
