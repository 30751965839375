import { useQuery } from '@tanstack/react-query'
import { getW2MinimumLunchLength } from 'api/company/companyLocation'
import { useAuthContext } from 'components/auth'

export interface W2MinimumLunchLengthQueryOptions {
  locationId?: number
  startTime?: Date
  endTime?: Date
}

export function useW2MinimumLunchLength(
  options: W2MinimumLunchLengthQueryOptions
) {
  const { locationId, startTime, endTime } = options
  const { company } = useAuthContext()

  return useQuery({
    queryKey: ['minimumLunchLength', locationId, startTime, endTime],
    queryFn: () => {
      if (!company?.id || !locationId || !startTime || !endTime) {
        return
      }
      return getW2MinimumLunchLength({
        companyId: company.id,
        locationId,
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
      })
    },
    enabled: !!company && !!locationId && !!startTime && !!endTime,
    placeholderData: (previousData) => previousData ?? 0,
  })
}
