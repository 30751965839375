import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Select, SelectProps } from 'ui'
import { useAssignedWorkRow } from '../useAssignedWorkerRow'
import { Work } from 'typings/common_defs'
import { useShiftDetail } from 'pages/ShiftDetailPage/useShiftDetail'
import { didWorkerWorkOvertime, didWorkerWorkUndertime } from 'lib/work'
import { AssignedWorkerOptionsEnum } from 'lib/work/assigned_work_options'

/**
 * Legacy AssignedWorkerOptionsSelector component before support for MinPayPolicy was introduced
 */

interface Props extends SelectProps {
  work: Work
}

type Option = {
  label: string
  value: string | null
  isDefault?: boolean
}

const CLOCK_IN_TIME_ONLY_OPTIONS: Option[] = [
  {
    label: 'Select Action',
    value: null,
    isDefault: true,
  },
  {
    label: 'No-show (no pay and will be blocked)',
    value: AssignedWorkerOptionsEnum.NO_SHOW,
  },
]

const HOURLY_UNDERTIME_LEFT_EARLY_CLOCK_OUT_OPTIONS: Option[] = [
  {
    label: 'Full Pay (Dismissed Early)',
    value: AssignedWorkerOptionsEnum.DISMISSED_NO_MORE_WORK,
    isDefault: true,
  },
  {
    label: 'No-show (no pay and will be blocked)',
    value: AssignedWorkerOptionsEnum.NO_SHOW,
  },
  {
    label: 'Prorated Pay (Did not meet requirements of the shift)',
    value: AssignedWorkerOptionsEnum.REJECTED_BY_EMPLOYER,
  },
  {
    label: 'Prorated Pay (Left early for personal reasons)',
    value: AssignedWorkerOptionsEnum.PERSONAL_REASONS,
  },
]

const HOURLY_UNDERTIME_LEFT_LATE_CLOCK_OUT_OPTIONS: Option[] = [
  {
    label: 'Prorated Pay (arrived late)',
    value: AssignedWorkerOptionsEnum.WORKED_UNDERTIME_ARRIVED_LATE,
    isDefault: true,
  },
  {
    label: 'No-show (no pay and will be blocked)',
    value: AssignedWorkerOptionsEnum.NO_SHOW,
  },
]

const HOURLY_OVERTIME_CLOCK_OUT_OPTIONS: Option[] = [
  {
    label: 'Worked overtime (hourly pay multiplied by time worked)',
    value: AssignedWorkerOptionsEnum.WORKED_OVERTIME,
    isDefault: true,
  },
  {
    label: 'No-show (no pay and will be blocked)',
    value: AssignedWorkerOptionsEnum.NO_SHOW,
  },
]

const HOURLY_NORMAL_CLOCK_OUT_OPTIONS: Option[] = [
  {
    label: 'Select Action',
    value: null,
    isDefault: true,
  },
  {
    label: 'No-show (no pay and will be blocked)',
    value: AssignedWorkerOptionsEnum.NO_SHOW,
  },
]

const LUMP_SUM_UNDERTIME_CLOCK_OUT_OPTIONS: Option[] = [
  {
    label: 'Full Pay (Completed work)',
    value: null,
    isDefault: true,
  },
  {
    label: 'No-show (no pay and will be blocked)',
    value: AssignedWorkerOptionsEnum.NO_SHOW,
  },
]

const LUMP_SUM_OVERTIME_CLOCK_OUT_OPTIONS: Option[] = [
  {
    label: 'Full Pay (Completed work)',
    value: null,
    isDefault: true,
  },
  {
    label: 'No-show (no pay and will be blocked)',
    value: AssignedWorkerOptionsEnum.NO_SHOW,
  },
]

export function LegacyAssignedWorkerOptionsSelector(props: Props) {
  const { work, ...otherProps } = props
  const { shift } = useShiftDetail()
  const { data, setValue } = useAssignedWorkRow(work.id)
  const [openDropdown, setOpenDropdown] = useState(false)
  const initialStartedAt = useRef(data.startedAt)
  const initialCompletedAt = useRef(data.completedAt)

  const options: Option[] = useMemo(() => {
    // options are determined based on the data the biz portal user has entered
    // and the type of shift

    if (!shift || !data) {
      return []
    }

    const { startedAt, completedAt } = data
    const isHourly = !!shift.payRate

    if (!startedAt) {
      // worker has not clocked in yet
      return CLOCK_IN_TIME_ONLY_OPTIONS
    }

    if (startedAt && !completedAt) {
      return CLOCK_IN_TIME_ONLY_OPTIONS
    }

    if (startedAt && completedAt) {
      const isUnderTime = didWorkerWorkUndertime(data, shift)
      const isOverTime = didWorkerWorkOvertime(data, shift)
      const isClockingOutAfterShiftEnd = completedAt > shift.endsAt
      if (isHourly) {
        if (isUnderTime) {
          if (isClockingOutAfterShiftEnd) {
            return HOURLY_UNDERTIME_LEFT_LATE_CLOCK_OUT_OPTIONS
          }
          return HOURLY_UNDERTIME_LEFT_EARLY_CLOCK_OUT_OPTIONS
        } else if (isOverTime) {
          return HOURLY_OVERTIME_CLOCK_OUT_OPTIONS
        } else {
          return HOURLY_NORMAL_CLOCK_OUT_OPTIONS
        }
      } else {
        return isUnderTime
          ? LUMP_SUM_UNDERTIME_CLOCK_OUT_OPTIONS
          : LUMP_SUM_OVERTIME_CLOCK_OUT_OPTIONS
      }
    }

    return []
  }, [data.startedAt, data.completedAt, data.finishedEarlyCategory])

  const value = useMemo(() => {
    if (!props.value) {
      return options.find((option) => option.isDefault)
    }
    const foundOption = options.find((option) => option.value === props.value)
    if (!foundOption) {
      // if no option found, just show the default
      return options.find((option) => option.isDefault)
    }
    return foundOption
  }, [data.startedAt, data.completedAt, data.finishedEarlyCategory, options])

  useEffect(() => {
    // when the options are reset, set the finished early category to the default
    const defaultOption = options.find((option) => option.isDefault)
    setValue('finishedEarlyCategory', defaultOption?.value || null)
  }, [options])

  useEffect(() => {
    // if the times are changed, open the dropdown
    if (
      data.startedAt &&
      data.completedAt &&
      (data.startedAt !== initialStartedAt.current ||
        data.completedAt !== initialCompletedAt.current)
    ) {
      setOpenDropdown(true)
    }
  }, [data.startedAt, data.completedAt])

  const handleOpenDropdownChange = (isOpen: boolean) => {
    setOpenDropdown(isOpen)
  }

  if (!options || options.length === 0) return null

  return (
    <Select
      aria-label={'Assigned Worker Options Selector'}
      options={options}
      {...otherProps}
      isMulti={false}
      value={value}
      menuIsOpen={openDropdown} // Pass the prop to the Select component
      onMenuOpen={() => handleOpenDropdownChange(true)}
      onMenuClose={() => handleOpenDropdownChange(false)}
    />
  )
}
