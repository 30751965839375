// TODO(Alex): Figure out how to move this into @workwhile/ui

import { Box, Flex, Text, useAppTheme } from '@workwhile/ui'
import { LucideChevronLeft, LucideChevronRight } from 'lucide-react'
import React, { useState } from 'react'

interface GridTableProps<T> {
  data: T[]
  renderCell: (item: T, index: number) => React.ReactNode
  rowsPerPage: number
  columnsPerPage: number
  totalCellsText?: string // Optional prop for custom text
  cellWidth?: number // Optional prop for cell width to maintain cell size consistency on last page
  minCellHeight?: number // Optional prop for cell height to maintain cell size consistency on last page
  showPageNumbers?: boolean
  showTotalCellsText?: boolean
}

export const GridTable = <T,>({
  data,
  renderCell,
  rowsPerPage,
  columnsPerPage,
  totalCellsText = 'Total Cells', // Default text if not provided
  cellWidth,
  minCellHeight,
  showPageNumbers = true,
  showTotalCellsText = true,
}: GridTableProps<T>) => {
  const { colors } = useAppTheme()
  const maxCellsPerPage = rowsPerPage * columnsPerPage
  const totalPages = Math.ceil(data.length / maxCellsPerPage)
  const [currentPage, setCurrentPage] = useState(0)

  const startIndex = currentPage * maxCellsPerPage
  const endIndex = Math.min(startIndex + maxCellsPerPage, data.length)
  const currentData = data.slice(startIndex, endIndex)

  const shouldPad = totalPages > 1 && currentPage === totalPages - 1

  // Determine the number of columns based on the data available for the current page
  const actualColumns = shouldPad
    ? columnsPerPage // Always use the full number of columns on the last page if there are multiple pages
    : Math.min(Math.ceil(currentData.length / rowsPerPage), columnsPerPage)

  // Determine if padding is necessary (only for the last page when there are multiple pages)
  const paddedData = shouldPad
    ? [
        ...currentData,
        ...Array(maxCellsPerPage - currentData.length).fill(null),
      ]
    : currentData

  // Organize data into columns
  const columns = Array.from({ length: actualColumns }).map((_, colIndex) =>
    paddedData.slice(colIndex * rowsPerPage, (colIndex + 1) * rowsPerPage)
  )

  const goBackAPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1)
    }
  }

  const goForwardAPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1)
    }
  }

  return (
    <Box>
      <Flex
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-around"
        width="100%"
      >
        {columns.map((columnData, colIndex) => (
          <Box key={colIndex} style={{ minWidth: cellWidth, flexGrow: 1 }}>
            {columnData.map((item, index) => (
              <Box
                key={index}
                style={{
                  width: cellWidth,
                  minHeight: minCellHeight,
                }}
              >
                {item ? (
                  renderCell(item, index)
                ) : (
                  <Text style={{ visibility: 'hidden' }}>Placeholder</Text>
                )}
              </Box>
            ))}
          </Box>
        ))}
      </Flex>
      <Flex justifyContent="space-between" alignItems="center" mt={2}>
        {showTotalCellsText ? (
          <Text fontSize={2} fontWeight="bold">
            {totalCellsText}: {data.length}
          </Text>
        ) : null}
        {showPageNumbers ? (
          <Flex alignItems="center">
            <Flex
              alignItems="center"
              onClick={goBackAPage}
              style={{ cursor: currentPage !== 0 ? 'pointer' : 'not-allowed' }}
            >
              <LucideChevronLeft
                size={24}
                color={
                  currentPage !== 0 ? colors.primary : colors.neutrals[200]
                }
              />
            </Flex>
            {Array.from({ length: totalPages }, (_, index) => (
              <Box
                borderRadius="50%"
                pl={1}
                pr={1}
                bg={index === currentPage ? 'primary' : 'white'}
              >
                <Text
                  style={{
                    cursor: 'pointer',
                  }}
                  key={index}
                  onClick={() => setCurrentPage(index)}
                  m={2}
                  color={index === currentPage ? colors.white : colors.primary}
                >
                  {index + 1}
                </Text>
              </Box>
            ))}
            <Flex
              alignItems="center"
              onClick={goForwardAPage}
              style={{
                cursor:
                  currentPage !== totalPages - 1 ? 'pointer' : 'not-allowed',
              }}
            >
              <LucideChevronRight
                size={24}
                color={
                  currentPage !== totalPages - 1
                    ? colors.primary
                    : colors.neutrals[200]
                }
              />
            </Flex>
          </Flex>
        ) : null}
      </Flex>
    </Box>
  )
}
