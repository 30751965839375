export const shiftKey = ['shift']

export const duplicateShiftsKey = [...shiftKey, 'duplicateShifts']

export const shiftForEditKey = (shiftId?: number) => [
  ...shiftKey,
  'shiftForEdit',
  shiftId,
]

export const shiftTimelineRootKey = [...shiftKey, 'shiftTimeline']

export const shiftTimelineKey = (
  startsAt?: Date,
  locationId?: number,
  positionId?: number
) => [...shiftTimelineRootKey, startsAt?.toISOString(), locationId, positionId]

export const shiftCsvKey = (
  startDate?: Date,
  endDate?: Date,
  locationId?: number,
  positionId?: number
) => ['shiftCsv', startDate, endDate, locationId, positionId]

export const employerGuidanceKey = (
  positionId: number,
  locationId: number,
  mileage?: number,
  shiftDurationHours?: number
) => [
  'shiftEditor',
  'employerGuidance',
  positionId,
  locationId,
  mileage,
  shiftDurationHours,
]

export const shiftTutorialsKey = (shiftId: number) => [
  ...shiftKey,
  'shiftTutorials',
  shiftId,
]

export const shiftTutorialKey = (shiftId: number, tutorialId?: number) => {
  return [...shiftKey, 'shiftTutorial', shiftId, tutorialId]
}
