import { useQuery, useQueryClient } from '@tanstack/react-query'
import { companyLocationTutorialsKey } from './keys'
import { getLocationTutorials } from 'api/company'

export function useCompanyLocationTutorials(locationId: number) {
  return useQuery({
    queryKey: companyLocationTutorialsKey(locationId),
    queryFn: () => getLocationTutorials(locationId),
  })
}

export function useInvalidateCompanyLocationTutorials() {
  const queryClient = useQueryClient()
  return function invalidateTutorials(locationId: number) {
    return queryClient.invalidateQueries({
      queryKey: companyLocationTutorialsKey(locationId),
    })
  }
}
