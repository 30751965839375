import React, { useState, useEffect } from 'react'
import { BlobProvider } from '@react-pdf/renderer'
import { ShiftDetailPrintableRosterPDF } from './index'
import { useShiftDetail } from '../useShiftDetail'
import { useCompanyInfo } from 'queries/company'
import { ThemeProvider } from '@workwhile/ui'

interface PDFDownloaderProps {
  onDownloaded: () => void
  pdfName: string
}

export const PDFDownloader = ({
  onDownloaded,
  pdfName,
}: PDFDownloaderProps) => {
  const { shift } = useShiftDetail()
  const { data: company } = useCompanyInfo()
  const [blobUrl, setBlobUrl] = useState<string | null>(null)

  useEffect(() => {
    if (blobUrl) {
      const link = document.createElement('a')
      link.href = blobUrl
      link.download = pdfName
      link.click()
      onDownloaded() // Notify the parent after download
    }
  }, [blobUrl, onDownloaded])

  if (!shift || !company) return null

  return (
    <BlobProvider
      document={
        <ThemeProvider>
          <ShiftDetailPrintableRosterPDF shift={shift} company={company} />
        </ThemeProvider>
      }
    >
      {({ blob, url, loading, error }) => {
        if (!loading && blob && !error) {
          setBlobUrl(url)
        }
        return null // Don't render anything
      }}
    </BlobProvider>
  )
}
