import React, { useMemo } from 'react'
import { Tabs } from 'ui'
import { Page } from 'pages/layout'
import { PositionLocation } from './PositionLocation'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { AuthGuard, useAuthContext } from 'components/auth'
import { PermissionEnum } from 'api/userPermissions'
import { Account } from './Account'
import { Team } from './Team'
import { Logs } from './Logs'
import { DemoSettings } from 'pages/settings/Demo'
import { DEMO_COMPANY_ID } from 'api/demo'

export function SettingsPage() {
  const params = useParams<{ tab: string }>()
  const navigate = useNavigate()
  const { hasPermission, company } = useAuthContext()
  const tabs = useMemo(() => {
    return [
      hasPermission(PermissionEnum.ManageShifts)
        ? {
            value: 'position-location',
            label: 'Positions & Locations',
          }
        : null,
      {
        value: 'account',
        label: 'Account',
      },
      hasPermission(PermissionEnum.ManageShifts)
        ? {
            value: 'logs',
            label: 'Logs',
          }
        : null,
      hasPermission(PermissionEnum.ManageRoles)
        ? { value: 'team', label: 'Team' }
        : null,
      Number(company?.id) === DEMO_COMPANY_ID
        ? { value: 'demo', label: 'Demo' }
        : null,
    ].filter(Boolean) as Array<{ value: string; label: string }>
  }, [hasPermission])
  const selectedTab = params.tab ?? tabs[0].value

  return (
    <Page title={'Settings'}>
      <Tabs
        value={selectedTab}
        onValueChange={(value) => navigate(`/settings/${value}`)}
        tabs={tabs}
      >
        <Tabs.Content value={'position-location'}>
          <AuthGuard.Permission value={PermissionEnum.ManageShifts}>
            <PositionLocation />
          </AuthGuard.Permission>
        </Tabs.Content>
        <Tabs.Content value={'account'}>
          <Account />
        </Tabs.Content>
        <Tabs.Content value={'team'}>
          <AuthGuard.Permission value={PermissionEnum.ManageRoles}>
            <Team />
          </AuthGuard.Permission>
        </Tabs.Content>
        <Tabs.Content value={'logs'}>
          <AuthGuard.Permission value={PermissionEnum.ManageShifts}>
            <Logs />
          </AuthGuard.Permission>
        </Tabs.Content>
        <Tabs.Content value={'demo'}>
          <DemoSettings />
        </Tabs.Content>
      </Tabs>
    </Page>
  )
}
