import { TimelineShift } from 'api/shift'
import { Work } from 'typings/common_defs'
import {
  didWorkerWorkUndertime,
  didWorkerWorkOvertime,
  calculateEstimatedTimeWorkedInMinutes,
} from '.'

/**
 * Options that can be displayed when biz portal worker edits an assigned worker's work
 */
export enum AssignedWorkerOptionsEnum {
  PERSONAL_REASONS = 'personal_reasons', // worked undertime, left early for personal reasons
  REJECTED_BY_EMPLOYER = 'rejected_by_employer', // worked undertime, sent home early as did not meet requirements of the shift
  DISMISSED_NO_MORE_WORK = 'dismissed_no_more_work', // worked undertime, dismissed by supervisor as there was no work left to be done
  NO_SHOW = 'no_show', // worker did not show up for the shift
  LUMP_SUM_DID_NOT_COMPLETE = 'lump_sum_did_not_complete', // [NOT IMPLEMENTED ON BACKEND YET] lump sum shift, worker did not complete so should be partially paid
  WORKED_UNDERTIME_ARRIVED_LATE = 'worked_undertime_arrived_late', // worker arrived late, worked undertime so should be paid prorated
  WORKED_OVERTIME = 'worked_overtime', // worker worked overtime, should be paid prorated
}
/**
 * Options when a worker works undertime and leaves before shift end time
 * - these options map to the finishedEarlyCategory field on the work object
 * - see: https://github.com/workwhile/backend/blob/92b05a2e9aa13722d79244d5667bf2c3886596c2/lib/shift/work.py/#L83-L86
 */
export const FINISHED_EARLY_OPTIONS = [
  AssignedWorkerOptionsEnum.PERSONAL_REASONS,
  AssignedWorkerOptionsEnum.REJECTED_BY_EMPLOYER,
  AssignedWorkerOptionsEnum.DISMISSED_NO_MORE_WORK,
]
/**
 * Options when a worker should be paid prorated
 * - i.e. pay_rate * (work.completed_at - work.started_at) instead of pay_rate * (shift.ends_at - shift.starts_at))
 */
export const PRORATED_PAY_OPTIONS = [
  AssignedWorkerOptionsEnum.PERSONAL_REASONS,
  AssignedWorkerOptionsEnum.REJECTED_BY_EMPLOYER,
  AssignedWorkerOptionsEnum.LUMP_SUM_DID_NOT_COMPLETE,
  AssignedWorkerOptionsEnum.WORKED_UNDERTIME_ARRIVED_LATE,
  AssignedWorkerOptionsEnum.WORKED_OVERTIME,
]
/**
 * Finished early options that should be paid prorated
 */
export const FINISHED_EARLY_PRORATED_OPTIONS = FINISHED_EARLY_OPTIONS.filter(
  (option) => PRORATED_PAY_OPTIONS.includes(option)
)
/**
 * Finished early options where the full pay should be paid: pay_rate * (shift.ends_at - shift.starts_at))
 */
export const FINISHED_EARLY_FULL_PAY_OPTIONS = FINISHED_EARLY_OPTIONS.filter(
  (option) => !PRORATED_PAY_OPTIONS.includes(option)
)

export type AssignedWorkerOption = {
  label: string
  value: string | null
  isDefault?: boolean
}

export const CLOCK_IN_TIME_ONLY_OPTIONS: AssignedWorkerOption[] = [
  {
    label: 'Select Action',
    value: null,
    isDefault: true,
  },
  {
    label: 'No-show (no pay and will be blocked)',
    value: AssignedWorkerOptionsEnum.NO_SHOW,
  },
]

const HOURLY_UNDERTIME_LEFT_EARLY_CLOCK_OUT_OPTIONS: AssignedWorkerOption[] = [
  {
    label: 'Dismissed Early (Pay from when worker arrived till end of shift)',
    value: AssignedWorkerOptionsEnum.DISMISSED_NO_MORE_WORK,
    isDefault: true,
  },
  {
    label: 'No-show (No pay and will be blocked)',
    value: AssignedWorkerOptionsEnum.NO_SHOW,
  },
  {
    label: 'Did not meet requirements of the shift (Prorated pay)',
    value: AssignedWorkerOptionsEnum.REJECTED_BY_EMPLOYER,
  },
  {
    label: 'Left early for personal reasons (Prorated pay)',
    value: AssignedWorkerOptionsEnum.PERSONAL_REASONS,
  },
]

const HOURLY_UNDERTIME_LEFT_ON_TIME_OR_LATE_CLOCK_OUT_OPTIONS: AssignedWorkerOption[] =
  [
    {
      label:
        'Worked less than scheduled, finished on time or late (Prorated pay)',
      value: AssignedWorkerOptionsEnum.WORKED_UNDERTIME_ARRIVED_LATE,
      isDefault: true,
    },
    {
      label: 'No-show (No pay and will be blocked)',
      value: AssignedWorkerOptionsEnum.NO_SHOW,
    },
  ]

const HOURLY_OVERTIME_CLOCK_OUT_OPTIONS: AssignedWorkerOption[] = [
  {
    label: 'Worked extra',
    value: AssignedWorkerOptionsEnum.WORKED_OVERTIME,
    isDefault: true,
  },
  {
    label: 'No-show (No pay and will be blocked)',
    value: AssignedWorkerOptionsEnum.NO_SHOW,
  },
]

const HOURLY_NORMAL_CLOCK_OUT_OPTIONS: AssignedWorkerOption[] = [
  {
    label: 'Completed work (Full pay)',
    value: null,
    isDefault: true,
  },
  {
    label: 'No-show (No pay and will be blocked)',
    value: AssignedWorkerOptionsEnum.NO_SHOW,
  },
]

const LUMP_SUM_UNDERTIME_CLOCK_OUT_OPTIONS: AssignedWorkerOption[] = [
  {
    label: 'Completed work (Full pay)',
    value: null,
    isDefault: true,
  },
  {
    label: 'No-show (No pay and will be blocked)',
    value: AssignedWorkerOptionsEnum.NO_SHOW,
  },
]

const LUMP_SUM_OVERTIME_CLOCK_OUT_OPTIONS: AssignedWorkerOption[] = [
  {
    label: 'Completed work (Full pay)',
    value: null,
    isDefault: true,
  },
  {
    label: 'No-show (No pay and will be blocked)',
    value: AssignedWorkerOptionsEnum.NO_SHOW,
  },
]

/**
 * Worked less than or equal to the min pay limit (e.g. 4hrs)
 */
const HOURLY_MINPAYPOLICY_UNDERMINHOURS_CLOCK_OUT_OPTIONS = (
  minPayLengthHrs: number
): AssignedWorkerOption[] => [
  {
    label: `Dismissed Early (${minPayLengthHrs}hr Min Pay Policy - Pay from when worker arrived till ${minPayLengthHrs}hr mark)`,
    value: AssignedWorkerOptionsEnum.DISMISSED_NO_MORE_WORK,
    isDefault: true,
  },
  {
    label: 'No-show (No pay and will be blocked)',
    value: AssignedWorkerOptionsEnum.NO_SHOW,
  },
  {
    label: 'Did not meet requirements of the shift (Prorated pay)',
    value: AssignedWorkerOptionsEnum.REJECTED_BY_EMPLOYER,
  },
  {
    label: 'Left early for personal reasons (Prorated pay)',
    value: AssignedWorkerOptionsEnum.PERSONAL_REASONS,
  },
]

/**
 * Worked more than the min pay limit (e.g. 4hrs) but less than the shift length and left before shift end time
 */
const HOURLY_MINPAYPOLICY_UNDERTIME_LEFT_EARLY_CLOCK_OUT_OPTIONS: AssignedWorkerOption[] =
  [
    {
      label: `Dismissed early (Prorated pay)`,
      value: AssignedWorkerOptionsEnum.DISMISSED_NO_MORE_WORK,
      isDefault: true,
    },
    {
      label: 'No-show (No pay and will be blocked)',
      value: AssignedWorkerOptionsEnum.NO_SHOW,
    },
    {
      label: 'Left early for personal reasons (Prorated pay)',
      value: AssignedWorkerOptionsEnum.PERSONAL_REASONS,
    },
  ]

/**
 * Worked more than the min pay limit (e.g. 4hrs) but less than the shift length and left on time or late
 */
const HOURLY_MINPAYPOLICY_UNDERTIME_LEFT_ON_TIME_OR_LATE_CLOCK_OUT_OPTIONS: AssignedWorkerOption[] =
  [
    {
      label:
        'Worked less than scheduled, finished on time or late (Prorated pay)',
      value: AssignedWorkerOptionsEnum.WORKED_UNDERTIME_ARRIVED_LATE,
      isDefault: true,
    },
    {
      label: 'No-show (No pay and will be blocked)',
      value: AssignedWorkerOptionsEnum.NO_SHOW,
    },
  ]

const getLumpSumClockOutOptions = (
  data: Work,
  shift: TimelineShift
): AssignedWorkerOption[] => {
  const isUnderTime = didWorkerWorkUndertime(data, shift)
  if (isUnderTime) {
    return LUMP_SUM_UNDERTIME_CLOCK_OUT_OPTIONS
  }
  return LUMP_SUM_OVERTIME_CLOCK_OUT_OPTIONS
}

const getHourlyClockOutOptions = (
  data: Work,
  shift: TimelineShift
): AssignedWorkerOption[] => {
  const isUnderTime = didWorkerWorkUndertime(data, shift)
  const isOverTime = didWorkerWorkOvertime(data, shift)

  if (isUnderTime) {
    return getHourlyUndertimeClockOutOptions(data, shift)
  }

  if (isOverTime) {
    return HOURLY_OVERTIME_CLOCK_OUT_OPTIONS
  }

  return HOURLY_NORMAL_CLOCK_OUT_OPTIONS
}

const getHourlyUndertimeClockOutOptions = (
  data: Work,
  shift: TimelineShift
): AssignedWorkerOption[] => {
  const { completedAt } = data
  const isClockingOutAtOrAfterShiftEnd = completedAt
    ? new Date(completedAt) >= new Date(shift.endsAt)
    : false
  const minPayLengthMins = shift.minimumPayPolicy?.minPayLength
  /**
   * If there is a 4hr min pay policy in place, then:
   * - if the worker worked less than 4hrs and they were dismissed early,
   *   they will get paid for 4hrs.
   * - if the worker worked more than 4hrs and they were dismissed early,
   * - they will get paid for time worked. (e.g. if the shift is from 5am-5pm, but the worker worked 5am - 3pm they will get paid for 10hrs)
   */
  if (minPayLengthMins) {
    const workDurationMinutes = calculateEstimatedTimeWorkedInMinutes(data)
    const isUnderMinHours = workDurationMinutes < minPayLengthMins
    const minPayLengthHrs = minPayLengthMins / 60
    if (isUnderMinHours) {
      return HOURLY_MINPAYPOLICY_UNDERMINHOURS_CLOCK_OUT_OPTIONS(
        minPayLengthHrs
      )
    }
    if (isClockingOutAtOrAfterShiftEnd) {
      return HOURLY_MINPAYPOLICY_UNDERTIME_LEFT_ON_TIME_OR_LATE_CLOCK_OUT_OPTIONS
    }
    return HOURLY_MINPAYPOLICY_UNDERTIME_LEFT_EARLY_CLOCK_OUT_OPTIONS
  } else {
    if (isClockingOutAtOrAfterShiftEnd) {
      return HOURLY_UNDERTIME_LEFT_ON_TIME_OR_LATE_CLOCK_OUT_OPTIONS
    }
    return HOURLY_UNDERTIME_LEFT_EARLY_CLOCK_OUT_OPTIONS
  }
}

/**
 * Returns the options that can be displayed when a worker clocks out
 * @param data - the work object that is editable by the biz portal user
 * @param shift - the shift the worker is working on
 * @returns {AssignedWorkerOption[]}
 */
export const getClockOutOptions = (
  data: Work,
  shift: TimelineShift
): AssignedWorkerOption[] => {
  const isHourly = !!shift.payRate
  if (isHourly) {
    return getHourlyClockOutOptions(data, shift)
  } else {
    return getLumpSumClockOutOptions(data, shift)
  }
}
