import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getAuth } from 'firebase/auth'
import { useInvalidateUserPermissions } from 'queries/user/useUserPermissions'

export function useSignOutMutation(options?: {
  onSuccess?: () => void
  onError?: (err: Error) => void
}) {
  const queryClient = useQueryClient()
  const { invalidate: invalidateUserPermissions } =
    useInvalidateUserPermissions()

  return useMutation({
    mutationFn: async () => {
      const auth = getAuth()
      await auth.signOut()
      await invalidateUserPermissions()
      queryClient.clear()
    },
    ...options,
  })
}
