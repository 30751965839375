import React, { useEffect, useMemo, useState } from 'react'
import { useAuthContext } from 'components/auth'
import { useIntercom } from 'react-use-intercom'
import { useLocation } from 'react-router-dom'

export function Intercom() {
  const { user } = useAuthContext()
  const { boot, update } = useIntercom()
  const [hasBooted, setHasBooted] = useState(false)
  const location = useLocation()

  const shouldHideLauncher = !!location.pathname.match('/messages')

  useEffect(() => {
    if (hasBooted) {
      update({
        hideDefaultLauncher: shouldHideLauncher,
      })
    }
  }, [location, hasBooted])

  useEffect(() => {
    if (user) {
      const intercomUser = {
        userId: user.uid,
        email: user.email ?? undefined,
        customAttributes: {
          status: 'client',
          type: 'client',
        },
        hideDefaultLauncher: shouldHideLauncher,
      }

      if (hasBooted) {
        update(intercomUser)
      } else {
        boot(intercomUser)
        setHasBooted(true)
      }
    }
  }, [user])

  return null
}
