import { useContext } from 'react'
import { AnalyticsContext } from './AnalyticsProvider'

export function useAnalyticsContext() {
  const context = useContext(AnalyticsContext)
  if (!context) {
    throw new Error(
      'useAnalyticsContext must be used within a AnalyticsProvider'
    )
  }
  return context
}
