import React, { Fragment } from 'react'
import {
  Flex,
  Modal,
  Button,
  Avatar,
  Text,
  Pluralize,
  Box,
  Paragraph,
} from 'ui'
import { BlockModalProps, BlockModalType } from './types'
import { differenceInMonths } from 'date-fns'

export function WorkerInformation(props: BlockModalProps) {
  const { setModalType, worker } = props

  return (
    <Modal
      title={'Before You Block'}
      width={[1, 600, 800, 900]}
      open={true}
      onClose={() => setModalType(null)}
      customCta={
        <>
          <Button
            variant={'text'}
            onClick={() => setModalType(BlockModalType.FillRate)}
          >
            Block Worker
          </Button>
          <Button onClick={() => setModalType(BlockModalType.Feedback)}>
            Give Feedback
          </Button>
        </>
      }
    >
      <Flex flexDirection={'column'} alignItems={'center'}>
        <Avatar
          user={{
            userId: worker.id.toString(),
            name: worker.name,
            photoUrl: worker.profilePicUrl,
          }}
          size={'xxlarge'}
        />
        <Text fontWeight={2} my={2}>
          {worker.name}
        </Text>
        {worker.favorites ? (
          <Box display={'inline-flex'} alignItems={'center'}>
            ❤️ Favorited by{' '}
            <Pluralize
              count={worker.favorites}
              oneText={'business'}
              manyText={'businesses'}
            />
          </Box>
        ) : null}
        <Flex
          flexWrap={'wrap'}
          mt={3}
          justifyContent={'space-between'}
          width={'100%'}
        >
          {[
            {
              value: worker.rating?.toFixed(2),
              label: 'out of 5 rating',
            },
            {
              value: worker.numShiftsForCompany,
              label: 'shifts worked for you',
            },
            {
              value: worker.firstActive
                ? differenceInMonths(new Date(), new Date(worker.firstActive))
                : null,
              label: 'months on WorkWhile',
            },
            {
              value: worker.numFinishedShifts,
              label: 'shifts on WorkWhile',
            },
          ].map(({ value, label }, index) => (
            <Fragment key={index}>
              {value ? (
                <Flex
                  width={[1, 2 / 5, 2 / 9]}
                  flexDirection={'column'}
                  alignItems={'center'}
                  mb={2}
                >
                  <Text fontWeight={2}>{value}</Text>
                  <Text textAlign={'center'}>{label}</Text>
                </Flex>
              ) : null}
            </Fragment>
          ))}
        </Flex>
        <Paragraph>
          {worker.name} has a good track record. We recommend giving this worker
          some <strong>feedback</strong> and giving them a second chance.
        </Paragraph>
      </Flex>
    </Modal>
  )
}
