import React, { useState } from 'react'
import { Button, Field, Heading, Input, Modal, toast } from 'ui'
import { WorkersSelector } from 'components/WorkersSelector'
import { CompanyWorker } from 'api/worker'
import {
  useCreateRosterMutation,
  useInvalidateCompanyRosters,
} from 'queries/company'
import { AddEmptyState } from 'pages/WorkersPage/AddEmptyState'

export function NewListModal() {
  const [listName, setListName] = useState('')
  const [step, setStep] = useState(-1)
  const [selected, setSelected] = useState([] as CompanyWorker[])
  const { invalidate: invalidateLists } = useInvalidateCompanyRosters()
  const { mutate: createRoster, isPending } = useCreateRosterMutation({
    onSuccess: () => {
      resetModal()
      invalidateLists()
      toast.success(`New list "${listName}" created successfully`)
    },
  })

  const onWorkersSelected = (selected: CompanyWorker[]) => {
    setSelected(selected)
    createRoster({
      name: listName,
      workerIds: selected.map((w) => w.id),
    })
  }

  const resetModal = () => {
    setStep(-1)
    setListName('')
    setSelected([])
  }

  return (
    <>
      <Modal
        width={[1, 600]}
        open={step === 0}
        title={'Create a New List'}
        onClose={() => setStep(-1)}
        customCta={
          <>
            <Button disabled={!listName.trim()} onClick={() => setStep(1)}>
              Next
            </Button>
          </>
        }
      >
        <Heading level={4}>
          Lists make it easy to target groups of workers for a shift.
        </Heading>
        <Field label={'List Name'}>
          <Input
            value={listName}
            onChange={(e) => setListName(e.target.value)}
            placeholder={'Enter List Name'}
          />
        </Field>
      </Modal>
      <WorkersSelector
        open={step === 1}
        selected={selected}
        loading={isPending}
        title={listName ? `Add Workers to ${listName}` : 'Add Workers to List'}
        onClose={() => setStep(-1)}
        onGoBack={() => setStep(0)}
        onChange={onWorkersSelected}
        emptyState={<AddEmptyState />}
      />
      <Button variant={'text'} onClick={() => setStep(0)}>
        + Create New List
      </Button>
    </>
  )
}
