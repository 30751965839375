import React, { useMemo } from 'react'
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { Box, Text } from '@workwhile/ui'
import { useAnalyticsContext } from '../useAnalyticsContext'
import { useTheme } from 'styled-components'
import { RatingTooltip } from './RatingTooltip'
import { format, parse } from 'date-fns'
import { apiDateFormat } from 'api/api'

export function RatingChart() {
  const { colors } = useTheme()
  const { insight } = useAnalyticsContext()

  const data = useMemo(() => {
    if (!insight) return null

    return insight.weeklyRatings.map((item) => ({
      ...item,
      weekStartDate: format(
        parse(item.weekStartDate, apiDateFormat, new Date()),
        'MMM d, yyyy'
      ),
    }))
  }, [insight])

  if (!data) return null

  return (
    <Box mt={4}>
      <Text color={'primary'} fontWeight={2} textAlign={'center'} my={3}>
        Average Rating
      </Text>
      <Box height={150} mt={3}>
        <ResponsiveContainer>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey={'weekStartDate'}
              tick={{ fill: colors.lightText, fontSize: 11, dy: 10 }}
            />
            <YAxis
              strokeWidth={0}
              type={'number'}
              domain={[0, 5]}
              ticks={[0, 1, 2, 3, 4, 5]}
            />
            <Tooltip content={<RatingTooltip />} />
            <Line
              dataKey="avgRating"
              stroke={colors.primary}
              fill={colors.primary}
              activeDot={{ r: 6 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  )
}
