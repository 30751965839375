import React from 'react'
import { Work } from 'typings/common_defs'
import { Flex, Textarea, Text } from 'ui'
import { useAssignedWorkRow } from '../useAssignedWorkerRow'
import { toTitleCase } from 'lib/string'
import { AssignedWorkerRatingTags } from './AssignedWorkerRatingTags'

interface Props {
  work: Work
}

/**
 * Inputs to supplement Star Rating e.g. comments, tags etc.
 */
export const AssignedWorkerRatingSupplement = ({ work }: Props) => {
  const { data, setValue } = useAssignedWorkRow(work.id)

  if (!data?.e2wReview?.rating) {
    // If no rating, don't show supplement
    return null
  }

  return (
    <Flex
      bg="neutrals.50"
      p={3}
      borderRadius={2}
      flex={1}
      flexDirection="column"
    >
      <Flex
        flexDirection={['column', 'column', 'column', 'row']}
        flex={1}
        justifyContent="space-between"
      >
        <AssignedWorkerRatingTags work={work} />
        {data?.e2wReview?.rating < 3 ? (
          <Text mb={2}>
            You must leave a comment for ratings less than 3 stars
          </Text>
        ) : null}
      </Flex>
      <Textarea
        value={data.finishedEarlyReason}
        onChange={(e) => setValue('e2wReview.comment', e.target.value)}
        minRows={1}
        placeholder={`How did ${toTitleCase(data.worker.name)} do?`}
      />
    </Flex>
  )
}
