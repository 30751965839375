import { CompanyMember } from 'api/company'

export const companyMembers = [
  {
    id: 15071,
    name: 'Pamela Halpert',
    email: 'workwhiledemo@gmail.com',
    phoneNumber: '',
    companyRolePerm: {
      id: 1,
      description: 'Company administrator',
    },
  },
  {
    id: 119408,
    name: 'Mark Peck',
    email: 'mark@trycatalog.com',
    phoneNumber: '1111111111',
    companyRolePerm: {
      id: 1,
      description: 'Company administrator',
    },
  },
] as CompanyMember[]
