import { getDemoData, matchGraphqlRequest } from '../utils'

export const mockGetOvertimeReviewShifts = {
  shouldMock: (request) =>
    matchGraphqlRequest(request, /company\{overtimeReviewShifts/),
  getResponse: async () => {
    const demoData = getDemoData()

    return {
      data: {
        company: {
          overtimeReviewShifts: demoData.shifts.filter((shift) =>
            (shift.work || []).some((work) => work.status === 'employer_review')
          ),
        },
      },
    }
  },
}

export const mockGetPendingReviewShifts = {
  shouldMock: (request) =>
    matchGraphqlRequest(request, /company\{performanceReviewShifts/),
  getResponse: async () => {
    const demoData = getDemoData()

    return {
      data: {
        company: {
          overtimeReviewShifts: demoData.shifts.filter((shift) =>
            (shift.work || []).some(
              (work) =>
                work.status &&
                ['completed', 'admin_review'].includes(work.status)
            )
          ),
        },
      },
    }
  },
}
