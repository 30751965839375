import { ReactNode } from 'react'
import { AuthenticatedProps } from './types'
import { useAuthContext } from './useAuthContext'
import { PermissionEnum } from 'api/userPermissions'

interface Props extends AuthenticatedProps {
  value: PermissionEnum | PermissionEnum[]
}

// similar to PermissionsGate
export default function Permission(props: Props): ReactNode {
  const { value, compareMode = 'any', children, fallback = null } = props
  const { user, permissions } = useAuthContext()

  if (user && !permissions) {
    return null
  }

  if (!permissions) {
    return fallback
  }

  const requiredPermissions = value
    ? Array.isArray(value)
      ? value
      : [value]
    : []

  if (requiredPermissions.length === 0) {
    throw new Error('At least 1 permission must be supplied to Permission')
  }

  if (
    compareMode === 'any' &&
    requiredPermissions.some((perm) => permissions[perm])
  ) {
    return children
  }

  if (
    compareMode === 'all' &&
    requiredPermissions.every((perm) => permissions[perm])
  ) {
    return children
  }

  return fallback
}
