import React, { useMemo } from 'react'
import { Page } from 'pages/layout'
import { Box, Flex, Heading, Loading, Text, toast } from '@workwhile/ui'
import { useParams, useNavigate } from 'react-router-dom'
import {
  useInvalidateShiftTutorials,
  useRemoveShiftTutorialMutation,
  useShiftTutorials,
} from 'queries/shift'
import {
  MarkdownContent,
  TutorialCategory,
  AllTutorialCategories,
} from 'api/shift/typings'
import { TutorialCard } from 'components/Tutorials/TutorialCard'
import { convertLegacyTutorialToMarkdown } from 'lib/tutorial'

export function ShiftInstructionsPage() {
  const shiftId = Number(useParams<{ shiftId: string }>().shiftId)
  const { data, isFetching } = useShiftTutorials(shiftId)
  const navigate = useNavigate()

  const invalidateTutorials = useInvalidateShiftTutorials()
  const { mutate: removeShiftTutorial, isPending: isRemoving } =
    useRemoveShiftTutorialMutation({
      shiftId,
      onSuccess() {
        invalidateTutorials(shiftId)
      },
      onError(error) {
        toast.error(error.message)
      },
    })

  const activeTutorials = useMemo(() => {
    if (!data?.tutorials) {
      return []
    }
    return data.tutorials.map((tutorial) => ({
      ...tutorial,
      content: convertLegacyTutorialToMarkdown(tutorial.content),
    }))
  }, [data])
  const inactiveTutorials = useMemo(
    () =>
      AllTutorialCategories.filter(
        (category) => !activeTutorials.some((t) => t.category === category)
      ).map((category) => ({
        category: category as TutorialCategory,
        content: [] as MarkdownContent[],
      })),
    [activeTutorials]
  )

  const handleRemove = ({
    tutorialId,
    propagate,
  }: {
    tutorialId: number
    propagate?: boolean
  }) => {
    removeShiftTutorial({
      tutorialId,
      propagate: propagate || false,
    })
  }

  const handleClick = (tutorialId: number | string) => {
    navigate(`/shift/${shiftId}/instruction/${tutorialId}`)
  }

  if (isFetching) {
    return <Loading />
  }

  return (
    <Page title={'Shift Instructions'}>
      <Text>
        Select any of the instruction categories below to add to your shift.
      </Text>
      {activeTutorials.length > 0 ? (
        <Box mt={4}>
          <Heading level={3}>Active</Heading>
          <Flex mx={-2} flexWrap={'wrap'}>
            {activeTutorials.map((tutorial) => (
              <Box
                key={tutorial.id}
                width={[1, 1 / 2, 1 / 2, 1 / 3, 1 / 4]}
                p={2}
              >
                <TutorialCard
                  data={tutorial}
                  position={data?.position}
                  location={data?.location}
                  isRemoving={isRemoving}
                  showOptions
                  removeTitle="Which shifts would you like to remove this instruction from?"
                  onRemove={handleRemove}
                  onClick={handleClick}
                />
              </Box>
            ))}
          </Flex>
        </Box>
      ) : null}
      {inactiveTutorials.length > 0 ? (
        <Box mt={4}>
          {activeTutorials.length > 0 ? (
            <Heading level={3}>Inactive</Heading>
          ) : null}
          <Flex mx={-2} flexWrap={'wrap'}>
            {inactiveTutorials.map((tutorial) => (
              <Box
                key={tutorial.category}
                width={[1, 1 / 2, 1 / 2, 1 / 3, 1 / 4]}
                p={2}
              >
                <TutorialCard
                  data={tutorial}
                  isRemoving={isRemoving}
                  onRemove={handleRemove}
                  onClick={handleClick}
                />
              </Box>
            ))}
          </Flex>
        </Box>
      ) : null}
    </Page>
  )
}
