import React from 'react'
import wwLogo from 'assets/ww_logo.png'

interface Props {
  size: 'small' | 'medium'
}

const sizes = {
  small: 24,
  medium: 50,
}

export function WWLogo(props: Props) {
  const { size } = props

  return (
    <img src={wwLogo} alt="WW Logo" width={sizes[size]} height={sizes[size]} />
  )
}
