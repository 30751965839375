import React from 'react'
import { AddPosition } from 'components/AddPosition'
import { Button, toast } from '@workwhile/ui'

interface Props {
  positionId: number
}

export function EditPositionButton(props: Props) {
  const { positionId } = props
  return (
    <AddPosition
      positionId={positionId}
      trigger={
        <Button kind={'small'} width={100}>
          Edit
        </Button>
      }
      onPositionUpdated={() => {
        toast.success('Successfully updated position')
      }}
    />
  )
}
