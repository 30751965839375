import { WorkerActions } from 'components/WorkerActions'
import React from 'react'
import { Work } from 'typings/common_defs'
import { Box, Flex, Text, Button } from 'ui'
import { StarRating } from '@workwhile/ui'
import { useAssignedWorkRow } from '../useAssignedWorkerRow'
import { shouldShowApproveButton } from 'lib/work'
import { AssignedWorkerOptionsEnum } from 'lib/work/assigned_work_options'
import { useShiftDetail } from 'pages/ShiftDetailPage/useShiftDetail'

interface Props {
  work: Work
}

export const AssignedWorkerRating = ({ work }: Props) => {
  const { shift } = useShiftDetail()
  const { data, setValue } = useAssignedWorkRow(work.id)

  if (!shift) return null

  if (!shouldShowApproveButton(data)) {
    return null
  }

  if (data?.finishedEarlyCategory === AssignedWorkerOptionsEnum.NO_SHOW) {
    return (
      <Flex flexDirection="row" alignItems="center" mr={3}>
        <Text color="errors.200">Will Be Blocked</Text>
      </Flex>
    )
  }

  return (
    <Flex flexDirection="row" alignItems="center" mr={1}>
      <Box mr={2}>
        <StarRating
          maxNumStars={5}
          selected={data.e2wReview?.rating}
          selectedChangedCallback={(value) =>
            setValue('e2wReview.rating', value)
          }
        />
      </Box>
      <Box
        height={20}
        minHeight={20}
        width={1}
        maxWidth={1}
        bg="neutrals.200"
        mr={1}
      />
      <Flex>
        <WorkerActions
          worker={data.worker}
          shiftId={shift.id}
          renderButton={(props) => (
            <Button
              variant={'secondary'}
              kind={'small'}
              mx={1}
              maxWidth={30}
              {...props}
            />
          )}
        />
      </Flex>
    </Flex>
  )
}
