import { api } from 'api'
import { MarkdownContent, QuizQuestion } from 'api/shift'
import { CancelTokenSource } from 'axios'
import { path } from 'ramda'

export interface GenerateQuizRequest {
  companyId: number
  text: {
    name: string
    content: MarkdownContent[]
  }
  cancelToken?: CancelTokenSource
}

export async function generateQuizFromText(
  request: GenerateQuizRequest
): Promise<QuizQuestion[]> {
  const { companyId, text, cancelToken } = request
  const payload = { text: JSON.stringify(text) }
  const response = await api.post(
    `/company/${companyId}/quiz_assistant`,
    payload,
    { cancelToken: cancelToken?.token }
  )

  return path(['data'], response)
}
