import { useNavigate, To, NavigateOptions, Location } from 'react-router'
import { useLocation } from 'react-router-dom'

export function useModalNavigate() {
  const navigate = useNavigate()
  const location: Location = useLocation()
  return (to: To, options: NavigateOptions = {}) => {
    const currentState = location.state || {}
    const backgroundStack = currentState.backgroundStack || []

    const newBackgroundStack = [...backgroundStack, location]

    const newState = {
      ...currentState,
      backgroundStack: newBackgroundStack,
    }

    navigate(to, { ...options, state: newState })
  }
}
