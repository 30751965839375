import React, { useEffect, useState } from 'react'
import { track } from 'lib/amplitude'
import { Box, Field, Heading, Input, Button, Message, Loading } from 'ui'
import { WWLogo } from 'components/WWLogo'
import * as zod from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from 'react-router'
import { Navigate, useLocation } from 'react-router-dom'
import {
  getAuth,
  MultiFactorError,
  AuthError,
  RecaptchaVerifier,
} from 'firebase/auth'
import { useSendMFACodeMutation, useVerifyMFACodeMutation } from 'queries/user'

const schema = zod.object({
  verificationCode: zod.string({
    required_error: 'Verification code is required',
  }),
})

type FormData = zod.infer<typeof schema>

export function VerifyMFAForm() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  })
  const navigate = useNavigate()
  const { state } = useLocation()
  const multiFactorError = (state ?? {}).multiFactorError as MultiFactorError
  const [authError, setAuthError] = useState<AuthError | null>(null)
  const [verificationId, setVerificationId] = useState('')

  const { mutate: sendMFACode, isPending: isSendingMFACode } =
    useSendMFACodeMutation({
      multiFactorError,
      onSuccess(verificationId: string) {
        setVerificationId(verificationId)
      },
      onError(error) {
        setAuthError(error)
      },
    })

  const { mutate: verifyMFACode, isPending: isVerifyingMFACode } =
    useVerifyMFACodeMutation({
      multiFactorError,
      onSuccess() {
        track('impression, success: firebase sign in')
        navigate('/home')
      },
      onError(error) {
        setAuthError(error)
      },
    })

  useEffect(() => {
    if (verificationId) {
      return
    }

    const auth = getAuth()
    const verifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
      },
      auth
    )
    sendMFACode({ verifier })
  }, [verificationId])

  const onSubmit = handleSubmit((data) => {
    track('button_click, verify_mfa_code')

    if (verificationId) {
      verifyMFACode({ verificationId, verificationCode: data.verificationCode })
    }
  })

  if (!multiFactorError) {
    return <Navigate to={'/'} />
  }

  return (
    <Box margin={'auto'} pb={4} width={[1, 450]}>
      <div id={'recaptcha-container'} />
      <form onSubmit={onSubmit}>
        <WWLogo size={'medium'} />
        <Heading level={2} my={4}>
          2-Factor Authentication
        </Heading>
        {isSendingMFACode ? <Loading /> : null}
        {verificationId ? (
          <>
            <Field
              label={'Verification code'}
              error={errors.verificationCode?.message?.toString()}
            >
              <Input
                disabled={isVerifyingMFACode}
                autoFocus={true}
                placeholder={'Enter the code sent to your phone number'}
                {...register('verificationCode')}
              />
            </Field>
            <Box mt={4}>
              <Button
                block={true}
                size={'large'}
                type={'submit'}
                loading={isVerifyingMFACode}
              >
                Verify
              </Button>
            </Box>
            {authError ? (
              <Message width={1} mt={2} mb={4} variant={'error'}>
                {authError.message}
              </Message>
            ) : null}
          </>
        ) : null}
      </form>
    </Box>
  )
}
