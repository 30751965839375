import { graphql } from 'api/graphql'
import { path } from 'ramda'
import { TimelineShift } from 'api/shift'

export async function getReviewOvertimeApprovalShifts() {
  const data = await graphql(
    `company {
        overtimeReviewShifts: shifts(status: "pending_approval") {
          id
          location {name, id, , address{timezone}}
          position {name, id}
          startsAt, endsAt
          work {
            id, status, completedAt, finishedEarlyCategory
          }
        },
      }`,
    'shifts_to_review_needs_attention'
  )

  return path(
    ['data', 'data', 'company', 'overtimeReviewShifts'],
    data
  ) as TimelineShift[]
}
