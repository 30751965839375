import React, { useEffect } from 'react'
import { Button, Modal, toast } from 'ui'
import { useShiftDetail } from '../useShiftDetail'
import { track } from 'lib/amplitude'
import { CancelModalContent } from 'pages/HomePage/ShiftCard/CancelModalContent'
import {
  useCancelShiftMutation,
  useRemoveShiftFromTimeline,
} from 'queries/shift'
import { useNavigate } from 'react-router-dom'

interface Props {
  open: boolean
  handleClose: () => void
}

export const CancelShiftModal = ({ open, handleClose }: Props) => {
  const navigate = useNavigate()
  const { shift } = useShiftDetail()
  const key = 'cancel_shift'

  useEffect(() => {
    // componentDidMount
    track(`impression, ${key}`)
  }, [])

  const { removeShift: removeShiftFromTimeline } = useRemoveShiftFromTimeline(
    shift?.id || -1
  )
  const { mutate: cancelShift, isPending: isCancelling } =
    useCancelShiftMutation({
      shiftId: shift?.id || -1,
      onSuccess: () => {
        toast.success('Shift has been cancelled')
        removeShiftFromTimeline()
        handleClose()
        navigate(-1)
      },
      onError: (err) => {
        toast.error(err.message)
      },
    })
  if (!shift) return null
  const handleCancelShift = () => {
    cancelShift()
  }
  return (
    <Modal
      title={'Are you sure you want to cancel this shift?'}
      open={open}
      width={[1, 900]}
      customCta={
        <Button
          variant="primary"
          kind="medium"
          onClick={handleCancelShift}
          loading={isCancelling}
        >
          Yes, Confirm
        </Button>
      }
    >
      <CancelModalContent shift={shift} />
    </Modal>
  )
}
