import React from 'react'
import { Modal, Message, Button, Paragraph } from 'ui'
import { BlockModalProps, BlockModalType } from './types'

export function FillRate(props: BlockModalProps) {
  const { setModalType, worker } = props

  return (
    <Modal
      title={'Warning: Blocking may reduce your fill rate'}
      open={true}
      onClose={() => setModalType(null)}
      customCta={
        <>
          <Button
            variant={'text'}
            onClick={() => setModalType(BlockModalType.Block)}
          >
            Confirm and Block Worker
          </Button>
          <Button onClick={() => setModalType(BlockModalType.Feedback)}>
            Give Feedback
          </Button>
        </>
      }
    >
      <Message variant={'warning'}>
        Blocking reduces the number of workers who can accept your shift.
      </Message>
      <Paragraph>
        Feedback can help {worker.name} improve and become a valuable asset to
        your team.
      </Paragraph>
    </Modal>
  )
}
