export const messageRootKey = ['message']
export const latestMessagesKey = [...messageRootKey, 'latestMessages']
export const workerMessagesKey = (workerId: number) => [
  ...messageRootKey,
  'workerMessages',
  workerId,
]
export const workerMessagesHeaderInfoKey = (
  workerId: number | undefined,
  shiftId: number | null
) => [...messageRootKey, 'workerMessages', 'headerInfo', workerId, shiftId]
export const threadShiftInfoKey = (shiftId: number | null) => [
  ...messageRootKey,
  'threadShiftInfo',
  shiftId,
]
export const messageableUpcomingShiftsKey = [
  ...messageRootKey,
  'messageableUpcomingShifts',
]
