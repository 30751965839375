import { useMutation, useQueryClient } from '@tanstack/react-query'
import { requestWorkers } from 'api/assignment/requestWorkers'
import { assignmentDetailKey } from './keys'

type UseRequestWorkersMutationProps = {
  assignmentId: number
  onSuccess?: () => void
}

export const useRequestWorkersMutation = ({
  assignmentId,
  onSuccess,
}: UseRequestWorkersMutationProps) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: requestWorkers,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: assignmentDetailKey(assignmentId),
      })
      onSuccess?.()
    },
  })
}
