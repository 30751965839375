import { useQuery } from '@tanstack/react-query'
import { companyLogsKey } from './keys'
import { getCompanyLogs } from 'api/company'

interface UseCompanyLogs {
  startDate: Date
  endDate: Date
  enabled?: boolean
}

export function useCompanyLogs(options: UseCompanyLogs) {
  const { startDate, endDate, enabled } = options

  return useQuery({
    queryKey: companyLogsKey(startDate, endDate),
    queryFn: () => getCompanyLogs({ startDate, endDate }),
    enabled,
  })
}
