import React from 'react'
import { WorkShift } from 'typings/common_defs'
import { Box, Pluralize } from 'ui'
import { AddressString } from 'components/AddressString'
import { getLocalTimezone } from 'lib/time'
import { formatInTimeZone } from 'date-fns-tz'

export function ShiftSummary(props: { data: WorkShift }) {
  const { data } = props
  const { location, startsAt, endsAt } = data
  const timezone = location?.address?.timezone ?? getLocalTimezone()

  return (
    <Box>
      {location?.address ? <AddressString address={location?.address} /> : null}
      {startsAt && endsAt ? (
        <Box>
          {formatInTimeZone(startsAt, timezone, 'MMM d, yyyy h:mmaaa')}
          &nbsp;-&nbsp;
          {formatInTimeZone(endsAt, timezone, 'MMM d, yyyy h:mmaaa zzz')}
        </Box>
      ) : null}
      <Box>
        <Pluralize
          count={data.workersNeeded ?? 0}
          oneText={'worker'}
          manyText={'workers'}
        />
        &nbsp;needed
      </Box>
    </Box>
  )
}
