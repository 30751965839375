import { useEffect, useMemo, useState } from 'react'
import { config } from 'config'
import { useUpdateEffect } from 'ahooks'

export function useApiHost() {
  const [override, setOverride] = useState(
    sessionStorage.getItem('useStagingApi') === 'yes'
  )
  const apiEnv = useMemo(() => {
    if (config.apiHostUrl === 'https://workwhile-staging.wl.r.appspot.com/v1') {
      return 'staging'
    }

    if (config.apiHostUrl === 'https://api.workwhilejobs.com/v1') {
      return 'production'
    }

    return 'local'
  }, [override])

  useUpdateEffect(() => {
    sessionStorage.setItem('useStagingApi', override ? 'yes' : 'no')
    window.location.reload()
  }, [override])

  return {
    apiEnv,
    apiHostUrl: config.apiHostUrl.replace('/v1', ''),
    override,
    setOverride,
  }
}
