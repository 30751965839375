import React from 'react'
import { Box, Flex, Heading } from 'ui'
import styled from 'styled-components'
import { AiOutlineMenu } from 'react-icons/ai'
import { useLayoutContext } from 'pages/layout/useLayoutContext'
import { NotificationButton } from 'pages/layout/NotificationButton'
import { MOBILE_NAV_HEIGHT } from 'pages/layout/index'

const NavContainer = styled(Box)`
  ${({ theme: { colors } }) => `
    position: fixed;
    z-index: 10000;
    width: 100%;
    left: 0;
    top: 0;
    
    .menu-trigger {
      background-color: transparent;
      outline: 0;
      border: 0;
      color: ${colors.white};
    }
  `}
`

export function MobileTopNav() {
  const { toggleMobileMenu, selectedNavOption } = useLayoutContext()

  return (
    <NavContainer
      className={'mobile-top-nav'}
      display={['flex', 'flex', 'none']}
      alignItems={'center'}
      backgroundColor={'primary'}
      height={MOBILE_NAV_HEIGHT}
      p={2}
    >
      <button
        aria-label={'Open Navigation Menu'}
        className={'menu-trigger'}
        onClick={toggleMobileMenu}
      >
        <AiOutlineMenu fontSize={22} />
      </button>
      {selectedNavOption ? (
        <Box ml={3}>
          <Heading level={4} my={0} color={'white'}>
            {selectedNavOption.text}
          </Heading>
        </Box>
      ) : null}
      <Flex flex={1} justifyContent={'flex-end'} pr={2}>
        {selectedNavOption?.mobileNotification ? <NotificationButton /> : null}
      </Flex>
    </NavContainer>
  )
}
