import { DemoApiMock } from '../types'
import { getDemoData, matchGraphqlRequest } from '../utils'

export const mockGetInsights: DemoApiMock = {
  shouldMock: (request) => matchGraphqlRequest(request, /company\{insight/),
  getResponse: async () => {
    const demoData = getDemoData()
    return {
      data: {
        company: {
          insight: demoData.insights,
        },
      },
    }
  },
}
