import React, { createContext, PropsWithChildren, useMemo } from 'react'
import { WorkerProfile } from 'api/worker'
import { useParams } from 'react-router-dom'
import { useWorkerProfile } from 'queries/worker'
import { Loading } from 'ui'

export interface WorkerProfileContextValues {
  profileData: WorkerProfile
  isLoading?: boolean
  isFetching?: boolean
}

export const WorkerProfileContext =
  createContext<WorkerProfileContextValues | null>(null)

export const WorkerProfileProvider = (props: PropsWithChildren) => {
  const params = useParams<{ workerId: string }>()
  const workerId = Number(params.workerId as string)
  const { data, isLoading, isFetching } = useWorkerProfile(workerId)

  const values = useMemo(() => {
    return {
      profileData: data as WorkerProfile,
      isLoading,
      isFetching,
    }
  }, [data, isLoading, isFetching])

  if (isLoading) {
    return <Loading />
  }

  return (
    <WorkerProfileContext.Provider value={values}>
      {props.children}
    </WorkerProfileContext.Provider>
  )
}
