import { useMutation } from '@tanstack/react-query'
import { BackupShiftRequest, postBackupShift } from 'api/shift'
import { useCompanyInfo } from 'queries/company'
import { AxiosError } from 'axios'

interface UseBackupShiftMutation {
  shiftId: number
  onSuccess?: () => void
  onError?: (err: AxiosError) => void
}

export function useBackupShiftMutation(options: UseBackupShiftMutation) {
  const { shiftId, ...otherOptions } = options
  const { data: company } = useCompanyInfo()

  return useMutation({
    mutationFn: async (data: BackupShiftRequest) => {
      if (!company?.id) {
        return
      }

      return postBackupShift(company.id, shiftId, data)
    },
    ...otherOptions,
  })
}
