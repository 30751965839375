import React, { useCallback, useMemo } from 'react'
import { Avatar, Box, Flex, Heading, Loading } from 'ui'
import { useWorkerMessages } from 'queries/message'
import { toTitleCase } from 'lib/legacy_util'
import { MessageBubble } from './MessageBubble'
import { reverse } from 'ramda'
import { ChatBox } from './ChatBox'

export function MessageDetails(props: { workerId: number }) {
  const { workerId } = props
  const { data, isLoading } = useWorkerMessages(workerId)
  const worker = useMemo(() => {
    if (data && data.items[0]) {
      const [from] = data.items[0].direction.split('2')
      return from === 'w' ? data.items[0].fromUser : data.items[0].toUser
    }

    return null
  }, [data])
  const messages = useMemo(() => {
    if (!data) {
      return []
    }

    const shiftIds: number[] = []
    return reverse(data.items).map((message) => {
      const showShiftInfo = !shiftIds.includes(Number(message.shift.id))
      shiftIds.push(Number(message.shift.id))
      return {
        data: message,
        showShiftInfo,
      }
    })
  }, [data])

  const scrollContainerRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node && data) {
        node.scrollTop = node.scrollHeight
      }
    },
    [data]
  )

  if (isLoading || !data) {
    return <Loading />
  }

  return (
    <Flex
      height={'100%'}
      flexDirection={'column'}
      boxShadow={['none', 'none', 'low']}
      borderRadius={'standard'}
    >
      <Box backgroundColor={'neutrals.50'} p={3}>
        <Flex flexDirection={'row'}>
          {worker ? (
            <Avatar
              size={'medium'}
              user={{
                userId: workerId.toString(),
                name: worker.name,
                photoUrl: worker.profilePicUrl,
              }}
            />
          ) : null}
          <Box ml={3} pt={2}>
            {worker ? (
              <Heading level={3} my={0}>
                {toTitleCase(worker.name)}
              </Heading>
            ) : null}
          </Box>
        </Flex>
      </Box>
      <Box flex={1} overflow={'auto'} ref={scrollContainerRef}>
        {messages.map((message, index) => (
          <MessageBubble
            data={message.data}
            showShiftInfo={message.showShiftInfo}
            key={index}
          />
        ))}
      </Box>
      <Box p={3}>
        {worker ? (
          <ChatBox workerId={worker.id} shiftId={data.currMessageableShiftId} />
        ) : null}
      </Box>
    </Flex>
  )
}
