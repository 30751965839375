import { useQuery } from '@tanstack/react-query'
import { shiftCsvKey } from './keys'
import { getShiftCsvData } from 'api/shift'
import { useCompanyInfo } from '../company'

interface UseShiftCSVOptions {
  startDate?: Date
  endDate?: Date
  locationId?: number
  positionId?: number
}

export function useShiftCSV(options: UseShiftCSVOptions) {
  const { startDate, endDate, locationId, positionId } = options
  const { data: company } = useCompanyInfo()

  return useQuery({
    queryKey: shiftCsvKey(startDate, endDate, locationId, positionId),
    queryFn: async () => {
      if (!company || !startDate || !endDate) return

      return getShiftCsvData({
        companyId: company.id,
        startDate,
        endDate,
        locationId,
        positionId,
      })
    },
    enabled: !!(company && startDate && endDate),
  })
}
