import React from 'react'
import EmptyWorkerAsset from 'assets/empty-list-workers.png'
import { Box, Flex, Heading, Text } from 'ui'

export function WorkerEmptyState() {
  return (
    <Flex
      flexDirection={'column'}
      borderRadius={'standard'}
      backgroundColor={'emptyState'}
      px={3}
      py={6}
      alignItems={'center'}
    >
      <img
        src={EmptyWorkerAsset}
        style={{ maxWidth: '100%', height: 58 }}
        alt={'No workers'}
      />
      <Box width={[1, 'auto']} px={[0, 5]}>
        <Heading level={4} color={'lightText'} textAlign={'center'}>
          Add workers to this List
        </Heading>
        <Text color={'lightText'} textAlign={'center'}>
          You can add your favorite workers here and assign jobs just for this
          List when you create a new shift.
        </Text>
      </Box>
    </Flex>
  )
}
