import React from 'react'
import { Button, Tooltip } from 'ui'
import { useCompanyPositions } from 'queries/company'

export function AddPositionTrigger() {
  const { data, isLoading } = useCompanyPositions()
  const trigger = <Button variant={'text'}>+ Add New Position</Button>

  if (isLoading) {
    return null
  }

  if (data?.length === 0) {
    return (
      <Tooltip
        side={'bottom'}
        sideOffset={-5}
        content={`You don't have any positions yet. Click here to add your first one.`}
        initialOpen={true}
      >
        {trigger}
      </Tooltip>
    )
  }

  return trigger
}
