import { CompanyWorker, getCompanyWorkers } from 'api/worker'
import { pick } from 'ramda'

export async function createMockWorkersForCategory() {
  const workers: CompanyWorker[] = []
  const loops = new Array(3).fill(0)
  let cursor = ''
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for await (const _ of loops) {
    const paginatedData = await getCompanyWorkers({
      includeRosters: false,
      cursor,
    })
    if (paginatedData.cursor) {
      cursor = paginatedData.cursor
    }
    workers.push(
      ...paginatedData.items.map((worker) => ({
        id: Number(worker.id),
        ...pick(
          ['name', 'profilePicUrl', 'rating', 'status', 'statusForCompany'],
          worker
        ),
      }))
    )
  }

  return workers
}
