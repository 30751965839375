import { useMutation } from '@tanstack/react-query'
import {
  AuthError,
  getAuth,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  UserCredential,
} from 'firebase/auth'

export function useSignInMutation(options?: {
  onSuccess?: (user: UserCredential) => void
  onError?: (err: AuthError) => void
}) {
  return useMutation({
    mutationFn: async (data: { email: string; password: string }) => {
      const auth = getAuth()
      try {
        return signInWithEmailAndPassword(auth, data.email, data.password)
      } catch (err) {
        const authError = err as AuthError
        if (authError.code === 'auth/multi-factor-auth-required') {
          throw err
        }
        throw new Error(translateError(authError))
      }
    },
    ...options,
  })
}

export function useOktaSignInMutation(options?: {
  onSuccess?: (user: UserCredential) => void
  onError?: (err: AuthError) => void
}) {
  return useMutation({
    mutationFn: async (data: { firebaseToken: string }) => {
      const auth = getAuth()
      try {
        return signInWithCustomToken(auth, data.firebaseToken)
      } catch (err) {
        const authError = err as AuthError
        throw new Error(translateError(authError))
      }
    },
    ...options,
  })
}

function translateError(err: AuthError) {
  switch (err.code) {
    case 'auth/invalid-login-credentials':
      return 'Invalid email or password'
    default:
      return 'We were unable to sign you in. Please try again.'
  }
}
