import React, { useState } from 'react'
import { useCompanyRosters } from 'queries/company'
import { Flex, Box, Loading, Modal, Heading, Button, Pluralize } from 'ui'
import { RosterCard } from './RosterCard'
import { useUniqueWorkersCount } from './useUniqueWorkersCount'

interface Props {
  title?: string
  ctaText?: string
  showStats?: boolean
  selected?: number[]
  onChange?: (selected: number[]) => void
  open?: boolean
  onClose?: () => void
  loading?: boolean
}

export function RosterSelector(props: Props) {
  const {
    open,
    onChange,
    title = 'Select Lists',
    ctaText = 'Select',
    showStats = true,
  } = props
  const { data: rosters, isLoading } = useCompanyRosters()
  const [selected, setSelected] = useState<number[]>(props.selected ?? [])
  const uniqueWorkersCount = useUniqueWorkersCount(rosters)
  const toggleSelection = (id: number) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id))
    } else {
      setSelected([...selected, id])
    }
  }

  return (
    <Modal
      width={[1, 1, 800, 900]}
      maxWidth={'auto'}
      open={open}
      onClose={props.onClose}
      customCta={
        <>
          {selected.length > 0 ? (
            <Heading level={4} mr={3} my={0}>
              {selected.length} Selected
            </Heading>
          ) : null}
          <Button
            width={[180, 200]}
            onClick={() => {
              if (onChange) onChange(selected)
            }}
            loading={props.loading}
          >
            {ctaText}
          </Button>
        </>
      }
    >
      <Flex alignItems={'center'}>
        <Heading level={[3, 2]}>{title}</Heading>
        <Box flex={1} />
        {showStats ? (
          <Box fontSize={3}>
            <Pluralize
              count={rosters?.length ?? 0}
              oneText={'List'}
              manyText={'Lists'}
            />
            ,&nbsp;
            <Pluralize
              count={uniqueWorkersCount}
              oneText={'unique Worker'}
              manyText={'unique Workers'}
            />
          </Box>
        ) : null}
      </Flex>
      {isLoading ? <Loading /> : null}
      {rosters ? (
        <Flex flexWrap={'wrap'} mx={-2}>
          {rosters.map((roster) => (
            <Box key={roster.id} width={[1, 1 / 2, 1 / 2, 1 / 3]} p={2}>
              <RosterCard
                data={roster}
                isSelected={selected.includes(Number(roster.id))}
                onClick={() => {
                  toggleSelection(roster.id)
                }}
              />
            </Box>
          ))}
        </Flex>
      ) : null}
    </Modal>
  )
}
