import React, { useState } from 'react'
import { useWorkMealBreaks } from 'queries/work'
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Loading,
  Message,
  Modal,
  Text,
} from '@workwhile/ui'
import { AlertCircleIcon, AlertTriangleIcon } from 'lucide-react'
import { MealBreakStatusEnum } from 'api/work'
import { getShiftTimezone } from 'lib/shift'
import { TimelineShift } from 'api/shift'
import { formatInTimeZone } from 'date-fns-tz'

export function MealBreaksModal({
  shift,
  workId,
}: {
  shift: TimelineShift
  workId: number
}) {
  const [visible, setVisible] = useState(false)
  const {
    data = {
      isSelfClockedOut: false,
      mealbreaks: [],
    },
    isLoading,
  } = useWorkMealBreaks(shift.id, workId, {
    enabled: visible,
  })
  const { isSelfClockedOut } = data
  const mealBreaks = data.mealbreaks.filter((mealBreak) =>
    [
      MealBreakStatusEnum.Taken,
      MealBreakStatusEnum.Overdue,
      MealBreakStatusEnum.Waived,
      MealBreakStatusEnum.Missed,
    ].includes(mealBreak.status)
  )
  const hasMultipleBreaks = (mealBreaks?.length ?? 0) > 1
  const hasIncompleteData = (mealBreaks || []).some(
    (mealbreak) => mealbreak.status === MealBreakStatusEnum.Overdue
  )
  const shifttz = getShiftTimezone(shift)

  return (
    <>
      <Button variant={'text'} onClick={() => setVisible(true)}>
        <Icon icon={AlertCircleIcon} color={'info'} size={16} />
      </Button>
      <Modal open={visible} onClose={() => setVisible(false)}>
        <Box>
          {isLoading ? <Loading /> : null}
          {mealBreaks?.map((mealbreak) => {
            return (
              <Box key={mealbreak.index}>
                <Heading level={4}>
                  {hasMultipleBreaks
                    ? mealbreak.index === 1
                      ? 'First Break'
                      : mealbreak.index === 2
                      ? 'Second Break'
                      : `Break No. ${mealbreak.index}`
                    : 'Meal Break'}
                </Heading>
                {mealbreak.status === MealBreakStatusEnum.Missed ? (
                  <>
                    <Flex mb={2}>
                      <Icon
                        icon={AlertTriangleIcon}
                        color={'warning'}
                        size={18}
                      />
                      <Text fontWeight={2} ml={2}>
                        {mealbreak.waiveable
                          ? 'Worker did not take nor waive the break.'
                          : 'Worker did not take the break.'}
                      </Text>
                    </Flex>
                  </>
                ) : null}
                {mealbreak.status === MealBreakStatusEnum.Overdue ? (
                  <>
                    <Flex mb={2}>
                      <Icon
                        icon={AlertTriangleIcon}
                        color={'warning'}
                        size={18}
                      />
                      <Text fontWeight={2} ml={2}>
                        Missing meal break information
                      </Text>
                    </Flex>
                    {mealbreak.takeBefore ? (
                      <>
                        Required to take before&nbsp;
                        {formatInTimeZone(
                          new Date(mealbreak.takeBefore),
                          shifttz,
                          'MMM dd, yyyy h:mm a zzz'
                        )}{' '}
                        {mealbreak.waiveable ? '(waivable)' : ''}
                      </>
                    ) : null}
                  </>
                ) : null}
                {mealbreak.status === MealBreakStatusEnum.Taken
                  ? `Taken at ${formatInTimeZone(
                      new Date(mealbreak.takenAt),
                      shifttz,
                      'MMM dd, yyyy h:mm a zzz'
                    )}`
                  : null}
                {mealbreak.status === MealBreakStatusEnum.Waived
                  ? 'Waived Break'
                  : null}
              </Box>
            )
          })}
          {!isSelfClockedOut && hasIncompleteData ? (
            <Message variant={'warning'} mt={3}>
              Worker did not clock out themselves and will be reminded to fill
              out break information in the app.
            </Message>
          ) : null}
          <Message variant={'info'} mt={3} hideIcon systemLevel>
            Break information displayed is based on information reported by each
            worker via the WorkWhile app. If you believe this information is
            inaccurate or have other questions, please reach out to support.
          </Message>
        </Box>
      </Modal>
    </>
  )
}
