import React from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Loading,
  Table,
  Text,
  useResponsiveValue,
} from 'ui'
import { useCompanyMembers } from 'queries/company'
import { memberColumns } from './columns'
import { AddCompanyMember } from 'components/AddCompanyMember'

export function CompanyMembers() {
  const { data, isLoading } = useCompanyMembers()
  const tableMode = useResponsiveValue(['borderless', 'standard']) as
    | 'borderless'
    | 'standard'
  const columnsToUse = useResponsiveValue([1, 4])

  return (
    <Box mt={5}>
      <Flex alignItems={'center'}>
        <Heading level={3}>Company Members</Heading>
        <Box flex={1} />
        <AddCompanyMember
          trigger={<Button variant={'text'}>+ Add a Member</Button>}
        />
      </Flex>
      {isLoading ? <Loading /> : null}
      {data && data.length > 0 ? (
        <Table
          variant={tableMode}
          columns={memberColumns.slice(0, columnsToUse)}
          data={data}
        />
      ) : null}
      {data && data.length === 0 ? (
        <Text color={'lightText'} mb={3}>
          Your company members will appear here.
        </Text>
      ) : null}
    </Box>
  )
}
