import React from 'react'
import { Box, Button, Heading, ModalCtaContainer } from '@workwhile/ui'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { requestWorkersSchema } from './requestWorkersSchema'
import { useSimpleMemoryRouterActions } from '../../../SimpleMemoryRouter'
import { useRequestWorkersFlowContextStateValue } from '../../context/RequestWorkersProvider'

export const RequestWorkers = () => {
  const { push } = useSimpleMemoryRouterActions()
  const {
    actions: { setFormData },
    state: { formData },
  } = useRequestWorkersFlowContextStateValue()

  const { handleSubmit } = useForm({
    resolver: zodResolver(requestWorkersSchema),
    defaultValues: formData.requestWorkers,
  })

  const onSubmit = handleSubmit((data) => {
    setFormData({ requestWorkers: data })
    push('choose-orientation')
  })

  return (
    <Box
      as="form"
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
      }}
    >
      <Heading level={3} style={{ marginTop: 0 }} as="h1">
        Request workers
      </Heading>
      <Box py={10}>TODO</Box>
      <ModalCtaContainer mt={4}>
        <Button type="submit">Choose orientation</Button>
      </ModalCtaContainer>
    </Box>
  )
}
