import React from 'react'
import {
  Box,
  Button,
  createColumnHelper,
  Heading,
  Loading,
  Table,
  Text,
  useResponsiveValue,
} from 'ui'
import { useDuplicateShifts } from 'queries/shift'
import { ShiftSimple } from 'api/shift'
import { useShiftEditor } from '../../useShiftEditor'
import { ShiftType, StepEnum } from '../../ShiftEditorProvider'
import { MobileShiftCard } from './MobileShiftCard'
import { getPayRate, getSupervisors } from './utils'
import { ShiftTime } from 'components/ShiftTime'

const columnHelper = createColumnHelper<ShiftSimple>()

export function RecentShiftsTable() {
  const { data, isLoading } = useDuplicateShifts()
  const { setShiftType, loadShiftData, goTo } = useShiftEditor()
  const showTable = useResponsiveValue([false, false, false, true])

  if (isLoading) {
    return <Loading />
  }

  if (!data) {
    return null
  }

  const columns = [
    columnHelper.accessor('position.name', {
      header: 'Position',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('location.name', {
      header: 'Location',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('startsAt', {
      header: 'Time',
      cell: (info) => <ShiftTime shift={info.row.original} />,
    }),
    columnHelper.accessor('payRate', {
      header: 'Pay',
      cell: (info) => getPayRate(info.row.original),
    }),
    columnHelper.accessor('supervisors', {
      header: 'Supervisor(s)',
      cell: (info) => getSupervisors(info.row.original),
    }),
    columnHelper.accessor('id', {
      header: '',
      cell: (info) => {
        const data = info.row.original
        return (
          <Button
            kind={'medium'}
            onClick={() => {
              setShiftType(data.isTryout ? ShiftType.Tryout : ShiftType.Regular)
              loadShiftData(data)
              goTo(StepEnum.Details)
            }}
          >
            Duplicate
          </Button>
        )
      },
    }),
  ]

  return (
    <Box mt={5}>
      <Heading level={3}>Duplicate Recent Shifts</Heading>
      {data.length === 0 ? (
        <Text color={'lightText'}>
          You don't have any recent shifts to duplicate.
        </Text>
      ) : showTable ? (
        <Table columns={columns} data={data.slice(0, 10)} />
      ) : (
        data
          .slice(0, 10)
          .map((shift) => <MobileShiftCard data={shift} key={shift.id} />)
      )}
    </Box>
  )
}
