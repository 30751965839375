import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useHomeContext } from 'pages/HomePage/useHomeContext'
import { shiftTimelineKey } from 'queries/shift/keys'
import { useLatest } from 'ahooks'

export function useIsLoadedFromCache(isFetching: boolean) {
  const queryClient = useQueryClient()
  const { referenceDate, locationId, positionId } = useHomeContext()
  const [loadedFromCache, setLoadedFromCache] = useState(false)

  useEffect(() => {
    const queryKey = shiftTimelineKey(referenceDate, locationId, positionId)
    const queryData = queryClient.getQueryData(queryKey)
    if (queryData) {
      setLoadedFromCache(true)
    }
  }, [referenceDate, locationId, positionId])

  useEffect(() => {
    if (loadedFromCache && !isFetching) {
      setLoadedFromCache(false)
    }
  }, [loadedFromCache, isFetching])

  return useLatest(loadedFromCache)
}
