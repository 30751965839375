import { useQuery } from '@tanstack/react-query'
import { workerShiftsKey } from './keys'
import { getWorkerShiftsForCompany } from 'api/worker'

export function useWorkerShifts(workerId: number) {
  return useQuery({
    queryKey: workerShiftsKey(workerId),
    queryFn: () => getWorkerShiftsForCompany(workerId),
  })
}
