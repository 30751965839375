import { useQuery } from '@tanstack/react-query'
import { positionTutorialKey } from './keys'
import { getPositionTutorial } from 'api/company'

export function usePositionTutorial(positionId: number, tutorialId?: number) {
  return useQuery({
    queryKey: positionTutorialKey(positionId, tutorialId),
    queryFn: () => {
      if (tutorialId) {
        return getPositionTutorial(positionId, tutorialId)
      }
    },
    enabled: !!tutorialId,
    gcTime: 0,
  })
}
