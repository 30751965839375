import { getDemoData, matchRestRequest, setDemoData } from 'api/demo/utils'
import { WorkSubmissionRequest } from 'api/work'
import {
  AssignedWorkerOptionsEnum,
  FINISHED_EARLY_FULL_PAY_OPTIONS,
  FINISHED_EARLY_PRORATED_OPTIONS,
} from 'lib/work/assigned_work_options'
import { matchPath } from 'react-router-dom'

export const mockUpdateWork = {
  shouldMock: (request) =>
    matchRestRequest(request, /\/company\/\d+\/shifts\/\d+\/edit_work/, 'POST'),
  getResponse: async (request) => {
    const params = request.data as WorkSubmissionRequest
    const match = matchPath(
      '/company/:companyId/shifts/:shiftId/edit_work',
      request.url as string
    )
    const shiftId = Number(match?.params.shiftId)
    const demoData = getDemoData()
    const updatedWork = getUpdateWork(shiftId, params)

    setDemoData({
      ...demoData,
      shifts: demoData.shifts.map((shift) =>
        shift.id === shiftId
          ? {
              ...shift,
              work: shift.work?.map((work) =>
                work.id === params.work_id ? updatedWork : work
              ),
            }
          : shift
      ),
    })

    return null
  },
}

export const mockApproveWork = {
  shouldMock: (request) =>
    matchRestRequest(
      request,
      /\/company\/\d+\/shifts\/\d+\/edit_review_approve/,
      'POST'
    ),
  getResponse: async (request) => {
    const params = request.data as WorkSubmissionRequest
    const match = matchPath(
      '/company/:companyId/shifts/:shiftId/edit_review_approve',
      request.url as string
    )
    const shiftId = Number(match?.params.shiftId)
    const demoData = getDemoData()
    const updatedWork = getUpdateWork(shiftId, params)
    updatedWork.status = 'employer_approved'

    setDemoData({
      ...demoData,
      shifts: demoData.shifts.map((shift) =>
        shift.id === shiftId
          ? {
              ...shift,
              work: shift.work?.map((work) =>
                work.id === params.work_id ? updatedWork : work
              ),
            }
          : shift
      ),
    })

    return null
  },
}

export const mockBulkApproveWork = {
  shouldMock: (request) =>
    matchRestRequest(
      request,
      /\/company\/\d+\/shifts\/\d+\/bulk_edit_review_approve/,
      'POST'
    ),
  getResponse: async (request) => {
    const workRequests = request.data.work_item_edits as WorkSubmissionRequest[]
    const match = matchPath(
      '/company/:companyId/shifts/:shiftId/bulk_edit_review_approve',
      request.url as string
    )
    const shiftId = Number(match?.params.shiftId)
    const demoData = getDemoData()

    setDemoData({
      ...demoData,
      shifts: demoData.shifts.map((shift) =>
        shift.id === shiftId
          ? {
              ...shift,
              work: shift.work?.map((work) => {
                const workRequest = workRequests.find(
                  (w) => w.work_id === work.id
                )
                if (!workRequest) {
                  return work
                }
                const updatedWork = getUpdateWork(shiftId, workRequest)
                updatedWork.status = 'employer_approved'
                return updatedWork
              }),
            }
          : shift
      ),
    })

    return workRequests.map((workRequest) => ({
      work_id: workRequest.work_id,
      succeeded: true,
      error_message: '',
    }))
  },
}

function getUpdateWork(shiftId: number, workRequest: WorkSubmissionRequest) {
  const demoData = getDemoData()
  const shift = demoData.shifts.find((s) => s.id === shiftId)
  if (!shift) {
    throw new Error('Shift not found')
  }
  const work = shift.work?.find((w) => w.id === workRequest.work_id)
  if (!work) {
    throw new Error('Work not found')
  }

  let leftEarly: boolean | undefined

  if (workRequest.finished_early_category) {
    if (
      FINISHED_EARLY_PRORATED_OPTIONS.includes(
        workRequest.finished_early_category as AssignedWorkerOptionsEnum
      )
    ) {
      leftEarly = true
    }
    if (
      FINISHED_EARLY_FULL_PAY_OPTIONS.includes(
        workRequest.finished_early_category as AssignedWorkerOptionsEnum
      )
    ) {
      leftEarly = false
    }
  }

  return {
    ...work,
    startedAt: workRequest.started_at ? workRequest.started_at : work.startedAt,
    completedAt: workRequest.completed_at
      ? workRequest.completed_at
      : work.completedAt,
    status: getUpdatedStatusForWork(workRequest) ?? work.status,
    leftEarly,
  }
}

function getUpdatedStatusForWork(workRequest: WorkSubmissionRequest) {
  if (workRequest.no_show) {
    return 'admin_review'
  }

  if (workRequest.completed_at) {
    return 'employer_approved'
  }

  if (workRequest.started_at) {
    return 'started'
  }
}
