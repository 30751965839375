import { useMutation } from '@tanstack/react-query'
import {
  removeCompanyMemberRole,
  removeCompanyGroupMemberRole,
} from 'api/company'
import { useCompanyInfo } from './useCompanyInfo'
import { useInvalidateCompanyMembers } from './useCompanyMembers'

export function useRemoveMemberRoleMutation(options?: {
  onSuccess?: () => void
  onError?: (err: Error) => void
}) {
  const { onSuccess, ...otherOptions } = options ?? {}
  const { data: company } = useCompanyInfo()
  const { invalidate: invalidateCompanyMembers } = useInvalidateCompanyMembers()

  return useMutation({
    mutationFn: ({
      userId,
      rolePermId,
      companyId,
    }: {
      userId: number
      rolePermId?: number
      companyId?: number
    }) => {
      if (!company) {
        throw new Error('Company not found')
      }

      if (!rolePermId) {
        throw new Error('Role permission id is required for removal')
      }

      if (companyId) {
        // if company id is passed in explicitly we know this call is coming from the group member table
        return removeCompanyGroupMemberRole(companyId, userId, rolePermId)
      } else {
        return removeCompanyMemberRole(company?.id, userId, rolePermId)
      }
    },
    onSuccess: () => {
      invalidateCompanyMembers()
      if (onSuccess) onSuccess()
    },
    ...otherOptions,
  })
}
