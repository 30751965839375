import { useMemo } from 'react'
import {
  CompanyWorker,
  PaginatedCompanyWorkers,
  StatusEnum,
  StatusForCompanyEnum,
} from 'api/worker'
import { InfiniteData } from '@tanstack/react-query'

interface Props {
  selected: CompanyWorker[]
  workers: InfiniteData<PaginatedCompanyWorkers> | undefined
}
export function useWorkers(props: Props) {
  const { selected, workers } = props

  const allWorkers = useMemo(() => {
    if (!workers) return []
    // flatten
    return workers.pages.reduce(
      (acc, page) => [...acc, ...page.items],
      [] as CompanyWorker[]
    )
  }, [workers])

  const unselectedWorkers = useMemo(() => {
    const selectedIds = new Set(selected.map((item) => item.id))
    return allWorkers.filter((worker) => {
      if (worker.shadowBlocked) return false
      if (worker.statusForCompany === StatusForCompanyEnum.Hired) return false
      if (worker.status !== StatusEnum.Active) return false
      return !selectedIds.has(worker.id)
    })
  }, [allWorkers, selected])

  return {
    unselectedWorkers,
    allWorkers,
  }
}
