import { useQuery, useQueryClient } from '@tanstack/react-query'
import { userProfileKey } from './keys'
import { getMyProfile } from 'api/user'
import { useAuthContext } from 'components/auth'

export function useUserProfile() {
  const { user } = useAuthContext()
  return useQuery({
    queryKey: userProfileKey(user?.uid),
    queryFn: getMyProfile,
  })
}

export function useInvalidateUserProfile() {
  const queryClient = useQueryClient()
  const { user } = useAuthContext()
  return {
    invalidate: () =>
      queryClient.invalidateQueries({
        queryKey: userProfileKey(user?.uid),
      }),
  }
}
