import React, { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useLocationTutorial,
  useSaveLocationTutorialMutation,
} from 'queries/company'
import { Loading, toast } from '@workwhile/ui'
import { TutorialCategory } from 'api/shift/typings'
import { Page } from 'pages/layout'
import { HeaderBar } from 'pages/layout/HeaderBar'
import { useCompatTutorial } from 'hooks/useCompatTutorial'
import { EditorProvider, EditorView } from 'components/Tutorials'

export function LocationInstructionEditor() {
  const navigate = useNavigate()
  const params = useParams<{ locationId: string; idOrCategory: string }>()
  const locationId = Number(params.locationId)
  const idOrCategory = params.idOrCategory as string
  const tutorialId = useMemo(() => {
    if (!isNaN(Number(idOrCategory))) {
      return Number(idOrCategory)
    }
  }, [idOrCategory])

  const { data: tutorial, isLoading } = useLocationTutorial(
    locationId,
    tutorialId
  )
  const compatTutorial = useCompatTutorial(tutorial)
  const category = useMemo(() => {
    if (tutorial) {
      return tutorial.category
    }
    return idOrCategory
  }, [idOrCategory, tutorial])

  const {
    mutate: saveTutorial,
    isPending: isSaving,
    error,
  } = useSaveLocationTutorialMutation({
    locationId,
    tutorialId,
    category: category as TutorialCategory,
    onSuccess(tutorialId) {
      toast.success('Instruction saved successfully')
      if (tutorialId) {
        // update routes if tutorialId is present
        navigate(`/location/${locationId}/instruction/${tutorialId}`, {
          replace: true,
        })
      }
    },
  })

  if (isLoading) {
    return <Loading />
  }

  return (
    <Page>
      <HeaderBar />
      <EditorProvider
        category={category as TutorialCategory}
        tutorialId={tutorialId}
        tutorial={compatTutorial}
        isSaving={isSaving}
        error={error}
        showNotifyWorkers
        title="This change will be applied for all shifts with this location"
        saveTutorial={saveTutorial}
      >
        <EditorView />
      </EditorProvider>
    </Page>
  )
}
