import { useQuery, useQueryClient } from '@tanstack/react-query'
import { workerMessagesKey } from './keys'
import { getWorkerMessages } from 'api/message'

export function useWorkerMessages(workerId: number) {
  return useQuery({
    queryKey: workerMessagesKey(Number(workerId)),
    queryFn: () => getWorkerMessages(workerId),
  })
}

export function useInvalidateWorkerMessages(workerId: number) {
  const queryClient = useQueryClient()

  return {
    invalidate: () =>
      queryClient.invalidateQueries({
        queryKey: workerMessagesKey(Number(workerId)),
      }),
  }
}
