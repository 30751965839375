import React, { useEffect } from 'react'
import { Flex, Box, Heading, Loading, Message, Text, toast } from 'ui'
import { Footer } from '../Footer'
import { usePositionTemplate } from 'queries/company'
import { FormProvider, useForm } from 'react-hook-form'
import { RequirementForm, requirementSchema } from '../schemas'
import { zodResolver } from '@hookform/resolvers/zod'
import { usePositionEditor } from '../usePositionEditor'
import { groupBy } from 'ramda'
import { RequirementGroup } from 'components/AddPosition/components/RequirementGroup'
import { useDebounceEffect } from 'ahooks'

export function Requirement() {
  const { positionData, setPositionData, goNext } = usePositionEditor()
  const methods = useForm<RequirementForm>({
    resolver: zodResolver(requirementSchema),
    defaultValues: positionData.requirement,
  })
  const {
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
  } = methods
  const { data: positionTemplate, isLoading } = usePositionTemplate(
    positionData.category?.positionTemplateId
  )
  const requirementGroups = groupBy(
    (requirement) => requirement.requirement.category ?? 'Other',
    positionTemplate?.requirements ?? []
  )
  const requirementIds = watch('requirementIds') ?? []

  useDebounceEffect(
    () => {
      // auto-save input values
      setPositionData({
        requirement: {
          requirementIds,
        },
      })
    },
    [requirementIds],
    { wait: 500 }
  )

  useEffect(() => {
    if (!positionData.requirement && positionTemplate?.requirements) {
      // prepopulate with pre-selected requirements
      const preselectedIds = positionTemplate.requirements
        .filter((r) => r.isPreSelected)
        .map((r) => Number(r.requirement.id))
      setValue('requirementIds', preselectedIds)
    }
  }, [positionTemplate, positionData])

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={(e) => {
          e.stopPropagation()
          e.preventDefault()
          handleSubmit((data) => {
            if (data.requirementIds.length === 0) {
              toast.error('Please select at least one requirement')
              return
            }
            setPositionData({
              requirement: data,
            })
            goNext()
          })(e)
        }}
      >
        <Flex
          width={[1, '85rem']}
          flexDirection={'column'}
          maxHeight={['70vh', '80vh']}
        >
          {isLoading ? (
            <Loading />
          ) : positionTemplate ? (
            <>
              <Box pb={4} flex={1} overflow={'auto'}>
                <Heading level={3}>
                  Select requirements for your {positionTemplate.name} position
                </Heading>
                <Text color={'lightText'} my={2}>
                  Tap on the requirements needed to get the job done, or add
                  your own. Keep in mind that adding more requirements will
                  restrict the labor pool available.
                </Text>
                <Message my={3} mb={5} variant={'info'}>
                  Workers will be warned that they may be turned away without
                  pay if they do not meet all of the requirements you’ve
                  selected below.
                </Message>
                {Object.keys(requirementGroups).map((groupName, index) => (
                  <Box mt={4} key={groupName}>
                    <Heading level={4}>
                      {index + 1}. {groupName}
                    </Heading>
                    <RequirementGroup
                      positionTemplateId={positionTemplate.id}
                      category={groupName}
                      requirements={requirementGroups[groupName]!}
                    />
                  </Box>
                ))}
              </Box>
              <Footer ctaText={'Next: Job Description'} />
            </>
          ) : null}
        </Flex>
      </form>
    </FormProvider>
  )
}
