import React from 'react'
import { Box, Heading, Loading, Message, Select } from 'ui'
import { useCompanyMembers } from 'queries/company'
import styled from 'styled-components'
import { useSupervisorOptions } from './useSupervisorOptions'
import { Selectable } from 'typings/selectable_ext'
import { pick } from 'ramda'
import { SupervisorCard } from './SupervisorCard'
import { ShiftSupervisor, SupervisorRequestType } from '../../schemas'
import { useFormContext } from 'react-hook-form'
import { useSupervisors } from './useSupervisors'

const Container = styled(Box)`
  ${({ theme: { colors } }) => `
    .other-team-member {
      padding: 1rem;
      > div {
        background-color: ${colors.primaries[50]};
        color: ${colors.primary};
      }
      
      &:hover {
        > div {
          background-color: ${colors.primary};
          color: ${colors.white};
        }
      }
    }
  `}
`

export function Supervisors() {
  const { data, isLoading } = useCompanyMembers()
  const { options, CustomOption, customFilterOption } =
    useSupervisorOptions(data)
  const {
    formState: { errors },
  } = useFormContext()
  const { addSupervisor, supervisors } = useSupervisors()

  if (isLoading) {
    return <Loading />
  }

  return (
    <Container mt={4}>
      <Heading level={3}>Supervisors</Heading>
      <Select
        aria-label={'Select Supervisor'}
        components={{ Option: CustomOption }}
        value={null}
        options={options}
        filterOption={customFilterOption}
        placeholder={'Select a Shift Supervisor'}
        isOptionDisabled={(option) => {
          const data = option as Selectable<ShiftSupervisor>
          return supervisors.some((supervisor) => supervisor.id === data.value)
        }}
        onChange={(newValue) => {
          const value = newValue as Selectable<ShiftSupervisor>
          if (value.value?.toString() === '0') {
            return addSupervisor({
              id: Date.now(),
              requestType: SupervisorRequestType.FreeForm,
            })
          }

          addSupervisor({
            ...pick(['id', 'name', 'email', 'phoneNumber'], value),
            requestType: SupervisorRequestType.ExistingUser,
          })
        }}
      />
      {supervisors.map((supervisor) => (
        <SupervisorCard key={supervisor.id} id={supervisor.id} />
      ))}
      {errors.supervisors?.message ? (
        <Message mt={3} variant={'error'} systemLevel={false}>
          {errors.supervisors.message?.toString()}
        </Message>
      ) : null}
    </Container>
  )
}
