import * as zod from 'zod'

export const DEFAULT_CLOCK_IN_RADIUS_FT = 300
export const DEFAULT_CLOCK_IN_RADIUS_METER = 100

export const locationSchema = zod.object({
  address: zod.string().nonempty('Address is required'),
  unit: zod.string().optional(),
  city: zod.string().nonempty('City is required'),
  state: zod.string().nonempty('State is required'),
  zipCode: zod.string().nonempty('Zip code is required'),
  name: zod.string().nonempty('Location name is required'),
  lat: zod.number().optional(),
  long: zod.number().optional(),
  clockInLat: zod.number().optional(),
  clockInLong: zod.number().optional(),
  clockInRadius: zod.coerce
    .number()
    .gte(
      DEFAULT_CLOCK_IN_RADIUS_METER,
      `Radius >= ${DEFAULT_CLOCK_IN_RADIUS_METER}m is required`
    )
    .default(DEFAULT_CLOCK_IN_RADIUS_METER),
})

export type LocationForm = zod.infer<typeof locationSchema>
