import React from 'react'
import { SimpleMemoryRouterOutlet } from '../SimpleMemoryRouter/SimpleMemoryRouter'
import { Modal } from '@workwhile/ui'
import {
  RequestWorkersProvider,
  useRequestWorkersFlowContextStateValue,
} from './context/RequestWorkersProvider'
import { useAssignmentWorkflowsContextStateValue } from '../context/AssignmentWorkflowsProvider'

const RequestWorkersModal = () => {
  const {
    requestWorkersFlow: { isOpen, close },
  } = useAssignmentWorkflowsContextStateValue()
  const {
    state: {
      mutationState: { isPending },
    },
  } = useRequestWorkersFlowContextStateValue()

  return (
    <Modal open={isOpen} onClose={close} position="top" loading={isPending}>
      <SimpleMemoryRouterOutlet />
    </Modal>
  )
}

export const RequestWorkersFlow = () => {
  return (
    <RequestWorkersProvider>
      <RequestWorkersModal />
    </RequestWorkersProvider>
  )
}
