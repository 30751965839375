import { Button, createColumnHelper, Text } from 'ui'
import { ChargePayment } from 'api/payment'
import React from 'react'
import { format } from 'date-fns'
import { ModalLink } from 'components/ModalLink'

const columnHelper = createColumnHelper<ChargePayment>()
export const invoiceTableColumns = [
  columnHelper.accessor('id', {
    header: 'Invoice',
    size: 200,
    cell: (info) => (
      <ModalLink to={`/payments/invoice/${info.getValue()}`}>
        {info.getValue()}
      </ModalLink>
    ),
  }),
  columnHelper.accessor('createdAt', {
    header: 'Date',
    cell: (info) => format(new Date(info.getValue()), 'MM/dd/yyyy'),
  }),
  columnHelper.accessor('totalAmount', {
    header: () => (
      <Text textAlign={'right'} pr={[0, 100]}>
        Amount
      </Text>
    ),
    cell: (info) => (
      <Text textAlign={'right'} pr={[0, 100]}>
        ${info.getValue()}
      </Text>
    ),
  }),
  columnHelper.accessor('status', {
    header: '',
    size: 120,
    cell: (info) => (
      <ModalLink to={`/payments/invoice/${info.row.original.id}`}>
        <Button width={120} kind={'small'}>
          Details
        </Button>
      </ModalLink>
    ),
  }),
]
