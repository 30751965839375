import { useQuery, useQueryClient } from '@tanstack/react-query'
import { workerProfileKey } from './keys'
import { getWorkerProfile } from 'api/worker'

export function useWorkerProfile(workerId: number) {
  return useQuery({
    queryKey: workerProfileKey(workerId),
    queryFn: () => getWorkerProfile(workerId),
  })
}

export function useInvalidateWorkerProfile(workerId: number) {
  const queryClient = useQueryClient()
  return {
    invalidate: () =>
      queryClient.invalidateQueries({ queryKey: workerProfileKey(workerId) }),
  }
}
