import { useFormContext } from 'react-hook-form'
import { useMemo } from 'react'

export function useAssignedWorkRow(id: number) {
  const {
    watch,
    register,
    setValue,
    formState: { errors },
    control,
  } = useFormContext()

  const index = control._formValues.findIndex((w) => w.id === id)
  const dataPath = `${index}`
  const data = watch(dataPath)

  const workErrors = errors ? errors[index] : {}

  const values = useMemo(() => {
    return {
      data,
      register: (name: string) => register(`${dataPath}.${name}`),
      watch: (name: string) => watch(`${dataPath}.${name}`),
      dataPath,
      getPath: (name: string) => `${dataPath}.${name}`,
      errors: workErrors,
      control,
      setValue: (name: string, value?: string | boolean | number | null) =>
        setValue(`${dataPath}.${name}`, value),
    }
  }, [data, dataPath, workErrors])

  return values
}
