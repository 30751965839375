import { useMutation } from '@tanstack/react-query'
import { useCompanyInfo } from '../company'
import { sendChatMessage, SendChatMessageRequest } from 'api/message'

interface UseSendMessageMutation {
  onSuccess?: () => void
}

export function useSendMessageMutation(options?: UseSendMessageMutation) {
  const { data: company } = useCompanyInfo()

  return useMutation({
    mutationFn: async (data: SendChatMessageRequest) => {
      if (!company?.id) {
        throw new Error('Company id not found')
      }
      return sendChatMessage(company.id, data)
    },
    ...options,
  })
}
