import { useQuery, useQueryClient } from '@tanstack/react-query'
import { rosterDetailKey } from './keys'
import { getRosterDetails } from 'api/worker'

export function useRosterDetail(rosterId: number) {
  return useQuery({
    queryKey: rosterDetailKey(rosterId),
    queryFn: () => getRosterDetails(rosterId),
  })
}

export function useInvalidateRosterDetail(rosterId: number) {
  const queryClient = useQueryClient()

  return {
    invalidate: () => {
      queryClient.invalidateQueries({ queryKey: rosterDetailKey(rosterId) })
    },
  }
}
