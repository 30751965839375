import React, { ReactNode, useState } from 'react'
import { Tooltip } from 'ui'
import { XCircleIcon } from 'lucide-react'
import { BlockModalType } from './types'
import { BlockWithReason } from './BlockWithReason'
import { WorkerInformation } from './WorkerInformation'
import { Feedback } from './Feedback'
import { FillRate } from './FillRate'
import { NewWorker } from './NewWorker'
import { User } from 'typings/common_defs'
import { StatusForCompanyEnum } from 'api/worker'

interface Props {
  worker: User
  shiftId?: number
  renderButton: (buttonProps: {
    onClick: () => void
    children: ReactNode
  }) => ReactNode
  onSuccess: (status?: StatusForCompanyEnum) => void
}
export function BlockModal(props: Props) {
  const { worker, renderButton, onSuccess, shiftId } = props
  const [modalType, setModalType] = useState<BlockModalType | null>(null)

  const onBlockClick = () => {
    const rating = worker.rating ?? 0
    const numShifts = worker.numFinishedShifts ?? 0

    if (rating > 4 && numShifts >= 5) {
      setModalType(BlockModalType.WorkerInformation)
      return
    }

    if (numShifts <= 1) {
      setModalType(BlockModalType.NewWorker)
      return
    }

    setModalType(BlockModalType.Block)
  }

  return (
    <>
      <Tooltip content={'Click to block'}>
        {renderButton({
          onClick: onBlockClick,
          children: <XCircleIcon style={{ flexShrink: 0 }} size={16} />,
        })}
      </Tooltip>
      {modalType === BlockModalType.WorkerInformation ? (
        <WorkerInformation setModalType={setModalType} worker={worker} />
      ) : null}
      {modalType === BlockModalType.Feedback ? (
        <Feedback
          setModalType={setModalType}
          worker={worker}
          shiftId={props.shiftId}
        />
      ) : null}
      {modalType === BlockModalType.FillRate ? (
        <FillRate setModalType={setModalType} worker={worker} />
      ) : null}
      {modalType === BlockModalType.NewWorker ? (
        <NewWorker setModalType={setModalType} worker={worker} />
      ) : null}
      {modalType === BlockModalType.Block ? (
        <BlockWithReason
          setModalType={setModalType}
          worker={worker}
          onSuccess={onSuccess}
        />
      ) : null}
    </>
  )
}
