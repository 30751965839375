import { Position } from 'typings/common_defs'

export const positions: Position[] = [
  {
    id: 1,
    name: 'Picker & Packer',
    about: 'Complete the daily picking and packing tasks as required.',
    hidden: false,
    positionTemplateId: 31,
    requirements:
      '["Closed-toe shoes", "Push, pull, squat", "Stand for long periods", "No jewelry or piercings"]',
    requirements2: [
      {
        id: 6,
        category: 'Attire & Grooming',
        name: 'Closed-toe shoes',
      },
      {
        id: 18,
        category: 'General Requirements',
        name: 'Push, pull, squat',
      },
      {
        id: 22,
        category: 'General Requirements',
        name: 'Stand for long periods',
      },
      {
        id: 40,
        category: 'Attire & Grooming',
        name: 'No jewelry or piercings',
      },
    ],
  },
  {
    id: 2,
    name: 'Warehouse Associate',
    about:
      'Job Duties—Read, interpret and follow basic work instructions/Standard Operation Procedures (SOP), diagrams, engineering drawings, specifications, bill of materials, and other written instructions or procedures to accurately assemble equipment and products. Utilize hand held tools such as hand held screw and drill gun. Maintain inventory of work stations.\n',
    hidden: false,
    positionTemplateId: 30,
    requirements:
      '["Closed-toe shoes", "Push, pull, squat", "Stand for long periods", "Long pants", "Steel-toed boots", "Safety glasses ", "Lift 40lbs"]',
    requirements2: [
      {
        id: 6,
        category: 'Attire & Grooming',
        name: 'Closed-toe shoes',
      },
      {
        id: 18,
        category: 'General Requirements',
        name: 'Push, pull, squat',
      },
      {
        id: 22,
        category: 'General Requirements',
        name: 'Stand for long periods',
      },
      {
        id: 29,
        category: 'Attire & Grooming',
        name: 'Long pants',
      },
      {
        id: 28,
        category: 'Attire & Grooming',
        name: 'Steel-toed boots',
      },
      {
        id: 3442,
        category: 'Attire & Grooming',
        name: 'Safety glasses ',
      },
      {
        id: 3443,
        category: 'General Requirements',
        name: 'Lift 40lbs',
      },
    ],
  },
  {
    id: 3,
    name: 'Delivery Driver',
    about: '-Deliver small packages\n-Up to 60 miles\n-Cold chain',
    hidden: false,
    positionTemplateId: 22,
    requirements: '["Personal vehicle", "Clean MVR", "Valid drivers license"]',
    requirements2: [
      {
        id: 12,
        category: 'General Requirements',
        name: 'Personal vehicle',
      },
      {
        id: 7,
        category: 'Certificates & Experience',
        name: 'Clean MVR',
      },
      {
        id: 8,
        category: 'Certificates & Experience',
        name: 'Valid drivers license',
      },
    ],
  },
  {
    id: 4,
    name: 'Delivery Courier',
    about: 'Job Des',
    hidden: false,
    positionTemplateId: 22,
    requirements:
      '["Personal vehicle", "Clean MVR", "Car insurance", "Valid drivers license", "Lift 50 lbs", "Warm clothing", "New Req "]',
    requirements2: [
      {
        id: 12,
        category: 'General Requirements',
        name: 'Personal vehicle',
      },
      {
        id: 7,
        category: 'Certificates & Experience',
        name: 'Clean MVR',
      },
      {
        id: 10,
        category: 'Certificates & Experience',
        name: 'Car insurance',
      },
      {
        id: 8,
        category: 'Certificates & Experience',
        name: 'Valid drivers license',
      },
      {
        id: 15,
        category: 'General Requirements',
        name: 'Lift 50lbs',
      },
      {
        id: 27,
        category: 'Attire & Grooming',
        name: 'Warm clothing',
      },
    ],
  },
  {
    id: 5,
    name: 'Event Staff',
    about: 'Event Staff',
    hidden: false,
    positionTemplateId: 37,
    requirements:
      '["Closed-toe shoes", "Loud Environment", "Read and write in English", "Stand for long periods", "Black pants"]',
    requirements2: [
      {
        id: 6,
        category: 'Attire & Grooming',
        name: 'Closed-toe shoes',
      },
      {
        id: 3250,
        category: 'General Requirements',
        name: 'Loud Environment',
      },
      {
        id: 21,
        category: 'General Requirements',
        name: 'Read and write in English',
      },
      {
        id: 22,
        category: 'General Requirements',
        name: 'Stand for long periods',
      },
      {
        id: 26,
        category: 'Attire & Grooming',
        name: 'Black pants',
      },
    ],
  },
  {
    id: 6,
    name: 'Server',
    about: 'Cleaning Tables and serving customers ',
    hidden: false,
    positionTemplateId: 45,
    requirements:
      '["Lift 50lbs", "Black Slacks", "Black Nonslip Shoes", "Black Long Sleeve Collared Shirt", "Stand for long periods", "line cook "]',
    requirements2: [
      {
        id: 15,
        category: 'General Requirements',
        name: 'Lift 50lbs',
      },
      {
        id: 3243,
        category: 'Attire & Grooming',
        name: 'Black Slacks',
      },
      {
        id: 3244,
        category: 'Attire & Grooming',
        name: 'Black Nonslip Shoes',
      },
      {
        id: 3247,
        category: 'Attire & Grooming',
        name: 'Black Long Sleeve Collared Shirt',
      },
      {
        id: 22,
        category: 'General Requirements',
        name: 'Stand for long periods',
      },
      {
        id: 3619,
        category: 'Certificates & Experience',
        name: 'line cook ',
      },
    ],
  },
  {
    id: 7,
    name: 'Forklift Operator',
    about: 'Job Description',
    hidden: false,
    positionTemplateId: 32,
    requirements:
      '["Closed-toe shoes", "Pallet jack", "Forklift Experience", "Push, pull, squat", "Stand for long periods", "Stand-up reach truck", "Steel-toed boots", "no hats"]',
    requirements2: [
      {
        id: 6,
        category: 'Attire & Grooming',
        name: 'Closed-toe shoes',
      },
      {
        id: 37,
        category: 'Certificates & Experience',
        name: 'Pallet jack',
      },
      {
        id: 41,
        category: 'Certificates & Experience',
        name: 'Forklift Experience',
      },
      {
        id: 18,
        category: 'General Requirements',
        name: 'Push, pull, squat',
      },
      {
        id: 22,
        category: 'General Requirements',
        name: 'Stand for long periods',
      },
      {
        id: 36,
        category: 'Certificates & Experience',
        name: 'Stand-up reach truck',
      },
      {
        id: 28,
        category: 'Attire & Grooming',
        name: 'Steel-toed boots',
      },
      {
        id: 3497,
        category: 'Attire & Grooming',
        name: 'no hats',
      },
    ],
  },
]

export const positionsByCategory = {
  general: [positions[0], positions[2], positions[4]],
  driving: [
    positions[2],
    positions[3],
    {
      ...positions[3],
      id: 7,
      name: 'Delivery Ambassador',
    },
  ],
  hospitality: [
    positions[4],
    positions[5],
    {
      ...positions[4],
      id: 7,
      name: 'Guest Services',
    },
  ],
  warehouse: [positions[0], positions[1], positions[6]],
}
