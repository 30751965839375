import React, { useEffect } from 'react'
import { Box, Flex, Loading, Text, useAppTheme } from '@workwhile/ui'
import { User } from 'typings/common_defs'
import { useWorkerAvailability } from 'queries/worker'
import { HypotheticalShift } from 'api/worker'
import { Tooltip } from '@workwhile/ui'
import { WorkerUnavailableReasons } from './WorkerUnavailableReasons'
import { LucideInfo } from 'lucide-react'
import {
  DeclinedWorkStatuses,
  ShiftEditData,
  TimelineShift,
  WorkStatusEnum,
} from 'api/shift'
import useShiftTimeline from 'hooks/useShiftTimeline'
import { track } from 'lib/amplitude'

/**
 * Possible statuses:
 * - Accepted
 * - Available
 * - Available X of Y days
 * - Awaiting Response
 * - Unavailable
 */

interface Props {
  worker: User
  hypotheticalShifts: Array<HypotheticalShift | null> // info on shifts to determine worker's availability
  existingShift?: TimelineShift | ShiftEditData // shift that has already been created. If it exists, we can determine if worker is already assigned to the shift
}

export const SelectedWorkerStatus = ({
  worker,
  hypotheticalShifts,
  existingShift,
}: Props) => {
  const { colors } = useAppTheme()
  const { data: workerAvailabilities, isLoading } = useWorkerAvailability({
    workerIds: [worker.id],
    hypotheticalShifts: existingShift ? [] : hypotheticalShifts,
    shiftIds: existingShift ? [existingShift.id] : [],
  })
  const { isInProgress, isInPast } = useShiftTimeline(existingShift || null)

  useEffect(() => {
    track('impression, selected_worker_status', {
      workerId: worker.id,
    })
  }, [])

  useEffect(() => {
    // Only track if we have availabilities
    if (!workerAvailabilities) return
    if (workerAvailabilities.length > 0) {
      const availabilitiesForWorker = workerAvailabilities.filter(
        (availability) => Number(availability.workerId) === Number(worker.id)
      )

      if (availabilitiesForWorker.length > 0) {
        track('impression, availabilities_for_worker', {
          workerId: worker.id,
          isAvailableAllDays: availabilitiesForWorker.every(
            (availability) => availability.available
          ),
        })
      }
    }
  }, [workerAvailabilities, worker.id])

  if (isLoading) {
    return <Loading type="button" />
  }

  // if the worker is already scheduled for the shift, return 'Accepted'
  if (existingShift?.work?.some((work) => work.worker?.id === worker.id)) {
    return <Text fontSize={1}>Accepted</Text>
  }

  // if the worker declined the shift
  if (
    existingShift?.unableToAttendWork?.some(
      (work) =>
        Number(work.worker?.id) === Number(worker.id) &&
        DeclinedWorkStatuses.includes(work.status as WorkStatusEnum)
    )
  ) {
    return <Text fontSize={1}>Declined</Text>
  }

  // if the shift has already started or is in the past, don't show availability
  if (existingShift && (isInProgress || isInPast)) {
    return null
  }

  const availabilitiesForWorker = workerAvailabilities?.filter(
    (availability) => Number(availability.workerId) === Number(worker.id)
  )

  if (
    !availabilitiesForWorker ||
    availabilitiesForWorker === undefined ||
    availabilitiesForWorker.length < 0
  ) {
    return null
  }

  // if the worker is available for all shifts
  if (
    availabilitiesForWorker?.every((availability) => availability.available)
  ) {
    // if the shift has already been created, return 'Awaiting Response'
    if (existingShift) {
      return <Text fontSize={1}>Awaiting Response</Text>
    }
    // otherwise return "Available"
    return <Text fontSize={1}>Available</Text>
  }

  const renderAvailabilityStatus = (
    availableCount: number,
    totalDays: number
  ) => {
    const statusText =
      availableCount === 0
        ? `Unavailable${totalDays > 1 ? ' all days' : ''}`
        : `Available ${availableCount} of ${totalDays} days`
    const iconSize = availableCount === 0 ? 16 : 10
    const isUnavailable = availableCount === 0

    const statusElement = (
      <Box>
        <Tooltip
          content={
            <WorkerUnavailableReasons
              worker={worker}
              availabilitiesForWorker={availabilitiesForWorker}
            />
          }
          variant="neutral"
        >
          <Flex flexDirection="row" flex={1} alignItems="center">
            <Text
              fontSize={2}
              mr={1}
              color={isUnavailable ? 'error' : 'info'}
              style={{
                textDecoration: 'underline',
                fontWeight: isUnavailable ? 'bold' : 'normal',
              }}
            >
              {statusText}
            </Text>
            <LucideInfo
              size={iconSize}
              color={isUnavailable ? colors.error : colors.info}
            />
          </Flex>
        </Tooltip>
      </Box>
    )

    return { statusElement, isUnavailable }
  }

  const availableCount = availabilitiesForWorker.filter(
    (availability) => availability.available
  ).length
  const totalDays = availabilitiesForWorker.length
  const { statusElement, isUnavailable } = renderAvailabilityStatus(
    availableCount,
    totalDays
  )
  return statusElement
}
