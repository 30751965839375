import React, { FormEvent, useState, useTransition } from 'react'
import { Box, Button, IconButton, InputWrapper } from 'ui'
import { SearchIcon, XIcon } from 'lucide-react'

interface SearchBarProps {
  setSearchKeyword: (keyword: string) => void
  isLoading: boolean
}

export function SearchBar({ setSearchKeyword, isLoading }: SearchBarProps) {
  const [keyword, setKeyword] = useState('')
  const [, startTransition] = useTransition()

  const onSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    startTransition(() => {
      setSearchKeyword(keyword.trim())
    })
  }

  const clearKeyword = () => {
    setKeyword('')
    startTransition(() => {
      setSearchKeyword('')
    })
  }

  return (
    <form onSubmit={onSearchSubmit} style={{ width: '100%' }}>
      <InputWrapper block={true}>
        <Box
          color={'lightText'}
          position={'absolute'}
          style={{ pointerEvents: 'none' }}
          top={'50%'}
          left={'1rem'}
          mt={'-0.8rem'}
        >
          <SearchIcon size={18} />
        </Box>
        <input
          style={{ paddingLeft: '3.5rem' }}
          value={keyword}
          onChange={(e) => setKeyword(e.currentTarget.value)}
          autoFocus={true}
          placeholder={'Search for a company group member'}
        />
        {keyword.trim() ? (
          <Box mr={3}>
            <IconButton size={'small'} onClick={clearKeyword}>
              <XIcon size={16} />
            </IconButton>
          </Box>
        ) : null}
        <Button
          type={'submit'}
          kind={'small'}
          mr={2}
          width={100}
          disabled={isLoading || !keyword.trim()}
        >
          Search
        </Button>
      </InputWrapper>
    </form>
  )
}
