import React, { useMemo } from 'react'
import { Box, Flex, Avatar, Heading, Text, Pluralize } from 'ui'
import { BriefcaseIcon, StarIcon } from 'lucide-react'
import { CompanyWorker } from 'api/worker'
import { format } from 'date-fns'

export function WorkerCardContent(props: { data: CompanyWorker }) {
  const { data } = props
  const lastShift = useMemo(() => {
    if (data.workForCompany && data.workForCompany.length > 0) {
      return new Date(data.workForCompany[0].startedAt as string)
    }

    return null
  }, [data])

  return (
    <>
      <Avatar
        size={'xlarge'}
        user={{
          userId: data.id.toString(),
          name: data.name,
          photoUrl: data.profilePicUrl,
        }}
      />
      <Box pl={3} pt={[0, 1]} flex={1}>
        <Flex>
          <Heading level={4} my={0}>
            {data.name}
          </Heading>
          <Box flex={1} />
        </Flex>
        <Box mt={2}>
          <Flex flexDirection={'row'} flexWrap={'wrap'}>
            <Box width={[1, 150]} display={'inline-flex'} alignItems={'center'}>
              <StarIcon size={16} />
              <Text ml={2}>
                {data.rating ? Number(data.rating).toFixed(2) : '--'}
                &nbsp;•&nbsp;
                <Pluralize
                  count={data.numShiftsForCompany ?? 0}
                  oneText={'shift'}
                  manyText={'shifts'}
                />
              </Text>
            </Box>
            <Box
              width={[1, 'auto']}
              ml={[0, 3]}
              display={'inline-flex'}
              alignItems={'center'}
            >
              <BriefcaseIcon size={16} />
              <Text ml={2}>Last Shift:&nbsp;</Text>
              {lastShift ? format(lastShift, 'MMM d, yyyy') : '--'}
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  )
}
