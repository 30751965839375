import React from 'react'
import EmptyReviewAsset from 'assets/empty-reviews.svg'
import { Box, Flex, Heading, Text } from 'ui'

export function EvaluateEmptyState() {
  return (
    <Flex
      flexDirection={'column'}
      borderRadius={'standard'}
      backgroundColor={'emptyState'}
      px={3}
      py={[6, 7]}
      alignItems={'center'}
    >
      <img
        src={EmptyReviewAsset}
        style={{ maxWidth: '100%', height: 73 }}
        alt={'No workers'}
      />
      <Box width={[1, 'auto']} px={[0, 6]}>
        <Heading level={4} color={'lightText'} textAlign={'center'}>
          Workers that need evaluations will appear here.
        </Heading>
        <Text color={'lightText'} textAlign={'center'}>
          Upon completing a shift, we’ll display workers here that need to be
          evaluated. Please be sure to rate them on time to prevent any payment
          delays.
        </Text>
      </Box>
    </Flex>
  )
}
