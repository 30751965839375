import React from 'react'
import { WorkShift } from 'typings/common_defs'
import { Box, Card, Link, Pluralize } from 'ui'
import { ShiftDetailProvider } from '../ShiftDetailProvider'
import { ShiftLeadRows } from './ShiftLeadRows'

interface Props {
  leadShift: WorkShift
}

export const LeadShiftCard = ({ leadShift }: Props) => {
  const numLeadsOnShift = leadShift?.work?.length ?? 0

  return (
    <Card borderWidth={0}>
      <ShiftDetailProvider
        key={`lead-shift-${leadShift.id}-rows`}
        shiftId={leadShift.id}
      >
        <ShiftLeadRows />
      </ShiftDetailProvider>
      <Box key={`lead-shift-${leadShift.id}-link`}>
        <Link to={`/shift/${leadShift.id}`}>
          View Shift{' '}
          <Pluralize
            hideCount
            count={numLeadsOnShift}
            oneText="Lead's"
            manyText="Leads'"
          />{' '}
          shift
        </Link>
      </Box>
    </Card>
  )
}
