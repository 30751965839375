import { TimelineShift } from 'api/shift'
import { getWorkStatusBadgeVariant } from 'lib/work'
import React from 'react'
import { MoreHorizontal, Check, X } from 'lucide-react'
import { Work } from 'typings/common_defs'
import { AvatarBadge } from '@workwhile/ui'

/**
 * Given a Work, determine the StatusBadge color and icon to show
 * @param {TimelineShift | null} shift
 * @param {Work} work
 * @returns {AvatarBadge | null}
 */
export const useWorkStatusBadge = (
  shift: TimelineShift | null,
  work: Work
): AvatarBadge | null => {
  if (!shift) return null
  const statusBadgeVariant = getWorkStatusBadgeVariant(shift, work)

  if (!statusBadgeVariant) {
    return null
  }

  let backgroundColor: string
  let IconComponent: React.ReactNode

  switch (statusBadgeVariant) {
    case 'pending':
      backgroundColor = 'warnings.100'
      IconComponent = <MoreHorizontal size={14} />
      break
    case 'accepted':
      backgroundColor = 'successes.100'
      IconComponent = <Check size={14} />
      break
    case 'unavailable':
      backgroundColor = 'errors.100'
      IconComponent = <X size={14} />
      break
  }

  return {
    icon: IconComponent,
    color: backgroundColor,
  }
}
