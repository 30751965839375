import React, { useMemo } from 'react'
import { getLocationTimezone } from 'lib/time'
import { formatInTimeZone } from 'date-fns-tz'
import * as Popover from '@radix-ui/react-popover'
import { BaseShiftCell, BaseShiftCellProps } from './BaseShiftCell'

import { useAppTheme } from '@workwhile/ui'
import { ShiftCellDetail } from './ShiftCellDetail'
import { CalendarViewShift } from 'pages/AssignmentDetailPage/types'

type ShiftCellProps = {
  shift: CalendarViewShift
}

export const ShiftCell = ({ shift }: ShiftCellProps) => {
  const { colors } = useAppTheme()

  const baseShiftCellProps: BaseShiftCellProps = useMemo(() => {
    const timezone = getLocationTimezone(shift.location)
    const startTime = formatInTimeZone(shift.startsAt, timezone, 'h:mm a')
    const endTime = formatInTimeZone(shift.endsAt, timezone, 'h:mm a z')

    if (shift.isOrientation) {
      return {
        label: 'Orientation',
        value: `${startTime} - ${endTime}`,
        backgroundColor: colors.warning50,
        color: colors.warning200,
      }
    }

    return {
      value: `${startTime} - ${endTime}`,
      backgroundColor: colors.info50,
      color: colors.info200,
    }
  }, [shift.isOrientation, shift.startsAt, shift.endsAt, colors])

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <BaseShiftCell {...baseShiftCellProps} />
      </Popover.Trigger>
      <Popover.Content
        side="right"
        align="start"
        sideOffset={5}
        asChild
        style={{ zIndex: 1 }}
      >
        <ShiftCellDetail shift={shift} />
      </Popover.Content>
    </Popover.Root>
  )
}
