import React from 'react'
import { Work } from 'typings/common_defs'
import { Flex, Textarea, Text } from 'ui'
import { useAssignedWorkRow } from '../useAssignedWorkerRow'
import { toTitleCase } from 'lib/string'
import { AssignedWorkerOptionsEnum } from 'lib/work/assigned_work_options'

interface Props {
  work: Work
}

export const FINISHED_EARLY_REASONS_REQUIRING_EXPLANATION = [
  AssignedWorkerOptionsEnum.PERSONAL_REASONS,
  AssignedWorkerOptionsEnum.REJECTED_BY_EMPLOYER,
]

export const AssignedWorkerFinishedEarlyExplanation = ({ work }: Props) => {
  const { data, setValue } = useAssignedWorkRow(work.id)

  if (
    !FINISHED_EARLY_REASONS_REQUIRING_EXPLANATION.includes(
      data.finishedEarlyCategory
    )
  ) {
    return null
  }

  return (
    <Flex
      bg="neutrals.50"
      p={3}
      borderRadius={2}
      flex={1}
      flexDirection="column"
      mb={3}
    >
      <Text mb={2}>Why did {toTitleCase(data.worker.name)} leave early?</Text>
      <Textarea
        value={data.finishedEarlyReason}
        onChange={(e) => setValue('finishedEarlyReason', e.target.value)}
        minRows={1}
        placeholder="Tell us what happened..."
      />
    </Flex>
  )
}
