import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Box, Button, Flex, Text } from 'ui'

const SummaryTable = styled(Box).attrs({
  borderRadius: 'standard',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'neutrals.100',
  px: [3, 4],
  py: [2, 3],
  mt: [2, 3],
})``

const LineItem = styled(Flex).attrs({
  flexDirection: 'row',
  alignItems: 'center',
  py: 1,
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: 'neutrals.100',
})`
  &:last-child {
    border-bottom: none;
  }
`
interface Props {
  lineItems: {
    title: string
    value: ReactNode
    cta?: () => void
  }[]
}

export function SummaryBox(props: Props) {
  const { lineItems } = props

  return (
    <SummaryTable>
      {lineItems.map((item) => (
        <LineItem key={item.title}>
          <Box flex={1}>{item.title}</Box>
          <Box
            textAlign={'right'}
            mr={4}
            fontWeight={2}
            maxWidth={[150, 200, 300]}
          >
            <Text maxLines={2} aria-label={`${item.value} ${item.title}`}>
              {item.value}
            </Text>
          </Box>
          <Button variant={'text'} onClick={item.cta}>
            Edit
          </Button>
        </LineItem>
      ))}
    </SummaryTable>
  )
}
