import React, { useState } from 'react'
import { QuizQuestion } from 'api/shift/typings'
import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  Input,
  RadioGroup,
  Text,
} from '@workwhile/ui'
import { useEditorContext } from './hooks/useEditorContext'
import { useDebounceEffect } from 'ahooks'

export function QuizQuestionCard({ question }: { question: QuizQuestion }) {
  const { setQuiz } = useEditorContext()
  const [questionLabel, setQuestionLabel] = useState(question.label)
  const [correctValue, setCorrectValue] = useState(question.correct_value)
  const [answers, setAnswers] = useState(question.values)

  // debounced to avoid too many updates
  useDebounceEffect(
    () => {
      const updatedQuiz = (item: QuizQuestion) => {
        if (item.key === question.key) {
          return {
            ...item,
            label: questionLabel,
            values: answers,
            correct_value: correctValue,
          }
        }
        return item
      }
      setQuiz((quiz) => (quiz || []).map(updatedQuiz))
    },
    [questionLabel, answers, correctValue],
    { wait: 500 }
  )

  return (
    <Card key={question.key} borderWidth={0} mb={3}>
      <Heading level={4} my={0} mb={2}>
        <Input
          block
          style={{ fontWeight: 'bold' }}
          defaultValue={questionLabel}
          onChange={(e) => setQuestionLabel(e.target.value)}
        />
      </Heading>
      <RadioGroup
        options={question.values}
        name={question.key}
        value={correctValue}
        optionStyleProps={{ mb: 2 }}
        onChange={(value) => setCorrectValue(value as string)}
        renderOption={({ option, isSelected, indicator }) => (
          <Flex alignItems={'center'} key={option.value} flexWrap={'wrap'}>
            {indicator}
            <Box mx={2} flex={1}>
              <Input
                block
                defaultValue={option.label as string}
                onChange={(e) =>
                  setAnswers((prevAnswers) =>
                    prevAnswers.map((item) => {
                      if (item.value === option.value) {
                        return {
                          ...item,
                          label: e.target.value,
                        }
                      }
                      return item
                    })
                  )
                }
              />
            </Box>
            <Box width={['100%', 120]}>
              {isSelected ? (
                <Text color={'primary'}>Correct Answer</Text>
              ) : null}
            </Box>
          </Flex>
        )}
      />
      <Box>
        <Button
          variant={'text'}
          danger
          onClick={() =>
            setQuiz((prev) =>
              (prev ?? []).filter((item) => item.key !== question.key)
            )
          }
        >
          Remove Question
        </Button>
      </Box>
    </Card>
  )
}
