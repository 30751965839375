import React from 'react'
import styled from 'styled-components'
import { Box, Text } from 'ui'
import { TrendingDownIcon } from 'lucide-react'

export const FillRateRec = styled(Box).attrs<{
  text: string
}>((props) => ({
  display: 'inline-flex',
  alignItems: 'center',
  children: (
    <>
      <Text color={'error'}>
        <TrendingDownIcon size={18} />
      </Text>
      <Text ml={2} color={'primary'}>
        {props.text}
      </Text>
    </>
  ),
}))``
