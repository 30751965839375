import { DemoCategory } from '../types'
import { CompanyInsight } from 'api/analytics'
import { addWeeks, format } from 'date-fns'
import { apiDateFormat } from 'api/api'
import { CompanyWorker } from 'api/worker'

export function createMockInsightsForCategory(
  category: DemoCategory,
  workers: CompanyWorker[]
): CompanyInsight {
  const today = new Date()
  const weeklyFillRates = Array.from({ length: 12 }).map((_, index) => {
    const weekStartDate = format(addWeeks(today, -11 + index), apiDateFormat)
    const workersRequested = Math.floor(Math.random() * 20) + 10
    const workersFilled = Math.floor(
      workersRequested * (0.89 + Math.random() * 0.08)
    )

    return {
      weekStartDate,
      workersRequested,
      workersFilled,
    }
  })

  const weeklyRatings = Array.from({ length: 12 }).map((_, index) => {
    return {
      weekStartDate: format(addWeeks(today, -11 + index), apiDateFormat),
      avgRating: 3.5 + Math.random(),
    }
  })

  const weeklySpends = {
    weekStartDate: format(today, apiDateFormat),
    totalSpend: Math.floor(Math.random() * 5000) + 3000,
    totalPay: Math.floor(Math.random() * 4000) + 2500,
    totalBonus: Math.floor(Math.random() * 1000) + 500,
  }

  const topWorkers = workers.slice(0, 5).map((worker) => ({
    worker: {
      ...worker,
      rating: 4 + Math.random(),
    },
    totalShifts: Math.floor(Math.random() * 50) + 20,
  }))

  return {
    weeklyFillRates,
    weeklyRatings,
    weeklySpends,
    avgShiftStats: {
      avgPay: 18 + Math.random() * 5,
      avgBonus: 2 + Math.random() * 3,
      recommendedPay: 22,
      recommendedBonus: 5,
      shortLeadDaysRate: 35,
      recommendedLeadDays: 3,
    },
    performance: {
      avgRating: 4.87,
      totalFiveStar: Math.floor(Math.random() * 40) + 180,
      totalFourStar: Math.floor(Math.random() * 30) + 20,
      totalThreeStar: Math.floor(Math.random() * 10) + 5,
      totalTwoStar: Math.floor(Math.random() * 3) + 1,
      totalOneStar: Math.floor(Math.random() * 2),
    },
    workerBench: {
      totalFavorite: Math.floor(Math.random() * 50) + 20,
      totalBlocked: Math.floor(Math.random() * 10),
      topWorkers,
    },
  }
}
