import React from 'react'
import { createSimpleMemoryRouter } from '../SimpleMemoryRouter'
import {
  RequestWorkers,
  ChooseOrientation,
  ReviewAndConfirm,
  NeedsPaymentMethod,
} from './components'

export const requestWorkersRouter = createSimpleMemoryRouter({
  routes: [
    {
      path: 'needs-payment-method',
      element: <NeedsPaymentMethod />,
    },
    {
      path: 'request-workers',
      element: <RequestWorkers />,
    },
    {
      path: 'choose-orientation',
      element: <ChooseOrientation />,
    },
    {
      path: 'review-and-confirm',
      element: <ReviewAndConfirm />,
    },
  ],
  initialEntries: ['request-workers'],
})
