function extractUrlsFromMarkdown(markdown: string) {
  const combinedRegex =
    /(!?)\[([^\]]*)\]\((https?:\/\/[^\s]+)(?:\s+"([^"]*)")?\)/g

  const results: string[] = []
  let match: RegExpExecArray | null
  while ((match = combinedRegex.exec(markdown)) !== null) {
    results.push(match[3])
  }
  return results
}

// Sanitize the markdown content
export function cleanUpContent(markdown: string) {
  const urls = extractUrlsFromMarkdown(markdown)
  let cleanedContent = markdown
  urls.forEach((url) => {
    const fixedUrl = url.replace('\\&', '&')
    if (fixedUrl !== url) {
      cleanedContent = cleanedContent.replace(url, fixedUrl)
    }
  })

  return cleanedContent
}
