import { graphql } from '../graphql'
import { path } from 'ramda'
import { ShiftEditData } from './typings'

export async function getShiftForEdit(id: number) {
  const data = await graphql(
    `company {
          shiftsDetail(ids: [${id}]) {
            rosters { 
              id, name, 
              workers {
                id, name, profilePicUrl
              } 
              isSpecificWorkerRequest
            }
            rostersHoldExpiresAt
            shiftBonuses { amount },
            position { name, id, hidden },
            location { name, id, address {city, state, street, unit, zip, lat, long, timezone}}
            locationless
            isTryout,
            id, startsAt, endsAt, shiftType, supervisor, supervisorPhone, supervisors (status: "scheduled") { id, name, phoneNumber, userId, status}, workersNeeded, 
            chargeLumpSum, chargeRate, payRate, payLumpSum, lunchLength, expectedMileage
            cancellationPolicy {
              policyWindows {
                cutoffTimeHours
                chargePercentage
              }
            },
            work {
              id,
              isAvailable,
              leftEarly,
              worker {
                id, name, profilePicUrl
              }
              confirmedAt
              earnings
              supervisorReasonEarlyClockOut,
              supervisorMarkedNoShow,
              startedAt, completedAt
              createdAt
              pendingAdminReview
              reservationExpiresAt
              reservationNotifiedAt
              status
              timeWorkedSeconds
              trips {status, shareUrl, eta, etaSeconds, locationStatus, serviceDeviceId}
            },
            unableToAttendWork {
              id
              status
              worker { id }
            }
          }
        }`,
    'shift_detail'
  )

  return path(
    ['data', 'data', 'company', 'shiftsDetail', 0],
    data
  ) as ShiftEditData
}
