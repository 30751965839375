import React, { useMemo } from 'react'
import { Page } from '../layout'
import { Box, Flex } from 'ui'
import { MessageList } from './MessageList'
import { useParams } from 'react-router-dom'
import { MessageDetails } from './MessageDetails'
import { useLatestMessages } from 'queries/message'
import { MessageEmptyState } from 'pages/MessagePage/MessageEmptyState'

export function MessagePage() {
  const params = useParams<{ workerId?: string }>()
  const workerId = params.workerId ? Number(params.workerId) : undefined
  const listMobileWidth = workerId ? 0 : 1
  const { data } = useLatestMessages()
  const isEmpty = useMemo(() => {
    if (!data) {
      return false
    }

    return data.pages[0].items.length === 0
  }, [data])

  return (
    <Page
      display={'flex'}
      flexDirection={'column'}
      title={'Messages'}
      fullHeight={true}
      py={[0, 0, 4]}
      px={[0, 0, 5]}
    >
      <Flex mt={[0, 0, 3]} flex={1}>
        <Box
          height={'100%'}
          overflow={'auto'}
          display={[
            listMobileWidth === 0 ? 'none' : 'block',
            listMobileWidth === 0 ? 'none' : 'block',
            listMobileWidth === 0 ? 'none' : 'block',
            'block',
          ]}
          width={[listMobileWidth, listMobileWidth, listMobileWidth, 2 / 5]}
          p={[3, 1]}
        >
          <MessageList />
        </Box>
        {workerId ? (
          <Box width={[1, 1, 1, 3 / 5]} pl={[0, 0, 0, 4, 6]}>
            <MessageDetails workerId={workerId} />
          </Box>
        ) : isEmpty ? (
          <MessageEmptyState />
        ) : null}
      </Flex>
    </Page>
  )
}
