import { getDemoData, matchGraphqlRequest } from '../utils'

export const mockGetRosters = {
  shouldMock: (request) => matchGraphqlRequest(request, /company\{rosters\{/),
  getResponse: async () => {
    const demoData = getDemoData()

    return {
      data: {
        company: {
          rosters: demoData.rosters,
        },
      },
    }
  },
}
