import { TimelineShift } from 'api/shift'
import { Company, User, Work, WorkShift } from '../typings/common_defs'
import { differenceInMinutes } from 'date-fns'
import { CompanyInfo } from 'api/company'
import { getLongDuration } from './time'
import { toFirstName, toTitleCase } from './string'
import {
  AssignedWorkerOptionsEnum,
  PRORATED_PAY_OPTIONS,
} from 'lib/work/assigned_work_options'
import { calculateEstimatedTimeWorkedInMinutes } from './work'

export const getTakePercentage = (company: Company | CompanyInfo) => {
  return company.takePercentageEstimate || 20
}

export const getChargeRate = (company: Company | CompanyInfo) => {
  return 1 + getTakePercentage(company) / 100
}

export const getShiftBonus = (shift: WorkShift | TimelineShift) => {
  return shift.shiftBonuses && shift.shiftBonuses.length > 0
    ? Number(shift.shiftBonuses[shift.shiftBonuses.length - 1].amount)
    : null
}

export const getShiftPay = (shift: WorkShift | TimelineShift) => {
  return shift.payLumpSum
    ? Number(shift.payLumpSum).toFixed(2)
    : Number(shift.payRate).toFixed(2)
}

/**
 * Calculate estimated pay for work on a shift
 * - NOTE: excludes shift bonuses
 * @param {Work} work
 * @param {TimelineShift} shift
 * @returns {number}
 */
export const calculateEstimatedPay = (
  work: Work,
  shift: TimelineShift
): number => {
  // if the worker is marked bailed, return 0
  if (work.status === 'bailed' || work.supervisorMarkedNoShow) return 0

  // if lump sum, return lump sum + bonus
  if (shift.payLumpSum) return Number(shift.payLumpSum)

  // TODO: handle lump sum partial pay, once we support it

  // if hourly, return hourly rate * hours worked
  // - factor in leftEarly value to determine whether to prorate or full pay
  const hourlyRate = Number(shift.payRate)

  if (!work.startedAt || !work.completedAt) return 0
  const shiftStartDate = new Date(shift.startsAt)
  const shiftEndDate = new Date(shift.endsAt)
  let hoursWorked = calculateEstimatedTimeWorkedInMinutes(work) / 60 || 0
  const minPayLengthMins = shift.minimumPayPolicy?.minPayLength

  // if the worker finished early, check if we should prorate
  let shouldProrate =
    work.finishedEarlyCategory &&
    PRORATED_PAY_OPTIONS.includes(
      work.finishedEarlyCategory as AssignedWorkerOptionsEnum
    )

  // the duration if the worker worked the full shift
  let fullShiftHours = differenceInMinutes(shiftEndDate, shiftStartDate) / 60

  if (minPayLengthMins) {
    // if there is a min pay policy in place for this company
    const isUnderMinHours = hoursWorked < minPayLengthMins / 60
    if (isUnderMinHours) {
      // if dismissed early (i.e. biz portal user doesn't want to prorate)
      // and worker worked less than min hours, pay for min hours
      fullShiftHours = minPayLengthMins / 60
    } else {
      // if the worker worked more than min hours, pay for time worked
      shouldProrate = true
    }
  }

  // subtract lunch length (i.e. unpaid break) if necessary
  if (shift.lunchLength) {
    fullShiftHours -= shift.lunchLength / 60
    hoursWorked -= shift.lunchLength / 60
  }

  const totalPay = shouldProrate
    ? hourlyRate * hoursWorked
    : hourlyRate * fullShiftHours
  return totalPay
}

/**
 * Get the text shown for estimated pay for a work
 * @param user
 * @param shift
 * @returns
 */
export const getMinimumPayText = (
  user: User | undefined,
  shift: TimelineShift | undefined
) => {
  const minimumPayMessage = shift?.minimumPayPolicy?.minPayLength
    ? `the first ${getLongDuration(
        shift.minimumPayPolicy.minPayLength
      )} as minimum pay as this shift has a ${getLongDuration(
        shift.minimumPayPolicy.minPayLength
      )} Minimum Pay Policy in place.`
    : 'full shift pay (adjusted if started late)'
  const userName = user ? toTitleCase(toFirstName(user?.name)) : 'Worker'

  return `
      ${userName} completed assigned work and/or was dismissed early by a Supervisor. ${userName} will receive ${minimumPayMessage}.
    `
}
