import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Box, Button, Flex, Message, SwitchRoot, SwitchThumb, Text } from 'ui'
import { AcknowledgementCheck } from '../../AcknowledgementCheck'
import { RSWCard } from './RSWCard'
import { useShiftEditor } from '../../useShiftEditor'
import { EditorMode } from '../../ShiftEditorProvider'

interface Props {
  openSelector?: () => void
}

export function SelectedRSW(props: Props) {
  const {
    watch,
    control,
    setValue,
    formState: { errors },
  } = useFormContext()
  const specificWorkers = watch('specificWorkers') || []
  const { editorMode } = useShiftEditor()

  return (
    <>
      {specificWorkers.length > 0 ? (
        <>
          <Flex alignItems={'center'} mb={-2}>
            <Text fontWeight={2}>Status -</Text>
            <Text>&nbsp;{specificWorkers.length} requested</Text>
            <Box flex={1} />
            <Button variant={'text'} onClick={props.openSelector}>
              Edit
            </Button>
          </Flex>
          <hr />
        </>
      ) : null}
      <Flex mx={-2} mb={[4, 2]} flexWrap={'wrap'}>
        {specificWorkers.map((worker) => (
          <RSWCard
            data={worker}
            key={worker.id}
            onRemove={() => {
              setValue(
                'specificWorkers',
                specificWorkers.filter((w) => w.id !== worker.id)
              )
            }}
          />
        ))}
      </Flex>
      {errors.specificWorkers?.message ? (
        <Message variant={'error'} my={3}>
          {errors.specificWorkers.message.toString()}
        </Message>
      ) : null}
      {specificWorkers.length > 0 && editorMode !== EditorMode.PastShift ? (
        <AcknowledgementCheck p={3} mx={-5} mb={-3}>
          <Controller
            name={'restrictShiftToAudience'}
            control={control}
            render={({ field }) => (
              <Flex justifyContent="space-between">
                <Text mr={3}>
                  If requested workers are unable to attend, let other qualified
                  workers accept.
                </Text>
                <SwitchRoot
                  checked={!field.value}
                  onCheckedChange={() => field.onChange(!field.value)}
                >
                  <SwitchThumb>{field.value ? 'OFF' : 'ON'}</SwitchThumb>
                </SwitchRoot>
              </Flex>
            )}
          />
        </AcknowledgementCheck>
      ) : null}
    </>
  )
}
