// src/components/LoginCallback.js
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { OktaAuth, Token } from '@okta/okta-auth-js'
import { api } from 'api/api'
import { useOktaSignInMutation, useOktaSignUpMutation } from 'queries/user'
import { track } from 'lib/amplitude'
import { toast, Loading } from '@workwhile/ui'
import { OktaData } from 'lib/okta'

const OktaLoginCallback = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState<string | null>(null)

  useEffect(() => {
    track('impression, oktaSignInCallback')
  }, [])

  const {
    mutate: signInOkta,
    isPending: isSigningInOkta,
    error: errorOkta,
  } = useOktaSignInMutation({
    async onSuccess(userCredential) {
      track('impression, success: firebase sign in')
      try {
        await userCredential.user.getIdToken()
      } catch {
        track('impression, failed: get user idToken okta')
      }
      track('impression, success: get user idToken okta')
      location.href = '/home'
      track('impression, doneWithOktaSignInCallback')
    },
    onError(err) {
      track('impression, failed: okta sign in')
      toast.error('Failed to sign in with Okta:' + err.message)
    },
  })

  const {
    mutate: signUp,
    isPending: isSigningUp,
    error: errorSignup,
  } = useOktaSignUpMutation({
    onSuccess() {
      location.href = '/home'
    },
  })

  useEffect(() => {
    const oktaDataRaw: string | null = localStorage.getItem('oktaData')
    if (!oktaDataRaw) {
      toast.error('Okta data not found')
      track('impression, okta callback: data not found')
      return
    }
    const oktaData: OktaData = JSON.parse(oktaDataRaw)
    const oktaAuth = new OktaAuth({
      issuer: oktaData.baseUrl,
      clientId: oktaData.clientId,
      redirectUri: window.location.origin + '/okta_callback',
      scopes: ['openid', 'profile'],
    })

    const handleAuthentication = async () => {
      try {
        if (oktaAuth.isLoginRedirect()) {
          // Parses tokens from the URL.
          await oktaAuth.handleRedirect()

          const idToken: Token | undefined = await oktaAuth.tokenManager.get(
            'idToken'
          )

          track('impression, okta callback: got idToken')

          api
            .post('/okta/token', {
              id_token: idToken,
              invite_code: oktaData.inviteCode,
              email: oktaData.email,
            })
            .then((response) => {
              // Use the Firebase SDK to authenticate with the custom token
              setLoading(false)
              if (oktaData.type === 'login') {
                track('impression, okta callback: firebase token signin')
                signInOkta({ firebaseToken: response.data.firebaseToken })
              } else if (oktaData.type === 'signup') {
                if (
                  !oktaData.name ||
                  !oktaData.phoneNumber ||
                  !oktaData.inviteCode
                ) {
                  setError('Name, phone number or invite code not found')
                  return
                }
                track('impression, okta callback: firebase token sign up')
                signUp({
                  name: oktaData.name,
                  phoneNumber: oktaData.phoneNumber,
                  email: oktaData.email,
                  inviteToken: oktaData.inviteCode,
                  firebaseToken: response.data.firebaseToken,
                })
              }
            })
            .catch((error) => {
              setLoading(false)
              //onError(error)
              track('impression, okta: failed to signin', {
                error: error.message,
              })
              toast.error('Error while logging in with Okta: ' + error.message)
            })
        } else {
          toast.error(
            'Incorrect or Malformed Okta Callback URL. Please contact support.'
          )
        }
      } catch (error) {
        navigate('/login') // Redirect to login on error
        toast.error('Error Handling Okta Callback. Please contact support.')
      }
    }

    handleAuthentication()
  }, [navigate])

  if (loading || isSigningInOkta || isSigningUp) {
    return <Loading />
  }

  if (errorOkta) {
    return <div>Failed to sign in with Okta: {errorOkta.message}</div>
  }
  if (error) {
    return <div>{error}</div>
  }
  if (errorSignup) {
    return <div>Failed to sign up with Okta: {errorSignup.message}</div>
  }

  return <div>Loading...</div>
}

export default OktaLoginCallback
