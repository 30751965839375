import { useQuery } from '@tanstack/react-query'
import { companyRolesKey } from './keys'
import { getCompanyRoles } from 'api/company'

export function useCompanyRoles(options?: { enabled?: boolean }) {
  return useQuery({
    queryKey: companyRolesKey,
    queryFn: getCompanyRoles,
    staleTime: Infinity, // the only time this would be stale is if user changes
    ...options,
  })
}
