import React from 'react'
import { useNavigate, useLocation } from 'react-router'
import useShiftTimeline from '../../hooks/useShiftTimeline'
import { shouldShowDuplicateShiftAction } from '../../lib/shift'
import { AuthGuard } from 'components/auth'
import { PermissionEnum } from 'api/userPermissions'
import { Box, Button, Flex } from 'ui'
import { useShiftDetail } from './useShiftDetail'
import { useModalNavigate } from 'hooks/useModalNavigate'

export const ShiftDetailHeader = () => {
  const navigate = useNavigate()
  const modalNavigate = useModalNavigate()
  const location = useLocation()

  const { shift } = useShiftDetail()

  const { isInProgress, isInPast } = useShiftTimeline(shift)

  if (!shift) return null

  const handleClose = () => {
    // if there is no option to go back, user entered URL directly into browser
    // - in that case, redirect to home
    if (location.key === 'default') {
      navigate('/')
      return
    }
    navigate(-1)
  }

  /**
   * Content shown only once the shift has been fetched from server
   */
  const getShiftLoadedContent = () => {
    if (!shift) return null
    return (
      <Flex>
        {shouldShowDuplicateShiftAction(shift) && (
          <AuthGuard.Permission value={PermissionEnum.ManageShifts}>
            <Box mr={4}>
              <Button
                size="large"
                variant="text"
                onClick={() => {
                  modalNavigate(
                    `/home/shift-editor/new?reference_id=${shift.id}`
                  )
                }}
              >
                Duplicate
              </Button>
            </Box>
          </AuthGuard.Permission>
        )}
        <AuthGuard.Permission value={PermissionEnum.ManageShifts}>
          <Box mr={4}>
            <Button
              size="large"
              variant="text"
              disabled={isInProgress || isInPast}
              onClick={() => {
                modalNavigate(`/home/shift-editor/${shift.id}/confirm`)
              }}
            >
              Edit
            </Button>
          </Box>
        </AuthGuard.Permission>
      </Flex>
    )
  }

  return (
    <Box
      position="sticky"
      display="flex"
      top={0}
      backgroundColor="white"
      zIndex={1000}
      justifyContent="flex-end"
    >
      {getShiftLoadedContent()}
      <Button kind="large" variant="secondary" onClick={handleClose}>
        Exit
      </Button>
    </Box>
  )
}
