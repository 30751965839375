import snakecaseKeys from 'snakecase-keys'

export const truncate = (str: string, n: number): string => {
  if (!str || !str.length) {
    return str
  }
  return str.length > n ? str.substr(0, n) + '...' : str
}

export function toSnakecaseKeys(obj: object, options?: { deep?: boolean }) {
  return snakecaseKeys(
    { ...obj },
    {
      deep: options?.deep ?? false,
    }
  )
}

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export function formatCurrency(amount: number | string) {
  return USDollar.format(Number(amount))
}

export function toTitleCase(text: string) {
  return typeof text === 'string'
    ? text
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')
    : ''
}

export const toFirstName = (text: string) =>
  typeof text === 'string'
    ? text
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')
        .split(' ')[0]
    : ''
