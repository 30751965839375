import { track } from 'lib/amplitude'
import { useShiftDetail } from 'pages/ShiftDetailPage/useShiftDetail'
import React, { useEffect, useState, FormEvent } from 'react'
import { Work } from 'typings/common_defs'
import { Button, Heading, Modal, Textarea, Text, toast } from 'ui'
import { useSendMessageMutation } from 'queries/message'
import { getAssignedWork } from 'lib/work'

interface Props {
  trigger: React.ReactNode
}

export const MessageShiftWorkersModal = ({ trigger }: Props) => {
  const { shift } = useShiftDetail()
  const key = 'send_all_shift_workers_message'
  const [message, setMessage] = useState('')
  const [open, setOpen] = useState(false)

  useEffect(() => {
    // componentDidMount
    track(`impression, ${key}`)
  }, [])

  const { mutate: sendMessage, isPending: isSendingMessage } =
    useSendMessageMutation({
      onSuccess: () => {
        toast.success('Message sent to all workers.')
        setMessage('')
        setOpen(false)
      },
    })

  const handleSubmit = (e: FormEvent) => {
    track(`button_click, ${key}`)
    e.preventDefault()
    setOpen(true) // set to True to trigger useEffect in modal component that handles closing on open == False
    if (shift?.id && message.trim().length > 0) {
      const workList = shift.work || []
      const assignedWorkers = getAssignedWork(workList)
      const workerIds = assignedWorkers.map((work: Work) =>
        Number(work.worker?.id)
      )
      sendMessage({
        message: message.trim(),
        shiftId: shift.id,
        toUserIds: workerIds,
      })
    }
  }

  if (!shift) return null

  return (
    <Modal
      trigger={trigger}
      width={[1, 900]}
      open={open}
      customCta={
        <Button
          kind="medium"
          variant="primary"
          onClick={handleSubmit}
          disabled={isSendingMessage}
          loading={isSendingMessage}
        >
          Send
        </Button>
      }
    >
      <Heading level={3}>
        Send a text message to all workers scheduled for this shift.
      </Heading>
      <Text mb={3}>You will be notified via email if you get a response.</Text>
      <Textarea
        value={message}
        placeholder="Write your message here"
        onChange={(e) => setMessage(e.target.value)}
        minRows={2}
      />
    </Modal>
  )
}
