import React from 'react'
import { config } from 'config'
import styled from 'styled-components'
import { Box, Checkbox, Flex, Label, Text, Tooltip } from 'ui'
import { rgba } from 'polished'
import { useApiHost } from './useApiHost'

const DevToolsContainer = styled(Box).attrs({
  position: 'fixed',
  py: 2,
  px: 2,
  left: 0,
  zIndex: 10000,
  borderTopRightRadius: 'standard',
  borderBottomRightRadius: 'standard',
})`
  ${({ theme: { colors } }) => `
    background-color: ${rgba(colors.infos[50], 0.8)};
    
    .env-toggle, .env-label-long {
      display: none;
    }
    
    &:hover {
      .env-toggle, .env-label-long {
        display: block;
      }
      
      .env-label-short {
        display: none;
      }
    }
  `}
`

export function DevTools() {
  const { apiEnv, apiHostUrl, override, setOverride } = useApiHost()

  if (config.env === 'production') {
    return null
  }

  return (
    <DevToolsContainer bottom={config.env === 'staging' ? 15 : 70}>
      <Flex alignItems={'center'} justifyContent={'center'}>
        <Tooltip content={apiHostUrl}>
          <Label
            variant={apiEnv === 'production' ? 'information' : 'warning'}
            style={{ textTransform: 'capitalize' }}
          >
            <span className={'env-label-long'}>Api: {apiEnv}</span>
            <span className={'env-label-short'}>{apiEnv.substring(0, 1)}</span>
          </Label>
        </Tooltip>
        <Box ml={3} className={'env-toggle'}>
          <Checkbox defaultChecked={override} onChange={setOverride}>
            Staging
          </Checkbox>
        </Box>
      </Flex>
    </DevToolsContainer>
  )
}
