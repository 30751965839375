import React from 'react'
import { useNavigate } from 'react-router'
import { Box, Button, createColumnHelper, Flex, Text } from 'ui'
import { isShiftInstructionImprovementsEnabled } from 'lib/flags'
import { Location, Position } from 'typings/common_defs'
import { AddressString } from 'components/AddressString'
import { EditLocationButton } from './EditLocationButton'
import { EditPositionButton } from './EditPositionButton'
import { TruncatedDescription } from './TruncatedDescription'

const locationColumnHelper = createColumnHelper<Location>()

const ActionLocationButtons = ({ location }: { location: Location }) => {
  const navigate = useNavigate()
  const hasTutorials = location?.tutorials?.length ?? 0 > 0
  const shiftInstructionImprovementsEnabled =
    isShiftInstructionImprovementsEnabled()

  if (shiftInstructionImprovementsEnabled) {
    return (
      <Flex flexWrap={['wrap', 'nowrap']}>
        <Button
          width={1}
          kind={'small'}
          variant={'text'}
          mr={[0, 3]}
          my={[3, 0]}
          onClick={() => {
            navigate(`/location/${location.id}/instructions`)
          }}
        >
          {hasTutorials ? 'Edit Tutorials' : 'Add Tutorials'}
        </Button>
        <EditLocationButton locationId={Number(location.id)} />
      </Flex>
    )
  }

  return <EditLocationButton locationId={Number(location.id)} />
}

export const createLocationColumns = (nameColSize?: number) => [
  locationColumnHelper.accessor('name', {
    header: 'Name',
    size: nameColSize,
    cell: (info) => (
      <>
        <Box pr={3} display={['none', 'none', 'block']}>
          <Text maxLines={2}>{info.getValue()}</Text>
        </Box>
        <Box display={['block', 'block', 'none']} py={2}>
          <Text maxLines={2} fontWeight={2}>
            {info.getValue()}
          </Text>
          <Text>
            {info.row.original.address ? (
              <AddressString address={info.row.original.address} />
            ) : null}
          </Text>
          <Box mt={2}>
            <EditLocationButton locationId={Number(info.row.original.id)} />
          </Box>
        </Box>
      </>
    ),
  }),
  locationColumnHelper.accessor('about', {
    header: 'Address',
    cell: (info) =>
      info.row.original.address ? (
        <AddressString address={info.row.original.address} />
      ) : null,
  }),
  locationColumnHelper.accessor('id', {
    header: '',
    size: 120,
    cell: (info) => <ActionLocationButtons location={info.row.original} />,
  }),
]

const positionColumnHelper = createColumnHelper<Position>()

const ActionPositionButtons = ({ position }: { position: Position }) => {
  const navigate = useNavigate()
  const hasTutorials = position?.tutorials?.length ?? 0 > 0
  const shiftInstructionImprovementsEnabled =
    isShiftInstructionImprovementsEnabled()
  if (shiftInstructionImprovementsEnabled) {
    return (
      <Flex flexWrap={['wrap', 'nowrap']}>
        <Button
          kind={'small'}
          variant={'text'}
          mr={3}
          onClick={() => {
            navigate(`/position/${position.id}/instructions`)
          }}
        >
          {hasTutorials ? 'Edit Tutorials' : 'Add Tutorials'}
        </Button>
        <EditPositionButton positionId={Number(position.id)} />
      </Flex>
    )
  }

  return <EditPositionButton positionId={Number(position.id)} />
}

export const createPositionColumns = (nameColSize?: number) => [
  positionColumnHelper.accessor('name', {
    header: 'Name',
    size: nameColSize,
    cell: (info) => (
      <>
        <Box pr={3} display={['none', 'none', 'block']}>
          <Text maxLines={2}>{info.getValue()}</Text>
        </Box>
        <Box display={['block', 'block', 'none']} py={2}>
          <Text maxLines={2} fontWeight={2}>
            {info.getValue()}
          </Text>
          <TruncatedDescription
            maxLength={140}
            content={info.row.original.about ?? ''}
          />
          <Box mt={2}>
            <ActionPositionButtons position={info.row.original} />
          </Box>
        </Box>
      </>
    ),
  }),
  positionColumnHelper.accessor('about', {
    header: 'Description',
    cell: (info) => (
      <TruncatedDescription content={info.getValue() ?? ''} maxLength={200} />
    ),
  }),
  positionColumnHelper.accessor('id', {
    header: '',
    size: 120,
    cell: (info) => <ActionPositionButtons position={info.row.original} />,
  }),
]
