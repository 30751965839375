import { useQuery, useQueryClient } from '@tanstack/react-query'
import { companyPositionRosterKey } from './keys'
import { getPositionRoster } from 'api/company'

interface UseCompanyPositionRosterOptions {
  positionId: number
  enabled?: boolean
  // allow certain view to always get latest data independent of cache/stale time, e.g. position list
  refetchOnMount: boolean | 'always'
}

export function useCompanyPositionRoster(
  options: UseCompanyPositionRosterOptions
) {
  return useQuery({
    queryKey: companyPositionRosterKey(options.positionId),
    queryFn: () => getPositionRoster(options.positionId),
    gcTime: Infinity, // only invalidate on adding/editing position
    staleTime: Infinity,
    ...options,
  })
}

export function useInvalidateCompanyPositionRoster(positionId: number) {
  const queryClient = useQueryClient()

  return {
    invalidate: () => {
      if (positionId == -1) {
        return null
      }
      return queryClient.invalidateQueries({
        queryKey: companyPositionRosterKey(positionId),
      })
    },
  }
}
