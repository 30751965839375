import { useAuthContext } from 'components/auth'
import { useMutation } from '@tanstack/react-query'
import { giveCompanyWorkerBonus } from 'api/worker'

interface Props {
  onSuccess?: () => void
  onError?: (err: Error) => void
}

export function useGiveWorkerBonusMutation(options: Props) {
  const { company } = useAuthContext()

  return useMutation({
    mutationFn: (bonusOptions: {
      workerId: number
      amount: number
      reason: string
    }) =>
      giveCompanyWorkerBonus({
        companyId: company?.id as number,
        ...bonusOptions,
      }),
    ...options,
  })
}
