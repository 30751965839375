import { getChargeRate } from 'lib/payment'
import { CalculatorIcon } from 'lucide-react'
import React from 'react'
import styled from 'styled-components'
import { Flex, Box, DisplayIcon, Heading, Text } from 'ui'
import { useShiftDetail } from '../useShiftDetail'
import { useCompanyInfo } from 'queries/company'
import { formatCurrency } from 'lib/string'

const LineItem = styled(Flex).attrs({
  px: 3,
  fontSize: 1,
})``

export const ShiftDetailPaymentSummary = () => {
  const { data: company } = useCompanyInfo()
  const { shift } = useShiftDetail()

  if (!shift || !company) return null

  const chargeRate = getChargeRate(company)
  const subtotal = shift.totalEstimatedCharge
    ? shift.totalEstimatedCharge / chargeRate
    : 0
  const bookingFees = shift.totalEstimatedCharge
    ? shift.totalEstimatedCharge - subtotal
    : 0
  return (
    <Box
      p={3}
      backgroundColor={'neutrals.50'}
      borderRadius={'large'}
      color={'neutrals.400'}
    >
      <Box display={'inline-flex'} alignItems={'center'}>
        <DisplayIcon>
          <CalculatorIcon size={16} />
        </DisplayIcon>
        <Heading level={4} my={0} ml={2}>
          Charge Estimate
        </Heading>
      </Box>
      <Box mt={3}>
        <LineItem>
          <Box flex={1}>Worker Pay</Box>
          {formatCurrency(subtotal)}
        </LineItem>
        <LineItem>
          <Box flex={1}>
            WorkWhile Fee ({((chargeRate - 1) * 100).toPrecision(2)}%)
          </Box>{' '}
          {formatCurrency(bookingFees)}
        </LineItem>
        <hr />
        <LineItem>
          <Box flex={1}>Total</Box>
          <Text fontWeight={1} fontSize={3} color={'info'}>
            {formatCurrency(shift?.totalEstimatedCharge || 0)}
          </Text>
        </LineItem>
      </Box>
    </Box>
  )
}
