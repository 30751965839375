import { useQuery } from '@tanstack/react-query'
import { locationTutorialKey } from './keys'
import { getLocationTutorial } from 'api/company'

export function useLocationTutorial(locationId: number, tutorialId?: number) {
  return useQuery({
    queryKey: locationTutorialKey(locationId, tutorialId),
    queryFn: () => {
      if (tutorialId) {
        return getLocationTutorial(locationId, tutorialId)
      }
    },
    enabled: !!tutorialId,
    gcTime: 0,
  })
}
