import React from 'react'
import { Page } from '../layout'
import { AnalyticsProvider } from './AnalyticsProvider'
import { FilterBar } from './FilterBar'
import { Box, Flex } from 'ui'
import { FillRate } from './FillRate'
import { Performance } from './Performance'
import { WorkerBench } from './WorkerBench'

export function AnalyticsPage() {
  return (
    <AnalyticsProvider>
      <Page title={'Analytics'} fullHeight={true}>
        <FilterBar />
        <Flex mt={[3, 4]} flexWrap={'wrap'}>
          <Box width={[1, 1, 1, 4 / 7, 2 / 3]} pr={[0, 0, 0, 4]}>
            <FillRate />
            <Performance />
          </Box>
          <Box width={[1, 1, 1, 3 / 7, 1 / 3]}>
            <WorkerBench />
          </Box>
        </Flex>
      </Page>
    </AnalyticsProvider>
  )
}
