import React from 'react'
import { Button, Flex, Link, Text } from '@workwhile/ui'

export const NeedsPaymentMethod = () => {
  return (
    <Flex
      py={4}
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Text color="error">
        You need to have a payment method on file to request workers.
      </Text>
      <Button as={Link} to="/payments" variant="primary" mt={3} kind="medium">
        <Text color="white">Add Payment Method</Text>
      </Button>
    </Flex>
  )
}
