import React, { useMemo, useState } from 'react'
import {
  Box,
  Loading,
  Text,
  Table,
  DatePicker,
  Flex,
  Button,
  useResponsiveValue,
  DropdownMenu,
  toast,
} from 'ui'
import { useInvoices } from 'queries/payment'
import { invoiceTableColumns } from './columns'
import { sort } from 'ramda'
import { addMonths, endOfDay, format, parse, startOfDay } from 'date-fns'
import { getInvoiceCSVData } from 'api/payment'
import { useCompanyInfo } from 'queries/company'
import fileDownload from 'js-file-download'
import { useSearchParams } from 'react-router-dom'

const dateFormat = 'yyyy-MM-dd'

export function PaidInvoices() {
  const columnsToShow = useResponsiveValue([3, 4])
  // default to past month
  const [searchParams, setSearchParams] = useSearchParams()
  const dateRange = useMemo(() => {
    const from = searchParams.get('from')
    const to = searchParams.get('to')

    return {
      from: from
        ? parse(from, dateFormat, new Date())
        : startOfDay(addMonths(new Date(), -1)),
      to: to ? parse(to, dateFormat, new Date()) : endOfDay(new Date()),
    }
  }, [searchParams])

  const { data: invoices, isLoading } = useInvoices({
    type: 'paid',
    startDate: dateRange.from,
    endDate: dateRange.to,
  })
  const sortedInvoices = useMemo(() => {
    if (!invoices) {
      return []
    }
    return sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    }, invoices || [])
  }, [invoices])
  const [loadingCSV, setLoadingCSV] = useState(false)
  const { data: company } = useCompanyInfo()

  const handleDownloadCSV = async (type: 'summary' | 'details') => {
    if (company) {
      setLoadingCSV(true)
      try {
        const data = await getInvoiceCSVData(company.id, {
          type,
          startDate: dateRange.from,
          endDate: dateRange.to,
        })
        fileDownload(
          data,
          `workwhile_invoice_${type}_${format(
            dateRange.from,
            dateFormat
          )}-${format(dateRange.to, dateFormat)}.csv`
        )
        setLoadingCSV(false)
      } catch (err) {
        toast.error('Unable to generate CSV, please try again later.')
        setLoadingCSV(false)
      }
    }
  }

  return (
    <Box py={[0, 3]}>
      <Flex
        mb={4}
        flexDirection={'row'}
        justifyContent={'flex-end'}
        flexWrap={'wrap'}
      >
        <DropdownMenu
          trigger={
            <Button
              loading={loadingCSV}
              disabled={isLoading || !invoices}
              mt={[3, 0]}
              width={[1, 150]}
              variant={'secondary'}
            >
              Download CSV
            </Button>
          }
        >
          <DropdownMenu.Item onSelect={() => handleDownloadCSV('summary')}>
            Download Summary
          </DropdownMenu.Item>
          <DropdownMenu.Item onSelect={() => handleDownloadCSV('details')}>
            Download Details
          </DropdownMenu.Item>
        </DropdownMenu>
        <Box width={[1, 300]} ml={[0, 3]} mt={[3, 0]}>
          <DatePicker
            disabled={isLoading || loadingCSV}
            mode={'range'}
            selected={dateRange}
            onSelect={(range) => {
              if (range) {
                const { from, to } = range as { from: Date; to: Date }
                setSearchParams((params) => {
                  params.set('from', format(from, dateFormat))
                  params.set('to', format(to, dateFormat))
                  return params
                })
              }
            }}
            selectCta={'Apply'}
          />
        </Box>
      </Flex>
      {isLoading ? <Loading /> : null}
      {!isLoading && sortedInvoices ? (
        <Table
          columns={invoiceTableColumns.slice(0, columnsToShow)}
          data={sortedInvoices}
        />
      ) : null}
      {!isLoading && sortedInvoices.length === 0 ? (
        <Text p={3} color={'neutrals.200'}>
          No Past Invoice
        </Text>
      ) : null}
    </Box>
  )
}
