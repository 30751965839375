import React from 'react'
import { Heading, Modal, Paragraph, Text } from 'ui'
import { InfoIcon } from 'lucide-react'

export function ShiftLeadInfoModal() {
  return (
    <Modal
      width={[1, 700]}
      title={'Why Shift Lead?'}
      onOk={({ closeModal }) => closeModal()}
      okText={'Got It'}
      showCancel={false}
      trigger={
        <Text
          color={'primary'}
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          Learn more about Shift Leads&nbsp;
          <InfoIcon size={16} />
        </Text>
      }
    >
      <Heading level={4} my={0}>
        Role and Duties:
      </Heading>
      <Paragraph my={0}>
        A Shift Lead is a seasoned worker who can help your on-site supervisor
        manage your team effectively. Their tasks include clocking in and out
        for workers, managing workers' timesheets, marking absent workers, and
        communicating with the workers to ensure the shift runs smoothly. We
        advise having one Shift Lead for every 20 workers to ensure optimal
        workflow. We also encourage offering a higher pay rate to Shift Leads,
        in recognition of their added responsibilities and expertise.
      </Paragraph>
      <Heading level={4} my={0} mt={3}>
        What Happens Next:
      </Heading>
      <Paragraph my={0}>
        We'll schedule a separate shift for the Shift Leads at the times and pay
        rate you choose. This means you can easily see and manage the Shift
        Leads' shift alongside the regular workers' shift. We always make sure
        that only our most experienced workers are eligible to work as Shift
        Leads.
      </Paragraph>
    </Modal>
  )
}
