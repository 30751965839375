import React from 'react'
import { MessageShiftInfo } from 'api/message'
import { Box, Flex } from 'ui'
import styled from 'styled-components'
import { isToday } from 'date-fns'
import { getLocalTimezone } from 'lib/time'
import { formatInTimeZone } from 'date-fns-tz'

const SeparatorLine = styled(Box).attrs({
  flex: 1,
  height: 1,
  backgroundColor: 'primary',
})``

export function ShiftInfo(props: { data: MessageShiftInfo }) {
  const { data } = props

  return (
    <Flex flexDirection={'row'} alignItems={'center'} px={3} mt={3}>
      <SeparatorLine />
      <Box color={'primary'} px={4} fontWeight={1}>
        {isToday(new Date(data.startsAt as string))
          ? 'Today'
          : formatInTimeZone(
              data.startsAt as string,
              data.location.address?.timezone ?? getLocalTimezone(),
              'MMM d'
            )}
        &nbsp;&nbsp;•&nbsp;&nbsp;
        {data.position.name}, {data.location.name}
      </Box>
      <SeparatorLine />
    </Flex>
  )
}
