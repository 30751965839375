import { api } from 'api'
import { format } from 'date-fns'
import { path } from 'ramda'

interface ShiftCsvDataOptions {
  companyId: number
  startDate: Date
  endDate: Date
  positionId?: number
  locationId?: number
}

const dateFormat = 'yyyy-MM-dd'

export async function getShiftCsvData(options: ShiftCsvDataOptions) {
  const { companyId, startDate, endDate, positionId, locationId } = options

  const data = await api.get(`/company/${companyId}/shifts_report`, {
    params: {
      start_date: format(startDate, dateFormat),
      end_date: format(endDate, dateFormat),
      location_id: locationId,
      position_id: positionId,
    },
  })

  return path(['data'], data)
}
