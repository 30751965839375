import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { Box, Loading } from 'ui'
import { useHomeContext } from 'pages/HomePage/useHomeContext'
import { useShiftTimeline } from 'queries/shift'
import {
  TimelineContent,
  TimelineContentType,
  useTimelineContent,
  scrollHeightLimit,
} from 'pages/HomePage/useTimelineContent'
import { DateHeading } from './DateHeading'
import { ShiftCard } from 'pages/HomePage/ShiftCard'
import styled from 'styled-components'
import { useInViewport, useUpdateEffect } from 'ahooks'
import { useIsLoadedFromCache } from 'pages/HomePage/useIsLoadedFromCache'
import useResizeObserver from 'use-resize-observer'
import { Placeholder } from 'pages/HomePage/ShiftCard/Placeholder'
import { pick } from 'ramda'
import { UpperScrollBoundary } from 'pages/HomePage/UpperScrollBoundary'
import { EmptyState } from 'pages/HomePage/EmptyState'

const viewportRootMargin = '2000px'
const ContentWrapper = styled(Box).attrs({
  position: 'relative',
  overflow: 'auto',
})`
  ${({ theme: { colors } }) => `
    &:after {
      content: '';
      position: fixed;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
      background-color: ${colors.neutrals[50]};
    }
  `}
`

const PaginateButton = styled(Box).attrs({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  opacity: 0,
})``

export function AbsoluteScrollContainer() {
  const { renderDate, locationId, positionId } = useHomeContext()
  const {
    data,
    hasPreviousPage,
    hasNextPage,
    isLoading,
    isFetching,
    fetchPreviousPage,
    fetchNextPage,
    isFetchingPreviousPage,
    isFetchingNextPage,
  } = useShiftTimeline({
    startAt: renderDate,
    locationId,
    positionId,
    refetchInterval: 1000 * 60 * 2, // every 2 mins
  })
  const {
    timelineContent,
    startingY,
    increaseLowerBound,
    increaseUpperBound,
    increaseStartingY,
  } = useTimelineContent({
    data,
    hasNextPage,
    hasPreviousPage,
  })
  const containerRef = useRef<HTMLDivElement | null>(null)
  const { height: containerHeight } = useResizeObserver<HTMLDivElement>({
    ref: containerRef,
  })
  const prevButtonRef = useRef<HTMLDivElement | null>(null)
  const [prevButtonVisible] = useInViewport(prevButtonRef, {
    root: containerRef,
    rootMargin: `${viewportRootMargin} 0px ${viewportRootMargin} 0px`,
  })
  const nextButtonRef = useRef<HTMLDivElement | null>(null)
  const [nextButtonVisible] = useInViewport(nextButtonRef, {
    root: containerRef,
    rootMargin: `${viewportRootMargin} 0px ${viewportRootMargin} 0px`,
  })
  const [scrollFixed, setScrollFixed] = useState(false)
  const isLoadedFromCache = useIsLoadedFromCache(isFetching)
  const isEmpty = useMemo(() => {
    if (!data) {
      return true
    }
    return (
      (data.pages.length === 0 ||
        !data.pages[0] ||
        data.pages[0].items.length === 0) &&
      !hasPreviousPage
    )
  }, [data, hasPreviousPage])

  useEffect(() => {
    if (renderDate) {
      setScrollFixed(false)
    }
  }, [renderDate])

  useEffect(() => {
    if (!scrollFixed && containerRef.current && timelineContent.length > 0) {
      containerRef.current?.scrollTo({
        top: scrollHeightLimit,
        behavior: 'instant',
      })
      setScrollFixed(true)
    }
  }, [containerRef, startingY, timelineContent, scrollFixed])

  const handleLoadPrevious = () => {
    if (isLoadedFromCache.current) {
      // reschedule until no longer loaded from cache
      return setTimeout(() => {
        handleLoadPrevious()
      }, 50)
    }
    if (hasPreviousPage) {
      if (!isFetchingPreviousPage) {
        fetchPreviousPage()
      }
    } else {
      increaseLowerBound()
    }
  }

  const handleLoadNext = () => {
    if (isLoadedFromCache.current) {
      // reschedule until no longer loaded from cache
      return setTimeout(() => {
        handleLoadNext()
      }, 50)
    }
    if (hasNextPage) {
      if (!isFetchingNextPage) {
        fetchNextPage()
      }
    } else {
      increaseUpperBound()
    }
  }

  useEffect(() => {
    if (prevButtonVisible) {
      handleLoadPrevious()
    }
  }, [prevButtonVisible])

  useEffect(() => {
    if (nextButtonVisible) {
      handleLoadNext()
    }
  }, [nextButtonVisible])

  useUpdateEffect(() => {
    // reached the end of available data
    if (!hasNextPage) {
      increaseUpperBound()
    }
  }, [hasNextPage])

  useUpdateEffect(() => {
    if (!hasPreviousPage) {
      increaseLowerBound()
    }
  }, [hasPreviousPage])

  const getTimelineContent = (content: TimelineContent) => {
    switch (content.type) {
      case TimelineContentType.LoadPrevious:
        return (
          <PaginateButton
            ref={prevButtonRef}
            style={{
              height: content.height,
              transform: `translate3d(0, ${content.translateY}px, 0)`,
            }}
          >
            Load Previous
          </PaginateButton>
        )
      case TimelineContentType.DateHeader:
        return (
          <DateHeading
            {...content}
            containerRef={containerRef}
            containerHeight={containerHeight}
          />
        )
      case TimelineContentType.ShiftCard:
        return (
          <ShiftCard
            containerRef={containerRef}
            data={content.data}
            height={content.height}
            translateY={content.translateY}
          />
        )
      case TimelineContentType.LoadNext:
        return (
          <PaginateButton
            ref={nextButtonRef}
            style={{
              height: content.height,
              transform: `translate3d(0, ${content.translateY}px, 0)`,
            }}
          >
            Load Next
          </PaginateButton>
        )
      case TimelineContentType.ShiftCardPlaceholder:
        return <Placeholder {...pick(['height', 'translateY'], content)} />
      default:
        return null
    }
  }

  if (isLoading) {
    return <Loading />
  }

  if (isEmpty) {
    return <EmptyState />
  }

  return (
    <ContentWrapper
      ref={containerRef}
      flex={1}
      mb={-3}
      style={{
        opacity: scrollFixed ? 1 : 0,
      }}
    >
      <UpperScrollBoundary
        onButtonClick={() => {
          increaseStartingY()
          setScrollFixed(false)
        }}
      />
      {timelineContent.map((content) => (
        <Fragment key={content.id}>{getTimelineContent(content)}</Fragment>
      ))}
    </ContentWrapper>
  )
}
