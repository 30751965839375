import { api, graphql } from 'api'
import { path } from 'ramda'
import { CompanyRolePerm, User } from 'typings/common_defs'

interface UpdateUserAccountOptions {
  name: string
  email: string
  phoneNumber: string
}

export async function updateUserAccount({
  name,
  email,
  phoneNumber,
}: UpdateUserAccountOptions) {
  await api.put('/me', {
    name,
    email,
    phone_number: phoneNumber,
  })
}

export async function acceptInviteRequest(token: string) {
  await api.post('/company/accept', {
    token,
  })
}

export interface UserProfile
  extends Pick<User, 'id' | 'name' | 'email' | 'isInternal'> {
  companyRolePerm: Pick<CompanyRolePerm, 'description'>
  companyUserNotificationOptions: Array<{
    type: string
    typeDisplayName: string
    frequencyOptions: Array<string>
    frequencyOptionsDisplayNames: Array<string>
    channel: string
  }>
  companyUserNotificationPreferences: Array<{
    type: string
    frequency: string
    frequencyDisplayName: string
    typeDescriptionDisplayName: string
    channel: string
  }>
}

export async function getMyProfile() {
  const data = await graphql(
    `me {
          name,
          email,
          isInternal,
          companyRolePerm {
            description
          },
          companyUserNotificationOptions {
            type,
            typeDisplayName,
            frequencyOptions,
            frequencyOptionsDisplayNames,
            channel
          },
          companyUserNotificationPreferences {
            type,
            frequency,
            frequencyDisplayName,
            typeDescriptionDisplayName,
            channel
          }
        }`,
    'user_profile'
  )

  return path(['data', 'data', 'me'], data) as UserProfile
}

export async function updateEmailPreference(
  companyId: number,
  type: string,
  frequency: string
) {
  await api.post(`/company/${companyId}/notification_preferences`, {
    type,
    frequency,
    channel: 'email',
  })
}
