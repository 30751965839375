import styled from 'styled-components'
import { Box } from 'ui'

export const PageContent = styled(Box).attrs({
  className: 'page-content',
})``

PageContent.defaultProps = {
  px: [3, 4, 5],
}
