import React from 'react'
import { useAnalyticsContext } from '../useAnalyticsContext'
import { Box, Flex, Heading, Text } from 'ui'

export function WorkerBenchStats() {
  const { insight } = useAnalyticsContext()

  return (
    <Flex flexDirection={'row'} my={3}>
      <Box mr={4}>
        <Heading color={'primary'} my={0}>
          {insight?.workerBench?.totalFavorite}
        </Heading>
        <Text>Favorite Workers</Text>
      </Box>
      <Box>
        <Heading color={'primary'} my={0}>
          {insight?.workerBench?.totalBlocked}
        </Heading>
        <Text>Blocked Workers</Text>
      </Box>
    </Flex>
  )
}
