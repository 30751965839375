import React from 'react'
import { Box } from 'ui'
import { PositionList } from './PositionList'
import { LocationList } from './LocationList'

export function PositionLocation() {
  return (
    <Box>
      <PositionList />
      <LocationList />
    </Box>
  )
}
