import {
  ToggleGroup as ToggleGroupPrimitive,
  ToggleGroupItem as ToggleGroupItemPrimitive,
  ToggleGroupSingleProps,
  ToggleGroupMultipleProps,
  ToggleGroupItemProps,
} from '@radix-ui/react-toggle-group'
import React from 'react'
import styled, { keyframes } from 'styled-components'
import { newColors } from '../constants'

const skeletonLoader = keyframes`
  0% {
    opacity: 0.2
  }
  100% {
    opacity: 0.8
  }
`

// @ts-ignore
const StyledToggleGroup = styled(ToggleGroupPrimitive)`
  display: inline-flex;
  background-color: ${newColors.gray};
  border: 1px solid ${newColors.gray};
  border-radius: 50px;
  padding: 1px;
`

interface StyledToggleGroupItemProps {
  loading?: boolean
}
// @ts-ignore
const StyledToggleGroupItem = styled(
  ToggleGroupItemPrimitive
)<StyledToggleGroupItemProps>`
  border: none;
  border-color: none;
  background-color: ${newColors.gray};
  color: ${newColors.blackWithTransparency};
  height: 35px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  font-size: 13px;
  height: auto;
  line-height: 1;
  align-items: center;
  justify-content: center;
  margin-left: 1px;
  border-radius: 49px;
  animation: ${(props) =>
    props.loading ? `${skeletonLoader} 1s linear infinite alternate` : 'none'};
  &:hover {
    cursor: ${(props) => (props.loading ? 'not-allowed' : 'pointer')};
  }
  &[data-state='on'] {
    background-color: ${newColors.primary};
    color: white;
  }
  :focus {
    position: relative;
    outline: none !important;
  }
`

// @ts-ignore
export const ToggleGroup: React.ForwardRefExoticComponent<
  (ToggleGroupSingleProps | ToggleGroupMultipleProps) &
    React.RefAttributes<HTMLDivElement>
> = React.forwardRef(({ children, ...props }, forwardedRef: any) => (
  <StyledToggleGroup {...props} ref={forwardedRef}>
    {children}
  </StyledToggleGroup>
))

export const ToggleGroupItem: React.ForwardRefExoticComponent<
  { children?: React.ReactNode | string | null } & {
    loading?: boolean
  } & ToggleGroupItemProps &
    React.RefAttributes<HTMLButtonElement>
> = React.forwardRef(({ children, ...props }, forwardedRef: any) => (
  <StyledToggleGroupItem {...props} ref={forwardedRef}>
    {children}
  </StyledToggleGroupItem>
))
