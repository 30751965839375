export const unmaskPhoneNumber = (phoneNumber?: string): string | null => {
  if (!phoneNumber) {
    return null
  }
  let unmaskedPhone = phoneNumber.replace(/\D/g, '')
  while (unmaskedPhone.length > 10) {
    unmaskedPhone = unmaskedPhone.substring(1)
  }
  return unmaskedPhone
}

export const PHONE_NUM = {
  mask: '(999)-999-9999',
}

export const isValidUSPhoneNumber = (phoneNumber: string): boolean => {
  const unmaskedPhone = unmaskPhoneNumber(phoneNumber)
  if (!unmaskedPhone) {
    return false
  }
  return unmaskedPhone.length === 10
}

export function formatPhoneNumber(phoneNumber: string) {
  const unmaskedPhone = unmaskPhoneNumber(phoneNumber)
  if (!unmaskedPhone) {
    return ''
  }
  return unmaskedPhone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
}
