import * as zod from 'zod'
import { selectNumericOptionSchema, selectOptionSchema } from './generic'
import { getLocalTimezone, getUTCDateTime } from 'lib/time'
import { orientationShiftSchema } from './orientationShiftSchema'

export const scheduleSchema = zod
  .object({
    selectedDays: zod
      .array(zod.date(), { required_error: 'Please select at least one day' })
      .min(1, 'Please select at least one day'),
    startTime: zod.object(selectOptionSchema, {
      required_error: 'Please select a start time',
    }),
    timezone: zod.string().default(getLocalTimezone()),
    endTime: zod.object(selectOptionSchema, {
      required_error: 'Please select an end time',
    }),
    lunchLength: zod.object(selectNumericOptionSchema).optional(),
    isW2: zod.boolean().optional(),
    // Used purely for validation purposes injected via default values
    orientationShift: orientationShiftSchema.optional(),
  })
  .superRefine((data, context) => {
    if (data.startTime?.value === data.endTime?.value) {
      context.addIssue({
        code: zod.ZodIssueCode.custom,
        message: 'Shift must be longer than 0 minutes.',
        path: ['endTime'],
      })
      return false
    }

    // Orientation shift days are sorted in ascending order
    const lastDayOfOrientation = data.orientationShift?.selectedDays?.at(-1)

    if (
      data.orientationShift &&
      data.orientationShift.willHaveOrientation.value &&
      data.orientationShift.endTime &&
      lastDayOfOrientation
    ) {
      const orientationShiftEnd = getUTCDateTime(
        lastDayOfOrientation,
        data.orientationShift.endTime.value,
        data.timezone
      )

      const workShiftStart = getUTCDateTime(
        data.selectedDays[0],
        data.startTime.value,
        data.timezone
      )

      if (workShiftStart < orientationShiftEnd) {
        context.addIssue({
          code: zod.ZodIssueCode.custom,
          message:
            'Please select a start time that does not overlap with orientation',
          path: ['startTime'],
        })
        return false
      }
    }

    return true
  })
export type ScheduleForm = zod.infer<typeof scheduleSchema>
