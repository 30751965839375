import { useQuery, useQueryClient } from '@tanstack/react-query'
import { companyPositionsKey } from './keys'
import { getCompanyPositions } from 'api/company'

interface UseCompanyPositionsOptions {
  enabled?: boolean
  // allow certain view to always get latest data independent of cache/stale time, e.g. position list
  refetchOnMount: boolean | 'always'
}

export function useCompanyPositions(options?: UseCompanyPositionsOptions) {
  return useQuery({
    queryKey: companyPositionsKey,
    queryFn: getCompanyPositions,
    gcTime: Infinity, // only invalidate on adding/editing position
    staleTime: Infinity,
    ...options,
  })
}

export function useInvalidateCompanyPositions() {
  const queryClient = useQueryClient()

  return {
    invalidate: () =>
      queryClient.invalidateQueries({
        queryKey: companyPositionsKey,
      }),
  }
}
