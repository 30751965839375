import { LucideInfo } from 'lucide-react'
import React, { useState } from 'react'
import { Flex, Label, Text, Box } from 'ui'
import { TryoutExplainerModal } from './TryoutExplainerModal'

interface Props {
  size?: 'small' | 'medium' | 'large'
  hideInfoIcon?: boolean
}

export const TryoutLabel = ({ size = 'medium', hideInfoIcon }: Props) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Label
        style={{ cursor: 'pointer' }}
        variant="dark"
        size={size}
        onClick={() => {
          if (hideInfoIcon) return
          setOpen(true)
        }}
      >
        <Flex flexDirection="row" alignItems="center">
          <Text>Tryout</Text>
          {hideInfoIcon ? null : (
            <Flex ml={2}>
              <LucideInfo size={14} />
            </Flex>
          )}
        </Flex>
      </Label>
      <TryoutExplainerModal open={open} doneCallback={() => setOpen(false)} />
    </>
  )
}
