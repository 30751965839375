import React, { FormEvent, useState, useTransition } from 'react'
import { Box, Button, IconButton, InputWrapper } from 'ui'
import { SearchIcon, XIcon } from 'lucide-react'
import { useWorkerSearch } from './useWorkerSearch'

export function SearchBar() {
  const [keyword, setKeyword] = useState('')
  const { searchKeyword, setSearchKeyword, isSearching } = useWorkerSearch()
  const [, startTransition] = useTransition()

  const onSearchSubmit = (e: FormEvent) => {
    e.preventDefault()
    startTransition(() => {
      setSearchKeyword(keyword.trim())
    })
  }

  const clearKeyword = () => {
    setKeyword('')
    startTransition(() => {
      setSearchKeyword('')
    })
  }

  return (
    <form onSubmit={onSearchSubmit}>
      <InputWrapper block={true} disabled={isSearching}>
        <Box
          color={'lightText'}
          position={'absolute'}
          style={{ pointerEvents: 'none' }}
          top={'50%'}
          left={'1rem'}
          mt={'-0.8rem'}
        >
          <SearchIcon size={18} />
        </Box>
        <input
          style={{ paddingLeft: '3.5rem' }}
          value={keyword}
          disabled={isSearching}
          onChange={(e) => setKeyword(e.currentTarget.value)}
          autoFocus={true}
          placeholder={'Search for a worker'}
        />
        {keyword.trim() ? (
          <Box mr={3}>
            <IconButton
              size={'small'}
              onClick={clearKeyword}
              disabled={isSearching}
            >
              <XIcon size={16} />
            </IconButton>
          </Box>
        ) : null}
        <Button
          type={'submit'}
          kind={'small'}
          mr={2}
          width={100}
          disabled={isSearching || keyword.trim() === searchKeyword.trim()}
        >
          Search
        </Button>
      </InputWrapper>
    </form>
  )
}
