import { graphql } from '../graphql'
import { path } from 'ramda'
import { QuizPage, TutorialPage } from 'components/profile/Tutorials/types'

export interface CompanyTutorial {
  id: number
  name: string
  content: TutorialPage[]
  quiz: QuizPage[]
}

export async function getCompanyTutorials() {
  const data = await graphql(`
  company {
    tutorials {
      id, name, content, quiz
    }
  }`)

  return path(['data', 'data', 'company', 'tutorials'], data).map(
    (tutorial) => ({
      ...tutorial,
      content: tutorial.content ? JSON.parse(tutorial.content) : [],
      quiz: tutorial.quiz ? JSON.parse(tutorial.quiz) : [],
    })
  ) as CompanyTutorial[]
}
