import React, { useEffect, useState } from 'react'
import { useShiftEditor } from '../../useShiftEditor'
import { ShiftType, StepEnum } from '../../ShiftEditorProvider'
import { Box, Button, Card, Flex, Heading } from 'ui'
import { FiPlusCircle } from 'react-icons/fi'
import styled from 'styled-components'
import { RecentShiftsTable } from './RecentShiftsTable'
import { track } from 'lib/amplitude'
import { TryoutExplainerModal } from 'components/tryouts/TryoutExplainerModal'
import { LucideCalendar } from 'lucide-react'
import { FeatureGates } from 'lib/statsig/feature_gates'
import { useGate } from 'statsig-react'

const OptionCard = styled(Card).attrs({
  flexDirection: ['column', 'column', 'column', 'row'],
  alignItems: ['flex-start', 'flex-start', 'flex-start', 'center'],
  my: 3,
  borderWidth: 0,
})``

const ButtonContainer = styled(Box).attrs({
  width: [1, 1, 1, 220],
  pl: [0, 0, 0, 3],
  mt: [3, 3, 3, 0],
})``

export function StartPage() {
  const { isNew, goTo, goNext, setShiftType, resetShiftData } = useShiftEditor()
  const [showTryoutModal, setShowTryoutModal] = useState(false)
  const { value: shouldEnableLTA } = useGate(FeatureGates.EnableLTA)

  useEffect(() => {
    track('impression, ShiftEditor_Start_page')
  }, [])

  const handleCreateNewShift = () => {
    setShiftType(ShiftType.Regular)
    resetShiftData()
    goNext()
  }

  const handleCreateTryout = () => {
    setShiftType(ShiftType.Tryout)
    setShowTryoutModal(true)
  }

  const handleCreateLongTermAssignment = () => {
    setShiftType(ShiftType.LongTermAssignment)
    resetShiftData()
    goNext()
  }

  useEffect(() => {
    if (!isNew) {
      goTo(StepEnum.Confirm, true)
    }
  }, [isNew])

  return (
    <Box>
      <Heading level={[2, 1]} my={4}>
        Create or Duplicate a Shift
      </Heading>
      <OptionCard>
        <Flex alignItems={'center'} width={[1, 1, 1, 180]}>
          <FiPlusCircle fontSize={18} />
          <Heading level={3} ml={3} my={0}>
            New Shift
          </Heading>
        </Flex>
        <Box flex={1} mt={[2, 2, 2, 0]}>
          Create a new shift or add a new company position. Customize schedules,
          roster, payments, and more.
        </Box>
        <ButtonContainer>
          <Button block={true} onClick={handleCreateNewShift}>
            Create New Shift
          </Button>
        </ButtonContainer>
      </OptionCard>
      {shouldEnableLTA ? (
        <OptionCard>
          <Flex alignItems={'center'} width={[1, 1, 1, 180]}>
            <LucideCalendar fontSize={18} />
            <Heading level={3} ml={3} my={0}>
              Long term assignment
            </Heading>
          </Flex>
          <Box flex={1} mt={[2, 2, 2, 0]}>
            Schedule a Long Term Assignment if you need the same skilled worker
            consistently for longer durations (e.g. 3 weeks or more).
          </Box>
          <ButtonContainer>
            <Button block={true} onClick={handleCreateLongTermAssignment}>
              Create Assignment
            </Button>
          </ButtonContainer>
        </OptionCard>
      ) : null}
      <OptionCard>
        <Flex alignItems={'center'} width={[1, 1, 1, 180]}>
          <LucideCalendar fontSize={18} />
          <Heading level={3} ml={3} my={0}>
            Tryout
          </Heading>
        </Flex>
        <Box flex={1} mt={[2, 2, 2, 0]}>
          Schedule a Tryout for workers that have not worked in that position
          before. Then, evaluate and qualify workers for future shifts.
        </Box>
        <ButtonContainer>
          <Button block={true} onClick={handleCreateTryout}>
            Create Tryout
          </Button>
        </ButtonContainer>
      </OptionCard>
      <RecentShiftsTable />
      <TryoutExplainerModal
        open={showTryoutModal}
        doneCallback={() => goTo(StepEnum.Details)}
      />
    </Box>
  )
}
