import { track } from './amplitude'
import { api } from 'api'

/*
 * Over time, this file should decrease in size.
 * Biz Logic should be moved to category specific files in lib folder.
 * e.g. lib/shift or lib/work or lib/payment or lib/time etc.
 * For a guide of where we will end up, see the lib folder in Backend repo.
 */

const FileDownload = require('js-file-download')

export function uniq(array) {
  return [...new Set(array)]
}

// squashes promise-chain error
export function fileDownload(url, saveFilename) {
  track('button_click, csv download')
  return api({ url, baseUrl: '' })
    .then(({ data }) => FileDownload(data, saveFilename))
    .catch((e) => console.warn(`Error downloading file: ${e}`))
}

export function toTitleCase(text) {
  return typeof text === 'string'
    ? text
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')
    : ''
}

export const toFirstName = (text) =>
  typeof text === 'string'
    ? text
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')
        .split(' ')[0]
    : ''
export function openIntercom() {
  // tracking call is now in IntercomProvider's onShow handler
  window.Intercom('show')
}

/*
 * addressItems can be:
 * default: street+city+state+zip
 * "street-city-state": street+city+state
 * "street-city": street+city
 * "city-state": city+state
 */
export function getAddressString(address, addressItems) {
  const { street, unit, zip, city, state } = address
  if (addressItems === 'street-city-state-zip') {
    return `${street}${unit ? `, ${unit}` : ''}, ${city}, ${state} ${zip}`
  }
  if (addressItems === 'street-city-state') {
    return `${street}${unit ? `, ${unit}` : ''}, ${city}, ${state}`
  }
  if (addressItems === 'street-city')
    return `${street}${unit ? `, ${unit}` : ''}, ${city}`
  if (addressItems === 'city-state') return `${city}, ${state}`
  return `${street}${unit ? `, ${unit}` : ''}, ${city}, ${state} ${zip}`
}

export const stopPropagation = (fn) => (evt) => {
  console.log('event: ', evt)
  evt.preventDefault()
  evt.stopPropagation()
  fn()
}

export const FINISHED_WORK_STATUS = [
  'completed',
  'admin_review',
  'employer_review',
  'employer_approved',
  'approved',
  'paid',
]
