import { api, graphql } from 'api'
import { path } from 'ramda'
import { Roster, User } from 'typings/common_defs'

export type CompanyWorker = Pick<
  User,
  | 'id'
  | 'name'
  | 'profilePicUrl'
  | 'numShiftsForCompany'
  | 'rating'
  | 'shadowBlocked'
  | 'status'
  | 'statusForCompany'
  | 'workForCompany'
> & {
  rosters?: Array<Pick<Roster, 'id' | 'name'>>
}

export enum StatusForCompanyEnum {
  Blocked = 'blocked',
  Hired = 'hired',
  Favorite = 'favorite',
}

export enum StatusEnum {
  Active = 'active',
  Blocked = 'blocked',
  Suspended = 'suspended',
}

export interface PaginatedCompanyWorkers {
  cursor: string
  items: CompanyWorker[]
}

interface CompanyWorkersQuery {
  cursor?: string
  search?: string
  includeRosters?: boolean
}

export async function getCompanyWorkers(params: CompanyWorkersQuery) {
  const { cursor, search, includeRosters = false } = params
  const data = await graphql(
    `company {
      workers2 ${
        cursor || search
          ? `(
          ${cursor ? `cursor: "${cursor}"` : ''}
          ${search ? `, search: "${search}"` : ''}
      )`
          : ''
      }
      {
        cursor
        items {
          id
          name
          profilePicUrl,
          ${
            includeRosters
              ? `
            rosters {
              id, name
            }
          `
              : ''
          }
          rating
          numShiftsForCompany
          shadowBlocked
          status
          statusForCompany
          workForCompany(lastNFinishedWork:1) {
            startedAt
          }
        }
      }
    }`,
    'workers_query'
  )

  return path(
    ['data', 'data', 'company', 'workers2'],
    data
  ) as PaginatedCompanyWorkers
}

export interface InviteWorkerRequest {
  name: string
  email: string | null
  phoneNumber: string | null
}

export async function inviteWorker(
  companyId: number,
  data: InviteWorkerRequest
) {
  await api.post(`/company/${companyId}/workerinvite`, {
    name: data.name,
    email: data.email,
    phone_number: data.phoneNumber,
  })
}

export interface WorkerInvite {
  id: number
  name: string
  email: string | null
  createdAt: string
}

export async function getPendingWorkerInvites() {
  const data = await graphql(`
    company {
      pendingWorkerInvites {
        id
        name
        email
        createdAt
      }
    }
  `)

  return path(
    ['data', 'data', 'company', 'pendingWorkerInvites'],
    data
  ) as WorkerInvite[]
}
