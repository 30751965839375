import React, { useState } from 'react'
import { Box, Flex, Message, Text } from 'ui'
import { CompanyLog } from 'api/company'
import { useErrorBoundary } from 'use-error-boundary'
import { LogItemSummary } from './LogItemSummary'
import { LogItemIcon } from './LogItemIcon'
import { LogItemDescription } from './LogItemDescription'
import { ChevronDownIcon, ChevronUpIcon } from 'lucide-react'
import TimeAgo from 'react-timeago'
import { format, isToday } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { getLocalTimezone } from 'lib/time'

interface Props {
  data: CompanyLog
}

export function LogItem(props: Props) {
  const { data } = props
  const { didCatch, ErrorBoundary } = useErrorBoundary()
  const [expanded, setExpanded] = useState(false)
  const timezone = getLocalTimezone()

  if (didCatch) {
    return <Message variant={'warning'}>Unable to render log item.</Message>
  }

  return (
    <ErrorBoundary>
      <Box py={3} borderBottom={'1px solid'} borderBottomColor={'neutrals.100'}>
        <Flex
          flexWrap={'wrap'}
          style={{ cursor: 'pointer' }}
          alignItems={'center'}
          onClick={() => setExpanded(!expanded)}
        >
          <LogItemIcon data={data} />
          <Box pl={3} flex={1}>
            <LogItemSummary data={data} />
          </Box>
          <Text color={'lightText'} fontSize={1} px={3}>
            {isToday(new Date(data.timestamp)) ? (
              <TimeAgo
                date={data.timestamp}
                title={formatInTimeZone(
                  data.timestamp,
                  timezone,
                  'MMM d, yyyy hh:mmaaa zzz'
                )}
              />
            ) : (
              <Flex width={[80, 'auto']} flexWrap={'wrap'}>
                <Box width={[1, 'auto']}>
                  {format(new Date(data.timestamp), 'MMM d, yyyy')}
                </Box>
                <Box display={['none', 'block']} px={1}>
                  -
                </Box>
                <Box width={[1, 'auto']}>
                  {formatInTimeZone(
                    new Date(data.timestamp),
                    timezone,
                    'hh:mmaaa zzz'
                  )}
                </Box>
              </Flex>
            )}
          </Text>
          <Text color={'primary'}>
            {expanded ? (
              <ChevronUpIcon size={24} />
            ) : (
              <ChevronDownIcon size={24} />
            )}
          </Text>
        </Flex>
        {expanded ? (
          <Box style={{ cursor: 'initial' }} pt={3} pl={40}>
            <LogItemDescription data={data} />
          </Box>
        ) : null}
      </Box>
    </ErrorBoundary>
  )
}
