import React from 'react'
import { useWorkerProfile } from './useWorkerProfile'
import { WorkerRostersSummary } from '../WorkersPage/WorkerRostersSummary'
import { ActionButton } from './ActionButton'

export function AddWorkerToList() {
  const { profileData } = useWorkerProfile()
  return (
    <WorkerRostersSummary
      ctaLabel={<ActionButton>Add To List</ActionButton>}
      data={profileData.worker}
    />
  )
}
