import React, { PropsWithChildren } from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { ErrorBoundaryComponent } from './ErrorBoundaryComponent'
import Bugsnag from '@bugsnag/js'
export * from './TopLevelErrorBoundary'

export function ErrorBoundary(props: PropsWithChildren) {
  const onError = (error: Error) => {
    Bugsnag.notify(error)
  }

  return (
    <ReactErrorBoundary fallback={<ErrorBoundaryComponent />} onError={onError}>
      {props.children}
    </ReactErrorBoundary>
  )
}
