import React, { useState } from 'react'
import { Button, Flex, Box, toast, Text, Tooltip } from 'ui'
import { workApprovalSchema } from '../schemas/workApprovalSchema'
import { shouldShowApproveButton } from 'lib/work'
import { useShiftDetail } from '../useShiftDetail'
import { Work } from 'typings/common_defs'
import { useAssignedWorkRow } from './useAssignedWorkerRow'
import { AssignedWorkerOptionsSelector } from './AssignedWorkerOptionsSelector'
import { LegacyAssignedWorkerOptionsSelector } from './LegacyAssignedWorkerOptionsSelector'
import { AssignedWorkerTimeRangeInput } from './AssignedWorkerTimeRangeInput'
import { useSaveWorkMutation } from 'queries/work/useSaveWorkMutation'
import { toServerData } from '../dataTransformers'
import { Selectable } from 'typings/selectable_ext'
import { AssignedWorkerFinishedEarlyExplanation } from './AssignedWorkerFinishedEarlyExplanation'
import { AssignedWorkerRatingSupplement } from './AssignedWorkerRatingSupplement'
import { AssignedWorkerRating } from './AssignedWorkerRating'
import { AssignedWorkerDurationAndPay } from './AssignedWorkerDurationAndPay'
import TryoutEvaluationToggleGroup, {
  TryoutEvaluationData,
} from 'components/tryouts/TryoutEvaluationToggleGroup'
import { AssignedWorkerEditModeOverflowActions } from './AssignedWorkerEditModeOverflowActions'
import { SpinningRefreshIcon } from './SpinningRefreshIcon'
import { useAppTheme } from '@workwhile/ui'
import { useGate } from 'statsig-react'
import { FeatureGates } from 'lib/statsig/feature_gates'

interface Props {
  work: Work
  switchToViewMode: () => void
}

export const ShiftDetailAssignedWorkerRowEditMode = ({
  work,
  switchToViewMode,
}: Props) => {
  const { value: isShiftDetailMinPayPolicySupportEnabled } = useGate(
    FeatureGates.BizPortalMinPayPolicySupport
  )
  const { shift, isFetching } = useShiftDetail()
  const { colors } = useAppTheme()
  const [error, setError] = useState<string | null>(null)

  const { data, setValue, errors: workError } = useAssignedWorkRow(work.id)

  const { mutate: saveWork, isPending: isSubmittingWork } = useSaveWorkMutation(
    {
      editedWork: data,
      shiftId: shift?.id || -1,
      onSuccess: () => {
        toast.success('Work updated succesfully')
        if (shouldShowApproveButton(data)) {
          // if we just approved the work, eagerly update the status so that the UI shows the "approved" view before we get new data from the backend
          setValue('status', 'employer_approved')
        }
        switchToViewMode()
      },
      onError: (err) => setError(err.message),
    }
  )

  if (!shift) return null
  if (!work?.worker?.id) return null
  if (!data) return null

  const workErrorMessage = workError?.message as any // TODO(Alex): resolve this type issue

  const handleSubmit = () => {
    setError(null)
    const result = workApprovalSchema.safeParse(data)
    if (!result.success) {
      setError(result.error.toString())
      return
    }
    const submissionData = toServerData(result.data)
    saveWork(submissionData)
  }

  const handleTryoutEvaluationSelect = (data: TryoutEvaluationData) => {
    setValue('tryoutNewStatus', data.status)
  }

  const AssignedWorkerOptions = isShiftDetailMinPayPolicySupportEnabled
    ? AssignedWorkerOptionsSelector
    : LegacyAssignedWorkerOptionsSelector

  return (
    <Flex flexDirection="column" flex="1">
      <Flex
        flexDirection="row"
        alignItems={['flex-start', 'flex-start', 'flex-start', 'center']}
        flex="1"
      >
        <Flex
          justifySelf="flex-start"
          flexDirection={['column', 'column', 'column', 'row']}
          alignItems={['flex-start', 'flex-start', 'flex-start', 'center']}
        >
          <Box mr={2} minWidth={160} lineHeight="5rem">
            {/* Line height needed for alignment: https://stackoverflow.com/questions/32118013/align-icon-vertically-to-the-center-of-the-first-line-of-text */}
            <AssignedWorkerTimeRangeInput work={work} />
          </Box>
          <Box mr={2} width={160}>
            <AssignedWorkerOptions
              work={work}
              value={data.finishedEarlyCategory}
              onChange={(newValue) => {
                const data = newValue as Selectable<unknown>
                setValue('finishedEarlyCategory', data.value)
              }}
            />{' '}
          </Box>
          <AssignedWorkerDurationAndPay work={work} />
          {shift.isTryout ? (
            <TryoutEvaluationToggleGroup
              shift={shift}
              worker={work.worker}
              handleToggleSelect={handleTryoutEvaluationSelect}
            />
          ) : (
            <AssignedWorkerRating work={work} />
          )}
          <AssignedWorkerEditModeOverflowActions work={work} />
          <Flex
            ml="auto"
            pl={2}
            alignItems={[
              'flex-start',
              'flex-start',
              'flex-start',
              'flex-start',
            ]}
          >
            <Button
              kind="small"
              variant="primary"
              onClick={handleSubmit}
              loading={isSubmittingWork}
              disabled={isSubmittingWork || isFetching}
            >
              {shouldShowApproveButton(data) ? 'Approve' : 'Update'}
            </Button>
            {isFetching ? (
              <Box mr={3}>
                <Tooltip content={'Updating work data...'}>
                  <SpinningRefreshIcon size={16} color={colors.info} />
                </Tooltip>
              </Box>
            ) : null}
          </Flex>
        </Flex>
      </Flex>
      <AssignedWorkerFinishedEarlyExplanation work={work} />
      <AssignedWorkerRatingSupplement work={work} />
      {error ? (
        <Flex
          mt={3}
          flexDirection="row"
          alignItems="flex-start"
          justifyContent={['flex-start', 'flex-start', 'flex-end']}
        >
          <Text color="errors.200" fontWeight="bold" mr={1}>
            Attention:{' '}
          </Text>
          <Text color="errors.200">{error}</Text>
        </Flex>
      ) : null}
      {workErrorMessage ? (
        <Flex
          mt={3}
          flexDirection="row"
          alignItems="flex-start"
          justifyContent={['flex-start', 'flex-start', 'flex-end']}
        >
          <Text color="errors.200" fontWeight="bold" mr={1}>
            Attention:{' '}
          </Text>
          <Text color="errors.200">{workErrorMessage}</Text>
        </Flex>
      ) : null}
    </Flex>
  )
}
