import { useAuthContext } from 'components/auth'
import { useMutation } from '@tanstack/react-query'
import { TutorialCategory } from 'api/shift/typings'
import {
  createPositionTutorial,
  CreatePositionTutorialRequest,
  editPositionTutorial,
} from 'api/company'

type MutationPayload = Pick<
  CreatePositionTutorialRequest,
  'content' | 'quiz' | 'notifyWorkers'
>

export function useSavePositionTutorialMutation(options: {
  positionId: number
  tutorialId?: number
  category?: TutorialCategory
  onSuccess?: (tutorialId?: number) => void
  onError?: (error: Error) => void
}) {
  const { positionId, tutorialId, category, onSuccess, onError } = options
  const companyId = useAuthContext().company?.id as number
  return useMutation({
    mutationFn: async (request: MutationPayload) => {
      if (tutorialId) {
        return editPositionTutorial({
          ...request,
          companyId,
          positionId,
          tutorialId,
        })
      }
      if (category) {
        return createPositionTutorial({
          ...request,
          companyId,
          positionId,
          category,
        })
      }
    },
    onSuccess,
    onError,
  })
}
