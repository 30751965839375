import { useMemo } from 'react'
import { useCompanyLocations } from 'queries/company'

/**
 * Custom hook to get a company location by id.
 * @param locationId
 */
export function useCompanyLocation(locationId?: number) {
  const { data } = useCompanyLocations()

  const location = useMemo(() => {
    if (!data || !locationId) return null
    return data.find(
      (location) => location.id?.toString() === locationId.toString()
    )
  }, [data, locationId])

  return location
}
