import React, { ReactNode, useMemo, useRef, useState } from 'react'
import { Modal } from '@workwhile/ui'
import { PostBackupShiftForm } from './PostBackupShiftForm'
import { BackupShiftForm } from './schema'
import { TimelineShift } from 'api/shift'
import { useBackupShiftMutation } from 'queries/shift/useBackupShiftMutation'
import { QUARTER_HOUR_INTERVAL_OPTIONS, getLocationTimezone } from 'lib/time'
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz'
import { SelectOption } from 'pages/ShiftEditorPage/schemas/generic'
import { toast } from '@workwhile/ui'
import { useInvalidateShiftTimeline } from 'queries/shift'
import { addHours } from 'date-fns'
import { useInvalidateShiftDetail } from 'queries/company/useCompanyShiftDetail'

interface Props {
  shift: TimelineShift
  trigger: ReactNode
}

export function PostBackupShift(props: Props) {
  const { shift, trigger } = props
  const backupShiftDefaultData = useMemo(() => {
    if (!shift) return

    const timezone = getLocationTimezone(shift.location)
    const shiftStartsAt = new Date(shift.startsAt)
    const shiftendsAt = new Date(shift.endsAt)
    const backupShiftStartsAt = addHours(shiftStartsAt, 2)
    const backupShiftEndsAt = addHours(shiftendsAt, 2)

    const startDate = backupShiftStartsAt
      ? utcToZonedTime(backupShiftStartsAt, timezone)
      : null
    const startsAt = backupShiftStartsAt
      ? formatInTimeZone(backupShiftStartsAt, timezone, 'HH:mm')
      : null
    const endsAt = backupShiftEndsAt
      ? formatInTimeZone(backupShiftEndsAt, timezone, 'HH:mm')
      : null
    const startTimeOption = startsAt
      ? QUARTER_HOUR_INTERVAL_OPTIONS.find(
          (option) => option.value === startsAt
        )
      : null
    const endTimeOption = endsAt
      ? QUARTER_HOUR_INTERVAL_OPTIONS.find((option) => option.value === endsAt)
      : null

    return {
      selectedDays: startDate ? [startDate] : [],
      startTime: startTimeOption as SelectOption,
      endTime: endTimeOption as SelectOption,
      timezone,
      lunchLength: {
        label: shift.lunchLength ? `${shift.lunchLength} mins` : '0 mins',
        value: shift.lunchLength ? shift.lunchLength : 0,
      },
      bonus:
        shift.shiftBonuses && shift.shiftBonuses.length > 0
          ? shift.shiftBonuses[
              shift.shiftBonuses.length - 1
            ]?.amount?.toString()
          : null,
    } as unknown as BackupShiftForm
  }, [shift])
  const closeModalRef = useRef<() => void>()
  const { invalidate: invalidateTimeline } = useInvalidateShiftTimeline()
  const { invalidate: invalidateShiftDetail } = useInvalidateShiftDetail([
    shift?.id,
  ])

  const { mutate: postBackupShift, isPending } = useBackupShiftMutation({
    shiftId: shift.id,
    onSuccess: async () => {
      invalidateShiftDetail()
      await invalidateTimeline()
      if (closeModalRef.current) {
        closeModalRef.current()
      }
      toast.success('Backup shift created successfully')
    },
    onError: (err) => {
      toast.error(err.message)
    },
  })

  return (
    <Modal
      trigger={trigger}
      loading={isPending}
      title="Post Your Backup Shift"
      width={[1, 720]}
    >
      <Modal.Consumer>
        {({ closeModal }) => (
          <PostBackupShiftForm
            shift={shift}
            data={backupShiftDefaultData}
            onCancel={closeModal}
            onSubmit={(data) => {
              closeModalRef.current = closeModal
              postBackupShift(data)
            }}
          />
        )}
      </Modal.Consumer>
    </Modal>
  )
}
