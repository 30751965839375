import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'
import { PermissionEnum } from 'api/userPermissions'
import {
  DollarSignIcon,
  HomeIcon,
  MessageSquareIcon,
  PieChartIcon,
  SettingsIcon,
  ThumbsUpIcon,
  UsersIcon,
} from 'lucide-react'
import { useResponsiveValue } from '@workwhile/ui'

interface NavOption {
  to: string
  icon: ReactNode
  text: string
  badgeCount?: number
  mobileNotification?: boolean
  permissionRequired?: PermissionEnum
}

export interface LayoutContextValueProps {
  navOptions: NavOption[]
  selectedNavOption: NavOption | null
  menuWidth: string
  mobileMenuOpen: boolean
  toggleMobileMenu: () => void
  notificationOpen: boolean
  toggleNotificationCenter: () => void
  isNavCollapsed: boolean
}

export const LayoutContext = createContext<LayoutContextValueProps | null>(null)

const navOptions = [
  {
    to: '/home',
    text: 'Home',
    icon: <HomeIcon size={18} />,
    mobileNotification: false,
  },
  {
    to: '/review',
    text: 'Review',
    icon: <ThumbsUpIcon size={18} />,
  },
  {
    to: '/workers',
    text: 'Workers',
    icon: <UsersIcon size={18} />,
  },
  {
    to: '/payments',
    text: 'Payments',
    icon: <DollarSignIcon size={18} />,
    permissionRequired: PermissionEnum.PaymentAccess,
  },
  {
    to: '/analytics',
    text: 'Analytics',
    icon: <PieChartIcon size={18} />,
    permissionRequired: PermissionEnum.PaymentAccess,
  },
  {
    to: '/messages',
    text: 'Messages',
    icon: <MessageSquareIcon size={18} />,
    permissionRequired: PermissionEnum.MessageWorker,
  },
  {
    to: '/settings',
    text: 'Settings',
    icon: <SettingsIcon size={18} />,
  },
]

export const LayoutProvider = (props: PropsWithChildren) => {
  const { children } = props
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const location = useLocation()
  const background = location.state && location.state.background
  const selectedNavOption = useMemo(() => {
    return (
      navOptions.find((navOption) => {
        return !!location.pathname.match(navOption.to)
      }) ?? null
    )
  }, [location])
  const [notificationOpen, setNotificationOpen] = useState(false)
  const isNavCollapsed = useResponsiveValue([false, false, true])

  const lockBodyScroll = () => {
    document.body.style.overflow = 'hidden'
  }

  const unlockBodyScroll = () => {
    document.body.style.overflow = 'auto'
  }

  useEffect(() => {
    if (mobileMenuOpen || notificationOpen) {
      lockBodyScroll()
    } else if (!background) {
      unlockBodyScroll()
    }
  }, [mobileMenuOpen, notificationOpen, background])

  useEffect(() => {
    setMobileMenuOpen(false)
    setNotificationOpen(false)
    if (!background) {
      unlockBodyScroll()
    }
  }, [location, background])

  const values = useMemo(
    () => ({
      navOptions: navOptions,
      selectedNavOption,
      mobileMenuOpen,
      toggleMobileMenu: () => setMobileMenuOpen(!mobileMenuOpen),
      menuWidth: '28rem',
      notificationOpen,
      toggleNotificationCenter: () => setNotificationOpen(!notificationOpen),
      isNavCollapsed,
    }),
    [selectedNavOption, mobileMenuOpen, notificationOpen, isNavCollapsed]
  )

  return (
    <LayoutContext.Provider value={values}>{children}</LayoutContext.Provider>
  )
}
