import { useReviewOvertimeApprovalShifts } from 'queries/review'
import React from 'react'
import { Box, Heading, Flex, Label } from 'ui'
import { NeedsAttentionReviewShift } from './NeedsAttentionReviewShift'
import Skeleton from 'react-loading-skeleton'

/**
 * Grab all shifts with work that needs attention.
 * For each shift, render a condensed version of the ShiftDetailWorkers section
 */

export const NeedsAttention = () => {
  const {
    data: overtimeApprovalShifts,
    isLoading: isLoadingOvertimeApprovalShifts,
  } = useReviewOvertimeApprovalShifts()

  if (
    !isLoadingOvertimeApprovalShifts &&
    overtimeApprovalShifts?.length === 0
  ) {
    return null
  }

  return (
    <Box mb={4}>
      <Flex flexDirection="row" alignItems="center" mb={3}>
        <Heading level={2} mr={3}>
          Needs Attention
        </Heading>
        <Label variant="error" size="medium">
          Urgent
        </Label>
      </Flex>
      {isLoadingOvertimeApprovalShifts ? (
        <Flex flexDirection="column">
          <Box mb={2}>
            <Skeleton height={20} width={200} />
          </Box>
          <Flex flexDirection="row" alignItems="center">
            <Box mr={2}>
              <Skeleton
                height={40}
                width={40}
                circle
                style={{ marginRight: 10 }}
              />
            </Box>
            <Box mr={4}>
              <Skeleton height={20} width={100} />
            </Box>
            <Box mr={2}>
              <Skeleton height={20} width={150} />
            </Box>
            <Box mr={2}>
              <Skeleton height={20} width={150} />
            </Box>
            <Box mr={2}>
              <Skeleton height={20} width={90} />
            </Box>
          </Flex>
        </Flex>
      ) : (
        overtimeApprovalShifts?.map((shift) => (
          <NeedsAttentionReviewShift shift={shift} key={shift.id} />
        ))
      )}
    </Box>
  )
}
