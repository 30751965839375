import { useMutation } from '@tanstack/react-query'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'

export function useResetPasswordMutation(options?: {
  onSuccess?: () => void
  onError?: (err: Error) => void
}) {
  return useMutation({
    mutationFn: (data: { email: string }) => {
      const auth = getAuth()
      return sendPasswordResetEmail(auth, data.email)
    },
    ...options,
  })
}
