import { Page } from 'pages/layout'
import React from 'react'
import { ShiftDetailHeader } from './ShiftDetailHeader'
import { ShiftDetailProvider } from './ShiftDetailProvider'
import { ShiftDetailContent } from './ShiftDetailContent'
import { useParams } from 'react-router-dom'

export function ShiftDetail() {
  const { shiftId: shiftIdParam } = useParams<{ shiftId: string }>()
  const shiftId = shiftIdParam ? Number(shiftIdParam) : null

  return (
    <ShiftDetailProvider shiftId={shiftId}>
      <Page>
        <ShiftDetailHeader />
        <ShiftDetailContent />
      </Page>
    </ShiftDetailProvider>
  )
}
