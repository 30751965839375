import { Statsig, DynamicConfig } from 'statsig-react'
import { config } from 'config'

export const DYNAMIC_CONFIGS = {
  tryouts_company_id_whitelist: 'tryouts_company_id_whitelist',
  shift_lead_creation_hospitality: 'shift_lead_creation_hospitality',
  biz_portal_tutorial_whitelist: 'biz_portal_tutorial_whitelist',
}

/**
 * Helper function to get a dynamic config from Statsig
 * @param {string} dynamicConfigId
 * @returns {DynamicConfig | null}
 */
export const getStatsigDynamicConfig = (
  dynamicConfigId: string
): DynamicConfig | null => {
  let config: DynamicConfig | null
  try {
    config = Statsig.getConfig(dynamicConfigId)
  } catch {
    config = null
  }
  return config
}

export const getStatsigGate = (gateId: string): boolean => {
  try {
    return Statsig.checkGate(gateId)
  } catch {
    return false
  }
}

export const getStatsigEnv = () => config.env
