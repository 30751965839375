import Role from './Role'
import Permission from './Permission'
import Unauthenticated from './Unauthenticated'
import Authenticated from './Authenticated'

export const AuthGuard = {
  Role,
  Permission,
  Unauthenticated,
  Authenticated,
}
