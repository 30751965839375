import React from 'react'
import { IconButton, Tooltip, Flex } from 'ui'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { RxDotFilled } from 'react-icons/rx'
import { useHomeContext } from './useHomeContext'
import { addWeeks, startOfWeek } from 'date-fns'

export function DateJumper() {
  const { currentDate, setCurrentDate } = useHomeContext()

  const onPrevWeekClick = () => {
    setCurrentDate(startOfWeek(addWeeks(currentDate, -1)))
  }

  const onNextWeekClick = () => {
    setCurrentDate(startOfWeek(addWeeks(currentDate, 1)))
  }

  const onTodayClick = () => {
    setCurrentDate(new Date())
  }

  return (
    <Flex width={['auto', 100]} px={[0, 1]}>
      <IconButton
        aria-label={'Previous Week'}
        size={'small'}
        backgroundColor={'neutrals.100'}
        onClick={onPrevWeekClick}
      >
        <FiChevronLeft fontSize={18} />
      </IconButton>
      <Tooltip content={'Jump to Today'}>
        <IconButton
          aria-label={'Jump to Today'}
          mx={2}
          size={'small'}
          backgroundColor={'transparent'}
          onClick={onTodayClick}
        >
          <RxDotFilled fontSize={18} />
        </IconButton>
      </Tooltip>
      <IconButton
        aria-label={'Next Week'}
        size={'small'}
        backgroundColor={'neutrals.100'}
        onClick={onNextWeekClick}
      >
        <FiChevronRight fontSize={18} />
      </IconButton>
    </Flex>
  )
}
