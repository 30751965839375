import { AxiosResponse } from 'axios'
import { api } from './api'
import { config } from 'config'

export function graphql<T = any, R = AxiosResponse<T>>(
  q: string,
  ctx?: any,
  options = {}
) {
  let headers = {}
  if (ctx) {
    headers = { 'APP-GQL-CONTEXT': ctx }
  }

  return api.post<T, R>(
    config.graphqlHostUrl,
    {
      query: `query { ${q} }`,
    },
    { headers, ...options }
  )
}
