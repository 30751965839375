import React from 'react'
import { Box, DisplayIcon, Flex, Heading, Text } from 'ui'
import styled from 'styled-components'
import { usePaymentSummary } from './usePaymentSummary'
import { CalculatorIcon } from 'lucide-react'
import { formatCurrency } from 'lib/string'

const LineItem = styled(Flex).attrs({
  px: 3,
  fontSize: 1,
})``

export function ChargeSummary() {
  const paymentData = usePaymentSummary()

  return (
    <Box
      p={3}
      backgroundColor={'neutrals.50'}
      borderRadius={'large'}
      color={'neutrals.400'}
    >
      <Box display={'inline-flex'} alignItems={'center'}>
        <DisplayIcon>
          <CalculatorIcon size={16} />
        </DisplayIcon>
        <Heading level={4} my={0} ml={2}>
          Charge Per Shift
        </Heading>
      </Box>
      {!paymentData.hasData ? (
        <Box py={4} px={4} textAlign={'center'} color={'neutrals.300'}>
          Enter Payment information and we'll calculate your cost per shift
        </Box>
      ) : (
        <Box mt={3}>
          <Text fontWeight={2} mb={2}>
            Base Pay
          </Text>
          <LineItem>
            <Box flex={1}>Workers</Box>
            {paymentData.numWorkers}
          </LineItem>
          {paymentData.hoursPerShift ? (
            <LineItem>
              <Box flex={1}>Hours Per Shift</Box>
              {paymentData.hoursPerShift}
            </LineItem>
          ) : null}
          <LineItem>
            <Box flex={1}>
              {paymentData.isHourly ? 'Pay Per Hour' : 'Pay Per Worker'}
            </Box>
            ${paymentData.pay.toFixed(2)}
          </LineItem>
          <LineItem>
            <Box flex={1}>WorkWhile Fee ({paymentData.baseWWFeePercent}%)</Box>$
            {paymentData.baseWWFee.toFixed(2)}
          </LineItem>
          {paymentData.numShiftLeads > 0 ? (
            <Box mt={3}>
              <LineItem>
                <Box flex={1}>Shift Leads</Box>
                {paymentData.numShiftLeads}
              </LineItem>
              {paymentData.shiftLeadHoursPerShift ? (
                <LineItem>
                  <Box flex={1}>Hours Per Shift</Box>
                  {paymentData.shiftLeadHoursPerShift}
                </LineItem>
              ) : null}
              <LineItem>
                <Box flex={1}>
                  {paymentData.isHourly ? 'Pay Per Hour' : 'Pay Per Shift Lead'}
                </Box>
                ${paymentData.shiftLeadPay.toFixed(2)}
              </LineItem>
              <LineItem>
                <Box flex={1}>
                  WorkWhile Fee ({paymentData.baseWWFeePercent}%)
                </Box>
                ${paymentData.shiftLeadBaseWWFee.toFixed(2)}
              </LineItem>
            </Box>
          ) : null}
          <hr />
          <LineItem>
            <Box flex={1} />
            <Text fontWeight={2}>${paymentData.totalBase.toFixed(2)}</Text>
          </LineItem>
          {paymentData.bonusPerWorker ? (
            <>
              <Text fontWeight={2} mb={2} mt={3}>
                Bonus
              </Text>
              <LineItem>
                <Box flex={1}>
                  Bonus per{' '}
                  {paymentData.numShiftLeads > 0
                    ? 'Worker/Shift Lead'
                    : 'Worker'}
                </Box>
                {formatCurrency(paymentData.bonusPerWorker)}&nbsp;x&nbsp;
                {paymentData.numWorkers + paymentData.numShiftLeads}
              </LineItem>
              <LineItem>
                <Box flex={1}>
                  WorkWhile Fee ({paymentData.bonusWWFeePercent}%)
                </Box>
                ${paymentData.bonusWWFee.toFixed(2)}
              </LineItem>
              <hr />
              <LineItem>
                <Box flex={1} />
                <Text fontWeight={2}>${paymentData.totalBonus.toFixed(2)}</Text>
              </LineItem>
            </>
          ) : null}
          <Text fontWeight={2} mb={2} mt={3}>
            Total
          </Text>
          <LineItem>
            <Box flex={1}>Total Charge Per Shift</Box>
            <Text fontWeight={1} fontSize={3} color={'info'}>
              ${paymentData.total.toFixed(2)}
            </Text>
          </LineItem>
        </Box>
      )}
    </Box>
  )
}
