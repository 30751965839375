import React from 'react'
import { Button, toast } from '@workwhile/ui'
import { AddLocation } from 'components/AddLocation'

interface Props {
  locationId: number
}

export function EditLocationButton(props: Props) {
  const { locationId } = props
  return (
    <AddLocation
      locationId={locationId}
      trigger={
        <Button kind={'small'} width={100}>
          Edit
        </Button>
      }
      onLocationSaved={() => {
        toast.success('Successfully updated location')
      }}
    />
  )
}
