import React, { useState } from 'react'
import { Box, Button, Flex, Select, Text } from 'ui'
import { activateDemoMode, deactivateDemoMode, isDemoMode } from 'api/demo'
import { DemoCategory } from 'api/demo/types'

const categoryOptions = [
  {
    label: 'General',
    value: 'general',
  },
  {
    label: 'Hospitality',
    value: 'hospitality',
  },
  {
    label: 'Driving',
    value: 'driving',
  },
  {
    label: 'Warehouse',
    value: 'warehouse',
  },
]

export function DemoSettings() {
  const [category, setCategory] = useState<string>('general')
  const [isDemo, setIsDemo] = useState(isDemoMode())
  const [isLoading, setLoading] = useState(false)

  return (
    <Flex flexDirection={'row'} width={[1, 1, 500]}>
      <Box flex={1}>
        {isDemo ? (
          <Text>Currently in demo mode</Text>
        ) : (
          <Select
            options={categoryOptions}
            defaultValue={categoryOptions.find((o) => o.value === category)}
            onChange={(option) =>
              setCategory((option as { value: string }).value)
            }
          />
        )}
      </Box>
      {isDemo ? (
        <Button
          ml={2}
          onClick={() => {
            deactivateDemoMode()
            setIsDemo(false)
          }}
        >
          Deactivate Demo Mode
        </Button>
      ) : (
        <Button
          ml={2}
          loading={isLoading}
          onClick={async () => {
            setLoading(true)
            await activateDemoMode(category as DemoCategory)
            setIsDemo(true)
            window.location.href = '/'
          }}
        >
          {isLoading ? 'Generating Demo Data...' : 'Activate Demo Mode'}
        </Button>
      )}
    </Flex>
  )
}
