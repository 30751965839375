import React, { ReactNode, useMemo, useRef, useState } from 'react'
import { Field, Input, Message, Modal, toast } from 'ui'
import { TimelineShift } from 'api/shift'
import * as zod from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAddShiftBonusMutation, useUpdateTimelineShift } from 'queries/shift'

interface Props {
  shift: TimelineShift
  trigger: ReactNode
}

const schema = zod.object({
  bonus: zod.coerce.number().positive('Please enter a valid bonus amount'),
})

export function BonusModal(props: Props) {
  const { shift, trigger } = props
  const currentBonus = useMemo(() => {
    if (!shift.shiftBonuses || shift.shiftBonuses.length === 0) return undefined
    return shift.shiftBonuses[shift.shiftBonuses.length - 1].amount
  }, [shift])
  const closeModalRef = useRef<() => void>()
  const [error, setError] = useState('')
  const { updateShift } = useUpdateTimelineShift()
  const [updatingShift, setUpdatingShift] = useState(false)
  const { mutate: addBonus, isPending } = useAddShiftBonusMutation({
    shiftId: shift.id,
    onSuccess: async () => {
      setUpdatingShift(true)
      await updateShift(shift.id)
      setUpdatingShift(false)
      setError('')
      if (closeModalRef.current) {
        closeModalRef.current()
      }
      toast.success('Shift bonus updated successfully')
    },
    onError: (err) => {
      setError(err.message)
    },
  })
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: currentBonus
      ? {
          bonus: currentBonus,
        }
      : undefined,
  })

  return (
    <Modal
      trigger={trigger}
      loading={isPending || updatingShift}
      title={'Enter a new Bonus'}
      onOk={({ closeModal }) =>
        handleSubmit((data) => {
          closeModalRef.current = closeModal
          addBonus(data.bonus)
        })()
      }
      okText={'Submit New Bonus'}
    >
      {currentBonus ? (
        <Message variant={'info'} mb={3}>
          This shift currently has a ${Number(currentBonus).toFixed(2)} bonus.
          The new bonus will apply to future workers who accept this shift, and
          will not apply to workers who have already accepted the shift.
        </Message>
      ) : null}
      <Field label={'Bonus Amount'} error={errors?.bonus?.message?.toString()}>
        <Input {...register('bonus')} placeholder={'Enter Bonus Amount'} />
      </Field>
      {error ? <Message variant={'error'}>{error}</Message> : null}
    </Modal>
  )
}
