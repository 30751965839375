import { DemoApiMock } from 'api/demo/types'
import { getDemoData, matchRestRequest, setDemoData } from 'api/demo/utils'
import { EditShiftSubmissionRequest } from 'api/shift'
import { ShiftBonus, WorkShift } from 'typings/common_defs'
import { pathOr } from 'ramda'
import { matchPath } from 'react-router-dom'

export const mockUpdateShift: DemoApiMock = {
  shouldMock: (request) =>
    matchRestRequest(request, /\/company\/\d+\/shifts\/\d+/, 'PUT'),
  getResponse: async (request) => {
    const match = matchPath(
      '/company/:companyId/shifts/:shiftId',
      request.url as string
    )
    const shiftId = Number(match?.params.shiftId)
    const params = request.data as EditShiftSubmissionRequest
    const demoData = getDemoData()
    const { locations, positions, shifts, supervisors } = demoData
    const location = locations.find((l) => l.id === params.location_id)
    const position = positions.find((p) => p.id === params.position_id)

    if (!location || !position) {
      throw new Error('Location or position not found')
    }

    const shift = shifts.find((s) => s.id === shiftId)

    if (!shift) {
      throw new Error('Shift not found')
    }

    const baseSupervisorId = (supervisors[supervisors.length - 1].id ?? 0) + 1
    const updatedShift: WorkShift = {
      ...shift,
      location,
      position,
      startsAt: params.starts_at,
      endsAt: params.ends_at,
      locationless: params.locationless,
      workersNeeded: params.workers_needed,
      lunchLength: params.lunch_length,
      payRate: params.pay_rate,
      payLumpSum: params.pay_lump_sum,
      supervisors: params.supervisors.map((supervisor, index) => ({
        id: baseSupervisorId + index,
        shiftId,
        name: supervisor.name,
        phoneNumber: supervisor.phone_number ?? '',
        email: supervisor.email,
        userId: supervisor.user_id,
      })),
    }

    setDemoData({
      ...demoData,
      shifts: demoData.shifts.map((s) => (s.id === shiftId ? updatedShift : s)),
    })

    return null
  },
}

export const mockAddShiftBonus: DemoApiMock = {
  shouldMock: (request) =>
    matchRestRequest(request, /\/company\/\d+\/shifts\/\d+\/addbonus/, 'PUT'),
  getResponse: async (request) => {
    const bonus = pathOr(null, ['data', 'bonus'], request)
    const match = matchPath(
      '/company/:companyId/shifts/:shiftId/addbonus',
      request.url as string
    )
    const shiftId = Number(match?.params.shiftId)

    if (!bonus) {
      throw new Error('Invalid bonus amount')
    }

    const demoData = getDemoData()
    const shift = demoData.shifts.find((s) => s.id === shiftId)

    if (!shift) {
      throw new Error('Shift not found')
    }

    const newBonus: ShiftBonus = {
      amount: bonus,
      createdAt: new Date().toISOString(),
      companyPaid: false,
    }

    const updatedShift = {
      ...shift,
      shiftBonuses: [...(shift.shiftBonuses ?? []), newBonus],
    }

    setDemoData({
      ...demoData,
      shifts: demoData.shifts.map((s) => (s.id === shiftId ? updatedShift : s)),
    })

    return null
  },
}

export const mockCancelShift: DemoApiMock = {
  shouldMock: (request) =>
    matchRestRequest(request, /\/company\/\d+\/cancel\/\d+/, 'POST'),
  getResponse: async (request) => {
    const match = matchPath(
      '/company/:companyId/cancel/:shiftId',
      request.url as string
    )
    const shiftId = Number(match?.params.shiftId)
    const demoData = getDemoData()
    const shift = demoData.shifts.find((s) => s.id === shiftId)

    if (!shift) {
      throw new Error('Shift not found')
    }

    setDemoData({
      ...demoData,
      shifts: demoData.shifts.filter((s) => s.id !== shiftId),
    })

    return null
  },
}
