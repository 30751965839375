import { Roster, User, Work, WorkShift } from 'typings/common_defs'
import { graphql } from '../graphql'
import { path } from 'ramda'

export enum WorkStatusEnum {
  Scheduled = 'scheduled',
  Started = 'started',
  Approved = 'approved',
  EmployerApproved = 'employer_approved',
  Removed = 'removed',
  ReservationCancelled = 'reservation_cancelled',
  Offered = 'offered',
  AdminReview = 'admin_review',
  Completed = 'completed',
  WorkerDeclinedRequest = 'worker_declined_req',
  Bailed = 'bailed',
  EmployerReview = 'employer_review',
  Rejected = 'rejected',
  Reserved = 'reserved',
  ReservationExpired = 'reservation_expired',
  EmployerCancelled = 'employer_cancelled',
  SystemDeclinedRequest = 'system_declined_req',
  Paid = 'paid',
}

export const LegacyDeclinedWorkStatuses = [
  WorkStatusEnum.WorkerDeclinedRequest,
  WorkStatusEnum.SystemDeclinedRequest,
  WorkStatusEnum.Rejected,
]

export const DeclinedWorkStatuses = [
  WorkStatusEnum.WorkerDeclinedRequest,
  WorkStatusEnum.Rejected,
]

export const CompletedWorkStatuses = [
  WorkStatusEnum.Completed,
  WorkStatusEnum.Paid,
  WorkStatusEnum.EmployerReview,
  WorkStatusEnum.EmployerApproved,
  WorkStatusEnum.AdminReview,
  WorkStatusEnum.Approved,
]

interface TimelineShiftRoster extends Omit<Roster, 'workers'> {
  workers?: Array<Pick<User, 'id' | 'name' | 'profilePicUrl'>>
}

export interface TimelineShift
  extends Omit<
    WorkShift,
    'startsAt' | 'endsAt' | 'work' | 'rosters' | 'workersNeeded'
  > {
  startsAt: string
  endsAt: string
  work?: Array<Omit<Work, 'status'> & { status?: WorkStatusEnum }>
  rosters?: Array<TimelineShiftRoster>
  workersNeeded: number | null
}

interface ShiftTimelineOptions {
  startAt?: Date
  locationId?: number
  positionId?: number
  headCursor?: string
  tailCursor?: string
}

export interface PaginatedShiftTimeline {
  items: TimelineShift[]
  headCursor?: string
  tailCursor?: string
}

const timelineShiftQuery = `
  clockInCode, clockOutCode,
  requiresClockInCode, requiresClockOutCode,
  requiresQrClockInCode, requiresQrClockOutCode,
  rosters { 
    id, name, 
    workers {
      id, name, profilePicUrl
    } 
    isSpecificWorkerRequest
  }
  shiftBonuses {
    amount
  }
  rostersHoldExpiresAt
  cancellationPolicy {
    policyWindows {
      cutoffTimeHours
      chargePercentage
    }
  }
  position { name, id, hidden},
  location { name, id , address {city, state, street, unit, zip, lat, long, timezone}}
  locationless
  id, startsAt, endsAt, workersNeeded, isTryout
  work {
      id
      status
      confirmedAt
      startedAt
      worker {id, name, profilePicUrl}
  },
  unableToAttendWork {
    id
    status
    worker { id }
  }
  recommendBackupShift
  shiftToBackup {
    startsAt
  }
  assignment {
    id
    name
  }
`

export async function hasPreviousTimelineData(
  options: Pick<
    ShiftTimelineOptions,
    'headCursor' | 'positionId' | 'locationId'
  >
) {
  const { locationId, positionId, headCursor } = options
  const gqlParams = [
    headCursor ? `headCursor: "${headCursor}"` : '',
    positionId ? `positionId: ${positionId}` : '',
    locationId ? `locationId: ${locationId}` : '',
  ].join(', ')

  const result = await graphql(
    `
    company {
      infiniteShifts(${gqlParams}) {
        headCursor
        tailCursor
        items {
          id
        }
      }
    }
  `
  )

  const data = path(
    ['data', 'data', 'company', 'infiniteShifts'],
    result
  ) as PaginatedShiftTimeline

  return data.items.length > 0
}

export async function getShiftsForTimeline(options: ShiftTimelineOptions) {
  const { startAt, locationId, positionId, headCursor, tailCursor } = options
  const gqlParams = [
    startAt && !(headCursor || tailCursor)
      ? `startDate: "${startAt.toISOString()}"`
      : '',
    headCursor ? `headCursor: "${headCursor}"` : '',
    tailCursor ? `tailCursor: "${tailCursor}"` : '',
    positionId ? `positionId: ${positionId}` : '',
    locationId ? `locationId: ${locationId}` : '',
  ]
    .filter((p) => p)
    .join(', ')

  const result = await graphql(
    `
    company {
      infiniteShifts(${gqlParams}) {
        headCursor
        tailCursor
        items {
          ${timelineShiftQuery}
        }
      }
    }
  `
  )

  const data = path(
    ['data', 'data', 'company', 'infiniteShifts'],
    result
  ) as PaginatedShiftTimeline

  return data
}

export async function getTimelineShift(id: number) {
  const data = await graphql(`
    company {
      shiftsDetail(ids: [${id}]) { 
        ${timelineShiftQuery} 
      }
    }`)

  return path(
    ['data', 'data', 'company', 'shiftsDetail', 0],
    data
  ) as TimelineShift
}
