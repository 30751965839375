import React, { Fragment, useMemo, useState } from 'react'
import { PositionTemplateToWorkerRequirement } from 'typings/common_defs'
import { Box, Button, InputWrapper, Label, Message, Text } from 'ui'
import { FiPlusCircle } from 'react-icons/fi'
import { usePositionEditor } from 'components/AddPosition/usePositionEditor'
import { useFormContext } from 'react-hook-form'
import { BsCheckCircle } from 'react-icons/bs'
import { AiFillInfoCircle } from 'react-icons/ai'
import { requirementNeedsPhotoUpload } from 'lib/requirement'

interface Props {
  positionTemplateId: number
  category: string
  requirements: PositionTemplateToWorkerRequirement[]
}

export function RequirementGroup(props: Props) {
  const { addRequirement, newRequirements, position } = usePositionEditor()
  const { category, positionTemplateId, requirements } = props
  const [showNewInput, setShowNewInput] = useState(false)
  const [newInputValue, setNewInputValue] = useState('')
  const { watch, setValue } = useFormContext()
  const requirementIds = watch('requirementIds') ?? []
  const newRequirementsForGroup = newRequirements.filter(
    (requirement) =>
      requirement.positionTemplateId === positionTemplateId &&
      requirement.requirement.category === category
  )
  const existingCustomRequirements = useMemo(() => {
    if (!position) return []
    const ids = requirements.map((r) => Number(r.requirement.id))
    return (position.requirements2 ?? [])
      .filter((r) => r.category === category && !ids.includes(Number(r.id)))
      .map((r) => ({
        positionTemplateId,
        isWorkwhileRequired: false,
        isPreSelected: false,
        requirement: {
          id: r.id,
          name: r.name,
          category: r.category,
        },
      }))
  }, [positionTemplateId, category, position, requirements])

  const onAddRequirement = () => {
    const {
      requirement: { id },
    } = addRequirement({
      name: newInputValue,
      category,
      positionTemplateId,
    })
    setShowNewInput(false)
    setNewInputValue('')
    setValue('requirementIds', [...requirementIds, id])
  }

  return (
    <Box>
      {[
        ...requirements,
        ...newRequirementsForGroup,
        ...existingCustomRequirements,
      ].map((requirement) => {
        const id = Number(requirement.requirement.id)
        const isSelected = requirementIds.includes(id)
        const isMandated = requirement.isWorkwhileRequired
        return (
          <Label
            fontWeight={0}
            style={{ position: 'relative', cursor: 'pointer' }}
            mr={3}
            mb={3}
            size={'large'}
            variant={isSelected || isMandated ? 'primary' : 'none'}
            key={id}
            onClick={() => {
              if (isMandated) {
                return
              }
              if (requirementIds.includes(id)) {
                return setValue(
                  'requirementIds',
                  requirementIds.filter((rId) => rId !== id)
                )
              }
              setValue('requirementIds', [...requirementIds, id])
            }}
          >
            {isSelected ? (
              <BsCheckCircle fontSize={18} />
            ) : (
              <FiPlusCircle fontSize={18} />
            )}
            <Text ml={2}>{requirement.requirement.name}</Text>
            {isMandated ? (
              <Box
                position={'absolute'}
                top={'-1rem'}
                right={'-0.7rem'}
                color={'info'}
              >
                <AiFillInfoCircle fontSize={22} />
              </Box>
            ) : null}
          </Label>
        )
      })}
      <Box>
        {showNewInput ? (
          <Box width={[1, 2 / 3]} mt={3}>
            <InputWrapper block={true}>
              <input
                autoFocus={true}
                value={newInputValue}
                onChange={(e) => setNewInputValue(e.currentTarget.value)}
                placeholder={'Enter New Requirement'}
              />
              <Button
                kind={'small'}
                mr={2}
                width={100}
                disabled={!newInputValue}
                onClick={onAddRequirement}
              >
                Add
              </Button>
            </InputWrapper>
          </Box>
        ) : (
          <Button variant={'text'} onClick={() => setShowNewInput(true)}>
            + Add Your Own
          </Button>
        )}
      </Box>
      {requirements.map((requirement) => {
        const id = Number(requirement.requirement.id)
        const isSelected = requirementIds.includes(id)
        const shouldShowLowFillWarning =
          isSelected &&
          !requirement.isWorkwhileRequired &&
          !requirement.isPreSelected &&
          requirementNeedsPhotoUpload(requirement.requirement)
        return (
          <Fragment key={id}>
            {requirement.isWorkwhileRequired ? (
              <Message variant={'info'} mt={3}>
                A {requirement.requirement.name} is mandated by WorkWhile.
              </Message>
            ) : null}
            {shouldShowLowFillWarning ? (
              <Message variant={'warning'} mt={3}>
                A {requirement.requirement.name} requirement may increase the
                time needed to fill this position.
              </Message>
            ) : null}
          </Fragment>
        )
      })}
    </Box>
  )
}
