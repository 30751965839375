import React from 'react'
import { Text } from 'ui'

interface Props {
  content: string
  maxLength?: number
}

export function TruncatedDescription(props: Props) {
  const { content, maxLength = 150 } = props

  const truncatedContent =
    content.length > maxLength ? `${content.slice(0, maxLength)}...` : content

  return <Text>{truncatedContent}</Text>
}
