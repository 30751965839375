import { graphql } from '../graphql'
import { path } from 'ramda'
import { ChargeAccount } from 'typings/common_defs'
import { api } from '../api'

export async function getChargeAccounts() {
  const data = await graphql(
    `company {
          chargeAccounts { accountType, brand, last4 }
        }`,
    'current_company'
  )

  return path(
    ['data', 'data', 'company', 'chargeAccounts'],
    data
  ) as ChargeAccount[]
}

export async function getStripeACHUrl() {
  const data = await graphql(
    ` company {
          stripeAddAchUrl
      }`
  )
  return path(['data', 'data', 'company', 'stripeAddAchUrl'], data) as
    | string
    | undefined
}

export function uploadCardToken(cardToken: string) {
  return api.post(`/company/token`, { card_token: cardToken })
}
