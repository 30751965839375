import React from 'react'
import { Box, Heading } from 'ui'
import { ProfileInfo } from './ProfileInfo'
import { ChargeAccounts } from 'pages/PaymentPage/ChargeAccounts'

export function Account() {
  return (
    <Box>
      <Heading level={3}>Account</Heading>
      <ProfileInfo />
      <Box mt={5}>
        <ChargeAccounts />
      </Box>
    </Box>
  )
}
