import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { getWorkMealBreaks } from 'api/work'
import { workMealBreaksKey } from './keys'

export function useWorkMealBreaks(
  shiftId: number,
  workId: number,
  options: Pick<UseQueryOptions, 'enabled'>
) {
  return useQuery({
    queryKey: workMealBreaksKey(workId),
    queryFn: () => getWorkMealBreaks(shiftId, workId),
    ...options,
  })
}
