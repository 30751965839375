import React, { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  usePositionTutorial,
  useSavePositionTutorialMutation,
} from 'queries/company'
import { EditorProvider, EditorView } from 'components/Tutorials'
import { Loading, toast } from '@workwhile/ui'
import { TutorialCategory } from 'api/shift/typings'
import { Page } from 'pages/layout'
import { HeaderBar } from 'pages/layout/HeaderBar'
import { useCompatTutorial } from 'hooks/useCompatTutorial'

export function PositionInstructionEditor() {
  const navigate = useNavigate()
  const params = useParams<{ positionId: string; idOrCategory: string }>()
  const positionId = Number(params.positionId)
  const idOrCategory = params.idOrCategory as string

  const tutorialId = useMemo(() => {
    if (!isNaN(Number(idOrCategory))) {
      return Number(idOrCategory)
    }
  }, [idOrCategory])

  const { data: tutorial, isLoading } = usePositionTutorial(
    positionId,
    tutorialId
  )
  const compatTutorial = useCompatTutorial(tutorial ?? undefined)
  const category = useMemo(() => {
    if (tutorial?.category) {
      return tutorial.category
    }
    return idOrCategory
  }, [idOrCategory, tutorial])

  const {
    mutate: saveTutorial,
    isPending: isSaving,
    error,
  } = useSavePositionTutorialMutation({
    positionId,
    tutorialId,
    category: category as TutorialCategory,
    onSuccess(tutorialId) {
      toast.success('Instruction saved successfully')
      if (tutorialId) {
        // update routes if tutorialId is present
        navigate(`/position/${positionId}/instruction/${tutorialId}`, {
          replace: true,
        })
      }
    },
  })

  if (isLoading) {
    return <Loading />
  }

  return (
    <Page>
      <HeaderBar />
      <EditorProvider
        category={category as TutorialCategory}
        tutorialId={tutorialId}
        tutorial={compatTutorial}
        isSaving={isSaving}
        error={error}
        showNotifyWorkers
        title="This change will be applied for all shifts with this position"
        saveTutorial={saveTutorial}
      >
        <EditorView />
      </EditorProvider>
    </Page>
  )
}
