import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Select, SelectProps } from 'ui'
import { useAssignedWorkRow } from '../useAssignedWorkerRow'
import { Work } from 'typings/common_defs'
import { useShiftDetail } from 'pages/ShiftDetailPage/useShiftDetail'
import {
  AssignedWorkerOption,
  CLOCK_IN_TIME_ONLY_OPTIONS,
  getClockOutOptions,
} from 'lib/work/assigned_work_options'

interface Props extends SelectProps {
  work: Work
}

export function AssignedWorkerOptionsSelector(props: Props) {
  const { work, ...otherProps } = props
  const { shift } = useShiftDetail()
  const { data, setValue } = useAssignedWorkRow(work.id)
  const [openDropdown, setOpenDropdown] = useState(false)
  const initialStartedAt = useRef(data.startedAt)
  const initialCompletedAt = useRef(data.completedAt)

  const options: AssignedWorkerOption[] = useMemo(() => {
    // options are determined based on the data the biz portal user has entered
    // and the type of shift

    if (!shift || !data) {
      return []
    }

    const { startedAt, completedAt } = data

    if (!startedAt) {
      // worker has not clocked in yet
      return CLOCK_IN_TIME_ONLY_OPTIONS
    }

    if (startedAt && !completedAt) {
      return CLOCK_IN_TIME_ONLY_OPTIONS
    }

    if (startedAt && completedAt) {
      return getClockOutOptions(data, shift)
    }

    return []
  }, [data.startedAt, data.completedAt])

  const value = useMemo(() => {
    if (!props.value) {
      return options.find((option) => option.isDefault)
    }
    const foundOption = options.find((option) => option.value === props.value)
    if (!foundOption) {
      // if no option found, just show the default
      return options.find((option) => option.isDefault)
    }
    return foundOption
  }, [data.startedAt, data.completedAt, data.finishedEarlyCategory, options])

  useEffect(() => {
    // when the options are reset, set the finished early category to the default
    const defaultOption = options.find((option) => option.isDefault)
    setValue('finishedEarlyCategory', defaultOption?.value || null)
  }, [options])

  useEffect(() => {
    // if the times are changed, open the dropdown
    if (
      data.startedAt &&
      data.completedAt &&
      (data.startedAt !== initialStartedAt.current ||
        data.completedAt !== initialCompletedAt.current)
    ) {
      setOpenDropdown(true)
    }
  }, [data.startedAt, data.completedAt])

  const handleOpenDropdownChange = (isOpen: boolean) => {
    setOpenDropdown(isOpen)
  }

  if (!options || options.length === 0) return null

  return (
    <Select
      aria-label={'Assigned Worker Options Selector'}
      options={options}
      {...otherProps}
      isMulti={false}
      value={value}
      menuIsOpen={openDropdown} // Pass the prop to the Select component
      onMenuOpen={() => handleOpenDropdownChange(true)}
      onMenuClose={() => handleOpenDropdownChange(false)}
    />
  )
}
