import React, { useState } from 'react'
import { ActionButton } from './ActionButton'
import { useWorkerProfile } from 'pages/WorkerProfilePage/useWorkerProfile'
import { GiveBonusModal } from 'components/GiveBonusModal'

export function GiveBonus() {
  const [open, setOpen] = useState(false)
  const { profileData } = useWorkerProfile()

  return (
    <>
      {open ? (
        <GiveBonusModal
          open={open}
          workerId={profileData.worker.id}
          workerName={profileData.worker.name}
          onClose={() => setOpen(false)}
        />
      ) : null}
      <ActionButton onClick={() => setOpen(true)}>Give Bonus</ActionButton>
    </>
  )
}
