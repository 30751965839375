import { useQuery } from '@tanstack/react-query'
import { messageableUpcomingShiftsKey } from './keys'
import { getMessageableUpcomingShifts } from 'api/message'

export function useMessageableUpcomingShifts() {
  return useQuery({
    queryKey: messageableUpcomingShiftsKey,
    queryFn: getMessageableUpcomingShifts,
  })
}
