import { TimelineShift } from 'api/shift'
import { Company, ShiftSupervisor, WorkShift } from 'typings/common_defs'
import { SelectableSupervisor } from 'typings/selectable_ext'
import { toTitleCase } from './legacy_util'
import { formatPhoneNumber, unmaskPhoneNumber } from './phone'

export const SUPERVISOR_TYPES = {
  existing_user: 'existing_user', // handles letting user select a user who is already a member of the company
  invited_team_member: 'invited_team_member', // handles letting user select a user who has been invited but not yet accepted
  free_form: 'free_form', // user can enter a name and a phone number (e.g. name="Dispatch") (Optional: invite this person to be a new team member)
  existing_shift_supervisor: 'existing_shift_supervisor', // handles letting user select legacy ShiftSupervisor entries that do not invite or link supervisor as a user
}

export const INIT_SUPERVISOR: SelectableSupervisor = {
  id: 0,
  requestType: SUPERVISOR_TYPES.free_form,
}

export const OTHER_TEAM_MEMBER_OPTION = {
  label: 'Assign Other Team Member',
  value: SUPERVISOR_TYPES.free_form,
  requestType: SUPERVISOR_TYPES.free_form,
}

export const getSupervisorListAsString = (shift: WorkShift | TimelineShift) => {
  if (shift.supervisors && shift.supervisors.length > 0) {
    return shift.supervisors
      .map((supervisor) =>
        supervisor.name
          ? `${toTitleCase(supervisor.name)}${
              supervisor.phoneNumber
                ? ` ${formatPhoneNumber(supervisor.phoneNumber)}`
                : ''
            }`
          : null
      )
      .join(', ')
  } else if (shift.supervisor) {
    // backwards compatible with non array-type supervisor fields
    return `${toTitleCase(shift.supervisor)}${
      shift.supervisorPhone
        ? ` ${formatPhoneNumber(shift.supervisorPhone)}`
        : ''
    }`
  }
  return null
}

export const getSupervisorMemberSelectOptions = (
  company: Company,
  existingSupervisors: Array<SelectableSupervisor>
) => {
  const existingSupervisorNames = existingSupervisors
    .map((es) => es.name)
    .filter((es) => es !== null)
  if (!company.members) {
    return {}
  }
  const sortedCompanyMembers = company.members.sort((a, b) => {
    if (a.name && b.name) {
      return b.name < a.name ? 1 : -1
    }
    return 0
  })
  return {
    label: 'Company Members',
    options: sortedCompanyMembers
      .map((member): SelectableSupervisor | null => {
        if (existingSupervisorNames.includes(member.name)) {
          return null
        }
        return {
          label: toTitleCase(member.name),
          name: member.name,
          value: `${SUPERVISOR_TYPES.existing_user}_` + member.id, // needs to be unique
          userId: member.id,
          phoneNumber: member.phoneNumber,
          email: member.email,
          roleId: member.companyRolePerm?.id,
          requestType: SUPERVISOR_TYPES.existing_user,
        }
      })
      .filter((s) => s !== null),
  }
}

export const getSupervisorPendingInviteSelectOptions = (
  company: Company,
  existingSupervisors: Array<SelectableSupervisor>
) => {
  const existingSupervisorEmails = existingSupervisors
    .map((es) => es.email)
    .filter((es) => es !== null)
  if (!company.pendingInvites) {
    return {}
  }
  const sortedPendingInvites = company.pendingInvites.sort((a, b) => {
    if (a.email && b.email) {
      return b.email < a.email ? 1 : -1
    }
    return 0
  })
  return {
    label: 'Invites',
    options: sortedPendingInvites
      .map((invite): SelectableSupervisor | null => {
        if (existingSupervisorEmails.includes(invite.email)) {
          return null
        }
        return {
          id: invite.id,
          label: invite.email + ' (Invite Sent)',
          value: `${SUPERVISOR_TYPES.invited_team_member}_` + invite.id, // needs to be unique
          requestType: SUPERVISOR_TYPES.invited_team_member,
          email: invite.email,
        }
      })
      .filter((s) => s !== null),
  }
}

/**
 * Returns list of supervisors previously chosen for this shift by the user
 * as a group of options that can be displayed in a Select component
 * @param existingSupervisors supervisors previously chosen for this shift by the user
 * @param shift
 */
export const getPreviousSupervisorSelectOptions = (
  existingSupervisors: Array<SelectableSupervisor>,
  shift?: WorkShift | null
) => {
  if (!shift || !shift.supervisors || shift.supervisors.length === 0) {
    return {}
  }
  const existingSupervisorNames = existingSupervisors
    .map((es) => es.name)
    .filter((es) => es !== null)
  const sortedPreviousSupervisors = shift.supervisors.sort((a, b) => {
    if (a.name && b.name) {
      return b.name < a.name ? 1 : -1
    }
    return 0
  })
  return {
    label: 'Previous Supervisors',
    options: sortedPreviousSupervisors
      .map((supervisor): SelectableSupervisor | null => {
        if (existingSupervisorNames.includes(supervisor.name)) {
          return null
        }
        const requestType = supervisor.userId
          ? SUPERVISOR_TYPES.existing_user
          : SUPERVISOR_TYPES.existing_shift_supervisor
        return {
          id: supervisor.id,
          label: toTitleCase(supervisor.name),
          value: `${requestType}_` + supervisor.id, // needs to be unique
          name: supervisor.name,
          phoneNumber: supervisor.phoneNumber,
          email: supervisor.email,
          userId: supervisor.userId,
          requestType,
        }
      })
      .filter((s) => s !== null),
  }
}

export const supervisorSelectFilterOption = (candidate, input) => {
  // "Other Team Member" is always shown no matter what the user searches for
  if (candidate.value === OTHER_TEAM_MEMBER_OPTION.value) return true
  return candidate.label
    ? candidate.label.toLowerCase().includes(input.toLowerCase())
    : false
}

// Makes server-side supervisor object to local supervisor object which can be selected in the UI
export const mapServerSupervisorToSelectableSupervisor = (
  supervisor: ShiftSupervisor
): SelectableSupervisor => {
  const requestType = supervisor.userId
    ? SUPERVISOR_TYPES.existing_user
    : SUPERVISOR_TYPES.existing_shift_supervisor

  return {
    label: toTitleCase(supervisor.name),
    value: `${requestType}_` + supervisor.id, // needs to be unique
    name: supervisor.name,
    phoneNumber: supervisor.phoneNumber,
    userId: supervisor.userId,
    requestType,
  }
}

export const mapClientSupervisorsToServerSupervisors = (
  supervisors: Array<SelectableSupervisor>
): Array<ShiftSupervisor> => {
  const supervisorsToConsider = supervisors.filter((s) => !!s.value)
  return supervisorsToConsider.map((supervisor) => {
    return {
      name: supervisor.name,
      phone_number: unmaskPhoneNumber(supervisor.phoneNumber),
      user_id: supervisor.userId,
      email: supervisor.email,
      role_perm_id: supervisor.roleId,
      request_type: supervisor.requestType,
    }
  })
}
