import React from 'react'
import { Box, createColumnHelper, Text } from 'ui'
import { ChargeItem } from 'api/payment'
import { formatCurrency } from 'lib/string'

const columnHelper = createColumnHelper<ChargeItem>()

export const columns = [
  columnHelper.accessor('description', {
    header: 'Description',
    cell: (info) => {
      const description = info.getValue()
      const details = description.split('|')

      return (
        <>
          {details.length > 1 ? details[0] : description}
          <Box display={['block', 'none']}>
            <Text>{details.length > 1 ? details[1] : null}</Text>
            <Text>Quantity: {info.row.original.quantity}</Text>
            <Text>
              Unit Amount: {formatCurrency(info.row.original.unitAmount)}
            </Text>
            <Text>Amount: {formatCurrency(info.row.original.amount)}</Text>
          </Box>
        </>
      )
    },
  }),
  columnHelper.accessor('id', {
    header: 'Location',
    cell: (info) => {
      const details = info.row.original.description.split('|')
      if (details.length > 1) {
        return details[1]
      }

      return null
    },
  }),
  columnHelper.accessor('quantity', {
    header: 'Quantity',
  }),
  columnHelper.accessor('unitAmount', {
    header: 'Unit Amount',
    cell: (info) => formatCurrency(info.getValue()),
  }),
  columnHelper.accessor('amount', {
    header: 'Amount',
    cell: (info) => formatCurrency(info.getValue()),
  }),
]
