import React from 'react'
import styled from 'styled-components'
import { Box, Flex, Heading, CloseButton } from 'ui'
import { useLayoutContext } from 'pages/layout/useLayoutContext'
import { Overlay } from '../Overlay'

const Container = styled(Box)<{ $open: boolean }>`
  position: fixed;
  z-index: 10002;
  height: 100%;
  max-height: 100vh;
  overflow-y: auto;
  top: 0;
  right: 0;
  border-radius: 0;
  transform: translateX(100%);
  box-shadow: none;
  transition: transform 0.3s ease-in-out;

  ${({ theme, $open }) => `
    background-color: ${theme.colors.white};
    
      ${
        $open
          ? `
        transform: translateX(0);
        box-shadow: ${theme.shadows.standard};
      `
          : ''
      }
  `}
`

export function NotificationCenter() {
  const { notificationOpen, toggleNotificationCenter } = useLayoutContext()

  return (
    <>
      {notificationOpen ? <Overlay onClick={toggleNotificationCenter} /> : null}
      <Container px={4} py={3} width={[1, '50rem']} $open={notificationOpen}>
        <Flex alignItems={'center'}>
          <Box flex={1}>
            <Heading level={2}>Notifications</Heading>
          </Box>
          <CloseButton onClick={toggleNotificationCenter} />
        </Flex>
      </Container>
    </>
  )
}
