import React from 'react'
import { useShiftEditor } from './useShiftEditor'
import { Box, Link, Stepper, Text } from 'ui'
import { useUnsavedBlock } from 'pages/ShiftEditorPage/useUnsavedBlock'
import { useLocation } from 'react-router-dom'

export function StepIndicator() {
  const location = useLocation()
  const state = location.state || {}
  const { steps, currentStep, canGoTo } = useShiftEditor()
  useUnsavedBlock()

  return (
    <Box width={[1, 1, '70rem']}>
      <Stepper
        steps={steps}
        currentStep={currentStep}
        renderStep={(step) => {
          const isCurrent = step.name === currentStep
          if (canGoTo(step.name)) {
            return (
              <Link to={step.route === '/' ? '' : step.route} state={state}>
                <Text
                  color={isCurrent ? 'success' : 'primary'}
                  fontWeight={isCurrent ? 2 : 0}
                >
                  {step.name}
                </Text>
              </Link>
            )
          }
          return step.name
        }}
      />
    </Box>
  )
}
