import { graphql } from 'api'
import { path } from 'ramda'

export async function getWorkerCompliments(workerId: number) {
  const data = await graphql(
    `
    company {
      worker(workerId: "${workerId}") {
        workForWorkWhile {
          e2wReview {
            tags
          }
        }
      }
    }
    `
  )

  const workForWorkWhile =
    path(['data', 'data', 'company', 'worker', 'workForWorkWhile'], data) ?? []

  const compliments: Record<string, number> = {}
  workForWorkWhile.forEach((work) => {
    try {
      const tags: string[] = work.e2wReview.tags
        ? JSON.parse(work.e2wReview.tags)
        : []
      tags.forEach((tag) => {
        if (tag in compliments) {
          compliments[tag] += 1
        } else {
          compliments[tag] = 1
        }
      })
    } catch {
      // do nothing
    }
  })

  return compliments
}
