import React from 'react'
import { MarkdownContent } from 'api/shift/typings'
import {
  Flex,
  Icon,
  MarkdownEditor,
  MarkdownProvider,
  Text,
} from '@workwhile/ui'
import { PlusIcon, Trash2Icon } from 'lucide-react'
import { PageWrapper, RemoveButton } from './styled'

interface Props {
  pages: MarkdownContent[]
  currentPageIdx: number
  onPageChange: (pageIdx: number) => void
  onAddPage: () => void
  onDeletePage: (pageIdx: number) => void
}

export function Paginator({
  pages,
  currentPageIdx,
  onPageChange,
  onAddPage,
  onDeletePage,
}: Props) {
  return (
    <Flex mb={3} overflow={'auto'}>
      {pages.map((page, index) => {
        const isCurrent = index === currentPageIdx
        return (
          <Flex
            flexDirection={'column'}
            alignItems={'center'}
            key={index}
            mr={3}
            flexShrink={0}
          >
            <PageWrapper
              data-page-number={index + 1}
              borderWidth={isCurrent ? 2 : 1}
              borderColor={isCurrent ? 'primary' : 'neutrals.200'}
              onClick={() => onPageChange(index)}
            >
              <RemoveButton
                onClick={(e) => {
                  e.stopPropagation()
                  onDeletePage(index)
                }}
              >
                <Icon icon={Trash2Icon} size={16} />
              </RemoveButton>
              <MarkdownProvider>
                <MarkdownEditor
                  defaultValue={page.markdown}
                  readonly={true}
                  borderless={true}
                  scale={0.4}
                />
              </MarkdownProvider>
            </PageWrapper>
            <Text fontSize={1} mt={1}>
              {index + 1}
            </Text>
          </Flex>
        )
      })}
      <PageWrapper
        key={'new-page'}
        alignItems={'center'}
        justifyContent={'center'}
        onClick={onAddPage}
      >
        <Icon icon={PlusIcon} size={40} color={'primary'} />
      </PageWrapper>
    </Flex>
  )
}
