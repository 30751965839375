import React, { Dispatch, SetStateAction, useMemo } from 'react'
import {
  Text,
  Button,
  createColumnHelper,
  Box,
  Avatar,
  Tooltip,
  IconButton,
} from 'ui'
import { CompanyWorker, StatusForCompanyEnum } from 'api/worker'
import styled from 'styled-components'
import { HiHeart } from 'react-icons/hi'
import { BiBlock } from 'react-icons/bi'

const columnHelper = createColumnHelper<CompanyWorker>()

const NameContainer = styled(Box).attrs({
  display: 'inline-flex',
  alignItems: 'center',
})``

export function useColumns(
  setSelected: Dispatch<SetStateAction<CompanyWorker[]>>
) {
  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Name',
        cell: (info) => {
          const worker = info.row.original
          return (
            <NameContainer>
              <Avatar
                size={'medium'}
                user={{
                  userId: worker.id.toString(),
                  name: worker.name,
                  photoUrl: worker.profilePicUrl,
                }}
              />
              <Text ml={2}>{info.getValue()}</Text>
            </NameContainer>
          )
        },
      }),
      columnHelper.accessor('numShiftsForCompany', {
        header: 'Shifts',
        cell: (info) => <Text>{info.getValue()}</Text>,
      }),
      columnHelper.accessor('rating', {
        header: 'Avg. Rating',
        cell: (info) => {
          const rating = info.getValue()
          return (
            <Box display={'inline-flex'} justifyContent={'center'}>
              <Box width={30}>{rating ? rating.toFixed(2) : '--'}</Box>
              {info.row.original.statusForCompany ===
              StatusForCompanyEnum.Favorite ? (
                <Text ml={2} color={'primary'}>
                  <Tooltip content={'Favorite'}>
                    <span>
                      <HiHeart fontSize={18} />
                    </span>
                  </Tooltip>
                </Text>
              ) : null}
              {info.row.original.statusForCompany ===
              StatusForCompanyEnum.Blocked ? (
                <Text ml={2} color={'error'}>
                  <Tooltip content={'Blocked'}>
                    <span>
                      <BiBlock fontSize={18} />
                    </span>
                  </Tooltip>
                </Text>
              ) : null}
            </Box>
          )
        },
      }),
      columnHelper.accessor('id', {
        header: '',
        size: 150,
        cell: (info) => (
          <Button
            kind={'medium'}
            disabled={
              info.row.original.statusForCompany ===
              StatusForCompanyEnum.Blocked
            }
            width={150}
            onClick={() => {
              setSelected((selected) => [...selected, info.row.original])
            }}
          >
            Select
          </Button>
        ),
      }),
    ],
    []
  )

  return columns
}
