import { useContext } from 'react'
import { WorkerProfileContext } from './WorkerProfileProvider'

export function useWorkerProfile() {
  const context = useContext(WorkerProfileContext)
  if (!context) {
    throw new Error(
      'useWorkerProfile must be used within a WorkerProfileProvider'
    )
  }
  return context
}
