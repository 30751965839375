import { useSignOutMutation } from 'queries/user/useSignOutMutation'
import { useNavigate } from 'react-router'
import { useEffect } from 'react'

export function SignOutPage() {
  const navigate = useNavigate()
  const { mutate: signOut } = useSignOutMutation({
    onSuccess: () => {
      navigate('/')
    },
  })

  useEffect(signOut, [])

  return null
}
