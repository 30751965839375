import React, { useMemo } from 'react'
import { Box, Card, Flex, Heading, Loading, Text } from 'ui'
import { useWorkerCompliments } from 'queries/worker'
import { useWorkerProfile } from './useWorkerProfile'
import AboveAndBeyondAsset from 'assets/above-beyond-badge.svg'
import VeryProductiveAsset from 'assets/very-productive-badge.svg'
import GreatAttitudeAsset from 'assets/great-attitude-badge.svg'

export function Compliments() {
  const { profileData } = useWorkerProfile()
  const { data, isLoading } = useWorkerCompliments(profileData.worker.id)
  const totalCompliments = useMemo(() => {
    if (!data) {
      return 0
    }
    return Object.values(data).reduce((acc, val) => acc + val, 0)
  }, [data])

  const renderBadge = (key: string, asset: string, label: string) => {
    if (!data || !data[key]) {
      return null
    }

    return (
      <Card
        borderWidth={0}
        width={[4 / 9, 3 / 10]}
        alignItems={'center'}
        justifyContent={'center'}
        mb={[3, 0]}
      >
        <img height={72} src={asset} alt={label} />
        <Heading level={2} my={1}>
          {data[key]}
        </Heading>
        <Text textAlign={'center'} fontSize={1}>
          {label}
        </Text>
      </Card>
    )
  }

  if (isLoading || !data) {
    return <Loading />
  }

  if (totalCompliments === 0) {
    return null
  }

  return (
    <Box width={1} py={3}>
      <Heading level={4}>Compliments</Heading>
      <Flex justifyContent={'space-evenly'} flexWrap={'wrap'}>
        {renderBadge('above_and_beyond', AboveAndBeyondAsset, 'Above & Beyond')}
        {renderBadge('very_productive', VeryProductiveAsset, 'Very Productive')}
        {renderBadge('great_attitude', GreatAttitudeAsset, 'Great Attitude')}
      </Flex>
    </Box>
  )
}
