import * as zod from 'zod'

export const schema = zod
  .object({
    name: zod.string().nonempty('Name is required'),
    phoneNumber: zod
      .string()
      .min(10, 'Invalid phone number')
      .optional()
      .or(zod.literal('')),
    email: zod
      .string()
      .email('Invalid email address')
      .optional()
      .or(zod.literal('')),
  })
  .refine((data) => data.phoneNumber || data.email, {
    message: 'Either email or phone number is required',
    path: ['email'], // path of error
  })

export type InviteWorkerForm = zod.infer<typeof schema>
