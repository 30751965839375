import React from 'react'
import { Page } from '../layout'
import { Box, Flex, Heading, Loading } from 'ui'
import { ListStats } from './ListStats'
import { ListDetails } from './ListDetails'
import { useRosterDetail } from 'queries/worker'
import { useParams } from 'react-router-dom'

export function ListDetailPage() {
  const params = useParams<{ rosterId: string }>()
  const rosterId = Number(params.rosterId)
  const { data, isLoading } = useRosterDetail(rosterId)

  if (isLoading || !data) {
    return <Loading />
  }

  return (
    <Page>
      <Heading>List</Heading>
      <Flex flexWrap={'wrap'}>
        <Box width={[1, 1, 1, 3 / 5]}>
          <ListDetails data={data} />
        </Box>
        <Box width={[1, 1, 1, 2 / 5]} pl={[0, 0, 0, 5]}>
          <ListStats />
        </Box>
      </Flex>
    </Page>
  )
}
