import { WorkSubmissionRequest } from 'api/work'
import { WorkApprovalData } from './schemas/workApprovalSchema'
import {
  AssignedWorkerOptionsEnum,
  FINISHED_EARLY_OPTIONS,
} from 'lib/work/assigned_work_options'

export const toServerData = (data: WorkApprovalData): WorkSubmissionRequest => {
  return {
    work_id: data.id,
    started_at: data.startedAt,
    completed_at: data.completedAt,
    finished_early_category:
      data.finishedEarlyCategory &&
      FINISHED_EARLY_OPTIONS.includes(
        data.finishedEarlyCategory as AssignedWorkerOptionsEnum
      )
        ? data.finishedEarlyCategory
        : null,
    finished_early_reason: data.finishedEarlyReason,
    no_show: data.finishedEarlyCategory === AssignedWorkerOptionsEnum.NO_SHOW, // no show category gets passed as a boolean
    review_rating: data.e2wReview?.rating,
    review_comment: data.e2wReview?.comment,
    review_tags: data.e2wReview?.tags ? JSON.parse(data.e2wReview?.tags) : null, // tags are sent as an array
    tryout_new_status: data.tryoutNewStatus,
  }
}
