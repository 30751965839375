import { useQuery } from '@tanstack/react-query'
import { shiftTutorialKey } from './keys'
import { getShiftTutorial } from 'api/shift'

export function useShiftTutorial(shiftId: number, tutorialId?: number) {
  return useQuery({
    queryKey: shiftTutorialKey(shiftId, tutorialId),
    queryFn: () => getShiftTutorial(shiftId, tutorialId || 0),
    gcTime: 0,
  })
}
