import React, { FormEvent, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Box, Button, Message, Modal, ModalCtaContainer, toast } from 'ui'
import {
  CardElement,
  Elements,
  ElementsConsumer,
} from '@stripe/react-stripe-js'
import { uploadCardToken } from 'api/payment'
import { config } from 'config'

interface Props {
  open: boolean
  onClose: () => void
  onSuccess?: () => void
}

const stripePromise = loadStripe(config.stripePublishableKey)

export function AddCreditCard(props: Props) {
  const { open, onClose, onSuccess } = props
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const resetForm = () => {
    setError('')
    onClose()
  }

  return (
    <Modal
      title={'Add Credit Card'}
      open={open}
      onClose={resetForm}
      loading={loading}
    >
      <Elements stripe={stripePromise}>
        <ElementsConsumer>
          {({ elements, stripe }) => {
            const handleSubmit = (e: FormEvent) => {
              e.preventDefault()
              if (stripe && elements) {
                const cardElement = elements.getElement(CardElement)
                if (cardElement) {
                  setLoading(true)
                  stripe
                    .createToken(cardElement)
                    .then((response) => {
                      if (response.token?.id) {
                        uploadCardToken(response.token.id)
                          .then(() => {
                            toast.success('Your Credit Card has been added.')
                            if (onSuccess) {
                              onSuccess()
                            }
                            resetForm()
                          })
                          .catch((err) => {
                            setError(err.message)
                          })
                          .finally(() => {
                            setLoading(false)
                          })
                      } else {
                        setLoading(false)
                      }
                    })
                    .catch((error) => {
                      setError('Failed to add Credit Card. Try again later.')
                      setLoading(false)
                    })
                }
              }
            }

            return (
              <form onSubmit={handleSubmit}>
                <Box my={4}>
                  <CardElement />
                </Box>
                {error ? (
                  <Message mb={3} variant={'error'}>
                    {error}
                  </Message>
                ) : null}
                <ModalCtaContainer>
                  <Button type={'submit'}>Add Card</Button>
                </ModalCtaContainer>
              </form>
            )
          }}
        </ElementsConsumer>
      </Elements>
    </Modal>
  )
}
