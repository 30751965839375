import React from 'react'
import { Box, Text, Message, Flex } from 'ui'
import { Clock2Icon, MoonIcon } from 'lucide-react'
import { useTheme } from 'styled-components'
import { useShiftDate } from '../SchedulePage/useShiftDate'
import { differenceInHours } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { useShiftEditor } from 'pages/ShiftEditorPage/useShiftEditor'
import { getLocalTimezone } from 'lib/time'

export function BonusGuidance() {
  const { colors } = useTheme()
  const { shiftData } = useShiftEditor()
  const { startsAt } = useShiftDate()
  const now = new Date()

  if (!startsAt) {
    return null
  }

  const timezone = shiftData.schedule?.timezone || getLocalTimezone()
  const startsAtShiftTz = utcToZonedTime(startsAt, timezone)

  if (differenceInHours(startsAt, now) < 6) {
    return (
      <Message variant={'fixed'} hideIcon={true}>
        <Flex>
          <Box flexShrink={0} pr={2}>
            <Clock2Icon size={24} color={colors.primary} />
          </Box>
          <Box>
            <Text fontWeight={2}>Last-minute Shift</Text>
            <Box mt={1}>
              We recommend you add a bonus to attract more workers with short
              notice.
            </Box>
          </Box>
        </Flex>
      </Message>
    )
  }

  if (startsAtShiftTz.getHours() >= 22 || startsAtShiftTz.getHours() <= 5) {
    return (
      <Message variant={'fixed'} hideIcon={true}>
        <Flex>
          <Box flexShrink={0} pr={2}>
            <MoonIcon size={24} color={colors.primary} />
          </Box>
          <Box>
            <Text fontWeight={2}>Late Night Shift</Text>
            <Box mt={1}>
              We recommend adding a bonus to attract more workers to this
              late-night shift.
            </Box>
          </Box>
        </Flex>
      </Message>
    )
  }

  return null
}
