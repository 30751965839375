import * as zod from 'zod'

export const workApprovalSchema = zod.object({
  id: zod.coerce.number(), // handle numeric string
  startedAt: zod.string().nullable().optional(),
  completedAt: zod.string().nullable().optional(),
  supervisorMarkedNoShow: zod.coerce.boolean().optional(),
  e2wReview: zod
    .object({
      rating: zod.number().nullable().optional(),
      comment: zod.string().nullable().optional(),
      tags: zod.string().nullable().optional(),
    })
    .nullable()
    .optional(),
  finishedEarlyCategory: zod.string().nullable().optional(),
  finishedEarlyReason: zod.string().nullable().optional(),
  tryoutNewStatus: zod.string().nullable().optional(),
})

export type WorkApprovalData = zod.infer<typeof workApprovalSchema>

export const bulkWorkApprovalSchema = zod.array(workApprovalSchema)

export type BulkWorkApprovalForm = zod.infer<typeof bulkWorkApprovalSchema>
