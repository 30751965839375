import React, { useEffect } from 'react'
import { track } from 'lib/amplitude'
import { Flex, Box, Text } from 'ui'
import { OnboardingForm } from './OnboardingForm'
import { Photography } from '../Photography'
import wwLogo from 'assets/ww_logo_white.svg'
import { useSearchParams } from 'react-router-dom'
import { OktaOnboardingForm } from './OktaOnboardingForm'

export function OnboardingPage() {
  const [searchParams] = useSearchParams()
  const useOkta = searchParams.get('okta')

  useEffect(() => {
    track('impression, invited_user_landing')
  }, [])

  return (
    <Flex height={'100vh'} flexDirection={'row'}>
      <Box
        position={'relative'}
        width={[0, 0, 0, 0, 750]}
        display={['none', 'none', 'none', 'none', 'block']}
      >
        <Photography />
        <Box position={'absolute'} top={100} left={60} width={350}>
          <Text fontWeight={2} fontSize={48} color={'white'} mb={3}>
            Welcome to WorkWhile.
            <br />
            Let's get you set up.
          </Text>
          <img src={wwLogo} alt="WW Logo" height={32} />
        </Box>
      </Box>
      <Box
        display={'grid'}
        flex={1}
        minHeight={'100vh'}
        p={[3, 3, 3, 3, 4]}
        overflow={'auto'}
      >
        {useOkta ? <OktaOnboardingForm /> : <OnboardingForm />}
      </Box>
    </Flex>
  )
}
