import { useAuthContext } from 'components/auth'
import { useMutation } from '@tanstack/react-query'
import { TutorialCategory } from 'api/shift/typings'
import {
  createLocationTutorial,
  CreateLocationTutorialRequest,
  editLocationTutorial,
} from 'api/company'

type MutationPayload = Pick<
  CreateLocationTutorialRequest,
  'content' | 'quiz' | 'notifyWorkers'
>

export function useSaveLocationTutorialMutation(options: {
  locationId: number
  tutorialId?: number
  category?: TutorialCategory
  onSuccess?: (tutorialId?: number) => void
  onError?: (error: Error) => void
}) {
  const { locationId, tutorialId, category, onSuccess, onError } = options
  const companyId = useAuthContext().company?.id as number
  return useMutation({
    mutationFn: async (request: MutationPayload) => {
      if (tutorialId) {
        return editLocationTutorial({
          ...request,
          companyId,
          locationId,
          tutorialId,
        })
      }
      if (category) {
        return createLocationTutorial({
          ...request,
          companyId,
          locationId,
          category,
        })
      }
    },
    onSuccess,
    onError,
  })
}
