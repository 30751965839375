import { useQuery, useQueryClient } from '@tanstack/react-query'
import { chargeLimitsKey } from './keys'
import { getChargeLimits } from 'api/payment'

export function useChargeLimits() {
  return useQuery({
    queryKey: chargeLimitsKey,
    queryFn: getChargeLimits,
  })
}

export function useInvalidateChargeLimits() {
  const queryClient = useQueryClient()

  return {
    invalidate: () =>
      queryClient.invalidateQueries({ queryKey: chargeLimitsKey }),
  }
}
