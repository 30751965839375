import React, { useEffect, useState } from 'react'
import { Message } from '@workwhile/ui'
import { useAuthContext } from 'components/auth'

export const CompanySwitchDetect = () => {
  const { company } = useAuthContext()
  const [message, setMessage] = useState<string | null>(null)

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'storedCompanyId') {
        const newCompanyId = event.newValue
        if (newCompanyId && newCompanyId !== company?.id?.toString()) {
          setMessage(
            'The company ID has changed in another tab. Please refresh this tab to reflect the changes.'
          )
        }
      }
    }

    window.addEventListener('storage', handleStorageChange)
    return () => window.removeEventListener('storage', handleStorageChange)
  }, [company])

  useEffect(() => {
    if (company?.id) {
      localStorage.setItem('storedCompanyId', company.id.toString())
    }
  }, [company])

  return (
    <>
      {message && (
        <Message
          variant="warning"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            zIndex: 10000,
            whiteSpace: 'normal',
            padding: '10px',
            boxSizing: 'border-box',
          }}
        >
          {message}
        </Message>
      )}
    </>
  )
}
