import React, { ReactNode, useMemo, useRef, useState } from 'react'
import { Modal } from '@workwhile/ui'
import { useSaveLocationMutation } from 'queries/company'
import { useCompanyLocation } from 'hooks/useCompanyLocation'
import { EditLocationForm } from './EditLocationForm'
import { DEFAULT_CLOCK_IN_RADIUS_FT, LocationForm } from './schema'

interface Props {
  locationId?: number
  trigger?: ReactNode
  onLocationSaved?: (locationId: number) => void
}

export function AddLocation(props: Props) {
  const { locationId, trigger, onLocationSaved } = props
  const location = useCompanyLocation(locationId)
  const [error, setError] = useState<Error | null>(null)
  const editLocationData = useMemo(() => {
    if (!location) return
    return {
      id: location.id,
      name: location.name,
      address: location.address?.street,
      unit: location.address?.unit,
      city: location.address?.city,
      state: location.address?.state,
      zipCode: location.address?.zip,
      lat: location.address?.lat,
      long: location.address?.long,
      arrivalInstructions: location.arrivalInstructions,
      clockInLat: location.clockInLat ?? location.address?.lat,
      clockInLong: location.clockInLong ?? location.address?.long,
      clockInRadius: location.clockInRadius ?? DEFAULT_CLOCK_IN_RADIUS_FT,
    } as unknown as LocationForm & { id: number }
  }, [location])
  const closeModalRef = useRef<() => void>()
  const { mutate: saveLocation, isPending } = useSaveLocationMutation({
    locationId,
    onSuccess: (addedId) => {
      if (onLocationSaved) {
        if (locationId) {
          onLocationSaved(locationId)
        }

        if (addedId) {
          onLocationSaved(addedId)
        }
      }

      if (closeModalRef.current) {
        closeModalRef.current()
      }
    },
    onError: (err) => {
      setError(err)
    },
  })

  return (
    <Modal
      trigger={trigger}
      loading={isPending}
      title={locationId ? 'Edit Location' : 'Add New Location'}
      okText={locationId ? 'Save Location' : 'Add Location'}
      width={[1, 920]}
    >
      <Modal.Consumer>
        {({ closeModal }) => (
          <EditLocationForm
            data={editLocationData}
            onCancel={closeModal}
            error={error}
            onSubmit={(data) => {
              closeModalRef.current = closeModal
              saveLocation(data)
            }}
          />
        )}
      </Modal.Consumer>
    </Modal>
  )
}
