import React, { useEffect, useMemo } from 'react'
import { ChatMessage } from 'api/message'
import { Avatar, Box, Flex, Link, Text } from 'ui'
import { MessageCircleIcon } from 'lucide-react'
import { toTitleCase } from 'lib/legacy_util'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useMarkAsReadMutation } from 'queries/message/useMarkAsReadMutation'

interface Props {
  data: ChatMessage
}

const StyledMessage = styled(Flex).attrs({
  flexDirection: 'row',
  alignItems: 'center',
  p: 3,
  borderBottomStyle: 'solid',
  borderBottomWidth: 1,
  borderBottomColor: 'neutrals.100',
})`
  ${({ theme: { colors } }) => `
    &:hover {
      background-color: ${colors.primaries[50]};
      text-decoration: none;
    }
  `}
`

const IconWrapper = styled(Box).attrs({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
})<{ $unread: boolean }>`
  ${({ theme: { colors }, $unread }) => `
    position: relative;
    flex-shrink: 0;
    
    ${
      $unread
        ? `
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: -0.1rem;
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        background-color: ${colors.error};
      }
    `
        : ''
    }
  `}
`

export function MessageListItem(props: Props) {
  const params = useParams<{ workerId?: string }>()
  const selectedWorkerId = params.workerId ? Number(params.workerId) : undefined
  const { data } = props
  const worker = useMemo(() => {
    const [from] = data.direction.split('2')
    if (from === 'w') {
      return data.fromUser
    }

    return data.toUser
  }, [data])
  const hasUnread = data.unreadCount > 0
  const { mutate: markAsRead } = useMarkAsReadMutation(Number(worker.id))

  useEffect(() => {
    if (selectedWorkerId === Number(worker.id)) {
      // mark message as read
      markAsRead()
    }
  }, [selectedWorkerId, worker])

  return (
    <StyledMessage
      as={Link}
      to={`/messages/${worker.id}`}
      backgroundColor={
        Number(worker.id) === selectedWorkerId ? 'primaries.50' : 'white'
      }
    >
      <Avatar
        size={'medium'}
        user={{
          userId: worker.id.toString(),
          name: worker.name,
          photoUrl: worker.profilePicUrl,
        }}
      />
      <Box
        ml={2}
        color={hasUnread ? 'text' : 'neutrals.300'}
        fontWeight={hasUnread ? 2 : 0}
      >
        <Text>{worker.name}</Text>
        <Box mt={1} display={'inline-flex'} alignItems={'center'}>
          <IconWrapper $unread={hasUnread}>
            <MessageCircleIcon size={16} />
          </IconWrapper>
          <Text ml={1} maxLines={1} fontSize={1}>
            {toTitleCase(data.fromUser.name)}:&nbsp;
            {data.message}
          </Text>
        </Box>
      </Box>
    </StyledMessage>
  )
}
