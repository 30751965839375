import { useContext } from 'react'
import { ShiftEditorContext } from './ShiftEditorProvider'

export function useShiftEditor() {
  const context = useContext(ShiftEditorContext)
  if (!context) {
    throw new Error('useShiftEditor must be used within a ShiftEditorProvider')
  }
  return context
}
