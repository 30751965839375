import React from 'react'
import styled from 'styled-components'
import { Box, Text } from 'ui'

export const FillRateLegend = styled(Box).attrs<{
  color: string
  text: string
}>((props) => ({
  display: 'inline-flex',
  alignItems: 'center',
  children: (
    <>
      <Text color={props.color} fontSize={18}>
        ◼
      </Text>
      <Text ml={1} color={'neutrals.400'} fontSize={1}>
        {props.text}
      </Text>
    </>
  ),
}))``
