import React, { useEffect } from 'react'
import { TimelineShift } from 'api/shift'
import { useCancellationPolicy } from './useCancellationPolicy'
import { useWorkerStats } from './useWorkerStats'
import { Box, Message, Pluralize, BaseLink, Text } from 'ui'
import { getLocalTimezone } from 'lib/time'
import { formatInTimeZone } from 'date-fns-tz'
import { DEFAULT_CANCELLATION_POLICY_WINDOW } from 'lib/shift'
import { track } from 'lib/amplitude'

const policyUrl =
  'https://storage.googleapis.com/workwhile-terms-docs/static/ww-customer-agreement/ww_customer_agreement_20201204.pdf'

export function CancelModalContent(props: { shift: TimelineShift }) {
  const { shift } = props
  const { policyWindow, isStandard } = useCancellationPolicy(shift)
  const { workers, hasWorkers } = useWorkerStats(shift)

  useEffect(() => {
    const policyData = {
      hasWorkers,
      policyWindowCutoffTimeHours: policyWindow?.cutoffTimeHours,
      policyWindowChargePercentage: policyWindow?.chargePercentage,
    }
    track('impression, cancel_modal_content', policyData)
  }, [hasWorkers, policyWindow])

  const timezone = shift.location?.address?.timezone ?? getLocalTimezone()
  const cancellationPolicyWindows =
    shift.cancellationPolicy?.policyWindows ||
    DEFAULT_CANCELLATION_POLICY_WINDOW

  const scheduledWorkersNotice = hasWorkers ? (
    <Box mb={3}>
      <Pluralize
        count={workers.length}
        oneText={'worker is'}
        manyText={'workers are'}
      />
      &nbsp;scheduled and will be notified of this cancellation.
    </Box>
  ) : null

  return (
    <>
      <Box mb={3}>
        {shift.position?.name} starting at{' '}
        {formatInTimeZone(shift.startsAt, timezone, 'EEEE MMM dd, hh:mma zzz')}
      </Box>
      {hasWorkers && policyWindow ? (
        <>
          <Message variant={'warning'} mb={3}>
            Shift is within {policyWindow.cutoffTimeHours} hours cancellation
            window, you will be charged {policyWindow.chargePercentage}% if you
            cancel this shift.
          </Message>
          {scheduledWorkersNotice}
          <Box mb={3}>
            <Text fontWeight="bold" mb={2}>
              Your cancellation policy is as follows:
            </Text>
            <ul
              style={{
                listStyle: 'disc',
                marginLeft: '1em',
                marginBottom: '1em',
              }}
            >
              {cancellationPolicyWindows.map((policy) => {
                return (
                  <li
                    key={`policy-${policy.cutoffTimeHours}-${policy.chargePercentage}`}
                  >
                    Cancellations made within {policy.cutoffTimeHours} hours of
                    the shift start time will be charged{' '}
                    {policy.chargePercentage}% of the shift cost.
                  </li>
                )
              })}
            </ul>
          </Box>
          {isStandard ? (
            <Box mt={3}>
              <BaseLink
                onClick={() => {
                  window.open(policyUrl, '_blank')
                }}
                href={policyUrl}
                target={'_blank'}
                rel="noreferrer"
              >
                See policy
              </BaseLink>
            </Box>
          ) : null}
        </>
      ) : (
        scheduledWorkersNotice
      )}
    </>
  )
}
