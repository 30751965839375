import { api, graphql } from 'api'
import { path } from 'ramda'
import { Location } from 'typings/common_defs'
import {
  Tutorial,
  TutorialCategory,
  MarkdownContent,
  QuizQuestion,
} from 'api/shift/typings'
import { toSnakecaseKeys } from 'lib/string'

export const AllLocationTutorialCategories = [
  TutorialCategory.ArrivalAndParking,
  TutorialCategory.ClockIn,
  TutorialCategory.ClockOut,
]

export async function getCompanyLocations() {
  const data = await graphql(
    `company {
          locations {
            id, 
            name, 
            arrivalInstructions, 
            clockInLat, 
            clockInLong, 
            clockInRadius, 
            address {
              city, 
              state, 
              street, 
              unit, 
              zip, 
              lat, 
              long, 
              timezone
            },
            tutorials {
              id,
              name,
              category,
              content,
              quiz
            }
          }
        }`,
    'current_company'
  )

  return path(['data', 'data', 'company', 'locations'], data) as Location[]
}

export interface LocationRequest {
  name: string
  street: string
  city: string
  unit?: string
  zip: string
  state: string
  arrivalInstructions?: string
  clockInLat?: number
  clockInLong?: number
  clockInRadius?: number
}

export async function createCompanyLocation(
  companyId: number,
  data: LocationRequest
) {
  const response = await api.post(
    `/company/${companyId}/location`,
    toSnakecaseKeys(data)
  )
  return path(['data', 'id'], response)
}

export async function updateCompanyLocation(
  companyId: number,
  locationId: number,
  data: LocationRequest
) {
  await api.put(
    `/company/${companyId}/location/${locationId}`,
    toSnakecaseKeys(data)
  )
}

export interface W2MinimumLunchLengthOptions {
  companyId: number
  locationId?: number
  startTime: string
  endTime: string
}

export async function getW2MinimumLunchLength(
  options: W2MinimumLunchLengthOptions
): Promise<number> {
  const { companyId, locationId, startTime, endTime } = options

  const data = await api.get(
    `/company/${companyId}/location/${locationId}/minimum_lunch_length`,
    {
      params: {
        start_time: startTime,
        end_time: endTime,
      },
    }
  )

  return path(['data', 'minimum_lunch_length'], data)
}

export async function getLocationTutorials(locationId: number) {
  const data = await getCompanyLocations()
  const location = data.find(
    (location) => String(location.id) === String(locationId)
  )
  const tutorials = location?.tutorials?.map(
    (
      tutorial: Omit<Tutorial, 'content' | 'quiz'> & {
        content?: string
        quiz?: string
      }
    ) => ({
      ...tutorial,
      content: tutorial.content ? JSON.parse(tutorial.content) : [],
      quiz: tutorial.quiz ? JSON.parse(tutorial.quiz) : tutorial.quiz,
    })
  )

  return tutorials
}

export async function getLocationTutorial(
  locationId: number,
  tutorialId: number
) {
  const tutorials = await getLocationTutorials(locationId)
  return (tutorials || []).find(
    (tutorial) => tutorial.id.toString() === tutorialId.toString()
  )
}

export interface CreateLocationTutorialRequest {
  companyId: number
  locationId: number
  category: TutorialCategory
  content: MarkdownContent[]
  quiz?: QuizQuestion[]
  notifyWorkers?: boolean
}

export async function createLocationTutorial(
  request: CreateLocationTutorialRequest
): Promise<number> {
  const { companyId, locationId, category, content, quiz, notifyWorkers } =
    request
  const data = await api.post(
    `/company/${companyId}/location/${locationId}/tutorial`,
    {
      category,
      content,
      quiz,
      notify_workers: notifyWorkers,
    }
  )
  const tutorialId = path(['data', 'tutorial_id'], data)
  return tutorialId
}

export type EditLocationTutorialRequest = Omit<
  CreateLocationTutorialRequest,
  'category'
> & {
  tutorialId: number
}

export async function editLocationTutorial(
  request: EditLocationTutorialRequest
): Promise<number> {
  const { companyId, locationId, tutorialId, content, quiz, notifyWorkers } =
    request
  const data = await api.put(
    `/company/${companyId}/location/${locationId}/tutorial/${tutorialId}`,
    {
      content,
      quiz,
      notify_workers: notifyWorkers,
    }
  )
  return path(['data', 'id'], data)
}

export interface RemoveLocationTutorialRequest {
  companyId: number
  locationId: number
  tutorialId: number
}

export async function removeLocationTutorial(
  request: RemoveLocationTutorialRequest
) {
  const { companyId, locationId, tutorialId } = request
  await api.delete(
    `/company/${companyId}/location/${locationId}/tutorial/${tutorialId}`
  )
}
