import React from 'react'
import { Box, Flex, Text } from '@workwhile/ui'
import { CalendarViewContextState } from '../../context'
import { ShiftCell } from './ShiftCell'

type ShiftRowProps = {
  shiftsByDate: CalendarViewContextState['state']['shiftsByDate']
}
export const ShiftRow = ({ shiftsByDate }: ShiftRowProps) => {
  return (
    <tr>
      <Box as="th" scope="row" verticalAlign="middle">
        <Text fontSize={16}>Shifts</Text>
      </Box>
      {shiftsByDate.map((data) => {
        if (!data.shifts) {
          return <Box as="td" p={4} key={`shifts-${data.date}-empty-cell`} />
        }

        return (
          <Box as="td" key={`shifts-wrapper-${data.date}`} verticalAlign="top">
            <Flex
              flexDirection="column"
              p={2}
              style={{
                gap: 8,
              }}
              alignItems="flex-start"
            >
              {data.shifts.map((shift) => (
                <ShiftCell key={`shifts-work-${shift.id}`} shift={shift} />
              ))}
            </Flex>
          </Box>
        )
      })}
    </tr>
  )
}
