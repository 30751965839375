import { graphql } from './graphql'
import { path } from 'ramda'

export enum PermissionEnum {
  ManageShifts = 'manageShift',
  ManageBonus = 'manageBonus',
  ManageRoles = 'manageRoles',
  PaymentAccess = 'paymentAccess',
  MessageWorker = 'messageWorker',
  WorkTimeAdjustment = 'workTimeAdjustment',
  ManageWorker = 'manageWorker',
  RequestSpecificWorker = 'requestSpecificWorker',
}

export type UserPermissions = {
  name: string
  description: string
} & {
  [key in PermissionEnum]: boolean
}

export async function getUserPermissions() {
  const data = await graphql(
    `me {
          companyRolePerm {
            name,
            description,
            manageRoles,
            manageBonus
            paymentAccess
            messageWorker
            workTimeAdjustment
            manageWorker
            manageShift
            requestSpecificWorker
            manageWorker
          }
        }`,
    'user_permissions'
  )

  return path(
    ['data', 'data', 'me', 'companyRolePerm'],
    data
  ) as UserPermissions
}
