import { TimelineShift } from 'api/shift'
import React, { useMemo } from 'react'
import { useWorkerStats } from './useWorkerStats'
import { Box, Button, Flex, Message } from 'ui'
import { PermissionEnum } from 'api/userPermissions'
import { AuthGuard } from 'components/auth'
import { PostBackupShift } from 'components/PostBackupShift'

export function BackupShiftAlert(props: { shift: TimelineShift }) {
  const { shift } = props
  const { workers, workersNeeded } = useWorkerStats(shift)

  const openSpots = useMemo(() => {
    return workersNeeded - workers.length
  }, [shift])

  if (openSpots <= 0 || !shift.recommendBackupShift) {
    return null
  } else {
    return (
      <Message variant="warning" systemLevel width={1} mt={3}>
        <Flex flexDirection={'row'} flexWrap={'wrap'}>
          <Box width={[1, 'auto']} flex={['auto', 1]} pr={[0, 2]} pb={[2, 0]}>
            Unfortunately, your shift has not filled. To schedule more workers,
            we recommend posting a backup shift that starts two hours later to
            give workers more time to sign up. The open spots from your current
            shift will move to the backup shift.
          </Box>
          <Box width={[1, 'auto']}>
            <AuthGuard.Permission value={PermissionEnum.ManageShifts}>
              <PostBackupShift
                shift={shift}
                trigger={
                  <Button kind={'small'} width={[150, 100, 150]}>
                    Post Shift
                  </Button>
                }
              />
            </AuthGuard.Permission>
          </Box>
        </Flex>
      </Message>
    )
  }
}
