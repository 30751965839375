import React, { Fragment } from 'react'
import App from './App'
import { createRoot } from 'react-dom/client'
import { initAmplitude } from 'lib/amplitude'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { AuthProvider } from 'components/auth'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { config } from 'config'
import { TopLevelErrorBoundary } from 'components/ErrorBoundary'

initAmplitude()

Bugsnag.start({
  releaseStage: config.bugsnagReleaseStage,
  enabledReleaseStages: ['staging', 'prod'], // stop sending local errors to bugsnag
  appVersion: config.appVersion,
  apiKey: config.bugsnagApiKey,
  plugins: [new BugsnagPluginReact(React)],
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        // dont retry on these statuses
        if (['403', '404'].includes((error as Error).name)) {
          return false
        }

        return failureCount < 3
      },
    },
  },
})

const container = document.getElementById('app-container')

if (container) {
  const root = createRoot(container)
  const bsReactPlugin = Bugsnag.getPlugin('react')
  const RootNode = bsReactPlugin
    ? bsReactPlugin.createErrorBoundary()
    : Fragment

  root.render(
    <RootNode FallbackComponent={TopLevelErrorBoundary}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </QueryClientProvider>
    </RootNode>
  )
}
