import { useEffect, useState } from 'react'

const FEET_TO_METER_FACTOR = 0.3048

export interface MapCircleProps {
  map: google.maps.Map
  radius: number // radius in meters
  center: { lat: number; lng: number }
  onCenterChange?: (center: { lat: number; lng: number }) => void
}

export const MapCircle = (props: MapCircleProps) => {
  const { map, radius, center, onCenterChange } = props
  const [circle, setCircle] = useState<google.maps.Circle | null>(null)
  const [initialCenter] = useState(center)

  useEffect(() => {
    const circle = new google.maps.Circle({
      map,
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.3,
      draggable: true,
      center: initialCenter,
    })

    setCircle(circle)

    const handleCenterChanged = () => {
      const center = circle.getCenter()?.toJSON()
      if (onCenterChange && center) {
        onCenterChange(center)
      }
    }

    circle.addListener('center_changed', handleCenterChanged)

    return () => {
      if (circle) {
        google.maps.event.clearListeners(circle, 'center_changed')
        circle.setMap(null)
      }
    }
  }, [map, initialCenter])

  useEffect(() => {
    if (circle && radius) {
      circle.setRadius(Number(radius))
    }
  }, [radius, circle])

  useEffect(() => {
    if (circle && center) {
      const currentCenter = circle.getCenter()
      if (currentCenter) {
        if (
          currentCenter.lat().toFixed(4) !== center.lat.toFixed(4) &&
          currentCenter.lng().toFixed(4) !== center.lng.toFixed(4)
        ) {
          circle.setCenter(center)
        }
      }
    }
  }, [circle, center])

  return null
}
