import React from 'react'
import { getPillItems } from 'lib/review'
import { Work } from 'typings/common_defs'
import { useAssignedWorkRow } from '../useAssignedWorkerRow'
import { Flex, Label } from 'ui'
import { useAppTheme } from '@workwhile/ui'

interface Props {
  work: Work
}

export const AssignedWorkerRatingTags = ({ work }: Props) => {
  const { data, setValue } = useAssignedWorkRow(work.id)
  const pillItems = getPillItems(data?.e2wReview?.rating)
  const { colors } = useAppTheme()
  const tagsKeyPath = 'e2wReview.tags'

  const handleLabelClick = (item) => {
    // if the tag is already selected, remove it
    // if the tag is not selected, add it
    const originalTags = data?.e2wReview?.tags
      ? JSON.parse(data?.e2wReview?.tags)
      : []

    if (originalTags.includes(item)) {
      const newTags = originalTags.filter((tag) => tag !== item)
      setValue(tagsKeyPath, JSON.stringify(newTags))
    } else {
      const newTags = [...originalTags, item]
      setValue(tagsKeyPath, JSON.stringify(newTags))
    }
  }

  return (
    <Flex flexDirection="row" flexWrap="wrap" mb={3}>
      {pillItems.map((pillItem, index) => {
        const isSelected: boolean = data?.e2wReview?.tags
          ? JSON.parse(data?.e2wReview?.tags)?.includes(pillItem)
          : false
        const selectedStyle = {
          backgroundColor: colors.primaries[200],
          color: 'white',
          cursor: 'pointer',
        }
        return (
          <Label
            style={
              isSelected
                ? selectedStyle
                : {
                    cursor: 'pointer',
                    backgroundColor: 'white',
                    color: colors.primaries[200],
                  }
            }
            variant="primary"
            size="small"
            key={`${pillItem}-${index}`}
            mr={1}
            mb={1}
            onClick={() => handleLabelClick(pillItem)}
          >
            {pillItem}
          </Label>
        )
      })}
    </Flex>
  )
}
