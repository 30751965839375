import styled from 'styled-components'
import { IconButton, Flex } from '@workwhile/ui'

export const RemoveButton = styled(IconButton).attrs({
  hoverBackgroundColor: 'transparent',
  hoverColor: 'error',
})`
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  z-index: 1;
`

export const PageWrapper = styled(Flex).attrs({
  position: 'relative',
  width: 90,
  height: 120,
  borderRadius: 'standard',
  overflow: 'hidden',
  borderStyle: 'solid',
  flexShrink: 0,
})`
  cursor: pointer;

  &:hover {
    ${RemoveButton} {
      display: block;
    }
  }
`

PageWrapper.defaultProps = {
  borderWidth: 1,
  borderColor: 'neutrals.200',
}
