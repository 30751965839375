import React from 'react'
import { Box } from 'ui'
import { LogsProvider } from './LogsProvider'
import { RangePicker } from './RangePicker'
import { LogsContent } from './LogsContent'

export function Logs() {
  return (
    <LogsProvider>
      <Box py={3}>
        <RangePicker />
      </Box>
      <LogsContent />
    </LogsProvider>
  )
}
