import React from 'react'
import { Box, Heading, Loading, Table, Text, useResponsiveValue } from 'ui'
import { useCompanyPendingInvites } from 'queries/company'
import { pendingColumns } from './columns'

export function PendingInvites() {
  const { data, isLoading } = useCompanyPendingInvites()
  const tableMode = useResponsiveValue(['borderless', 'standard'])
  const columnsToUse = useResponsiveValue([1, 2])

  return (
    <Box>
      <Heading level={3}>Pending Invites</Heading>
      {isLoading ? <Loading /> : null}
      {data && data.length > 0 ? (
        <Table
          variant={tableMode as 'borderless' | 'standard'}
          columns={pendingColumns.slice(0, columnsToUse)}
          data={data}
        />
      ) : null}
      {data && data.length === 0 ? (
        <Text color={'lightText'} mb={3}>
          Pending invites will appear here.
        </Text>
      ) : null}
    </Box>
  )
}
