import { useQuery } from '@tanstack/react-query'
import { positionTemplateGroupsKey } from './keys'
import { getPositionTemplateGroups } from 'api/company'

export function usePositionTemplateGroups() {
  return useQuery({
    queryKey: positionTemplateGroupsKey,
    queryFn: getPositionTemplateGroups,
  })
}
