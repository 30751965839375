import { useAuthContext } from 'components/auth'
import { useMutation } from '@tanstack/react-query'
import { TutorialCategory } from 'api/shift/typings'
import {
  createShiftTutorial,
  CreateShiftTutorialRequest,
  editShiftTutorial,
} from 'api/shift'

type MutationPayload = Pick<
  CreateShiftTutorialRequest,
  'content' | 'quiz' | 'propagate' | 'notifyWorkers'
>

export function useSaveShiftTutorialMutation(options: {
  shiftId: number
  tutorialId?: number
  category?: TutorialCategory
  onSuccess?: (tutorialId?: number) => void
  onError?: (error: Error) => void
}) {
  const { shiftId, tutorialId, category, onSuccess, onError } = options
  const companyId = useAuthContext().company?.id as number
  return useMutation({
    mutationFn: async (request: MutationPayload) => {
      if (tutorialId) {
        return editShiftTutorial({ ...request, companyId, shiftId, tutorialId })
      }
      if (category) {
        return createShiftTutorial({ ...request, companyId, shiftId, category })
      }
    },
    onSuccess,
    onError,
  })
}
