import React from 'react'
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from '@react-pdf/renderer'
import { isBefore } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { getShiftTimezone, shouldShowClockInOutCodes } from 'lib/shift'
import { useAppTheme } from '@workwhile/ui'
import { getAddressString } from 'lib/legacy_util'
import { getSupervisorListAsString } from 'lib/supervisor'
import { getAssignedWork } from 'lib/work'
import { TimelineShift } from 'api/shift'
import { CompanyInfo } from 'api/company'

interface Props {
  shift: TimelineShift
  company: CompanyInfo
}

export const ShiftDetailPrintableRosterPDF = ({ shift, company }: Props) => {
  const { colors } = useAppTheme()
  if (!shift) return null

  const positionDisplay = shift.position ? shift.position.name : 'Worker'

  const timezone = getShiftTimezone(shift)
  const currentDate = new Date()
  const pageGenerationTimestamp = formatInTimeZone(
    currentDate,
    timezone,
    'EEEE MMMM d, yyyy h:mmaaa zzz'
  )

  // Convert the given times to the specific timezone
  const startsAtDate = new Date(shift.startsAt)
  const endsAtDate = new Date(shift.endsAt)

  // Format the times
  const startTime = formatInTimeZone(startsAtDate, timezone, 'h:mmaaa')
  const endTime = formatInTimeZone(endsAtDate, timezone, 'h:mmaaa zzz')

  // Determine if the shift is overnight
  const overnight = isBefore(startsAtDate, endsAtDate) ? ' (overnight)' : ''

  const unpaidBreak = shift.lunchLength
    ? `Includes ${shift.lunchLength}min unpaid break`
    : null

  const locationDisplay = shift.locationless
    ? 'Locationless'
    : getAddressString(shift?.location?.address, 'street-city-state')

  const supervisorDisplay = getSupervisorListAsString(shift)

  const workList = shift.work || []
  const assignedWork = getAssignedWork(workList)
  const numWorkersAssigned = assignedWork.length
  const sortedWork = assignedWork.sort((a, b) =>
    a?.worker?.name &&
    b?.worker?.name &&
    b.worker.name.toLowerCase() < a.worker.name.toLowerCase()
      ? 1
      : -1
  )

  const rows = sortedWork.map((w) => {
    const clockedIn = w.startedAt
      ? formatInTimeZone(
          new Date(w.startedAt),
          timezone,
          'yyyy-MM-dd h:mmaaa zzz'
        )
      : null

    const clockedOut = w.completedAt
      ? formatInTimeZone(
          new Date(w.completedAt),
          timezone,
          'yyyy-MM-dd h:mmaaa zzz'
        )
      : null

    return (
      <View style={[styles.tableRow, { height: 60 }]} key={w.id}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{w?.worker?.name}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{clockedIn}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{clockedOut}</Text>
        </View>
        <View style={[styles.tableCol, { width: '40%' }]}>
          <Text style={styles.tableCell}>
            {w.supervisorMarkedNoShow ? 'Supervisor Marked No Show' : ''}
          </Text>
        </View>
      </View>
    )
  })

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.header}>
          {company?.logoUrl ? (
            <Image src={company?.logoUrl} style={styles.logo} cache={false} />
          ) : null}
        </View>
        <View style={styles.subheader}>
          <View style={styles.warningView}>
            <Text style={styles.warningViewText}>
              This roster was generated on {pageGenerationTimestamp}. Roster
              details may have changed since then. To view the latest details
              please visit https://business.workwhilejobs.com.
            </Text>
          </View>
        </View>
        <View style={styles.subheader}>
          <View>
            <Text style={[styles.text, { fontSize: 24 }]}>
              {positionDisplay}
            </Text>
            <Text style={styles.text}>
              {formatInTimeZone(startsAtDate, timezone, 'EEEE, MMMM d, yyyy')} ·{' '}
              {startTime} - {endTime}
              {overnight}
            </Text>
            {unpaidBreak ? (
              <Text style={styles.text}>{unpaidBreak}</Text>
            ) : null}
            <Text style={styles.text}>{locationDisplay}</Text>
            <Text style={styles.text}>{supervisorDisplay}</Text>
            <Text style={styles.text}>
              Scheduled{' '}
              {shift?.workersNeeded && numWorkersAssigned > shift.workersNeeded
                ? shift?.workersNeeded
                : numWorkersAssigned}{' '}
              of {shift?.workersNeeded}{' '}
              {shift?.workersNeeded !== 1 ? 'workers' : 'worker'}
            </Text>
          </View>
          {shouldShowClockInOutCodes(shift) ? (
            <View style={{ flexDirection: 'row' }}>
              {shift.clockInCode ? (
                <View
                  style={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginRight: 10,
                  }}
                >
                  <Text style={[styles.text, { color: colors.neutrals[200] }]}>
                    Clock In Code
                  </Text>
                  <View
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: 12,
                    }}
                  >
                    <Text style={styles.text}>{shift.clockInCode}</Text>
                    &nbsp;&nbsp;
                  </View>
                </View>
              ) : null}
              {shift.clockOutCode ? (
                <View
                  style={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginRight: 10,
                  }}
                >
                  <Text style={[styles.text, { color: colors.neutrals[200] }]}>
                    Clock Out Code
                  </Text>
                  <View
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: 12,
                    }}
                  >
                    <Text style={styles.text}>{shift.clockOutCode}</Text>
                    &nbsp;&nbsp;
                  </View>
                </View>
              ) : null}
            </View>
          ) : null}
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Name</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Clocked In</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Clocked Out</Text>
            </View>
            <View style={[styles.tableColHeader, { width: '40%' }]}>
              <Text style={styles.tableCellHeader}></Text>
            </View>
          </View>
          {rows}
        </View>
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  body: {
    padding: 20,
  },
  table: {
    display: 'flex',
    flexDirection: 'column', // Explicitly set to column
    width: '100%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: 'row', // Explicitly set to row
  },
  tableColHeader: {
    flex: 1, // This ensures equal width across columns
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    flex: 1, // Same width as tableColHeader
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 500,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  subheader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 20,
  },
  logo: {
    height: 40,
    width: 40,
  },
  text: {
    fontSize: 11,
    marginBottom: 5,
  },
  textWithMargin: {
    fontSize: 12,
    marginTop: 10,
  },
  textRight: {
    fontSize: 12,
    textAlign: 'right',
  },
  warningView: {
    flex: 1,
    padding: 10,
    border: '1px solid',
    borderRadius: 2,
    backgroundColor: '#fff3cd',
    borderColor: '#ffeeba',
    width: '100%',
  },
  warningViewText: {
    color: '#856404',
    fontSize: 10,
  },
})
