import { useQuery } from '@tanstack/react-query'
import { WorkerAvailabilityRequest, getWorkerAvailability } from 'api/worker'
import { workerAvailabilityKey } from './keys'
import { useAuthContext } from 'components/auth'

export function useWorkerAvailability({
  workerIds,
  hypotheticalShifts,
  shiftIds,
  enabled = true,
}: WorkerAvailabilityRequest & { enabled?: boolean }) {
  const { company } = useAuthContext()
  return useQuery({
    queryKey: workerAvailabilityKey({
      workerIds,
      hypotheticalShifts,
      shiftIds,
    }),
    queryFn: async () => {
      try {
        if (!company?.id || !workerIds?.length) {
          return []
        }

        const result = await getWorkerAvailability({
          companyId: company.id,
          workerIds,
          hypotheticalShifts,
          shiftIds,
        })

        return result || []
      } catch (error) {
        return []
      }
    },
    staleTime: 2 * 60 * 1000,
    retry: false,
    enabled,
  })
}
