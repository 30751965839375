import React from 'react'
import { Page } from 'pages/layout'
import {
  ShiftEditorContext,
  ShiftEditorProvider,
} from 'pages/ShiftEditorPage/ShiftEditorProvider'
import { Route, Routes } from 'react-router'
import { StepIndicator } from './StepIndicator'
import { StepChecker } from './StepChecker'

export function ShiftEditorPage() {
  return (
    <ShiftEditorProvider>
      <ShiftEditorContext.Consumer>
        {(context) => {
          return (
            <Page fullHeight={true}>
              <StepIndicator />
              <Routes>
                {context?.steps.map((step) => (
                  <Route
                    key={step.route}
                    path={step.route}
                    element={
                      <StepChecker name={step.name}>{step.page}</StepChecker>
                    }
                  />
                ))}
              </Routes>
            </Page>
          )
        }}
      </ShiftEditorContext.Consumer>
    </ShiftEditorProvider>
  )
}
