import { api, graphql } from 'api'
import { path } from 'ramda'
import { User } from 'typings/common_defs'

export interface RosterDetail {
  id: number
  name: string
  createdAt: string
  workers: Array<
    Pick<
      User,
      | 'id'
      | 'name'
      | 'numShiftsForCompany'
      | 'profilePicUrl'
      | 'rating'
      | 'statusForCompany'
      | 'workForCompany'
    >
  >
}

export async function getRosterDetails(rosterId: number) {
  const data = await graphql(
    `
      company {
        rosters(rosterId:${rosterId}) {
          id
          name
          createdAt
          workers {
            id
            name
            numShiftsForCompany
            profilePicUrl    
            rating
            statusForCompany
            workForCompany(lastNFinishedWork:1) {
              startedAt
            }
          }
        }
      }`,
    'roster_detail_query'
  )

  return path(['data', 'data', 'company', 'rosters', 0], data) as RosterDetail
}

export function removeWorkerFromRoster(
  companyId: number,
  rosterId: number,
  workerId: number
) {
  return api.delete(
    `/company/${companyId}/roster/${rosterId}/worker/${workerId}`
  )
}
