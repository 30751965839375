import React from 'react'
import {
  StartPage,
  DetailsPage,
  SchedulePage,
  StaffPage,
  PaymentPage,
  ConfirmPage,
} from './pages'
import {
  detailsSchema,
  scheduleSchema,
  staffSchema,
  paymentSchema,
  orientationShiftSchema,
} from './schemas'
import { StepEnum, Step, ShiftType } from './types'
import { OrientationShiftPage } from './pages/OrientationShiftPage'
import { AssignmentConfirmPage } from './pages/AssignmentConfirmPage'

const StepsForCreateAndEdit: Step[] = [
  {
    name: StepEnum.Start,
    route: '/',
    page: <StartPage />,
  },
  {
    name: StepEnum.Details,
    route: 'details',
    page: <DetailsPage />,
    schemaKey: 'details',
    schema: detailsSchema,
  },
  {
    name: StepEnum.Schedule,
    route: 'schedule',
    page: <SchedulePage />,
    schemaKey: 'schedule',
    schema: scheduleSchema,
  },
  {
    name: StepEnum.Staff,
    route: 'staff',
    page: <StaffPage />,
    schemaKey: 'staff',
    schema: staffSchema,
  },
  {
    name: StepEnum.Payment,
    route: 'payment',
    page: <PaymentPage />,
    schemaKey: 'payment',
    schema: paymentSchema,
  },
  {
    name: StepEnum.Confirm,
    route: 'confirm',
    page: <ConfirmPage />,
  },
]

const StepsForLongTermAssignment: Step[] = [
  {
    name: StepEnum.Start,
    route: '/',
    page: <StartPage />,
  },
  {
    name: StepEnum.Details,
    route: 'details',
    page: <DetailsPage />,
    schemaKey: 'details',
    schema: detailsSchema,
  },
  {
    name: StepEnum.OrientationShift,
    route: 'orientation-shift',
    page: <OrientationShiftPage />,
    schemaKey: 'orientationShift',
    schema: orientationShiftSchema,
  },
  {
    name: StepEnum.WorkShift,
    route: 'schedule',
    page: <SchedulePage />,
    schemaKey: 'schedule',
    schema: scheduleSchema,
  },
  {
    name: StepEnum.Staff,
    route: 'staff',
    page: <StaffPage />,
    schemaKey: 'staff',
    schema: staffSchema,
  },
  {
    name: StepEnum.Payment,
    route: 'payment',
    page: <PaymentPage />,
    schemaKey: 'payment',
    schema: paymentSchema,
  },
  {
    name: StepEnum.Confirm,
    route: 'confirm',
    page: <AssignmentConfirmPage />,
  },
]

export const getStepsByShiftType = (shiftType: ShiftType) => {
  if (shiftType === ShiftType.LongTermAssignment) {
    return StepsForLongTermAssignment
  }

  return StepsForCreateAndEdit
}
