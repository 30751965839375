import React from 'react'
import { Box, Flex } from 'ui'
import { useShiftDetail } from '../useShiftDetail'
import useShiftTimeline from 'hooks/useShiftTimeline'
import { Avatar } from '@workwhile/ui'
import { Search } from 'lucide-react'

export const ShiftDetailUnfilledWorkerRow = () => {
  const { shift } = useShiftDetail()
  const { isInProgress, isInPast } = useShiftTimeline(shift)

  if (!shift) return null

  return (
    <Box>
      <Flex flexDirection="row" alignItems="center">
        <Box mr={3}>
          <Avatar
            size="large"
            badge={{
              icon: <Search size={9} color={'neutrals.00'} />,
              color: 'neutrals.300',
            }}
          />
        </Box>
        <Box mr={3}>
          {isInProgress || isInPast
            ? 'Unassigned'
            : 'This spot is being offered to qualified workers'}
        </Box>
      </Flex>
    </Box>
  )
}
