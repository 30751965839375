import { Address } from 'typings/common_defs'

interface Props {
  address: Address
}

export function AddressString(props: Props) {
  const { address } = props
  const { street, city, state, zip } = address

  return [street, city, `${state} ${zip}`].filter(Boolean).join(', ')
}
