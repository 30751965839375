import React, { useMemo, useState } from 'react'
import { getInvoiceDetailCSVData, InvoiceDetail } from 'api/payment'
import { Box, Button, Flex, Heading, Table, Text, useResponsiveValue } from 'ui'
import { columns } from './columns'
import { formatCurrency } from 'lib/string'
import { apiDateFormat } from 'api'
import { format } from 'date-fns'
import fileDownload from 'js-file-download'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { InvoicePDF } from './InvoicePDF'

interface Props {
  data: InvoiceDetail
}

export function InvoiceDetails(props: Props) {
  const { data } = props
  const total = useMemo(() => {
    return data.chargePayment.chargeItems.reduce((acc, item) => {
      return acc + Number(item.amount)
    }, 0)
  }, [data])
  const columnsToUse = useResponsiveValue([1, 5])
  const tableVariant = useResponsiveValue<'borderless' | 'standard'>([
    'borderless',
    'standard',
  ])
  const fileName = useMemo(() => {
    const filenamePrefix = `WorkWhile_${data.companyName}`
    const type = data.chargeUpfront
      ? data.chargePayment.status === 'paid'
        ? 'Receipt'
        : 'Charges'
      : 'Invoice'

    return `${filenamePrefix}_${type}_${format(
      new Date(data.chargePayment.createdAt),
      apiDateFormat
    )}`
  }, [data])
  const [isLoadingCSV, setLoadingCSV] = useState(false)

  const onDownloadCSV = async () => {
    setLoadingCSV(true)
    const csvData = await getInvoiceDetailCSVData(
      data.chargePayment.chargeDetailsUrl
    )
    fileDownload(csvData, `${fileName}.csv`)
    setLoadingCSV(false)
  }

  return (
    <Box pb={5}>
      <Flex alignItems={'center'} flexWrap={'wrap'} mb={[3, 3, 0]}>
        <Box width={[1, 1, 'auto']}>
          <Heading level={3}>
            #{data.chargePayment.id}&nbsp;-&nbsp;
            {data.chargePayment.status === 'paid' ? (
              <Text inline color={'success'}>
                Paid
              </Text>
            ) : null}
            {data.chargePayment.status !== 'paid' ? (
              <Text inline color={'warning'}>
                Payment Pending
              </Text>
            ) : null}
          </Heading>
        </Box>
        <Box flex={1} />
        <Box width={[1 / 2, 1 / 2, 'auto']} pr={2}>
          <PDFDownloadLink
            document={<InvoicePDF data={data} />}
            fileName={`${fileName}.pdf`}
          >
            {({ loading }) => (
              <Button
                block
                variant={'secondary'}
                kind={'medium'}
                loading={loading}
              >
                {loading ? 'Generating PDF...' : 'Download PDF'}
              </Button>
            )}
          </PDFDownloadLink>
        </Box>
        <Box width={[1 / 2, 1 / 2, 'auto']} pl={2}>
          <Button
            block
            variant={'secondary'}
            kind={'medium'}
            loading={isLoadingCSV}
            onClick={onDownloadCSV}
          >
            Download CSV
          </Button>
        </Box>
      </Flex>
      <Table
        variant={tableVariant}
        columns={columns.slice(0, columnsToUse)}
        data={data.chargePayment.chargeItems}
      />
      <Flex mt={3} justifyContent={'flex-end'} alignItems={'center'}>
        Total&nbsp;&nbsp;
        <Text fontSize={3} fontWeight={2}>
          {formatCurrency(total)}
        </Text>
      </Flex>
    </Box>
  )
}
