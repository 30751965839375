import { Box, Text, useResponsiveValue, Popover } from '@workwhile/ui'
import { WorkerAvailability } from 'api/worker'
import React, { useEffect } from 'react'
import { GridTable } from './GridTable'
import { format } from 'date-fns'
import { track } from 'lib/amplitude'
import { User } from 'typings/common_defs'

interface Props {
  worker: User
  availabilitiesForWorker: WorkerAvailability[]
}

export const WorkerUnavailableReasons = ({
  worker,
  availabilitiesForWorker,
}: Props) => {
  useEffect(() => {
    track('impression, worker_unavailable_reasons', {
      workerId: worker.id,
      workerName: worker.name,
      availabilitiesForWorker,
    })
  }, [])

  const unavailableTimes = availabilitiesForWorker.filter(
    (availability) => !availability.available
  )
  const cellWidth = 200
  const minCellHeight = 80
  const rowsPerPage = useResponsiveValue([4, 5, 5]) // 4 rows for mobile, 5 for tablets, 5 for desktop,
  const columnsPerPage = useResponsiveValue([1, 2, 3]) // 1 column for mobile, 2 for tablets, 3 columns for desktop
  const hasMultiplePages =
    unavailableTimes.length > rowsPerPage * columnsPerPage
  const showTotalCellsText = useResponsiveValue([
    false,
    hasMultiplePages,
    hasMultiplePages,
  ])

  const renderCell = (availability: WorkerAvailability, index: number) => {
    const formattedDate = format(
      new Date(availability.shift.start),
      'EEEE MMMM do'
    )
    const firstReason = availability?.reasons?.[0]
    const subsequentReasons = availability?.reasons?.slice(1)
    return (
      <Box key={index} p={1} m={1} minWidth={cellWidth} maxWidth={cellWidth}>
        <Text>{formattedDate}</Text>
        <Box>
          <Text fontSize={1} color="error">
            {firstReason}
          </Text>
          {subsequentReasons?.length ?? 0 > 0 ? (
            <Popover
              trigger={
                <Box>
                  <Text
                    fontSize={1}
                    color="primary"
                    style={{ cursor: 'pointer' }}
                  >
                    View +{subsequentReasons?.length} reasons
                  </Text>
                </Box>
              }
            >
              <Box p={2}>
                {subsequentReasons?.map((reason) => (
                  <Text>&#x2022; {reason}</Text>
                ))}
              </Box>
            </Popover>
          ) : null}
        </Box>
      </Box>
    )
  }

  return (
    <Box>
      <GridTable
        data={unavailableTimes}
        renderCell={renderCell}
        rowsPerPage={rowsPerPage}
        columnsPerPage={columnsPerPage}
        totalCellsText="Total Days Unavailable"
        cellWidth={cellWidth}
        minCellHeight={minCellHeight}
        showTotalCellsText={showTotalCellsText}
        showPageNumbers={hasMultiplePages}
      />
    </Box>
  )
}
