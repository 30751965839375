import React, { forwardRef, useMemo } from 'react'
import {
  Box,
  Button,
  Card,
  CardProps,
  Flex,
  Heading,
  Link,
  Message,
  Text,
  useAppTheme,
} from '@workwhile/ui'
import * as Popover from '@radix-ui/react-popover'
import { CircleDollarSign, Clock, ClockAlert, X } from 'lucide-react'
import { getLocationTimezone } from 'lib/time'
import { formatInTimeZone } from 'date-fns-tz'
import { formatCurrency } from 'lib/string'
import { getShiftBonus, getShiftPay } from 'lib/payment'
import { CalendarViewContextState } from '../../context'
import { ShiftDetailBodySection } from '../ShiftDetailBodySection'
import { calculateEstimatedTimeWorked } from 'lib/work'

type WorkerShiftCellDetailProps = Omit<
  CardProps,
  'ref' | 'children' | 'shadowless'
> & {
  work: CalendarViewContextState['state']['workers'][number]['works'][string][number]
}

export const WorkerShiftCellDetail = forwardRef<
  HTMLDivElement,
  WorkerShiftCellDetailProps
>(({ work, ...rest }, ref) => {
  const { colors, space } = useAppTheme()
  const timezone = getLocationTimezone(work.shift.location)
  const startTime =
    work.shift.startsAt &&
    formatInTimeZone(work.shift.startsAt, timezone, 'h:mm a')

  const endTime =
    work.shift.endsAt &&
    formatInTimeZone(work.shift.endsAt, timezone, 'h:mm a z')

  const day =
    work.shift.startsAt &&
    formatInTimeZone(work.shift.startsAt, timezone, 'EEEE, MMMM d')

  const shiftStatus = useMemo(() => {
    const { status } = work

    const timezone = getLocationTimezone(work.shift.location)

    if (status === 'scheduled') {
      return {
        value: `Scheduled`,
      }
    }

    if (status === 'started') {
      const workerClockedInAt = work.startedAt
        ? formatInTimeZone(work.startedAt, timezone, 'h:mm a z')
        : undefined

      return {
        value: workerClockedInAt
          ? `Clocked in at ${workerClockedInAt}`
          : 'Working...',
      }
    }

    if (
      [
        'completed',
        'approved',
        'paid',
        'employer_approved',
        'admin_review',
        'employer_review',
      ].includes(status)
    ) {
      const startTime = work.startedAt
        ? formatInTimeZone(work.startedAt, timezone, 'h:mm a')
        : undefined

      const endTime = work.completedAt
        ? formatInTimeZone(work.completedAt, timezone, 'h:mm a z')
        : undefined

      const estimatedTimeWorked = calculateEstimatedTimeWorked(work)

      return {
        value:
          startTime && endTime
            ? `${startTime} - ${endTime} (${estimatedTimeWorked})`
            : 'Completed',
      }
    }

    if (status === 'removed') {
      return {
        value: 'Removed from this shift',
      }
    }

    if (status === 'bailed') {
      return {
        icon: ClockAlert,
        color: colors.error100,
        value: 'No show',
      }
    }
  }, [work])

  const shiftPay = useMemo(() => {
    const { status } = work

    if (status === 'scheduled') {
      const pay = getShiftPay(work.shift)
      const bonus = getShiftBonus(work.shift)
      const unit = work.shift.payLumpSum ? '' : '/hour'

      const payString = `${formatCurrency(pay)} ${unit}`
      const bonusString =
        bonus && Number(bonus) > 0 ? ` • Bonus: ${formatCurrency(bonus)}` : ''

      return `Est. ${payString}${bonusString}`
    }

    if (
      [
        'completed',
        'approved',
        'paid',
        'employer_approved',
        'admin_review',
        'employer_review',
      ].includes(status)
    ) {
      const totalWorkerPay = work.earnings ? Math.max(work.earnings, 0) : 0

      return `Est. ${formatCurrency(totalWorkerPay)}`
    }

    if (status === 'removed') {
      return undefined
    }
  }, [work])

  return (
    <Card ref={ref} width={[200, 320]} {...rest}>
      <Popover.Close
        asChild
        style={{ position: 'absolute', top: space[1], right: space[1] }}
      >
        <Button
          variant="text"
          p={1}
          style={{
            height: 'auto',
            padding: space[1],
          }}
        >
          <X size={18} />
        </Button>
      </Popover.Close>
      <Box>
        <Heading level={4} fontWeight="400" my={0}>
          {day}
        </Heading>
        {startTime && endTime ? (
          <Text
            color="lightText"
            fontSize={1}
          >{`${startTime} - ${endTime}`}</Text>
        ) : null}
      </Box>
      {shiftStatus ? (
        <ShiftDetailBodySection
          Icon={shiftStatus.icon || Clock}
          mt={3}
          iconColor={shiftStatus.color}
        >
          <Text color={shiftStatus.color}>{shiftStatus.value}</Text>
        </ShiftDetailBodySection>
      ) : null}

      {shiftPay ? (
        <ShiftDetailBodySection Icon={CircleDollarSign} mt={3}>
          <Text>{shiftPay}</Text>
        </ShiftDetailBodySection>
      ) : null}
      {work.status === 'employer_review' ? (
        <Message variant="error" mt={3}>
          <Text>
            <Text fontWeight="bold" as="b">
              Attention:
            </Text>{' '}
            Please review this worker's shift details
          </Text>
        </Message>
      ) : null}

      {work.status === 'admin_review' ? (
        <Message variant="warning" mt={3}>
          <Text>
            {' '}
            <Text fontWeight="bold" as="b">
              Attention:
            </Text>{' '}
            This shift is under review by WorkWhile
          </Text>
        </Message>
      ) : null}
      <Flex mt={3} justifyContent="flex-end">
        <Button as={Link} to={`/shift/${work.id}`} kind="medium">
          <Text color="white">View Shift</Text>
        </Button>
      </Flex>
    </Card>
  )
})

WorkerShiftCellDetail.displayName = 'WorkerShiftCellDetail'
