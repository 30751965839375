import React from 'react'
import { Button, DropdownMenu, Flex } from '@workwhile/ui'
import {
  LucideChevronLeft,
  LucideChevronRight,
  CalendarCheck,
  UserPlus,
} from 'lucide-react'
import { useCalendarViewContextStateValue } from '../context/CalendarViewProvider'
import { useAssignmentWorkflowsContextStateValue } from '../context/AssignmentWorkflowsProvider'
import { useGate } from 'statsig-react'
import { FeatureGates } from 'lib/statsig/feature_gates'
export const CalendarControls = () => {
  const { actions } = useCalendarViewContextStateValue()
  const { requestWorkersFlow } = useAssignmentWorkflowsContextStateValue()
  const { value: shouldEnableLTActions } = useGate(
    FeatureGates.EnableLTAActions
  )
  return (
    <>
      <Button
        variant="text"
        onClick={actions.goToPreviousWeek}
        mr={2}
        aria-label="Previous Week"
        backgroundColor="transparent"
      >
        <LucideChevronLeft size={20} />
      </Button>
      <Button
        variant="text"
        onClick={actions.goToToday}
        mr={2}
        aria-label="Today"
      >
        <CalendarCheck size={20} />
      </Button>
      <Button
        variant="text"
        onClick={actions.goToNextWeek}
        aria-label="Next Week"
      >
        <LucideChevronRight size={20} />
      </Button>
      {shouldEnableLTActions ? (
        <DropdownMenu trigger={<Button kind="medium">Actions</Button>}>
          <DropdownMenu.Item onClick={requestWorkersFlow.open}>
            <Flex as="span" alignItems="center" style={{ gap: 8 }}>
              <UserPlus size={14} />
              Request Workers
            </Flex>
          </DropdownMenu.Item>
        </DropdownMenu>
      ) : null}
    </>
  )
}
