import amplitude from 'amplitude-js/amplitude'
import { config } from 'config'

export const initAmplitude = () => {
  amplitude.getInstance().init(config.amplitudeApiKey)
}

export const setAmplitudeUserDevice = (installationToken: string) => {
  amplitude.getInstance().setDeviceId(installationToken)
}

export const setAmplitudeUserId = (userId: string) => {
  amplitude.getInstance().setUserId(userId)
}

export const setAmplitudeUserProperties = (properties: Object) => {
  amplitude.getInstance().setUserProperties(properties)
}

export const track = (eventType: string, eventProperties?: Object) => {
  amplitude.getInstance().logEvent(eventType, eventProperties)
}
