import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { companyAllWorkersKey, companyWorkersKey } from './keys'
import {
  CompanyRoster,
  getCompanyWorkers,
  PaginatedCompanyWorkers,
} from 'api/worker'

interface UseCompanyWorkersOptions {
  search?: string
  includeRosters?: boolean
  minLength?: number
}

export function useCompanyWorkers(options: UseCompanyWorkersOptions) {
  const { search, includeRosters, minLength = 3 } = options
  return useInfiniteQuery<PaginatedCompanyWorkers>({
    queryKey: companyWorkersKey(search, includeRosters),
    queryFn: ({ pageParam }) =>
      getCompanyWorkers({
        search,
        includeRosters,
        cursor: pageParam as string,
      }),
    initialPageParam: null as string | null,
    getNextPageParam: (lastPage) => lastPage.cursor || null,
    staleTime: Infinity,
    enabled: !search || search.length >= minLength,
  })
}

export function useInvalidateCompanyWorkers() {
  const queryClient = useQueryClient()

  return {
    invalidate: () =>
      queryClient.invalidateQueries({
        queryKey: companyAllWorkersKey,
      }),
  }
}

export function useUpdateCompanyWorkerRosters(
  options: Pick<UseCompanyWorkersOptions, 'search' | 'includeRosters'>
) {
  const queryClient = useQueryClient()
  const { search, includeRosters } = options

  return {
    updateWorkerRosters: (
      workerId: number,
      rosters: Array<Pick<CompanyRoster, 'id' | 'name'>>
    ) => {
      queryClient.setQueryData(
        companyWorkersKey(search, includeRosters),
        (oldData?: InfiniteData<PaginatedCompanyWorkers>) => {
          if (!oldData) return oldData

          const newPages = oldData.pages.map((page) => {
            return {
              ...page,
              items: page.items.map((worker) => {
                if (Number(worker.id) === Number(workerId)) {
                  return {
                    ...worker,
                    rosters,
                  }
                }
                return worker
              }),
            }
          })

          return {
            ...oldData,
            pages: newPages,
          }
        }
      )
    },
  }
}
