import { CompanyLog, LogEventType } from 'api/company'
import { toTitleCase } from 'lib/legacy_util'

export function LogItemSummary(props: { data: CompanyLog }) {
  const { data } = props
  const { eventType, message, user, subjectUser, shift } = data
  const displayedUserFullName = user ? toTitleCase(user.name) : ''
  const displayedSubjectUserFullName = subjectUser
    ? toTitleCase(subjectUser.name)
    : ''

  // Shift Create
  if (eventType === LogEventType.ShiftCreate) {
    if (user && shift) {
      return `${displayedUserFullName} created a ${
        shift.position?.name
      } shift ${
        shift.locationless
          ? ''
          : `in ${shift.location?.address?.city}, ${shift.location?.address?.state}`
      }`
    }
  }

  // Shift Modify
  if (eventType === LogEventType.ShiftModify) {
    if (user && shift) {
      return `${displayedUserFullName} modified a ${
        shift.position?.name
      } shift ${
        shift.locationless
          ? ''
          : `in ${shift.location?.address?.city}, ${shift.location?.address?.state}`
      }`
    }
  }

  // E2W Review
  if (eventType === LogEventType.E2WReview) {
    if (user && subjectUser) {
      const relevantE2wMessageContent = message
        .split('rating')[1]
        .split('shift')[0]
      return `${displayedUserFullName} reviewed ${displayedSubjectUserFullName}. rating${relevantE2wMessageContent}`
    }
  }

  // Work
  if (eventType === LogEventType.Work) {
    const relevantWorkMessageContent = message.split(/ (.+)/)[1] // remove the first name
    // scheduled, completed, rejected, removed
    if (user && shift) {
      const workEventType = relevantWorkMessageContent.split('shift')[0].trim()
      const displayedWorkEventType =
        workEventType === 'is removed from' ? 'was removed from' : workEventType

      return `${displayedUserFullName} ${displayedWorkEventType} a ${
        shift.position?.name
      } shift ${
        shift.locationless ? '' : `in ${shift.location?.address?.city}`
      }`
    }
  }

  // Company Worker Profile
  if (eventType === LogEventType.CompanyWorkerProfile) {
    const formattedMsg = message
      .split('changes=')[1]
      .replace(/'/g, '"')
      .replace(/: /g, ':"')
      .replace(/,/g, '",')
      .replace('}', '"}')
    const changes = JSON.parse(formattedMsg)
    const key = Object.keys(changes)[0]
    let action = ''
    switch (key) {
      case 'blocked':
        action = changes[key] === 'True' ? 'blocked' : 'unblocked'
        break
      case 'favorite':
        action = changes[key] === 'True' ? 'favorited' : 'unfavorited'
        break
      default:
      // code block
    }
    if (user && subjectUser) {
      return `${displayedUserFullName} ${action} ${displayedSubjectUserFullName}`
    }
  }

  return message
}
