import React, { PropsWithChildren } from 'react'
import { ExitButton } from './ExitButton'
import { Flex } from '@workwhile/ui'

/**
 * HeaderBar component that includes an Exit button at the end
 * @param props
 * @constructor
 */
export function HeaderBar(props: PropsWithChildren) {
  return (
    <Flex
      position={'sticky'}
      justifyContent={'flex-end'}
      alignItems={'center'}
      backgroundColor={'white'}
      zIndex={2}
      top={0}
    >
      {props.children}
      <ExitButton />
    </Flex>
  )
}
