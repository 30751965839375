import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { latestMessagesKey } from './keys'
import { ChatMessage, getLatestMessages } from 'api/message'

export function useLatestMessages() {
  return useInfiniteQuery({
    queryKey: latestMessagesKey,
    queryFn: ({ pageParam }) => getLatestMessages(pageParam),
    initialPageParam: '',
    getNextPageParam: (lastPage) => lastPage?.cursor ?? null,
  })
}

export function useResetUnreadCount(workerId: number) {
  const queryClient = useQueryClient()

  return {
    resetUnreadCount: () => {
      queryClient.setQueryData<ChatMessage[]>(latestMessagesKey, (prevData) => {
        if (!prevData) {
          return prevData
        }

        return prevData.map((message) => ({
          ...message,
          unreadCount:
            Number(message.fromUser.id) === Number(workerId)
              ? 0
              : message.unreadCount,
        }))
      })
    },
  }
}
