import React from 'react'
import { WorkForCompany } from 'api/worker'
import { Box, createColumnHelper, Flex } from 'ui'
import { StarRating } from './StarRating'
import { ShiftDate } from './ShiftDate'

const columnHelper = createColumnHelper<WorkForCompany>()

export const completedColumns = [
  columnHelper.accessor('shift.position.name', {
    header: 'Position',
    cell: (info) => {
      const data = info.row.original
      return (
        <>
          {info.getValue()}
          <Box display={['block', 'block', 'none']}>
            <Flex flexDirection={'row'}>
              <ShiftDate shift={data.shift} />
            </Flex>
            <Flex flexDirection={'row'}>
              Rating:&nbsp;
              <StarRating rating={data.e2wReview?.rating} />
            </Flex>
            <Flex flexDirection={'row'}>Supervisor:&nbsp;</Flex>
          </Box>
        </>
      )
    },
  }),
  columnHelper.accessor('shift.startsAt', {
    header: 'Date & Time',
    cell: (info) => <ShiftDate shift={info.row.original.shift} />,
  }),
  columnHelper.accessor('e2wReview.rating', {
    header: 'Rating',
    cell: (info) => <StarRating rating={info.getValue()} />,
  }),
  columnHelper.accessor('shift.id', {
    header: 'Supervisor',
    cell: () => null,
  }),
]

export const upcomingColumns = [
  columnHelper.accessor('shift.position.name', {
    header: 'Position',
    cell: (info) => {
      const data = info.row.original
      return (
        <>
          {info.getValue()}
          <Box display={['block', 'block', 'none']}>
            <Flex flexDirection={'row'}>
              <ShiftDate shift={data.shift} />
            </Flex>
            <Flex flexDirection={'row'}>Pay:&nbsp;</Flex>
            <Flex flexDirection={'row'}>Supervisor:&nbsp;</Flex>
          </Box>
        </>
      )
    },
  }),
  columnHelper.accessor('shift.startsAt', {
    header: 'Date & Time',
    cell: (info) => <ShiftDate shift={info.row.original.shift} />,
  }),
  columnHelper.accessor('e2wReview.rating', {
    header: 'Pay',
    cell: () => null,
  }),
  columnHelper.accessor('shift.id', {
    header: 'Supervisor',
    cell: () => null,
  }),
]
