import React, { useMemo } from 'react'
import { useShiftDetail } from './useShiftDetail'
import { Flex, Label, Text } from 'ui'
import { getAssignedWork, shouldShowApproveButton } from 'lib/work'
import { LucideClock4, LucideHourglass, LucideInfo } from 'lucide-react'
import { CushionedWorkersModal } from './alerts/CushionedWorkersModal'
import { TimesheetReviewPeriodModal } from './alerts/TimesheetReviewPeriodModal'
import { getAutoApproveDate, getShiftTimezone } from 'lib/shift'
import { formatInTimeZone } from 'date-fns-tz'
import { MinPayPolicyModal } from './alerts/MinPayPolicyModal'
import { useGate } from 'statsig-react'
import { FeatureGates } from 'lib/statsig/feature_gates'

export const ShiftDetailWorkersAlerts = () => {
  const { shift } = useShiftDetail()
  const { value: isShiftDetailMinPayPolicySupportEnabled } = useGate(
    FeatureGates.BizPortalMinPayPolicySupport
  )

  if (!shift) return null

  const workList = shift.work || []
  const assignedWork = getAssignedWork(workList)
  const numWorkersAssigned = assignedWork.length

  const content = useMemo(() => {
    const contentList: React.ReactNode[] = []
    const areWorkersAssigned = shift?.workersNeeded
      ? numWorkersAssigned > shift?.workersNeeded
      : false
    // if there are cushioned workers, add the cushion Label
    if (areWorkersAssigned) {
      const numExtraWorkers = numWorkersAssigned - (shift?.workersNeeded || 0)
      contentList.push(
        <CushionedWorkersModal
          trigger={
            <Label
              variant="information"
              size="large"
              mr={3}
              style={{ cursor: 'pointer' }}
              key="cushioned-workers-label"
            >
              <Flex flexDirection="row" alignItems={'center'}>
                <Text mr={2}>{numExtraWorkers} Extra Workers - on us!</Text>
                <LucideInfo size={16} />
              </Flex>
            </Label>
          }
        />
      )
    }

    // if we are:
    // - past the shift end time
    // - and there is still work that needs approval
    // add the "timesheet approval hours" label
    const shiftEndTime = shift?.endsAt ? new Date(shift?.endsAt) : null
    const hasWorkThatNeedsApproval = assignedWork.some((work) =>
      shouldShowApproveButton(work)
    )
    if (hasWorkThatNeedsApproval && shiftEndTime && shiftEndTime < new Date()) {
      const timezone = getShiftTimezone(shift)
      const timesheetReviewApprovalDate = getAutoApproveDate(shift)
      const formattedDate = formatInTimeZone(
        timesheetReviewApprovalDate,
        timezone,
        'eee MMM do, h:mma zzz'
      )
      contentList.push(
        <TimesheetReviewPeriodModal
          trigger={
            <Label
              variant="neutral"
              size="large"
              mr={3}
              style={{ cursor: 'pointer' }}
              key="timesheet-review-period-label"
            >
              <Flex flexDirection="row" alignItems={'center'}>
                <LucideHourglass size={16} />
                <Text ml={2}>Approve by {formattedDate}</Text>
              </Flex>
            </Label>
          }
        />
      )
    }

    const minPayLengthMins = shift?.minimumPayPolicy?.minPayLength
    if (isShiftDetailMinPayPolicySupportEnabled && minPayLengthMins) {
      const minPayLengthHours = minPayLengthMins / 60
      contentList.push(
        <MinPayPolicyModal
          trigger={
            <Label
              variant="neutral"
              size="large"
              mr={3}
              style={{ cursor: 'pointer' }}
              key="min-pay-policy-label"
            >
              <Flex flexDirection="row" alignItems={'center'}>
                <LucideClock4 size={16} />
                <Text ml={2}>{minPayLengthHours}hr Min Pay Policy</Text>
              </Flex>
            </Label>
          }
        />
      )
    }

    return contentList
  }, [shift])

  return (
    <Flex flexDirection={'row'} flexWrap={'wrap'} alignItems={'center'} mb={3}>
      {content}
    </Flex>
  )
}
