import { graphql } from '../graphql'
import { path } from 'ramda'
import { api } from 'api/api'
import {
  QuizQuestion,
  MarkdownContent,
  Tutorial,
  TutorialCategory,
} from './typings'
import { sortTutorialsByCategory } from 'api/company'

export async function getShiftTutorials(shiftId: number) {
  const response = await graphql(
    `company {
          shiftsDetail(ids: [${shiftId}]) {
            location {
              name
            }
            position {
              name
            }
            tutorials {
              id,
              name,
              content,
              quiz,
              category,
              companyId
            }
          }
        }`,
    'shift_tutorials'
  )
  const data = path(['data', 'data', 'company', 'shiftsDetail', 0], response)
  const location = path(['location', 'name'], data)
  const position = path(['position', 'name'], data)
  const tutorials = path(['tutorials'], data).map(
    (
      tutorial: Omit<Tutorial, 'content' | 'quiz'> & {
        content?: string
        quiz: string | null
      }
    ) => ({
      ...tutorial,
      content: tutorial.content ? JSON.parse(tutorial.content) : [],
      quiz: tutorial.quiz ? JSON.parse(tutorial.quiz) : tutorial.quiz,
    })
  )
  const sortedTutorials = sortTutorialsByCategory(tutorials)
  return {
    tutorials: sortedTutorials,
    location,
    position,
  }
}

export async function getShiftTutorial(shiftId: number, tutorialId: number) {
  const { tutorials, location, position } = await getShiftTutorials(shiftId)
  const tutorial = tutorials.find(
    (tutorial) => tutorial.id.toString() === tutorialId.toString()
  )
  return {
    tutorial,
    location,
    position,
  }
}

export interface CreateShiftTutorialRequest {
  companyId: number
  shiftId: number
  category: TutorialCategory
  content: MarkdownContent[]
  quiz?: QuizQuestion[]
  propagate: boolean
  notifyWorkers: boolean
}

export async function createShiftTutorial(
  request: CreateShiftTutorialRequest
): Promise<number> {
  const {
    companyId,
    shiftId,
    category,
    content,
    quiz,
    propagate,
    notifyWorkers,
  } = request
  const data = await api.post(`/company/${companyId}/tutorial`, {
    shift_id: shiftId,
    category,
    content,
    quiz,
    notify_workers: notifyWorkers,
  })
  const tutorialId = path(['data', 'id'], data)
  // assign tutorial to shift
  await assignShiftTutorial({ companyId, shiftId, tutorialId, propagate })
  return tutorialId
}

export async function assignShiftTutorial(data: {
  companyId: number
  shiftId: number
  tutorialId: number
  propagate: boolean
}) {
  const { companyId, shiftId, tutorialId, propagate } = data
  return api.post(`/company/${companyId}/tutorial/assign`, {
    shift_id: shiftId,
    tutorial_id: tutorialId,
    propagate,
  })
}

export type EditShiftTutorialRequest = Omit<
  CreateShiftTutorialRequest,
  'category'
> & {
  tutorialId: number
}

export async function editShiftTutorial(
  request: EditShiftTutorialRequest
): Promise<number> {
  const {
    companyId,
    shiftId,
    tutorialId,
    content,
    quiz,
    propagate,
    notifyWorkers,
  } = request
  const data = await api.put(`/company/${companyId}/tutorial/${tutorialId}`, {
    shift_id: shiftId,
    content,
    quiz,
    propagate,
    notify_workers: notifyWorkers,
  })
  return path(['data', 'id'], data)
}

export type RemoveShiftTutorialRequest = Omit<
  EditShiftTutorialRequest,
  'category' | 'content' | 'notifyWorkers'
>

export function removeShiftTutorial(request: RemoveShiftTutorialRequest) {
  const { companyId, shiftId, tutorialId, propagate } = request
  return api.post(`/company/${companyId}/tutorial/remove`, {
    shift_id: shiftId,
    tutorial_id: tutorialId,
    propagate,
  })
}
