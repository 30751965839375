export const paymentRootKey = ['payment']
export const invoicesKey = (type: string, startDate?: Date, endDate?: Date) => [
  ...paymentRootKey,
  'invoices',
  type,
  startDate?.toISOString(),
  endDate?.toISOString(),
]
export const invoiceKey = (invoiceId: number) => [
  ...paymentRootKey,
  'invoice',
  invoiceId,
]
export const chargeAccountsKey = [...paymentRootKey, 'chargeAccounts']
export const stripeACHUrlKey = [...paymentRootKey, 'stripeACHUrl']
export const chargeLimitsKey = [...paymentRootKey, 'chargeLimits']
