import { useCompanyPosition } from 'hooks/useCompanyPosition'
import { useMemo } from 'react'

const DRIVING_POSITION_TEMPLATE = 22

export function useIsDrivingPosition(positionId: number) {
  const position = useCompanyPosition(positionId)
  const isDrivingPosition = useMemo(() => {
    if (!position) {
      return false
    }

    return (
      position.requirements?.includes('Valid drivers license') ||
      position.positionTemplateId === DRIVING_POSITION_TEMPLATE
    )
  }, [position])

  return isDrivingPosition
}
