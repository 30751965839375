import { DemoApiMock } from '../types'
import { getGraphqlRequest, matchGraphqlRequest } from '../utils'
import { positionTemplateGroups } from '../data/positionTemplateGroups'

export const mockGetPositionTemplate: DemoApiMock = {
  shouldMock: (request) =>
    matchGraphqlRequest(request, /positionTemplates\(positionTemplateId/),
  getResponse: async (request) => {
    const graphqlRequest = getGraphqlRequest(request)
    const positionTemplateId = Number(
      graphqlRequest.variables.positionTemplateId
    )
    return {
      data: {
        company: {
          positionTemplates: [
            ...positionTemplateGroups
              .flatMap((group) => group.positionTemplates)
              .filter((template) => template.id === positionTemplateId),
          ],
        },
      },
    }
  },
}
