import React, { useEffect } from 'react'
import { Modal, Text, Heading } from 'ui'
import { track } from '../../../../lib/amplitude'

interface Props {
  trigger: React.ReactNode
}

export const UnableToEditWorkExplainerModal = ({ trigger }: Props) => {
  useEffect(() => {
    // componentDidMount
    track(`impression, unable_to_edit_work_explainer_modal`)
  }, [])

  const title = 'Work is already approved'
  const body =
    "A worker's time cannot be edited after it has been approved. Please contact WorkWhile Support if you require assistance."

  return (
    <Modal trigger={trigger} width={[1, 900]}>
      <Heading level={3}>{title}</Heading>
      <Text>{body}</Text>
    </Modal>
  )
}
