import React from 'react'
import { useShiftEditor } from '../useShiftEditor'
import { useCompanyLocation } from 'hooks/useCompanyLocation'
import { useLocationCoordinates } from 'hooks/useLocationCoordinates'
import { Map } from 'components/Map'
import { Box, IconContentView, Text } from 'ui'
import { AddressString } from 'components/AddressString'
import { MapIcon } from 'lucide-react'

export function LocationMap() {
  const { shiftData } = useShiftEditor()
  const location = useCompanyLocation(shiftData?.details?.locationId)
  const coordinates = useLocationCoordinates(location)

  if (shiftData.details?.locationless || !coordinates) return null

  return (
    <Box m={3} boxShadow={'low'} borderRadius={'standard'}>
      <Map
        height={'18rem'}
        center={coordinates}
        zoom={11}
        borderBottomLeftRadius={0}
        borderBottomRightRadius={0}
        markers={[
          {
            position: coordinates,
            title: location?.name ?? 'Selected Location',
          },
        ]}
      />
      {location?.address ? (
        <Box p={2}>
          <IconContentView icon={<MapIcon size={14} />}>
            <Text ml={2} fontSize={1}>
              <AddressString address={location?.address} />
            </Text>
          </IconContentView>
        </Box>
      ) : null}
    </Box>
  )
}
