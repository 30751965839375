import React, { Fragment } from 'react'
import { Box } from 'ui'
import { NavLink } from 'pages/layout/SideNav/NavLink'
import { useLayoutContext } from 'pages/layout/useLayoutContext'
import { AuthGuard } from 'components/auth'

export function NavSection() {
  const { navOptions, selectedNavOption } = useLayoutContext()

  return (
    <Box>
      {navOptions.map((navOption) => {
        const navContent = (
          <NavLink
            {...navOption}
            selected={selectedNavOption?.to === navOption.to}
          />
        )

        if (navOption.permissionRequired) {
          return (
            <AuthGuard.Permission
              value={navOption.permissionRequired}
              key={navOption.to}
            >
              {navContent}
            </AuthGuard.Permission>
          )
        }

        return <Fragment key={navOption.to}>{navContent}</Fragment>
      })}
    </Box>
  )
}
