import { WorkSubmissionRequest, bulkApproveWork } from 'api/work'
import { useCompanyInfo } from '../company'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useInvalidateShiftDetail } from 'queries/company/useCompanyShiftDetail'
import { useUpdateTimelineShift } from '../shift'

export interface BulkApprovalWorkError {
  work_id: number
  succeeded: boolean
  error_message: string
}

export function useBulkSaveWorkMutation(options: {
  shiftId: number
  onSuccess?: (parsedData: BulkApprovalWorkError[]) => void
  onError?: (err: AxiosError) => void
}) {
  const { data: company } = useCompanyInfo()

  const { invalidate } = useInvalidateShiftDetail([options.shiftId])
  const { updateShift: updateTimelineShift } = useUpdateTimelineShift()

  return useMutation({
    mutationFn: async (data: WorkSubmissionRequest[]) => {
      if (!company?.id || options.shiftId === -1) {
        return
      }

      return bulkApproveWork(company.id, options.shiftId, data)
    },
    ...options,
    onSuccess: (result) => {
      if (options.onSuccess) {
        options.onSuccess(result)
      }
      updateTimelineShift(options.shiftId)
      invalidate()
    },
  })
}
