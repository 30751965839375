import React from 'react'
import { getLocationTimezone } from 'lib/time'
import { Box, Text } from 'ui'
import { formatInTimeZone } from 'date-fns-tz'
import { ShiftTime } from 'components/ShiftTime'
import { WorkerProfileShift } from 'api/worker'

export function ShiftDate(props: { shift: WorkerProfileShift }) {
  const timezone =
    props.shift.location?.address?.timezone || getLocationTimezone()

  return (
    <Box>
      <Text>
        {formatInTimeZone(props.shift.startsAt, timezone, 'MMMM d, yyyy')}
      </Text>
      <ShiftTime shift={props.shift} shortVersion={true} />
    </Box>
  )
}
