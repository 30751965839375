import React from 'react'
import { RosterDetail } from 'api/worker'
import { Box, Flex, Heading, Text } from 'ui'
import { CalendarClockIcon } from 'lucide-react'
import { format } from 'date-fns'
import { WorkerCard } from './WorkerCard'
import { AddWorkersModal } from './AddWorkersModal'
import { EditListModal } from './EditListModal'
import { WorkerEmptyState } from 'pages/ListDetailPage/WorkerEmptyState'

export function ListDetails(props: { data: RosterDetail }) {
  const { data } = props

  return (
    <>
      <Flex alignItems={'center'}>
        <Heading level={2} fontWeight={0}>
          {data.name}
        </Heading>
        <Box flex={1} />
        <EditListModal data={data} />
      </Flex>
      <Box display={'inline-flex'} mb={4}>
        <CalendarClockIcon size={18} />
        <Text ml={3}>
          List Created on {format(new Date(data.createdAt), 'MMM dd, yyyy')}
        </Text>
      </Box>
      <hr />
      <Flex mt={4} alignItems={'center'}>
        <Heading level={2} fontWeight={0}>
          Workers
        </Heading>
        <Box flex={1} />
        <AddWorkersModal data={data} />
      </Flex>
      <Box>
        {data.workers.map((worker) => (
          <WorkerCard
            data={worker}
            rosterId={Number(data.id)}
            key={worker.id}
          />
        ))}
      </Box>
      {data.workers.length === 0 ? <WorkerEmptyState /> : null}
    </>
  )
}
