import React from 'react'
import { MarkdownEditor, MarkdownProvider } from '@workwhile/ui'
import IphoneMockup from 'components/IphoneMockup'

interface Props {
  content?: string
}

export function Preview({ content }: Props) {
  return (
    <IphoneMockup>
      <MarkdownProvider>
        <MarkdownEditor
          defaultValue={content}
          readonly={true}
          borderless={true}
          scale={0.9}
        />
      </MarkdownProvider>
    </IphoneMockup>
  )
}
