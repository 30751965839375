import { useMutation } from '@tanstack/react-query'
import { addWorkersToRoster } from 'api/worker'
import { useCompanyInfo } from './useCompanyInfo'

interface UseAddWorkersToRosterMutation {
  rosterId: number
  onSuccess?: () => void
  onError?: (err: Error) => void
}

export function useAddWorkersToRosterMutation(
  options: UseAddWorkersToRosterMutation
) {
  const { data: company } = useCompanyInfo()

  return useMutation({
    mutationFn: async (workerIds: number[]) => {
      if (!company?.id) {
        throw new Error('Company id not found')
      }

      return addWorkersToRoster(company.id, options.rosterId, workerIds)
    },
    ...options,
  })
}
