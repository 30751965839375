import {
  geocodeFromAddress,
  PlaceSuggestion,
  usePlaceSuggestionInfo,
} from 'components/AutocompleteAddress'
import { useDebounce } from 'ahooks'
import { useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { DEFAULT_CLOCK_IN_RADIUS_METER, LocationForm } from './schema'

export function useAddressInfo(methods: UseFormReturn<LocationForm>) {
  const {
    watch,
    setValue,
    formState: { isDirty },
  } = methods
  const [suggestion, setSuggestion] = useState<PlaceSuggestion | null>()
  const suggestionInfo = usePlaceSuggestionInfo(suggestion)
  const lat = watch('lat')
  const long = watch('long')
  const clockInLat = watch('clockInLat')
  const clockInLong = watch('clockInLong')
  const clockInRadius = watch('clockInRadius')
  const address = useDebounce(watch('address'), { wait: 500 })
  const city = useDebounce(watch('city'), { wait: 500 })
  const state = useDebounce(watch('state'), { wait: 500 })
  const zipCode = useDebounce(watch('zipCode'), { wait: 500 })

  useEffect(() => {
    if (!isDirty) return

    if (suggestionInfo) {
      setValue('address', suggestionInfo.addressComponents.address)
      setValue('unit', suggestionInfo.addressComponents.unit)
      setValue('city', suggestionInfo.addressComponents.city)
      setValue('state', suggestionInfo.addressComponents.state)
      setValue('zipCode', suggestionInfo.addressComponents.postalCode)
      setValue('lat', suggestionInfo.geometry.lat)
      setValue('long', suggestionInfo.geometry.lng)
      setValue('clockInLat', suggestionInfo.geometry.lat)
      setValue('clockInLong', suggestionInfo.geometry.lng)
      setValue('clockInRadius', DEFAULT_CLOCK_IN_RADIUS_METER)
    } else {
      setValue('lat', undefined)
      setValue('long', undefined)
      setValue('clockInLat', undefined)
      setValue('clockInLong', undefined)
    }
  }, [suggestionInfo])

  useEffect(() => {
    if (!isDirty) return

    // if address was manually edited
    if (address && city && state && zipCode) {
      if (
        !suggestionInfo ||
        suggestionInfo.addressComponents.address !== address ||
        suggestionInfo.addressComponents.city !== city ||
        suggestionInfo.addressComponents.state !== state ||
        suggestionInfo.addressComponents.postalCode !== zipCode
      ) {
        setSuggestion(null)
        geocodeFromAddress(`${address}, ${city}, ${state}, ${zipCode}`)
          .then((placeInfo) => {
            setValue('lat', placeInfo.geometry.lat)
            setValue('long', placeInfo.geometry.lng)
            setValue('clockInLat', placeInfo.geometry.lat)
            setValue('clockInLong', placeInfo.geometry.lng)
          })
          .catch(() => {
            setValue('lat', undefined)
            setValue('long', undefined)
            setValue('clockInLat', undefined)
            setValue('clockInLong', undefined)
          })
      }
    }
  }, [address, city, state, zipCode, suggestionInfo])

  return {
    setSuggestion,
    lat,
    long,
    clockInLat: clockInLat ?? lat,
    clockInLong: clockInLong ?? long,
    clockInRadius: clockInRadius ?? DEFAULT_CLOCK_IN_RADIUS_METER,
    mapId: `${address}-${city}-${state}-${zipCode}`,
  }
}
