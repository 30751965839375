import React from 'react'
import { Modal, Text } from 'ui'
import { useShiftDetail } from '../useShiftDetail'

interface Props {
  trigger: React.ReactNode
}

export const MinPayPolicyModal = ({ trigger }: Props) => {
  const { shift } = useShiftDetail()

  if (!shift) return null

  const minPayLengthMins = shift?.minimumPayPolicy?.minPayLength
  if (!minPayLengthMins) return null
  const minPayLengthHours = minPayLengthMins / 60

  return (
    <Modal
      title={`This shift has a ${minPayLengthHours} hour minimum pay policy`}
      trigger={trigger}
      width={[1, 900]}
    >
      <Text mb={2}>
        If a worker arrives onsite, clocks in and is dismissed early due to lack
        of work, they will receive the first {minPayLengthHours} hours as
        minimum pay.
      </Text>
      <Text mb={2}>
        To be eligible: the worker must arrive on time and meet the requirements
        of the shift.
      </Text>
    </Modal>
  )
}
