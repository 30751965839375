import React, { ReactNode } from 'react'
import { Box, Flex } from 'ui'

interface Props {
  icon: ReactNode
  label: string
  value: ReactNode
}

export function InfoLineItem(props: Props) {
  const { icon, label, value } = props

  return (
    <Flex
      py={2}
      alignItems={'center'}
      borderBottomWidth={1}
      borderBottomStyle={'solid'}
      borderBottomColor={'neutrals.100'}
    >
      {icon}
      <Box ml={3} flex={1}>
        {label}
      </Box>
      {value}
    </Flex>
  )
}
