import { useReviewTryoutShifts } from 'queries/review'
import React from 'react'
import { Box, Heading, Flex, Paragraph } from 'ui'
import { TryoutReviewShift } from './TryoutReviewShift'
import { TryoutLabel } from 'components/tryouts/TryoutLabel'

/**
 * Grab all shifts with work that needs attention.
 * For each shift, render a condensed version of the ShiftDetailWorkers section
 */

export const Tryouts = () => {
  const { data: tryoutReviewShifts } = useReviewTryoutShifts()

  if (!tryoutReviewShifts || tryoutReviewShifts.length === 0) {
    return null
  }

  return (
    <Box mb={4}>
      <Flex flexDirection="row" alignItems="center" mb={3}>
        <Heading level={2} mr={3}>
          Evaluate Tryout Workers
        </Heading>
        <TryoutLabel />
      </Flex>
      <Paragraph mb={3}>
        Workers from your Tryout will remain in the List for that position below
        by default. Remove a worker from your List if you no longer wish to work
        with them. Removing them from your Tryout will not prevent them from
        seeing other shifts from your company.
      </Paragraph>
      {tryoutReviewShifts?.map((shift) => (
        <TryoutReviewShift shift={shift} key={shift.id} />
      ))}
    </Box>
  )
}
