import React from 'react'
import { useParams } from 'react-router-dom'
import { Page } from '../layout'
import { Loading } from 'ui'
import { useInvoice } from 'queries/payment'
import { InvoiceDetails } from './InvoiceDetails'

export function InvoiceDetailPage() {
  const params = useParams<{ invoiceId: string }>()
  const invoiceId = Number(params.invoiceId)
  const { data: invoice, isLoading } = useInvoice({ invoiceId })

  return (
    <Page title={'Invoice Detail'}>
      {isLoading ? <Loading /> : null}
      {invoice ? <InvoiceDetails data={invoice} /> : null}
    </Page>
  )
}
