import React from 'react'
import { Box, Flex, Heading, Loading, Text } from 'ui'
import { FillRateLegend } from './FillRateLegend'
import { FillRateInfo } from './FillRateInfo'
import { useFillRate } from './useFillRate'
import { formatCurrency } from 'lib/string'
import { FillRateChart } from './FillRateChart'

export function FillRate() {
  const {
    insight,
    data,
    dateRangeLabel,
    totalWorkersRequested,
    totalWorkersFilled,
    averageFillRate,
    leadDayRec,
    payRec,
    bonusRec,
  } = useFillRate()

  return (
    <Box borderRadius={'xlarge'} boxShadow={'medium'} p={[3, 4]}>
      <Flex flexWrap={'wrap'}>
        <Heading level={3} my={0}>
          Fill Rate
        </Heading>
        <Box flex={1} width={[0, 'auto']} />
        <Flex
          flexDirection={'row'}
          alignItems={'flex-end'}
          width={[1, 'auto']}
          mt={[3, 0]}
        >
          <FillRateLegend color={'primary'} text={'Workers Filled'} />
          <FillRateLegend
            ml={3}
            color={'primaries.100'}
            text={'Workers Requested'}
          />
        </Flex>
      </Flex>
      <Text color={'primary'} fontWeight={2} textAlign={'center'} my={3}>
        {dateRangeLabel}
      </Text>
      {insight ? (
        <>
          <FillRateChart data={data} />
          <Box mt={4}>
            <FillRateInfo
              label={'Fill Rate'}
              text={
                isNaN(averageFillRate)
                  ? 'No Data'
                  : `${(averageFillRate * 100).toFixed(2)}%`
              }
            />
            <FillRateInfo label={'Workers Filled'} text={totalWorkersFilled} />
            <FillRateInfo
              label={'Workers Requested'}
              text={totalWorkersRequested}
            />
            <FillRateInfo
              label={'% of shifts posted within 24hrs'}
              text={
                insight?.avgShiftStats.shortLeadDaysRate
                  ? `${insight?.avgShiftStats.shortLeadDaysRate?.toFixed(2)}%`
                  : 'No Data'
              }
              rec={leadDayRec ? `Recommended: ${leadDayRec} days` : null}
            />
            <FillRateInfo
              label={'Average Pay'}
              text={
                insight?.avgShiftStats.avgPay
                  ? `${formatCurrency(
                      insight?.avgShiftStats.avgPay?.toFixed(2)
                    )}/hr`
                  : 'No Data'
              }
              rec={payRec ? `Recommended: ${formatCurrency(payRec)}/hr` : null}
            />
            <FillRateInfo
              label={'Average Bonus'}
              text={
                insight?.avgShiftStats.avgBonus
                  ? `${formatCurrency(
                      insight?.avgShiftStats.avgBonus?.toFixed(2)
                    )}/hr`
                  : 'No Data'
              }
              rec={
                bonusRec ? `Recommended: ${formatCurrency(bonusRec)}/hr` : null
              }
            />
          </Box>
        </>
      ) : (
        <Loading />
      )}
    </Box>
  )
}
