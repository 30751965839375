import { remark } from 'remark'
import remarkParse from 'remark-parse'
import { TutorialContent } from 'components/Tutorials/types'

// Function to convert markdown to custom JSON
export function convertToLegacyJsonTutorial(markdownString: string) {
  const processor = remark().use(remarkParse)
  const tree = processor.parse(markdownString)
  return transformTree(tree)
}

function transformTree(root) {
  const items: TutorialContent[] = []
  root.children?.forEach((child) => {
    const item = transformNode(child)
    if (Array.isArray(item)) {
      items.push(...item)
    } else {
      items.push(item)
    }
  })
  return items
}

function transformNode(node) {
  if (node.type === 'heading') {
    const level = node.depth
    if (!node.children[0]) {
      return null
    }
    return {
      type: level <= 2 ? 'subtitle' : 'secondary_subtitle',
      data: node.children[0].value,
    }
  }

  if (node.type === 'paragraph' || node.type === 'strong') {
    const children = node.children.map(transformNode)
    return children.filter((child) => child !== null)
  }

  if (node.type === 'list') {
    return {
      type: 'bullet_list',
      data: filterTextDescendants(node.children),
    }
  }

  if (node.type === 'link') {
    const url = node.url
    if (url.endsWith('.pdf')) {
      return {
        type: 'pdf_url',
        data: url,
      }
    }
    if (url.startsWith('https://www.youtube.com/')) {
      return {
        type: 'video_youtube',
        data: url,
      }
    }
  }

  if (node.type === 'image') {
    return {
      type: 'image_url',
      data: node.url,
    }
  }

  if (node.type === 'text') {
    return {
      type: 'paragraph',
      data: node.value,
    }
  }

  return null
}

function filterTextDescendants(children) {
  // find deep descendants whose type is "text"
  return children.reduce((acc, child) => {
    if (child.type === 'text') {
      return acc.concat(child.value)
    }
    if (child.children) {
      return acc.concat(filterTextDescendants(child.children))
    }
    return acc
  }, [] as string[])
}
