import React from 'react'
import { Flex, Box, Carousel, Heading, Loading } from 'ui'
import { useCompanyRosters } from 'queries/company'
import { RosterCard } from './RosterCard'
import { NewListModal } from './NewListModal'
import { ListEmptyState } from 'pages/WorkersPage/ListEmptyState'

export function ListSection() {
  const { data: rosters, isLoading } = useCompanyRosters()
  return (
    <>
      <Flex alignItems={'center'}>
        <Heading level={3} my={0}>
          Lists
        </Heading>
        <Box flex={1} />
        <NewListModal />
      </Flex>
      {isLoading ? <Loading /> : null}
      <Carousel mx={-2} py={3} cardsToShow={[1, 2, 2, 3, 4]} gutter={2}>
        {rosters?.map((roster) => (
          <RosterCard key={roster.id} data={roster} />
        ))}
      </Carousel>
      {rosters && rosters.length === 0 ? <ListEmptyState /> : null}
    </>
  )
}
