import React, { ReactNode, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Heading,
  Loading,
  Table,
  createColumnHelper,
  useModal,
  useResponsiveValue,
  Flex,
  Text,
} from 'ui'
import { useChargeAccounts, useInvalidateChargeAccounts } from 'queries/payment'
import { useStripeACHUrl } from 'queries/payment/useStripeACHUrl'
import { ChargeAccount } from 'typings/common_defs'
import { AddCreditCard } from 'components/AddCreditCard'

const columnHelper = createColumnHelper<ChargeAccount>()

interface Props {
  title?: ReactNode
  emptyStateText?: ReactNode
}

export function ChargeAccounts(props: Props) {
  const {
    title = 'Payment Information',
    emptyStateText = "You haven't added payment information yet",
  } = props
  const [showCCModal, setShowCCModal] = useState(false)
  const { showModal } = useModal()
  const { data, isLoading } = useChargeAccounts({
    refetchOnWindowFocus: true,
  })
  const { invalidate: invalidateChargeAccounts } = useInvalidateChargeAccounts()
  const { data: stripeACHUrl } = useStripeACHUrl()
  const columnsToUse = useResponsiveValue([2, 3])
  const handleEditButtonClick = (accountType?: string) => {
    if (accountType === 'bank_account') {
      if (stripeACHUrl) {
        return window.open(stripeACHUrl, '_blank', 'noopener')
      }
      return showModal({
        title: 'Edit Bank Connection',
        content:
          'Please contact WorkWhile support for bank connection assistance.',
        showCancel: false,
        onOk: ({ closeModal }) => closeModal(),
      })
    }

    if (accountType === 'card') {
      setShowCCModal(true)
    }
  }

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('brand', {
        size: 200,
        cell: (info) => (
          <Box>
            {info.getValue()}
            <Box display={['block', 'none']} my={2}>
              <Button
                width={120}
                kind={'small'}
                onClick={() =>
                  handleEditButtonClick(info.row.original.accountType)
                }
              >
                Edit
              </Button>
            </Box>
          </Box>
        ),
      }),
      columnHelper.accessor('last4', {
        cell: (info) => `Ending in ${info.getValue()}`,
      }),
      columnHelper.accessor('accountType', {
        size: 120,
        cell: (info) => (
          <Button
            width={120}
            kind={'small'}
            onClick={() => handleEditButtonClick(info.row.original.accountType)}
          >
            Edit
          </Button>
        ),
      }),
    ]
  }, [stripeACHUrl])
  return (
    <Box>
      <Heading level={3}>{title}</Heading>
      {isLoading ? <Loading /> : null}
      {!isLoading && data ? (
        <Box
          py={2}
          borderStyle={'solid'}
          borderColor={'neutrals.100'}
          borderTopWidth={1}
          borderBottomWidth={1}
        >
          <Table
            variant={'borderless'}
            columns={columns.slice(0, columnsToUse)}
            data={data}
          />
          {data.length === 0 ? (
            <>
              <Text color={'lightText'} mb={3}>
                {emptyStateText}
              </Text>
              <Flex>
                <Button
                  width={[1 / 2, 200]}
                  mr={3}
                  onClick={() => handleEditButtonClick('bank_account')}
                >
                  Add Bank Account
                </Button>
                <Button
                  width={[1 / 2, 200]}
                  onClick={() => handleEditButtonClick('card')}
                >
                  Add Credit Card
                </Button>
              </Flex>
            </>
          ) : null}
        </Box>
      ) : null}
      <AddCreditCard
        open={showCCModal}
        onClose={() => setShowCCModal(false)}
        onSuccess={() => {
          invalidateChargeAccounts()
        }}
      />
    </Box>
  )
}
