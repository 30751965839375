import React, { useMemo } from 'react'
import { ChatMessage } from 'api/message'
import { Avatar, Box, Flex, Text } from 'ui'
import { format } from 'date-fns'
import { ShiftInfo } from './ShiftInfo'

interface Props {
  data: ChatMessage
  showShiftInfo: boolean
}

export function MessageBubble(props: Props) {
  const { data, showShiftInfo } = props
  const isFromWorker = useMemo(() => {
    return data.direction.slice(0, 1) === 'w'
  }, [data])

  return (
    <>
      {showShiftInfo ? <ShiftInfo data={data.shift} /> : null}
      <Flex
        px={3}
        flexDirection={'row'}
        alignItems={'flex-end'}
        justifyContent={isFromWorker ? 'flex-start' : 'flex-end'}
        my={4}
      >
        <Box order={isFromWorker ? 1 : 2} flexShrink={0}>
          <Avatar
            size={'large'}
            user={{
              userId: data.fromUser.id.toString(),
              name: data.fromUser.name,
              photoUrl: data.fromUser.profilePicUrl,
            }}
          />
        </Box>
        <Flex
          flexDirection={'column'}
          alignItems={isFromWorker ? 'flex-start' : 'flex-end'}
          order={isFromWorker ? 2 : 1}
          mx={3}
        >
          <Box
            backgroundColor={isFromWorker ? 'neutrals.100' : 'primaries.50'}
            borderRadius={'xxlarge'}
            borderBottomLeftRadius={isFromWorker ? '0' : 'xxlarge'}
            borderBottomRightRadius={!isFromWorker ? '0' : 'xxlarge'}
            py={3}
            px={4}
            color={'neutrals.400'}
          >
            {data.message}
          </Box>
          <Text color={'neutrals.300'} fontSize={1} mt={2}>
            {format(new Date(data.createdAt), 'h:mm aaa')}
          </Text>
        </Flex>
      </Flex>
    </>
  )
}
