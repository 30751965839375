import React from 'react'
import { Box, Flex, Tabs, Text } from 'ui'
import { useWorkerProfile } from './useWorkerProfile'
import { format } from 'date-fns'
import { reverse } from 'ramda'
import { toTitleCase } from 'lib/string'

export function WorkHistory() {
  const { profileData } = useWorkerProfile()
  const workExperience = profileData.worker.workProfile?.workExperience || []

  const renderSkills = (skillsStr: string) => {
    if (!skillsStr) {
      return null
    }

    try {
      const skills = JSON.parse(skillsStr) as string[]
      if (skills.length === 0) {
        return null
      }
      return `Skills: ${skills.join(', ')}`
    } catch {
      return null
    }
  }

  return (
    <Box py={4}>
      <Tabs
        defaultValue={'experience'}
        tabs={[
          { value: 'experience', label: 'Work Experience' },
          // { value: 'reference', label: 'Reference', disabled: true },
        ]}
      >
        <Tabs.Content value={'experience'}>
          <Box py={2}>
            {workExperience.length > 0 ? (
              reverse(workExperience).map((exp, expIdx) => (
                <Box
                  key={exp.id}
                  py={3}
                  mb={3}
                  borderStyle={'solid'}
                  borderColor={'neutrals.200'}
                  borderTopWidth={expIdx > 0 ? 1 : 0}
                >
                  <Flex mb={3}>
                    <Box flex={1} fontWeight={2} mr={2}>
                      {exp.companyName},{' '}
                      {toTitleCase((exp.experience || '').replace(/_/g, ' '))}
                    </Box>
                    <Text fontSize={1} ml={2}>
                      {format(new Date(exp.startedAt), 'MMM yyyy')}
                      &nbsp;-&nbsp;
                      {format(new Date(exp.endedAt), 'MMM yyyy')}
                    </Text>
                  </Flex>
                  <Box color={'lightText'}>{renderSkills(exp.skills)}</Box>
                </Box>
              ))
            ) : (
              <Text>Worker has not entered their work experience.</Text>
            )}
          </Box>
        </Tabs.Content>
      </Tabs>
    </Box>
  )
}
