import { CompanyRole } from 'api/company'
import { PermissionEnum } from 'api/userPermissions'

export function useRoleDescription(
  companyRoles?: CompanyRole[],
  rolePermId?: number
): string[] {
  if (!companyRoles || !rolePermId) return []

  const companyRole = companyRoles.find((r) => Number(r.id) === rolePermId)
  if (!companyRole) return []

  return Object.keys(companyRole)
    .map((key) => {
      if (typeof companyRole[key] !== 'boolean' || companyRole[key] === false) {
        return null
      }

      switch (key) {
        case PermissionEnum.ManageRoles:
          return 'Team roles'
        case PermissionEnum.ManageBonus:
          return 'Give bonuses'
        case PermissionEnum.PaymentAccess:
          return 'Payment accounts and receipts'
        case PermissionEnum.MessageWorker:
          return 'Message workers'
        case PermissionEnum.RequestSpecificWorker:
          return 'Request specific workers'
        case PermissionEnum.ManageShifts:
          return 'Create and manage shift'
        case PermissionEnum.ManageWorker:
          return 'Manage workers'
        case PermissionEnum.WorkTimeAdjustment:
          return 'Clock in/out workers, adjust time sheets'
        default:
          return null
      }
    })
    .filter((item) => item !== null) as string[]
}
