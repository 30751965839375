import { useQuery, useQueryClient } from '@tanstack/react-query'
import { pendingInvitesKey } from './keys'
import { getPendingWorkerInvites } from 'api/worker'

export function usePendingInvites() {
  return useQuery({
    queryKey: pendingInvitesKey,
    queryFn: getPendingWorkerInvites,
  })
}

export function useInvalidatePendingInvites() {
  const queryClient = useQueryClient()
  return {
    invalidate: () =>
      queryClient.invalidateQueries({ queryKey: pendingInvitesKey }),
  }
}
