import { DemoApiMock } from 'api/demo/types'
import { getDemoData, matchRestRequest, setDemoData } from 'api/demo/utils'
import { Position } from 'typings/common_defs'
import { matchPath } from 'react-router-dom'
import { getPositionTemplate } from 'api/company'

export interface PositionRequest {
  id?: number
  name?: string | null
  about?: string | null
  position_template_id?: number
  worker_requirement_ids?: Array<number>
  custom_requirements?: Array<{ name?: string; category?: string }>
}

export const mockUpdatePosition: DemoApiMock = {
  shouldMock: (request) =>
    matchRestRequest(request, /\/company\/\d+\/position\/\d+/, 'PUT'),
  getResponse: async (request) => {
    const match = matchPath(
      '/company/:companyId/position/:positionId',
      request.url as string
    )
    const positionId = Number(match?.params.positionId)
    const params = request.data as PositionRequest
    const demoData = getDemoData()
    const { positions } = demoData
    const position = positions.find((p) => p.id === positionId)

    if (!position) {
      throw new Error('Cannot edit position that does not exist')
    }

    const updatedPosition: Position = {
      ...position,
      name: params.name ?? position.name,
      about: params.about ?? position.about,
      positionTemplateId:
        params.position_template_id ?? position.positionTemplateId,
    }

    const template = await getPositionTemplate(
      updatedPosition.positionTemplateId!
    )
    if (template) {
      const requirementIds = (params.worker_requirement_ids || []).map(String)
      updatedPosition.requirements2 = (template.requirements ?? [])
        .filter((item) =>
          requirementIds.includes(item.requirement.id.toString())
        )
        .map((item) => ({
          id: item.requirement.id,
          name: item.requirement.name,
          category: item.requirement.category,
        }))
      updatedPosition.requirements = JSON.stringify(
        updatedPosition.requirements2.map((item) => item.name)
      )
    }

    setDemoData({
      ...demoData,
      positions: demoData.positions.map((p) =>
        p.id === positionId ? updatedPosition : p
      ),
    })

    return null
  },
}
