import React, { createContext, useContext, useState } from 'react'
import {
  SimpleMemoryRouterProvider,
  useSimpleMemoryRouterActions,
} from '../../SimpleMemoryRouter'
import { requestWorkersRouter } from '../routes'
import * as zod from 'zod'
import { partialRequestWorkersModalSchema } from '../schemas'
import { useHasPaymentMethod } from 'queries/payment/useHasPaymentMethod'
import { useRequestWorkersMutation } from 'queries/assignment/useRequestWorkersMutation'
import { useAssignmentDetailContextStateValue } from 'pages/AssignmentDetailPage/context'

type RequestWorkersProviderContextState = {
  actions: {
    submitFormToServer: ReturnType<typeof useRequestWorkersMutation>['mutate']
    setFormData: (
      data: zod.infer<typeof partialRequestWorkersModalSchema>
    ) => void
  }
  state: {
    formData: zod.infer<typeof partialRequestWorkersModalSchema>
    mutationState: {
      isPending: boolean
      isError: boolean
    }
  }
}

const RequestWorkersContext = createContext<
  RequestWorkersProviderContextState | undefined
>(undefined)

export const useRequestWorkersFlowContextStateValue = () => {
  const context = useContext(RequestWorkersContext)
  if (context === undefined) {
    throw new Error(
      'useRequestWorkersFlowContextStateValue must be used within a RequestWorkersProvider'
    )
  }
  return context
}

type RequestWorkersContextProps = {
  children?: React.ReactNode
}

const RequestWorkersContextProvider = ({
  children,
}: RequestWorkersContextProps) => {
  const [requestWorkersFormData, setRequestWorkersFormData] = useState<
    zod.infer<typeof partialRequestWorkersModalSchema>
  >({})

  const hasPaymentMethod = useHasPaymentMethod()

  const { replace } = useSimpleMemoryRouterActions()

  const { assignment } = useAssignmentDetailContextStateValue()

  const {
    mutate: requestWorkersMutation,
    isPending,
    isError,
  } = useRequestWorkersMutation({
    assignmentId: assignment.id,
    onSuccess: () => {
      setRequestWorkersFormData({})
    },
  })

  const value: RequestWorkersProviderContextState = {
    actions: {
      submitFormToServer: requestWorkersMutation,
      setFormData: (
        data: zod.infer<typeof partialRequestWorkersModalSchema>
      ) => {
        setRequestWorkersFormData((prev) => ({
          ...prev,
          ...data,
        }))
      },
    },
    state: {
      formData: requestWorkersFormData,
      mutationState: {
        isPending,
        isError,
      },
    },
  }

  if (!hasPaymentMethod) {
    replace('needs-payment-method')
  }

  return (
    <RequestWorkersContext.Provider value={value}>
      {children}
    </RequestWorkersContext.Provider>
  )
}

export const RequestWorkersProvider = ({
  children,
}: RequestWorkersContextProps) => {
  return (
    <SimpleMemoryRouterProvider router={requestWorkersRouter}>
      <RequestWorkersContextProvider>{children}</RequestWorkersContextProvider>
    </SimpleMemoryRouterProvider>
  )
}
