import React from 'react'
import { Modal, Text } from 'ui'
import { useShiftDetail } from '../useShiftDetail'
import { formatInTimeZone } from 'date-fns-tz'
import {
  getShiftTimezone,
  getAutoApproveDate,
  getAutoApproveHours,
} from 'lib/shift'

interface Props {
  trigger: React.ReactNode
}

export const TimesheetReviewPeriodModal = ({ trigger }: Props) => {
  const { shift } = useShiftDetail()

  if (!shift) return null

  const timesheetReviewHours = getAutoApproveHours(shift)
  const timezone = getShiftTimezone(shift)
  const timesheetReviewApprovalDate = getAutoApproveDate(shift)
  const formattedDate = formatInTimeZone(
    timesheetReviewApprovalDate,
    timezone,
    'eee MMM do, h:mma zzz'
  )

  return (
    <Modal
      title={`Work will be auto-approved ${timesheetReviewHours}hrs after shift end time`}
      trigger={trigger}
      width={[1, 900]}
    >
      <Text mb={2}>
        Please review promptly. Workers will be paid by {formattedDate}.
      </Text>
    </Modal>
  )
}
