import React from 'react'
import { BackupShiftForm, BackupShiftSchema } from './schema'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Flex,
  Field,
  Input,
  ModalCtaContainer,
  Button,
  Select,
  DatePicker,
  Text,
} from 'ui'
import { TimeSelector } from 'components/TimeSelector'
import { UNPAID_BREAK_OPTIONS, toMinutes } from 'lib/time'
import { BackupShiftRequest, TimelineShift } from 'api/shift'
import { addDays, addMinutes, startOfDay } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { DollarSignIcon } from 'lucide-react'

interface Props {
  shift: TimelineShift
  data?: BackupShiftForm
  onSubmit: (data: BackupShiftRequest) => void
  onCancel?: () => void
}

export function PostBackupShiftForm(props: Props) {
  const { shift, data, onSubmit, onCancel } = props
  const methods = useForm<BackupShiftForm>({
    resolver: zodResolver(BackupShiftSchema),
    defaultValues: data,
  })

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = methods

  const startTime = watch('startTime')

  const onFormSubmit = handleSubmit((data) => {
    const date = data.selectedDays[0]
    const timezone = data.timezone
    const startMinutes = toMinutes(data.startTime.value)
    const endMinutes = toMinutes(data.endTime.value)
    const startsAt = addMinutes(startOfDay(date), startMinutes)
    const endsAt = addMinutes(startOfDay(date), endMinutes)
    const submitData = {
      starts_at: zonedTimeToUtc(startsAt, timezone).toISOString(),
      ends_at: zonedTimeToUtc(
        endMinutes < startMinutes ? addDays(endsAt, 1) : endsAt,
        timezone
      ).toISOString(),
      lunch_length: data.lunchLength?.value,
      bonus: data.bonus,
    }
    onSubmit(submitData)
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={onFormSubmit}>
        <Flex flexDirection={'column'} maxHeight={['100vh', '80vh']}>
          <Text color={'lightText'}>
            Once the backup shift is posted, the open spots from the current
            shift will move to the new backup shift. The total number of
            requested workers and pay rate will not change. We recommend
            starting the backup shift two hours later.
          </Text>
          <Flex
            flexDirection={'column'}
            my={3}
            px={[2, 4, 4]}
            py={4}
            backgroundColor={'offWhite'}
          >
            <Flex flexDirection={'column'} mb={4}>
              <Text fontWeight={'bold'}>
                [Backup Shift] {shift.position?.name}
              </Text>
              <Text>{shift.location?.name}</Text>
            </Flex>
            <Flex flexDirection={['column', 'row']} mb={[1, 3]}>
              <Field
                width={[1, 1 / 3]}
                mr={[0, 3]}
                label={'Date'}
                error={errors.selectedDays?.message?.toString()}
                mb={[5, 0]}
              >
                <Controller
                  name={'selectedDays'}
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      mode={'single'}
                      selected={field.value as Date}
                      onSelect={(days) =>
                        field.onChange(days instanceof Date ? [days] : days)
                      }
                      placeholder={'Select Date'}
                    />
                  )}
                />
              </Field>
              <Field
                width={[1, 1 / 3]}
                mr={[0, 4]}
                mb={[5, 0]}
                label={'Start time'}
                error={errors.startTime?.message?.toString()}
              >
                <Controller
                  name={'startTime'}
                  control={control}
                  render={({ field }) => (
                    <TimeSelector
                      aria-label={'Select Start Time'}
                      placeholder={'Select a start time'}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </Field>
              <Field
                width={[1, 1 / 3]}
                mb={[5, 0]}
                label={'End time'}
                error={errors.endTime?.message?.toString()}
              >
                <Controller
                  name={'endTime'}
                  control={control}
                  render={({ field }) => (
                    <TimeSelector
                      aria-label={'Select End Time'}
                      placeholder={'Select an end time'}
                      value={field.value}
                      from={startTime?.value}
                      onChange={field.onChange}
                      showDuration
                    />
                  )}
                />
              </Field>
            </Flex>
            <Flex flexDirection={['column', 'row']} mb={[1, 3]}>
              <Field
                width={[1, 1 / 2]}
                mr={[0, 4]}
                mb={[5, 0]}
                label={'Unpaid break time'}
                error={errors.lunchLength?.message?.toString()}
              >
                <Controller
                  name={'lunchLength'}
                  control={control}
                  render={({ field }) => (
                    <Select
                      aria-label={'Select Break Time'}
                      placeholder={'Select a break time'}
                      value={field.value}
                      options={UNPAID_BREAK_OPTIONS}
                      onChange={field.onChange}
                    />
                  )}
                />
              </Field>
              <Field
                width={[1, 1 / 2]}
                label={'Bonus'}
                mb={[5, 0]}
                error={errors.bonus?.message?.toString()}
              >
                <Input
                  aria-label={'Enter Bonus Amount'}
                  type={'number'}
                  step={'0.01'}
                  {...register('bonus')}
                  placeholder={'We recommend adding a bonus'}
                  icon={<DollarSignIcon size={17} />}
                />
              </Field>
            </Flex>
          </Flex>
          <ModalCtaContainer>
            <Button variant={'text'} onClick={onCancel}>
              Cancel
            </Button>
            <Button onClick={onFormSubmit} kind={'medium'}>
              Post
            </Button>
          </ModalCtaContainer>
        </Flex>
      </form>
    </FormProvider>
  )
}
