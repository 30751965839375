import React, { useEffect, useState } from 'react'
import { loader, PlaceInfo, transformGeocoderResult } from './utils'

export interface PlaceSuggestion {
  description: string
  placeId: string
}

export function usePlaceSuggestionInfo(suggestion?: PlaceSuggestion | null) {
  const [service, setService] = useState<google.maps.Geocoder>()
  const [result, setResult] = useState<PlaceInfo>()

  useEffect(() => {
    loader.importLibrary('geocoding').then((geocoding) => {
      setService(new geocoding.Geocoder())
    })
  }, [])

  useEffect(() => {
    if (service && suggestion) {
      service.geocode({ placeId: suggestion.placeId }).then(({ results }) => {
        const result = results[0]
        if (result) {
          setResult(transformGeocoderResult(result))
        }
      })
    } else {
      setResult(undefined)
    }
  }, [service, suggestion])

  return result
}
