import React from 'react'
import {
  Box,
  Flex,
  AvatarGroup,
  Text,
  Heading,
  Checkbox,
  Loading,
  Pluralize,
  AvatarUser,
} from 'ui'
import { repeat } from 'ramda'
import { Controller, useFormContext } from 'react-hook-form'
import { useCompanyPosition } from 'hooks/useCompanyPosition'
import { ClipboardEdit } from 'lucide-react'
import { AcknowledgementCheck } from '../../AcknowledgementCheck'
import { useCompanyPositionRoster } from 'queries/company/useCompanyPositionRoster'

export function AcknowledgeTryoutList() {
  const { watch, control } = useFormContext()
  const position = useCompanyPosition(watch('positionId'))

  const { data: positionRoster, isLoading: isLoadingPositionRoster } =
    useCompanyPositionRoster({
      positionId: watch('positionId') || -1,
      refetchOnMount: true,
    })

  const getPositionRosterContent = () => {
    if (!position) return null

    if (isLoadingPositionRoster) {
      return <Loading />
    }

    const rosterName = positionRoster ? positionRoster.name : position.name

    return (
      <>
        <Heading level={4} my={0}>
          {positionRoster
            ? `Workers will be added to your ${rosterName}`
            : `A new List will be created for ${rosterName}`}
        </Heading>
        <Text>
          The List is where your qualified Tryout workers will be stored. Once
          Tryout schedule is over, the workers will be treated as regulars or
          repeat workers you prefer for this position.
        </Text>
        <Flex p={3} alignItems={'center'}>
          <ClipboardEdit size={24} style={{ flexShrink: 0 }} />
          <Text pl={3}>
            After each shift, evaluate Tryout workers and decide whether they
            qualify for&nbsp;
            <strong>{rosterName}</strong> {positionRoster ? '' : 'List'}.
          </Text>
        </Flex>
        <Flex p={3} pt={0} alignItems={'center'}>
          <ClipboardEdit size={24} style={{ flexShrink: 0 }} />
          <Text pl={3}>
            Once your Tryout has ended, you can create shifts which only those
            in&nbsp;<strong>{rosterName}</strong> {positionRoster ? '' : 'List'}{' '}
            will be able to accept.
          </Text>
        </Flex>
      </>
    )
  }

  const positionRosterWorkers = positionRoster?.workers ?? []
  const numPositionRosterWorkers = positionRosterWorkers.length ?? 0

  return (
    <Box
      borderWidth={1}
      borderStyle={'solid'}
      borderColor={'neutrals.200'}
      borderRadius={'standard'}
    >
      <Flex
        pt={4}
        backgroundColor={position ? 'infos.50' : 'neutrals.100'}
        alignItems={'flex-end'}
        justifyContent={'center'}
        overflow={'hidden'}
      >
        <Box
          backgroundColor={'neutrals.50'}
          boxShadow={'medium'}
          width={200}
          p={3}
          borderTopLeftRadius={'standard'}
          borderTopRightRadius={'standard'}
        >
          <AvatarGroup
            users={
              positionRosterWorkers.map((user): AvatarUser => {
                return {
                  userId: user.id.toString(),
                  name: user.name,
                  photoUrl: user.profilePicUrl,
                }
              }) || repeat({}, 4)
            }
            size={'medium'}
            max={4}
            overlapOffset={'-1.5rem'}
          />
          {position ? (
            <>
              <Text mt={2} fontWeight={2} color={'lightText'}>
                {position.name}
              </Text>
              <Text fontSize={1} color={'lightText'}>
                <Pluralize
                  count={numPositionRosterWorkers}
                  oneText={'Worker'}
                  manyText="Workers"
                />
              </Text>
            </>
          ) : (
            <>
              <Box
                backgroundColor={'neutrals.100'}
                width={150}
                height={8}
                my={2}
                borderRadius={'small'}
              />
              <Box
                backgroundColor={'neutrals.100'}
                width={75}
                height={8}
                mb={3}
                borderRadius={'small'}
              />
            </>
          )}
        </Box>
      </Flex>
      <Box p={3}>
        {position ? (
          getPositionRosterContent()
        ) : (
          <>
            <Heading level={4} my={0}>
              Select a position to see a List
            </Heading>
            <Text>
              The List is where your qualified Tryout workers will be stored.
              Once Tryout schedule is over, the workers will be treated as
              regulars or repeat workers you prefer for this position.
            </Text>
          </>
        )}
      </Box>
      {position ? (
        <AcknowledgementCheck p={3}>
          <Controller
            name={'acknowledgeTryoutList'}
            control={control}
            render={({ field }) => (
              <Checkbox darkMode={true} {...field}>
                I understand that I'll have to review Tryout workers after each
                shift and remove those who are not a good fit from my&nbsp;
                <strong>{position.name}</strong> List.
              </Checkbox>
            )}
          />
        </AcknowledgementCheck>
      ) : null}
    </Box>
  )
}
