import React, { ReactNode, useRef, useState } from 'react'
import { Message, Modal } from 'ui'
import { TimelineShift } from 'api/shift'
import { CancelModalContent } from './CancelModalContent'
import {
  useCancelShiftMutation,
  useRemoveShiftFromTimeline,
} from 'queries/shift'

interface Props {
  shift: TimelineShift
  trigger: ReactNode
}

export function CancelModal(props: Props) {
  const { shift, trigger } = props
  const closeModalRef = useRef<() => void>()
  const [error, setError] = useState('')
  const { removeShift: removeShiftFromTimeline } = useRemoveShiftFromTimeline(
    shift.id
  )
  const { mutate: cancelShift, isPending: isCancelling } =
    useCancelShiftMutation({
      shiftId: shift.id,
      onSuccess: () => {
        removeShiftFromTimeline()
        if (closeModalRef.current) {
          closeModalRef.current()
        }
      },
      onError: (err) => {
        setError(err.message)
      },
    })

  const handleCancelShift = () => {
    cancelShift()
  }

  return (
    <Modal
      trigger={trigger}
      loading={isCancelling}
      title={'Are you sure you want to cancel this shift?'}
      onOk={({ closeModal }) => {
        closeModalRef.current = closeModal
        handleCancelShift()
      }}
      okText={'Yes, Confirm'}
    >
      <CancelModalContent shift={shift} />
      {error ? <Message variant={'error'}>{error}</Message> : null}
    </Modal>
  )
}
