import React, { useEffect, useMemo } from 'react'
import {
  useCompanyRoles,
  useInviteCompanyMemberMutation,
} from 'queries/company'
import {
  Box,
  Button,
  Field,
  Flex,
  Input,
  Loading,
  ModalCtaContainer,
  Select,
  Text,
  useModalContext,
  toast,
  OptionType,
} from 'ui'
import * as zod from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useRoleDescription } from './useRoleDescription'

const schema = zod.object({
  email: zod
    .string({
      required_error: 'Work email is required',
    })
    .email('Must be valid email address'),
  role: zod.coerce.number({
    required_error: 'Role is required',
  }),
})

type FormData = zod.infer<typeof schema>

export function InviteForm() {
  const { data, isLoading } = useCompanyRoles()
  const {
    register,
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  })
  const { closeModal, setLoading } = useModalContext()
  const role = watch('role')
  const roleDescriptions = useRoleDescription(data, role)
  const { mutate: inviteCompanyMember, isPending: isInviting } =
    useInviteCompanyMemberMutation({
      onSuccess() {
        toast.success('Successfully invited new company member.')
        closeModal()
      },
      onError(err) {
        toast.error(err.message)
      },
    })

  useEffect(() => {
    setLoading(isInviting)
  }, [isInviting])

  const roleOptions = useMemo(() => {
    if (!data) return []

    return data.map((role) => ({
      label: role.description,
      value: role.id,
    }))
  }, [data])

  const onSubmit = handleSubmit((data) => {
    inviteCompanyMember({
      email: data.email,
      rolePermId: data.role,
    })
  })

  if (isLoading) {
    return <Loading />
  }

  return (
    <form onSubmit={onSubmit}>
      <Field label={'Work Email'} error={errors.email?.message?.toString()}>
        <Input {...register('email')} placeholder={'Enter their work email'} />
      </Field>
      <Field error={errors.role?.message?.toString()}>
        <Controller
          name={'role'}
          control={control}
          render={({ field }) => (
            <Select
              aria-label={'Select Role'}
              placeholder={'Select a role'}
              value={roleOptions.find(
                (option) => Number(option.value) === Number(field.value)
              )}
              options={roleOptions}
              onChange={(option) => {
                const { value } = option as OptionType
                field.onChange(Number(value))
              }}
            />
          )}
        />
      </Field>
      {roleDescriptions.length > 0 ? (
        <Box
          borderRadius={'small'}
          bg={'infos.50'}
          mb={3}
          p={3}
          color={'infos.200'}
          minHeight={210}
        >
          <Text>This role has access to the following functionality:</Text>
          <ul style={{ listStyle: 'disc', padding: '0 2rem' }}>
            {roleDescriptions.map((description, index) => (
              <li key={index} color={'infos.200'}>
                {description}
              </li>
            ))}
          </ul>
        </Box>
      ) : (
        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          height={210}
          borderRadius={'small'}
          bg={'infos.50'}
          mb={3}
        >
          <Text color={'infos.200'}>
            Select a role above to see a description.
          </Text>
        </Flex>
      )}
      <ModalCtaContainer>
        <Button variant={'text'} onClick={closeModal}>
          Cancel
        </Button>
        <Button type={'submit'}>Add Company Member</Button>
      </ModalCtaContainer>
    </form>
  )
}
