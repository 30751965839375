import React, {
  createContext,
  Fragment,
  useContext,
  useLayoutEffect,
  useState,
} from 'react'
import { createSimpleMemoryRouter } from './createSimpleMemoryRouter'

const SimpleMemoryRouterProviderContext = createContext<ReturnType<
  typeof createSimpleMemoryRouter
> | null>(null)

const useSimpleMemoryRouterProviderContextValue = () => {
  const context = useContext(SimpleMemoryRouterProviderContext)
  if (context == null) {
    throw new Error(
      'useSimpleMemoryRouterProviderContextValue must be used within a SimpleMemoryRouterProvider'
    )
  }
  return context
}

export const useSimpleMemoryRouterActiveRoutes = () => {
  const router = useSimpleMemoryRouterProviderContextValue()
  const [currentPath, setCurrentPath] = useState(
    router.history.currentLocation.path
  )

  useLayoutEffect(() => {
    const unlisten = router.history.listen(() => {
      setCurrentPath(router.history.currentLocation.path)
    })

    return unlisten
  }, [router.history])

  const routes = router.routes

  return routes.filter((route) => route.path === currentPath)
}

export const useSimpleMemoryRouterActions = () => {
  const normalizedRouter = useSimpleMemoryRouterProviderContextValue()
  return normalizedRouter.history
}

export const SimpleMemoryRouterProvider = ({
  router,
  children,
}: {
  children?: React.ReactNode
  router: ReturnType<typeof createSimpleMemoryRouter>
}) => {
  return (
    <SimpleMemoryRouterProviderContext.Provider value={router}>
      {children}
    </SimpleMemoryRouterProviderContext.Provider>
  )
}

export const SimpleMemoryRouterOutlet = () => {
  const activeRoutes = useSimpleMemoryRouterActiveRoutes()
  const renderedRoutes = activeRoutes.map((route) => (
    <Fragment key={route.path}>{route.element}</Fragment>
  ))

  return <>{renderedRoutes}</>
}
